import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { NPCSHOPService } from './npcshop.service';
import { RCStackInventoryService } from './rcstack-inventory.service';
import { RCEquipmentService } from './rcequipment.service';
import { RCAttriService } from './rcattri.service';
import { RCSkillService } from './rcskill.service';
import { QuestListService } from './quest-list.service';
import { QuestInfoService } from './quest-info.service';
import { NPCConverService } from './npcconver.service';
import { PetGuiService } from './pet-gui.service';
import { PetTravelService } from './pet-travel.service';
import { PetUpLevelService } from './pet-uplevel';
import { ZCharInfoService } from './zchar-info.service';
import { UpgradeItemService } from './upgrade-item.service';
import { UpgradeRarItemService } from './upgraderar-item.service';
import { FarmPlotService } from './farm-plot.service';
import { OverlayDSPService } from './overlay-dsp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class ExecModuleService {
  wh;
  menuevent_pagecount = 0;
  menuevent_pagei = 0;
  menuevent_fwd;
  menuevent_bg;
  menuevent_back;
  menuevent_banner = [];
  menuevent_text = [];
  menuevent_descr = [];
  menuevent_closebtn;
  eventinfo;
  lastlocalmap = '';
  lastnpcvideo = '';
  worldmapzoomed = false;
  worldmap2zoomed = false;
  shopclickcount = 0;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public npcshop: NPCSHOPService,
    public stackinven: RCStackInventoryService,
    public equipment: RCEquipmentService,
    public attri: RCAttriService,
    public skill: RCSkillService,
    public questlist: QuestListService,
    public questinfo: QuestInfoService,
    public npcconver: NPCConverService,
    public petgui: PetGuiService,
    public pettravel: PetTravelService,
    public zcharinfo: ZCharInfoService,
    public upgradeitem: UpgradeItemService,
    public farmplot: FarmPlotService,
    public overlaydsp: OverlayDSPService,
    public petuplevel: PetUpLevelService,
    public upgraderar: UpgradeRarItemService
  ) {
    this.plog = new plog();
    //this.plog.setLevel(4);
  }
  gemsaddtoitem_itempicked_gemcode = "";
  async exec(mod: string, wh) {
    if (wh != undefined)
      this.wh = wh;
    if (wh == undefined)
      wh = this.wh;
    var moda = mod.split(':');
    //this.plog.error('exec-module moda', wh, moda);
    this.wh.registry.list.ga.se2('exec', mod);

    switch (moda[0]) {
      case 'gemsaddtoitem':
        this.plog.log(moda);
        this.gemsaddtoitem_itempicked_gemcode = moda[1];
        //show(wh, cbfunc, invenmode, param2 = "", itemdescrtxt = "Select Item", setscope = undefined)
        wh.registry.list.pickitemitem.show(
          wh,
          this.gemsaddtoitem_itempicked,
          'gemsaddtoitem',
          0,
          this.wh.trs("f:exec:selectgemsitems===Select item to attach this gem to"),
          this
        );
        break;
      case 'drill1':
        this.plog.log(moda);
        wh.registry.list.pickitemitem.show(
          wh,
          this.drill1_itempicked,
          'drill1items',
          0,
          this.wh.trs("f:exec:selectdrill2items===Select item to create the 1st gem slot")
        );
        break;
      case 'drill2':
        this.plog.log(moda);
        wh.registry.list.pickitemitem.show(
          wh,
          this.drill2_itempicked,
          'drill2items',
          0,
          this.wh.trs("f:exec:selectdrill2items===Select item to create the 2nd gem slot")
        );
        break;
      case 'drill3':
        this.plog.log(moda);
        wh.registry.list.pickitemitem.show(
          wh,
          this.drill3_itempicked,
          'drill3items',
          0,
          this.wh.trs("f:exec:selectdrill3items===Select item to create the 3rd gem slot")
        );
        break;
      case 'drill4':
        this.plog.log(moda);
        wh.registry.list.pickitemitem.show(
          wh,
          this.drill4_itempicked,
          'drill4items',
          0,
          this.wh.trs("f:exec:selectdrill4items===Select item to create the 4th gem slot")
        );
        break;
      case 'atmnpc':
        this.plog.log(moda);
        switch (moda[1]) {
          case "1":
            wh.atmpower_1 = 1;
            break;
          case "2":
            wh.atmpower_2 = 1;
            break;

          default:
            break;
        }
        wh.atm_atmpower();
        var npcsprite = wh.registry.list.phm.findnpc(wh, wh.lastnpcuidclicked);
        //wh.registry.list.phm.charinteractnpc(wh, npcsprite, 'thrust');
        var twtime = 300;
        var timepline = this.wh.add.timeline([
          {
            at: 0,
            tween: {
              targets: npcsprite,
              alpha: { from: 1, to: 0 },
              ease: 'Power3',
              duration: twtime,
              onComplete: function () {
                //console.error("oncompletex",this,arguments);
                //this.wh.anims.resumeAll();
                try {
                } catch (e) {
                  console.log(e);
                }

              }.bind(this)
            }
          },
        ]
        );
        timepline.play();
        //await this.wh.registry.list.rctoast.sleep(950);
        //this.wh.atmpower_1 = 1;
        await this.wh.registry.list.rctoast.sleep(twtime + 100);
        var tmpmetrix = [];
        var tmpmetrixmob = [];
        var tmpallmetrix = await this.wh.registry.list.phm.buildmapmetrix(this.rcvarsservice.gameobj.scene.scenes[0]);
        tmpmetrix = tmpallmetrix[0];
        tmpmetrixmob = tmpallmetrix[1];
        //this.plog.log("buildmapmetrix", tmpallmetrix);

        this.rcvarsservice.activemapmetrix = tmpmetrix;
        this.rcvarsservice.activemapmetrixmob = tmpmetrixmob;
        break;
      case 'gotoshareboss1':
        var bossloc = await wh.registry.list.rchttp.getcommon2("shareboss_getmap", { bossid: "shareboss1" });
        wh.chkwarppos_warp(wh, bossloc);//        this.plog.error(bossloc);
        break;
      case 'gotoshareboss2':
        var bossloc = await wh.registry.list.rchttp.getcommon2("shareboss_getmap", { bossid: "shareboss2" });
        wh.chkwarppos_warp(wh, bossloc);//        this.plog.error(bossloc);
        break;
      case 'gotoshareboss3':
        var bossloc = await wh.registry.list.rchttp.getcommon2("shareboss_getmap", { bossid: "shareboss3" });
        wh.chkwarppos_warp(wh, bossloc);//        this.plog.error(bossloc);
        break;
      case 'healallpets':
        var choicechestinfo = await wh.registry.list.rchttp.getcommon2("healallpets", {});

        await wh.registry.list.rchttp.updatecharinfo();
        break;
      case 'xpscrollmulter':
        //console.log(moda);
        //this.wh.registry.list.rcloading.loading2(this.wh);
        await this.wh.registry.list.rctoast.sleep(950);

        var res = await this.wh.registry.list.rchttp.getcommon2("usestackitem", { useid: Number(moda[1]), scrollmult: Number(moda[2]) })
        await this.wh.registry.list.rchttp.updatecharinfo();
        //this.wh.registry.list.rcloading.close(this.wh);

        break;
      case 'kiscrollmulter':
        //console.log(moda);
        //this.wh.registry.list.rcloading.loading2(this.wh);
        await this.wh.registry.list.rctoast.sleep(950);

        var res = await this.wh.registry.list.rchttp.getcommon2("usestackitem", { useid: Number(moda[1]), scrollmult: Number(moda[2]) })
        await this.wh.registry.list.rchttp.updatecharinfo();
        //this.wh.registry.list.rcloading.close(this.wh);

        break;
      case 'redocombat':
        this.wh.registry.list.rcloading.loading2(this.wh, false, false);

        var combatres = await this.wh.registry.list.rchttp.combatwin(
          "{\"0\":{}}",
          "",
          0,
          "redocombat",
          "[\"bnormal:::Uses Combat Renundo\"]"
        );

        this.wh.registry.list.showreward.effwithtxt(this.wh, "ixbeff38_b", "REDO!")
        this.wh.registry.list.wsrc.sendeffect("redo", "ixbeff38_b");

        /* this.wh.registry.list.phm.mapitemeffect(
           this.wh.char_main,
           'ixbeff38_b'
         );*/
        await this.wh.registry.list.rctoast.sleep(950);


        //this.plog.log("xxxx",this);
        //display obtained items/ combatres["dropped"]
        var dropinfo = combatres['dropped'];
        await wh.registry.list.rchttp.updatecharinfo();
        this.wh.registry.list.rcloading.close();
        this.wh.registry.list.showreward.show(this.wh, dropinfo, {
          bg: 'combatwin',
          text: 'Victory Re-do!',
          combatredocount: combatres['combatredocount']
        });
        break;
      case 'redocombatx4':
        this.wh.registry.list.showreward.effwithtxt(this.wh, "ixbeff38_b", "REDO!")
        this.wh.registry.list.wsrc.sendeffect("redo4", "ixbeff38_b");
        for (var i = 1; i <= 4; i++) {
          var combatres = await this.wh.registry.list.rchttp.combatwin(
            "{\"0\":{}}",
            "",
            0,
            "redocombat",
            "[\"bnormal:::Uses Combat Renundo\"]"
          );
          //console.log("redocombatx4")
          await this.wh.registry.list.rctoast.sleep(850);

          //this.wh.registry.list.rcloading.loading2(this.wh);
          //display obtained items/ combatres["dropped"]
          var dropinfo = combatres['dropped'];
          this.wh.registry.list.rcloading.close();
          this.wh.registry.list.showreward.show(this.wh, dropinfo, {
            bg: 'combatwin',
            text: 'Re-do ' + i + '/4!',
            windowmode: 4,
            windowpos: i,
          });
        }
        await wh.registry.list.rchttp.updatecharinfo();

        break;
      case 'redocombatx9':
        this.wh.registry.list.showreward.effwithtxt(this.wh, "ixbeff38_b", "REDO!");
        this.wh.registry.list.wsrc.sendeffect("redo9", "ixbeff38_b");
        //this.wh.registry.list.rcloading.loading2(this.wh);
        for (var i = 1; i <= 9; i++) {
          var combatres = await this.wh.registry.list.rchttp.combatwin(
            "{\"0\":{}}",
            "",
            0,
            "redocombat",
            "[\"bnormal:::Uses Combat Renundo\"]"
          );

          //this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2text(            'Loading ' + i + '/9'          );
          //console.log("redocombatx9")
          await this.wh.registry.list.rctoast.sleep(700);

          //this.wh.registry.list.rcloading.loading2(this.wh);
          //display obtained items/ combatres["dropped"]
          var dropinfo = combatres['dropped'];
          this.wh.registry.list.showreward.show(this.wh, dropinfo, {
            bg: 'combatwin',
            text: 'Re-do ' + i + '/9!',
            windowmode: 9,
            windowpos: i,
          });
        }

        this.wh.registry.list.rcloading.close();
        await wh.registry.list.rchttp.updatecharinfo();

        break;

      case 'redocombatx16':
        this.wh.registry.list.showreward.effwithtxt(this.wh, "ixbeff38_b", "REDO!")
        this.wh.registry.list.wsrc.sendeffect("redo16", "ixbeff38_b");
        //this.wh.registry.list.rcloading.loading2(this.wh);
        for (var i = 1; i <= 16; i++) {
          var combatres = await this.wh.registry.list.rchttp.combatwin(
            "{\"0\":{}}",
            "",
            0,
            "redocombat",
            "[\"bnormal:::Uses Combat Renundo\"]"
          );

          //this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2text(            'Loading ' + i + '/16'          );
          //console.log("redocombatx9")
          await this.wh.registry.list.rctoast.sleep(500);

          //this.wh.registry.list.rcloading.loading2(this.wh);
          //display obtained items/ combatres["dropped"]
          var dropinfo = combatres['dropped'];
          this.wh.registry.list.showreward.show(this.wh, dropinfo, {
            bg: 'combatwin',
            text: 'Re-do ' + i + '/16!',
            windowmode: 16,
            windowpos: i,
          });
        }

        this.wh.registry.list.rcloading.close();
        await wh.registry.list.rchttp.updatecharinfo();

        break;

      case 'pickpetcatfollow':
        this.plog.log('pickpetcatfollow', moda);
        //var npcbribeinfo = await wh.registry.list.rchttp.getcommon2("npcbribeinfo", { choicechest: moda[1], mode: "getdata" });
        this.pickpet_picked_thecat = moda[1];
        this.wh.registry.list.pickpet.show(this.wh, this.pickpet_picked, "pickpetcatfollow:" + moda[1], "", this.wh.trs("f:exec:choosepetcatfollow===Choose who this cat will follow"), this);
        break;
      case 'sharereward':
        var combatres = await this.wh.registry.list.rchttp.combatwin(
          "{\"0\":{}}",
          "",
          0,
          "sharereward",
          "[\"bnormal:::Uses Combat Renundo\"]"
        );

        this.wh.registry.list.showreward.effwithtxt(this.wh, "ixbeff38_b", "REWARD!")
        this.wh.registry.list.wsrc.sendeffect("sharereward", "ixbeff38_b");
        /* this.wh.registry.list.phm.mapitemeffect(
           this.wh.char_main,
           'ixbeff38_b'
         );*/
        await this.wh.registry.list.rctoast.sleep(950);


        //this.plog.log("xxxx",this);
        this.wh.registry.list.rcloading.loading2(this.wh);
        //display obtained items/ combatres["dropped"]
        var dropinfo = combatres['dropped'];
        await wh.registry.list.rchttp.updatecharinfo();
        this.wh.registry.list.rcloading.close();
        this.wh.registry.list.showreward.show(this.wh, dropinfo, {
          bg: 'combatwin',
          text: 'Share Reward!',
        });
        break;
      case 'sharecombatforreward':
        this.wh.registry.list.anyjs.sharer_sharecombatforreward(this.wh.lastcombattoken);
        break;

      case 'farmorder1':
        this.plog.log('farmorder1', moda);
        //var npcbribeinfo = await wh.registry.list.rchttp.getcommon2("npcbribeinfo", { choicechest: moda[1], mode: "getdata" });
        this.wh.registry.list.farmorder.show(this.wh, this.farmorder1_picked, "farmorder1:" + moda, "", this.wh.trs("f:exec:Orders, you can sell these===Orders, you can sell these"));
        break;
      case 'farmorder2':
        this.plog.log('farmorder2', moda);
        //var npcbribeinfo = await wh.registry.list.rchttp.getcommon2("npcbribeinfo", { choicechest: moda[1], mode: "getdata" });
        this.wh.registry.list.farmorder.show(this.wh, this.farmorder2_picked, "farmorder2:" + moda, "", this.wh.trs("f:exec:Orders, you can sell these===Orders, you can sell these"));
        break;
      case 'npcbribe':
        this.plog.log('npcbribe', moda);
        //var npcbribeinfo = await wh.registry.list.rchttp.getcommon2("npcbribeinfo", { choicechest: moda[1], mode: "getdata" });
        this.wh.registry.list.pickitem.show(this.wh, this.npcbribe_picked, "npcbribe:" + moda[1], "", this.wh.trs("f:exec:To pass through, select item to put in===To pass through, select item to put in"));
        break;

      case 'couponmaster':
        this.plog.log('couponmaster', moda);
        //var npcbribeinfo = await wh.registry.list.rchttp.getcommon2("npcbribeinfo", { choicechest: moda[1], mode: "getdata" });
        this.wh.registry.list.pickitem.show(this.wh, this.couponmaster_picked, "couponmaster:" + moda[1], "", this.wh.trs("f:exec:Choose your coupon===Choose your coupon (+300 diamond fee)"));
        break;

      case 'couponmaster3':
        this.plog.log('couponmaster3', moda);
        //var npcbribeinfo = await wh.registry.list.rchttp.getcommon2("npcbribeinfo", { choicechest: moda[1], mode: "getdata" });
        this.wh.registry.list.pickitem.show(this.wh, this.couponmaster3_picked, "couponmaster3:" + moda[1], "", this.wh.trs("f:exec:Choose your coupon===Choose your 3 coupons"));
        break;

      case 'choicechest':
        this.plog.log('choicechest', moda);
        var choicechestinfo = await wh.registry.list.rchttp.getcommon2("choicechestinfo", { choicechest: moda[1], mode: "getdata" });
        this.wh.registry.list.pickreward.show(this.wh, this.choicechest_picked, moda[1], choicechestinfo["rewardlist"], this.wh.trs("f:exec:Select Item===Select Item"));
        break;

      case 'collectarchimedal':
        this.plog.log('collectarchimedal', moda);
        var collectinfo = await wh.registry.list.rchttp.getcommon2("collectarchireward", { mode: "medal", questid: moda[1], level: moda[2] });
        //this.plog.error('collectarchimedal res', collectinfo);

        wh.registry.list.snd.snd("buy");
        await wh.registry.list.rchttp.updatecharinfo();
        return collectinfo;
        break;

      case 'activemapmodule':
        this.plog.log('activemapmodule', moda);
        var activemapmoduleres = await wh.registry.list.rchttp.getcommon2("activemapmodule", { mode: "-", chest: moda[1] });
        wh.registry.list.snd.snd("buy");
        //await wh.registry.list.rchttp.updatecharinfo();
        break;

      case 'collectarchiquest':
        this.plog.log('collectarchiquest', moda);
        var collectinfo = await wh.registry.list.rchttp.getcommon2("collectarchireward", { mode: "quest", questid: moda[1], level: moda[2] });
        wh.registry.list.snd.snd("buy");
        await wh.registry.list.rchttp.updatecharinfo();
        return collectinfo;
        break;

      case 'wishingwell':
        this.plog.log('wishingwell', moda);
        wh.registry.list.rcloading.close();
        var wellinfo = await wh.registry.list.rchttp.getcommon2("wishingwell", { wellid: moda[1], mode: "getdata" });

        var cfm = await wh.registry.list.rctoast.rcconfirm(wh, "Spend " + wellinfo["coststr"] + "?", "Spend " + wellinfo["coststr"] + " and get random reward?\n\nlike " + wellinfo["rewardlist"] + "");
        if (cfm == "ok") {
          await wh.registry.list.rchttp.getcommon2("wishingwell", { wellid: moda[1], mode: "risk" });
        }

        break;


      case 'resettutorial':
        this.plog.log('resettutorial', moda);
        wh.registry.list.rcloading.close();

        var cfm = await wh.registry.list.rctoast.rcconfirm(wh, "Reset tutorial?", "Reset all tutorials and view them again?");
        if (cfm == "ok") {
          wh.registry.list.rcstorage.resettutorial();

        }

        break;
      case 'useskin':

        this.plog.log('useskin', moda);
        wh.registry.list.rcloading.close();

        var cfm = await wh.registry.list.rctoast.rcconfirm(wh,
          this.wh.trs("f:exec:Apply Skin===Apply Skin"),
          this.wh.trs("f:exec:Use this skin for your character?===Use this skin for your character?"));
        if (cfm == "ok") {
          var res = await wh.registry.list.rchttp.getcommon2("consumestackitem", { usecode: "useskin" + moda[1] });
          this.plog.log("consumestackitem res", res);
          if (res == "ok") {
            var res = await wh.registry.list.rchttp.getcommon2("useskin", { skin: "" + moda[1] });
            await wh.registry.list.phm.refreshnpc_hard();
            //await wh.registry.list.rctoast.sleep(50);
            //this.wh.registry.list.phm.movemaincharto(            wh.char_main.currenty,            wh.char_main.currentx);
          }
          //wh.registry.list.zchari.show(wh, wh.registry.list.rcvarpass.activechar["id"]);

        }
        break;
      case 'buildablespawn':

        this.plog.log('element', moda);
        wh.registry.list.rcloading.close();

        var cfm = await wh.registry.list.rctoast.rcconfirm(wh,
          this.wh.trs("f:exec:Place Structure===Place Structure"),
          this.wh.trs("f:exec:Place this structure where you are standing?===Place this structure where you are standing?"));
        if (cfm == "ok") {
          var res1 = await wh.registry.list.rchttp.getcommon2("spawnbuildable", { building: moda[1] });
          this.plog.log("spawnbuildable res", res);
          if (res1 == "ok") {
            var res = await wh.registry.list.rchttp.getcommon2("consumestackitem", { usecode: "" + moda[1] });
            await wh.registry.list.phm.refreshnpc_hard();
            //await wh.registry.list.rctoast.sleep(50);
            //this.wh.registry.list.phm.movemaincharto(            wh.char_main.currenty,            wh.char_main.currentx);
          }
          //wh.registry.list.zchari.show(wh, wh.registry.list.rcvarpass.activechar["id"]);

        }
        break;
      case 'element':
        this.plog.log('element', moda);
        wh.registry.list.rcloading.close();

        var cfm = await wh.registry.list.rctoast.rcconfirm(wh,
          this.wh.trs("f:exec:Please Confirm===Please Confirm"),
          this.wh.trs("f:exec:Switch your element to:===Switch your element to:") + " [" + moda[1] + "]?");
        if (cfm == "ok") {
          await wh.registry.list.rchttp.getcommon2("switchmyelement", { newel: moda[1] });
          var res = await wh.registry.list.rchttp.getcommon2("consumestackitem", { usecode: "element" + moda[1] })
          wh.registry.list.zchari.show(wh, wh.registry.list.rcvarpass.activechar["id"]);

        }

        break;

      case 'mobdetect':
        this.plog.log('mobdetect', moda);
        wh.registry.list.rcloading.close();
        var mapid = wh.registry.list.rcvarpass.activemap['map']['code'];

        var mobinfo = await wh.registry.list.rchttp.getcommon2("mobdetect", { mapid: mapid });
        await this.wh.registry.list.rctoast.spritepaperdialog(
          wh,
          mobinfo
        );

        break;

      case 'customavatarremove':

        this.plog.log('customavatarremove', moda);
        var res = await wh.registry.list.rchttp.getcommon2(
          'customavatarremove'
        );
        this.plog.log('customavatarremove res', res);

        if (res == 'ok') {
          wh.pauseload = true;
          await wh.registry.list.rchttp.updatecharinfo();
          wh.registry.list.phm.initmap();
        }
        break;
      case 'switchgendertomale':
        this.plog.log('switchgendertomale', moda);
        var res = await wh.registry.list.rchttp.getcommon2(
          'switchgendertomale'
        );
        this.plog.log('switchgendertomale res', res);

        if (res == 'ok') {
          wh.pauseload = true;
          await wh.registry.list.rchttp.updatecharinfo();
          wh.registry.list.phm.initmap();
        }
        break;
      case 'switchgendertofemale':
        this.plog.log('switchgendertofemale', moda);
        var res = await wh.registry.list.rchttp.getcommon2(
          'switchgendertofemale'
        );
        this.plog.log('switchgendertofemale res', res);

        if (res == 'ok') {
          wh.pauseload = true;
          await wh.registry.list.rchttp.updatecharinfo();
          wh.registry.list.phm.initmap();
        }
        break;
      case 'changecharactername':
        this.plog.log('changecharactername', moda);
        var newname =
          await this.rcvarsservice.gameobj.registry.list.rctoast.rcentertext(
            this.wh,
            this.wh.trs("f:exec:Choose your new name===Choose your new name"),
            this.wh.trs("f:exec:newnamedescr===Your name can't be duplicated with existing records\nOnly a-z , 0-9 and space are allowed")
          );
        this.plog.log('newname =', newname);
        if (newname == 'cancel') return;
        if (newname == '') return;
        var result = await wh.registry.list.rchttp.getcommon2(
          'changecharactername',
          {
            newname: newname,
          }
        );
        this.plog.log('changecharactername result=', result);
        if (result == "ok") {
          await wh.registry.list.rctoast.rcconfirm(wh,
            this.wh.trs("f:exec:Done===Done"),
            this.wh.trs("f:exec:Your name updated===Your name updated"));
          wh.pauseload = true;
          await wh.registry.list.rchttp.updatecharinfo();
          wh.registry.list.phm.initmap();
        } else {
          wh.registry.list.rctoast.rcalert(wh, "Error", result);
        }
        break;
      case 'inputitemuse':
        this.plog.log('inputitemuse', moda);
        if (moda[1] == 'fishingstation') {
          //Oh, Fish!
          wh.registry.list.fishing.show(wh, moda[2]);
        }
        if (moda[1] == 'homepetheal') {
          var res = await wh.registry.list.rchttp.getcommon2('homepetheal', { npcid: moda[2] });
          await wh.registry.list.rchttp.updatecharinfo();
          this.wh.registry.list.rcvarpass.inputitem =
            await this.wh.registry.list.rchttp.getcommon2('inputitem', {
              mode: 'get',
            });
          this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
        }

        if (moda[1] == 'cooker') {
          var res = await wh.registry.list.rchttp.getcommon2('cooker', { npcid: moda[2] });
          await wh.registry.list.rchttp.updatecharinfo();
          this.wh.registry.list.rcvarpass.inputitem =
            await this.wh.registry.list.rchttp.getcommon2('inputitem', {
              mode: 'get',
            });
          this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
        }
        if (moda[1] == 'cooker2') {
          var res = await wh.registry.list.rchttp.getcommon2('cooker2', { npcid: moda[2] });
          await wh.registry.list.rchttp.updatecharinfo();
          this.wh.registry.list.rcvarpass.inputitem =
            await this.wh.registry.list.rchttp.getcommon2('inputitem', {
              mode: 'get',
            });
          this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
        }
        if (moda[1] == 'shipshield') {
          var res = await wh.registry.list.rchttp.getcommon2('inputitemactivate', { npcid: moda[2] });
          await wh.registry.list.rchttp.updatecharinfo();
          this.wh.registry.list.rcvarpass.inputitem =
            await this.wh.registry.list.rchttp.getcommon2('inputitem', {
              mode: 'get',
            });
          this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
        }

        if (moda[1] == 'shipfuelone') {
          var res = await wh.registry.list.rchttp.getcommon2('inputitemactivate', { npcid: moda[2] });
          await wh.registry.list.rchttp.updatecharinfo();
          this.wh.registry.list.rcvarpass.inputitem =
            await this.wh.registry.list.rchttp.getcommon2('inputitem', {
              mode: 'get',
            });
          this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
        }
        if (moda[1] == 'shipfueltwo') {
          var res = await wh.registry.list.rchttp.getcommon2('inputitemactivate', { npcid: moda[2] });
          await wh.registry.list.rchttp.updatecharinfo();
          this.wh.registry.list.rcvarpass.inputitem =
            await this.wh.registry.list.rchttp.getcommon2('inputitem', {
              mode: 'get',
            });
          this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
        }
        if (moda[1] == 'shipfuelthree') {
          var res = await wh.registry.list.rchttp.getcommon2('inputitemactivate', { npcid: moda[2] });
          await wh.registry.list.rchttp.updatecharinfo();
          this.wh.registry.list.rcvarpass.inputitem =
            await this.wh.registry.list.rchttp.getcommon2('inputitem', {
              mode: 'get',
            });
          this.wh.registry.list.gameitf.inputitem_updatedisplay(this.wh);
        }
        break;
      case 'spinner':
        this.plog.log('spinner', moda);
        wh.registry.list.spinner.show(wh, moda[1]);
        break;

      case 'pnnpcclick':
        this.plog.log('pnnpcclick - redir totoggleinputitembox ', moda);
        this.exec('toggleinputitembox', wh);
        break;
      case 'pnnpcclickfarmplot':
        this.plog.log('pnnpcclickfarmplot - redir1 totoggleinputitembox ', moda);
        this.exec('toggleinputitembox', wh);
        if (wh.pnnpcitfdb[wh.lastnpcuidclicked]['status'].visible == true) break;
        wh.registry.list.farmplot.show(wh, wh.lastnpcuidclicked);
        break;

      case 'toggleinputitembox':
        this.plog.log('toggleinputitembox', moda, wh.lastnpcuidclicked);
        if (wh.pnnpcitfdb[wh.lastnpcuidclicked] != undefined) {
          if (wh.pnnpcitfdb[wh.lastnpcuidclicked]['text'].visible == false) {
            //HIDE OTHERS 
            for (const key in wh.pnnpcitfdb) {
              if (Object.prototype.hasOwnProperty.call(wh.pnnpcitfdb, key)) {
                if (wh.pnnpcitfdb[key] != undefined) {
                  wh.pnnpcitfdb[key]['text'].visible = false;
                  wh.pnnpcitfdb[key]['btn1'].visible = false;
                  wh.pnnpcitfdb[key]['btn2'].visible = false;
                  wh.pnnpcitfdb[key]['btn3'].visible = false;
                  wh.pnnpcitfdb[key]['ffw'].visible = false;
                }
              }
            }
            //this.plog.log("wh.pnnpcitfdb lastnpcuidclicked visible",wh.pnnpcitfdb[wh.lastnpcuidclicked],wh.pnnpcitfdb[wh.lastnpcuidclicked]['status'].visible);
            this.plog.log("xpass", wh.pnnpcitfdb[wh.lastnpcuidclicked], wh.lastnpcuidclicked);
            if (wh.pnnpcitfdb[wh.lastnpcuidclicked]['status'].visible == true) {
              //wh.lastnpcuidclicked = '';
              //this.plog.log("wh.lastnpcuidclicked", wh.lastnpcuidclicked)
              break;
            }

            wh.pnnpcitfdb[wh.lastnpcuidclicked]['text'].visible = true;
            wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn1'].visible = true;
            wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn2'].visible = true;
            wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn3'].visible = true;

            wh.mapcontainer.bringToTop(wh.pnnpcitfdb[wh.lastnpcuidclicked]['text']);
            wh.mapcontainer.bringToTop(wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn1']);
            wh.mapcontainer.bringToTop(wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn2']);
            wh.mapcontainer.bringToTop(wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn3']);

            var griddat = [];
            var griddatf = [];
            griddatf.push(wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn1']);
            griddatf.push(wh.pnnpcitfdb[wh.lastnpcuidclicked]['ffw']);

            griddat.push(wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn1']);
            griddat.push(wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn2']);
            griddat.push(wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn3']);
            griddat.push(wh.pnnpcitfdb[wh.lastnpcuidclicked]['ffw']);
            await this.wh.registry.list.gp.guigrid(griddat, griddatf);
            this.wh.registry.list.gp.guigrid_noclosebtn = true;

            if (
              wh.registry.list.rcvarpass.activemap['map']['npc_personal'] != undefined &&
              wh.registry.list.rcvarpass.activemap['map']['npc_personal'][wh.lastnpcuidclicked] != undefined
            ) {
              var tmpstat = wh.registry.list.rcvarpass.activemap['map']['npc_personal'][wh.lastnpcuidclicked];
              this.plog.log("xpass tmpstat", tmpstat);

              //this.plog.log("tmpstat", tmpstat,tmpstat['stat'])
              //this.plog.log("tmpstat time", tmpstat['statuntil'], (wh.registry.list.timectl.humantimeorig + wh.gametimesec + 5))
              //this.plog.log("tmpstat o", wh.lastnpcuidclicked,wh.pnnpcitfdb[wh.lastnpcuidclicked]['ffw'])

              if (tmpstat['stat'] != undefined && tmpstat['stat'] != "" && tmpstat['statuntil'] > (wh.registry.list.timectl.humantimeorig + wh.gametimesec + 5)) {
                wh.pnnpcitfdb[wh.lastnpcuidclicked]['ffw'].visible = true;
                wh.mapcontainer.bringToTop(wh.pnnpcitfdb[wh.lastnpcuidclicked]['ffw']);
                wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn1'].visible = false;
                this.plog.warn("tmpstat o", wh.lastnpcuidclicked, wh.pnnpcitfdb[wh.lastnpcuidclicked]['ffw'])

              }

            }

          } else {

            wh.pnnpcitfdb[wh.lastnpcuidclicked]['text'].visible = false;
            wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn1'].visible = false;
            wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn2'].visible = false;
            wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn3'].visible = false;
            wh.pnnpcitfdb[wh.lastnpcuidclicked]['ffw'].visible = false;

          }
        } else {
          this.plog.log('toggleinputitembox pnnpcitfdb undefined', wh.pnnpcitfdb, wh.pnnpcitfdb[wh.lastnpcuidclicked]);

        }
        if (wh.inputitemdb[wh.lastnpcuidclicked] != undefined) {

          if (wh.inputitemdb[wh.lastnpcuidclicked]['bg'].visible == false) {
            wh.inputitemdb[wh.lastnpcuidclicked]['bg'].visible = true;
            wh.inputitemdb[wh.lastnpcuidclicked]['text'].visible = true;
            wh.inputitemdb[wh.lastnpcuidclicked]['usebtn'].visible = true;
            wh.inputitemdb[wh.lastnpcuidclicked]['btns'][0].visible = true;
            if (wh.inputitemdb[wh.lastnpcuidclicked]['btns'][1] != undefined)
              wh.inputitemdb[wh.lastnpcuidclicked]['btns'][1].visible = true;
            if (wh.inputitemdb[wh.lastnpcuidclicked]['btns'][2] != undefined)
              wh.inputitemdb[wh.lastnpcuidclicked]['btns'][2].visible = true;
            wh.inputitemdb[wh.lastnpcuidclicked]['amount'][0].visible = true;
            if (wh.inputitemdb[wh.lastnpcuidclicked]['amount'][1] != undefined)
              wh.inputitemdb[wh.lastnpcuidclicked]['amount'][1].visible = true;
            if (wh.inputitemdb[wh.lastnpcuidclicked]['amount'][2] != undefined)
              wh.inputitemdb[wh.lastnpcuidclicked]['amount'][2].visible = true;

            var griddat = [];
            var griddatf = [];
            griddatf.push(wh.inputitemdb[wh.lastnpcuidclicked]['usebtn']);
            griddat.push(wh.inputitemdb[wh.lastnpcuidclicked]['usebtn']);
            griddat.push(wh.inputitemdb[wh.lastnpcuidclicked]['btns'][0]);
            griddat.push(wh.inputitemdb[wh.lastnpcuidclicked]['btns'][1]);
            griddat.push(wh.inputitemdb[wh.lastnpcuidclicked]['btns'][2]);
            /*
            griddat.push(wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn1']);
            griddat.push(wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn1']);
            griddat.push(wh.pnnpcitfdb[wh.lastnpcuidclicked]['btn2']);
            griddat.push(wh.pnnpcitfdb[wh.lastnpcuidclicked]['ffw']);*/
            this.wh.registry.list.gp.guigrid(griddat, griddatf);
            this.wh.registry.list.gp.guigrid_noclosebtn = true;
          } else {
            wh.inputitemdb[wh.lastnpcuidclicked]['bg'].visible = false;
            wh.inputitemdb[wh.lastnpcuidclicked]['text'].visible = false;
            wh.inputitemdb[wh.lastnpcuidclicked]['usebtn'].visible = false;
            wh.inputitemdb[wh.lastnpcuidclicked]['btns'][0].visible = false;
            if (wh.inputitemdb[wh.lastnpcuidclicked]['btns'][1] != undefined)
              wh.inputitemdb[wh.lastnpcuidclicked]['btns'][1].visible = false;
            if (wh.inputitemdb[wh.lastnpcuidclicked]['btns'][2] != undefined)
              wh.inputitemdb[wh.lastnpcuidclicked]['btns'][2].visible = false;
            wh.inputitemdb[wh.lastnpcuidclicked]['amount'][0].visible = false;
            if (wh.inputitemdb[wh.lastnpcuidclicked]['amount'][1] != undefined)
              wh.inputitemdb[wh.lastnpcuidclicked]['amount'][1].visible = false;
            if (wh.inputitemdb[wh.lastnpcuidclicked]['amount'][2] != undefined)
              wh.inputitemdb[wh.lastnpcuidclicked]['amount'][2].visible = false;
          }


        }



        wh.registry.list.rcloading.close();
        break;
      case 'teleport':
        this.plog.log('teleport', moda);
        wh.chkwarppos_warp(wh, moda[1]);
        break;

      case 'talktonpc':
        //starttalk
        this.npcconver.starttalk(moda[1], wh);
        //this.npcconver.conver(moda[1], wh, 0);
        break;

      case 'theatre':
        //starttalk
        await this.npcconver.starttalk("theatre", wh);
        //this.npcconver.conver(moda[1], wh, 0);

        if (wh.gamemenuloading != undefined) {
          //wh.gamemenuloading.visible = false;
        }
        break;

      case 'healicon':
        if (
          wh.registry.list.rcvarpass.activechar.cbt['hp'] ==
          wh.registry.list.rcvarpass.activechar.cbt['maxhp']
        ) {
          return;
        }
        var res = await wh.registry.list.rchttp.getcommon2('healicon', {});
        wh.registry.list.phm.mapitemeffect(wh.char_main, 'ixbeff147_b');

        if (res == 'ok') {
          wh.registry.list.rchttp.updatecharinfo();
          wh.registry.list.snd.snd("heal");
        }
        break;
      case 'merchantviewmyitem':
        wh.registry.list.merchant.VIEWMY(wh);
        break;
      case 'merchantviewmystackitem':
        wh.registry.list.merchant.VIEWMYSTACK(wh);
        break;
      case 'merchantbuyitem':
        wh.registry.list.merchant.BUYITEM(wh);
        break;
      case 'merchantbuystackitem':
        wh.registry.list.merchant.BUYSTACKITEM(wh);
        break;
      case 'merchantsellitem':
        wh.registry.list.merchant.sellitem(wh);
        break;
      case 'merchantsellstackitem':
        wh.registry.list.merchant.sellstackitem(wh);
        break;
      case 'redeemcode':
        this.plog.log('redeemcode', moda);
        //rcentertext(wh, head, text, deftext = "")
        var code =
          await this.rcvarsservice.gameobj.registry.list.rctoast.rcentertext(
            this.wh,
            this.wh.trs("f:exec:Enter Gift Code here===Enter Gift Code here"),
            this.wh.trs("f:exec:Enter Gift code in this box===Enter Gift code in this box")
          );
        this.plog.log('Gift code=', code);
        if (code == 'cancel') return;
        var redeem = await wh.registry.list.rchttp.getcommon2('redeemcode', {
          code: code,
        });
        this.plog.log('Gift code redeem=', redeem);
        if (redeem != undefined && redeem['redeemstat'] == 'ok') {
          wh.registry.list.showreward.show(wh, redeem['reward']);
          await wh.registry.list.rchttp.updatecharinfo();
        }
        break;
      case 'miningclick':
        this.plog.log('miningclick', wh);
        var reqitem = wh.registry.list.rcreq.chk({ weapon1: 'shovel' });
        if (reqitem == false) {
          wh.registry.list.phm.charsaybubble(
            wh.scene.scene.char_main,
            this.wh.trs("f:exec:I have to equip [Shovel] before mining===I have to equip [Shovel] before mining")
          );
          wh.registry.list.rcloading.close();
          return;
        }
        var shopdata = await wh.registry.list.rchttp.getcommon2('miningclick');
        this.plog.log('miningclick', wh.scene.scene.char_main);
        //wh.scene.scene.char_main.play("thrust");
        var npcsprite = wh.registry.list.phm.findnpc(wh, wh.lastnpcuidclicked);
        wh.registry.list.phm.charinteractnpc(wh, npcsprite, 'thrust');
        wh.registry.list.wsrc.ping();
        //"2-female-mine-thrust-l"
        //await wh.registry.list.rchttp.updatecharinfo();;
        //wh.registry.list.gameitf.mapfadeout(wh, 5000);
        break;

      case 'petpetcat':
        //this.plog.log('petpetcat', wh);
        this.plog.log('petpetcat', moda);

        var shopdata = await wh.registry.list.rchttp.getcommon2('petpetcat', { catid: moda[1] });
        //this.plog.log('petpetcat', wh.scene.scene.char_main);
        if (shopdata == "ok") {
          var spritetouse = "petpetcatsprite_petcata";
          if (moda[2] == "catset01") spritetouse = "petpetcatsprite_petcata";
          if (moda[2] == "catset02") spritetouse = "petpetcatsprite_petcatb";
          if (moda[2] == "catset03") spritetouse = "petpetcatsprite_petcatc";
          if (moda[2] == "catset04") spritetouse = "petpetcatsprite_petcatd";
          if (moda[2] == "catset05") spritetouse = "petpetcatsprite_petcate";
          this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rctoast.spritedialog(
            this.wh,
            '' + spritetouse,
            this.wh.trs("f:exec:Petting a cat===Petting a cat"),
            this.wh.trs("f:exec:Got 10 Cat Furs===Got 10 Cat Furs")
          );
          this.rcvarsservice.gameobj.scene.scenes[0].registry.list.phm.voicethis(this.rcvarsservice.gameobj.scene.scenes[0], "petpetcat");
          await wh.registry.list.rchttp.updatecharinfo();;
        }

        //wh.scene.scene.char_main.play("thrust");
        //"2-female-mine-thrust-l"
        //await wh.registry.list.rchttp.updatecharinfo();;
        //wh.registry.list.gameitf.mapfadeout(wh, 5000);
        break;
      case 'logspawner':
        this.plog.log('logspawner', wh);

        var shopdata = await wh.registry.list.rchttp.getcommon2('logspawner');
        this.plog.log('logspawner', wh.scene.scene.char_main);
        //wh.scene.scene.char_main.play("thrust");
        var npcsprite = wh.registry.list.phm.findnpc(wh, wh.lastnpcuidclicked);
        wh.registry.list.phm.charinteractnpc(wh, npcsprite, 'thrust');
        wh.registry.list.wsrc.ping();
        break;
      case 'bushreward':
        //wh.registry.list.farmplot.show(wh, wh.lastnpcuidclicked);
        this.plog.log('bushreward:', moda, wh.lastnpcuidclicked);
        var mapid = wh.registry.list.rcvarpass.activemap['map']['code'];

        var npcsprite = wh.registry.list.phm.findnpc(wh, wh.lastnpcuidclicked);
        await wh.registry.list.phm.charinteractnpc(wh, npcsprite, 'thrust');
        var shopdata = await wh.registry.list.rchttp.getcommon2('bushreward', {
          bushid: wh.lastnpcuidclicked,
        });
        wh.registry.list.gameitf.doidoi(wh, npcsprite);
        await wh.registry.list.rchttp.updatecharinfo();
        await this.wh.registry.list.phm.updatebushrewardnpcdsp(this.wh);
        wh.registry.list.snd.snd("fruitcollect");

        break;

      case 'chest':
        this.plog.log('chest', moda);
        var spadata = await wh.registry.list.rchttp.getcommon2(
          'getsparchestinfo',
          { sparcode: moda[1] }
        );
        this.plog.log('chest spadata', spadata);
        if (spadata != undefined)
          wh.registry.list.combat.start(wh, {
            combatmode: 'chest',
            data: spadata,
          });

        break;
      case 'localmap':
        this.plog.log('localmap', moda, wh);
        var mapid = wh.registry.list.rcvarpass.activemap['map']['code'];
        this.lastlocalmap = mapid;
        var loaderlocal = new Phaser.Loader.LoaderPlugin(
          this.rcvarsservice.gameobj.scene.scenes[0]
        ); // + Math.random()+ Math.random()
        var svversionurlstring = this.rcvarsservice.svversionstring;
        if (svversionurlstring != '') {
          svversionurlstring = '?' + svversionurlstring;
        }
        loaderlocal.image(
          'localmap_' + mapid + '',
          this.rcvarsservice.rc_baseurl +
          'localmap/' +
          mapid +
          '.png' +
          svversionurlstring
        );
        //this.plog.log("loadcharsprite", Phaser.Loader.Events, sprite, this, loaderlocal);
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2(
          wh
        );

        loaderlocal.once(
          Phaser.Loader.Events.COMPLETE,
          () => {
            // texture loaded so use instead of the placeholder
            this.localmap_loaded();
            //this.plog.log("oncomplete ", this);
          },
          this
        );
        loaderlocal.on(
          'progress',
          function (value) {
            this.plog.log(
              'loaderevent',
              value,
              loaderlocal.totalComplete,
              loaderlocal.totalToLoad
            );
            this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2text(
              'Loading ' +
              loaderlocal.totalComplete +
              '/' +
              loaderlocal.totalToLoad
            );
            this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2percent(
              value
            );
          },
          this
        );
        //this.plog.log("loadcharsprite afterset", Phaser.Loader.Events);
        loaderlocal.start();
        //var spadata = await wh.registry.list.rchttp.getcommon2("localmap", { sparcode: moda[1] });
        break;

      case 'npcvideo':
        this.plog.log('npcvideo', moda, wh);
        this.lastnpcvideo = moda[1];
        var loaderlocal = new Phaser.Loader.LoaderPlugin(
          this.rcvarsservice.gameobj.scene.scenes[0]
        ); // + Math.random()+ Math.random()
        loaderlocal.crossOrigin = 'anonymous';
        this.wh.kbfocuslv1 = "npcvideo";
        var svversionurlstring = this.rcvarsservice.svversionstring;
        if (svversionurlstring != '') {
          svversionurlstring = '?' + svversionurlstring;
        }
        var tmpurl = this.rcvarsservice.gameobj.registry.list.rcvarpass.getlocal(
          'npcvideo/' +
          this.lastnpcvideo +
          '.webm' +
          svversionurlstring
        );
        loaderlocal.video(
          'npcvideo_' + this.lastnpcvideo + '',
          tmpurl
          //, "loadeddata", false, true
        );
        //this.plog.log("loadcharsprite", Phaser.Loader.Events, sprite, this, loaderlocal);
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2(wh);

        loaderlocal.once(
          Phaser.Loader.Events.COMPLETE,
          () => {
            // texture loaded so use instead of the placeholder
            this.npcvideo_loaded();
            //this.plog.log("oncomplete ", this);
          },
          this
        );
        loaderlocal.on(
          'progress',
          function (value) {
            this.plog.log('loaderevent', value, loaderlocal.totalComplete, loaderlocal.totalToLoad);
            this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2text(
              'Loading ' +
              loaderlocal.totalComplete +
              '/' +
              loaderlocal.totalToLoad
            );
            this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2percent(value);
          }, this);
        //this.plog.log("loadcharsprite afterset", Phaser.Loader.Events);
        loaderlocal.start();
        //var spadata = await wh.registry.list.rchttp.getcommon2("localmap", { sparcode: moda[1] });
        break;

      case 'sleepingcat':
        this.plog.log('sleepingcat', moda, wh);
        var loaderlocal = new Phaser.Loader.LoaderPlugin(
          this.rcvarsservice.gameobj.scene.scenes[0]
        ); // + Math.random()+ Math.random()
        var svversionurlstring = this.rcvarsservice.svversionstring;
        if (svversionurlstring != '') {
          svversionurlstring = '?' + svversionurlstring;
        }
        loaderlocal.atlas(
          'pettingcat',
          this.rcvarsservice.rc_baseurl +
          'sprite/pettingcat.png' +
          svversionurlstring,
          this.rcvarsservice.rc_baseurl +
          'sprite/pettingcat.json' +
          svversionurlstring
        );
        //this.plog.log("loadcharsprite", Phaser.Loader.Events, sprite, this, loaderlocal);
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2(
          wh, false, false
        );

        loaderlocal.once(
          Phaser.Loader.Events.COMPLETE,
          () => {
            // texture loaded so use instead of the placeholder
            this.pettingcat_loaded();
            //this.plog.log("oncomplete ", this);
          },
          this
        );
        loaderlocal.on(
          'progress',
          function (value) {
            this.plog.log(
              'loaderevent',
              value,
              loaderlocal.totalComplete,
              loaderlocal.totalToLoad
            );
            this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2text(
              'Loading ' +
              loaderlocal.totalComplete +
              '/' +
              loaderlocal.totalToLoad
            );
            this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2percent(
              value
            );
          },
          this
        );
        //this.plog.log("loadcharsprite afterset", Phaser.Loader.Events);
        loaderlocal.start();
        //var spadata = await wh.registry.list.rchttp.getcommon2("localmap", { sparcode: moda[1] });
        break;
      case 'sparboard':
        this.plog.log('sparboard', moda);
        wh.registry.list.sparb.show(wh, moda[1]);
        break;
      case "shipnavigation":

        this.plog.log('shipnavigation', moda);
        wh.registry.list.shipnavigation.show(wh, moda[1]);
        break;
      case 'spar':
        this.plog.log('spar', moda);
        var spadata = await wh.registry.list.rchttp.getcommon2('getsparinfo', {
          sparcode: moda[1],
        });
        this.plog.log('spar spadata', spadata);
        if (spadata['donetoday'] >= spadata['limitperday']) {
          wh.registry.list.rctoast.rcalert(
            wh,
            this.wh.trs("f:exec:Limit Reached===Limit Reached"),
            "You've done this spar " +
            spadata['donetoday'] +
            '/' +
            spadata['limitperday']
          );
          return;
        }
        if (spadata['mode'] == 'boss' && spadata['bossisdead'] == 'yes') {
          wh.registry.list.rcloading.close();

          wh.registry.list.rctoast.rcalert(
            wh,
            this.wh.trs("f:exec:Enemy eliminated===Enemy eliminated"),
            this.wh.trs("f:exec:You've already defeated the boss===You've already defeated the boss")
          );
          return;
        }
        wh.registry.list.combat.start(wh, {
          combatmode: spadata['mode'],
          data: spadata,
        });
        break;
      case 'homestartglobalinven':
        //this.plog.log('homeinvensi?', wh);
        this.wh.kbfocus = "homeinvensi";
        this.plog.log('homeinvensi?', this.wh, this.wh.kbfocus);
        wh.registry.list.rctoast.homeinvensi(wh);

        this.plog.log('homeinvensi?a', this.wh, this.wh.kbfocus);
        break;
      case 'rebornas1':
        this.plog.log('rebornas1?', wh);
        if (wh.registry.list.rcvarpass.activechar['level'] < 300) {
          wh.registry.list.rctoast.rcalert(wh,
            this.wh.trs("f:exec:Requirement not met===Requirement not met"),
            this.wh.trs("f:exec:You need to be level 300reborn===You need to be level 300 to REBORN"));
          return;
        }
        var cfm = await wh.registry.list.rctoast.rcconfirm(wh,
          this.wh.trs("f:exec:Please Confirm===Please Confirm"),
          this.wh.trs("f:exec:Are you sure you want to REBORNTANKER===Are you sure you want to REBORN\n\nas a TANKER ? \n\nYour level/XP/KI will also be reset."));
        if (cfm == "ok") {
          wh.registry.list.phm.mapitemeffect(wh.char_main, 'farteleport_b');
          await wh.registry.list.rchttp.getcommon2("rebornas", { rebornas: 1 });
          wh.pauseload = true;
          await wh.registry.list.rchttp.updatecharinfo();
          wh.chkwarppos_warp(wh, "home");
          wh.registry.list.phm.initmap();
        }
        break;
      case 'rebornas2':
        this.plog.log('rebornas2?', wh);
        if (wh.registry.list.rcvarpass.activechar['level'] < 300) {
          wh.registry.list.rctoast.rcalert(wh,
            this.wh.trs("f:exec:Requirement not met===Requirement not met"),
            this.wh.trs("f:exec:You need to be level 300reborn===You need to be level 300 to REBORN"));
          return;
        }

        var cfm = await wh.registry.list.rctoast.rcconfirm(wh,
          this.wh.trs("f:exec:Please Confirm===Please Confirm"),
          this.wh.trs("f:exec:Are you sure you want to REBORNMAGICIAN===Are you sure you want to REBORN\n\nas a MAGICIAN ? \n\nYour level/XP/KI will also be reset."));
        if (cfm == "ok") {
          wh.registry.list.phm.mapitemeffect(wh.char_main, 'farteleport_b');
          await wh.registry.list.rchttp.getcommon2("rebornas", { rebornas: 2 });
          wh.pauseload = true;
          await wh.registry.list.rchttp.updatecharinfo();
          wh.chkwarppos_warp(wh, "home");
          wh.registry.list.phm.initmap();
        }
        break;
      case 'rebornas3':
        this.plog.log('rebornas3?', wh);
        if (wh.registry.list.rcvarpass.activechar['level'] < 300) {
          wh.registry.list.rctoast.rcalert(wh,
            this.wh.trs("f:exec:Requirement not met===Requirement not met"),
            this.wh.trs("f:exec:You need to be level 300reborn===You need to be level 300 to REBORN"));
          return;
        }

        var cfm = await wh.registry.list.rctoast.rcconfirm(wh,
          this.wh.trs("f:exec:Please Confirm===Please Confirm"),
          this.wh.trs("f:exec:Are you sure you want to REBORNSWORDMAN===Are you sure you want to REBORN\n\nas a SWORDMAN ? \n\nYour level/XP/KI will also be reset."));
        if (cfm == "ok") {
          wh.registry.list.phm.mapitemeffect(wh.char_main, 'farteleport_b');
          await wh.registry.list.rchttp.getcommon2("rebornas", { rebornas: 3 });
          wh.pauseload = true;
          await wh.registry.list.rchttp.updatecharinfo();
          wh.chkwarppos_warp(wh, "home");
          wh.registry.list.phm.initmap();
        }
        break;
      case 'rebornas4':
        this.plog.log('rebornas4?', wh);
        if (wh.registry.list.rcvarpass.activechar['level'] < 300) {
          wh.registry.list.rctoast.rcalert(wh,
            this.wh.trs("f:exec:Requirement not met===Requirement not met"),
            this.wh.trs("f:exec:You need to be level 300reborn===You need to be level 300 to REBORN"));
          return;
        }

        var cfm = await wh.registry.list.rctoast.rcconfirm(wh,
          this.wh.trs("f:exec:Please Confirm===Please Confirm"),
          this.wh.trs("f:exec:Are you sure you want to REBORNRANGER===Are you sure you want to REBORN\n\nas a RANGER  ?\n\nYour level/XP/KI will also be reset."));
        if (cfm == "ok") {
          wh.registry.list.phm.mapitemeffect(wh.char_main, 'farteleport_b');
          await wh.registry.list.rchttp.getcommon2("rebornas", { rebornas: 4 });
          wh.pauseload = true;
          await wh.registry.list.rchttp.updatecharinfo();
          wh.chkwarppos_warp(wh, "home");
          wh.registry.list.phm.initmap();
        }
        break;
      case 'rebornas5':
        this.plog.log('rebornas5?', wh);
        if (wh.registry.list.rcvarpass.activechar['level'] < 300) {
          wh.registry.list.rctoast.rcalert(wh,
            this.wh.trs("f:exec:Requirement not met===Requirement not met"),
            this.wh.trs("f:exec:You need to be level 300reborn===You need to be level 300 to REBORN"));
          return;
        }

        var cfm = await wh.registry.list.rctoast.rcconfirm(wh,
          this.wh.trs("f:exec:Please Confirm===Please Confirm"),
          this.wh.trs("f:exec:Are you sure you want to REBORNNECROMANCER===Are you sure you want to REBORN\n\nas a NECROMANCER ? \n\nYour level/XP/KI will also be reset."));
        if (cfm == "ok") {
          wh.registry.list.phm.mapitemeffect(wh.char_main, 'farteleport_b');
          await wh.registry.list.rchttp.getcommon2("rebornas", { rebornas: 5 });
          wh.pauseload = true;
          await wh.registry.list.rchttp.updatecharinfo();
          wh.chkwarppos_warp(wh, "home");
          wh.registry.list.phm.initmap();
        }
        break;
      case 'rebornitem':
        this.npcconver.starttalk("rebornnpc", wh);

        break;
        var cfm = await wh.registry.list.rctoast.rcconfirm(wh, "Confirm ", "Are you sure you want to REBORN?\n\nYour ");
        if (cfm == "ok") {
          this.plog.log('rebornitem exed', wh);

          /*wh.registry.list.phm.mapitemeffect(
            wh.char_main,
            'farteleport_b'
          );
          //await wh.registry.list.rchttp.getcommon2("wishingwell", { wellid: moda[1], mode: "risk" });
          wh.chkwarppos_warp(wh, "desert0");
*/
        }
        //wh.registry.list.rctoast.homeinvensi(wh);
        break;
      case 'sleephome':
        this.plog.log('sleephome', wh);
        if (
          wh.registry.list.rcvarpass.activechar.cbt['hp'] ==
          wh.registry.list.rcvarpass.activechar.cbt['maxhp']
        ) {
          wh.registry.list.phm.charsaybubble(
            wh.char_main,
            this.wh.trs("f:exec:I'm not sleepy, and my HP is full===I'm not sleepy, and my HP is full")
          );
          wh.registry.list.phm.voicethis(wh, "nobed");
        } else {
          var shopdata = await wh.registry.list.rchttp.getcommon2('sleephome');
          await wh.registry.list.rchttp.updatecharinfo();
          wh.registry.list.rctoast.rctoast(this.wh, this.wh.trs("f:exec:Your HP is now full===Your HP is now full"));
          wh.registry.list.gameitf.mapfadeout(wh, 3000);
        }
        break;
      case 'openshop':
        this.plog.log('shopdata', shopdata);
        this.plog.log('shopdata moda', moda);
        var shopdata = wh.registry.list.rcvarpass.allnpcshop[moda[1]];
        this.npcshop.shop_stackitem(wh, shopdata);
        break;
      case 'petuplevel':
        this.petuplevel.show(wh, true);
        break;
      case 'gamemenu':
        this.plog.log('gamemenu moda', moda);
        if (moda[1] == 'inventory') {
          var invendata = wh.registry.list.rcvarpass.activechar;
          wh.registry.list.gameitf.disablegamemenuicon(wh, "inventory");
          wh.registry.list.inven.showinventory(wh, invendata, '', true);
          this.plog.log("gamemenux", wh.gamemenu);
        }
        if (moda[1] == 'shop') {
          this.shopclickcount++;
          wh.registry.list.gameitf.disablegamemenuicon(wh, "shop");
          if (this.shopclickcount <= 0) {
            wh.registry.list.rctoast.rcalert(
              wh,
              'Thank you, but.. ',
              'Purchasing items from shop will be available in the next update, thank you for your support, please check back again '
            );
          } else {

            wh.registry.list.iapstore.showinventory(wh, 'normal', true);
          }
        }
        if (moda[1] == 'stackitem') {
          wh.registry.list.gameitf.disablegamemenuicon(wh, "stackitem");
          var invendata = wh.registry.list.rcvarpass.activechar;
          this.stackinven.showinventory(wh, invendata, '', true);
        }
        if (moda[1] == 'quest') {
          wh.registry.list.gameitf.disablegamemenuicon(wh, "quest");
          var invendata = wh.registry.list.rcvarpass.activechar;
          this.questlist.show(wh, true);
        }
        if (moda[1] == 'equipment') {
          wh.registry.list.gameitf.disablegamemenuicon(wh, "equipment");
          var invendata = wh.registry.list.rcvarpass.activechar;
          this.equipment.show(wh, true);
        }
        if (moda[1] == 'attribute') {
          wh.registry.list.gameitf.disablegamemenuicon(wh, "attribute");
          var invendata = wh.registry.list.rcvarpass.activechar;
          this.attri.show(wh, true);
        }
        if (moda[1] == 'skill') {
          wh.registry.list.gameitf.disablegamemenuicon(wh, "skill");
          var invendata = wh.registry.list.rcvarpass.activechar;
          this.skill.show(wh, true);
        }
        if (moda[1] == 'pet') {
          this.plog.log(
            ' x',
            wh.registry.list.rcvarpass.activemap['map']['code']
          );
          if (wh.registry.list.rcvarpass.activemap['map']['code'] == 'home') {
            wh.registry.list.gameitf.disablegamemenuicon(wh, "pet");
            this.petgui.show(wh, true);
          } else {
            wh.registry.list.gameitf.disablegamemenuicon(wh, "pet");
            this.pettravel.show(wh, true);
          }
        }
        if (moda[1] == 'event') {
          //this.overlaydsp.menuevent(wh);
          //this.openWebpage("https://peacely.co/");

          this.plog.log('event', moda, wh);
          this.eventinfo = await wh.registry.list.rchttp.getcommon2("menuevent");

          var loaderlocal = new Phaser.Loader.LoaderPlugin(
            this.rcvarsservice.gameobj.scene.scenes[0]
          ); // + Math.random()+ Math.random()
          var svversionurlstring = this.rcvarsservice.svversionstring;
          if (svversionurlstring != '') {
            svversionurlstring = '?' + svversionurlstring;
          }
          var i = 0;
          for (const k in this.eventinfo) {
            var v = this.eventinfo[k];
            this.plog.log("menuevent b", v, this.wh.registry.list.rcvarpass.rc_baseurl)
            var baseurl1 = "";
            baseurl1 = (this.wh.registry.list.rcvarpass.rc_baseurl + "").replace("/provider", "");
            var useurlimg;
            if ((v["img"]["url"] + "" + "").toString().indexOf("/svcli/") != -1) {
              var useurl = (v["img"]["url"] + "").split("/svcli/");
              useurlimg = baseurl1 + useurl[1];
            } else {
              useurlimg = v["img"]["url"] + "";
            }
            this.plog.log("menuevent", baseurl1, useurl, useurlimg)

            loaderlocal.image(
              'menuevent_' + i + '',
              useurlimg +
              svversionurlstring
            );
            i++;
          }
          //this.plog.log("loadcharsprite", Phaser.Loader.Events, sprite, this, loaderlocal);
          this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2(
            wh
          );

          loaderlocal.once(
            Phaser.Loader.Events.COMPLETE,
            () => {
              // texture loaded so use instead of the placeholder
              this.menuevent_loaded();
              //this.plog.log("oncomplete ", this);
            },
            this
          );
          loaderlocal.on(
            'progress',
            function (value) {
              this.plog.log(
                'loaderevent',
                value,
                loaderlocal.totalComplete,
                loaderlocal.totalToLoad
              );
              this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2text(
                'Loading ' +
                loaderlocal.totalComplete +
                '/' +
                loaderlocal.totalToLoad
              );
              this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2percent(
                value
              );
            },
            this
          );
          //this.plog.log("loadcharsprite afterset", Phaser.Loader.Events);
          loaderlocal.start();
        }

        if (typeof wh.camman === "function") {
          // ref by Preloader scene
          wh.camman(wh);
        }
        break;
      case 'donationshed-item':
        var invendata = wh.registry.list.rcvarpass.activechar;
        wh.registry.list.inven.showinventory(wh, invendata, 'donate', true);
        break;
      case 'donationshed-stackitem':
        var invendata = wh.registry.list.rcvarpass.activechar;
        this.stackinven.showinventory(wh, invendata, 'donate', true);
        break;
      case 'showquestinfo':
        this.plog.log('qqq', this, wh);
        if (wh.npccontainer != undefined) {
          wh.npccontainer.visible = false;
        }
        this.questinfo.firsttimerun = true;//forcefocus
        this.questinfo.show(wh, moda[1]);
        //this.wh.registry.list.gameitf.guiwindowclick(this.wh.skillcontainer);

        //this.stackinven.showinventory(wh, invendata, "sell");
        break;
      case 'npc_upgradeitem':
        this.upgradeitem.show(wh);
        break;
      case 'upgraderar':
        this.upgraderar.show(wh);
        break;
      case 'npc_sellstackitem':
        var invendata = wh.registry.list.rcvarpass.activechar;
        this.stackinven.showinventory(wh, invendata, 'sell');

        break;
      case 'flag':
        var flagres = await wh.registry.list.rchttp.getcommon2("setmyflag", { flag: moda[1] });
        wh.registry.list.zchari.show(wh, wh.registry.list.rcvarpass.activechar["id"]);
        break;
      case 'teleportkey2':
        this.plog.log("exec teleportkey2", moda, this);

        var loaderlocal = new Phaser.Loader.LoaderPlugin(
          this.rcvarsservice.gameobj.scene.scenes[0]
        ); // + Math.random()+ Math.random()
        var svversionurlstring = this.rcvarsservice.svversionstring;
        if (svversionurlstring != '') {
          svversionurlstring = '?' + svversionurlstring;
        }
        loaderlocal.image('worldmap', this.rcvarsservice.rc_baseurl + 'worldmap.png' + svversionurlstring);
        //this.plog.log("loadcharsprite", Phaser.Loader.Events, sprite, this, loaderlocal);
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2(
          wh
        );

        loaderlocal.once(
          Phaser.Loader.Events.COMPLETE,
          () => {
            // texture loaded so use instead of the placeholder
            this.openworldmap(this.wh);
            //this.plog.log("oncomplete ", this);
          },
          this
        );
        loaderlocal.on(
          'progress',
          function (value) {
            this.plog.log('loaderevent', value, loaderlocal.totalComplete, loaderlocal.totalToLoad);

            this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2text('Loading ' + loaderlocal.totalComplete + '/' + loaderlocal.totalToLoad);
            this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2percent(value);
          },
          this
        );
        //this.plog.log("loadcharsprite afterset", Phaser.Loader.Events);
        loaderlocal.start();
        break;

      case 'teleportkey3':
        this.plog.log("exec teleportkey3", moda, this);

        var loaderlocal = new Phaser.Loader.LoaderPlugin(
          this.rcvarsservice.gameobj.scene.scenes[0]
        ); // + Math.random()+ Math.random()
        var svversionurlstring = this.rcvarsservice.svversionstring;
        if (svversionurlstring != '') {
          svversionurlstring = '?' + svversionurlstring;
        }
        loaderlocal.image('worldmap2', this.rcvarsservice.rc_baseurl + 'worldmap2.png' + svversionurlstring);
        //this.plog.log("loadcharsprite", Phaser.Loader.Events, sprite, this, loaderlocal);
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2(
          wh
        );

        loaderlocal.once(
          Phaser.Loader.Events.COMPLETE,
          () => {
            // texture loaded so use instead of the placeholder
            this.openworldmap2(this.wh);
            //this.plog.log("oncomplete ", this);
          },
          this
        );
        loaderlocal.on(
          'progress',
          function (value) {
            this.plog.log('loaderevent', value, loaderlocal.totalComplete, loaderlocal.totalToLoad);

            this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2text('Loading ' + loaderlocal.totalComplete + '/' + loaderlocal.totalToLoad);
            this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2percent(value);
          },
          this
        );
        //this.plog.log("loadcharsprite afterset", Phaser.Loader.Events);
        loaderlocal.start();
        break;
      case 'npc_sellitem':
        var invendata = wh.registry.list.rcvarpass.activechar;
        wh.registry.list.inven.showinventory(wh, invendata, 'sell');
        break;
      case 'ungradeinvensize':
        wh.registry.list.diamondoffer.ungradeinvensize(wh);
        break;
      case 'ungradestackinvensize':
        wh.registry.list.diamondoffer.ungradestackinvensize(wh);
        break;
      case 'shipboarding':
        this.plog.log('shipboarding', wh)
        wh.registry.list.phm.mapitemeffect(
          wh.char_main,
          'farteleport_b'
        );
        //await wh.registry.list.rchttp.getcommon2("wishingwell", { wellid: moda[1], mode: "risk" });
        wh.chkwarppos_warp(wh, "ship1");
        break;
      case 'teleporttodes':
        this.plog.log('teleporttodes', moda);
        if (wh.registry.list.rcvarpass.activechar['level'] < 150) {
          wh.registry.list.rctoast.rcalert(
            wh,
            this.wh.trs("f:exec:Requirement not met===Requirement not met"),
            this.wh.trs("f:exec:You need to be level 150 to use this teleporter===You need to be level 150 to use this teleporter")
          );
          return;
        }
        var cfm = await wh.registry.list.rctoast.rcconfirm(wh,
          this.wh.trs("f:exec:Confirm Teleportation===Confirm Teleportation"),
          this.wh.trs("f:exec:Spend 2 diamonds to go to Desert Area?===Spend 2 diamonds to go to Desert Area?"));
        if (cfm == "ok") {
          wh.registry.list.phm.mapitemeffect(
            wh.char_main,
            'farteleport_b'
          );
          //await wh.registry.list.rchttp.getcommon2("wishingwell", { wellid: moda[1], mode: "risk" });
          wh.chkwarppos_warp(wh, "desert0");

        }
        break;
      case 'mapscroll':
        this.plog.log('mapscroll', moda);
        wh.chkwarppos_warp(wh, moda[1]);
        break;

      case 'openeggpass':
        var reqitem = wh.registry.list.rcreq.chk({
          stackitem1: 'eggpass',
          stackitem1_min: 1,
        });
        if (reqitem == false) {
          wh.registry.list.rctoast.rcalert(
            wh,
            this.wh.trs("f:exec:Item Required===Item Required"),
            this.wh.trs("f:exec:Item [Egg Pass] required to incubate an egg===Item [Egg Pass] required to incubate an egg")
          );
        } else {
          wh.registry.list.eggnest.incubate(wh, moda[1]);
        }
        this.plog.log('openeggpass:', reqitem, moda);
        break;
      case 'openeggnest':
        var reqitem = wh.registry.list.rcreq.chk({
          stackitem1: 'eggpass',
          stackitem1_min: 1,
        });
        if (reqitem == false) {
          wh.registry.list.rctoast.rcalert(
            wh,
            this.wh.trs("f:exec:Item Required===Item Required"),
            this.wh.trs("f:exec:Item [Egg Pass] required to incubate an egg===Item [Egg Pass] required to incubate an egg")
          );
        } else {
          wh.registry.list.eggnest.show(wh);
        }
        this.plog.log('openeggnest:', reqitem, moda);
        break;

      case 'farmplot':
        this.plog.log('farmplot:', moda, wh);
        wh.registry.list.farmplot.show(wh, wh.lastnpcuidclicked);
        break;
      case 'partyflag':
        this.plog.log('partyflag:', moda);
        wh.registry.list.rcloading.close();

        wh.registry.list.partymap.show(wh);
        break;
      case 'updatequest':
        this.plog.log("updatequest", moda, wh);
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.loading2(wh);
        var res = await this.wh.registry.list.rchttp.getcommon2('updatequest', { questid: moda[1], step: moda[2] });
        this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.close();
        this.questinfo.show(wh, moda[1], true);

        break;

      default:
        this.plog.log('UNKNOWN MODULE', moda);
        wh.registry.list.rcloading.close();
        break;
    }
  }
  localmap_loaded() {
    this.plog.log('localmap_loaded()');
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.close();
    try {
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rctoast.localmap(
        this.wh,
        this.lastlocalmap
      );
    } catch (e) {
      this.plog.log("localmap_loaded", e);
    }
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.phm.voicethis(this.rcvarsservice.gameobj.scene.scenes[0], "explorearea");

  }
  async npcvideo_loaded() {
    this.plog.log('npcvideo_loaded()', this.lastnpcvideo);
    var descr = await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2("npcvideo", { code: this.lastnpcvideo });
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.close();
    this.plog.log("npcvideo_loaded descr", descr);
    try {
      this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rctoast.npcvideo(
        this.wh,
        this.lastnpcvideo,
        descr["descr"]
      );
    } catch (e) {
      this.plog.log("npcvideo_loaded", e);
    }

  }
  async pettingcat_loaded() {
    //this.plog.log('localmap_loaded()');
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.gameitf.genloadedanims(
      this.wh,
      'pettingcat_b'
    );
    var res = await this.wh.registry.list.rchttp.getcommon2('pettingcat', {});
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.snd.snd("meow1");

    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.close();
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rctoast.spritedialog(
      this.wh,
      'pettingcat_b',
      this.wh.trs("f:exec:Petting a cat===Petting a cat"),
      this.wh.trs("f:exec:You got blessed, EXP x1.5, KI x2 for 4 hours===You got blessed, EXP x1.5, KI x2 for 4 hours")
    );
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.phm.voicethis(this.rcvarsservice.gameobj.scene.scenes[0], "catbless");

  }

  openWebpage(url: string) {
    /*const options: InAppBrowserOptions = {
      zoom: 'no'
    }
    // Opening a URL and returning an InAppBrowserObject
    const browser = this.iab.create(url, '_self', options);
   // Inject scripts, css and more with browser.X
   */
    //const browser = this.iab.create(url, '_self');
    window.open(url, '_blank');
  }
  menuevent_loaded() {
    this.plog.log("menuevent_loaded", this.eventinfo);
    this.menuevent_close();
    //this.wh.kbfocuslv1="event";
    this.wh.registry.list.rcloading.close();

    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    var usew = Math.min(width, height);
    this.menuevent_back = this.wh.add.sprite(0, 0, "thebb", "backsign");
    this.menuevent_back.setPosition(width / 2 - this.menuevent_back.displayWidth - 10, height - this.menuevent_back.displayHeight + 10);
    this.menuevent_back.setDepth(1500001);
    this.menuevent_back.removeAllListeners();
    this.menuevent_back.setInteractive();
    this.menuevent_back.on("pointerup", function () {
      this.menuevent_page("back");
    }, this);

    this.menuevent_fwd = this.wh.add.sprite(0, 0, "thebb", "forwardsign");
    this.menuevent_fwd.setPosition(width / 2 + this.menuevent_fwd.displayWidth + 10, height - this.menuevent_fwd.displayHeight + 10);
    this.menuevent_fwd.setDepth(1500001);
    this.menuevent_fwd.removeAllListeners();
    this.menuevent_fwd.setInteractive();
    this.menuevent_fwd.on("pointerup", function () {
      this.menuevent_page("next");
    }, this);

    this.menuevent_bg = this.wh.add.rectangle(0, 0, width, height, 0xffffff);
    this.menuevent_bg.setAlpha(0.7);
    this.menuevent_bg.setPosition(0, 0);
    this.menuevent_bg.setOrigin(0, 0);
    this.menuevent_bg.displayWidth = width;
    this.menuevent_bg.displayHeight = height;
    this.menuevent_bg.setDepth(1500000);
    this.menuevent_bg.removeAllListeners();
    this.menuevent_bg.setInteractive();
    this.menuevent_bg.on("pointerup", function () {
      this.menuevent_close();
    }, this);

    if (usew > 600) usew = 600;
    var i = 0;
    for (const k in this.eventinfo) {
      var v = this.eventinfo[k];
      this.menuevent_banner[i] = this.wh.add.image(0, 0, "menuevent_" + i);
      this.menuevent_banner[i].setPosition(width / 2, height / 2);
      this.menuevent_banner[i].setDepth(1500001);
      this.menuevent_banner[i].displayWidth = usew;
      this.menuevent_banner[i].scaleY = this.menuevent_banner[i].scaleX;

      this.menuevent_banner[i].removeAllListeners();
      this.menuevent_banner[i].setInteractive();
      this.menuevent_banner[i].on("pointerup", function () {
        this.openWebpage("https://peacely.online/");
      }, this);

      this.menuevent_text[i] = this.wh.add.text(0, 0, this.wh.gl(v["name"]), this.wh.fs["event_text"]);
      this.menuevent_text[i].setPosition(width / 2 - this.menuevent_text[i].displayWidth / 2, height / 2 + this.menuevent_banner[i].displayHeight / 2 + 15);
      this.menuevent_text[i].setDepth(1500002);
      this.menuevent_text[i].setWordWrapWidth(usew);

      this.menuevent_descr[i] = this.wh.add.text(0, 0, this.wh.gl(v["descr"]), this.wh.fs["event_descr"]);
      this.menuevent_descr[i].setPosition(width / 2 - this.menuevent_descr[i].displayWidth / 2, height / 2 + this.menuevent_banner[i].displayHeight / 2 + 60);
      this.menuevent_descr[i].setDepth(1500002);
      this.menuevent_descr[i].setWordWrapWidth(usew);
      i++;
    }
    this.menuevent_pagecount = i;
    this.menuevent_page("back");

    this.menuevent_closebtn = this.wh.add.sprite(300, 300, "thesq", 'close');
    this.menuevent_closebtn.setOrigin(0.5, 0.5);
    this.menuevent_closebtn.setPosition(50, 50);

    this.menuevent_closebtn.setDepth(1500003);
    this.menuevent_closebtn.removeAllListeners();
    this.menuevent_closebtn.setInteractive();
    this.menuevent_closebtn.on("pointerup", function () {
      this.menuevent_close();
    }.bind(this));
    var griddat = [];
    var griddatf = [];

    griddat.push(this.menuevent_back);
    griddat.push(this.menuevent_fwd);
    griddat.push(this.menuevent_closebtn);
    griddatf.push(this.menuevent_fwd);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }
  menuevent_page(viewpage) {
    if (viewpage == 'back') {
      if (this.menuevent_pagei > 0) this.menuevent_pagei--;
    }
    if (viewpage == 'next') {
      if (this.menuevent_pagei < this.menuevent_pagecount - 1) this.menuevent_pagei++;
    }

    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.menuevent_banner[i] != undefined) {
        this.menuevent_banner[i].visible = false;
      }
      if (this.menuevent_text[i] != undefined) {
        this.menuevent_text[i].visible = false;
      }
      if (this.menuevent_descr[i] != undefined) {
        this.menuevent_descr[i].visible = false;
      }
    }
    this.plog.log("menuevent_page", viewpage, this.menuevent_pagei, this.menuevent_banner, this.menuevent_banner[this.menuevent_pagei]);
    if (this.menuevent_banner[this.menuevent_pagei] != undefined)
      this.menuevent_banner[this.menuevent_pagei].visible = true;
    if (this.menuevent_text[this.menuevent_pagei] != undefined)
      this.menuevent_text[this.menuevent_pagei].visible = true;
    if (this.menuevent_descr[this.menuevent_pagei] != undefined)
      this.menuevent_descr[this.menuevent_pagei].visible = true;

  }
  menuevent_close() {

    if (this.menuevent_back != undefined) {
      this.menuevent_back.destroy();
      this.menuevent_back = undefined;
    }
    if (this.menuevent_fwd != undefined) {
      this.menuevent_fwd.destroy();
      this.menuevent_fwd = undefined;
    }
    if (this.menuevent_bg != undefined) {
      this.menuevent_bg.destroy();
      this.menuevent_bg = undefined;
    }
    if (this.menuevent_closebtn != undefined) {
      this.menuevent_closebtn.destroy();
      this.menuevent_closebtn = undefined;
    }
    for (const k in this.menuevent_banner) {
      if (this.menuevent_banner[k] != undefined) {
        this.menuevent_banner[k].destroy();
        this.menuevent_banner[k] = undefined;
      }
    }
    for (const k in this.menuevent_text) {
      if (this.menuevent_text[k] != undefined) {
        this.menuevent_text[k].destroy();
        this.menuevent_text[k] = undefined;
      }
    }
    for (const k in this.menuevent_descr) {
      if (this.menuevent_descr[k] != undefined) {
        this.menuevent_descr[k].destroy();
        this.menuevent_descr[k] = undefined;
      }
    }
    this.wh.registry.list.gp.guigrid_focushide();
    this.wh.kbfocus = "";
  }
  async choicechest_picked(wh1, wh2) {
    this.plog.log("choicechest_picked", this, wh1, wh2);
    var choicechestinfo = await this.wh.registry.list.rchttp.getcommon2("choicechestinfo", { choicechest: wh2, chooseitem: wh1, mode: "use" });
    await this.wh.registry.list.rchttp.updatecharinfo();

  }
  pickpet_picked_thecat = "";
  async pickpet_picked(a1, a2, a3, a4) {
    //console.log("pickpet_picked this", this);
    //console.log("pickpet_picked", arguments);
    var res = await this.wh.registry.list.rchttp.getcommon2('pickpet_picked', { setfollowcat: this.pickpet_picked_thecat, setfollow: arguments[0] });
    await this.wh.registry.list.rchttp.updatecharinfo();
  }
  async farmorder1_picked(a1, a2, a3, a4) {
    this.plog.log("farmorder1_picked", arguments);
    var res = await this.wh.registry.list.rchttp.getcommon2('farmorder1sell', { orderid: a1 });
    await this.wh.registry.list.rchttp.updatecharinfo();
  }
  async farmorder2_picked(a1, a2, a3, a4) {
    this.plog.log("farmorder2_picked", arguments);
    var res = await this.wh.registry.list.rchttp.getcommon2('farmorder2sell', { orderid: a1 });
    await this.wh.registry.list.rchttp.updatecharinfo();
  }
  async couponmaster_picked(a1, a2, a3, a4) {
    this.plog.log("couponmaster_picked", arguments);
    var couponresult = await this.wh.registry.list.rchttp.getcommon2("couponmaster", { itemcode: a1, itemid: a2, npcid: this.wh.lastnpcuidclicked });
    this.plog.log("couponmaster_picked couponresult", couponresult);
    await this.wh.registry.list.rchttp.updatecharinfo();
  }
  async couponmaster3_picked(a1, a2, a3, a4) {
    this.plog.log("couponmaster3_picked", arguments);
    var couponresult = await this.wh.registry.list.rchttp.getcommon2("couponmaster3", { itemcode: a1, itemid: a2, npcid: this.wh.lastnpcuidclicked });
    this.plog.log("couponmaster3_picked couponresult", couponresult);
    await this.wh.registry.list.rchttp.updatecharinfo();
  }
  async npcbribe_picked(a1, a2, a3, a4) {
    this.plog.log("npcbribe_picked", arguments);
    var npcbribeinfo = await this.wh.registry.list.rchttp.getcommon2("npcbribe_putitem", { itemcode: a1, itemid: a2, npcid: this.wh.lastnpcuidclicked });
    await this.wh.registry.list.phm.updatenpcbribedsp(this.wh, true);
    var tmpmetrix = [];
    var tmpmetrixmob = [];
    var tmpallmetrix = await this.wh.registry.list.phm.buildmapmetrix(this.rcvarsservice.gameobj.scene.scenes[0]);
    tmpmetrix = tmpallmetrix[0];
    tmpmetrixmob = tmpallmetrix[1];
    //this.plog.log("buildmapmetrix", tmpallmetrix);

    this.rcvarsservice.activemapmetrix = tmpmetrix;
    this.rcvarsservice.activemapmetrixmob = tmpmetrixmob;
  }

  openworldmap_poitercount = 0;
  async openworldmap(wh) {
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.close();
    wh.registry.list.execmod.worldmapzoomed = false;
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (wh.worldmapbg != undefined) {
      wh.worldmapbg.destroy();//true
      wh.worldmapbg = undefined;
    }
    if (wh.worldmap != undefined) {
      wh.worldmap.destroy();//true
      wh.worldmap = undefined;
    }
    wh.worldmap_gesturetakecontrol = false;

    if (wh.worldmapalt != undefined) {
      wh.worldmapalt.destroy();//true
      wh.worldmapalt = undefined;
    }

    wh.worldmapalt = this.wh.add.text(0, 0, "", this.wh.fs["event_text"]);
    //wh.worldmapalt.setPosition(width / 2 - this.menuevent_text[i].displayWidth / 2, height / 2 + this.menuevent_banner[i].displayHeight / 2 + 15);
    wh.worldmapalt.setDepth(1001020);
    wh.worldmapalt.setWordWrapWidth(1000);
    wh.worldmapdata = await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2("worldmapdata", {});
    wh.worldmapbg = wh.add.rectangle(0, 0, width, height, 0x000000);
    wh.worldmapbg.setAlpha(0.9);
    wh.worldmapbg.setPosition(0, 0);
    wh.worldmapbg.setOrigin(0, 0);
    wh.worldmapbg.setAlpha(0.8);
    //wh.worldmapbg.visible = false;
    //npcbg.setDepth(70000);
    wh.worldmapbg.setInteractive();

    wh.worldmap = wh.add.image(
      width / 2,
      height / 2,
      'worldmap'
    );
    wh.worldmap.setAlpha(1);
    wh.worldmap.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    let scaleX = (width - 10) / wh.worldmap.width;
    let scaleY = (height - 20) / wh.worldmap.height;
    let scale = Math.min(scaleX, scaleY);
    //this.plog.log("worldmap scale", scale);
    wh.worldmap.setDepth(1001010);
    wh.worldmapbg.setDepth(1001000);
    wh.worldmap.setScale(scale); //.setScrollFactor(0);
    wh.worldmap.removeAllListeners();
    wh.worldmap.off();
    wh.worldmap.setInteractive();
    var usex = 0;
    var usey = 0;
    var usexraw = 0;
    var useyraw = 0;
    wh.worldmap.on('pointermove', function (pointer, localX, localY, event) {
      var width = this.scene.game.canvas.width;
      var height = this.scene.game.canvas.height;
      usex = Math.floor(localX / 100);
      usey = Math.floor(localY / 100);
      usexraw = Math.floor(localX);
      useyraw = Math.floor(localY);
      var mapdata = this.scene.worldmapdata[usex + "-" + usey];
      //this.plog.log(localX, localY, mapdata);
      if (mapdata != undefined) {
        if (mapdata == "NO" || mapdata == undefined) {
          this.scene.worldmapalt.setText("");
          //this.scene.registry.list.rctoast.rcalert(this.scene, "Error", "Can't teleport to cursed area or dungeons");
        } else {
          var mapname = this.scene.registry.list.rcvarpass.allmap[mapdata]["name"];
          if (mapdata == "EVENT") mapname = "EVENT MAP";
          this.scene.worldmapalt.setText(mapname + " lv." + this.scene.registry.list.rcvarpass.allmap[mapdata]["level"]);
          this.scene.worldmapalt.setPosition(width / 2 - this.scene.worldmapalt.displayWidth / 2, 50);
        }
      };
    });
    wh.mapcami(wh, wh.worldmap);
    wh.worldmap.on('wheel', (pointer) => {
      //console.log(pointer);
      //console.log(pointer, usexraw, useyraw);
      //console.log(arguments);
      var newscale = wh.worldmap.scale + pointer.deltaY * 0.0005;
      //console.log(newscale);
      if (newscale > 0.65) newscale = 0.65;
      if (newscale < 0.1) newscale = 0.1;
      //wh.worldmap.scale = newscale;
      var tw = wh.tweens.add({
        targets: wh.worldmap,
        scale: newscale,
        //x: ((zoomposw / 2) * this.scale * 3) - (localX*2 * this.scale * 3) * (this.scale * 3),
        //y: ((zoomposh / 2) * this.scale * 3) - (localY*2 * this.scale * 3) * (this.scale * 3),
        ease: 'Power3',
        duration: 150,
        delay: 0,
      });
      /*
      var u = ((wh.worldmap.scale) * usexraw);//+ pointer.downX;
      var v = ((wh.worldmap.scale) * useyraw);//+ pointer.downX;
      console.log(u,v);*/
      //wh.worldmap.x = 0-u;
      //wh.worldmap.y = 0-v;
    });
    wh.worldmap.on('pointerdown', async function (pointer, localX, localY, event) {

      wh.worldmap_gesturetakecontrol = false;
    });
    wh.worldmap.on('pointerup', async function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.scene.plog.log("worldmap pointerup", pointer, localX, localY, event, this);
      //return;
      if (wh.worldmap_gesturetakecontrol == true) return;
      //console.log("pointerup", arguments)
      var zoomposw = this.texture.source[0].width;
      var zoomposh = this.texture.source[0].height;
      var finalscale = this.scale * 3
      var width = this.scene.game.canvas.width;
      var height = this.scene.game.canvas.height;
      /*
            if (this.scene.registry.list.execmod.worldmapzoomed != true) {
              this.scene.plog.log("worldmap pointerup, not zooming", this.scale, this);
              this.scale = (this.scale * 3);
              this.x = (width / 2) +
                (((zoomposw / 2) - localX) * this.scale);
              this.y = (height / 2) +
                (((zoomposh / 2) - localY) * this.scale);
              //
              / *var tw = wh.tweens.add({
                targets: [this],
                scale: (this.scale * 3),
                //x: ((zoomposw / 2) * this.scale * 3) - (localX*2 * this.scale * 3) * (this.scale * 3),
                //y: ((zoomposh / 2) * this.scale * 3) - (localY*2 * this.scale * 3) * (this.scale * 3),
                ease: 'Power3',
                duration: 10,
                delay: 150,
                onComplete: function () {
                  // arguments[1][0].visible = false;
                }, // set context? how?
              });* /
              this.scene.registry.list.execmod.worldmapzoomed = true;
              return;
            }*/
      var clickcellx = Math.floor(localX / 10) + 1; //divide by minimap cellsize
      var clickcelly = Math.floor(localY / 10) + 1; //divide by minimap cellsize

      //this.plog.log("worldmap clickx",clickcellx,clickcelly,walkablechk);
      //this.plog.log("worldmap walk", clickcellx, clickcelly, walkablechk, this.scene);
      var tmpmock = { name: 'bg-' + clickcellx + '-' + clickcelly };
      this.scene.plog.log("worldmapclick", localX, localY)
      var usex = Math.floor(localX / 100);
      var usey = Math.floor(localY / 100);
      var mapdata = this.scene.worldmapdata[usex + "-" + usey];
      if (mapdata != undefined) {
        if (mapdata == "NO") {
          this.scene.registry.list.rctoast.rcalert(this.scene, this.scene.trs("f:exec:Error===Error"),
            this.scene.trs("f:exec:Can't teleport to cursed area or dungeons===Can't teleport to cursed area or dungeons"));
        } else {
          var res = await this.scene.registry.list.rchttp.getcommon2("consumestackitem", { usecode: "teleportkey2" })
          this.scene.chkwarppos_warp(this.scene, mapdata);
        }
      };

      this.scene.plog.log("worldmapclick found", mapdata, this.scene.worldmapdata)
      var tw = wh.tweens.add({
        targets: [this, this.scene.worldmapbg, this.scene.worldmapalt],
        alpha: 0,
        ease: 'Power3',
        duration: 400, // duration of animation; higher=slower
        delay: 150, // wait 500 ms before starting
        onComplete: function () {
          arguments[1][0].visible = false;
          arguments[1][1].visible = false;
          arguments[1][2].visible = false;
        }, // set context? how?
      });
      //this.visible = false;
      //this.scene.worldmapbg.visible = false;
      //     this.plog.log("worldmapclick",this);;
    });
    /*wh.worldmap.on('pointermove', function (pointer, localX, localY, event) {
      var clickcellx = Math.floor(localX / 10); //divide by minimap cellsize
      var clickcelly = Math.floor(localY / 10); //divide by minimap cellsize
      var mchkx = clickcellx + 1;
      var mchky = clickcelly + 1;

    });*/
    //wh.worldmap.visible = false;
    wh.worldmapbg.on(
      'pointerup',
      function () {
        if (wh.worldmap_gesturetakecontrol == true) return;
        wh.worldmap.visible = false;
        wh.worldmapalt.visible = false;
        wh.worldmapbg.visible = false;
      },
      this
    );
  }

  async openworldmap2(wh) {
    this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rcloading.close();
    wh.registry.list.execmod.worldmap2zoomed = false;
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (wh.worldma2pbg != undefined) {
      wh.worldmap2bg.destroy();//true
      wh.worldmap2bg = undefined;
    }
    if (wh.worldmap2 != undefined) {
      wh.worldmap2.destroy();//true
      wh.worldmap2 = undefined;
    }

    if (wh.worldmap2alt != undefined) {
      wh.worldmap2alt.destroy();//true
      wh.worldmap2alt = undefined;
    }

    wh.worldmap2alt = this.wh.add.text(0, 0, "", this.wh.fs["event_text"]);
    wh.worldmap2alt.setDepth(1001020);
    wh.worldmap2alt.setWordWrapWidth(1000);
    wh.worldmap2data = await this.rcvarsservice.gameobj.scene.scenes[0].registry.list.rchttp.getcommon2("worldmap2data", {});
    wh.worldmap2bg = wh.add.rectangle(0, 0, width, height, 0x000000);
    wh.worldmap2bg.setAlpha(0.9);
    wh.worldmap2bg.setPosition(0, 0);
    wh.worldmap2bg.setOrigin(0, 0);
    wh.worldmap2bg.setAlpha(0.8);
    //wh.worldmapbg.visible = false;
    //npcbg.setDepth(70000);
    wh.worldmap2bg.setInteractive();

    wh.worldmap2 = wh.add.image(
      width / 2,
      height / 2,
      'worldmap2'
    );
    wh.worldmap2.setAlpha(1);
    wh.worldmap2.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    let scaleX = (width - 10) / wh.worldmap2.width;
    let scaleY = (height - 20) / wh.worldmap2.height;
    let scale = Math.min(scaleX, scaleY);
    //this.plog.log("worldmap scale", scale);
    wh.worldmap2.setDepth(1001010);
    wh.worldmap2bg.setDepth(1001000);
    wh.worldmap2.setScale(scale); //.setScrollFactor(0);
    wh.worldmap2.removeAllListeners();
    wh.worldmap2.off();
    wh.worldmap2.setInteractive();
    wh.worldmap2.on('pointermove', function (pointer, localX, localY, event) {
      var width = this.scene.game.canvas.width;
      var height = this.scene.game.canvas.height;
      var usex = Math.floor(localX / 100);
      var usey = Math.floor(localY / 100);
      var mapdata = this.scene.worldmap2data[usex + "-" + usey];
      //this.plog.log(localX, localY, mapdata);
      if (mapdata != undefined) {
        if (mapdata == "NO" || mapdata == undefined) {
          this.scene.worldmap2alt.setText("");
          //this.scene.registry.list.rctoast.rcalert(this.scene, "Error", "Can't teleport to cursed area or dungeons");
        } else {
          var mapname = this.scene.registry.list.rcvarpass.allmap[mapdata]["name"];
          if (mapdata == "EVENT") mapname = "EVENT MAP";
          this.scene.worldmap2alt.setText(mapname + " lv." + this.scene.registry.list.rcvarpass.allmap[mapdata]["level"]);
          this.scene.worldmap2alt.setPosition(width / 2 - this.scene.worldmap2alt.displayWidth / 2, 50);
        }
      };
    });
    wh.mapcami(wh, wh.worldmap2);
    wh.worldmap2.on('wheel', (pointer) => {
      //console.log(pointer);
      //console.log(pointer, usexraw, useyraw);
      //console.log(arguments);
      var newscale = wh.worldmap2.scale + pointer.deltaY * 0.0005;
      //console.log(newscale);
      if (newscale > 0.65) newscale = 0.65;
      if (newscale < 0.1) newscale = 0.1;
      //wh.worldmap2.scale = newscale;
      var tw = wh.tweens.add({
        targets: wh.worldmap2,
        scale: newscale,
        //x: ((zoomposw / 2) * this.scale * 3) - (localX*2 * this.scale * 3) * (this.scale * 3),
        //y: ((zoomposh / 2) * this.scale * 3) - (localY*2 * this.scale * 3) * (this.scale * 3),
        ease: 'Power3',
        duration: 150,
        delay: 0,
      });
    });
    wh.worldmap2.on('pointerdown', async function (pointer, localX, localY, event) {

      wh.worldmap_gesturetakecontrol = false;
    });
    wh.worldmap2.on('pointerup', async function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      if (wh.worldmap_gesturetakecontrol == true) return;
      this.scene.plog.log("worldmap2 pointerup", pointer, localX, localY, event, this);
      //return;
      var zoomposw = this.texture.source[0].width;
      var zoomposh = this.texture.source[0].height;
      var finalscale = this.scale * 3
      var width = this.scene.game.canvas.width;
      var height = this.scene.game.canvas.height;

      /*if (this.scene.registry.list.execmod.worldmap2zoomed != true) {
        this.scene.plog.log("worldmap2 pointerup, not zooming", this.scale, this);
        this.scale = (this.scale * 3);
        this.x = (width / 2) +
          (((zoomposw / 2) - localX) * this.scale);
        this.y = (height / 2) +
          (((zoomposh / 2) - localY) * this.scale);
        //
        / *var tw = wh.tweens.add({
          targets: [this],
          scale: (this.scale * 3),
          //x: ((zoomposw / 2) * this.scale * 3) - (localX*2 * this.scale * 3) * (this.scale * 3),
          //y: ((zoomposh / 2) * this.scale * 3) - (localY*2 * this.scale * 3) * (this.scale * 3),
          ease: 'Power3',
          duration: 10,
          delay: 150,
          onComplete: function () {
            // arguments[1][0].visible = false;
          }, // set context? how?
        });* /
        this.scene.registry.list.execmod.worldmap2zoomed = true;
        return;
      }
        */
      var clickcellx = Math.floor(localX / 10) + 1; //divide by minimap cellsize
      var clickcelly = Math.floor(localY / 10) + 1; //divide by minimap cellsize

      //this.plog.log("worldmap2 clickx",clickcellx,clickcelly,walkablechk);
      //this.plog.log("worldmap2 walk", clickcellx, clickcelly, walkablechk, this.scene);
      var tmpmock = { name: 'bg-' + clickcellx + '-' + clickcelly };
      this.scene.plog.log("worldmap2click", localX, localY)
      var usex = Math.floor(localX / 100);
      var usey = Math.floor(localY / 100);
      var mapdata = this.scene.worldmap2data[usex + "-" + usey];
      if (mapdata != undefined) {
        if (mapdata == "NO") {
          this.scene.registry.list.rctoast.rcalert(this.scene, this.scene.trs("f:exec:Error===Error"),
            this.scene.trs("f:exec:Can't teleport to cursed area or dungeons===Can't teleport to cursed area or dungeons")
          );
        } else {
          var res = await this.scene.registry.list.rchttp.getcommon2("consumestackitem", { usecode: "teleportkey3" })
          this.scene.chkwarppos_warp(this.scene, mapdata);
        }
      };

      this.scene.plog.log("worldmap2click found", mapdata, this.scene.worldmapdata)
      var tw = wh.tweens.add({
        targets: [this, this.scene.worldmap2bg, this.scene.worldmap2alt],
        alpha: 0,
        ease: 'Power3',
        duration: 400, // duration of animation; higher=slower
        delay: 150, // wait 500 ms before starting
        onComplete: function () {
          arguments[1][0].visible = false;
          arguments[1][1].visible = false;
          arguments[1][2].visible = false;
        }, // set context? how?
      });
      //this.visible = false;
      //this.scene.worldmapbg.visible = false;
      //     this.plog.log("worldmapclick",this);;
    });
    /*wh.worldmap.on('pointermove', function (pointer, localX, localY, event) {
      var clickcellx = Math.floor(localX / 10); //divide by minimap cellsize
      var clickcelly = Math.floor(localY / 10); //divide by minimap cellsize
      var mchkx = clickcellx + 1;
      var mchky = clickcelly + 1;

    });*/
    //wh.worldmap.visible = false;
    wh.worldmap2bg.on(
      'pointerup',
      function () {
        if (wh.worldmap_gesturetakecontrol == true) return;
        this.wh.worldma2p.visible = false;
        this.wh.worldmap2alt.visible = false;
        this.wh.worldmap2bg.visible = false;
      },
      this
    );
  }
  async drill1_itempicked(a1, a2) {
    //console.log("drill1_itempicked", a1, a2);
    var iteminfo = this.wh.registry.list.rcvarpass.activechar['item'][a1];
    var iteminfo2 = this.wh.registry.list.rcvarpass.allitem[a2];
    //console.log(iteminfo, iteminfo2);
    if (iteminfo == undefined) return;
    if (iteminfo2 == undefined) return;
    var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Drill gem slot?", "Use Drill I to create the first gem slot in an item: \n" + iteminfo2['name'] + " lv." + iteminfo2['level'] + " +" + iteminfo['itemplus']);
    if (cfm == "ok") {
      var drillres = await this.wh.registry.list.rchttp.getcommon2("drill", { itemid: a1, gemslot: 1 });
    }
  }

  async drill2_itempicked(a1, a2) {
    //console.log("drill1_itempicked", a1, a2);
    var iteminfo = this.wh.registry.list.rcvarpass.activechar['item'][a1];
    var iteminfo2 = this.wh.registry.list.rcvarpass.allitem[a2];
    //console.log(iteminfo, iteminfo2);
    if (iteminfo == undefined) return;
    if (iteminfo2 == undefined) return;
    var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Drill gem slot?", "Use Drill II to create the 2nd gem slot in an item: \n" + iteminfo2['name'] + " lv." + iteminfo2['level'] + " +" + iteminfo['itemplus']);
    if (cfm == "ok") {
      var drillres = await this.wh.registry.list.rchttp.getcommon2("drill", { itemid: a1, gemslot: 2 });
    }
  }

  async drill3_itempicked(a1, a2) {
    //console.log("drill1_itempicked", a1, a2);
    var iteminfo = this.wh.registry.list.rcvarpass.activechar['item'][a1];
    var iteminfo2 = this.wh.registry.list.rcvarpass.allitem[a2];
    //console.log(iteminfo, iteminfo2);
    if (iteminfo == undefined) return;
    if (iteminfo2 == undefined) return;
    var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Drill gem slot?", "Use Drill III to create the 3rd gem slot in an item: \n" + iteminfo2['name'] + " lv." + iteminfo2['level'] + " +" + iteminfo['itemplus']);
    if (cfm == "ok") {
      var drillres = await this.wh.registry.list.rchttp.getcommon2("drill", { itemid: a1, gemslot: 3 });
    }
  }

  async drill4_itempicked(a1, a2) {
    //console.log("drill1_itempicked", a1, a2);
    var iteminfo = this.wh.registry.list.rcvarpass.activechar['item'][a1];
    var iteminfo2 = this.wh.registry.list.rcvarpass.allitem[a2];
    //console.log(iteminfo, iteminfo2);
    if (iteminfo == undefined) return;
    if (iteminfo2 == undefined) return;
    var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Drill gem slot?", "Use Drill IV to create the 4th gem slot in an item: \n" + iteminfo2['name'] + " lv." + iteminfo2['level'] + " +" + iteminfo['itemplus']);
    if (cfm == "ok") {
      var drillres = await this.wh.registry.list.rchttp.getcommon2("drill", { itemid: a1, gemslot: 4 });
    }
  }


  async gemsaddtoitem_itempicked(a1, a2) {
    console.log("gemsaddtoitem_itempicked", a1, a2, this, this.gemsaddtoitem_itempicked_gemcode);
    var iteminfo = this.wh.registry.list.rcvarpass.activechar['item'][a1];
    var iteminfo2 = this.wh.registry.list.rcvarpass.allitem[a2];
    var geminfo = this.wh.registry.list.rcvarpass.allstackitem[this.gemsaddtoitem_itempicked_gemcode];
    //console.log(iteminfo, iteminfo2);
    if (iteminfo == undefined) return;
    if (iteminfo2 == undefined) return;
    if (geminfo == undefined) return;
    var cfm = await this.wh.registry.list.rctoast.selectgemslot(this.wh, "Select Gem Slot", iteminfo, iteminfo2, geminfo);
    if (cfm != "cancel" &&cfm != "") {
      console.log("cfm result", cfm);
      console.log("gemsaddtoitem_itempicked", a1, a2, this, this.gemsaddtoitem_itempicked_gemcode);
      var embedgemres = await this.wh.registry.list.rchttp.getcommon2("gemsaddtoitem", { itemid: a1, gemslot: cfm, gem: this.gemsaddtoitem_itempicked_gemcode });
    }
  }
}
