import { Injectable } from '@angular/core';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class TimeCtlService {
  timerunsec = 0;//start from 1, use to keep log of what last done at what sec
  wh;
  svtime;
  humantime = 0;
  timerun = 0;
  secperround = 3; //increase to speed up time
  timename = "";
  prevtimename = "";
  game_yr = 0;
  game_mn = 0;
  game_dy = 0;
  game_hr = 0;
  tinteddb = [];
  targettint = [];
  tinteddbscreenbg = [];
  targettintscreenbg = [];
  humantimeorig = 0;
  pausetime = false;
  plog;

  constructor() {
    this.plog = new plog();
    //this.plog.log("TimeCtlService constructor", this);
  }
  secondsToDsp(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " D " : " D ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " H " : " H ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " M " : " M ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }
  regettimerunning = false;
  async regettime(wh) {
    if (this.regettimerunning == true) return;
    var isloading = wh.registry.list.rcloading.isloading();
    if (isloading == true) {
      console.log("skip gettime for loading somethingelse");
      return;
    }
    this.regettimerunning = true;
    const clientTimestamp: number = new Date().getTime();
    var tmp = await wh.registry.list.rchttp.getcommon2("gettime", {
      ct: clientTimestamp
    });
    // Parse server-client difference time and server timestamp from response
    const serverClientRequestDiffTime: number = tmp["diff"];
    const serverTimestamp: number = tmp["humantime"];

    // Get current timestamp in milliseconds
    const nowTimeStamp: number = new Date().getTime();

    // Calculate server-client difference time on response and response time
    const serverClientResponseDiffTime: number = nowTimeStamp - serverTimestamp;
    const responseTime: number = (serverClientRequestDiffTime - nowTimeStamp + clientTimestamp - serverClientResponseDiffTime) / 2;

    // Calculate the synced server time
    const syncedServerTime: Date = new Date(nowTimeStamp + (serverClientResponseDiffTime - responseTime));

    var humantimeuse = Math.floor(syncedServerTime.getTime() / 1000)

    this.svtime = tmp["peacelytime"];
    this.humantime = humantimeuse;
    this.humantimeorig = humantimeuse;
    wh.gametimesec = 0;
    this.regettimerunning = false;
  }
  async timefunc(wh, forcetime = "") {
    //return;
    this.wh = wh;
    if (this.pausetime == true) return;
    this.timerun++;
    //this.timerun+=10;

    //this.plog.log("TimeCtlService timefunc", arguments);
    if (this.svtime == undefined) {
      var isloading = wh.registry.list.rcloading.isloading();
      if (isloading == true) {
        console.log("skip gettime for loading somethingelse");
        return;
      }
      if (this.regettimerunning == true) return;
      if (wh.registry.list.rcvarpass.rcserver_url == undefined) return; // not logged in
      const clientTimestamp: number = new Date().getTime();
      var tmp = await wh.registry.list.rchttp.getcommon2("gettime", {
        ct: clientTimestamp
      });
      // Parse server-client difference time and server timestamp from response
      const serverClientRequestDiffTime: number = tmp["diff"];
      const serverTimestamp: number = tmp["humantime"];

      // Get current timestamp in milliseconds
      const nowTimeStamp: number = new Date().getTime();

      // Calculate server-client difference time on response and response time
      const serverClientResponseDiffTime: number = nowTimeStamp - serverTimestamp;
      const responseTime: number = (serverClientRequestDiffTime - nowTimeStamp + clientTimestamp - serverClientResponseDiffTime) / 2;

      // Calculate the synced server time 
      const syncedServerTime: Date = new Date(nowTimeStamp + (serverClientResponseDiffTime - responseTime));

      var humantimeuse = Math.floor(syncedServerTime.getTime() / 1000)

      this.svtime = tmp["peacelytime"];
      this.humantime = humantimeuse;
      this.humantimeorig = humantimeuse;
      wh.gametimesec = 0;
      //this.plog.log("TimeCtlService gettime", this.svtime);
      return;
    }
    this.svtime = this.svtime + this.secperround;
    this.humantime = this.humantime + this.secperround;
    var yearlen = (60 * 60 * 24 * 7);//week
    this.game_yr = Math.floor(this.svtime / yearlen);
    //this.plog.log("TimeCtlService svtime", this.svtime);
    //this.plog.log("TimeCtlService yearlen", yearlen, "game_yr", this.game_yr);
    var monthlen = (60 * 60 * 24);//day
    var secleft = this.svtime - (yearlen * this.game_yr);
    this.game_mn = Math.floor(secleft / monthlen);
    //this.plog.log("TimeCtlService monthlen", monthlen, "game_mn", this.game_mn);
    var daylen = (60 * 5);//5 min
    var secleft = this.svtime - ((yearlen * this.game_yr) + (monthlen * this.game_mn));
    this.game_dy = Math.floor(secleft / daylen);
    //this.plog.log("TimeCtlService daylen", daylen, "game_dy", this.game_dy);
    var hourlen = (15);//30 sec
    var secleft = this.svtime - ((yearlen * this.game_yr) + (monthlen * this.game_mn) + (daylen * this.game_dy));
    this.game_hr = Math.floor(secleft / hourlen);
    //this.plog.log("TimeCtlService hourlen", hourlen, "game_hr", this.game_hr);
    if (wh.clock_hh != undefined) {
      wh.clock_hh.setText(this.game_hr);
    }
    this.timename = "morning";
    if (this.game_hr >= 2) this.timename = "day";
    if (this.game_hr >= 8) this.timename = "evening";
    if (this.game_hr >= 11) this.timename = "dusk";
    if (this.game_hr >= 12) this.timename = "night";
    if (this.game_hr >= 18) this.timename = "morning";
    if (forcetime != "") this.timename = forcetime;
    //this.timename="night";///
    if (this.wh.registry.list.rcvarpass.activemap != undefined && this.wh.registry.list.rcvarpass.activemap["map"] != undefined && this.wh.registry.list.rcvarpass.activemap["map"]["fixedtime"] != undefined && this.wh.registry.list.rcvarpass.activemap["map"]["fixedtime"] != "") {
      this.timename = this.wh.registry.list.rcvarpass.activemap["map"]["fixedtime"];
    }
    if (this.timename != this.prevtimename) {
      //timename change
      var timetext = "";
      switch (this.timename) {
        case 'day':
          timetext = this.wh.trs("f:timectl:Day Time===Day Time");
          break;
        case 'evening':
          timetext = this.wh.trs("f:timectl:Evening===Evening");
          break;
        case 'dusk':
          timetext = this.wh.trs("f:timectl:Dusk===Dusk");
          break;
        case 'night':
          timetext = this.wh.trs("f:timectl:Night Time===Night Time");
          break;
        case 'morning':
          timetext = this.wh.trs("f:timectl:Morning===Morning");
          break;

        default:
          break;
      }
      this.wh.registry.list.gameitf.gamemenutooltip(this.wh, this.wh.clock_bg, timetext, "left");
      this.prevtimename = this.timename;
      //this.plog.log("TIMECHANGE to:", this.timename,wh.maplayers);
      //this.plog.log("TIMECHANGE to:", this.timename,this);
      this.timechange(this.timename);

      //this.plog.log("this.chkconfiglighteffects", this.wh);
      //if (this.wh.registry.list.thethis != undefined && this.wh.registry.list.thethis.chkconfiglighteffects != undefined && this.wh.registry.list.thethis.chkconfiglighteffects == "yes") {
      for (const k in wh.maplayers) {
        if (this.tinteddb[k] == undefined) {
          this.tinteddb[k] = 0xffffff;
          this.targettint[k] = 0xffffff;
        }
      }
      for (const k in wh.screenbg) {
        if (this.tinteddbscreenbg[k] == undefined) {
          this.tinteddbscreenbg[k] = 0xffffff;
          this.targettintscreenbg[k] = 0xffffff;
        }
      }

      for (const k in wh.maplayers) {
        var v = wh.maplayers[k];
        if (this.timename == "day") {
          this.targettint[k] = 0xffffff;
          if (this.tinteddb[k] == undefined) this.tinteddb[k] = 0xffffff;
        }
        if (this.timename == "morning") {
          this.targettint[k] = 0xaaaaff;
          if (this.tinteddb[k] == undefined) this.tinteddb[k] = 0xffffff;
        }
        if (this.timename == "evening") {
          this.targettint[k] = 0xffff99;
          if (this.tinteddb[k] == undefined) this.tinteddb[k] = 0xffffff;
        }
        if (this.timename == "dusk") {
          this.targettint[k] = 0xff7777;
          if (this.tinteddb[k] == undefined) this.tinteddb[k] = 0xffffff;
        }
        if (this.timename == "night") {
          this.targettint[k] = 0x7777ff;
          if (this.tinteddb[k] == undefined) this.tinteddb[k] = 0xffffff;
        }

        var tween = wh.tweens.addCounter({
          from: 0, to: 20,
          ease: 'Power1',
          duration: 2000,
          repeat: 0,
          onComplete: function () {
            //this.plog.log("tween complete", this, arguments);
            for (const k in arguments[0].parent.scene.registry.list.timectl.wh.maplayers) {
              arguments[0].parent.scene.registry.list.timectl.tinteddb[k] = arguments[0].parent.scene.registry.list.timectl.targettint[k];
            }
          },
          onUpdate: function () {
            //this.plog.log("TIMECHANGE onupdate this", arguments);
            for (const k in arguments[0].parent.scene.registry.list.timectl.wh.maplayers) {
              if (arguments[0].parent.scene.registry.list.timectl.tinteddb[k] == undefined) continue;
              if (arguments[0].parent.scene.registry.list.timectl.targettint[k] == undefined) continue;
              var v = arguments[0].parent.scene.registry.list.timectl.wh.maplayers[k];
              const value = Math.floor(tween.getValue());
              var rgb = Phaser.Display.Color.IntegerToRGB(v.tintTopLeft);
              //this.plog.log("TIMECHANGE onupdate", Phaser.Display.Color.ValueToColor(arguments[0].parent.scene.registry.list.timectl.tinteddb[k]), Phaser.Display.Color.ValueToColor(arguments[0].parent.scene.registry.list.timectl.targettint[k]), value, k, tint );
              var tint = Phaser.Display.Color.Interpolate.ColorWithColor(
                Phaser.Display.Color.ValueToColor(arguments[0].parent.scene.registry.list.timectl.tinteddb[k]),
                Phaser.Display.Color.ValueToColor(arguments[0].parent.scene.registry.list.timectl.targettint[k]),
                20,
                value
              );
              var coloruse = Phaser.Display.Color.GetColor(tint.r, tint.g, tint.b);

              v.setTint(coloruse);
            }
          }
        });
      }
      //screenbg
      for (const k in wh.screenbg) {
        var v = wh.screenbg[k];
        if (this.timename == "day") {
          this.targettintscreenbg[k] = 0xffffff;
          if (this.tinteddbscreenbg[k] == undefined) this.tinteddbscreenbg[k] = 0xffffff;
        }
        if (this.timename == "morning") {
          this.targettintscreenbg[k] = 0xaaaaff;
          if (this.tinteddbscreenbg[k] == undefined) this.tinteddbscreenbg[k] = 0xffffff;
        }
        if (this.timename == "evening") {
          this.targettintscreenbg[k] = 0xffff99;
          if (this.tinteddbscreenbg[k] == undefined) this.tinteddbscreenbg[k] = 0xffffff;
        }
        if (this.timename == "dusk") {
          this.targettintscreenbg[k] = 0xff7777;
          if (this.tinteddbscreenbg[k] == undefined) this.tinteddbscreenbg[k] = 0xffffff;
        }
        if (this.timename == "night") {
          this.targettintscreenbg[k] = 0x7777ff;
          if (this.tinteddbscreenbg[k] == undefined) this.tinteddbscreenbg[k] = 0xffffff;
        }

        var tween = wh.tweens.addCounter({
          from: 0, to: 20,
          ease: 'Power1',
          duration: 3000,
          repeat: 0,
          onComplete: function () {
            //this.plog.log("tween complete", this, arguments);
            for (const k in arguments[0].parent.scene.registry.list.timectl.wh.screenbg) {
              arguments[0].parent.scene.registry.list.timectl.tinteddbscreenbg[k] = arguments[0].parent.scene.registry.list.timectl.targettintscreenbg[k];
            }
          },
          onUpdate: function () {
            //this.plog.log("TIMECHANGE onupdate this", arguments);
            for (const k in arguments[0].parent.scene.registry.list.timectl.wh.screenbg) {
              if (arguments[0].parent.scene.registry.list.timectl.tinteddbscreenbg[k] == undefined) continue;
              if (arguments[0].parent.scene.registry.list.timectl.targettintscreenbg[k] == undefined) continue;
              var v = arguments[0].parent.scene.registry.list.timectl.wh.screenbg[k];
              const value = Math.floor(tween.getValue());
              var rgb = Phaser.Display.Color.IntegerToRGB(v.tintTopLeft);
              //this.plog.log("TIMECHANGE onupdate", Phaser.Display.Color.ValueToColor(arguments[0].parent.scene.registry.list.timectl.tinteddb[k]), Phaser.Display.Color.ValueToColor(arguments[0].parent.scene.registry.list.timectl.targettint[k]), value, k, tint );
              var tint = Phaser.Display.Color.Interpolate.ColorWithColor(
                Phaser.Display.Color.ValueToColor(arguments[0].parent.scene.registry.list.timectl.tinteddbscreenbg[k]),
                Phaser.Display.Color.ValueToColor(arguments[0].parent.scene.registry.list.timectl.targettintscreenbg[k]),
                20,
                value
              );
              var coloruse = Phaser.Display.Color.GetColor(tint.r, tint.g, tint.b);

              v.setTint(coloruse);
            }
          }
        });
      }
      //}
    }
    if (wh.clock_bg != undefined) {
      var radius = wh.clock_bg.displayHeight / 2;
      var cX = wh.clock_bg.x;
      var cY = wh.clock_bg.y;
      var theta = Phaser.Math.DegToRad(0 - (this.game_hr * 18));
      //this.plog.log("TIMECHANGE--",this.game_hr,(this.game_hr * 18),theta);
      var newX = Math.sin(theta) * radius;
      var newY = Math.cos(theta) * radius;
      if (wh.clock_dot != undefined) {
        wh.clock_dot.x = cX - newX;
        wh.clock_dot.y = cY - newY;
      }
    }
  }
  showymd() {

    var timetextn = "";
    switch (this.timename) {
      case 'day':
        timetextn = this.wh.trs("f:timectl:Day Time===Day Time");
        break;
      //case 'day':
      //  timetextn = this.wh.gl("Evening");
      //  break;
      case 'dusk':
        timetextn = this.wh.trs("f:timectl:Dusk===Dusk");
        break;
      case 'night':
        timetextn = this.wh.trs("f:timectl:Night Time===Night Time");
        break;
      case 'morning':
        timetextn = this.wh.trs("f:timectl:Morning===Morning");
        break;

      default:
        break;
    }
    var timetext = this.wh.trs("f:timectl:Year===Year") + " " + this.game_yr + " " + this.wh.trs("f:timectl:month===month") + " " + this.game_mn + " " + this.wh.trs("f:timectl:day===day") + " " + this.game_dy + "\n" + this.wh.trs("f:timectl:Hour===Hour") + " " + this.game_hr + " " + timetextn;

    this.wh.registry.list.gameitf.gamemenutooltip(this.wh, this.wh.clock_bg, timetext, "left");

  }
  timefunc1sec() {
    this.timerunsec++;
  }
  async timechange(timename = '', wh = undefined) {
    //timename="night";
    if (wh != undefined) this.wh = wh;
    if (this.wh == undefined) return;
    var prevtime = this.wh.registry.list.timectl
      .timename;
    this.plog.log('PHM timechange from', prevtime, 'to', timename);
    //this.plog.log('PHM timechange from', prevtime, 'to', timename, this);
    this.wh.registry.list.gameitf.updatenpcvisiblereq(this.wh);
    var chkconfiglighteffects =
      await this.wh.registry.list.rcstorage.get(
        'setting_lighteffects', true
      );
    if (chkconfiglighteffects == 'no') {
      this.plog.log('chkconfiglighteffects disabled by settings');
      return;
    }
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    if (timename == '') {
      timename =
        this.wh.registry.list.timectl
          .timename;
      //this.plog.log("PHM timechange-no timename", timename);
      if (timename != 'night') {
        //this.plog.log("PHM timechange - not night hideing");
        for (const k2 in this.wh
          .zaninightlist) {
          var v2 = this.wh.zaninightlist[k2];
          v2.scale = 0;
          //this.plog.log("PHM timechange - not night hideing", v2);
        }
        this.wh.registry.list.timectl.timename =
          '';
        this.wh.registry.list.timectl.timefunc(
          this.wh
        );

        if (this.wh.fow != undefined) {
          this.wh.fow.setAlpha(1);//0.8
        }
      } else {

        if (this.wh.fow != undefined) {
          this.wh.fow.setAlpha(1);
        }
      }
    }
    //this.plog.log("REMOVING LIGHT2D out", timename);



    if (timename == 'morning') {
      //this.wh.registry.list['gameitf'].mapfadeout(this.wh, 1200);
      setTimeout(() => {
        this.wh.lights.setAmbientColor(0x505050);

        for (const k in this.wh.maplayers) {
          var v = this.wh.maplayers[k];
          //v.setPipeline("TextureTintPipeline");
          //v.resetPipeline(true, true);
        }
        /*for (const k in this.wh.screenbg) {
          var v = this.wh.screenbg[k];
          //v.setPipeline("TextureTintPipeline");
          if (v != undefined) {
            v.resetPipeline(true, true);
          }
        }*/
        for (const k in this.wh.mobs) {
          var v = this.wh.mobs[k];
          //v.setPipeline("TextureTintPipeline");
          if (v.sprite != undefined) {
            //v.sprite.resetPipeline(true, true);
          }
        }
        for (const k in this.wh.zsortlist) {
          var v = this.wh.zsortlist[k];
          //v.setPipeline("TextureTintPipeline");
          if (v == undefined) continue;
          if (v.sprite != undefined) {
            //v.sprite.resetPipeline(true, true);
          }
          //v.resetPipeline(true, true);
        }

        //this.wh.biglightsource.setIntensity(0);
        //this.wh.biglightsource2.setIntensity(0);
        /*var tween =
          this.wh.tweens.addCounter({
            from: 10,
            to: 30,
            ease: 'Power1',
            duration: 500,
            repeat: 0,
            onComplete: function () {
              //this.plog.log("tween complete", this, arguments);
            },
            onUpdate: function () {
              const value = Math.floor(arguments[1].value);
              //this.plog.log("out of night", value, arguments[1].value)
              arguments[0].parent.scene.biglightsource.setIntensity(
                (value / 10) * 1
              );
            },
          });*/
      }, 600);
    }

    if (timename == 'night') {
      for (const k2 in this.wh
        .zaninightlist) {
        var v2 = this.wh.zaninightlist[k2];
        this.plog.log('zan', v2);
        //v2.setBlendMode(Phaser.BlendModes.DIFFERENCE);
        var tween = this.wh.tweens.add({
          targets: v2,
          scale: 1,
          ease: 'Power1',
          duration: 500,
          yoyo: false,
        });
      }
      /*for (var key in this.rcvarsservice.activemap) {
        if (key == "zaninight") {
          var value = this.rcvarsservice.activemap[key];
          this.plog.log("night zan",value);
        }
      }*/
    }
    if (timename == 'morning' || timename == 'day' || timename == 'evening') {
      for (const k2 in this.wh.zaninightlist) {
        var v2 = this.wh.zaninightlist[k2];
        //this.plog.log("zan", v2);
        var tween = this.wh.tweens.add({
          targets: v2,
          scale: 0,
          ease: 'Power1',
          duration: 500,
          yoyo: false,
        });
      }
      /*for (var key in this.rcvarsservice.activemap) {
        if (key == "zaninight") {
          var value = this.rcvarsservice.activemap[key];
          this.plog.log("night zan",value);
        }
      }*/
    }
  }
  async initmaplight(wh) {
    //console.log("initmaplight", wh);
    if (this.wh == undefined) this.wh = wh;
    if (this.wh.registry.list.thethis != undefined && this.wh.registry.list.thethis.chkconfiglighteffects != undefined && this.wh.registry.list.thethis.chkconfiglighteffects != "yes") {
      return;
    }
    if (this.wh.maplayers == undefined) {
      console.log("timectl initmaplight return, maplayers undefined");
      return;
    }
    if (this.wh.maplayers != undefined) {
      for (const k in this.wh.maplayers) {
        try {
          this.wh.maplayers[k].setPipeline("Light2D");
        } catch (e) {
          this.plog.log("initmap_preloaddone_initfow setPipeline error", e);
        }
        //console.log("setting pipeline", k);
      }
    }

    for (const k in this.wh.npclist) {
      try {
        await this.wh.npclist[k].setPipeline("Light2D");
        //console.log("setting npclist pipeline", k);
      } catch (error) {
        ///this.plog.log("ERROR: destroy npclist sub error", error);
      }
    }
    for (const k in this.wh.mapmob) {
      try {
        await this.wh.mapmob[k].sprite.setPipeline("Light2D");
        //console.log("setting npclist pipeline", k);
      } catch (error) {
        ///this.plog.log("ERROR: destroy npclist sub error", error);
      }
    }
    for (const k in this.wh.mobs) {
      try {
        await this.wh.mobs[k].sprite.setPipeline("Light2D");
        //console.log("setting npclist pipeline", k);
      } catch (error) {
        ///this.plog.log("ERROR: destroy npclist sub error", error);
      }
    }


    this.wh.lights.enable();
    //console.log("lights.enable()");


    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;

    var lightsize1 = 10; //day/normal
    if (this.timename == "night") lightsize1 = 1;

    if (this.wh.biglightsource == undefined && this.wh.maplayers != undefined && this.wh.maplayers["bg"] != undefined) {
      this.wh.biglightsource =
        this.wh.lights.addLight(
          this.wh.maplayers['bg'].displayWidth / 2,
          this.wh.maplayers['bg'].displayHeight / 2,
          Math.min(width, height) * lightsize1
        );
    }

    //fade in light

    if (this.wh.biglightsource2 == undefined && this.wh.maplayers != undefined && this.wh.maplayers["bg"] != undefined) {
      this.wh.biglightsource2 =
        this.wh.lights.addLight(
          this.wh.maplayers['bg'].displayWidth / 2,
          this.wh.maplayers['bg'].displayHeight / 2,
          Math.min(width / 2, height / 2)
        );
    }
    try {
      if (this.wh.biglightsource != undefined)
        this.wh.biglightsource.setIntensity(0.5);
      if (this.wh.biglightsource2 != undefined)
        this.wh.biglightsource2.setIntensity(1);
    } catch (e) {
      console.log(e);
    }
    this.setlightpos();
  }
  setlightpos() {
    if (this.wh.registry.list.thethis != undefined && this.wh.registry.list.thethis.chkconfiglighteffects != undefined && this.wh.registry.list.thethis.chkconfiglighteffects != "yes") {
      return;
    }
    var lightsize1 = 20; //day/normal
    if (this.timename == "night") lightsize1 = 1;
    try {
      var width = this.wh.game.canvas.width;
      var height = this.wh.game.canvas.height;
    } catch (e) {
      console.log(e, this);
    }
    //console.log("setlightpos",this.wh.biglightsource,this.wh.char_main);
    if (this.wh.char_main != undefined && this.wh.biglightsource != undefined && this.wh.maplayers != undefined && this.wh.maplayers["bg"] != undefined) {
      this.wh.biglightsource.setPosition(this.wh.char_main.x, this.wh.char_main.y);
      this.wh.biglightsource2.setPosition(this.wh.char_main.x, this.wh.char_main.y);
      this.wh.biglightsource.setRadius(Math.min(width, height) * lightsize1);
    }
  }
}
