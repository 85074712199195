import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class QuestListService {
  shopdragbtn;
  title;

  shopbackbtn;
  shopfwdbtn;

  currentmode = "";

  resptotalpage;
  respperpage = 6;
  currentresppage = 1;

  sharebtn;
  qlistbtn = [];
  qlistdescr = [];
  qlisttitle = [];
  qlistbg = [];

  dwidescr_weekly;
  wwdata;
  dwichest_weekly;
  wwtitle = [];
  wwdescr = [];
  wwicon = [];
  wwbtn = [];
  //graphics;
  dddata;
  ddtitle = [];
  dddescr = [];
  ddicon = [];
  ddbtn = [];
  dwidescr_daily;
  dwichest_daily;
  countquest;
  questlistcontainer_char;
  // maskm;
  selectcircle;
  scrollareabox;
  shopclosebtn;
  itemdescr;
  eqitems = [];
  eqitemsp = [];
  itemwh = 54;
  shopguibg;
  wh;
  menubaritem = [];
  btnstory;
  btndaily;
  btnweekly;
  loading;
  empty;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
  }

  firsttimerun = true;
  async firsttimerunfunc() {

    if (this.firsttimerun == true) {
      await this.rchttp.updatecharinfo();
      //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(        this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer      );
    }
    this.firsttimerun = false;
  }

  loadingshow() {
    this.wh.questlistcontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  async show(wh, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;

    this.wh = wh;
    /*if (wh.questlistcontainer != undefined) {
      wh.questlistcontainer.visible = true;
      this.show_loaded();
      return;
    }*/
    if (this.wh.questlistcontainer != undefined) {
      await this.show_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('equipment show', wh, this);

    const shopguibg = wh.add.image(0, 0, 'blankbarguibg');//questlistguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    //this.plog.log("questgui var", this.rcvarsservice);

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );

    wh.questlistcontainer = wh.add.container(300, 300);
    wh.questlistcontainer._refsrv = this;
    wh.questlistcontainer.visible = false;
    wh.questlistcontainer.name = "questlistcontainer";
    wh.questlistcontainer.add(this.shopclosebtn);
    wh.questlistcontainer.add(shopguibg);
    wh.questlistcontainer.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, '', {
      fontFamily: 'ffff',
      fontSize: '10px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    wh.questlistcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;

    this.btnstory = wh.add.sprite(0, 0, "thesb", "story");//wh.add.image(0, 0, 'storyquestbtn');
    this.btnstory.setOrigin(0.5, 0.5);
    this.btnstory.setInteractive();
    this.btnstory.removeAllListeners();
    wh.questlistcontainer.add(this.btnstory);
    wh.questlistcontainer.bringToTop(this.btnstory);
    this.btnstory.displayWidth = 100;
    this.btnstory.scaleY = this.btnstory.scaleX;
    this.btnstory.setPosition(-100, -155);
    this.btnstory.on(
      'pointerup',
      function () {
        this.show_loaded();
        this.wh.registry.list.snd.snd("btn2");
      },
      this
    );

    this.btndaily = wh.add.sprite(0, 0, "thesb", "daily");//wh.add.image(0, 0, 'dailyquestbtn');
    this.btndaily.setOrigin(0.5, 0.5);
    this.btndaily.setInteractive();
    this.btndaily.removeAllListeners();
    wh.questlistcontainer.add(this.btndaily);
    wh.questlistcontainer.bringToTop(this.btndaily);
    this.btndaily.displayWidth = 100;
    this.btndaily.scaleY = this.btndaily.scaleX;
    this.btndaily.setPosition(0, -155);
    this.btndaily.on(
      'pointerup',
      function () {
        this.daily_loaded();
        this.wh.registry.list.snd.snd("btn2");
      },
      this
    );

    this.btnweekly = wh.add.sprite(0, 0, "thesb", "weekly");//wh.add.image(0, 0, 'weeklyquestbtn');
    this.btnweekly.setOrigin(0.5, 0.5);
    this.btnweekly.setInteractive();
    this.btnweekly.removeAllListeners();
    wh.questlistcontainer.add(this.btnweekly);
    wh.questlistcontainer.bringToTop(this.btnweekly);
    this.btnweekly.displayWidth = 100;
    this.btnweekly.scaleY = this.btnweekly.scaleX;
    this.btnweekly.setPosition(100, -155);
    this.btnweekly.on(
      'pointerup',
      function () {
        this.weekly_loaded();
        this.wh.registry.list.snd.snd("btn2");
      },
      this
    );

    this.empty = this.wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 0);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    this.wh.questlistcontainer.add(this.empty);
    this.wh.questlistcontainer.bringToTop(this.empty);
    this.empty.visible = false;

    //drag
    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.y =
            tmpy - movedy;
          //this.maskg.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.x,this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.y)
          //this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.y = pointer.y;
          //this.setmaskposition();
        }
      },
      this
    );
    wh.questlistcontainer.add(this.shopdragbtn);
    wh.questlistcontainer.bringToTop(this.shopdragbtn);
    this.menubaritem.push(this.shopdragbtn);
    //skilltype bar
    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("questlistcontainer", wh.questlistcontainer, width, height);
    wh.questlistcontainer.setPosition(width / 2, height / 2);
    let loader = new Phaser.Loader.LoaderPlugin(wh);

    //move
    wh.questlistcontainer.x = width - this.shopguibg.width / 2 + 20;

    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.questlistcontainer.add(this.loading);
    wh.questlistcontainer.bringToTop(this.loading);


    this.title = wh.add.text(0, 0, 'QUEST LIST', wh.fs["winguititle"]);
    this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.questlistcontainer.add(this.title);
    wh.questlistcontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -223);

    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    var loadlist = ['questitem', 'questitemg'];
    for (var k in loadlist) {
      var key = loadlist[k];
      if (this.rcvarsservice.gameobj.textures.game.textures.list[key] == undefined) {

        loader.atlas(
          key + '',
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.png' +
          svversionurlstring,
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.json' +
          svversionurlstring
        );
      }
    }

    //this.setmaskposition();
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.show_loaded();
      },
      this
    );

    this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer);

    loader.start();
  }

  /*setmaskposition() {
    if (this.maskm != undefined) {
      this.maskm.geometryMask.setPosition(
        this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.x + 50,
        this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer.y - 130
      );
    } else {
      this.plog.log("setmaskposition maskm not defined");
    }
    //this.maskm.geometryMask.displayWidth=500;
  }*/
  async buildquestctnr(iscallfromgameitf = false) {
    this.plog.log('buildquestctnr');
    if (this.wh.questlistcontainer_char != undefined) {
      this.wh.questlistcontainer_char.destroy();
    }
    var width = this.wh.game.canvas.width;
    var height = this.wh.game.canvas.height;
    this.wh.questlistcontainer_char = this.wh.add.container(0, 0);
    this.wh.questlistcontainer.add(this.wh.questlistcontainer_char);


    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.qlistbg[i] != undefined) {
        this.qlistbg[i].destroy();
      }
      if (this.qlistbtn[i] != undefined) {
        this.qlistbtn[i].destroy();
      }
      if (this.qlistdescr[i] != undefined) {
        this.qlistdescr[i].destroy();
      }
      if (this.qlisttitle[i] != undefined) {
        this.qlisttitle[i].destroy();
      }
    }
    if (this.shopbackbtn != undefined) {
      this.shopbackbtn.destroy();
    }
    if (this.shopfwdbtn != undefined) {
      this.shopfwdbtn.destroy();
    }

    this.shopbackbtn = this.wh.add.sprite(0, 0, "thesb", "backsign");//this.wh.add.image(0, 0, 'listbackbtn');
    this.shopbackbtn.setOrigin(0.5, 0.5);
    this.shopbackbtn.setInteractive();
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn = this.wh.add.sprite(0, 0, "thesb", "forwardsign");//this.wh.add.image(0, 0, 'listfwdbtn');
    this.shopfwdbtn.setOrigin(0.5, 0.5);
    this.shopfwdbtn.setInteractive();
    this.shopfwdbtn.removeAllListeners();


    this.shopbackbtn.displayWidth = 100;
    this.shopfwdbtn.displayWidth = 100;
    this.shopbackbtn.displayHeight = 40;
    this.shopfwdbtn.displayHeight = 40;
    this.wh.questlistcontainer_char.add(this.shopfwdbtn);
    this.wh.questlistcontainer_char.add(this.shopbackbtn);
    this.wh.questlistcontainer_char.bringToTop(this.shopfwdbtn);
    this.wh.questlistcontainer_char.bringToTop(this.shopbackbtn);
    this.shopbackbtn.setPosition(-110, 50);
    this.shopfwdbtn.setPosition(110, 50);

    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(this.wh, 'back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(this.wh, 'next');
      },
      this
    );
    //this.wh.questlistcontainer_char.setMask(this.maskm);
    //this.setmaskposition();

    var dat;
    dat = this.rcvarsservice.activechar['quest'];
    this.countquest = 0;
    for (const k in this.rcvarsservice.activechar['quest']) {
      var v = this.rcvarsservice.activechar['quest'][k];
      this.countquest++;
    }

    if (this.countquest > 6) {
      //pages
      this.respperpage = 6;
      this.resptotalpage = Math.ceil(this.countquest / this.respperpage);
    }
    if (this.countquest == 0) {
      this.empty.visible = true;
    } else {
      this.empty.visible = false;
    }
    this.plog.log('xxxcountquest', this.countquest);
    //this.questlistcontainer_char = this.wh.questlistcontainer_char;
    this.wh.questlistcontainer_char.setPosition(0, 0);
    var i = 0;
    var rowh = 50;
    var iconwh = 40;
    var rowi = 0;
    var ii = 0;
    var hadjust = -250;
    var vadjust = -135;
    for (const k in this.rcvarsservice.activechar['quest']) {
      var qdat = this.wh.registry.list.rcvarpass.allquest[v['quest']];
      //var qdatmy=this.rcvarsservice.activechar['quest'][k];
      if (qdat == undefined) {
        //reloadquestlist
        var sv;
        sv = await this.rchttp.getcommon('allquest');
        this.rcvarsservice.allquest = sv.data;
      }
    }
    for (const k in this.rcvarsservice.activechar['quest']) {
      var v = dat[k];
      this.plog.log('questlist', k, v);
      this.qlistbg[ii] = this.wh.add.image(0, 0, 'rowbg');
      this.wh.questlistcontainer_char.add(this.qlistbg[ii]);
      this.qlistbg[ii].setOrigin(0.5, 0.5);
      this.qlistbg[ii].setInteractive();
      this.qlistbg[ii].removeAllListeners();
      this.qlistbg[ii].name = v['quest'];
      var qdat = this.wh.registry.list.rcvarpass.allquest[v['quest']];

      ///var qdat = this.wh.registry.list.rcvarpass.allquest["bakepie1"];
      this.plog.log('questlist', qdat);
      /*
      bg.on('pointermove', function (pointer) {
        this.plog.log(this);
        //var pos = this.parentContainer.y + this.y;
        //this.plog.log("pos", pos);
        //if (pos > -150) {
        if (pointer.isDown) {
          this.scene.registry.list.questlist.questlistcontainer_char.y +=
            pointer.velocity.y / 3;
          this.scene.registry.list.questlist.questlistcontainer_char.y =
            Phaser.Math.Clamp(
              this.scene.registry.list.questlist.questlistcontainer_char.y,
              0,
              430 - this.scene.registry.list.questlist.countquest * 50
            );
          //this.plog.log("xxxskill in container", 0-(this.countquest*50),this.questlistcontainer_char.y);
        }
        //}
      });
      */
      this.qlistbg[ii].on('pointerup', function (pointer) {
        this.scene.plog.log('xxx,skill,', this);
        var tmpnamea = this.name.split('---');
        this.scene.registry.list.questlist.loadingshow();
        this.scene.registry.list.questinfo.show(this.scene, this.name, true);
        this.scene.registry.list.questlist.loadinghide();
      });

      this.qlistbg[ii].setPosition(-20 + vadjust + (this.qlistbg[ii].displayWidth / 2), rowi * rowh - rowh / 2 + 5 + hadjust + (this.qlistbg[ii].displayHeight / 2));
      this.qlistbg[ii].displayHeight = iconwh;
      this.qlistbg[ii].displayWidth = 300;
      this.wh.questlistcontainer_char.sendToBack(this.qlistbg[ii]);
      this.qlisttitle[ii] = this.wh.add.text(
        0,
        0,
        this.wh.gl(qdat['name']),
        this.wh.fs["winguilist_title"]
      );
      this.wh.questlistcontainer_char.add(this.qlisttitle[ii]);
      this.wh.questlistcontainer_char.bringToTop(this.qlisttitle[ii]);
      this.qlisttitle[ii].setPosition(30 + vadjust, rowi * rowh - 29 + hadjust);

      this.qlistbtn[ii] = this.wh.add.sprite(0, 0, "thesq", "questd");
      this.qlistbtn[ii].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;

      this.wh.questlistcontainer_char.add(this.qlistbtn[ii]);
      this.wh.questlistcontainer_char.bringToTop(this.qlistbtn[ii]);
      this.qlistbtn[ii].setPosition(10 + vadjust, rowi * rowh - 29 + hadjust + 26);
      this.qlistbtn[ii].displayWidth = this.qlistbtn[ii].displayHeight = 25;
      //console.log(v);
      if (v["thestep"] != undefined && this.wh.registry.list.rcreq.chk(v["thestep"]["requirements"])) {
        this.qlistbtn[ii].setTexture("thesq", "quest");
      }
      //tmpskname.setResolution(0.25);
      this.plog.log('zzzzzz', this.qlisttitle[ii]);

      this.qlistdescr[ii] = this.wh.add.text(0, 0, ' ' + v['dt'], this.wh.fs["winguilist_descr"]);
      this.wh.questlistcontainer_char.add(this.qlistdescr[ii]);
      this.wh.questlistcontainer_char.bringToTop(this.qlistdescr[ii]);
      this.qlistdescr[ii].setPosition(30 + vadjust, rowi * rowh - 5 + hadjust);

      i++;
      rowi++;
      ii++;
      if (rowi == 6) rowi = 0;

    }
    this.wh.questlistcontainer_char.y = 150;
    this.showinventory_showpage(this.wh, "back");

    //this.plog.log('xxxmask', this.maskm);
  }

  showinventory_showpage(gc, viewpage) {

    this.wh.questlistcontainer_char.bringToTop(this.shopfwdbtn);
    this.wh.questlistcontainer_char.bringToTop(this.shopbackbtn);
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.qlistbg[i] != undefined) {
        this.qlistbg[i].visible = false;
      }
      if (this.qlistbtn[i] != undefined) {
        this.qlistbtn[i].visible = false;
      }
      if (this.qlistdescr[i] != undefined) {
        this.qlistdescr[i].visible = false;
      }
      if (this.qlisttitle[i] != undefined) {
        this.qlisttitle[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );

    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);
    griddat.push(this.btnstory);
    griddat.push(this.btndaily);
    griddat.push(this.btnweekly);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);


    for (var i = numfrom; i < numto; i++) {
      if (this.qlistbg[i] != undefined) {
        this.qlistbg[i].visible = true;
        this.qlistbtn[i].visible = true;
        this.qlistdescr[i].visible = true;
        this.qlisttitle[i].visible = true;

        griddat.push(this.qlistbg[i]);
        griddatf.push(this.qlistbg[i]);
      }
    }
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }
  async daily_loaded(iscallfromgameitf = false) {
    this.empty.visible = false;
    this.currentmode = "daily"
    this.plog.log('daily_loaded', this.wh, this.wh.questlistcontainer_char);
    if (this.wh.questlistcontainer_char != undefined) {
      //this.wh.questlistcontainer_char.destroy();true
      //this.wh.questlistcontainer_char = undefined;
      //this.wh.questlistcontainer_char.visible=false;
      this.wh.questlistcontainer_char.setVisible(false);
      this.wh.questlistcontainer_char.destroy(false);
      //this.graphics.clear();
      // this.wh.questlistcontainer.remove(this.wh.questlistcontainer_char);
      //this.wh.questlistcontainer.sendToBack(this.wh.questlistcontainer_char);
      //this.wh.questlistcontainer_char.setVisible(false);
      //this.wh.questlistcontainer_char.destroy(false);
    }
    if (this.wh.questlistcontainer_daily != undefined) {
      this.wh.questlistcontainer_daily.destroy();
      this.wh.questlistcontainer_daily = undefined;
    }
    if (this.wh.questlistcontainer_daily == undefined) {
      this.wh.questlistcontainer_daily = this.wh.add.container(0, 0);
      this.wh.questlistcontainer.add(this.wh.questlistcontainer_daily);
    }
    if (this.wh.questlistcontainer_char != undefined)
      this.wh.questlistcontainer_char.visible = false;
    if (this.wh.questlistcontainer_daily != undefined)
      this.wh.questlistcontainer_daily.visible = true;
    if (this.wh.questlistcontainer_weekly != undefined)
      this.wh.questlistcontainer_weekly.visible = false;
    this.dwidescr_daily = this.wh.add.text(
      0,
      0,
      this.wh.gl('Complete above to open daily chest'),
      this.wh.fs["winguiitemdescr"]
    );
    this.wh.questlistcontainer_daily.add(this.dwidescr_daily);
    this.dwidescr_daily.setOrigin(0, 0);
    this.dwidescr_daily.setPosition(-140, 174);
    var dat;
    if (iscallfromgameitf == false) {
      this.loadingshow();
      await this.firsttimerunfunc();
      this.loadinghide();
    }
    dat = await this.rchttp.getcommon2('dailyquest');

    this.dddata = dat;
    this.plog.log('dailyloaded', dat);
    var toppos = -130;
    var leftpos = -140;
    var i = 0;
    var itemheight = 60;
    var iconsize = 52;
    var incompletecount = 0;
    if (this.sharebtn != undefined) {
      this.sharebtn.visble = false;
    }
    for (const k in dat) {
      if (k == 'dailystat') continue;
      var v = dat[k];
      this.plog.log(i, v);
      if (v['descr'] == '') v['descr'] = v['name'];
      if (v['num'] < v['rqnum']) incompletecount++;
      this.ddtitle[i] = this.wh.add.text(0, 0, '' + this.wh.gl(v['name']) + '', this.wh.fs["winguilist_title"]);
      this.wh.questlistcontainer_daily.add(this.ddtitle[i]);
      this.wh.questlistcontainer_daily.bringToTop(this.ddtitle[i]);
      this.ddtitle[i].setPosition(
        leftpos + iconsize + 5,
        toppos + i * itemheight + 1
      );
      this.dddescr[i] = this.wh.add.text(
        0,
        0,
        '(' + v['num'] + '/' + v['rqnum'] + ') ' + this.wh.gl(v['descr']) + '',
        this.wh.fs["winguilist_descr"]
      );
      this.wh.questlistcontainer_daily.add(this.dddescr[i]);
      this.wh.questlistcontainer_daily.bringToTop(this.dddescr[i]);
      this.dddescr[i].setPosition(
        leftpos + iconsize + 5,
        toppos + i * itemheight + 5 + 20
      );
      this.ddicon[i] = this.wh.add.sprite(0, 0, 'questitem', v['code']);
      this.wh.questlistcontainer_daily.add(this.ddicon[i]);
      this.wh.questlistcontainer_daily.bringToTop(this.ddicon[i]);
      if (v['num'] < v['rqnum']) {
        this.ddicon[i].setTexture('questitemg', v['code']);
      }
      this.ddicon[i].setPosition(
        leftpos + iconsize / 2,
        toppos + i * itemheight + iconsize / 2
      );
      if (v['num'] < v['rqnum']) {
        this.ddbtn[i] = this.wh.add.sprite(0, 0, "thesb", "claimdis");//this.wh.add.image(0, 0, 'claimbtndis');
        if (k == "sharer") {
          this.sharebtn = this.wh.add.sprite(0, 0, "thesq", "share");//this.wh.add.image(0, 0, 

          this.wh.questlistcontainer_daily.add(this.sharebtn);
          this.wh.questlistcontainer_daily.bringToTop(this.sharebtn);
          this.sharebtn.setPosition(25, toppos + i * itemheight + 5 + 15);
          this.sharebtn.displayHeight = 40;
          this.sharebtn.scaleX = this.sharebtn.scaleY;
          this.sharebtn.visible = true;

          this.sharebtn.setInteractive();
          this.sharebtn.removeAllListeners();

          this.sharebtn.on('pointerup', async function () {
            this.plog.log('quest daily share btn click', arguments, this);
            this.wh.registry.list.anyjs.sharer_global();
          }, this);
        }
      } else {
        if (v['claimed'] == 1) {
          this.ddbtn[i] = this.wh.add.sprite(0, 0, "thesb", "claimdis");//this.wh.add.image(0, 0, 'claimbtndis');
        } else {
          this.ddbtn[i] = this.wh.add.sprite(0, 0, "thesb", "claim");//this.wh.add.image(0, 0, 'claimbtn');
          this.ddbtn[i].name = k;
          this.ddbtn[i].setInteractive();
          this.ddbtn[i].removeAllListeners();
          this.ddbtn[i].on('pointerup', async function () {
            this.scene.plog.log('quest daily item collect btn', arguments, this);
            this.scene.registry.list.rcloading.loading2(this.scene);
            await this.scene.registry.list.rchttp.getcommon2('dwquestreward', {
              mode: 'dailyitem',
              item: this.name,
            });
            this.scene.registry.list.rcloading.close();
            /*this.scene.registry.list.showreward.show(
              this.scene.registry.list.questlist.wh,
              this.scene.registry.list.questlist.dddata[this.name]['reward']
            );*/
            await this.scene.registry.list.rchttp.updatecharinfo();
            this.pipeline.game.scene.scenes[0].registry.list.questlist.daily_loaded(); //scene gone after updatecharinfo
            //this.scene.registry.list.questlist.daily_loaded();
          });
          this.wh.registry.list.gameitf.wiggle(this.wh, this.ddbtn[i]);
        }
      }
      this.wh.questlistcontainer_daily.add(this.ddbtn[i]);
      this.wh.questlistcontainer_daily.bringToTop(this.ddbtn[i]);
      this.ddbtn[i].displayWidth = 100;
      this.ddbtn[i].scaleY = this.ddbtn[i].scaleX;
      this.ddbtn[i].setPosition(100, toppos + i * itemheight + 5 + 15);

      i++;
    }
    if (this.sharebtn != undefined) {
      this.wh.questlistcontainer_daily.bringToTop(this.sharebtn);
    }
    this.plog.log('incompletecount', incompletecount);
    if (incompletecount > 0) {
      this.dwichest_daily = this.wh.add.sprite(
        0,
        0,
        'questitemg',
        'justicon_dailychest_close'
      );
    } else {
      if (dat['dailystat']['claimed'] == 0) {
        this.dwichest_daily = this.wh.add.sprite(
          0,
          0,
          'questitem',
          'justicon_dailychest_close'
        );
        this.wh.registry.list.gameitf.wiggle(this.wh, this.dwichest_daily);

        this.dwichest_daily.setInteractive();
        this.dwichest_daily.removeAllListeners();
        this.dwichest_daily.on(
          'pointerup',
          async function () {
            this.plog.log('dwichest_daily pointerup, this', this);
            this.wh.registry.list.rcloading.loading2(this.wh);
            await this.wh.registry.list.rchttp.getcommon2('dwquestreward', {
              mode: 'thedaily',
            });
            await this.rchttp.updatecharinfo();
            this.wh.registry.list.rcloading.close();
            /*
            this.wh.registry.list.showreward.show(
              this.wh,
              this.dddata['dailystat']['reward']
            );*/
            this.daily_loaded();
          },
          this
        );
      } else {
        this.dwichest_daily = this.wh.add.sprite(
          0,
          0,
          'questitemg',
          'justicon_dailychest_open'
        );
      }
    }

    this.wh.questlistcontainer_daily.add(this.dwichest_daily);
    this.wh.questlistcontainer_daily.bringToTop(this.dwichest_daily);
    this.dwichest_daily.setPosition(120, 175);
    this.empty.visible = false;



    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);
    griddat.push(this.btnstory);
    griddat.push(this.btndaily);
    griddat.push(this.btnweekly);
    griddat.push(this.sharebtn);

    for (const k in this.ddbtn) {
      var v = this.ddbtn[k];
      griddat.push(this.ddbtn[k]);
      griddatf.push(this.ddbtn[k]);

    }
    griddat.push(this.dwichest_daily);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);
    this.wh.registry.list.tut.show(this.wh, "questlistloaded_daily");

  }
  async weekly_loaded(iscallfromgameitf = false) {
    this.empty.visible = false;
    this.currentmode = "weekly"
    this.plog.log('weekly_loaded', this.wh, this.empty);
    if (this.wh.questlistcontainer_char != undefined) {
      //this.wh.questlistcontainer_char.destroy();true
      //this.wh.questlistcontainer_char = undefined;
      this.wh.questlistcontainer_char.visible = false;
      //this.graphics.clear();
    }
    if (this.wh.questlistcontainer_weekly != undefined) {
      this.wh.questlistcontainer_weekly.destroy();
      this.wh.questlistcontainer_weekly = undefined;
    }
    if (this.wh.questlistcontainer_weekly == undefined) {
      this.wh.questlistcontainer_weekly = this.wh.add.container(0, 0);
      this.wh.questlistcontainer.add(this.wh.questlistcontainer_weekly);
    }
    if (this.wh.questlistcontainer_char != undefined)
      this.wh.questlistcontainer_char.visible = false;
    if (this.wh.questlistcontainer_daily != undefined)
      this.wh.questlistcontainer_daily.visible = false;
    if (this.wh.questlistcontainer_weekly != undefined)
      this.wh.questlistcontainer_weekly.visible = true;
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].questlistcontainer);
    var dat;
    if (iscallfromgameitf == false) {
      this.loadingshow();

      await this.firsttimerunfunc();
      this.loadinghide();
    }
    dat = await this.rchttp.getcommon2('weeklyquest');

    this.dwidescr_weekly = this.wh.add.text(
      0,
      0,
      this.wh.gl('Complete above to open weekly chest'),
      this.wh.fs["winguiitemdescr"]
    );
    this.wh.questlistcontainer_weekly.add(this.dwidescr_weekly);
    this.dwidescr_weekly.setOrigin(0, 0);
    this.dwidescr_weekly.setPosition(-140, 174);
    this.wwdata = dat;
    this.plog.log('dailyloaded', dat);
    var toppos = -130;
    var leftpos = -140;
    var i = 0;
    var itemheight = 60;
    var iconsize = 52;
    var incompletecount = 0;
    for (const k in dat) {
      if (k == 'weeklystat') continue;
      var v = dat[k];
      this.plog.log(i, v);
      if (v['descr'] == '') v['descr'] = v['name'];
      if (v['num'] < v['rqnum']) incompletecount++;
      this.wwtitle[i] = this.wh.add.text(0, 0, '' + this.wh.gl(v['name']) + '', this.wh.fs["winguilist_title"]);
      this.wh.questlistcontainer_weekly.add(this.wwtitle[i]);
      this.wh.questlistcontainer_weekly.bringToTop(this.wwtitle[i]);
      this.wwtitle[i].setPosition(
        leftpos + iconsize + 5,
        toppos + i * itemheight + 1
      );
      this.wwdescr[i] = this.wh.add.text(
        0,
        0,
        '(' + v['num'] + '/' + v['rqnum'] + ') ' + this.wh.gl(v['descr']) + '',
        this.wh.fs["winguilist_descr"]
      );
      this.wh.questlistcontainer_weekly.add(this.wwdescr[i]);
      this.wh.questlistcontainer_weekly.bringToTop(this.wwdescr[i]);
      this.wwdescr[i].setPosition(
        leftpos + iconsize + 5,
        toppos + i * itemheight + 5 + 20
      );
      this.wwicon[i] = this.wh.add.sprite(0, 0, 'questitem', v['code']);
      this.wh.questlistcontainer_weekly.add(this.wwicon[i]);
      this.wh.questlistcontainer_weekly.bringToTop(this.wwicon[i]);
      if (v['num'] < v['rqnum']) {
        this.wwicon[i].setTexture('questitemg', v['code']);
      }
      this.wwicon[i].setPosition(
        leftpos + iconsize / 2,
        toppos + i * itemheight + iconsize / 2
      );
      if (v['num'] < v['rqnum']) {
        this.wwbtn[i] = this.wh.add.sprite(0, 0, "thesb", "claimdis");//this.wh.add.image(0, 0, 'claimbtndis');
      } else {
        if (v['claimed'] == 1) {
          this.wwbtn[i] = this.wh.add.sprite(0, 0, "thesb", "claimdis");//this.wh.add.image(0, 0, 'claimbtndis');
        } else {
          this.wwbtn[i] = this.wh.add.sprite(0, 0, "thesb", "claim");//this.wh.add.image(0, 0, 'claimbtn');
          this.wwbtn[i].name = k;
          this.wwbtn[i].setInteractive();
          this.wwbtn[i].removeAllListeners();
          this.wwbtn[i].on('pointerup', async function () {
            this.scene.plog.log('quest daily item collect btn', arguments, this);
            this.scene.registry.list.rcloading.loading2(this.scene);
            await this.scene.registry.list.rchttp.getcommon2('dwquestreward', {
              mode: 'weeklyitem',
              item: this.name,
            });
            this.scene.registry.list.rcloading.close();/*
            this.scene.registry.list.showreward.show(
              this.scene.registry.list.questlist.wh,
              this.scene.registry.list.questlist.wwdata[this.name]['reward']
            );*/
            await this.scene.registry.list.rchttp.updatecharinfo();
            this.pipeline.game.scene.scenes[0].registry.list.questlist.weekly_loaded();
          });
          this.wh.registry.list.gameitf.wiggle(this.wh, this.wwbtn[i]);
        }
      }
      this.wh.questlistcontainer_weekly.add(this.wwbtn[i]);
      this.wh.questlistcontainer_weekly.bringToTop(this.wwbtn[i]);
      this.wwbtn[i].displayWidth = 100;
      this.wwbtn[i].scaleY = this.wwbtn[i].scaleX;
      this.wwbtn[i].setPosition(100, toppos + i * itemheight + 5 + 15);
      i++;
    }
    this.plog.log('incompletecount', incompletecount);
    if (incompletecount > 0) {
      this.dwichest_weekly = this.wh.add.sprite(
        0,
        0,
        'questitemg',
        'justicon_dailychest_close'
      );
    } else {
      if (dat['weeklystat']['claimed'] == 0) {
        this.dwichest_weekly = this.wh.add.sprite(
          0,
          0,
          'questitem',
          'justicon_dailychest_close'
        );
        this.wh.registry.list.gameitf.wiggle(this.wh, this.dwichest_weekly);

        this.dwichest_weekly.setInteractive();
        this.dwichest_weekly.removeAllListeners();
        this.dwichest_weekly.on(
          'pointerup',
          async function () {
            this.plog.log('dwichest_weekly pointerup, this', this);
            this.wh.registry.list.rcloading.loading2(this.wh);
            await this.wh.registry.list.rchttp.getcommon2('dwquestreward', {
              mode: 'theweekly',
            });
            await this.rchttp.updatecharinfo();
            this.wh.registry.list.rcloading.close();/*
            this.wh.registry.list.showreward.show(
              this.wh,
              this.wwdata['weeklystat']['reward']
            );*/
            this.weekly_loaded();
          },
          this
        );
      } else {
        this.dwichest_weekly = this.wh.add.sprite(
          0,
          0,
          'questitemg',
          'justicon_dailychest_open'
        );
      }
    }
    this.wh.questlistcontainer_weekly.add(this.dwichest_weekly);
    this.wh.questlistcontainer_weekly.bringToTop(this.dwichest_weekly);
    this.dwichest_weekly.setPosition(120, 175);
    this.empty.visible = false;



    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);
    griddat.push(this.btnstory);
    griddat.push(this.btndaily);
    griddat.push(this.btnweekly);

    for (const k in this.wwbtn) {
      var v = this.wwbtn[k];
      griddat.push(this.wwbtn[k]);
      griddatf.push(this.wwbtn[k]);

    }
    griddat.push(this.dwichest_weekly);
    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);
    this.wh.registry.list.tut.show(this.wh, "questlistloaded_weekly");

  }
  async show_loaded(iscallfromgameitf = false) {
    //called by default, show _char
    this.firsttimerunfunc();
    this.plog.log('show_loaded', this.wh, this);
    //this.setmaskposition();
    //this.loadingshow();
    //await this.rchttp.updatecharinfo();
    //this.loadinghide();
    this.currentmode = "story"
    await this.buildquestctnr(false);
    await this.buildquestctnr(false);
    if (this.wh.questlistcontainer_char != undefined)
      this.wh.questlistcontainer_char.visible = true;
    if (this.wh.questlistcontainer_daily != undefined)
      this.wh.questlistcontainer_daily.visible = false;
    if (this.wh.questlistcontainer_weekly != undefined)
      this.wh.questlistcontainer_weekly.visible = false;

    this.wh.questlistcontainer.visible = true;
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);



    if (iscallfromgameitf == false) {

    }
    this.loadinghide();
    this.wh.registry.list.tut.show(this.wh, "questlistloaded_main");

  }
  async show_close(wh) {
    if (wh.questlistcontainer != undefined)
      wh.questlistcontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.questlistcontainer);

    for (const k in this.wh.questlistcontainer.list) {
      try {
        await this.wh.questlistcontainer.remove(this.wh.questlistcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! questlistcontainer.destroy() ", e);
      }
    }
    try {
      await this.wh.questlistcontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! questlistcontainer.destroy() ", e);
    }
    wh.questlistcontainer = undefined;
  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    //this.showinventory_showpage(null, ""); //????
    if (this.currentmode == "story") {
      this.showinventory_showpage(this.wh, "");
    }
    if (this.currentmode == "daily") {
      this.daily_loaded(false);
    }
    if (this.currentmode == "weekly") {
      this.weekly_loaded(false);
    }
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
