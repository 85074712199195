import { Injectable } from '@angular/core';
import { Component } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { RCVarsService } from './rcvars-service.service';
import { plog } from './plog.service';
import { Toast } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
//import RoundRectangle from 'phaser3-rex-plugins/plugins/roundrectangle.js';

@Injectable({
  providedIn: 'root',
})
/*
@Component({
  selector: 'toast-example',
  templateUrl: 'toast-example.html',
  styleUrls: ['./toast-example.css'],
})*/
export class RCToastService {
  wh;

  spritepaperdialogbg;
  spritepaperdialogbgimg;
  spritepaperdialogtext;

  rcaLconfirmbg;
  rcaLconfirmbtn;
  rcaLconfirmhead;
  rcaLconfirmtext;
  rcaLconfirmbgimg;

  npcvideobg;
  npcvideobgimg;
  npcvideobgtext;
  npcvideoimg;
  npcvideotext;

  spritedialogbg;
  spritedialogbgimg;
  spritedialogtext;
  spritedialogimg;

  localmapbgimg;
  localmapbg;
  localmapimg;
  localmaptext;

  rcentLTpass;
  rcentLTbg;
  rcentLTbgimg;
  rcentLTbtn;
  rcentLTcancelbtn;
  rcentLThead;
  rcentLTtext;
  rcentLTTEXT;

  rcentertextpass;
  rcentertextbg;
  rcentertextbgimg;
  rcentertextbtn;
  rcentertextcancelbtn;
  rcentertexthead;
  rcentertexttext;
  rcentertextTEXT;

  rcenternumbg;
  rcenternumbgimg;
  rcenternumbtn;
  rcenternumcancelbtn;
  rcenternumhead;
  rcenternumtext;
  inputNum;

  homeinvensipass = '';
  homeinvensibg;
  homeinvensibgimg;
  homeinvensihead;
  homeinvensitext;
  homeinvensiibtn;
  homeinvensisbtn;

  rcaconfirmbgimg;
  rcaconfirmbg;
  rcaconfirmhead;
  rcaconfirmtext;
  rcaconfirmbtn;
  rcconfirmtext;
  rcconfirmhead;
  timeout;
  rcconfirmbg;
  rcconfirmbtn;
  rccanceltn;
  rccancelbtn;
  rcconfirmpass = '';
  rcalertpass = '';
  rcenternumpass;// = '';
  rcconfirmbgimg;
  homeinvenclosebtn;
  plog;

  constructor(
    public toastController: ToastController,
    public alertcontroller: AlertController,
    public rcvar: RCVarsService
  ) {
    this.plog = new plog();
    //this.plog.setLevel(4);
    this.timeout = async (ms) => new Promise((res) => setTimeout(res, ms));
  }

  async show(txt: string) {
    this.plog.log('RCToast-show', txt);

    const toast = await this.toastController.create({
      message: ' ' + txt,
      duration: 2000,
    });
    toast.present();
  }

  async rctoast(wh, txt: string) {
    //var rect = new RoundRectangle(wh, 100, 100, 300, 30, 8, 0x008888, 0xff8888);
    var thiso = wh.add.sprite(100, 100, "thecolor", "black");
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    var toast = new Toast(wh, {
      x: width / 2,
      y: height - 50,
      width: 70,
      background: thiso,
      text: wh.add.text(0, 0, '', {
        fontFamily: 'ffff,uuuu',
        fontSize: '14px',
        wordWrap: { width: width * 0.7, useAdvancedWrap: true }
      }),
      space: {
        left: 5,
        right: 5,
        top: 5,
        bottom: 5,
      },
      transitIn: 0,
      transitOut: 1,
      duration: {
        in: 250,
        hold: 5000,
        out: 250,
      },
    });
    toast.showMessage(txt + '');
    wh.add.existing(toast);
    toast.bringToTop();
    this.plog.log('RCToast-show', txt, toast);
    return toast;
  }

  async rcenternum(wh, head, text, defval = 0) {
    this.wh = wh;
    this.plog.log('rcenternum', head, text, defval);
    this.rcenternumpass = '';
    this.wh.kbfocuslv1 = "rcenternum";
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.rcenternumbg == undefined) {
      var rcenternumbg = wh.add.rectangle(0, 0, width, height, 0xffffff);
      rcenternumbg.setAlpha(0.7);
      rcenternumbg.setPosition(0, 0);
      rcenternumbg.setOrigin(0, 0);
      rcenternumbg.setAlpha(0.5);
      rcenternumbg.visible = true;
      rcenternumbg.setDepth(1500000);
      rcenternumbg.setInteractive();
      this.rcenternumbg = rcenternumbg;

      this.rcenternumbgimg = wh.add.image(300, 300, 'dialogbg');
      this.rcenternumbgimg.setOrigin(0.5, 0.5);
      this.rcenternumbgimg.setPosition(width / 2, height / 2);
      var localwidth = Math.min(width, height);
      if (localwidth > 550) localwidth = 550;
      //this.plog.log("localwidth", localwidth);
      //if (localwidth)
      this.rcenternumbgimg.displayWidth = localwidth * 0.9;
      this.rcenternumbgimg.scaleY = this.rcenternumbgimg.scaleX;
      this.rcenternumbgimg.setDepth(1500003);
      //this.rcenternumbgimg.visible=false;
      this.plog.log('rcenternumbgimg', this.rcenternumbgimg);

      this.rcenternumbtn = wh.add.sprite(0, 0, "thesb", "confirm");//wh.add.image(300, 300, 'confirmbtn');
      this.rcenternumbtn.setOrigin(0.5, 0.5);
      var buttonw = localwidth / 4;
      var buttonh = buttonw * 0.4;
      var btmofdialog = height / 2 + this.rcenternumbgimg.displayHeight / 2; //- buttonh;
      btmofdialog = btmofdialog - this.rcenternumbgimg.displayHeight * 0.2; //margin
      this.rcenternumbtn.setPosition(width / 2 + buttonw / 2 + 5, btmofdialog);
      this.plog.log(
        'rcenternumbtn.setPosition',
        btmofdialog,
        width / 2 - (buttonw + 5)
      );
      this.rcenternumbtn.displayWidth = buttonw;
      this.rcenternumbtn.displayHeight = buttonh;
      this.rcenternumbtn.setDepth(1500005);

      this.rcenternumcancelbtn = wh.add.sprite(0, 0, "thesb", "cancel");//wh.add.image(300, 300, 'cancelbtn');
      this.rcenternumcancelbtn.setOrigin(0.5, 0.5);
      this.rcenternumcancelbtn.setPosition(
        width / 2 - (buttonw / 2 + 5),
        btmofdialog
      );

      this.rcenternumcancelbtn.displayWidth = buttonw;
      this.rcenternumcancelbtn.displayHeight = buttonh;
      this.rcenternumcancelbtn.setDepth(1500005);
      this.rcenternumhead = wh.add.text(0, 0, 'head', wh.fs["rcalert_head"]);
      this.rcenternumtext = wh.add.text(0, 0, 'text', wh.fs["rcalert_text"]);

      this.inputNum = wh.add
        .rexInputText(0, 0, 100, 25, {
          id: 'userinputnum',
          type: 'number',
          text: '10',
          fontSize: '20px',
          color: '#000',
          autoComplete: 'off',
          border: 2,
          backgroundColor: '#f2f2f2',
          borderColor: '#000',
          selectAll: true,
        })
        .resize(100, 30)
        .setOrigin(0.5)
        .on('textchange', function (inputText) {
          //this.plog.log(inputText);
        });
      if (defval == undefined) {
        defval = 0;
      }

      /*this.inputNum.node.addEventListener("keypress", function (evt) {
        if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) {
          evt.preventDefault();
        }
      });*/
      var style = document.createElement('style');
      style.innerHTML = `
      #userinputnum::-webkit-inner-spin-button, 
      #userinputnum::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
      }
      #userinputnum {
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }`;
      document.head.appendChild(style);
    }

    this.inputNum.setText(defval);

    this.rcenternumbtn.visible = true;
    this.rcenternumcancelbtn.visible = true;
    this.rcenternumhead.visible = true;
    this.rcenternumtext.visible = true;
    this.rcenternumbgimg.visible = true;
    this.rcenternumbg.visible = true;
    this.inputNum.visible = true;
    this.rcenternumbtn.setAlpha(1);
    this.rcenternumcancelbtn.setAlpha(1);
    this.rcenternumhead.setAlpha(1);
    this.rcenternumtext.setAlpha(1);
    this.rcenternumbgimg.setAlpha(1);
    this.rcenternumbg.setAlpha(0.5);
    this.inputNum.setAlpha(1);

    var topofdialog = height / 2 - this.rcenternumbgimg.displayHeight / 2; //- buttonh;
    topofdialog = topofdialog + this.rcenternumbgimg.displayHeight * 0.2;
    //this.rcenternumhead.setPosition(width / 2, topofdialog);
    this.rcenternumtext.setPosition(width / 2, height / 2 - 35);
    this.rcenternumtext.setDepth(1500010);
    this.rcenternumhead.setDepth(1500010);
    //wh.inventorycontainer_stackitem.add(this.inputNum);
    //wh.inventorycontainer_stackitem.bringToTop(this.inputNum);
    this.inputNum.setDepth(1500090);
    this.inputNum.displayWidth = 150;
    this.inputNum.displayHeight = 30;
    this.inputNum.setPosition(width / 2, height / 2 + 35);
    //this.inputNum.x=(width / 2);
    //this.inputNum.y=(height / 2);
    this.inputNum.setFocus();

    this.plog.log('inputNum', this.inputNum);
    var oldy = this.rcenternumbtn.x;
    this.rcenternumbtn.x = oldy + 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcenternumbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    var oldy = this.rcenternumcancelbtn.x;
    this.rcenternumcancelbtn.x = oldy - 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcenternumcancelbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    var oldy = this.rcenternumbgimg.y;
    this.rcenternumbgimg.y = oldy + 250;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcenternumbgimg,
      y: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    this.rcenternumhead.setText(wh.gl(head));
    this.rcenternumhead.setPosition(
      width / 2 - this.rcenternumhead.displayWidth / 2,
      topofdialog
    );

    this.rcenternumtext.setText(wh.gl(text));
    this.rcenternumtext.setPosition(
      width / 2 - this.rcenternumtext.displayWidth / 2,
      height / 2 - this.rcenternumtext.displayHeight / 2
    );
    this.rcenternumbtn.removeAllListeners();
    this.rcenternumbtn.setInteractive();
    this.rcenternumbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcenternumbtn",this,arguments);
        this.rcenternum_ok();
      },
      this
    );

    this.rcenternumcancelbtn.removeAllListeners();
    this.rcenternumcancelbtn.setInteractive();
    this.rcenternumcancelbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcenternumbtn",this,arguments);
        this.rcenternum_cancel();
      },
      this
    );
    this.lcamman(wh);
    while (this.rcenternumpass == '') await this.timeout(50); // pauses script
    this.plog.log('this.rcenternumpass', this.rcenternumpass);
    return this.rcenternumpass;
  }
  rcenternum_cancel() {

    this.rcenternumpass = '0';
    this.wh.kbfocuslv1 = "";
    this.inputNum.visible = false;
    this.wh.tweens.add({
      targets: [
        this.rcenternumbg,
        this.rcenternumbtn,
        this.rcenternumcancelbtn,
        this.rcenternumhead,
        this.rcenternumtext,
        this.rcenternumbgimg,
        this.rcenternumbg,
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        //this.plog.log('osdflash tweencomplete', arguments);
        arguments[1][0].visible = true;
        arguments[1][1].visible = true;
        arguments[1][2].visible = true;
        arguments[1][3].visible = true;
        arguments[1][4].visible = true;
        arguments[1][5].visible = true;
        arguments[1][6].visible = true;
      },
    });
  }
  rcenternum_ok() {

    this.rcenternumpass = Number(Number(this.inputNum.text));
    this.wh.kbfocuslv1 = "";
    this.inputNum.visible = false;
    //this.rcenternum_close();
    this.wh.tweens.add({
      targets: [
        this.rcenternumbg,
        this.rcenternumbtn,
        this.rcenternumcancelbtn,
        this.rcenternumhead,
        this.rcenternumtext,
        this.rcenternumbgimg,
        this.rcenternumbg,
        this.inputNum,
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        //this.plog.log('osdflash tweencomplete', this);
        arguments[1][0].visible = true;
        arguments[1][1].visible = true;
        arguments[1][2].visible = true;
        arguments[1][3].visible = true;
        arguments[1][4].visible = true;
        arguments[1][5].visible = true;
        arguments[1][6].visible = true;
        arguments[1][7].visible = true;
      },
    });
  }
  rcenternum_close() {
    this.plog.log('rcenternum_close');
    this.wh.kbfocuslv1 = "";
    this.rcenternumpass = '';
    this.rcenternumbtn.visible = false;
    this.rcenternumcancelbtn.visible = false;
    this.rcenternumhead.visible = false;
    this.rcenternumtext.visible = false;
    this.rcenternumbgimg.visible = false;
    this.rcenternumbg.visible = false;
    this.inputNum.visible = false;
  }
  async sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  selectgemslotpass = "";
  selectgemslotpasstmp = "";
  selectgemslotbg;
  selectgemslotbgimg;
  selectgemslotbtn;
  selectgemslotcancelbtn;
  selectgemslothead;
  selectgemslotitemicon;
  selectgemslotgemicon;
  selectgemslot_gem1; selectgemslot_text1;
  selectgemslot_gem2; selectgemslot_text2;
  selectgemslot_gem3; selectgemslot_text3;
  selectgemslot_gem4; selectgemslot_text4;
  async selectgemslot(wh, head, iteminfo, iteminfo2, geminfo) {
   // console.log("selectgemslot", wh, head, iteminfo, iteminfo2, geminfo);

    this.plog.log('rcentertext', wh);
    this.wh = wh;
    this.wh.kbfocuslv1 = "selectgemslot";
    this.selectgemslotpass = '';
    this.selectgemslotpasstmp = '';
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    if (this.selectgemslot != undefined) {
      this.selectgemslot_close();
    }
    if (this.selectgemslotbg == undefined) {
      this.selectgemslotbg = wh.add.rectangle(0, 0, width, height, 0xffffff);;
      this.selectgemslotbgimg = wh.add.image(300, 300, 'guiitemgem');
      this.selectgemslotbtn = wh.add.sprite(0, 0, "thesb", "confirm");//wh.add.image(300, 300, 'confirmbtn');
      this.selectgemslotcancelbtn = wh.add.sprite(0, 0, "thesb", "cancel");//wh.add.image(300, 300, 'cancelbtn');
      this.selectgemslotitemicon = wh.add.sprite(0, 0, "item_tp", iteminfo["item"]);
      this.selectgemslotitemicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;
      this.selectgemslotgemicon = wh.add.sprite(0, 0, "stackitem_tp", geminfo["code"]);
      this.selectgemslotgemicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);;

      this.selectgemslotbgimg.setOrigin(0.5, 0.5);
      this.selectgemslotbgimg.setPosition(width / 2, height / 2);
      
      this.selectgemslotitemicon.displayWidth = this.selectgemslotitemicon.displayHeight = 64;
      this.selectgemslotitemicon.setPosition(this.selectgemslotbgimg.x + 45-130, this.selectgemslotbgimg.y - 10);
      this.selectgemslotitemicon.setDepth(1500005);
      this.selectgemslotgemicon.displayWidth = this.selectgemslotgemicon.displayHeight = 32;
      this.selectgemslotgemicon.setPosition(this.selectgemslotbgimg.x + 45-130, this.selectgemslotbgimg.y + 50);
      this.selectgemslotgemicon.setDepth(1500005);

      this.selectgemslothead = wh.add.text(0, 0, 'head', wh.fs["rcalert_head"]);

      this.selectgemslot_gem1 = wh.add.sprite(0, 0, "thecolor", "gemempty");;
      this.selectgemslot_text1 = wh.add.text(0, 0, 'Empty', wh.fs["rcalert_text"]);
      this.selectgemslot_gem1.setDepth(1500005);
      this.selectgemslot_text1.setDepth(1500005);
      this.selectgemslot_gem2 = wh.add.sprite(0, 0, "thecolor", "gemempty");;
      this.selectgemslot_text2 = wh.add.text(0, 0, 'Empty', wh.fs["rcalert_text"]);
      this.selectgemslot_gem2.setDepth(1500005);
      this.selectgemslot_text2.setDepth(1500005);
      this.selectgemslot_gem3 = wh.add.sprite(0, 0, "thecolor", "gemempty");;
      this.selectgemslot_text3 = wh.add.text(0, 0, 'Empty', wh.fs["rcalert_text"]);
      this.selectgemslot_gem3.setDepth(1500005);
      this.selectgemslot_text3.setDepth(1500005);
      this.selectgemslot_gem4 = wh.add.sprite(0, 0, "thecolor", "gemempty");;
      this.selectgemslot_text4 = wh.add.text(0, 0, 'Empty', wh.fs["rcalert_text"]);
      this.selectgemslot_gem4.setDepth(1500005);
      this.selectgemslot_text4.setDepth(1500005);

      this.selectgemslot_gem1.setPosition(this.selectgemslotbgimg.x + 120-130, this.selectgemslotbgimg.y - 35);
      this.selectgemslot_text1.setPosition(this.selectgemslotbgimg.x + 137-130, this.selectgemslotbgimg.y - 35 - 15);
      this.selectgemslot_gem2.setPosition(this.selectgemslotbgimg.x + 120-130, this.selectgemslotbgimg.y + 5);
      this.selectgemslot_text2.setPosition(this.selectgemslotbgimg.x + 137-130, this.selectgemslotbgimg.y + 5 - 15);
      this.selectgemslot_gem3.setPosition(this.selectgemslotbgimg.x + 120-130, this.selectgemslotbgimg.y + 45);
      this.selectgemslot_text3.setPosition(this.selectgemslotbgimg.x + 137-130, this.selectgemslotbgimg.y + 45 - 15);
      this.selectgemslot_gem4.setPosition(this.selectgemslotbgimg.x + 120-130, this.selectgemslotbgimg.y + 85);
      this.selectgemslot_text4.setPosition(this.selectgemslotbgimg.x + 137-130, this.selectgemslotbgimg.y + 85 - 15);
    }
    if (iteminfo["gems"]["gem1"] == undefined) {
      this.selectgemslot_gem1.visible = false;
      this.selectgemslot_text1.visible = false;
    } else {
      if (iteminfo["gems"]["gem1"] != "") {
        this.selectgemslot_gem1.setTexture("stackitem_tpt", iteminfo["gems"]["gem1"]);
        var gemslotinfo = this.wh.registry.list.rcvarpass.allstackitem[iteminfo["gems"]["gem1"]];
        if (gemslotinfo != undefined) {
          this.selectgemslot_text1.setText(gemslotinfo['name']);
        }
      }
    }

    if (iteminfo["gems"]["gem2"] == undefined) {
      this.selectgemslot_gem2.visible = false;
      this.selectgemslot_text2.visible = false;
    } else {
      if (iteminfo["gems"]["gem2"] != "") {
        this.selectgemslot_gem2.setTexture("stackitem_tpt", iteminfo["gems"]["gem2"]);
        var gemslotinfo = this.wh.registry.list.rcvarpass.allstackitem[iteminfo["gems"]["gem2"]];
        if (gemslotinfo != undefined) {
          this.selectgemslot_text2.setText(gemslotinfo['name']);
        }
      }
    }

    if (iteminfo["gems"]["gem3"] == undefined) {
      this.selectgemslot_gem3.visible = false;
      this.selectgemslot_text3.visible = false;
    } else {
      if (iteminfo["gems"]["gem3"] != "") {
        this.selectgemslot_gem3.setTexture("stackitem_tpt", iteminfo["gems"]["gem3"]);
        var gemslotinfo = this.wh.registry.list.rcvarpass.allstackitem[iteminfo["gems"]["gem3"]];
        if (gemslotinfo != undefined) {
          this.selectgemslot_text3.setText(gemslotinfo['name']);
        }
      }
    }

    if (iteminfo["gems"]["gem4"] == undefined) {
      this.selectgemslot_gem4.visible = false;
      this.selectgemslot_text4.visible = false;
    } else {
      if (iteminfo["gems"]["gem4"] != "") {
        this.selectgemslot_gem4.setTexture("stackitem_tpt", iteminfo["gems"]["gem4"]);
        var gemslotinfo = this.wh.registry.list.rcvarpass.allstackitem[iteminfo["gems"]["gem4"]];
        if (gemslotinfo != undefined) {
          this.selectgemslot_text4.setText(gemslotinfo['name']);
        }
      }
    }

    this.selectgemslot_gem1.displayWidth=this.selectgemslot_gem1.displayHeight=16;
    this.selectgemslot_gem2.displayWidth=this.selectgemslot_gem2.displayHeight=16;
    this.selectgemslot_gem3.displayWidth=this.selectgemslot_gem3.displayHeight=16;
    this.selectgemslot_gem4.displayWidth=this.selectgemslot_gem4.displayHeight=16;
    
    this.selectgemslot_text1.setInteractive();
    this.selectgemslot_text1.removeAllListeners();
    this.selectgemslot_text1.on("pointerup", function () {
      this.selectgemslotpasstmp = "1";
      this.selectgemslot_text1.setStyle({ color: '#ff0000', backgroundColor: '#bdd4ff' });
      this.selectgemslot_text2.setStyle({ color: '#000000', backgroundColor: '' });
      this.selectgemslot_text3.setStyle({ color: '#000000', backgroundColor: '' });
      this.selectgemslot_text4.setStyle({ color: '#000000', backgroundColor: '' });
    }.bind(this));
    this.selectgemslot_text2.setInteractive();
    this.selectgemslot_text2.removeAllListeners();
    this.selectgemslot_text2.on("pointerup", function () {
      this.selectgemslotpasstmp = "2";
      this.selectgemslot_text1.setStyle({ color: '#000000', backgroundColor: '' });
      this.selectgemslot_text2.setStyle({ color: '#ff0000', backgroundColor: '#bdd4ff' });
      this.selectgemslot_text3.setStyle({ color: '#000000', backgroundColor: '' });
      this.selectgemslot_text4.setStyle({ color: '#000000', backgroundColor: '' });
    }.bind(this));
    this.selectgemslot_text3.setInteractive();
    this.selectgemslot_text3.removeAllListeners();
    this.selectgemslot_text3.on("pointerup", function () {
      this.selectgemslotpasstmp = "3";
      this.selectgemslot_text1.setStyle({ color: '#000000', backgroundColor: '' });
      this.selectgemslot_text2.setStyle({ color: '#000000', backgroundColor: '' });
      this.selectgemslot_text3.setStyle({ color: '#ff0000', backgroundColor: '#bdd4ff' });
      this.selectgemslot_text4.setStyle({ color: '#000000', backgroundColor: '' });
    }.bind(this));
    this.selectgemslot_text4.setInteractive();
    this.selectgemslot_text4.removeAllListeners();
    this.selectgemslot_text4.on("pointerup", function () {
      this.selectgemslotpasstmp = "4";
      this.selectgemslot_text1.setStyle({ color: '#000000', backgroundColor: '' });
      this.selectgemslot_text2.setStyle({ color: '#000000', backgroundColor: '' });
      this.selectgemslot_text3.setStyle({ color: '#000000', backgroundColor: '' });
      this.selectgemslot_text4.setStyle({ color: '#ff0000', backgroundColor: '#bdd4ff' });
    }.bind(this));

    this.selectgemslotbg.setAlpha(0.7);
    this.selectgemslotbg.setPosition(0, 0);
    this.selectgemslotbg.setOrigin(0, 0);
    this.selectgemslotbg.setAlpha(0.5);
    this.selectgemslotbg.visible = true;
    this.selectgemslotbg.setDepth(1500000);
    this.selectgemslotbg.setInteractive();

    var localwidth = Math.min(width, height);
    if (localwidth > 550) localwidth = 550;
    //this.plog.log("localwidth", localwidth);
    //if (localwidth)
    this.selectgemslotbgimg.displayWidth = 360;//localwidth * 0.9;
    this.selectgemslotbgimg.scaleY = this.selectgemslotbgimg.scaleX;
    this.selectgemslotbgimg.setDepth(1500003);
    //this.rcentertextbgimg.visible=false;
    this.plog.log('selectgemslotbgimg', this.selectgemslotbgimg);

    this.selectgemslotbtn.setOrigin(0.5, 0.5);
    var buttonw = localwidth / 4;
    var buttonh = buttonw * 0.4;
    var btmofdialog = height / 2 + this.selectgemslotbgimg.displayHeight / 2; //- buttonh;
    btmofdialog = btmofdialog - this.selectgemslotbgimg.displayHeight * 0.2; //margin
    btmofdialog += 20;
    this.selectgemslotbtn.setPosition(width / 2 + buttonw / 2 + 5, btmofdialog);
    this.plog.log(
      'rcentertextbtn.setPosition',
      btmofdialog,
      width / 2 - (buttonw + 5)
    );
    this.selectgemslotbtn.displayWidth = buttonw;
    this.selectgemslotbtn.displayHeight = buttonh;
    this.selectgemslotbtn.setDepth(1500005);

    this.selectgemslotcancelbtn.setOrigin(0.5, 0.5);
    this.selectgemslotcancelbtn.setPosition(
      width / 2 - (buttonw / 2 + 5),
      btmofdialog
    );

    this.selectgemslotcancelbtn.displayWidth = buttonw;
    this.selectgemslotcancelbtn.displayHeight = buttonh;
    this.selectgemslotcancelbtn.setDepth(1500005);

    /*this.rcentertextTEXT.node.addEventListener("keypress", function (evt) {
      if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
    });*/
    /*
       this.rcentertextbtn.visible = true;
       this.rcentertextcancelbtn.visible = true;
       this.rcentertexthead.visible = true;
       this.rcentertexttext.visible = true;
       this.rcentertextbgimg.visible = true;
       this.rcentertextbg.visible = true;
       this.rcentertextTEXT.visible = true;
       this.rcentertextbtn.setAlpha(1);
       this.rcentertextcancelbtn.setAlpha(1);
       this.rcentertexthead.setAlpha(1);
       this.rcentertexttext.setAlpha(1);
       this.rcentertextbgimg.setAlpha(1);
       this.rcentertextbg.setAlpha(0.5);
       this.rcentertextTEXT.setAlpha(1);
   */
    var topofdialog = height / 2 - this.selectgemslotbgimg.displayHeight / 2; //- buttonh;
    topofdialog = topofdialog + this.selectgemslotbgimg.displayHeight * 0.2;
    //this.rcentertexthead.setPosition(width / 2, topofdialog); 
    this.selectgemslothead.setDepth(1500010);
    //wh.inventorycontainer_stackitem.add(this.rcentertextTEXT);
    //wh.inventorycontainer_stackitem.bringToTop(this.rcentertextTEXT); 
    this.selectgemslothead.setStyle({ fixedWidth: this.selectgemslotbgimg.displayWidth * 0.7, backgroundColor: '' })

    // var oldy = this.selectgemslotbtn.x;
    //this.selectgemslotbtn.x = oldy + 70;
    this.plog.log('tween', wh);


    this.selectgemslothead.setText(head);
    this.selectgemslothead.setPosition(
      width / 2 - this.selectgemslothead.displayWidth / 2,
      topofdialog + 3
    );

    this.selectgemslotbtn.removeAllListeners();
    this.selectgemslotbtn.setInteractive();
    this.selectgemslotbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcentertextbtn",this,arguments);
        this.selectgemslot_ok();
      },
      this
    );

    this.selectgemslotcancelbtn.removeAllListeners();
    this.selectgemslotcancelbtn.setInteractive();
    this.selectgemslotcancelbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcentertextbtn",this,arguments);
        this.selectgemslotpass = "cancel";
        this.selectgemslot_close();
        /*
        */
      },
      this
    );
    this.lcamman(wh);
    while (this.selectgemslotpass == '') await this.timeout(50); // pauses script
    this.plog.log('this.selectgemslotpass', this.selectgemslotpass);
    this.selectgemslot_close();
    return this.selectgemslotpass;
  }
  selectgemslot_ok() {
    if (this.selectgemslotpasstmp == "") {
      this.rctoast(this.wh, "Please select a slot");
      return;
    }
    this.selectgemslotpass = this.selectgemslotpasstmp;;
    this.selectgemslot_close();

  }
  selectgemslot_close() {
    for (let destroyi = 0; destroyi < 20; destroyi++) {
      try {
        this.selectgemslotbg.destroy();
        this.selectgemslotbg = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslotbgimg.destroy();
        this.selectgemslotbgimg = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslotbtn.destroy();
        this.selectgemslotbtn = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslotgemicon.destroy();
        this.selectgemslotgemicon = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslotcancelbtn.destroy();
        this.selectgemslotcancelbtn = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslothead.destroy();
        this.selectgemslothead = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslot_gem1.destroy();
        this.selectgemslot_gem1 = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslot_gem2.destroy();
        this.selectgemslot_gem2 = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslot_gem3.destroy();
        this.selectgemslot_gem3 = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslot_gem4.destroy();
        this.selectgemslot_gem4 = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslot_text1.destroy();
        this.selectgemslot_text1 = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslot_text2.destroy();
        this.selectgemslot_text2 = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslot_text3.destroy();
        this.selectgemslot_text3 = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslot_text4.destroy();
        this.selectgemslot_text4 = undefined;
      } catch (error) {
        //console.log(error);
      }
      try {
        this.selectgemslotitemicon.destroy();
        this.selectgemslotitemicon = undefined;
      } catch (error) {
        //console.log(error);
      }
    }
  }
  async rcentertext(wh, head, text, deftext = '') {
    this.plog.log('rcentertext', wh);
    this.wh = wh;
    this.wh.kbfocuslv1 = "rcentertext";
    this.rcentertextpass = '';
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    if (this.rcentertextbg != undefined) {
      this.rcentertext_close();
    }
    if (this.rcentertextbg == undefined) {
      this.rcentertextbg = wh.add.rectangle(0, 0, width, height, 0xffffff);;
      this.rcentertextbgimg = wh.add.image(300, 300, 'dialogbg');
      this.rcentertextbtn = wh.add.sprite(0, 0, "thesb", "confirm");//wh.add.image(300, 300, 'confirmbtn');
      this.rcentertextcancelbtn = wh.add.sprite(0, 0, "thesb", "cancel");//wh.add.image(300, 300, 'cancelbtn');

      this.rcentertextTEXT = wh.add
        .rexInputText(0, 0, 100, 25, {
          id: 'userrcentertextTEXT',
          type: 'text',
          text: '',
          fontSize: '20px',
          color: '#000',
          autoComplete: 'off',
          border: 2,
          backgroundColor: '#f2f2f2',
          borderColor: '#000',
          selectAll: true,
        })
        .resize(200, 30)
        .setOrigin(0.5)
        .on('textchange', function (inputText) {
          //this.plog.log(inputText);
        });

      var style = document.createElement('style');
      style.innerHTML = `
        #userrcentertextTEXT::-webkit-inner-spin-button, 
        #userrcentertextTEXT::-webkit-outer-spin-button { 
          -webkit-appearance: none; 
          margin: 0; 
        }
        #userrcentertextTEXT {
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
        }`;
      document.head.appendChild(style);

      this.rcentertexthead = wh.add.text(0, 0, 'head', wh.fs["rcalert_head"]);
      this.rcentertexttext = wh.add.text(0, 0, 'text', wh.fs["rcalert_text"]);
    }
    this.rcentertextbg.setAlpha(0.7);
    this.rcentertextbg.setPosition(0, 0);
    this.rcentertextbg.setOrigin(0, 0);
    this.rcentertextbg.setAlpha(0.5);
    this.rcentertextbg.visible = true;
    this.rcentertextbg.setDepth(1500000);
    this.rcentertextbg.setInteractive();

    this.rcentertextbgimg.setOrigin(0.5, 0.5);
    this.rcentertextbgimg.setPosition(width / 2, height / 2);
    var localwidth = Math.min(width, height);
    if (localwidth > 550) localwidth = 550;
    //this.plog.log("localwidth", localwidth);
    //if (localwidth)
    this.rcentertextbgimg.displayWidth = localwidth * 0.9;
    this.rcentertextbgimg.scaleY = this.rcentertextbgimg.scaleX;
    this.rcentertextbgimg.setDepth(1500003);
    //this.rcentertextbgimg.visible=false;
    this.plog.log('rcentertextbgimg', this.rcentertextbgimg);

    this.rcentertextbtn.setOrigin(0.5, 0.5);
    var buttonw = localwidth / 4;
    var buttonh = buttonw * 0.4;
    var btmofdialog = height / 2 + this.rcentertextbgimg.displayHeight / 2; //- buttonh;
    btmofdialog = btmofdialog - this.rcentertextbgimg.displayHeight * 0.2; //margin
    this.rcentertextbtn.setPosition(width / 2 + buttonw / 2 + 5, btmofdialog);
    this.plog.log(
      'rcentertextbtn.setPosition',
      btmofdialog,
      width / 2 - (buttonw + 5)
    );
    this.rcentertextbtn.displayWidth = buttonw;
    this.rcentertextbtn.displayHeight = buttonh;
    this.rcentertextbtn.setDepth(1500005);

    this.rcentertextcancelbtn.setOrigin(0.5, 0.5);
    this.rcentertextcancelbtn.setPosition(
      width / 2 - (buttonw / 2 + 5),
      btmofdialog
    );

    this.rcentertextcancelbtn.displayWidth = buttonw;
    this.rcentertextcancelbtn.displayHeight = buttonh;
    this.rcentertextcancelbtn.setDepth(1500005);

    /*this.rcentertextTEXT.node.addEventListener("keypress", function (evt) {
      if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
    });*/
    this.rcentertextTEXT.setText(deftext);

    this.rcentertextbtn.visible = true;
    this.rcentertextcancelbtn.visible = true;
    this.rcentertexthead.visible = true;
    this.rcentertexttext.visible = true;
    this.rcentertextbgimg.visible = true;
    this.rcentertextbg.visible = true;
    this.rcentertextTEXT.visible = true;
    this.rcentertextbtn.setAlpha(1);
    this.rcentertextcancelbtn.setAlpha(1);
    this.rcentertexthead.setAlpha(1);
    this.rcentertexttext.setAlpha(1);
    this.rcentertextbgimg.setAlpha(1);
    this.rcentertextbg.setAlpha(0.5);
    this.rcentertextTEXT.setAlpha(1);

    var topofdialog = height / 2 - this.rcentertextbgimg.displayHeight / 2; //- buttonh;
    topofdialog = topofdialog + this.rcentertextbgimg.displayHeight * 0.2;
    //this.rcentertexthead.setPosition(width / 2, topofdialog);
    this.rcentertexttext.setPosition(width / 2, height / 2 - 35);
    this.rcentertexttext.setDepth(1500010);
    this.rcentertexthead.setDepth(1500010);
    //wh.inventorycontainer_stackitem.add(this.rcentertextTEXT);
    //wh.inventorycontainer_stackitem.bringToTop(this.rcentertextTEXT);
    this.rcentertextTEXT.setDepth(1500010);
    this.rcentertextTEXT.displayWidth = 150;
    this.rcentertextTEXT.displayHeight = 30;
    this.rcentertextTEXT.setPosition(width / 2, height / 2 + 35);
    //this.rcentertextTEXT.x=(width / 2);
    //this.rcentertextTEXT.y=(height / 2);
    this.rcentertextTEXT.setFocus();
    this.rcentertexthead.setStyle({ fixedWidth: this.rcentertextbgimg.displayWidth * 0.7 })

    this.plog.log('rcentertextTEXT', this.rcentertextTEXT);
    var oldy = this.rcentertextbtn.x;
    this.rcentertextbtn.x = oldy + 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcentertextbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        this.rcentertextTEXT.setFocus();
      }.bind(this)
    });

    var oldy = this.rcentertextcancelbtn.x;
    this.rcentertextcancelbtn.x = oldy - 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcentertextcancelbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    var oldy = this.rcentertextbgimg.y;
    this.rcentertextbgimg.y = oldy + 250;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcentertextbgimg,
      y: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    this.rcentertexthead.setText(wh.gl(head));
    this.rcentertexthead.setPosition(
      width / 2 - this.rcentertexthead.displayWidth / 2,
      topofdialog
    );

    this.rcentertexttext.setText(wh.gl(text));
    this.rcentertexttext.setPosition(
      width / 2 - this.rcentertexttext.displayWidth / 2,
      height / 2 - this.rcentertexttext.displayHeight / 2
    );
    this.rcentertextbtn.removeAllListeners();
    this.rcentertextbtn.setInteractive();
    this.rcentertextbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcentertextbtn",this,arguments);
        this.rcentertext_ok();
      },
      this
    );

    this.rcentertextcancelbtn.removeAllListeners();
    this.rcentertextcancelbtn.setInteractive();
    this.rcentertextcancelbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcentertextbtn",this,arguments);
        this.rcentertext_close();
        /*
        */
      },
      this
    );
    this.lcamman(wh);
    while (this.rcentertextpass == '') await this.timeout(50); // pauses script
    this.plog.log('this.rcentertextpass', this.rcentertextpass);
    return this.rcentertextpass;
  }
  rcentertext_ok() {
    this.rcentertextpass = this.rcentertextTEXT.text;
    this.rcentertextTEXT.visible = false;
    this.wh.kbfocuslv1 = "";
    this.wh.tweens.add({
      targets: [
        this.rcentertextbg,
        this.rcentertextbtn,
        this.rcentertextcancelbtn,
        this.rcentertexthead,
        this.rcentertexttext,
        this.rcentertextbgimg,
        this.rcentertextbg,
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        //this.plog.log('osdflash tweencomplete', this);
        arguments[1][0].visible = true;
        arguments[1][1].visible = true;
        arguments[1][2].visible = true;
        arguments[1][3].visible = true;
        arguments[1][4].visible = true;
        arguments[1][5].visible = true;
        arguments[1][6].visible = true;
        for (let destroyi = 0; destroyi < 20; destroyi++) {
          for (let destroyi2 = 0; destroyi2 <= 6; destroyi2++) {
            try {
              arguments[1][destroyi2].destroy();
            } catch (error) {
              //console.log(error);
            }
          }
        }
      },
    });
  }
  rcentertext_cancel() {
    this.rcentertextpass = 'cancel';
    this.wh.kbfocuslv1 = "";

    this.rcentertextTEXT.visible = false;
    /*try {
      this.rcentertextTEXT.destroy();
    } catch (e) {
      this.plog.warn(e);
    }*/
    this.wh.tweens.add({
      targets: [
        this.rcentertextbg,
        this.rcentertextbtn,
        this.rcentertextcancelbtn,
        this.rcentertexthead,
        this.rcentertexttext,
        this.rcentertextbgimg,
        this.rcentertextbg,
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        //this.plog.log('osdflash tweencomplete', arguments);
        arguments[1][0].visible = true;
        arguments[1][1].visible = true;
        arguments[1][2].visible = true;
        arguments[1][3].visible = true;
        arguments[1][4].visible = true;
        arguments[1][5].visible = true;
        arguments[1][6].visible = true;
        for (let destroyi = 0; destroyi < 20; destroyi++) {
          for (let destroyi2 = 0; destroyi2 <= 6; destroyi2++) {
            try {
              arguments[1][destroyi2].destroy();
            } catch (error) {
              //console.log(error);
            }
          }
        }
      },
    });
  }

  rcentertext_close() {
    this.rcentertextpass = '';
    this.rcentertextbtn.visible = false;
    this.rcentertextcancelbtn.visible = false;
    this.rcentertexthead.visible = false;
    this.rcentertexttext.visible = false;
    this.rcentertextbgimg.visible = false;
    this.rcentertextbg.visible = false;
    this.rcentertextTEXT.visible = false;
    for (let destroyi = 0; destroyi < 20; destroyi++) {
      try {
        this.rcentertextbtn.destroy();
        this.rcentertextbtn = undefined;
      } catch (error) {
        //console.log(error);
      }
    }
    for (let destroyi = 0; destroyi < 20; destroyi++) {
      try {
        this.rcentertextcancelbtn.destroy();
        this.rcentertextcancelbtn = undefined;
      } catch (error) {
        //console.log(error);
      }
    }
    for (let destroyi = 0; destroyi < 20; destroyi++) {
      try {
        this.rcentertexthead.destroy();
        this.rcentertexthead = undefined;
      } catch (error) {
        //console.log(error);
      }
    }
    for (let destroyi = 0; destroyi < 20; destroyi++) {
      try {
        this.rcentertexttext.destroy();
        this.rcentertexttext = undefined;
      } catch (error) {
        //console.log(error);
      }
    }
    for (let destroyi = 0; destroyi < 20; destroyi++) {
      try {
        this.rcentertextbgimg.destroy();
        this.rcentertextbgimg = undefined;
      } catch (error) {
        //console.log(error);
      }
    }
    for (let destroyi = 0; destroyi < 20; destroyi++) {
      try {
        this.rcentertextbg.destroy();
        this.rcentertextbg = undefined;
      } catch (error) {
        //console.log(error);
      }
    }
    for (let destroyi = 0; destroyi < 20; destroyi++) {
      try {
        this.rcentertextTEXT.destroy();
        this.rcentertextTEXT = undefined;
      } catch (error) {
        //console.log(error);
      }
    }
  }
  async rcentLT(wh, head, text, deftext = '') {
    this.plog.log('rcentLT', wh);
    this.rcentLTpass = '';
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.rcentLTbg == undefined) {
      var rcentLTbg = wh.add.rectangle(0, 0, width, height, 0xffffff);
      rcentLTbg.setAlpha(0.7);
      rcentLTbg.setPosition(0, 0);
      rcentLTbg.setOrigin(0, 0);
      rcentLTbg.setAlpha(0.5);
      rcentLTbg.visible = true;
      rcentLTbg.setDepth(1500000);
      rcentLTbg.setInteractive();
      this.rcentLTbg = rcentLTbg;

      this.rcentLTbgimg = wh.add.image(300, 300, 'dialogbg');
      this.rcentLTbgimg.setOrigin(0.5, 0.5);
      this.rcentLTbgimg.setPosition(width / 2, height / 2);
      var localwidth = Math.min(width, height);
      if (localwidth > 550) localwidth = 550;
      //this.plog.log("localwidth", localwidth);
      //if (localwidth)
      this.rcentLTbgimg.displayWidth = localwidth * 0.9;
      this.rcentLTbgimg.scaleY = this.rcentLTbgimg.scaleX;
      this.rcentLTbgimg.setDepth(1500003);
      //this.rcentLTbgimg.visible=false;
      this.plog.log('rcentLTbgimg', this.rcentLTbgimg);

      this.rcentLTbtn = wh.add.sprite(0, 0, "thesb", "confirm");//wh.add.image(300, 300, 'confirmbtn');
      this.rcentLTbtn.setOrigin(0.5, 0.5);
      var buttonw = localwidth / 4;
      var buttonh = buttonw * 0.4;
      var btmofdialog = height / 2 + this.rcentLTbgimg.displayHeight / 2; //- buttonh;
      btmofdialog = btmofdialog - this.rcentLTbgimg.displayHeight * 0.2; //margin
      this.rcentLTbtn.setPosition(width / 2 + buttonw / 2 + 5, btmofdialog);
      this.plog.log(
        'rcentLTbtn.setPosition',
        btmofdialog,
        width / 2 - (buttonw + 5)
      );
      this.rcentLTbtn.displayWidth = buttonw;
      this.rcentLTbtn.displayHeight = buttonh;
      this.rcentLTbtn.setDepth(1500005);

      this.rcentLTcancelbtn = wh.add.sprite(0, 0, "thesb", "cancel");//wh.add.image(300, 300, 'cancelbtn');
      this.rcentLTcancelbtn.setOrigin(0.5, 0.5);
      this.rcentLTcancelbtn.setPosition(
        width / 2 - (buttonw / 2 + 5),
        btmofdialog
      );

      this.rcentLTcancelbtn.displayWidth = buttonw;
      this.rcentLTcancelbtn.displayHeight = buttonh;
      this.rcentLTcancelbtn.setDepth(1500005);
      this.rcentLThead = wh.add.text(0, 0, 'head', wh.fs["rcalert_head"]);
      this.rcentLTtext = wh.add.text(0, 0, 'text', wh.fs["rcalert_text"]);

      this.rcentLTTEXT = wh.add
        .rexInputText(0, 0, 100, 25, {
          id: 'userrcentLTTEXT',
          type: 'textarea',
          text: '',
          fontSize: '20px',
          color: '#000',
          autoComplete: 'off',
          border: 2,
          backgroundColor: '#f2f2f2',
          borderColor: '#000',
          selectAll: true,
        })
        .resize(200, 70)
        .setOrigin(0.5)
        .on('textchange', function (inputText) {
          //this.plog.log(inputText);
        });
      /*this.rcentLTTEXT.node.addEventListener("keypress", function (evt) {
        if (evt.which != 8 && evt.which != 0 && evt.which < 48 || evt.which > 57) {
          evt.preventDefault();
        }
      });*/
      var style = document.createElement('style');
      style.innerHTML = `
      #userrcentLTTEXT::-webkit-inner-spin-button, 
      #userrcentLTTEXT::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
      }
      #userrcentLTTEXT {
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }`;
      document.head.appendChild(style);
    }
    this.rcentLTTEXT.setText(deftext);

    this.rcentLTbtn.visible = true;
    this.rcentLTcancelbtn.visible = true;
    this.rcentLThead.visible = true;
    this.rcentLTtext.visible = true;
    this.rcentLTbgimg.visible = true;
    this.rcentLTbg.visible = true;
    this.rcentLTTEXT.visible = true;
    this.rcentLTbtn.setAlpha(1);
    this.rcentLTcancelbtn.setAlpha(1);
    this.rcentLThead.setAlpha(1);
    this.rcentLTtext.setAlpha(1);
    this.rcentLTbgimg.setAlpha(1);
    this.rcentLTbg.setAlpha(0.5);
    this.rcentLTTEXT.setAlpha(1);

    var topofdialog = height / 2 - this.rcentLTbgimg.displayHeight / 2; //- buttonh;
    topofdialog = topofdialog + this.rcentLTbgimg.displayHeight * 0.2;
    //this.rcentLThead.setPosition(width / 2, topofdialog);
    this.rcentLTtext.setPosition(width / 2, height / 2 - 35);
    this.rcentLTtext.setDepth(1500010);
    this.rcentLThead.setDepth(1500010);
    //wh.inventorycontainer_stackitem.add(this.rcentLTTEXT);
    //wh.inventorycontainer_stackitem.bringToTop(this.rcentLTTEXT);
    this.rcentLTTEXT.setDepth(1500090);
    this.rcentLTTEXT.displayWidth = 150;
    this.rcentLTTEXT.displayHeight = 30;
    this.rcentLTTEXT.setPosition(width / 2, height / 2 + 35);
    //this.rcentLTTEXT.x=(width / 2);
    //this.rcentLTTEXT.y=(height / 2);
    this.rcentLTTEXT.setFocus();

    this.plog.log('rcentLTTEXT', this.rcentLTTEXT);
    var oldy = this.rcentLTbtn.x;
    this.rcentLTbtn.x = oldy + 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcentLTbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    var oldy = this.rcentLTcancelbtn.x;
    this.rcentLTcancelbtn.x = oldy - 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcentLTcancelbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    var oldy = this.rcentLTbgimg.y;
    this.rcentLTbgimg.y = oldy + 250;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcentLTbgimg,
      y: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    this.rcentLThead.setText(wh.gl(head));
    this.rcentLThead.setPosition(
      width / 2 - this.rcentLThead.displayWidth / 2,
      topofdialog - 5
    );

    this.rcentLTtext.setText(wh.gl(text));
    this.rcentLTtext.setPosition(
      width / 2 - this.rcentLTtext.displayWidth / 2,
      height / 2 - 50
    );
    this.rcentLTbtn.removeAllListeners();
    this.rcentLTbtn.setInteractive();
    this.rcentLTbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcentLTbtn",this,arguments);
        this.rcentLTpass = this.rcentLTTEXT.text;
        this.rcentLTTEXT.visible = false;

        wh.tweens.add({
          targets: [
            this.rcentLTbg,
            this.rcentLTbtn,
            this.rcentLTcancelbtn,
            this.rcentLThead,
            this.rcentLTtext,
            this.rcentLTbgimg,
            this.rcentLTbg,
          ],
          alpha: 0,
          ease: 'Power1',
          duration: 300,
          yoyo: false,
          repeat: 0,
          onComplete: function () {
            //this.plog.log('osdflash tweencomplete', this);
            arguments[1][0].visible = true;
            arguments[1][1].visible = true;
            arguments[1][2].visible = true;
            arguments[1][3].visible = true;
            arguments[1][4].visible = true;
            arguments[1][5].visible = true;
            arguments[1][6].visible = true;
          },
        });
      },
      this
    );

    this.rcentLTcancelbtn.removeAllListeners();
    this.rcentLTcancelbtn.setInteractive();
    this.rcentLTcancelbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcentLTbtn",this,arguments);
        this.rcentLTpass = '';
        this.rcentLTTEXT.visible = false;
        wh.tweens.add({
          targets: [
            this.rcentLTbg,
            this.rcentLTbtn,
            this.rcentLTcancelbtn,
            this.rcentLThead,
            this.rcentLTtext,
            this.rcentLTbgimg,
            this.rcentLTbg,
          ],
          alpha: 0,
          ease: 'Power1',
          duration: 300,
          yoyo: false,
          repeat: 0,
          onComplete: function () {
            //this.plog.log('osdflash tweencomplete', arguments);
            arguments[1][0].visible = true;
            arguments[1][1].visible = true;
            arguments[1][2].visible = true;
            arguments[1][3].visible = true;
            arguments[1][4].visible = true;
            arguments[1][5].visible = true;
            arguments[1][6].visible = true;
          },
        });
      },
      this
    );
    this.lcamman(wh);
    while (this.rcentLTpass == '') await this.timeout(50); // pauses script
    this.plog.log('this.rcentLTpass', this.rcentLTpass);
    return this.rcentLTpass;
  }
  rcentLT_close() {
    this.rcentLTpass = '';
    this.rcentLTbtn.visible = false;
    this.rcentLTcancelbtn.visible = false;
    this.rcentLThead.visible = false;
    this.rcentLTtext.visible = false;
    this.rcentLTbgimg.visible = false;
    this.rcentLTbg.visible = false;
  }
  async homeinvensi(wh) {
    this.wh = wh;
    var head = this.wh.trs("f:rctoast:Which inventory you want to view?===Which inventory you want to view?");
    var text = this.wh.trs("f:rctoast:Which inventory you want to view?===Which inventory you want to view?");
    this.plog.log('homeinvensi', wh);
    this.homeinvensipass = '';
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.homeinvensibg == undefined) {
      var homeinvensibg = wh.add.rectangle(0, 0, width, height, 0xffffff);
      homeinvensibg.setAlpha(0.7);
      homeinvensibg.setPosition(0, 0);
      homeinvensibg.setOrigin(0, 0);
      homeinvensibg.setAlpha(0.5);
      homeinvensibg.visible = true;
      homeinvensibg.setDepth(1500000);
      homeinvensibg.setInteractive();
      this.homeinvensibg = homeinvensibg;

      this.homeinvensibgimg = wh.add.image(300, 300, 'dialogbg');
      this.homeinvensibgimg.setOrigin(0.5, 0.5);
      this.homeinvensibgimg.setPosition(width / 2, height / 2);
      var localwidth = Math.min(width, height);
      if (localwidth > 550) localwidth = 550;
      //this.plog.log("localwidth", localwidth);
      //if (localwidth)
      this.homeinvensibgimg.displayWidth = localwidth * 0.9;
      this.homeinvensibgimg.scaleY = this.homeinvensibgimg.scaleX;
      this.homeinvensibgimg.setDepth(1500003);
      //this.homeinvensibgimg.visible=false;
      this.plog.log('homeinvensibgimg', this.homeinvensibgimg);

      this.homeinvensisbtn = wh.add.sprite(0, 0, "thesq", 'stacks');
      this.homeinvensisbtn.setOrigin(0.5, 0.5);
      var buttonw = localwidth / 4;
      var buttonh = buttonw;
      var btmofdialog = height / 2 + this.homeinvensibgimg.displayHeight / 2; //- buttonh;
      btmofdialog = btmofdialog - this.homeinvensibgimg.displayHeight * 0.2; //margin
      this.homeinvensisbtn.setPosition(
        width / 2 + buttonw / 2 + 5,
        btmofdialog
      );
      this.plog.log(
        'homeinvensibtn.setPosition',
        btmofdialog,
        width / 2 - (buttonw + 5)
      );
      this.homeinvensisbtn.displayWidth = buttonw;
      this.homeinvensisbtn.displayHeight = buttonh;
      this.homeinvensisbtn.setDepth(1500005);

      this.homeinvensiibtn = wh.add.sprite(0, 0, "thesq", 'items');
      this.homeinvensiibtn.setOrigin(0.5, 0.5);
      this.homeinvensiibtn.setPosition(
        width / 2 - (buttonw / 2 + 5),
        btmofdialog
      );

      this.homeinvensiibtn.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
      this.homeinvensisbtn.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

      this.homeinvensiibtn.displayWidth = buttonw;
      this.homeinvensiibtn.displayHeight = buttonh;
      this.homeinvensiibtn.setDepth(1500005);
      this.homeinvensihead = wh.add.text(0, 0, 'head', wh.fs["rcalert_head"]);
      this.homeinvensitext = wh.add.text(0, 0, 'text', wh.fs["rcalert_text"]);

      var homeinvenclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
      this.homeinvenclosebtn = homeinvenclosebtn;
      this.homeinvenclosebtn.setOrigin(0.5, 0.5);
      this.homeinvenclosebtn.displayWidth =
        this.homeinvenclosebtn.displayHeight = 50;
      this.homeinvensiibtn.setDepth(1500010);
    }

    this.homeinvensisbtn.visible = true;
    this.homeinvensiibtn.visible = true;
    this.homeinvensihead.visible = true;
    this.homeinvensitext.visible = true;
    this.homeinvensibgimg.visible = true;
    this.homeinvensibg.visible = true;
    this.homeinvenclosebtn.visible = true;
    this.homeinvensiibtn.setAlpha(1);
    this.homeinvensihead.setAlpha(1);
    this.homeinvensitext.setAlpha(1);
    this.homeinvensibgimg.setAlpha(1);
    this.homeinvenclosebtn.setAlpha(1);
    this.homeinvensiibtn.setAlpha(1);
    this.homeinvensisbtn.setAlpha(1);
    this.homeinvensibg.setAlpha(0.5);

    //this.lcamman(wh);

    var topofdialog = height / 2 - this.homeinvensibgimg.displayHeight / 2; //- buttonh;
    topofdialog =
      topofdialog + (this.homeinvensibgimg.displayHeight * 0.2 - 10);
    this.homeinvensihead.setPosition(width / 2, topofdialog);
    this.homeinvensitext.setPosition(width / 2, height / 2);
    this.homeinvensitext.setDepth(1500010);
    this.homeinvensihead.setDepth(1500010);
    this.homeinvenclosebtn.setDepth(1500010);

    var oldy = this.homeinvensisbtn.x;
    this.homeinvensisbtn.x = oldy;//disable tween to compat with gp.grid  + 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.homeinvensisbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    var oldy = this.homeinvensiibtn.x;
    this.homeinvensiibtn.x = oldy;//disable tween to compat with gp.grid  - 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.homeinvensiibtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    var oldy = this.homeinvensibgimg.y;
    this.homeinvensibgimg.y = oldy + 250;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.homeinvensibgimg,
      y: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    this.homeinvensihead.setText(wh.gl(head));
    this.homeinvensihead.setPosition(
      width / 2 - this.homeinvensihead.displayWidth / 2,
      topofdialog
    );

    this.homeinvensitext.setText(wh.gl(text));
    this.homeinvensitext.setPosition(
      width / 2 - this.homeinvensitext.displayWidth / 2,
      height / 2 - this.homeinvensitext.displayHeight / 2
    );

    this.homeinvenclosebtn.setPosition(
      width / 2 + this.homeinvensibgimg.displayWidth / 2,
      height / 2 - (this.homeinvensibgimg.displayHeight / 2 - 20)
    );


    wh.registry.list.gp.guigrid([
      this.homeinvenclosebtn,
      this.homeinvensisbtn,
      this.homeinvensiibtn,
    ], [
      this.homeinvensiibtn,
    ]);

    this.homeinvenclosebtn.removeAllListeners();
    this.homeinvenclosebtn.setInteractive();
    this.homeinvenclosebtn.on(
      'pointerup',
      function () {
        //this.plog.log("homeinvensibtn",this,arguments);
        //close
        this.plog.log('close');
        this.homeinvensipass = 'close';
        this.homeinvensi_close();
        //wh.kbfocus="";
        wh.tweens.add({
          targets: [
            this.homeinvensibg,
            this.homeinvensiibtn,
            this.homeinvensisbtn,
            this.homeinvensihead,
            this.homeinvensitext,
            this.homeinvensibgimg,
            this.homeinvensibg,
            this.homeinvenclosebtn,
          ],
          alpha: 0,
          ease: 'Power1',
          duration: 300,
          yoyo: false,
          repeat: 0,
          onComplete: function () {
            //this.plog.log('osdflash tweencomplete', arguments);
            arguments[1][0].visible = true;
            arguments[1][1].visible = true;
            arguments[1][2].visible = true;
            arguments[1][3].visible = true;
            arguments[1][4].visible = true;
            arguments[1][5].visible = true;
            arguments[1][6].visible = true;
            arguments[1][7].visible = true;
          },
        });
      },
      this
    );

    this.homeinvensiibtn.removeAllListeners();
    this.homeinvensiibtn.setInteractive();
    this.homeinvensiibtn.on(
      'pointerup',
      function () {
        //this.plog.log("homeinvensibtn",this,arguments);
        this.plog.log('item');
        this.homeinvensipass = 'item';
        var invendata = wh.registry.list.rcvarpass.activechar;

        this.wh.registry.list.gp.guigrid_focushide();
        this.wh.kbfocus = "";
        wh.registry.list.homeinveni.showinventory(wh, invendata, 'home', true);

        wh.tweens.add({
          targets: [
            this.homeinvensibg,
            this.homeinvensiibtn,
            this.homeinvensisbtn,
            this.homeinvensihead,
            this.homeinvensitext,
            this.homeinvensibgimg,
            this.homeinvensibg,
            this.homeinvenclosebtn,
          ],
          alpha: 0,
          ease: 'Power1',
          duration: 300,
          yoyo: false,
          repeat: 0,
          onComplete: function () {
            //this.plog.log('osdflash tweencomplete', arguments);
            arguments[1][0].visible = true;
            arguments[1][1].visible = true;
            arguments[1][2].visible = true;
            arguments[1][3].visible = true;
            arguments[1][4].visible = true;
            arguments[1][5].visible = true;
            arguments[1][6].visible = true;
            arguments[1][7].visible = true;
          },
        });
      },
      this
    );

    this.homeinvensisbtn.removeAllListeners();
    this.homeinvensisbtn.setInteractive();
    this.homeinvensisbtn.on(
      'pointerup',
      function () {
        //this.plog.log("homeinvensibtn",this,arguments);
        //i

        this.wh.registry.list.gp.guigrid_focushide();
        this.wh.kbfocus = "";
        var invendata = wh.registry.list.rcvarpass.activechar;
        wh.registry.list.homeinvens.showinventory(wh, invendata, 'home', true);

        wh.tweens.add({
          targets: [
            this.homeinvensibg,
            this.homeinvensiibtn,
            this.homeinvensisbtn,
            this.homeinvensihead,
            this.homeinvensitext,
            this.homeinvensibgimg,
            this.homeinvensibg,
            this.homeinvenclosebtn,
          ],
          alpha: 0,
          ease: 'Power1',
          duration: 300,
          yoyo: false,
          repeat: 0,
          onComplete: function () {
            //this.plog.log('osdflash tweencomplete', arguments);
            arguments[1][0].visible = true;
            arguments[1][1].visible = true;
            arguments[1][2].visible = true;
            arguments[1][3].visible = true;
            arguments[1][4].visible = true;
            arguments[1][5].visible = true;
            arguments[1][6].visible = true;
            arguments[1][7].visible = true;
          },
        });
      },
      this
    );
    this.lcamman(wh);
    while (this.homeinvensipass == '') await this.timeout(50); // pauses script
    this.plog.log('this.homeinvensipass', this.homeinvensipass);
    return this.homeinvensipass;
  }
  homeinvensi_close() {
    this.homeinvensipass = '';
    this.homeinvensisbtn.visible = false;
    this.homeinvensiibtn.visible = false;
    this.homeinvensihead.visible = false;
    this.homeinvensitext.visible = false;
    this.homeinvensibgimg.visible = false;
    this.homeinvensibg.visible = false;
    this.homeinvenclosebtn.visible = false;
    this.wh.registry.list.gp.guigrid_focushide();
    this.wh.kbfocus = "";
  }

  async rcconfirm(wh, head, text) {
    wh.kbfocuslv1 = "rcconfirm";
    this.plog.log('rcconfirm', wh, head, text);
    this.rcconfirmpass = '';
    this.plog.log('rcconfirm wh.kbfocuslv1', wh.kbfocuslv1);

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    var localwidth = Math.min(width, height);
    if (localwidth > 550) localwidth = 550;
    var buttonw = localwidth / 4;
    var buttonh = buttonw * 0.4;

    if (this.rcconfirmbg == undefined) {
      var rcconfirmbg = wh.add.rectangle(0, 0, width, height, 0xffffff);
      rcconfirmbg.setAlpha(0.7);
      rcconfirmbg.setPosition(0, 0);
      rcconfirmbg.setOrigin(0, 0);
      rcconfirmbg.setAlpha(0.5);
      rcconfirmbg.visible = true;
      rcconfirmbg.setDepth(1500000);
      rcconfirmbg.setInteractive();
      this.rcconfirmbg = rcconfirmbg;

      this.rcconfirmbgimg = wh.add.image(300, 300, 'dialogbg');
      this.rcconfirmbgimg.setOrigin(0.5, 0.5);
      this.rcconfirmbgimg.setPosition(width / 2, height / 2);
      //this.plog.log("localwidth", localwidth);
      //if (localwidth)
      this.rcconfirmbgimg.displayWidth = localwidth * 0.9;
      this.rcconfirmbgimg.scaleY = this.rcconfirmbgimg.scaleX;
      this.rcconfirmbgimg.setDepth(1500003);
      //this.rcconfirmbgimg.visible=false;
      this.plog.log('rcconfirmbgimg', this.rcconfirmbgimg);

      this.rcconfirmbtn = wh.add.sprite(0, 0, "thesb", "confirm");//wh.add.image(300, 300, 'confirmbtn');
      this.rcconfirmbtn.setOrigin(0.5, 0.5);
      var btmofdialog = height / 2 + this.rcconfirmbgimg.displayHeight / 2; //- buttonh;
      btmofdialog = btmofdialog - this.rcconfirmbgimg.displayHeight * 0.2; //margin
      this.rcconfirmbtn.setPosition(width / 2 + buttonw / 2 + 5, btmofdialog);
      this.plog.log(
        'rcconfirmbtn.setPosition',
        btmofdialog,
        width / 2 - (buttonw + 5)
      );
      this.rcconfirmbtn.displayWidth = buttonw;
      this.rcconfirmbtn.displayHeight = buttonh;

      this.rccancelbtn = wh.add.sprite(0, 0, "thesb", "cancel");//wh.add.image(300, 300, 'cancelbtn');
      this.rccancelbtn.setOrigin(0.5, 0.5);
      this.rccancelbtn.setPosition(width / 2 - (buttonw / 2 + 5), btmofdialog);

      this.rccancelbtn.displayWidth = buttonw;
      this.rccancelbtn.displayHeight = buttonh;
      this.rcconfirmhead = wh.add.text(0, 0, 'head', wh.fs["rcalert_head"]);
      this.rcconfirmtext = wh.add.text(0, 0, 'text', wh.fs["rcalert_text"]);
      this.rcconfirmtext.setWordWrapWidth(this.rcconfirmbgimg.displayWidth * 0.7);

    }

    this.rcconfirmtext.setText(text);

    this.rcconfirmtext.setText(text);
    //chk if text fit the bg
    var expectedheight = this.rcconfirmtext.displayHeight + 200;
    this.plog.log("expectedheight", expectedheight, this.rcconfirmbgimg.displayHeight)
    if (this.rcconfirmbgimg.displayHeight < expectedheight) {
      this.rcconfirmbgimg.displayHeight = expectedheight;
      this.plog.log("expectedheight adjusting", expectedheight, this.rcconfirmbgimg.displayHeight)
    }

    this.rcconfirmbtn.visible = true;
    this.rccancelbtn.visible = true;
    this.rcconfirmhead.visible = true;
    this.rcconfirmtext.visible = true;
    this.rcconfirmbgimg.visible = true;
    this.rcconfirmbg.visible = true;
    this.rcconfirmbtn.setAlpha(1);
    this.rccancelbtn.setAlpha(1);
    this.rcconfirmhead.setAlpha(1);
    this.rcconfirmtext.setAlpha(1);
    this.rcconfirmbgimg.setAlpha(1);
    this.rcconfirmbg.setAlpha(0.5);
    this.rcconfirmhead.setStyle({ fixedWidth: this.rcconfirmbgimg.displayWidth * 0.7 })

    var btmofdialog = height / 2 + this.rcconfirmbgimg.displayHeight / 2; //- buttonh;
    btmofdialog = btmofdialog - this.rcconfirmbgimg.displayHeight * 0.1; //margin

    this.rccancelbtn.setPosition(width / 2 - (buttonw / 2 + 5), btmofdialog);
    this.rcconfirmbtn.setPosition(width / 2 + buttonw / 2 + 5, btmofdialog);
    this.rcconfirmbtn.setDepth(1500015);
    this.rccancelbtn.setDepth(1500015);

    this.plog.log("expectedheight chkbtn", this.rcconfirmbtn)


    var topofdialog = height / 2 - this.rcconfirmbgimg.displayHeight / 2; //- buttonh;
    topofdialog = topofdialog + this.rcconfirmbgimg.displayHeight * 0.15;
    this.rcconfirmhead.setPosition(width / 2, topofdialog);
    this.rcconfirmtext.setPosition(width / 2, height / 2);
    this.rcconfirmtext.setDepth(1500010);
    this.rcconfirmhead.setDepth(1500010);

    var oldy = this.rcconfirmbtn.x;
    this.rcconfirmbtn.x = oldy + 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcconfirmbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    var oldy = this.rccancelbtn.x;
    this.rccancelbtn.x = oldy - 70;
    //this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rccancelbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    this.rcconfirmbgimg.setPosition(width / 2, height / 2);

    var oldy = this.rcconfirmbgimg.y;
    this.rcconfirmbgimg.y = oldy + 250;
    //this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcconfirmbgimg,
      y: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    this.rcconfirmhead.setText(head);
    this.rcconfirmhead.setPosition(
      width / 2 - this.rcconfirmhead.displayWidth / 2,
      topofdialog
    );

    this.rcconfirmtext.setPosition(
      width / 2 - this.rcconfirmtext.displayWidth / 2,
      height / 2 - this.rcconfirmtext.displayHeight / 2
    );
    this.rcconfirmbtn.removeAllListeners();
    this.rcconfirmbtn.setInteractive();
    this.rcconfirmbtn.on(
      'pointerup',
      function () {
        wh.kbfocuslv1 = "";
        //this.plog.log("rcconfirmbtn",this,arguments);
        this.rcconfirmpass = 'ok';
        this.rcconfirm_clickclose(wh);
      },
      this
    );

    this.rccancelbtn.removeAllListeners();
    this.rccancelbtn.setInteractive();
    this.rccancelbtn.on(
      'pointerup',
      function () {
        wh.kbfocuslv1 = "";
        //this.plog.log("rcconfirmbtn",this,arguments);
        this.rcconfirmpass = 'cancel';
        this.rcconfirm_clickclose(wh);
      },
      this
    );
    this.lcamman(wh);
    this.plog.log('this.rcconfirmpass wh.kbfocuslv1 b', wh.kbfocuslv1);
    while (this.rcconfirmpass == '') await this.timeout(50); // pauses script
    this.plog.log('this.rcconfirmpass', this.rcconfirmpass, wh.kbfocuslv1);
    return this.rcconfirmpass;
  }
  rcconfirm_clickclose_ok(wh) {
    this.plog.log("rcconfirm_clickclose_ok(wh)");
    this.rcconfirmpass = 'ok';
    this.rcconfirm_clickclose(wh);
  }
  rcconfirm_clickclose_cancel(wh) {
    this.plog.log("rcconfirm_clickclose_cancel(wh)");
    this.rcconfirmpass = 'cancel';
    this.rcconfirm_clickclose(wh);
  }
  rcconfirm_clickclose(wh) {
    for (let desi = 0; desi < 10; desi++) {




      try {

        this.rcconfirmbg.destroy();
        this.rcconfirmbtn.destroy();
        this.rccancelbtn.destroy();
        this.rcconfirmhead.destroy();
        this.rcconfirmtext.destroy();
        this.rcconfirmbgimg.destroy();
        this.rcconfirmbg.destroy();
      } catch (e) {
        console.log(e);
      }
    }
    this.rcconfirmbg = undefined;
    this.rcconfirmbtn = undefined;
    this.rccancelbtn = undefined;
    this.rcconfirmhead = undefined;
    this.rcconfirmtext = undefined;
    this.rcconfirmbgimg = undefined;
    this.rcconfirmbg = undefined;
    return;
    wh.tweens.add({
      targets: [
        this.rcconfirmbg,
        this.rcconfirmbtn,
        this.rccancelbtn,
        this.rcconfirmhead,
        this.rcconfirmtext,
        this.rcconfirmbgimg,
        this.rcconfirmbg,
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        // this.plog.log('osdflash tweencomplete', arguments);
        arguments[1][0].visible = true;
        arguments[1][1].visible = true;
        arguments[1][2].visible = true;
        arguments[1][3].visible = true;
        arguments[1][4].visible = true;
        arguments[1][5].visible = true;
        arguments[1][6].visible = true;
        try {

          arguments[1][0].destroy();
          arguments[1][1].destroy();
          arguments[1][2].destroy();
          arguments[1][3].destroy();
          arguments[1][4].destroy();
          arguments[1][5].destroy();
          arguments[1][6].destroy();
        } catch (e) {
          console.log(e);
        }
      },
    });
  }
  rcconfirm_close() {
    this.rcconfirmpass = '';
    this.rcconfirmbtn.visible = false;
    this.rccancelbtn.visible = false;
    this.rcconfirmhead.visible = false;
    this.rcconfirmtext.visible = false;
    this.rcconfirmbgimg.visible = false;
    this.rcconfirmbg.visible = false;
    try {

      this.rcconfirmbtn.destroy();
      this.rccancelbtn.destroy();
      this.rcconfirmhead.destroy();
      this.rcconfirmtext.destroy();
      this.rcconfirmbgimg.destroy();
      this.rcconfirmbg.destroy();
    } catch (e) {
      console.log(e);
    }
  }
  rcdedpass;
  rcdedbg;
  rcdedbgimg
  rcdedbtn
  rcdedtext
  async rcded(wh, text) {
    this.plog.log('rcded', wh);
    this.rcdedpass = '';
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.rcdedbg != undefined) {
      this.rcdedbgimg.destroy();
      this.rcdedbgimg = undefined;
      this.rcdedbg.destroy();
      this.rcdedbg = undefined;
      this.rcdedbtn.destroy();
      this.rcdedbtn = undefined;
      this.rcdedtext.destroy();
      this.rcdedtext = undefined;
    }
    var rcdedbg = wh.add.rectangle(0, 0, width, height, 0x000000);
    rcdedbg.setAlpha(0.7);
    rcdedbg.setPosition(0, 0);
    rcdedbg.setOrigin(0, 0);
    rcdedbg.setAlpha(0.5);
    rcdedbg.visible = true;
    rcdedbg.setDepth(1500000);
    rcdedbg.setInteractive();
    this.rcdedbg = rcdedbg;

    this.rcdedbgimg = wh.add.image(300, 300, 'dialogdedbg');
    this.rcdedbgimg.setOrigin(0.5, 0.5);
    var localwidth = Math.min(width, height);
    if (localwidth > 550) localwidth = 550;
    //this.plog.log("localwidth", localwidth);
    //if (localwidth)
    this.rcdedbgimg.displayWidth = localwidth * 0.9;
    this.rcdedbgimg.scaleY = this.rcdedbgimg.scaleX;
    this.rcdedbgimg.setDepth(1500003);
    //this.rcconfirmbgimg.visible=false;
    this.plog.log('rcdedbgimg', this.rcdedbgimg);

    this.rcdedbtn = wh.add.sprite(0, 0, "thesb", "confirm");//wh.add.image(300, 300, 'confirmbtn');
    this.rcdedbtn.setOrigin(0.5, 0.5);
    var buttonw = localwidth / 4;
    var buttonh = buttonw * 0.4;

    this.rcdedtext = wh.add.text(0, 0, 'text', wh.fs["rcded_text"]);
    this.rcdedtext.setWordWrapWidth(this.rcdedbgimg.displayWidth * 0.8);


    this.rcdedbtn.visible = true;
    this.rcdedtext.visible = true;
    this.rcdedbgimg.visible = true;
    this.rcdedbg.visible = true;
    this.rcdedbtn.setAlpha(1);
    this.rcdedtext.setAlpha(1);
    this.rcdedbgimg.setAlpha(1);
    this.rcdedbg.setAlpha(0.7);




    this.rcdedtext.setText(this.gl(text));
    //chk if text fit the bg
    var expectedheight = this.rcdedtext.displayHeight + 200;
    this.plog.log("expectedheight", expectedheight, this.rcdedbgimg.displayHeight)
    if (this.rcdedbgimg.displayHeight < expectedheight) {
      this.rcdedbgimg.displayHeight = expectedheight;
      this.plog.log("expectedheight adjusting", expectedheight, this.rcdedbgimg.displayHeight)
    }


    var btmofdialog =
      height / 2 + this.rcdedbgimg.displayHeight / 2 - buttonh;
    // ;
    //btmofdialog = btmofdialog - (this.rcdedbtn.displayHeight * 0.2); //margin
    this.plog.log(
      'rcdedbtn.setPosition',
      btmofdialog,
      width / 2 - (buttonw + 5)
    );
    this.rcdedbtn.displayWidth = buttonw;
    this.rcdedbtn.displayHeight = buttonh;
    this.rcdedbtn.setDepth(1500005);

    this.rcdedbgimg.setPosition(width / 2, height / 2);
    this.rcdedbtn.setPosition(width / 2, btmofdialog);

    var topofdialog = height / 2 - this.rcdedbgimg.displayHeight / 2; //- buttonh;
    topofdialog = topofdialog + this.rcdedbgimg.displayHeight * 0.15;
    this.rcdedtext.setPosition(width / 2, height / 2);
    this.rcdedtext.setDepth(1500010);


    var oldy = this.rcdedbtn.y;
    this.rcdedbtn.y = oldy + 100;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcdedbtn,
      y: oldy,
      ease: 'Power1',
      duration: 1300,
      yoyo: false,
      repeat: 0,
    });


    var oldy = this.rcdedbgimg.y;
    //this.rcdedbgimg.y = oldy + 250;
    this.plog.log('tween', wh);
    //this.rcdedbgimg.alpha=0;
    /*
    this.rcdedbgimg.setAlpha(0);
    var tw = wh.tweens.add({
      targets: this.rcdedbgimg,
      alpha: 1,
      ease: 'Bounce',
      duration: 2000,
      yoyo: false,
      repeat: 0,
    });*/

    this.rcdedtext.setPosition(
      width / 2 - this.rcdedtext.displayWidth / 2,
      height / 2 - this.rcdedtext.displayHeight / 2
    );
    this.rcdedbtn.removeAllListeners();
    this.rcdedbtn.setInteractive();
    this.rcdedbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcconfirmbtn",this,arguments);
        this.rcdedpass = 'ok';
        wh.tweens.add({
          targets: [
            this.rcdedbg,
            this.rcdedbtn,
            this.rcdedtext,
            this.rcdedbgimg,
            this.rcdedbg,
          ],
          alpha: 0,
          ease: 'Power1',
          duration: 300,
          yoyo: false,
          repeat: 0,
          onComplete: function () {
            //this.plog.log('osdflash tweencomplete', this);
            return;
            arguments[1][0].visible = true;
            arguments[1][1].visible = true;
            arguments[1][2].visible = true;
            arguments[1][3].visible = true;
            arguments[1][4].visible = true;
          },
        });
      },
      this
    );
    this.lcamman(wh);
    while (this.rcdedpass == '') await this.timeout(50); // pauses script
    this.plog.log('this.rcdedpass', this.rcdedpass);
    return this.rcdedpass;
  }
  async rcalert(wh, head, text) {
    this.plog.log('rcalert', wh);
    this.rcalertpass = '';
    wh.kbfocuslv1 = "rcalert";
    if (wh.registry.list.gameitf.guihidestatus == "hide") {
      wh.registry.list.gameitf.showgui(wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.rcaconfirmbg != undefined) {
      this.rcaconfirmbgimg.destroy();
      this.rcaconfirmbgimg = undefined;
      this.rcaconfirmbg.destroy();
      this.rcaconfirmbg = undefined;
      this.rcaconfirmbtn.destroy();
      this.rcaconfirmbtn = undefined;
      this.rcaconfirmhead.destroy();
      this.rcaconfirmhead = undefined;
      this.rcaconfirmtext.destroy();
      this.rcaconfirmtext = undefined;
    }
    var rcaconfirmbg = wh.add.rectangle(0, 0, width, height, 0xffffff);
    rcaconfirmbg.setAlpha(0.7);
    rcaconfirmbg.setPosition(0, 0);
    rcaconfirmbg.setOrigin(0, 0);
    rcaconfirmbg.setAlpha(0.5);
    rcaconfirmbg.visible = true;
    rcaconfirmbg.setDepth(1500000);
    rcaconfirmbg.setInteractive();
    this.rcaconfirmbg = rcaconfirmbg;

    this.rcaconfirmbgimg = wh.add.image(300, 300, 'dialogbg');
    this.rcaconfirmbgimg.setOrigin(0.5, 0.5);
    var localwidth = Math.min(width, height);
    if (localwidth > 550) localwidth = 550;
    //this.plog.log("localwidth", localwidth);
    //if (localwidth)
    this.rcaconfirmbgimg.displayWidth = localwidth * 0.9;
    this.rcaconfirmbgimg.scaleY = this.rcaconfirmbgimg.scaleX;
    this.rcaconfirmbgimg.setDepth(1500003);
    //this.rcconfirmbgimg.visible=false;
    this.plog.log('rcaconfirmbgimg', this.rcaconfirmbgimg);

    this.rcaconfirmbtn = wh.add.sprite(0, 0, "thesb", "confirm");//wh.add.image(300, 300, 'confirmbtn');
    this.rcaconfirmbtn.setOrigin(0.5, 0.5);
    var buttonw = localwidth / 4;
    var buttonh = buttonw * 0.4;

    this.rcaconfirmhead = wh.add.text(0, 0, 'head', wh.fs["rcalert_head"]);
    this.rcaconfirmtext = wh.add.text(0, 0, 'text', wh.fs["rcalert_text"]);
    this.rcaconfirmtext.setWordWrapWidth(this.rcaconfirmbgimg.displayWidth * 0.8);


    this.rcaconfirmbtn.visible = true;
    this.rcaconfirmhead.visible = true;
    this.rcaconfirmtext.visible = true;
    this.rcaconfirmbgimg.visible = true;
    this.rcaconfirmbg.visible = true;
    this.rcaconfirmbtn.setAlpha(1);
    this.rcaconfirmhead.setAlpha(1);
    this.rcaconfirmtext.setAlpha(1);
    this.rcaconfirmbgimg.setAlpha(1);
    this.rcaconfirmbg.setAlpha(0.5);




    this.rcaconfirmtext.setText(this.gl(text));
    //chk if text fit the bg
    var expectedheight = this.rcaconfirmtext.displayHeight + 200;
    this.plog.log("expectedheight", expectedheight, this.rcaconfirmbgimg.displayHeight)
    if (this.rcaconfirmbgimg.displayHeight < expectedheight) {
      this.rcaconfirmbgimg.displayHeight = expectedheight;
      this.plog.log("expectedheight adjusting", expectedheight, this.rcaconfirmbgimg.displayHeight)
    }


    var btmofdialog =
      height / 2 + this.rcaconfirmbgimg.displayHeight / 2 - buttonh;
    // ;
    //btmofdialog = btmofdialog - (this.rcaconfirmbtn.displayHeight * 0.2); //margin
    this.plog.log(
      'rcaconfirmbtn.setPosition',
      btmofdialog,
      width / 2 - (buttonw + 5)
    );
    this.rcaconfirmbtn.displayWidth = buttonw;
    this.rcaconfirmbtn.displayHeight = buttonh;
    this.rcaconfirmbtn.setDepth(1500005);

    this.rcaconfirmbgimg.setPosition(width / 2, height / 2);
    this.rcaconfirmbtn.setPosition(width / 2, btmofdialog);

    var topofdialog = height / 2 - this.rcaconfirmbgimg.displayHeight / 2; //- buttonh;
    topofdialog = topofdialog + this.rcaconfirmbgimg.displayHeight * 0.15;
    this.rcaconfirmtext.setPosition(width / 2, height / 2);
    this.rcaconfirmtext.setDepth(1500010);
    this.rcaconfirmhead.setDepth(1500010);
    this.rcaconfirmhead.setStyle({ fixedWidth: this.rcaconfirmbgimg.displayWidth * 0.7 })


    var oldy = this.rcaconfirmbtn.x;
    this.rcaconfirmbtn.x = oldy - 70;
    //this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcaconfirmbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });


    var oldy = this.rcaconfirmbgimg.y;
    this.rcaconfirmbgimg.y = oldy + 250;
    //this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcaconfirmbgimg,
      y: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    var headpos = topofdialog - 10;
    if (width < 400) {
      var headpos = topofdialog - 5;
    }
    this.rcaconfirmhead.setText(this.gl(head));
    this.rcaconfirmhead.setPosition(
      width / 2 - this.rcaconfirmhead.displayWidth / 2,
      headpos
    );

    this.rcaconfirmtext.setPosition(
      width / 2 - this.rcaconfirmtext.displayWidth / 2,
      height / 2 - this.rcaconfirmtext.displayHeight / 2
    );
    this.rcaconfirmbtn.removeAllListeners();
    this.rcaconfirmbtn.setInteractive();
    this.rcaconfirmbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcconfirmbtn",this,arguments);
        this.rcalertpass = 'ok';
        this.rcalert_clickclosebtn(wh);
      },
      this
    );
    this.lcamman(wh);
    while (this.rcalertpass == '') await this.timeout(50); // pauses script
    this.plog.log('this.rcaconfirmpass', this.rcconfirmpass);
    wh.kbfocus = "";

    return this.rcalertpass;
  }
  async rcalert_clickclosebtn(wh) {

    wh.tweens.add({
      targets: [
        this.rcaconfirmbg,
        this.rcaconfirmbtn,
        this.rcaconfirmhead,
        this.rcaconfirmtext,
        this.rcaconfirmbgimg,
        this.rcaconfirmbg,
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        //this.plog.log('osdflash tweencomplete', this);
        arguments[1][0].visible = true;
        arguments[1][1].visible = true;
        arguments[1][2].visible = true;
        arguments[1][3].visible = true;
        arguments[1][4].visible = true;
        arguments[1][5].visible = true;
      },
    });
  }
  async rclongalert(wh, head, text) {
    this.plog.log('rclongalert', wh);
    this.rcalertpass = '';
    wh.kbfocuslv1 = "rclongalert";
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.rcaLconfirmbg == undefined) {
      var rcaLconfirmbg = wh.add.rectangle(0, 0, width, height, 0xffffff);
      rcaLconfirmbg.setAlpha(0.7);
      rcaLconfirmbg.setPosition(0, 0);
      rcaLconfirmbg.setOrigin(0, 0);
      rcaLconfirmbg.setAlpha(0.5);
      rcaLconfirmbg.visible = true;
      rcaLconfirmbg.setDepth(1500000);
      rcaLconfirmbg.setInteractive();
      this.rcaLconfirmbg = rcaLconfirmbg;

      this.rcaLconfirmbgimg = wh.add.image(300, 300, 'dialogbglong');
      this.rcaLconfirmbgimg.setOrigin(0.5, 0.5);
      this.rcaLconfirmbgimg.setPosition(width / 2, height / 2);
      var localwidth = Math.min(width, height);
      if (localwidth > 550) localwidth = 550;
      //this.plog.log("localwidth", localwidth);
      //if (localwidth)
      this.rcaLconfirmbgimg.displayWidth = width;
      this.rcaLconfirmbgimg.displayHeight = height;
      this.rcaLconfirmbgimg.setDepth(1500003);
      //this.rcconfirmbgimg.visible=false;
      //this.plog.log("rcaLconfirmbgimg", this.rcaLconfirmbgimg);

      this.rcaLconfirmbtn = wh.add.sprite(0, 0, "thesb", "confirm");// wh.add.image(300, 300, 'confirmbtn');
      this.rcaLconfirmbtn.setOrigin(0.5, 0.5);
      var buttonw = localwidth / 4;
      var buttonh = buttonw * 0.4;
      var btmofdialog =
        height / 2 + this.rcaLconfirmbgimg.displayHeight / 2 - buttonh;
      // ;
      btmofdialog = btmofdialog - this.rcaLconfirmbtn.displayHeight * 0.1; //margin
      this.rcaLconfirmbtn.setPosition(width / 2, btmofdialog);
      this.plog.log(
        'rcaLconfirmbtn.setPosition',
        btmofdialog,
        width / 2 - (buttonw + 5)
      );
      this.rcaLconfirmbtn.displayWidth = buttonw;
      this.rcaLconfirmbtn.displayHeight = buttonh;
      this.rcaLconfirmbtn.setDepth(1500005);

      this.rcaLconfirmhead = wh.add.text(0, 0, 'head', wh.fs["rcalert_head"]);
      this.rcaLconfirmtext = wh.add.text(0, 0, wh.gl(text), wh.fs["rcalert_text"]);
      this.rcaLconfirmtext.setWordWrapWidth(this.rcaLconfirmbgimg.displayWidth);

    }

    this.rcaLconfirmbtn.visible = true;
    this.rcaLconfirmhead.visible = true;
    this.rcaLconfirmtext.visible = true;
    this.rcaLconfirmbgimg.visible = true;
    this.rcaLconfirmbg.visible = true;
    this.rcaLconfirmbtn.setAlpha(1);
    this.rcaLconfirmhead.setAlpha(1);
    this.rcaLconfirmtext.setAlpha(1);
    this.rcaLconfirmbgimg.setAlpha(1);
    this.rcaLconfirmbg.setAlpha(0.5);

    var topofdialog = height / 2 - this.rcaLconfirmbgimg.displayHeight / 2; //- buttonh;
    topofdialog = topofdialog + this.rcaLconfirmbgimg.displayHeight * 0.2;
    this.rcaLconfirmhead.setPosition(width / 2, topofdialog);
    this.rcaLconfirmtext.setPosition(width / 2, height / 2);
    this.rcaLconfirmtext.setDepth(1500010);
    this.rcaLconfirmhead.setDepth(1500010);

    var oldy = this.rcaLconfirmbtn.x;
    this.rcaLconfirmbtn.x = oldy - 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcaLconfirmbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    var oldy = this.rcaLconfirmbgimg.y;
    this.rcaLconfirmbgimg.y = oldy + 250;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcaLconfirmbgimg,
      y: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    this.rcaLconfirmhead.setText(this.gl(head));
    this.rcaLconfirmhead.setPosition(
      width / 2 - this.rcaLconfirmhead.displayWidth / 2,
      topofdialog
    );

    this.rcaLconfirmtext.setOrigin(0.5, 0.5);

    this.rcaLconfirmtext.setPosition(width / 2, height / 2);

    //this.rcaLconfirmtext.displayWidth = width * 0.8;
    if (width < 600) {
      //this.rcaLconfirmtext.displayWidth = width * 0.8;
      this.rcaLconfirmtext.setWordWrapWidth(width * 0.8);
    }
    this.rcaLconfirmbtn.removeAllListeners();
    this.rcaLconfirmbtn.setInteractive();
    this.rcaLconfirmbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcconfirmbtn",this,arguments);
        this.rcconfirmpass = 'ok';
        this.rclongalert_clickclosebtn(wh);
      },
      this
    );
    this.lcamman(wh);
    while (this.rcconfirmpass == '') await this.timeout(50); // pauses script
    this.plog.log('this.rcaLconfirmpass', this.rcconfirmpass);

    wh.kbfocus = "";
    return this.rcconfirmpass;
  }
  async rclongalert_clickclosebtn(wh) {

    wh.tweens.add({
      targets: [
        this.rcaLconfirmbg,
        this.rcaLconfirmbtn,
        this.rcaLconfirmhead,
        this.rcaLconfirmtext,
        this.rcaLconfirmbgimg,
        this.rcaLconfirmbg,
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        //this.plog.log('osdflash tweencomplete', this);
        arguments[1][0].visible = true;
        arguments[1][1].visible = true;
        arguments[1][2].visible = true;
        arguments[1][3].visible = true;
        arguments[1][4].visible = true;
        arguments[1][5].visible = true;
      },
    });
  }
  rcalert_close() {
    this.rcconfirmpass = '';
    this.rcconfirmbtn.visible = false;
    this.rcconfirmhead.visible = false;
    this.rcconfirmtext.visible = false;
    this.rcconfirmbgimg.visible = false;
    this.rcconfirmbg.visible = false;
  }
  async confirm(head, text) {
    return new Promise(async (resolve) => {
      const confirm = await this.alertcontroller.create({
        header: head,
        message: text,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: (event) => {
              this.plog.log('confirmevent', arguments);
              try {
                event.preventDefault();
              } catch (error) {
                this.plog.log('ERROR event.preventDefault();', error);
              }
              try {
                event.stopPropagation();
              } catch (error) {
                this.plog.log('ERROR event.stopPropagation();', error);
              }
              try {
                event.stopImmediatePropagation();
              } catch (error) {
                this.plog.log('ERROR event.stopImmediatePropagation();', error);
              }
              return resolve(false);
            },
          },
          {
            text: 'Confirm',
            handler: (event) => {
              try {
                event.preventDefault();
              } catch (error) {
                this.plog.log('ERROR event.preventDefault();', error);
              }
              try {
                event.stopPropagation();
              } catch (error) {
                this.plog.log('ERROR event.stopPropagation();', error);
              }
              try {
                event.stopImmediatePropagation();
              } catch (error) {
                this.plog.log('ERROR event.stopImmediatePropagation();', error);
              }

              return resolve(true);
            },
          },
        ],
      });

      await confirm.present();
      const { role } = await confirm.onDidDismiss();
      this.plog.log('onDidDismiss resolved with role', role);
    });
  }
  async alert(headstr: string, subheadstr: string, msg: string) {
    const alert = await this.alertcontroller.create({
      cssClass: 'my-custom-class',
      header: '' + headstr,
      subHeader: '' + subheadstr,
      message: '' + msg,
      buttons: [{
        text: 'Okay',
        handler: async function () {
          // user has clicked the alert button
          // begin the alert's dismiss transition
          this.plog.log("xxxxxxxxxxxxxxx", alert);
          await this.sleep(500);
          return false;
        }.bind(this)
      }]
      //backdropDismiss: false
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    this.plog.log('onDidDismiss resolved with role', role);
  }
  async confirmbox_logout() {
    const alert = await this.alertcontroller.create({
      cssClass: 'my-custom-class',
      header: 'Confirm',
      subHeader: 'are you sure to logout?',
      message: '',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.plog.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Logout',
          role: 'confirm',
          cssClass: 'warning',
          handler: () => {
            this.plog.log('Confirm Okay');
          },
        },
      ],
    });
    await alert.present();

    const { role } = await alert.onDidDismiss();
    this.plog.log('onDidDismiss resolved with role', role);
    return role;
  }

  async presentToastWithOptions() {
    const toast = await this.toastController.create({
      header: 'Toast header',
      message: 'Click to Close',
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'star',
          text: 'Favorite',
          handler: () => {
            this.plog.log('Favorite clicked');
          },
        },
        {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            this.plog.log('Cancel clicked');
          },
        },
      ],
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
    //this.plog.log('onDidDismiss resolved with role', role);
  }

  localmap(wh, mapid) {
    this.wh = wh;
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.localmapbg != undefined) {
      this.localmapbg.destroy();
      this.localmapbg = undefined;
    }
    if (this.localmapbgimg != undefined) {
      this.localmapbgimg.destroy();
      this.localmapbgimg = undefined;
    }

    if (this.localmapimg != undefined) {
      this.localmapimg.destroy();
      this.localmapimg = undefined;
    }
    if (this.localmaptext != undefined) {
      this.localmaptext.destroy();
      this.localmaptext = undefined;
    }

    this.localmapbg = wh.add.rectangle(0, 0, width, height, 0xffffff);
    this.localmapbg.setAlpha(0.7);
    this.localmapbg.setPosition(0, 0);
    this.localmapbg.setOrigin(0, 0);
    this.localmapbg.setAlpha(0.5);
    this.localmapbg.visible = true;
    this.localmapbg.setDepth(1500000);
    this.localmapbg.setInteractive();

    this.localmapbgimg = wh.add.image(0, 0, 'localmapguibg');
    this.localmapbgimg.setOrigin(0.5, 0.5);
    this.localmapbgimg.setPosition(width / 2, height / 2);
    if (width > height * 0.8) {
      this.localmapbgimg.displayHeight = height;
      this.localmapbgimg.scaleX = this.localmapbgimg.scaleY;
    } else {
      this.localmapbgimg.displayWidth = width;
      this.localmapbgimg.scaleY = this.localmapbgimg.scaleX;
    }
    this.localmapbgimg.setDepth(1500003);
    this.localmapbg.on(
      'pointerup',
      function () {
        this.localmap_close();
      },
      this
    );

    this.localmapimg = wh.add.image(0, 0, 'localmap_' + mapid);
    this.localmapimg.displayWidth = this.localmapbgimg.displayWidth * 0.6;
    this.localmapimg.scaleY = this.localmapimg.scaleX;
    this.localmapimg.setOrigin(0.5, 0);
    this.localmapimg.setPosition(
      width / 2,
      this.localmapbgimg.y - this.localmapbgimg.displayHeight * 0.3
    );
    this.localmapimg.setDepth(1500005);
    this.plog.log('this.localmapbgimg', this.localmapbgimg);
    this.plog.log('this.localmapimg', this.localmapimg);

    this.localmaptext = wh.add.text(0, 0, '..', wh.fs["rcalert_text"]);
    //this.plog.log("localmaptext",wh);
    this.localmaptext.setText(
      wh.gl(wh.registry.list.rcvarpass.activemap['map']['localmaptext'])
    );
    this.localmaptext.setWordWrapWidth(this.localmapimg.displayWidth);
    this.localmaptext.setDepth(1500010);
    this.localmaptext.setPosition(
      this.localmapimg.x - this.localmapimg.displayWidth / 2,
      this.localmapimg.y + this.localmapimg.displayHeight
    );
    this.lcamman(wh);
    wh.kbfocuslv1 = "localmap";
  }
  localmap_close() {

    this.wh.tweens.add({
      targets: [
        this.localmapimg,
        this.localmaptext,
        this.localmapbgimg,
        this.localmapbg
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      delay: 100,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        //this.plog.log('localmapclose tweencomplete', this);
        arguments[1][0].destroy();
        arguments[1][1].destroy();
        arguments[1][2].destroy();
        arguments[1][3].destroy();
      },
    });
    return;
    if (this.localmapbg != undefined) {
      this.localmapbg.destroy();//true
      this.localmapbg = undefined;
    }
    if (this.localmapbgimg != undefined) {
      this.localmapbgimg.destroy();//true
      this.localmapbgimg = undefined;
    }
    if (this.localmaptext != undefined) {
      this.localmaptext.destroy();//true
      this.localmaptext = undefined;
    }
    if (this.localmapimg != undefined) {
      this.localmapimg.destroy();//true
      this.localmapimg = undefined;
    }

  }

  npcvideo(wh, mapid, descr) {
    this.plog.log("npcvideo", mapid, descr);
    this.wh = wh;
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.npcvideobg != undefined) {
      this.npcvideobg.destroy();
      this.npcvideobg = undefined;
    }
    if (this.npcvideobgimg != undefined) {
      this.npcvideobgimg.destroy();
      this.npcvideobgimg = undefined;
    }

    if (this.npcvideoimg != undefined) {
      this.npcvideoimg.destroy();
      this.npcvideoimg = undefined;
    }
    if (this.npcvideotext != undefined) {
      this.npcvideotext.destroy();
      this.npcvideotext = undefined;
    }

    this.npcvideobg = wh.add.rectangle(0, 0, width, height, 0xffffff);
    this.npcvideobg.setAlpha(0.7);
    this.npcvideobg.setPosition(0, 0);
    this.npcvideobg.setOrigin(0, 0);
    this.npcvideobg.setAlpha(0.5);
    this.npcvideobg.visible = true;
    this.npcvideobg.setDepth(1500000);
    this.npcvideobg.setInteractive();

    this.npcvideobgimg = wh.add.image(0, 0, 'paperguibg');
    this.npcvideobgimg.setOrigin(0.5, 0.5);
    this.npcvideobgimg.setPosition(width / 2, height / 2);
    if (width > height * 0.8) {
      this.npcvideobgimg.displayHeight = height;
      this.npcvideobgimg.scaleX = this.npcvideobgimg.scaleY;
    } else {
      this.npcvideobgimg.displayWidth = width;
      this.npcvideobgimg.scaleY = this.npcvideobgimg.scaleX;
    }
    this.npcvideobgimg.setDepth(1500003);
    this.npcvideobg.on(
      'pointerup',
      function () {
        this.npcvideo_close();
      },
      this
    );

    //this.npcvideoimg = wh.add.image(0, 0, 'npcvideo_' + mapid);
    this.npcvideoimg = wh.add.video(0, 0, 'npcvideo_' + mapid);
    //this.npcvideoimg = wh.add.video(0, 0);
    this.npcvideoimg.once('play', () => {

      this.npcvideoimg.setDisplaySize(this.npcvideobgimg.displayWidth * 0.6, this.npcvideobgimg.displayWidth * 0.6);

    });
    this.npcvideoimg.play(true);
    this.npcvideoimg.displayWidth = this.npcvideobgimg.displayWidth * 0.6;
    //this.npcvideoimg.scaleY = this.npcvideoimg.scaleX;
    this.npcvideoimg.displayHeight = this.npcvideoimg.displayWidth;
    this.npcvideoimg.setOrigin(0.5, 0);
    this.npcvideoimg.setPosition(
      width / 2,
      this.npcvideobgimg.y - this.npcvideobgimg.displayHeight * 0.4
    );
    this.npcvideoimg.setDepth(1500005);
    this.plog.log('this.npcvideobgimg', this.npcvideobgimg);
    this.plog.log('this.npcvideoimg', this.npcvideoimg);

    this.npcvideotext = wh.add.text(0, 0, '..', wh.fs["rcalert_text"]);
    //this.plog.log("npcvideotext",wh);
    this.npcvideotext.setText(
      wh.gl(descr)
    );
    this.npcvideotext.setWordWrapWidth(this.npcvideoimg.displayWidth);
    this.npcvideotext.setDepth(1500010);
    this.npcvideotext.setPosition(
      this.npcvideoimg.x - this.npcvideoimg.displayWidth / 2,
      this.npcvideoimg.y + this.npcvideoimg.displayHeight + 30
    );
    this.lcamman(wh);
  }
  npcvideo_close() {

    this.wh.tweens.add({
      targets: [
        this.npcvideoimg,
        this.npcvideotext,
        this.npcvideobgimg,
        this.npcvideobg
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      delay: 100,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        //this.plog.log('npcvideoclose tweencomplete', this);
        if (arguments[1][0] != undefined)
          arguments[1][0].destroy();
        if (arguments[1][1] != undefined)
          arguments[1][1].destroy();
        if (arguments[1][2] != undefined)
          arguments[1][2].destroy();
        if (arguments[1][3] != undefined)
          arguments[1][3].destroy();
      },
    });
    return;
    if (this.npcvideobg != undefined) {
      this.npcvideobg.destroy();//true
      this.npcvideobg = undefined;
    }
    if (this.npcvideobgimg != undefined) {
      this.npcvideobgimg.destroy();//true
      this.npcvideobgimg = undefined;
    }
    if (this.npcvideotext != undefined) {
      this.npcvideotext.destroy();//true
      this.npcvideotext = undefined;
    }
    if (this.npcvideoimg != undefined) {
      this.npcvideoimg.destroy();//true
      this.npcvideoimg = undefined;
    }

  }

  spritedialog(wh, spritecode, title, txt) {
    this.wh = wh;
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.spritedialogbg != undefined) {
      this.spritedialogbg.destroy();
      this.spritedialogbg = undefined;
    }
    if (this.spritedialogbgimg != undefined) {
      this.spritedialogbgimg.destroy();
      this.spritedialogbgimg = undefined;
    }

    if (this.spritedialogimg != undefined) {
      this.spritedialogimg.destroy();
      this.spritedialogimg = undefined;
    }
    if (this.spritedialogtext != undefined) {
      this.spritedialogtext.destroy();
      this.spritedialogtext = undefined;
    }

    this.spritedialogbg = wh.add.rectangle(0, 0, width, height, 0xffffff);
    this.spritedialogbg.setAlpha(0.7);
    this.spritedialogbg.setPosition(0, 0);
    this.spritedialogbg.setOrigin(0, 0);
    this.spritedialogbg.setAlpha(0.5);
    this.spritedialogbg.visible = true;
    this.spritedialogbg.setDepth(1500000);
    this.spritedialogbg.setInteractive();

    this.spritedialogbgimg = wh.add.image(0, 0, 'spritedialogguibg');
    this.spritedialogbgimg.setOrigin(0.5, 0.5);
    this.spritedialogbgimg.setPosition(width / 2, height / 2);
    if (width > height * 0.8) {
      this.spritedialogbgimg.displayHeight = height;
      this.spritedialogbgimg.scaleX = this.spritedialogbgimg.scaleY;
    } else {
      this.spritedialogbgimg.displayWidth = width;
      this.spritedialogbgimg.scaleY = this.spritedialogbgimg.scaleX;
    }
    this.spritedialogbgimg.setDepth(1500003);
    this.spritedialogbg.on(
      'pointerup',
      function () {
        this.spritedialog_close();
      },
      this
    );

    var spritecodea = spritecode.split('_');
    this.spritedialogimg = wh.add.sprite(0, 0, spritecodea[0]).play(spritecode);
    this.spritedialogimg.displayWidth =
      this.spritedialogbgimg.displayWidth * 0.6;
    this.spritedialogimg.scaleY = this.spritedialogimg.scaleX;
    this.spritedialogimg.setOrigin(0.5, 0.5);
    this.spritedialogimg.setPosition(
      width / 2,
      this.spritedialogbgimg.y - this.spritedialogbgimg.displayHeight * 0.25
    );
    this.spritedialogimg.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    this.spritedialogimg.setDepth(1500005);
    this.plog.log('this.spritedialogbgimg', this.spritedialogbgimg);
    this.plog.log('this.spritedialogimg', this.spritedialogimg);

    this.spritedialogtext = wh.add.text(0, 0, '..', wh.fs["wingui_text"]);
    //this.plog.log("spritedialogtext",wh);
    this.spritedialogtext.setText(wh.gl(title) + '\n\n' + wh.gl(txt));
    this.spritedialogtext.setWordWrapWidth(this.spritedialogimg.displayWidth);
    this.spritedialogtext.setDepth(1500010);
    this.spritedialogtext.setPosition(
      this.spritedialogimg.x - this.spritedialogimg.displayWidth / 2,
      this.spritedialogimg.y + this.spritedialogimg.displayHeight / 2
    );
    this.lcamman(wh);
    this.wh.kbfocuslv1 = "spritedialog";
  }
  spritedialog_close() {

    this.wh.tweens.add({
      targets: [
        this.spritedialogbg,
        this.spritedialogbgimg,
        this.spritedialogtext,
        this.spritedialogimg
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      delay: 100,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        //this.plog.log('localmapclose tweencomplete', this);
        arguments[1][0].destroy();
        arguments[1][1].destroy();
        arguments[1][2].destroy();
        arguments[1][3].destroy();
      },
    });
    return;
    if (this.spritedialogbg != undefined) {
      this.spritedialogbg.destroy();//true
      this.spritedialogbg = undefined;
    }
    if (this.spritedialogbgimg != undefined) {
      this.spritedialogbgimg.destroy();//true
      this.spritedialogbgimg = undefined;
    }
    if (this.spritedialogtext != undefined) {
      this.spritedialogtext.destroy();//true
      this.spritedialogtext = undefined;
    }
    if (this.spritedialogimg != undefined) {
      this.spritedialogimg.destroy();//true
      this.spritedialogimg = undefined;
    }
  }

  spritepaperdialog(wh, txt) {
    this.plog.log("spritepaperdialog", wh, txt);
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.spritepaperdialogbg != undefined) {
      this.spritepaperdialogbg.destroy();//true
      this.spritepaperdialogbg = undefined;
    }
    if (this.spritepaperdialogbgimg != undefined) {
      this.spritepaperdialogbgimg.destroy();//true
      this.spritepaperdialogbgimg = undefined;
    }

    if (this.spritepaperdialogtext != undefined) {
      this.spritepaperdialogtext.destroy();//true
      this.spritepaperdialogtext = undefined;
    }

    this.spritepaperdialogbg = wh.add.rectangle(0, 0, width, height, 0xffffff);
    this.spritepaperdialogbg.setAlpha(0.7);
    this.spritepaperdialogbg.setPosition(0, 0);
    this.spritepaperdialogbg.setOrigin(0, 0);
    this.spritepaperdialogbg.setAlpha(0.5);
    this.spritepaperdialogbg.visible = true;
    this.spritepaperdialogbg.setDepth(1500000);
    this.spritepaperdialogbg.setInteractive();

    this.spritepaperdialogbgimg = wh.add.image(0, 0, 'spritedialogguibg');
    this.spritepaperdialogbgimg.setOrigin(0.5, 0.5);
    this.spritepaperdialogbgimg.setPosition(width / 2, height / 2);
    if (width > height * 0.8) {
      this.spritepaperdialogbgimg.displayHeight = height;
      this.spritepaperdialogbgimg.scaleX = this.spritepaperdialogbgimg.scaleY;
    } else {
      this.spritepaperdialogbgimg.displayWidth = width;
      this.spritepaperdialogbgimg.scaleY = this.spritepaperdialogbgimg.scaleX;
    }
    this.spritepaperdialogbgimg.setDepth(1500003);
    this.spritepaperdialogbg.on(
      'pointerup',
      function () {
        this.spritepaperdialog_close();
      },
      this
    );

    this.plog.log('this.spritepaperdialogbgimg', this.spritepaperdialogbgimg);

    this.spritepaperdialogtext = wh.add.text(0, 0, '..', wh.fs["rcalert_text"]);
    //this.plog.log("spritepaperdialogtext",wh);
    this.spritepaperdialogtext.setText(wh.gl(txt));
    this.spritepaperdialogtext.setWordWrapWidth(
      this.spritepaperdialogbgimg.displayWidth * 0.8
    );
    this.spritepaperdialogtext.setDepth(1500010);
    this.spritepaperdialogtext.setPosition(
      width / 2 - this.spritepaperdialogtext.displayWidth / 2,
      height / 2 - this.spritepaperdialogtext.displayHeight / 2
    );
    //this.spritepaperdialogtext.setPosition(this.spritepaperdialogbgimg.x-(this.spritepaperdialogimg.displayWidth/2),this.spritepaperdialogimg.y+(this.spritepaperdialogimg.displayHeight/2));
    this.lcamman(wh);
  }
  spritepaperdialog_close() {
    if (this.spritepaperdialogbg != undefined) {
      this.spritepaperdialogbg.destroy();//true
      this.spritepaperdialogbg = undefined;
    }
    if (this.spritepaperdialogbgimg != undefined) {
      this.spritepaperdialogbgimg.destroy();//true
      this.spritepaperdialogbgimg = undefined;
    }
    if (this.spritepaperdialogtext != undefined) {
      this.spritepaperdialogtext.destroy();//true
      this.spritepaperdialogtext = undefined;
    }
  }

  gl(str) {
    //this.plog.log("gl(),", str, this.registry.list.rcvarpass.lang);
    if (str == undefined) return "";
    str = str.replace("::l::", "___l___")
    var stra = str.split("___l___");

    if (this.rcvar.lang == undefined || this.rcvar.lang == "") {
      return stra[0];
    }
    switch (this.rcvar.lang) {
      case "EN":
        return stra[0];
        break;
      case "TH":
        if ((stra[1] == "" || stra[1] == undefined) && stra[0] != "") {
          return stra[0];
        }
        return stra[1];
        break;

      default:
        return stra[0];
        break;
    }
    return str;
  }
  lcamman(wh) {
    if (typeof wh.camman === "function") {
      // ref by Preloader scene
      wh.camman(wh);
    }
  }
}
