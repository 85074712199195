import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { GameInterfaceService } from './game-interface.service';
import { PGBar } from './pgbar';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';
import { Purchases, PRODUCT_CATEGORY, LOG_LEVEL, PURCHASES_ERROR_CODE } from '@revenuecat/purchases-capacitor';

@Injectable({
  providedIn: 'root',
})
export class IAPStoreService {
  iapview_rcbtn;
  shopdragbtn;
  iapview_amazonbtn;
  shopclosebtn;
  title;
  iapviewcontainer;
  iapview_iap2btn;
  iapview_freebtn;
  paypalbtn;
  stripebtn;
  stripebtn_origx;
  stripebtn_origy;
  iapview_bgimg;
  iapview_bg;
  iapview_txt;
  iapview_price;
  iapview_descr;
  iapview_icon;
  iapview_rw = [];
  iapview_rwt = [];
  svcost;
  svcostpg;

  rcconfirmpass = '';
  rcconfirmbg;
  rcconfirmbtn;
  rcconfirmbgimg;
  rcconfirmtext;
  rcconfirmhead;
  rccancelbtn;

  winguiltab;
  banner;
  stackarr = [];
  empty;
  invensize;
  submn = [];
  selecteditemno = '';
  currentresppage = 1;
  respperpage = 6;
  resptotalpage;
  selectcircle;
  itemdescr;
  shopitemlist = [];
  shopitemlistn = [];
  shopitemlistp = [];
  shopitemlistl = [];
  shopfwdbtn;
  shopbackbtn;
  invenmode = '';
  sellbtn;
  shopguibg;
  wh;
  shopdata;
  filtertype = 'diamond';
  loading;
  timeout;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rchttp: RCHTTP,
    public gameitf: GameInterfaceService
  ) {
    this.timeout = async (ms) => new Promise((res) => setTimeout(res, ms));
    this.plog = new plog();
    //this.plog.setLevel(4);
  }

  firsttimerun = true;
  async firsttimerunfunc() {
    if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(this.wh.iapstorecontainer);
      this.svcost = await this.wh.registry.list.rchttp.getcommon2('svcost', {});
    }
    this.firsttimerun = false;
  }

  loadingshow() {
    this.wh.iapstorecontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }

  async showinventory(wh, invenmode, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;
    this.wh = wh;

    if (wh.iapstorecontainer != undefined) {
      await this.showinventory_close(wh);
    }
    var shopdata = this.wh.registry.list.rcvarpass.iapstoredata;
    this.invenmode = invenmode;
    this.shopdata = shopdata;
    try {
      await this.banner.destroy();
    } catch (e) {
      this.plog.log("ERROR!", e);
    }
    if (this.banner != undefined) {
      try {
        await this.banner.destroy();
      } catch (e) {
        this.plog.log("ERROR!", e);
      }
    }

    if (wh.iapstorecontainer != undefined) {
      for (const k in wh.iapstorecontainer.list) {
        try {
          await wh.iapstorecontainer.remove(wh.iapstorecontainer.list[k], true);
        } catch (e) {
          this.plog.log("ERROR!", e);
        }
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      }
    }
    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].destroy(); //true = undefined;
      }
      if (this.shopitemlistp[i] != undefined) {
        //this.shopitemlistp[i].destroy(); // = undefined;
        this.shopitemlistp[i] = undefined;
      }
      if (this.shopitemlistn[i] != undefined) {
        //this.shopitemlistp[i].destroy(); // = undefined;
        this.shopitemlistn[i] = undefined;
      }
      if (this.shopitemlistl[i] != undefined) {
        //this.shopitemlistl[i].destroy(); // = undefined;
        this.shopitemlistl[i] = undefined;
      }
    }
    if (wh.iapstorecontainer != undefined) {
      try {
        await wh.iapstorecontainer.destroy();
      } catch (e) {
        this.plog.log("ERROR!", e);
      }
    }
    wh.iapstorecontainer = undefined;

    if (wh.iapstorecontainer != undefined) {
      this.plog.log('iapstorecontainer', wh.iapstorecontainer);
      wh.iapstorecontainer.visible = true;
      if (this.winguiltab != undefined) this.winguiltab.visible = false;
      this.filtertype = 'diamond';
      this.showinventory_loaded(wh, shopdata);
      return;
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('showinventory', wh, shopdata);

    const selectcircle = wh.add.sprite(0, 0, 'selectcircle').play('selected_b');
    //const selectcircle = wh.add.image(0, 0, "selectcircle");
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, 'shopguibg3x2');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    const shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    shopbackbtn.setOrigin(0.5, 0.5);
    shopbackbtn.setInteractive();
    shopbackbtn.removeAllListeners();
    const shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');
    shopfwdbtn.setOrigin(0.5, 0.5);
    shopfwdbtn.setInteractive();
    shopfwdbtn.removeAllListeners();
    const sellbtn = wh.add.sprite(0, 0, "thesb", "purchase");//wh.add.image(0, 0, 'purchasebtn');
    sellbtn.setOrigin(0.5, 0.5);
    sellbtn.setInteractive();
    sellbtn.removeAllListeners();
    sellbtn.visible = false;
    //this.plog.log('showinventory var', this.rcvarsservice);
    this.shopfwdbtn = shopfwdbtn;
    this.shopbackbtn = shopbackbtn;
    this.selectcircle = selectcircle;
    this.sellbtn = sellbtn;

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.showinventory_close(wh);
        this.showinventory_close(wh);
        this.showinventory_close(wh);
        this.showinventory_close(wh);
        this.showinventory_close(wh);
      },
      this
    );

    this.winguiltab = wh.add.image(0, 0, 'winguiltab');
    this.winguiltab.setOrigin(0.5, 0.5);
    this.winguiltab.displayWidth = this.winguiltab.displayHeight = 52;
    this.winguiltab.visible = false;

    this.banner = wh.add.sprite(0, 0, 'iapstore_catebanner', 'diamond');
    this.banner.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    this.banner.setOrigin(0.5, 0.5);
    this.banner.displayWidth = 370;
    this.banner.displayHeight = 140;

    wh.iapstorecontainer = wh.add.container(300, 300);
    wh.iapstorecontainer.visible = false;
    wh.iapstorecontainer.name = "iapstorecontainer";
    wh.iapstorecontainer.add(this.shopclosebtn);
    wh.iapstorecontainer.add(selectcircle);
    wh.iapstorecontainer.add(shopguibg);
    wh.iapstorecontainer.add(shopbackbtn);
    wh.iapstorecontainer.add(shopfwdbtn);
    wh.iapstorecontainer.add(sellbtn);
    wh.iapstorecontainer.add(this.winguiltab);
    wh.iapstorecontainer.add(this.banner);


    this.title = wh.add.text(0, 0, 'SHOP', wh.fs["winguititle42"]);
    //this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.iapstorecontainer.add(this.title);
    wh.iapstorecontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -226);


    this.banner.visible = false;

    wh.iapstorecontainer.bringToTop(this.banner);
    this.plog.log('this.banner', this.banner);

    shopbackbtn.displayWidth = 100;
    shopfwdbtn.displayWidth = 100;
    sellbtn.displayWidth = 100;
    shopbackbtn.displayHeight = 40;
    shopfwdbtn.displayHeight = 40;
    sellbtn.displayHeight = 40;
    shopbackbtn.setPosition(-110, 220);
    shopfwdbtn.setPosition(110, 220);
    sellbtn.setPosition(-10, 220);

    wh.iapstorecontainer.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, wh.trs("f:iapstore:Click Item to View Description===Click Item to View Description"), wh.fs["winguiitemdescr"]);
    wh.iapstorecontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;
    this.invensize = wh.add.text(0, 0, '0/0', {
      fontFamily: 'ffff',
      fontSize: '16px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    wh.iapstorecontainer.add(this.invensize);
    this.invensize.setOrigin(1, 1);
    this.invensize.setPosition(150, -147);

    this.empty = wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 80);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    wh.iapstorecontainer.add(this.empty);
    wh.iapstorecontainer.bringToTop(this.empty);
    this.empty.visible = false;

    //drag

    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.y = pointer.y;
        }
        //event.preventDefault();
      },
      this
    );
    wh.iapstorecontainer.add(this.shopdragbtn);
    wh.iapstorecontainer.bringToTop(this.shopdragbtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.iapstorecontainer.setSize(shopguibg.width, shopguibg.height);
    wh.iapstorecontainer.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/

    this.plog.log('iapstorecontainer', wh.iapstorecontainer, width, height);
    wh.iapstorecontainer.setPosition(width / 2, height / 2);
    //move
    wh.iapstorecontainer.x = width - this.shopguibg.width / 2 + 30;

    let loader = new Phaser.Loader.LoaderPlugin(wh);
    /*
    move to spritestack
    for (var vv in shopdata["stackitem"]) {
      var vdat = shopdata["stackitem"][vv];
      var itemdat = this.rcvarsservice.allstackitem[vdat.item];
      //this.plog.log("xxxx",itemdat,vdat.item,this.rcvarsservice);
      if (itemdat == undefined) continue;
      loader.image(vdat["item"], this.rcvarsservice.rc_baseurl + "stackitem/" + itemdat["sprite"] + ".png");

      this.plog.log("load shop ", vv, vdat, itemdat);
    }
    */

    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.iapstorecontainer.add(this.loading);
    wh.iapstorecontainer.bringToTop(this.loading);
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    var loadlist = [
      'iapstore_cate',
      'iapstore_item',
      'iapstore_itemg',
      'iapstore_catebanner',
      'iapstore_catebannerg',
    ];
    for (var k in loadlist) {
      var key = loadlist[k];
      if (this.rcvarsservice.gameobj.textures.game.textures.list[key] == undefined) {

        loader.atlas(
          key + '',
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.png' +
          svversionurlstring,
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.json' +
          svversionurlstring
        );
      }
    }
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.showinventory_loaded(wh, shopdata);
      },
      this
    );
    loader.start();
  }

  async showinventory_loaded(wh, shopdata, pagemode = 'normal') {
    this.firsttimerunfunc();
    this.plog.log("iapstore wh", wh);
    this.loadingshow();
    await wh.registry.list.thethis.initializePayment();
    var shopdata = this.wh.registry.list.rcvarpass.iapstoredata;

    this.loadinghide();
    this.plog.log('showinventory_loaded svcost', this.svcost);
    if (this.svcost == undefined) {
      this.svcost = { s: 0, e: 0 }
    }
    this.plog.log('showinventory_loaded', wh, shopdata);
    this.plog.log('showinventory_loaded filtertype', this.filtertype);
    this.plog.log('showinventory_loaded banner', this.filtertype);
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer);
    this.banner.setTexture('iapstore_catebanner', this.filtertype);
    this.banner.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    this.banner.displayWidth = 298;
    this.banner.scaleY = this.banner.scaleX;
    this.banner.setPosition(1, -85);
    this.banner.visible = true;

    this.banner.removeAllListeners();
    this.banner.setInteractive();
    this.banner.on("pointerup", function () {

      this.showinventory_loaded(
        this.wh,
        this.shopdata
      );
    }, this);

    var paddingl = -175;
    var paddingt = -140;
    this.stackarr['special'] = 'Special Items'; //['subscription'];
    this.stackarr['dailydeal'] = 'Daily Deals'; //['dailydeal'];
    this.stackarr['weeklydeal'] = 'Weekly Deals'; //['weeklydeal'];
    this.stackarr['monthlychest'] = 'Monthly Chests'; //['monthlychest'];
    this.stackarr['diamond'] = 'Diamonds'; //['diamond'];
    this.stackarr['gold'] = 'Gold deals'; //['gold'];
    this.stackarr['advance'] = 'Advancement Pack'; //['advance'];
    this.stackarr['subscription'] = 'Boosters!'; //['subscription'];
    this.stackarr['free'] = 'Free Deals!!'; //['subscription'];

    var paddingts = paddingt + 0;
    var iconwhsub = 30;
    var rowh = 40;
    var i = 0;
    for (var k in this.stackarr) {
      var v = this.stackarr[k];
      this.plog.log('xxxxx', k, v, i);

      this.submn[k] = this.wh.add.sprite(
        0,
        0,
        'iapstore_cate',
        k,
        iconwhsub * i + 2 + paddingts
      );
      this.wh.iapstorecontainer.add(this.submn[k]);
      this.wh.iapstorecontainer.bringToTop(this.submn[k]);
      this.submn[k].displayWidth = this.submn[k].displayHeight = iconwhsub;
      this.submn[k].setPosition(
        paddingl,
        paddingt + ((iconwhsub + 3) * i + 30)
      );
      paddingts = paddingts + rowh;
      this.submn[k].setInteractive();
      this.submn[k].removeAllListeners();
      this.submn[k].name = k;
      this.submn[k].on('pointerup', function () {
        this.scene.plog.log(
          'xxxxxpointerup',
          this,
          this.scene.registry.list.iapstore.winguiltab
        );

        this.scene.registry.list.iapstore.filtertype = this.name;
        this.scene.registry.list.iapstore.winguiltab.visible = true;
        this.scene.iapstorecontainer.moveBelow(
          this.scene.registry.list.iapstore.winguiltab,
          this
        );
        this.scene.registry.list.iapstore.winguiltab.setPosition(
          this.x - 7,
          this.y
        );

        this.scene.registry.list.iapstore.showinventory_loaded(
          this.scene.registry.list.iapstore.wh,
          this.scene.registry.list.iapstore.shopdata
        );
        this.scene.registry.list.iapstore.banner.setTexture(
          'iapstore_catebanner',
          '' + this.scene.registry.list.iapstore.filtertype
        );
        this.scene.registry.list.iapstore.banner.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

      });
      this.submn[k].on('pointerover', function () {
        var tmpname = this.scene.registry.list.iapstore.stackarr[this.name];
        this.scene.registry.list.gameitf.gamemenutooltip(
          this.scene,
          this,
          this.scene.trs("t:iapstore_cate:code:name:" + this.name + "===" + tmpname),
          'left'
        );
      });
      i++;
    }

    if (this.winguiltab.visible == false) {
      this.winguiltab.visible = true;
      this.wh.iapstorecontainer.moveBelow(
        this.winguiltab,
        this.submn['special']
      );
      this.winguiltab.setPosition(
        this.submn['special'].x - 7,
        this.submn['special'].y
      );
    }

    var margintop = 28;
    var marginleft = -117;
    var cxpos = 0;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < 50; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
      }
    }
    for (var i = 0; i < 50; i++) {
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = false;
      }
    }
    for (var i = 0; i < 50; i++) {
      if (this.shopitemlistn[i] != undefined) {
        this.shopitemlistn[i].visible = false;
      }
    }
    for (var i = 0; i < 50; i++) {
      if (this.shopitemlistl[i] != undefined) {
        this.shopitemlistl[i].visible = false;
      }
    }

    for (var i = 0; i < 50; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].destroy(); // = undefined;
        this.shopitemlist[i] = undefined;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].destroy(); // = undefined;
        this.shopitemlistp[i] = undefined;
      }
      if (this.shopitemlistn[i] != undefined) {
        this.shopitemlistn[i].destroy(); // = undefined;
        this.shopitemlistn[i] = undefined;
      }
      if (this.shopitemlistl[i] != undefined) {
        this.shopitemlistl[i].destroy(); // = undefined;
        this.shopitemlistl[i] = undefined;
      }
    }
    this.plog.log('pagemode', pagemode, this.currentresppage);
    if (pagemode == 'normal') {
      this.currentresppage = 1;
    }
    if (pagemode == 'retainpage') {
      if (this.currentresppage == 0 || this.currentresppage == undefined)
        this.currentresppage = 1;
    }
    if (pagemode == '1st') {
      this.currentresppage = 1;
    }
    var countslot = 0;
    for (const countslotk in this.shopdata) {
      countslot++;
    }
    var i = 0;
    //this.plog.log("showinven-updateslotdsp",this.shopdata["stackitem"],this.shopdata["stackitem"].length);
    this.invensize.setText(' ');

    for (var vv in shopdata) {
      var itemdat = shopdata[vv];
      //stackarr

      //this.plog.log('loaded shop ', vv, itemdat, this.filtertype);
      if (itemdat == undefined) continue;
      if (this.filtertype != '' && itemdat['pcode'] != this.filtertype)
        continue;
      if (itemdat["disabled"] != "yes") {
        this.shopitemlist[i] = wh.add.sprite(32, 32, 'iapstore_item', itemdat['code']);
      } else {
        this.shopitemlist[i] = wh.add.sprite(32, 32, 'iapstore_itemg', itemdat['code']);
      }
      //var tmp = wh.add.image(32, 32, itemdat["code"]);
      this.shopitemlist[i].displayWidth = 96;
      this.shopitemlist[i].displayHeight = 96;
      wh.iapstorecontainer.add(this.shopitemlist[i]);
      this.shopitemlist[i].setPosition(18 + marginleft + cxpos * 100, margintop + crow * 100);
      if (itemdat["disabled"] != "yes") {
        this.shopitemlistp[i] = wh.add.text(0, 0, '$' + itemdat['price'], wh.fs["iap_itemprice"]);
      } else {
        this.shopitemlistp[i] = wh.add.text(0, 0, '' + itemdat['price'], wh.fs["iap_itempricedis"]);
      }
      //var tmpn;
      var tmpitemname = wh.trs("t:iapstore_item:code:name:" + itemdat['code'] + "===" + itemdat['name'])
      if (itemdat["disabled"] != "yes") {
        this.shopitemlistn[i] = wh.add.text(0, 0, '' + tmpitemname, wh.fs["iap_itemtitle"]);
        //this.plog.log(wh.fs["iap_itemtitle"]);
        // this.shopitemlistn[i].setLineSpacing(-5);
      } else {
        this.shopitemlistn[i] = wh.add.text(0, 0, '' + tmpitemname, wh.fs["iap_itemtitledis"]);
        //this.shopitemlistn[i] = wh.add.text(0, 0, '' + itemdat['name'], wh.fs["iap_itemtitledis"]);
      }
      if (tmpitemname.length > 14) this.shopitemlistn[i].setFontSize("10px");
      var limitstr = "";
      if (itemdat['limitstr'] != undefined && itemdat['limitstr'] != "")
        limitstr = 'limit ' + itemdat['limitstr'];
      this.shopitemlistl[i] = wh.add.text(0, 0, limitstr, wh.fs["iap_limit"]);

      //var this.shopitemlistn[i] = wh.add.text(0, 0, '' + itemdat['name'], wh.fs["iap_itemtitle"]);
      this.shopitemlistn[i].setWordWrapWidth(this.shopitemlist[i].displayWidth, true);
      //this.shopitemlistn[i].setLineSpacing(0);
      this.shopitemlistn[i].updateText();

      wh.iapstorecontainer.add(this.shopitemlist[i]);
      wh.iapstorecontainer.add(this.shopitemlistl[i]);
      wh.iapstorecontainer.add(this.shopitemlistp[i]);
      wh.iapstorecontainer.add(this.shopitemlistn[i]);
      this.shopitemlistp[i].setPosition(this.shopitemlist[i].x - 46, this.shopitemlist[i].y + 17);
      this.shopitemlistn[i].setPosition(this.shopitemlist[i].x - 46, this.shopitemlist[i].y - 46);
      this.shopitemlistl[i].setPosition((this.shopitemlist[i].x + 30) - (this.shopitemlistl[i].displayWidth / 2), this.shopitemlist[i].y + 28);
      //this.shopitemlistp[i] = tmpp;
      //this.shopitemlistn[i] = tmpn;
      //this.shopitemlistl[i] = tmpl;
      this.shopitemlist[i].visible = true;
      this.shopitemlistp[i].visible = true;
      this.shopitemlistn[i].visible = true;
      this.shopitemlistl[i].visible = true;
      this.shopitemlist[i].setInteractive();
      this.shopitemlist[i].removeAllListeners();
      this.shopitemlist[i].name =
        '' + itemdat['code'] + '-' + i + '-' + itemdat['id'];
      if (itemdat["disabled"] != "yes") {

        this.shopitemlist[i].on(
          'pointerup',
          function (pointer, localX, localY, event) {
            this.scene.plog.log('shop itemclick this', this);
            this.scene.plog.log('shop itemclick', pointer, localX, localY, event);
            this.scene.registry.list.iapstore.showinventory_itemclick(
              this.scene,
              this
            );
          }
        );
      }
      cxpos++;
      if (cxpos > 2) {
        cxpos = 0;
        crow++;
      }
      i++;
      if (i % 6 == 0) {
        cxpos = 0;
        crow = 0;
      }
    }
    this.plog.log('shop i count', i);
    if (i > 6) {
      //pages

      this.respperpage = 6;
      this.resptotalpage = Math.ceil(i / 6);
    }

    if (i == 0) {
      this.empty.visible = true;
    } else {
      this.empty.visible = false;
    }

    this.shopbackbtn.visible = false;
    this.shopfwdbtn.visible = false;
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );

    this.showinventory_showpage(wh, '1st');

    this.sellbtn.removeAllListeners();
    this.sellbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop sellbtn', this);
        this.showinventory_sell(this, 1);
      },
      this
    );

    //svcost
    // this.plog.error("svcost",this.svcost);
    if (this.svcostpg == undefined && this.svcost != undefined && this.svcost['e'] != 0) {
      this.svcostpg = new PGBar(
        this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0],
        {
          x: -120,
          y: 210,
          w: 240,
          h: 14,
          max: this.svcost['e'],
          me: this.svcost['s'],
          barcol1: 0x0fbd5e,
          barcol2: 0x0e9e50,
          bgcol: 0x888888,
          imgcol: 'darkgreen',
          //txt: 'Help with this month server cost? ($'+this.svcost['s']+'/$'+this.svcost['e']+')',
          txt: this.wh.trs("f:iapstore:Help the dev with this month server cost?===Help the dev with this month server cost?") + '\nCurrent funds: $' + this.svcost['s'] + ' / target: $' + this.svcost['e'] + ' '
        }, "img"
      );
      this.plog.log("svcostpg", this.svcostpg);

      wh.iapstorecontainer.add(this.svcostpg.barfg);
      wh.iapstorecontainer.add(this.svcostpg.bar);
      wh.iapstorecontainer.add(this.svcostpg.txt);
      wh.iapstorecontainer.bringToTop(this.svcostpg.bar);
      wh.iapstorecontainer.bringToTop(this.svcostpg.barfg);
      wh.iapstorecontainer.bringToTop(this.svcostpg.txt);
      this.svcostpg.bar.setPosition(-120, 210);
      this.svcostpg.txt.setPosition(-110, 220);
      this.svcostpg.txt.setWordWrapWidth(230, true);
      this.svcostpg.txt.setStyle({
        fontFamily: 'ffff,uuuu', fontSize: '8px', stroke: '#000000', strokeThickness: 3, lineSpacing: -2,
        shadow: { color: '#000000', fill: true, blur: 4, stroke: true }
      });
      this.svcostpg.txt.setLineSpacing(-2);
    } else {
      if (this.svcostpg != undefined) {
        this.svcostpg.set(this.svcost['s'], this.svcost['e']);
      }
    }
    this.wh.registry.list.gameitf.updatereddots(this.wh);
    this.wh.iapstorecontainer.visible = true;
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);

    this.wh.registry.list.tut.show(this.wh, "iapstore_freecategory");

  }
  async showinventory_sell(wh, amnt) {
    this.plog.log('showinventory_sell', wh, Number(this.selecteditemno));
    if (this.selecteditemno == '') return;
    if (
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno] == undefined
    ) {
      this.plog.log(
        'showinventory_sell skip dropped',
        this.selecteditemno,
        wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]
      );
      return; //dropped
    }
    var tmpid =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['id'];
    var tmpamnt =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['amnt'];
    var tmpitem =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['item'];
    var tmpsellprice =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno][
      'sellprice'
      ];
    var iteminfo2 = wh.rcvarsservice.allstackitem[tmpitem];
    if (amnt != 1) {
      var confirm =
        await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
          wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
          'Confirm Sell',
          'Sell ' +
          amnt +
          ' of:\n' +
          iteminfo2['name'] +
          ' for ' +
          tmpsellprice * amnt +
          ' gold'
        );
      this.plog.log('rcconfirmresult', confirm);
      //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Sell", "Sell " + amnt + " of:\n" + iteminfo2["name"] + " for " + (tmpsellprice * amnt) + " gold");
      if (confirm != 'ok') return;
    }
    this.rcvarsservice.gameobj.registry.list.gameitf.osdflash(
      this.rcvarsservice.gameobj.scene.scenes[0],
      [{ txt: 'Gold +' + tmpsellprice * amnt, col: 'green' }]
    );
    this.plog.log('showinventory_sell', wh.rcvarsservice.activechar);
    this.loadingshow();
    var tmpid =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['id'];
    var tmpamnt =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['amnt'];
    var res = await this.rchttp.sellstackitem(tmpid, amnt);
    await this.rcvarsservice.setactivechar(
      res,
      wh.rcvarsservice.activechar['id']
    );
    this.plog.log('showinventory_sell res', res, wh.rcvarsservice);
    this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.loadinghide();
    if (tmpamnt <= 1) {
      this.selecteditemno = '';
    }
  }

  showinventory_itemclick(wh, item) {
    this.plog.log('showinventory_itemclick', wh, item);
    this.plog.log('showinventory_itemclick item.name', item.name);
    //this.plog.log("showinventory_itemclick shopitemlist", namea, this.shopitemlist);
    this.selectcircle.visible = true;
    var namea = item.name.split('-');
    wh.registry.list.iapstore.selecteditemno = namea[0];
    this.selectcircle.displayWidth = 96;
    this.selectcircle.displayHeight = 96;
    this.selectcircle.setPosition(item.x, item.y);
    //wh.iapstorecontainer.sendToBack(wh.shopguibg);
    wh.iapstorecontainer.bringToTop(this.selectcircle);
    wh.iapstorecontainer.bringToTop(this.shopitemlist[namea[1]]);
    wh.iapstorecontainer.bringToTop(this.shopitemlistp[namea[1]]);
    wh.iapstorecontainer.bringToTop(this.shopitemlistn[namea[1]]);
    wh.iapstorecontainer.bringToTop(this.shopitemlistl[namea[1]]);
    this.plog.log(
      'showinventory_itemclick selectcircle',
      namea,
      item,
      wh.registry.list.rcvarpass.activechar
    );
    //var iteminfo = wh.registry.list.rcvarpass.iapstoredata[namea[0]];

    this.iapview(wh, namea[0]);
    /*
    //this.selectcircle.width=
    //this.plog.log("xxxx", iteminfo2);

    this.itemdescr.setText(
      iteminfo['name'] + ' x' + iteminfo['amnt'] + ' click Drop to DROP ALL'
    );
    wh.registry.list.rchttp.getcommon2('removeisnew_stackitem', {
      itemid: iteminfo['id'],
    });
    wh.registry.list.rcvarpass.activechar['stackitem'][namea[2]]['isnew'] = 0;*/
  }
  async showinventory_close(wh) {
    //wh.iapstorecontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.iapstorecontainer);
    this.plog.log("showinventory_close");

    try {
      await this.banner.destroy();
    } catch (e) {
      this.plog.log("ERROR!", e);
    }

    try {
      await this.banner.destroy();
    } catch (e) {
      this.plog.log("ERROR!", e);
    }
    for (let desi = 0; desi < 20; desi++) {

      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.list) {
        try {
          await this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.remove(this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.list[k], true);
        } catch (e) {
          //this.plog.log("ERROR!", e);
        }
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      }
    }
    try {
      await this.svcostpg.bar.destroy();
      await this.svcostpg.txt.destroy();
    } catch (e) {
      this.plog.log("ERROR!", e);
    }
    for (var i = 0; i < 50; i++) {
      if (this.shopitemlist[i] != undefined) {
        await this.shopitemlist[i].destroy(); // = undefined;
        this.shopitemlist[i] = undefined;
      }
      if (this.shopitemlistp[i] != undefined) {
        await this.shopitemlistp[i].destroy(); // = undefined;
        this.shopitemlistp[i] = undefined;
      }
      if (this.shopitemlistn[i] != undefined) {
        await this.shopitemlistn[i].destroy(); // = undefined;
        this.shopitemlistn[i] = undefined;
      }
      if (this.shopitemlistl[i] != undefined) {
        await this.shopitemlistl[i].destroy(); // = undefined;
        this.shopitemlistl[i] = undefined;
      }
      if (this.submn[i] != undefined) {
        await this.submn[i].destroy(); // = undefined;
        this.submn[i] = undefined;
      }
      if (this.submn[i] != undefined) {
        await this.submn[i].destroy(); // = undefined;
        this.submn[i] = undefined;
      }
      if (this.iapview_rw[i] != undefined) {
        await this.iapview_rw[i].destroy(); // = undefined;
        this.iapview_rw[i] = undefined;
      }
      if (this.iapview_rwt[i] != undefined) {
        await this.iapview_rwt[i].destroy(); // = undefined;
        this.iapview_rwt[i] = undefined;
      }
      if (this.wh.iapstorecontainer != undefined) {
        for (const k in this.wh.iapstorecontainer.list) {
          try {
            await this.wh.iapstorecontainer.remove(this.wh.iapstorecontainer.list[k], true);
            //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();

          } catch (e) {
            this.plog.log("ERROR! iapstorecontainer.destroy() ", e);
          }
        }
      }
      if (this.wh.iapstorecontainer != undefined) {
        for (const k in this.wh.iapstorecontainer.list) {
          try {
            await this.wh.iapstorecontainer.remove(this.wh.iapstorecontainer.list[k], true);
            //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();

          } catch (e) {
            this.plog.log("ERROR! iapstorecontainer.destroy() ", e);
          }
        }
      }
      try {
        await this.wh.iapstorecontainer.destroy(true);
      } catch (e) {
        ///this.plog.log("ERROR! iapstorecontainer.destroy() ", e);
      }

      try {
        await this.wh.iapstorecontainer.destroy(true);
      } catch (e) {
        //this.plog.log("ERROR! iapstorecontainer.destroy() ", e);
      }
      if (this.svcostpg != undefined) {
        try {
          await this.svcostpg.destroy(true);
        } catch (e) {
          this.plog.log("ERROR! iapstorecontainer.destroy() ", e);
        }
        this.svcostpg = undefined;
      }


      this.wh.iapstorecontainer = undefined;
    }

    if (this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer != undefined) {
      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.list) {
        try {
          await this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.list[k].destroy();
        } catch (e) {
          this.plog.log("ERROR!", e);
        }
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      }
    }
    if (this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer != undefined) {
      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.list) {
        try {
          await this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.list[k].destroy();
        } catch (e) {
          //this.plog.log("ERROR!", e);
        }
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      }
    }
    if (this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer != undefined) {
      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.list) {
        try {
          await this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.remove(this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.list[k], true);
        } catch (e) {
          //this.plog.log("ERROR!", e);
        }
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      }
    }
    if (this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer != undefined) {
      for (const k in this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.list) {
        try {
          await this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.remove(this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer.list[k], true);
        } catch (e) {
          this.plog.log("ERROR!", e);
        }
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      }
    }
    this.rcvarsservice.gameobj.scene.scenes[0].iapstorecontainer = undefined;
  }
  showinventory_showpage(gc, viewpage) {
    this.selectcircle.visible = false;
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = false;
      }
      if (this.shopitemlistn[i] != undefined) {
        this.shopitemlistn[i].visible = false;
      }
      if (this.shopitemlistl[i] != undefined) {
        this.shopitemlistl[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );

    for (var i = numfrom; i < numto; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = true;
        this.shopitemlistp[i].visible = true;
        this.shopitemlistn[i].visible = true;
        this.shopitemlistl[i].visible = true;
        var namea = this.shopitemlist[i].name.split('-');
        var isnewdata =
          this.wh.registry.list.rcvarpass.activechar['stackitem'][namea[1]];
        //this.plog.log("xxx1", isnewdata);
        if (isnewdata == undefined) continue;
        if (isnewdata['isnew'] == 1) {
          this.wh.registry.list.notif.show(this.wh, this.shopitemlist[i]);
        }
      }
    }
  }
  createpaypalbtn(wh, price, currency): any {
    var randid = "paypalbtn" + Math.floor((Math.random() * 999999999) + 100000000);

    const app = document.getElementById('thecanvasoverlay');
    const p = document.createElement('p');
    p.id = randid;
    // 3. Add the text content
    //p.textContent = '    <div id="paypal-button-container"></div>';
    p.textContent = '    ';
    // 4. Append the p element to the div element
    app?.appendChild(p);
    this.plog.log('createpaypalbtn', app, p);
    var tmp = [];
    tmp[0] = p;
    tmp[1] = randid;
    return tmp;
  }

  async rcconfirm(wh, head, text) {
    this.plog.log('rcconfirm', wh);
    this.rcconfirmpass = '';
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    if (this.rcconfirmbg == undefined) {
      var rcconfirmbg = wh.add.rectangle(0, 0, width, height, 0xffffff);
      rcconfirmbg.setAlpha(0.7);
      rcconfirmbg.setPosition(0, 0);
      rcconfirmbg.setOrigin(0, 0);
      rcconfirmbg.setAlpha(0.5);
      rcconfirmbg.visible = true;
      rcconfirmbg.setDepth(1500000);
      rcconfirmbg.setInteractive();
      this.rcconfirmbg = rcconfirmbg;

      this.rcconfirmbgimg = wh.add.image(300, 300, 'dialogbg');
      this.rcconfirmbgimg.setOrigin(0.5, 0.5);
      this.rcconfirmbgimg.setPosition(width / 2, height / 2);
      var localwidth = Math.min(width, height);
      if (localwidth > 550) localwidth = 550;
      //this.plog.log("localwidth", localwidth);
      localwidth = width;
      //if (localwidth)
      this.rcconfirmbgimg.displayWidth = localwidth * 0.9;
      this.rcconfirmbgimg.scaleY = this.rcconfirmbgimg.scaleX;
      this.rcconfirmbgimg.setDepth(1500003);
      //this.rcconfirmbgimg.visible=false;
      this.plog.log('rcconfirmbgimg', this.rcconfirmbgimg);

      this.rcconfirmbtn = wh.add.sprite(0, 0, "thesb", "confirm");//wh.add.image(300, 300, 'confirmbtn');
      this.rcconfirmbtn.setOrigin(0.5, 0.5);
      var buttonw = localwidth / 4;
      var buttonh = buttonw * 0.4;
      var btmofdialog = height / 2 + this.rcconfirmbgimg.displayHeight / 2; //- buttonh;
      btmofdialog = btmofdialog - this.rcconfirmbgimg.displayHeight * 0.2; //margin
      this.rcconfirmbtn.setPosition(width / 2 + buttonw / 2 + 5, btmofdialog);
      this.plog.log(
        'rcconfirmbtn.setPosition',
        btmofdialog,
        width / 2 - (buttonw + 5)
      );
      this.rcconfirmbtn.displayWidth = buttonw;
      this.rcconfirmbtn.displayHeight = buttonh;
      this.rcconfirmbtn.setDepth(1500005);

      this.rccancelbtn = wh.add.sprite(0, 0, "thesb", "cancel");//wh.add.image(300, 300, 'cancelbtn');
      this.rccancelbtn.setOrigin(0.5, 0.5);
      this.rccancelbtn.setPosition(width / 2 - (buttonw / 2 + 5), btmofdialog);

      this.rccancelbtn.displayWidth = buttonw;
      this.rccancelbtn.displayHeight = buttonh;
      this.rccancelbtn.setDepth(1500005);
      this.rcconfirmhead = wh.add.text(0, 0, 'head', {
        fontFamily: 'ffff',
        fontSize: '20px',
        stroke: '#fff',
        strokeThickness: 3,
        color: '#000',
        fontStyle: 'bold',
      });
      this.rcconfirmtext = wh.add.text(0, 0, 'text', {
        fontFamily: 'ffff',
        fontSize: '16px',
        stroke: '#fff',
        strokeThickness: 0,
        color: '#000',
        fontStyle: 'bold',
      });
    }

    this.rcconfirmbtn.visible = true;
    this.rccancelbtn.visible = true;
    this.rcconfirmhead.visible = true;
    this.rcconfirmtext.visible = true;
    this.rcconfirmbgimg.visible = true;
    this.rcconfirmbg.visible = true;
    this.rcconfirmbtn.setAlpha(1);
    this.rccancelbtn.setAlpha(1);
    this.rcconfirmhead.setAlpha(1);
    this.rcconfirmtext.setAlpha(1);
    this.rcconfirmbgimg.setAlpha(1);
    this.rcconfirmbg.setAlpha(0.5);

    var topofdialog = height / 2 - this.rcconfirmbgimg.displayHeight / 2; //- buttonh;
    topofdialog = topofdialog + this.rcconfirmbgimg.displayHeight * 0.2;
    this.rcconfirmhead.setPosition(width / 2, topofdialog);
    this.rcconfirmtext.setPosition(width / 2, height / 2);
    this.rcconfirmtext.setDepth(1500010);
    this.rcconfirmhead.setDepth(1500010);

    var oldy = this.rcconfirmbtn.x;
    this.rcconfirmbtn.x = oldy + 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcconfirmbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    var oldy = this.rccancelbtn.x;
    this.rccancelbtn.x = oldy - 70;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rccancelbtn,
      x: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    this.rcconfirmbgimg.setPosition(width / 2, height / 2);

    var oldy = this.rcconfirmbgimg.y;
    this.rcconfirmbgimg.y = oldy + 250;
    this.plog.log('tween', wh);
    var tw = wh.tweens.add({
      targets: this.rcconfirmbgimg,
      y: oldy,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
    });

    this.rcconfirmhead.setText(head);
    this.rcconfirmhead.setPosition(
      width / 2 - this.rcconfirmhead.displayWidth / 2,
      topofdialog
    );

    this.rcconfirmtext.setText(text);
    this.rcconfirmtext.setPosition(
      width / 2 - this.rcconfirmtext.displayWidth / 2,
      height / 2 - this.rcconfirmtext.displayHeight / 2
    );
    this.rcconfirmbtn.removeAllListeners();
    this.rcconfirmbtn.setInteractive();
    this.rcconfirmbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcconfirmbtn",this,arguments);
        this.rcconfirmpass = 'ok';
        wh.tweens.add({
          targets: [
            this.rcconfirmbg,
            this.rcconfirmbtn,
            this.rccancelbtn,
            this.rcconfirmhead,
            this.rcconfirmtext,
            this.rcconfirmbgimg,
            this.rcconfirmbg,
          ],
          alpha: 0,
          ease: 'Power1',
          duration: 300,
          yoyo: false,
          repeat: 0,
          onComplete: function () {
            this.plog.log('osdflash tweencomplete', this);
            arguments[1][0].visible = true;
            arguments[1][1].visible = true;
            arguments[1][2].visible = true;
            arguments[1][3].visible = true;
            arguments[1][4].visible = true;
            arguments[1][5].visible = true;
            arguments[1][6].visible = true;
          },
        });
      },
      this
    );

    this.rccancelbtn.removeAllListeners();
    this.rccancelbtn.setInteractive();
    this.rccancelbtn.on(
      'pointerup',
      function () {
        //this.plog.log("rcconfirmbtn",this,arguments);
        this.rcconfirmpass = 'cancel';

        wh.tweens.add({
          targets: [
            this.rcconfirmbg,
            this.rcconfirmbtn,
            this.rccancelbtn,
            this.rcconfirmhead,
            this.rcconfirmtext,
            this.rcconfirmbgimg,
            this.rcconfirmbg,
          ],
          alpha: 0,
          ease: 'Power1',
          duration: 300,
          yoyo: false,
          repeat: 0,
          onComplete: function () {
            this.plog.log('osdflash tweencomplete', arguments);
            arguments[1][0].visible = true;
            arguments[1][1].visible = true;
            arguments[1][2].visible = true;
            arguments[1][3].visible = true;
            arguments[1][4].visible = true;
            arguments[1][5].visible = true;
            arguments[1][6].visible = true;
          },
        });
      },
      this
    );

    while (this.rcconfirmpass == '') await this.timeout(50); // pauses script
    this.plog.log('this.rcconfirmpass', this.rcconfirmpass);
    return this.rcconfirmpass;
  }
  rcconfirm_close() {
    this.rcconfirmpass = '';
    this.rcconfirmbtn.visible = false;
    this.rccancelbtn.visible = false;
    this.rcconfirmhead.visible = false;
    this.rcconfirmtext.visible = false;
    this.rcconfirmbgimg.visible = false;
    this.rcconfirmbg.visible = false;
  }
  async iapview(wh, code) {
    this.loadingshow();
    this.plog.log("iapview", code, this.iapviewcontainer);
    var iapdata = wh.registry.list.rcvarpass.iapstoredata[code];
    var rwidth = wh.game.canvas.width;
    var rheight = wh.game.canvas.height;
    var width = 800;
    var height = 800;
    if (this.paypalbtn != undefined && this.paypalbtn[0] != undefined) {
      this.paypalbtn[0].style.display = 'none'
    }

    if (this.paypalbtn != undefined && this.paypalbtn[0] != undefined) {
      this.paypalbtn[0].style.display = 'none'
    }
    for (var k in this.iapview_rw) {
      if (this.iapview_rw[k] != undefined)
        this.iapview_rw[k].destroy();
    }
    for (var k in this.iapview_rwt) {
      if (this.iapview_rwt[k] != undefined)
        this.iapview_rwt[k].destroy();
    }
    if (this.iapview_freebtn != undefined)
      this.iapview_freebtn.destroy();

    if (this.iapview_iap2btn != undefined)
      this.iapview_iap2btn.destroy();
    if (this.iapviewcontainer != undefined) {
      for (let desi = 0; desi < 20; desi++) {
        for (const k in this.iapviewcontainer.list) {
          this.iapviewcontainer.list[k].destroy();
        }
      }
      //this.iapviewcontainer.destroy();
    }
    this.iapviewcontainer = undefined;
    if (this.iapviewcontainer == undefined) {
      this.iapviewcontainer = wh.add.container(0, 0);
    }
    this.iapviewcontainer.visible = true;
    this.iapviewcontainer.setDepth(1500003);
    this.iapviewcontainer.setScale(1);
    /*if (this.iapview_bgimg != undefined) {
      this.iapview_icon.destroy();
      this.iapview_icon = undefined;
      this.iapview_bgimg.destroy();
      this.iapview_bgimg = undefined;
      this.iapview_bg.destroy();
      this.iapview_bg = undefined;
      this.iapview_txt.destroy();
      this.iapview_txt = undefined;
      this.iapview_price.destroy();
      this.iapview_price = undefined;
    }
    if (this.iapview_descr != undefined) {
      this.iapview_descr.destroy();
      this.iapview_descr = undefined;
    }*/

    this.iapview_bg = wh.add.rectangle(0, 0, rwidth * 10, rheight * 10, 0xffffff);
    this.iapview_bg.setAlpha(0.7);
    this.iapview_bg.setPosition(0, 0);
    this.iapview_bg.setOrigin(0, 0);
    this.iapview_bg.setAlpha(0.5);
    this.iapview_bg.visible = true;
    this.iapview_bg.setDepth(1500000);
    this.iapview_bg.setInteractive();
    this.iapview_bg.on("pointerup", function () {
      this.iapview_close(wh);
    }, this);
    this.iapviewcontainer.add(this.iapview_bg);
    this.iapviewcontainer.bringToTop(this.iapview_bg);

    this.iapview_bgimg = wh.add.image(0, 0, 'iapitembg');
    this.iapview_bgimg.setOrigin(0.5, 0.5);
    var localwidth = Math.min(width, height);
    if (localwidth > 550) localwidth = 550;
    localwidth = width;
    this.iapview_bgimg.displayWidth = localwidth * 0.9;
    this.iapview_bgimg.scaleY = this.iapview_bgimg.scaleX;
    //this.iapview_bgimg.setDepth(1500003);
    this.iapview_bgimg.setPosition(width / 2, height / 2);
    this.iapview_bgimg.setInteractive({ pixelPerfect: true });
    this.iapviewcontainer.add(this.iapview_bgimg);
    this.iapviewcontainer.bringToTop(this.iapview_bgimg);

    this.iapview_icon = wh.add.sprite(32, 32, 'iapstore_item', code);
    this.iapview_icon.setOrigin(0.5, 0.5);
    var localwidth = Math.min(width, height);
    if (localwidth > 550) localwidth = 550;
    localwidth = width;
    this.iapview_icon.displayWidth = this.iapview_bgimg.displayWidth * 0.25;
    this.iapview_icon.scaleY = this.iapview_icon.scaleX;
    //this.iapview_icon.setDepth(1500003);
    this.iapview_icon.setPosition(
      (this.iapview_bgimg.x -
        (this.iapview_bgimg.displayWidth / 2)) + (this.iapview_icon.displayWidth / 2) + 120,
      (this.iapview_bgimg.y -
        (this.iapview_bgimg.displayHeight / 2)) + (this.iapview_icon.displayHeight / 2) + 50,
    );
    this.iapviewcontainer.add(this.iapview_icon);
    this.iapviewcontainer.bringToTop(this.iapview_icon);

    var tmpname = this.wh.trs("t:iapstore_item:code:name:" + iapdata["code"] + "===" + iapdata["name"])
    this.iapview_txt = wh.add.text(0, 0, tmpname, wh.fs["iapviewhead"]);
    if (tmpname.length > 14) this.iapview_txt.setFontSize("22px");
    this.iapview_txt.setDepth(1500003);
    this.iapview_txt.setPosition(
      this.iapview_icon.x + (this.iapview_icon.displayWidth / 2) + 7,
      this.iapview_icon.y - (this.iapview_icon.displayHeight / 2) + 7
    );
    this.iapview_txt.setWordWrapWidth(this.iapview_bgimg.displayWidth * 0.45);
    this.iapviewcontainer.add(this.iapview_txt);
    this.iapviewcontainer.bringToTop(this.iapview_txt);

    this.iapview_price = wh.add.text(0, 0, "" + iapdata["currency"] + iapdata["price"], wh.fs["iapviewprice"]);
    this.iapview_price.setDepth(1500003);
    this.iapview_price.setPosition(
      this.iapview_icon.x + (this.iapview_icon.displayWidth / 2) + 7,
      this.iapview_icon.y + (this.iapview_icon.displayHeight / 2) - 55
    );
    this.iapview_price.setWordWrapWidth(this.iapview_bgimg.displayWidth * 0.50);
    this.iapviewcontainer.add(this.iapview_price);
    this.iapviewcontainer.bringToTop(this.iapview_price);

    var s_t = (this.iapview_bgimg.y + (this.iapview_bgimg.displayHeight / 2)) - 170;
    var s_l = (this.iapview_icon.x - (this.iapview_icon.displayWidth / 2) + 7) + 30;
    var s_size = 96;
    var ei = 0;
    for (const k in iapdata["effdata"]) {
      var ev = iapdata["effdata"][k];
      var eva = ev.split(":");
      this.plog.log("iaploop", k, eva);
      if (eva[0] == "stackitem") {
        this.iapview_rw[ei] = wh.add.sprite(0, 0, "stackitem_tp", eva[1]);
        this.iapview_rw[ei].name = wh.registry.list.rcvarpass.allstackitem[eva[1]]["name"];
        this.iapview_rwt[ei] = wh.add.text(0, 0, eva[2], wh.fs["iapviewwingui_amnt"]);
      } else if (eva[0] == "item") {
        this.iapview_rw[ei] = wh.add.sprite(0, 0, "item_tp", eva[1]);
        this.iapview_rw[ei].name = wh.registry.list.rcvarpass.allitem[eva[1]]["name"];
        this.iapview_rwt[ei] = wh.add.text(0, 0, "+" + eva[2], wh.fs["iapviewwingui_amnt"]);
      } else {
        continue;
      }
      this.iapview_rw[ei].removeAllListeners();
      this.iapview_rw[ei].setInteractive();
      this.iapview_rw[ei].on("pointerup", async function () {
        this.scene.registry.list.rctoast.rctoast(this.scene, this.name);
      });
      if (eva.length < 3) continue;
      if (this.iapview_rw[ei] == undefined) continue;
      //wh.iapstorecontainer.add(this.iapview_rw[ei]);
      //wh.iapstorecontainer.bringToTop(this.iapview_rw[ei]);

      this.iapview_rw[ei].x = s_l + (3 * ei) + (s_size * ei);
      this.iapview_rw[ei].y = s_t;
      this.iapview_rwt[ei].x = this.iapview_rw[ei].x - (s_size / 2)
      this.iapview_rwt[ei].y = this.iapview_rw[ei].y + 18
      this.iapview_rw[ei].displayWidth = this.iapview_rw[ei].displayHeight = s_size;
      this.iapview_rw[ei].setDepth(1500053);
      this.iapview_rwt[ei].setDepth(1500053);
      this.plog.log("iapview_rw", k, eva, this.iapview_rw[ei]);
      this.iapviewcontainer.add(this.iapview_rw[ei]);
      this.iapviewcontainer.bringToTop(this.iapview_rw[ei]);
      this.iapviewcontainer.add(this.iapview_rwt[ei]);
      this.iapviewcontainer.bringToTop(this.iapview_rwt[ei]);

      ei++;
    }
    var tmpdescr = this.wh.trs("t:iapstore_item:code:descr:" + iapdata["code"] + "===" + iapdata["descr"])

    this.iapview_descr = wh.add.text(0, 0, tmpdescr, wh.fs["iapviewdescr"]);
    this.iapview_descr.setDepth(1500003);
    this.iapview_descr.setPosition(
      this.iapview_icon.x - (this.iapview_icon.displayWidth / 2) + 35,
      this.iapview_icon.y + (this.iapview_icon.displayHeight / 2) + 20
    );
    this.iapviewcontainer.add(this.iapview_descr);
    this.iapviewcontainer.bringToTop(this.iapview_descr);

    this.iapview_descr.setWordWrapWidth(this.iapview_bgimg.displayWidth * 0.55, true);
    var btndisplayed = 0;
    this.plog.log("paymentoptions", wh.registry.list.rcvarpass.paymentoptions);

    //this.plog.error("iapdata", iapdata);
    if (iapdata['price_usd'] == 0) {
      //free deals
      this.iapview_freebtn = wh.add.sprite(0, 0, "theb", "freeupper");//wh.add.image(0,0,"purchasebtn");
      this.iapviewcontainer.add(this.iapview_freebtn);
      this.iapviewcontainer.bringToTop(this.iapview_freebtn);
      this.iapview_freebtn.visible = true;


      this.iapview_freebtn.x = (this.iapview_icon.x - (this.iapview_icon.displayWidth / 2) + 7) + (btndisplayed * 150);
      this.iapview_freebtn.y = (
        (this.iapview_bgimg.y +
          (this.iapview_bgimg.displayHeight / 2)) - 115);
      this.iapview_freebtn.setDepth(1500103);
      //this.plog.log("this.iapview_iap2btn", this.iapview_iap2btn);
      this.iapview_freebtn.displayWidth = 300;
      this.iapview_freebtn.setOrigin(0, 0);
      this.iapview_freebtn.scaleY = this.iapview_freebtn.scaleX;
      this.iapview_freebtn.removeAllListeners();
      this.iapview_freebtn.setInteractive();
      this.iapview_freebtn.on("pointerup", async function () {
        this.iapview_freebtn.visible = false;
        //var tmp=this.wh.registry.list.thethis.iap.order(iapdata["code"]);
        //this.plog.log("iapview_iap2btn pointerup", this.wh.registry.list.thethis.iap);
        //this.plog.log("iapview_freebtn products", this.wh.registry.list.thethis.iap.products);
        this.plog.log("iapview_freebtn iapdata", iapdata);
        //iapdata


        var createorder = await this.wh.registry.list.rchttp.getcommon2("iapstore-getfreeitems", {
          code: iapdata["code"]
        });
        this.plog.log("iapstore-getfreeitems res", createorder);
        if (createorder == "ok") {
          this.wh.registry.list.rctoast.rcalert(this.wh, "Success", "Thank you for your support\n\nPlease check your inbox/character status for free rewards!");
          this.iapview_close(this.wh);
          this.wh.registry.list.iapstore.showinventory(this.wh, 'normal', true);
          await this.wh.registry.list.rchttp.updatecharinfo();
        } else {
          this.wh.registry.list.rctoast.rcalert(this.wh, "Error", "Transaction error\n\nPlease check your transaction and try again");
          this.iapview_close(this.wh);
          await this.wh.registry.list.rchttp.updatecharinfo();
        }

      }, this);
    } else {
      if (wh.registry.list.rcvarpass.paymentoptions.includes("rc")) {
        this.iapview_rcbtn = wh.add.sprite(0, 0, "thesb", "purchase");//wh.add.image(0,0,"purchasebtn");
        this.iapviewcontainer.add(this.iapview_rcbtn);
        this.iapviewcontainer.bringToTop(this.iapview_rcbtn);

        this.iapview_rcbtn.x = (this.iapview_icon.x - (this.iapview_icon.displayWidth / 2) + 7) + (btndisplayed * 150);
        this.iapview_rcbtn.y = (
          (this.iapview_bgimg.y +
            (this.iapview_bgimg.displayHeight / 2)) - 115);
        this.iapview_rcbtn.setDepth(1500103);
        //this.plog.log("this.iapview_rcbtn", this.iapview_rcbtn);
        this.iapview_rcbtn.displayWidth = 135;
        this.iapview_rcbtn.setOrigin(0, 0);
        this.iapview_rcbtn.scaleY = this.iapview_rcbtn.scaleX;
        this.iapview_rcbtn.removeAllListeners();
        this.iapview_rcbtn.setInteractive();
        this.iapview_rcbtn.on("pointerup", async function () {
          //var tmp=this.wh.registry.list.thethis.iap.order(iapdata["code"]);
          //this.plog.log("iapview_iap2btn pointerup", this.wh.registry.list.thethis.iap);
          //var prod=this.wh.registry.list.rcvarpass
          //this.plog.log("iapview_rcbtn products", this.wh.registry.list);
          //this.plog.log("iapview_rcbtn products", this.wh.registry.list.thethis.iap.products);
          var tmpcurrentlogin = await this.wh.registry.list.rcstorage.get('rc_login');
          var tmplastplaycharid = 0;
          console.log("varchk", this.wh.registry.list);
          if (this.wh.registry.list.rcvarpass.activechar != undefined) {
            tmplastplaycharid = this.wh.registry.list.rcvarpass.activechar.id;
          }
          var tmpappuserid = tmpcurrentlogin + "#" + tmplastplaycharid
          this.plog.log("iapview_rcbtn iapdata", iapdata);
          const customerInfo = await Purchases.logIn({ appUserID: tmpappuserid });
          this.plog.log("iapview_rcbtn customerInfo", customerInfo);
          //iapdata

          //var product = await this.wh.registry.list.thethis.iap.get(iapdata["code"]);
          var product = await this.rc_getProducts(iapdata["code"]);
          this.plog.log('iapview_rcbtn Product Info: ', product);
          try {
            const purchaseResult = await Purchases.purchaseStoreProduct({
              product: product["products"][0],
            });
            console.log("purchaseResult", purchaseResult);
            if (typeof purchaseResult.productIdentifier !== "undefined") {
              // Unlock that great "pro" content
              console.warn("Unlock that great pro content");

              var createorder = await this.wh.registry.list.rchttp.getcommon2("iapstore-rcpc", {
                code: iapdata["code"],
                rcdata: purchaseResult
              });//,this.wh.registry.list.rcvarpass.rc_baseurl
              console.log("createorder", createorder);
              this.wh.registry.list.rctoast.rcalert(this.wh, "Success", "Thank you for your support\n\nPlease check your inbox/character status for your delivery!");
              this.iapview_close(this.wh);
              this.wh.registry.list.iapstore.showinventory(this.wh, 'normal', true);
              await this.wh.registry.list.rchttp.updatecharinfo();
            }
          } catch (error: any) {
            console.warn("Purchase error", error);

            if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
              // Purchase cancelled
              //console.warn("Purchase cancelled");
              this.wh.registry.list.rctoast.rcalert(this.wh, "Error", "Purchase cancelled\n\nPlease check your transaction and try again");
              this.iapview_close(this.wh);
              await this.wh.registry.list.rchttp.updatecharinfo();
            } else {
              // Error making purchase
              console.warn("Error making purchase");
              this.wh.registry.list.rctoast.rcalert(this.wh, "Error", "Transaction error\n\nPlease check your transaction and try again");
              this.iapview_close(this.wh);
              await this.wh.registry.list.rchttp.updatecharinfo();
            }
          }
          return;
          try {
            const purchaseResult = await Purchases.purchasePackage({ aPackage: product });
            console.warn("purchaseResult", purchaseResult);
            if (typeof purchaseResult.customerInfo.entitlements.active['my_entitlement_identifier'] !== "undefined") {
              // Unlock that great "pro" content
              console.warn("Unlock that great pro content");
            }
          } catch (error: any) {
            console.warn("Purchase error", error);

            if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
              // Purchase cancelled
              console.warn("Purchase cancelled");
            } else {
              // Error making purchase
              console.warn("Error making purchase");
            }
          }
          //} catch (err) {
          //  this.plog.log('Error Ordering ', err);
          //}
        }, this);
        btndisplayed++;
      }

      if (wh.registry.list.rcvarpass.paymentoptions.includes("amazon")) {
        this.iapview_amazonbtn = wh.add.sprite(0, 0, "thesb", "purchase");//wh.add.image(0,0,"purchasebtn");
        this.iapviewcontainer.add(this.iapview_amazonbtn);
        this.iapviewcontainer.bringToTop(this.iapview_amazonbtn);

        this.iapview_amazonbtn.x = (this.iapview_icon.x - (this.iapview_icon.displayWidth / 2) + 7) + (btndisplayed * 150);
        this.iapview_amazonbtn.y = (
          (this.iapview_bgimg.y +
            (this.iapview_bgimg.displayHeight / 2)) - 115);
        this.iapview_amazonbtn.setDepth(1500103);
        //this.plog.log("this.iapview_amazonbtn", this.iapview_amazonbtn);
        this.iapview_amazonbtn.displayWidth = 135;
        this.iapview_amazonbtn.setOrigin(0, 0);
        this.iapview_amazonbtn.scaleY = this.iapview_amazonbtn.scaleX;
        this.iapview_amazonbtn.removeAllListeners();
        this.iapview_amazonbtn.setInteractive();
        this.iapview_amazonbtn.on("pointerup", async function () {
          //var tmp=this.wh.registry.list.thethis.iap.order(iapdata["code"]);
          //this.plog.log("iapview_iap2btn pointerup", this.wh.registry.list.thethis.iap);
          //var prod=this.wh.registry.list.rcvarpass
          //this.plog.log("iapview_amazonbtn products", this.wh.registry.list);
          //this.plog.log("iapview_amazonbtn products", this.wh.registry.list.thethis.iap.products);
          var tmpcurrentlogin = await this.wh.registry.list.rcstorage.get('rc_login');
          var tmplastplaycharid = 0;
          console.log("varchk", this.wh.registry.list);
          if (this.wh.registry.list.rcvarpass.activechar != undefined) {
            tmplastplaycharid = this.wh.registry.list.rcvarpass.activechar.id;
          }
          var tmpappuserid = tmpcurrentlogin + "#" + tmplastplaycharid
          console.log("iapview_amazonbtn iapdata", iapdata);
          //iapdata

          //var product = await this.wh.registry.list.thethis.iap.get(iapdata["code"]);

          var useshopcode = iapdata['code']
          //this.plog.log("create stripe btnx", wh, iapdata,useshopcode);
          console.log("create amazon btnx", wh, iapdata, useshopcode);
          wh.registry.list.anyjs.amazonbuypackage(useshopcode + "");
          return;
        }, this);
        btndisplayed++;
      }
      if (wh.registry.list.rcvarpass.paymentoptions.includes("iap2")) {
        this.iapview_iap2btn = wh.add.sprite(0, 0, "thesb", "purchase");//wh.add.image(0,0,"purchasebtn");
        this.iapviewcontainer.add(this.iapview_iap2btn);
        this.iapviewcontainer.bringToTop(this.iapview_iap2btn);

        this.iapview_iap2btn.x = (this.iapview_icon.x - (this.iapview_icon.displayWidth / 2) + 7) + (btndisplayed * 150);
        this.iapview_iap2btn.y = (
          (this.iapview_bgimg.y +
            (this.iapview_bgimg.displayHeight / 2)) - 115);
        this.iapview_iap2btn.setDepth(1500103);
        //this.plog.log("this.iapview_iap2btn", this.iapview_iap2btn);
        this.iapview_iap2btn.displayWidth = 135;
        this.iapview_iap2btn.setOrigin(0, 0);
        this.iapview_iap2btn.scaleY = this.iapview_iap2btn.scaleX;
        this.iapview_iap2btn.removeAllListeners();
        this.iapview_iap2btn.setInteractive();
        this.iapview_iap2btn.on("pointerup", async function () {
          //var tmp=this.wh.registry.list.thethis.iap.order(iapdata["code"]);
          //this.plog.log("iapview_iap2btn pointerup", this.wh.registry.list.thethis.iap);
          this.plog.log("iapview_iap2btn products", this.wh.registry.list.thethis.iap.products);
          this.plog.log("iapview_iap2btn iapdata", iapdata);
          //iapdata

          var product = await this.wh.registry.list.thethis.iap.get(iapdata["code"]);
          this.plog.log('iapview_iap2btn Product Info: ', product);
          //try {
          //var product = await this.wh.registry.list.thethis.iap.get(iapdata["code"]);
          //this.plog.log('Product Info: ', product);
          /*this.wh.registry.list.thethis.iap.order(iapdata["code"]).then(() => {
            this.plog.log('Purchase Succesfull');
          }).catch(() => {
            this.plog.log('Error Ordering From Store');
          });*/
          this.wh.registry.list.thethis.iap.order(iapdata["code"]).then(p => {
            // Purchase in progress!
            this.plog.log("Purchase in progress");
          }, e => {
            this.plog.error('Failed', `Failed to purchase: ${e}`);
          });
          //} catch (err) {
          //  this.plog.log('Error Ordering ', err);
          //}
        }, this);
        btndisplayed++;
      }
      if (wh.registry.list.rcvarpass.paymentoptions.includes("paypal")) {
        this.paypalbtn = this.createpaypalbtn(wh, iapdata["price"], iapdata["currency"]);
        this.plog.log("paypal ppbtn", this.paypalbtn);
        //this.iapviewcontainer.add(this.paypalbtn);
        //this.iapviewcontainer.bringToTop(this.paypalbtn);

        this.paypalbtn[0].style.position = "absolute";
        this.paypalbtn[0].origx = (this.iapview_icon.x - (this.iapview_icon.displayWidth / 2) + 7) + (btndisplayed * 150)
        this.paypalbtn[0].style.left = this.paypalbtn[0].origx + "px";
        this.paypalbtn[0].style.height = "40px";
        this.paypalbtn[0].origy = (
          (this.iapview_bgimg.y +
            (this.iapview_bgimg.displayHeight / 2)) - 115);
        this.paypalbtn[0].style.top = this.paypalbtn[0].origy + "px";
        this.plog.log("this.paypalbtn", this.paypalbtn);
        wh.registry.list.paypal.Buttons({
          style: {
            layout: 'horizontal'
          },
          /*
          // Set up the transaction
          payment: function (data, actions) {
            this.plog.log("paypal payment",data,actions);
            // 2. Make a request to your server
            return actions.request.post('https://peacelyadmin.9nn.in/webhook/paypal.php')
              .then(function (res) {
                // 3. Return res.id from the response
                this.plog.log("paypal response", res);
                return res.id;
              });
          },
          // Execute the payment:
          // 1. Add an onAuthorize callback
          onAuthorize: function (data, actions) {
            // 2. Make a request to your server
            this.plog.log("paypal onAuthorize", data, actions);
  
            return actions.request.post('https://peacelyadmin.9nn.in/webhook/paypal.php', {
              paymentID: data.paymentID,
              payerID: data.payerID
            })
              .then(function (res) {
                this.plog.log("confirmation message.", res);
                // 3. Show the buyer a confirmation message.
              });
          }
          */
          createOrder: async function (data, actions) {
            this.plog.log('paypal createOrder this', this);
            this.plog.log('paypal createOrder', data, actions);
            //sd

            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: "" + iapdata["price"],
                  currency: iapdata["currency"]
                }
              }]
            });
          },

          // Finalize the transaction
          onApprove: async function (data, actions) {
            this.plog.log('paypalonApprove', this);

            return actions.order.capture().then(async function (orderData) {
              // Successful capture! For demo purposes:

              this.plog.log('paypalCapture result', orderData, JSON.stringify(orderData, null, 2));
              var createorder = await this.wh.registry.list.rchttp.getcommon2("paypal-chkorder", {
                code: iapdata["code"],
                price: iapdata["price"],
                orderData: orderData
              });
              this.plog.log("paypal-createorder res", createorder);
              if (createorder["result"] == "ok") {
                this.wh.registry.list.rctoast.rcalert(this.wh, "Success", "Thank you for your support\n\nPlease check your inbox/character status for purchase result");
                this.iapview_close(this.wh);
                await this.wh.registry.list.rchttp.updatecharinfo();
                if (createorder["reward"] != undefined) {
                  this.wh.registry.list.showreward.show(this.wh, createorder["reward"]);
                }
              } else {
                this.wh.registry.list.rctoast.rcalert(this.wh, "Error", "Transaction error\n\nPlease check your transaction and try again or contact support");
                this.iapview_close(this.wh);
                await this.wh.registry.list.rchttp.updatecharinfo();
              }
              //var transaction = orderData.purchase_units[0].payments.captures[0];
              //alert('Transaction ' + transaction.status + ': ' + transaction.id );//+ '\n\nSee console for all available details');

              // Replace the above to show a success message within this page, e.g.
              // const element = document.getElementById('paypal-button-container');
              // element.innerHTML = '';
              // element.innerHTML = '<h3>Thank you for your payment!</h3>';
              // Or go to another URL:  actions.redirect('thank_you.html');
            }.bind(this));
          }.bind(this)

        }).render('#' + this.paypalbtn[1]);
        //ppbtn[0].style.display = 'none';
        btndisplayed++;

      }
      if (wh.registry.list.rcvarpass.paymentoptions.includes("stripe")) {
        btndisplayed++;
        var useshopcode = iapdata['code']
        //this.plog.log("create stripe btnx", wh, iapdata,useshopcode);
        this.plog.log("create stripe btnx", wh, iapdata, useshopcode);
        wh.registry.list.anyjs.stripepaymentRequest(useshopcode + "");
        this.stripebtn = document.getElementById('payment-request-button');
        this.stripebtn.style.display = 'block';
        this.stripebtn_origx = (this.iapview_icon.x - (this.iapview_icon.displayWidth / 2) + 7) + (btndisplayed * 150)
        //this.stripebtn.style.left = this.stripebtn_origx + "px";
        this.stripebtn_origy = (
          (this.iapview_bgimg.y +
            (this.iapview_bgimg.displayHeight / 2)) - 115);
        //this.stripebtn.style.top = this.stripebtn_origy + "px";
      }
    }
    //scale
    var rtow = Math.min(800, rwidth) / width;//this.iapview_bgimg.displayWidth;
    var rtoh = Math.min(800, rheight) / width;//this.iapview_bgimg.displayWidth;
    var rto = Math.min(rtow, rtoh)
    this.plog.log("iapview rto", rto);
    this.iapviewcontainer.scale = rto;

    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
    this.loadinghide();

  }
  iapview_close(wh) {

    wh.tweens.add({
      targets: [
        this.iapviewcontainer
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 200,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        arguments[1][0].scene.plog.log('osdflash tweencomplete', this, arguments);
        if (arguments[1][0] != undefined)
          arguments[1][0].visible = false;
      },
    });
    if (this.paypalbtn != undefined && this.paypalbtn[0] != undefined) {
      this.paypalbtn[0].style.display = 'none'
    }
    if (this.stripebtn != undefined) {
      this.stripebtn.style.display = 'none'
    }

    return;
    if (this.iapviewcontainer != undefined)
      this.iapviewcontainer.destroy();
    return;
    if (this.paypalbtn != undefined && this.paypalbtn[0] != undefined) {
      this.paypalbtn[0].style.display = 'none'
    }
    for (var k in this.iapview_rw) {
      if (this.iapview_rw[k] != undefined)
        this.iapview_rw[k].destroy();
    }
    for (var k in this.iapview_rwt) {
      if (this.iapview_rwt[k] != undefined)
        this.iapview_rwt[k].destroy();
    }
    if (this.iapview_iap2btn != undefined)
      this.iapview_iap2btn.destroy();

    wh.tweens.add({
      targets: [
        this.iapview_bgimg,
        this.iapview_bg,
        this.iapview_txt,
        this.iapview_price,
        this.iapview_descr,
        this.iapview_icon,
      ],
      alpha: 0,
      ease: 'Power1',
      duration: 300,
      yoyo: false,
      repeat: 0,
      onComplete: function () {
        this.plog.log('osdflash tweencomplete', this);
        arguments[1][0].visible = false;
        arguments[1][1].visible = false;
        arguments[1][2].visible = false;
        arguments[1][3].visible = false;
        arguments[1][4].visible = false;
        arguments[1][5].visible = false;
      },
    });
  }


  async rc_getProducts(pcode) {
    console.log(PRODUCT_CATEGORY.NON_SUBSCRIPTION);
    const productIds = [pcode];//['annual_freetrial', 'unknown_product'];
    const products = await Purchases.getProducts({
      productIdentifiers: productIds,
      type: PRODUCT_CATEGORY.NON_SUBSCRIPTION,
    });
    return products;
    //updateLastFunction('getProducts', products);
  };

  async rc_purchaseStoreProduct() {
    const offerings = await Purchases.getOfferings();
    const productToBuy =
      offerings.current &&
      offerings.current.availablePackages &&
      offerings.current.availablePackages[0].product;
    if (productToBuy == null) {
      console.error("productToBuy null");
      //updateLastFunction(        'purchaseStoreProduct',        'No product found in current offering',      );
      return;
    }
    const purchaseResult = await Purchases.purchaseStoreProduct({
      product: productToBuy,
    });
    return purchaseResult;
    //updateLastFunction('purchaseStoreProduct', purchaseResult);
  };
}
