import { Injectable } from '@angular/core';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class RCOSDService {
  textlist = [];
  tw;
  equipbtn;
  unequipbtn;
  plog;

  constructor() {
    this.plog = new plog();
    // this.plog.setLevel(4);
  }

  show(wh, mode, data, selecteditem) {
    //this.plog.error("RCOSD show", wh, mode, data);
    if (wh.osdcontainer == undefined) {
      //
      wh.osdcontainer = wh.add.container(0, 0);
    }
    wh.osdcontainer.setDepth(2000000);
    if (this.tw != undefined)
      this.tw.stop();
    wh.osdcontainer.setAlpha(1);
    wh.osdcontainer.removeAll(true);
    this.plog.log("RCOSD wh.osdcontainer", wh.osdcontainer);
    var lineheight = 20;
    var cline = 0;
    var secondcolx = 100;
    var secondcolx2 = 150
    var thirdcolx = 200;
    var narrowcol2 = 70;
    var narrowcol3 = 140;
    var fs = {
      name: { fontSize: '16px', color: '#fff', stroke: '#000', strokeThickness: 3 },
      rarity: { fontSize: '16px', color: '#0ff', stroke: '#000', strokeThickness: 3 },
      sub: { fontSize: '12px', color: '#fff', stroke: '#000', strokeThickness: 2 },
      price: { fontSize: '12px', color: '#0ff', stroke: '#000', strokeThickness: 2 },
      red: { fontSize: '12px', color: '#fcc', stroke: '#000', strokeThickness: 3 },
      green: { fontSize: '12px', color: '#7f7', stroke: '#000', strokeThickness: 3 },
      blue: { fontSize: '12px', color: '#77f', stroke: '#000', strokeThickness: 3 },
    }
    var margintop = 100;
    var marginleft = 10;

    if (mode == "pet") {
      this.plog.log("rcosdpet", data);
      if (data["name"] == "") data["name"] = "Unnamed Pet";
      if (data != undefined && data["name"] != undefined) {
        var tmp = wh.add.text(0, 0, data["name"], {});
        tmp.setShadow(3, 3, '#000000', 0, true, true);
        tmp.setStyle(fs["name"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        cline++;
      }
      if (data != undefined && data["dtstart"] != undefined && data["dtstart"] != "") {
        var tmp = wh.add.text(0, 0, "Hatched: " + wh.gl(data["dtstart"]), {});
        tmp.setShadow(3, 3, '#000000', 0, true, true);
        tmp.setStyle(fs["sub"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        cline++;
      }

      var tmp = wh.add.text(0, 0, wh.trs("f:rcosd:Level===Level") + ": " + wh.gl(data["level"] + "/" + data["maxlevel"]), {});
      tmp.setShadow(3, 3, '#000000', 0, true, true);
      tmp.setStyle(fs["sub"]);
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      cline++;
      var tmp = wh.add.text(0, 0, "Race: " + wh.gl(data["racename"]), {});
      tmp.setShadow(3, 3, '#000000', 0, true, true);
      tmp.setStyle(fs["sub"]);
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      cline++;

    }

    if (mode == "stackitem") {
      if (data != undefined && data["name"] != undefined) {
        var tmp = wh.add.text(0, 0, data["name"], {});
        tmp.setShadow(3, 3, '#000000', 0, true, true);
        tmp.setStyle(fs["name"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        cline++;
      }
      if (data != undefined && data["descr"] != undefined && data["descr"] != "") {
        var tmp = wh.add.text(0, 0, wh.gl(data["descr"]), {});
        tmp.setShadow(3, 3, '#000000', 0, true, true);
        tmp.setStyle(fs["sub"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        cline++;
      }
      if (data != undefined && data["pcode"] != undefined) {
        var typestr = wh.trs("t:stackitem_type:code:name:" + data["pcode"] + "===" + data["pcode"])
        var tmp = wh.add.text(0, 0, wh.trs("f:rcosd:Type===Type") + ": " + typestr, {});
        tmp.setShadow(3, 3, '#000000', 0, true, true);
        tmp.setStyle(fs["green"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        cline++;
      }
      if (data != undefined && data["pcode"] != undefined && data["pcode"] == "egg") {
        var mobdata = wh.registry.list.rcvarpass.allmob[data['code'].substring(3)];
        //console.log(data);
        if (mobdata != undefined) {
          //console.log(mobdata);
          var moblevelstr = wh.trs("f:rcosd:Level===Level") + ": " + mobdata["level"];
          var tmp = wh.add.text(0, 0, moblevelstr, {});
          tmp.setShadow(3, 3, '#000000', 0, true, true);
          tmp.setStyle(fs["green"]);
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
          cline++;

          var tmp = wh.add.text(0, 0, wh.registry.list.rcvarpass.allrace[mobdata["attmimic"]]["name"], {});
          tmp.setShadow(3, 3, '#000000', 0, true, true);
          tmp.setStyle(fs["blue"]);
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
          cline++;
        }
      }
      var tmp = wh.add.text(0, 0, "Rarity: " + data["rarity"], {});
      tmp.setShadow(3, 3, '#000000', 0, true, true);
      tmp.setStyle(fs["green"]);
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      cline++;
      if (data["pcode"] == "skillbook") {
        //
        this.plog.log("found skillbook", data);

        var skillida = data["module"].split("-");
        var skillid = skillida[1];//+ "-" + skillida[2];
        var skilldata = wh.registry.list.rcvarpass.skilltp[skillid];
        this.plog.warn("skilltp", wh.registry.list.rcvarpass.skilltp);
        this.plog.warn("skilldata", skillid, skilldata);
        if (skilldata != undefined) {
          var tmp = wh.add.text(0, 0, wh.trs("f:rcosd:Use to learn skill===Use to learn skill") + ": ", {});
          tmp.setShadow(3, 3, '#000000', 0, true, true);
          tmp.setStyle(fs["sub"]);
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
          cline++;
          var tmp = wh.add.text(0, 0, wh.gl(skilldata["name"]), {});
          tmp.setShadow(3, 3, '#000000', 0, true, true);
          tmp.setStyle(fs["name"]);
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
          cline++;
          var tmp = wh.add.text(0, 0, wh.trs("t:skill_tp:code:descr:" + skilldata["code"] + "===" + skilldata["descr"]), {});
          tmp.setShadow(3, 3, '#000000', 0, true, true);
          tmp.setStyle(fs["sub"]);
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
          cline++;
          var tmp = wh.add.text(0, 0, wh.trs("f:rcosd:For===For") + ": " + wh.registry.list.rcvarpass.allrace[skilldata["targetrace"]]["name"], {});
          tmp.setShadow(3, 3, '#000000', 0, true, true);
          if (skilldata["targetrace"] == wh.registry.list.rcvarpass.activechar["race"]) {
            tmp.setStyle(fs["green"]);
          } else {
            tmp.setStyle(fs["red"]);
          }
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
          cline++;
          var tmp = wh.add.text(0, 0, wh.gl("Requirements:"), {});
          tmp.setShadow(3, 3, '#000000', 0, true, true);
          tmp.setStyle(fs["name"]);
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
          cline++;
          //

          var tmp = wh.add.text(0, 0, "STR: " + skilldata["requirements"]["att_str"], {});
          if (wh.registry.list.rcvarpass.activechar["s_str"] >= skilldata["requirements"]["att_str"]) {
            tmp.setStyle(fs["green"]);
          } else {
            tmp.setStyle(fs["red"]);
            passreq = false;
          }
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
          var tmp = wh.add.text(0, 0, "DEX: " + skilldata["requirements"]["att_dex"], {});
          if (wh.registry.list.rcvarpass.activechar["s_dex"] >= skilldata["requirements"]["att_dex"]) {
            tmp.setStyle(fs["green"]);
          } else {
            tmp.setStyle(fs["red"]);
            passreq = false;
          }
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft + narrowcol2, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);

          var tmp = wh.add.text(0, 0, "CON: " + skilldata["requirements"]["att_con"], {});
          if (wh.registry.list.rcvarpass.activechar["s_con"] >= skilldata["requirements"]["att_con"]) {
            tmp.setStyle(fs["green"]);
          } else {
            tmp.setStyle(fs["red"]);
            passreq = false;
          }
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft + narrowcol3, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
          cline++;
          //

          var tmp = wh.add.text(0, 0, "CHA: " + skilldata["requirements"]["att_cha"], {});
          if (wh.registry.list.rcvarpass.activechar["s_cha"] >= skilldata["requirements"]["att_cha"]) {
            tmp.setStyle(fs["green"]);
          } else {
            tmp.setStyle(fs["red"]);
            passreq = false;
          }
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
          var tmp = wh.add.text(0, 0, "INT: " + skilldata["requirements"]["att_int"], {});
          if (wh.registry.list.rcvarpass.activechar["s_int"] >= skilldata["requirements"]["att_int"]) {
            tmp.setStyle(fs["green"]);
          } else {
            tmp.setStyle(fs["red"]);
            passreq = false;
          }
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft + narrowcol2, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
          var tmp = wh.add.text(0, 0, "WIS: " + skilldata["requirements"]["att_wis"], {});
          if (wh.registry.list.rcvarpass.activechar["s_wis"] >= skilldata["requirements"]["att_wis"]) {
            tmp.setStyle(fs["green"]);
          } else {
            tmp.setStyle(fs["red"]);
            passreq = false;
          }
          tmp.setOrigin(0, 0);
          tmp.setPosition(marginleft + narrowcol3, margintop + (cline * lineheight));
          this.textlist[cline] = tmp;
          wh.osdcontainer.add(tmp);
        }
      }
      this.plog.log("data", data);
    }

    if (mode == "skill") {

      if (data["name"] != undefined) {
        var tmp = wh.add.text(0, 0, data["name"], {});
        tmp.setShadow(3, 3, '#000000', 0, true, true);
        tmp.setStyle(fs["name"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        cline++;
      }
      if (data["descr"] != undefined) {
        //this.plog.log("t:skill_tp:code:descr:" + data["code"]);
        var skdescr = wh.trs("t:skill_tp:code:descr:" + data["code"] + "===" + data["code"]);
        if (skdescr == data["code"]) {
          skdescr = wh.trs("t:skill_weapon_tp:code:descr:" + data["code"] + "===" + data["code"]);
        }
        var tmp = wh.add.text(0, 0, skdescr, {});
        tmp.setShadow(3, 3, '#000000', 0, true, true);
        tmp.setStyle(fs["sub"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
      }
      cline++;
      var tmp = wh.add.text(0, 0, wh.trs("f:rcosd:Type===Type") + ": " + data["skill_type"], {});
      tmp.setShadow(3, 3, '#000000', 0, true, true);
      tmp.setStyle(fs["sub"]);
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);

      cline++;
      var tmp = wh.add.text(0, 0, wh.trs("f:rcosd:Level===Level") + ": " + data["level"], {});
      tmp.setShadow(3, 3, '#000000', 0, true, true);
      tmp.setStyle(fs["sub"]);
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      cline++;

      var passreq = true;
      if (data["requirements"] != undefined && data["requirements"]["att_str"] != undefined && data["requirements"]["att_dex"] != undefined) {
        var tmp = wh.add.text(0, 0, "STR: " + data["requirements"]["att_str"], {});
        if (wh.registry.list.rcvarpass.activechar["s_str"] >= data["requirements"]["att_str"]) {
          tmp.setStyle(fs["green"]);
        } else {
          tmp.setStyle(fs["red"]);
          passreq = false;
        }
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        var tmp = wh.add.text(0, 0, "DEX: " + data["requirements"]["att_dex"], {});
        if (wh.registry.list.rcvarpass.activechar["s_dex"] >= data["requirements"]["att_dex"]) {
          tmp.setStyle(fs["green"]);
        } else {
          tmp.setStyle(fs["red"]);
          passreq = false;
        }
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + narrowcol2, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
      }
      if (data["requirements"] != undefined && data["requirements"]["att_con"] != undefined && data["requirements"]["att_cha"] != undefined) {

        var tmp = wh.add.text(0, 0, "CON: " + data["requirements"]["att_con"], {});
        if (wh.registry.list.rcvarpass.activechar["s_con"] >= data["requirements"]["att_con"]) {
          tmp.setStyle(fs["green"]);
        } else {
          tmp.setStyle(fs["red"]);
          passreq = false;
        }
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + narrowcol3, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);

        cline++;
        var tmp = wh.add.text(0, 0, "CHA: " + data["requirements"]["att_cha"], {});
        if (wh.registry.list.rcvarpass.activechar["s_cha"] >= data["requirements"]["att_cha"]) {
          tmp.setStyle(fs["green"]);
        } else {
          tmp.setStyle(fs["red"]);
          passreq = false;
        }
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
      }
      if (data["requirements"] != undefined && data["requirements"]["att_int"] != undefined && data["requirements"]["att_wis"] != undefined) {

        var tmp = wh.add.text(0, 0, "INT: " + data["requirements"]["att_int"], {});
        if (wh.registry.list.rcvarpass.activechar["s_int"] >= data["requirements"]["att_int"]) {
          tmp.setStyle(fs["green"]);
        } else {
          tmp.setStyle(fs["red"]);
          passreq = false;
        }
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + narrowcol2, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        var tmp = wh.add.text(0, 0, "WIS: " + data["requirements"]["att_wis"], {});
        if (wh.registry.list.rcvarpass.activechar["s_wis"] >= data["requirements"]["att_wis"]) {
          tmp.setStyle(fs["green"]);
        } else {
          tmp.setStyle(fs["red"]);
          passreq = false;
        }
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + narrowcol3, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
      }
    }

    if (mode == "item" || mode == "equipment" || mode == "itemnoeq") {
      if (data["name"] != undefined) {
        var tmp = wh.add.text(0, 0, data["name"], {});
        tmp.setShadow(3, 3, '#000000', 0, true, true);
        tmp.setStyle(fs["name"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
      }
      cline++;
      var attmult = 1;
      if (selecteditem != undefined && selecteditem["rarity"] != undefined && selecteditem["rarity"] != "") {
        var tmptext = "";
        if (selecteditem["rarity"] == "rar") {
          tmptext = "RARE ITEM";
          attmult = 3;
        }
        if (selecteditem["rarity"] == "leg") {
          tmptext = "LEGENDARY ITEM";
          attmult = 8;
        }
        if (selecteditem["rarity"] == "ult") {
          tmptext = "ULTIMATE ITEM";
          attmult = 27;
        }
        if (selecteditem["rarity"] == "rel") {
          tmptext = "RELIC ITEM";
          attmult = 99;
        }
        var tmp = wh.add.text(0, 0, tmptext, {});
        tmp.setShadow(3, 3, '#000000', 0, true, true);
        tmp.setStyle(fs["rarity"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        cline++;
      }
      //console.log(data);
      var typedescr = wh.trs("t:item_type:code:name:" + data["pcode"] + "===" + data["pcode"]);
      var tmp = wh.add.text(0, 0, wh.trs("f:rcosd:Type===Type") + ": " + typedescr, {});
      tmp.setShadow(3, 3, '#000000', 0, true, true);
      tmp.setStyle(fs["sub"]);
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      var tmp = wh.add.text(0, 0, "Price: " + data["price"], {});
      tmp.setShadow(3, 3, '#000000', 0, true, true);
      tmp.setStyle(fs["price"]);
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft + secondcolx2, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      cline++;
      var tmp = wh.add.text(0, 0, wh.trs("f:rcosd:Level===Level") + ": " + data["level"], {});
      tmp.setShadow(3, 3, '#000000', 0, true, true);
      tmp.setStyle(fs["sub"]);
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      if (selecteditem["itemplus"] != undefined && Number(selecteditem["itemplus"]) != 0) {
        var tmp = wh.add.text(0, 0, "+" + selecteditem["itemplus"], {});
        tmp.setShadow(3, 3, '#000000', 0, true, true);
        tmp.setStyle(fs["green"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + secondcolx, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);

      }
      cline++;

      var passreq = true;
      var tmp = wh.add.text(0, 0, "STR: " + data["requirements"]["att_str"], {});
      if (wh.registry.list.rcvarpass.activechar["s_str"] >= data["requirements"]["att_str"]) {
        tmp.setStyle(fs["green"]);
      } else {
        tmp.setStyle(fs["red"]);
        passreq = false;
      }
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      var tmp = wh.add.text(0, 0, "DEX: " + data["requirements"]["att_dex"], {});
      if (wh.registry.list.rcvarpass.activechar["s_dex"] >= data["requirements"]["att_dex"]) {
        tmp.setStyle(fs["green"]);
      } else {
        tmp.setStyle(fs["red"]);
        passreq = false;
      }
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft + narrowcol2, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      var tmp = wh.add.text(0, 0, "CON: " + data["requirements"]["att_con"], {});
      if (wh.registry.list.rcvarpass.activechar["s_con"] >= data["requirements"]["att_con"]) {
        tmp.setStyle(fs["green"]);
      } else {
        tmp.setStyle(fs["red"]);
        passreq = false;
      }
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft + narrowcol3, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);

      cline++;
      var tmp = wh.add.text(0, 0, "CHA: " + data["requirements"]["att_cha"], {});
      if (wh.registry.list.rcvarpass.activechar["s_cha"] >= data["requirements"]["att_cha"]) {
        tmp.setStyle(fs["green"]);
      } else {
        tmp.setStyle(fs["red"]);
        passreq = false;
      }
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      var tmp = wh.add.text(0, 0, "INT: " + data["requirements"]["att_int"], {});
      if (wh.registry.list.rcvarpass.activechar["s_int"] >= data["requirements"]["att_int"]) {
        tmp.setStyle(fs["green"]);
      } else {
        tmp.setStyle(fs["red"]);
        passreq = false;
      }
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft + narrowcol2, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      var tmp = wh.add.text(0, 0, "WIS: " + data["requirements"]["att_wis"], {});
      if (wh.registry.list.rcvarpass.activechar["s_wis"] >= data["requirements"]["att_wis"]) {
        tmp.setStyle(fs["green"]);
      } else {
        tmp.setStyle(fs["red"]);
        passreq = false;
      }
      tmp.setOrigin(0, 0);
      tmp.setPosition(marginleft + narrowcol3, margintop + (cline * lineheight));
      this.textlist[cline] = tmp;
      wh.osdcontainer.add(tmp);
      //item properties
      if (data["effects"] != undefined) {
        cline++;
        var tmp = wh.add.text(0, 0, "Attributes:", {});
        tmp.setStyle(fs["name"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + 0, margintop + (cline * lineheight));
        wh.osdcontainer.add(tmp);
        cline++;
        var dspval = data["effects"]["patk"].replace(".000", ""); dspval = dspval * attmult;
        if (dspval == undefined || dspval == "" || dspval == "undefined") dspval = 0;
        var tmp = wh.add.text(0, 0, "PATK: " + dspval, {});
        tmp.setStyle(fs["sub"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + 0, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        var dspval = data["effects"]["def"].replace(".000", ""); dspval = dspval * attmult;
        if (dspval == undefined || dspval == "" || dspval == "undefined") dspval = 0;
        var tmp = wh.add.text(0, 0, "DEF: " + dspval, {});
        tmp.setStyle(fs["sub"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + narrowcol2 + 10, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        var dspval = data["effects"]["acc"].replace(".000", ""); dspval = dspval * attmult;
        if (dspval == undefined || dspval == "" || dspval == "undefined") dspval = 0;
        var tmp = wh.add.text(0, 0, "ACC: " + dspval, {});
        tmp.setStyle(fs["sub"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + narrowcol3 + 20, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        cline++;

        var dspval = data["effects"]["spd"].replace(".000", ""); dspval = dspval * attmult;
        if (dspval == undefined || dspval == "" || dspval == "undefined") dspval = 0;
        var tmp = wh.add.text(0, 0, "ACC: " + dspval, {});
        tmp.setStyle(fs["sub"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + 0, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);

        var dspval = data["effects"]["matk"].replace(".000", ""); dspval = dspval * attmult;
        if (dspval == undefined || dspval == "" || dspval == "undefined") dspval = 0;
        var tmp = wh.add.text(0, 0, "MATK: " + dspval, {});
        tmp.setStyle(fs["sub"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + narrowcol2 + 10, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        /*
                if (data["effects"]["acc"] == undefined) data["effects"]["acc"] = 0;
                data["effects"]["acc"] = data["effects"]["acc"].replace(".000", "");
                var tmp = wh.add.text(0, 0, "ACC: " + data["effects"]["acc"], {});
                tmp.setStyle(fs["name"]);
                tmp.setOrigin(0, 0);
                tmp.setPosition(marginleft + narrowcol3, margintop + (cline * lineheight));
                this.textlist[cline] = tmp;
                wh.osdcontainer.add(tmp);*/
      }
      if (selecteditem != undefined && selecteditem["gems"] != undefined && selecteditem["gems"] != "") {
        cline++;

        //console.error(selecteditem);
        var tmp = wh.add.text(0, 0, "Gem Slots", {});
        tmp.setStyle(fs["name"]);
        tmp.setOrigin(0, 0);
        tmp.setPosition(marginleft + 0, margintop + (cline * lineheight));
        this.textlist[cline] = tmp;
        wh.osdcontainer.add(tmp);
        cline++;
        //cline++;

        for (var gemdspi = 1; gemdspi <= 4; gemdspi++) {
          if (selecteditem["gems"]["gem" + gemdspi] == undefined) continue;
          var tmpgemdspi = wh.add.sprite(0, 0, "thecolor", "gemempty");

          if (selecteditem["gems"]["gem" + gemdspi] != "") {
            // tmpgemdspi.setTexture("stackitem_tpt", gemstr["gem"+gemdspi]);
            var gemcode = selecteditem["gems"]["gem" + gemdspi].replace(/\d+$/, '');
            tmpgemdspi.setTexture("thecolor", "gem" + gemcode);
          }
          tmpgemdspi.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
          tmpgemdspi.setOrigin(0, 0);
          tmpgemdspi.postFX.addShine(0.5, 0.2, 5, false);
          this.textlist[cline] = tmpgemdspi;
          tmpgemdspi.displayWidth = tmpgemdspi.displayHeight = 16;
          wh.osdcontainer.add(tmpgemdspi);
          tmpgemdspi.setPosition(marginleft + 0, margintop + (cline * lineheight));

          var gemslotinfo = wh.registry.list.rcvarpass.allstackitem[selecteditem["gems"]["gem" + gemdspi]];
          if (gemslotinfo != undefined) {

            var tmp = wh.add.text(0, 0, "" + gemslotinfo["name"], {});
            tmp.setStyle(fs["sub"]);
            tmp.setOrigin(0, 0);
            tmp.setPosition(marginleft + 24, margintop + (cline * lineheight));
            this.textlist[cline] = tmp;
            wh.osdcontainer.add(tmp);
          } else {
            var tmp = wh.add.text(0, 0, "Empty Gem Slot", {});
            tmp.setStyle(fs["sub"]);
            tmp.setOrigin(0, 0);
            tmp.setPosition(marginleft + 24, margintop + (cline * lineheight));
            this.textlist[cline] = tmp;
            wh.osdcontainer.add(tmp);
          }

          cline++;
        }
      }
      if (mode == "item") {
        //////////////////equip buttons
        if (passreq == true) {
          //cline++;
          //cline++;
          //cline++;
          const equipbtn = wh.add.sprite(0, 0, "thesb", "equip");//wh.add.image(0, 0, "equipbtn");
          equipbtn.setOrigin(0.5, 0.5);
          equipbtn.setPosition(marginleft + 50, margintop + (cline * lineheight));
          equipbtn.setAlpha(1);
          equipbtn.displayHeight = 40;
          equipbtn.displayWidth = 90;
          wh.osdcontainer.add(equipbtn);
          equipbtn.setInteractive();
          equipbtn.removeAllListeners();
          equipbtn.on("pointerdown", function () {
            wh.plog.log("equipbtn down", wh, mode, data);
            wh.registry.list.inven.equip(selecteditem);
            if (wh.registry.list.rcosd.equipbtn != undefined) {
              wh.registry.list.rcosd.equipbtn.visible = false;
            }
          }, this);
          this.equipbtn = equipbtn;
          this.equipbtn.visible = false;//use button on inven gui
        }
      }
      if (mode == "equipment") {
        //////////////////equip buttons
        cline++;
        cline++;
        cline++;/*
        const unequipbtn = wh.add.sprite(0, 0, "thesb", "unequip");//wh.add.image(0, 0, "unequipbtn");
        unequipbtn.setOrigin(0.5, 0.5);
        unequipbtn.setPosition(marginleft + 50, margintop + (cline * lineheight));
        unequipbtn.setAlpha(1);
        unequipbtn.displayHeight = 40;
        unequipbtn.displayWidth = 90;
        wh.osdcontainer.add(unequipbtn);
        unequipbtn.setInteractive();
        unequipbtn.removeAllListeners();
        unequipbtn.on("pointerdown", function () {
          this.plog.log("UNequipbtn down", wh, mode, data, arguments);
          wh.registry.list.equipment.unequip(selecteditem);
          if (wh.registry.list.rcosd.unequipbtn != undefined) {
            wh.registry.list.rcosd.unequipbtn.visible = false;
          }
        }, this);
        this.unequipbtn = unequipbtn;*/
      }
    }


    //this.plog.log("this", this);
    var con = wh.osdcontainer.scene.registry.list.gameitf.getContainerSize(wh.osdcontainer.scene.game, wh.osdcontainer);
    //this.plog.log("wh.osdcontainer", con);
    var usew = con.w;
    if (usew < 290) usew = 290;
    /*var bggraphics = wh.add.graphics();
    bggraphics.fillStyle(0x000000, 0.5);
    //  32px radius on the corners
    bggraphics.fillRoundedRect(marginleft - 5, margintop - 5, usew + 10, (((cline + 1) * lineheight)) + 5, 5);
    //  Using an object to define a different radius per corner
    bggraphics.setDepth(600000);
    wh.osdcontainer.add(bggraphics);
    wh.osdcontainer.sendToBack(bggraphics);*/
    var bggraphics = wh.add.sprite(0, 0, "thecolor", "black");
    bggraphics.setOrigin(0, 0);
    bggraphics.setAlpha(0.4);
    bggraphics.setPosition(marginleft - 5, margintop - 5);
    bggraphics.displayWidth = usew + 10;
    bggraphics.displayHeight = (((cline + 1) * lineheight)) + 5;
    bggraphics.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

    bggraphics.setDepth(600000);
    wh.osdcontainer.add(bggraphics);
    wh.osdcontainer.sendToBack(bggraphics);
    this.tw = wh.tweens.add(
      {
        targets: wh.osdcontainer,
        alpha: 0,
        ease: 'Power3',
        duration: 500, // duration of animation; higher=slower
        delay: 3000,      // wait 500 ms before starting
        onComplete: function () {
          wh.osdcontainer.removeAll(true);
        }  // set context? how?
      });

    if (typeof wh.mapcami === "function") {
      // ref by Preloader scene
      this.plog.log("mapcami in rcosd;");
      wh.mapcami(wh, wh.osdcontainer);
    }
  }
}
