import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { GameInterfaceService } from './game-interface.service';
import { PGBar } from './pgbar';
import { MISC } from './misc';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCAttriService {
  shopdragbtn;
  title;
  firsttimerun = true;
  loading;
  wh;
  helpicon;
  shopguibg;
  suppicon;
  supptext;
  shopclosebtn;
  itemdescr;
  exppg;
  n_exp;
  n_expp;
  kitxt;
  n_ki;
  n_kip;
  levelupbtn;
  levelupbtndis;
  n_level;

  atttxt = [];
  attcure = [];
  attreq = [];
  attupbtn = [];
  attupbtndis = [];
  attpg = [];
  stattxt = [];
  statval = [];
  plog;
  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP,
    public gameitf: GameInterfaceService,
    public misc: MISC
  ) {
    this.plog = new plog();
  }


  loadingshow() {
    this.wh.attricontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }


  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      //this.wh.registry.list.gameitf.guiwindowclick(this.wh.attricontainer);
    }
    this.firsttimerun = false;
  }
  async show(wh, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;
    this.wh = wh;
    if (wh.attricontainer != undefined) {
      await this.show_close(wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('attri show', wh, this);

    const shopguibg = wh.add.image(0, 0, 'blankguibg');//attriguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    this.plog.log('attri var', this.rcvarsservice);

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );


    this.helpicon = wh.add.sprite(300, 300, "thesq", 'help');
    this.helpicon.setOrigin(0.5, 0.5);
    this.helpicon.setPosition(165, -160);
    this.helpicon.displayWidth = 40;
    this.helpicon.displayHeight = 40;
    this.helpicon.setInteractive();
    this.helpicon.removeAllListeners();
    this.helpicon.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.wh.registry.list.rctoast.rclongalert(this.wh, this.wh.trs("f:attricontainer:Leveling/Attribute===Leveling/Attribute"),
          this.wh.trs("f:attricontainer:wisdomcatdescr===Level up is not automatic, but you can level up whenever you are ready\n\nAnd don't forget to upgrade attributes before level up"))
      },
      this
    );
    /*
        var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
          this.rcvarsservice.activechar["sprite"] + "", {
          prefix: 'walk-d-',
          start: 1,
          end: 2,
          zeroPad: 0
        });
        this.rcvarsservice.gameobj.anims.remove("mainchar-idle");
        var tmpx = this.rcvarsservice.gameobj.anims.create({
          key: 'mainchar-idle',
          frames: tmpframe,
          frameRate: 1,
          repeat: -1
        });
        const charicon = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(100, 100, this.rcvarsservice.activechar["sprite"], tmpframe[0]["frame"]).setPosition(0, 50).setScale(2).play('mainchar-idle');//.setDisplayOrigin(0.5, 0.8);
        charicon.smoothed = false;
        charicon.imageSmoothingEnabled = false;
    
        // for safe compatibility, you can add these as well:
        charicon.mozImageSmoothingEnabled = false;
        charicon.oImageSmoothingEnabled = false;
        charicon.webkitImageSmoothingEnabled = false;
        charicon.msImageSmoothingEnabled = false;
    */

    wh.attricontainer = wh.add.container(300, 300);
    wh.attricontainer._refsrv = this;
    wh.attricontainer.visible = false;
    wh.attricontainer.name = "attricontainer";
    wh.attricontainer.add(this.shopclosebtn);
    wh.attricontainer.add(this.helpicon);
    wh.attricontainer.add(shopguibg);
    //wh.attricontainer.add(charicon);
    wh.attricontainer.bringToTop(this.shopclosebtn);
    wh.attricontainer.bringToTop(this.helpicon);
    var itemdescr = wh.add.text(0, 0, wh.gl(' '), wh.fs["winguiitemdescr"]);
    wh.attricontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 180);
    this.itemdescr = itemdescr;


    this.title = wh.add.text(0, 0, 'ATTRIBUTE', wh.fs["winguititle"]);
    this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.attricontainer.add(this.title);
    wh.attricontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -225);

    var leftcolx = -140;
    var maxrightx = 140;
    var leftcolx2 = -70;
    var rightcolx = 20;
    var toppos = -170;
    var lvltxt = wh.add.text(0, 0, 'Level', wh.fs["attri_blue_lm"]);
    lvltxt.setPosition(leftcolx, toppos).setOrigin(0, 0);
    wh.attricontainer.add(lvltxt);
    var n_level = wh.add.text(0, 0, '--', wh.fs["attrip_blue_lm"]);
    n_level.setPosition(0, toppos).setOrigin(0, 0);
    this.n_level = n_level;
    wh.attricontainer.add(n_level);
    var lvltxt = wh.add.text(0, 0, 'EXP', wh.fs["attri_black_lm"]);
    lvltxt.setPosition(leftcolx, toppos + 22).setOrigin(0, 0);
    wh.attricontainer.add(lvltxt);
    var n_exp = wh.add.text(0, 0, '.......x', wh.fs["attrip_black_lm"]);
    n_exp.setPosition(0 - 25, toppos + 22).setOrigin(0, 0);
    wh.attricontainer.add(n_exp);
    this.n_exp = n_exp;
    var n_expp = wh.add.text(0, 0, '.......%', wh.fs["attrip_black_lm"]);
    n_expp.setPosition(maxrightx, toppos + 22).setOrigin(1, 0);
    wh.attricontainer.add(n_expp);
    this.n_expp = n_expp;

    this.exppg = new PGBar(wh, {
      x: leftcolx,
      y: toppos + 50,
      w: maxrightx - leftcolx,
      h: 20,
      max: 2000,
      me: 1500,
      imgcol: 'orange',
    }, 'img');
    this.plog.log('exppg', this.exppg);
    wh.attricontainer.add(this.exppg.getobj());
    wh.attricontainer.add(this.exppg.getobjfg());
    this.exppg.set(1750);

    const levelupbtn = wh.add.sprite(0, 0, "thesb", "levelup");//wh.add.image(0, 0, 'levelupbtn');
    levelupbtn.setOrigin(0.5, 0.5);
    levelupbtn.setPosition(0, toppos + 95);
    levelupbtn.displayWidth = 100;
    levelupbtn.displayHeight = 35;
    //levelupbtn.visible = false;
    this.levelupbtn = levelupbtn;
    wh.attricontainer.add(levelupbtn);
    const levelupbtndis = wh.add.sprite(0, 0, "thesb", "levelupdis");//wh.add.image(0, 0, 'levelupbtndis');
    levelupbtndis.setOrigin(0.5, 0.5);
    levelupbtndis.setPosition(0, toppos + 95);
    levelupbtndis.displayWidth = 100;
    levelupbtndis.displayHeight = 35;
    //levelupbtndis.visible = false;
    this.levelupbtndis = levelupbtndis;
    wh.attricontainer.add(levelupbtndis);

    this.levelupbtn.setInteractive();
    this.levelupbtn.removeAllListeners();
    this.levelupbtn.on(
      'pointerup',
      function () {
        this.plog.log('levelupbtn', this);
        this.levelup(wh);
      },
      this
    );

    var lvltxt = wh.add.text(0, 0, 'Attributes', wh.fs["attri_blue_lm"]);
    lvltxt.setPosition(leftcolx, toppos + 120).setOrigin(0, 0);
    wh.attricontainer.add(lvltxt);

    var kitxt = wh.add.text(0, 0, 'KI', wh.fs["attri_black_lm"]);
    kitxt.setPosition(leftcolx, toppos + 140).setOrigin(0, 0);
    wh.attricontainer.add(kitxt);
    this.kitxt = kitxt;
    var n_ki = wh.add.text(0, 0, '.......x', wh.fs["attrip_black_lm"]);
    n_ki.setPosition(0, toppos + 140).setOrigin(0, 0);
    wh.attricontainer.add(n_ki);
    this.n_ki = n_ki;


    //attri list;
    var attdb = ['str', 'dex', 'con', 'cha', 'int', 'wis'];
    var atty = toppos + 170;
    var atti = 0;
    var attx = leftcolx;
    var attruni = 0;
    var attlineheight = 35;
    for (let key of Object.keys(attdb)) {
      attruni++;
      let val = attdb[key];
      if (attruni == 4) {
        attx = 10;
        atti = 0;
      }
      /*atttxt=[];
      attcure=[];
      attreq=[];
      attupbtn=[];
      attupbtndis=[];*/
      this.atttxt[val] = wh.add.text(0, 0, val.toUpperCase() + '', wh.fs["attri_black_lm"]);
      this.atttxt[val]
        .setPosition(attx, atty + atti * attlineheight)
        .setOrigin(0, 0);
      wh.attricontainer.add(this.atttxt[val]);

      this.attcure[val] = wh.add.text(0, 0, '123', wh.fs["attrip_black_lm"]);
      this.attcure[val]
        .setPosition(attx + 50, atty + atti * attlineheight)
        .setOrigin(0, 0);
      wh.attricontainer.add(this.attcure[val]);

      this.attreq[val] = wh.add.text(0, 0, '123456', {
        fontFamily: 'ffff',
        fontSize: '8px',
        fill: '#000',
        boundsAlignH: 'right',
        boundsAlignV: 'middle',
      });
      this.attreq[val]
        .setPosition(attx + 120, atty + atti * attlineheight + 2)
        .setOrigin(1, 0);
      wh.attricontainer.add(this.attreq[val]);

      this.attupbtn[val] = wh.add.sprite(300, 300, "thesq", 'plus');
      this.attupbtn[val].setOrigin(0.5, 0.5);
      this.attupbtn[val].setPosition(
        attx + 133,
        atty + atti * attlineheight + 8
      );
      this.attupbtn[val].displayWidth = 24;
      this.attupbtn[val].displayHeight = 24;
      this.attupbtn[val].name = val;
      this.attupbtn[val].setInteractive();
      this.attupbtn[val].removeAllListeners();
      this.attupbtn[val].on('pointerup', function () {
        this.scene.plog.log(this);
        this.scene.registry.list.attri.attup(this.name);
      });
      //this.attupbtn[val].visible = false;
      wh.attricontainer.add(this.attupbtn[val]);

      this.attupbtndis[val] = wh.add.sprite(300, 300, "thesq", 'plusdis');
      this.attupbtndis[val].setOrigin(0.5, 0.5);
      this.attupbtndis[val].setPosition(
        attx + 133,
        atty + atti * attlineheight + 8
      );
      this.attupbtndis[val].displayWidth = 24;
      this.attupbtndis[val].displayHeight = 24;
      //this.attupbtndis[val].visible = false;
      wh.attricontainer.add(this.attupbtndis[val]);

      this.attpg[val] = new PGBar(wh, {
        x: attx,
        y: atty + atti * attlineheight + 18,
        w: 120,
        h: 8,
        max: 2000,
        me: 1500,
        imgcol: 'orange',
      }, 'img');
      this.attpg[val]
        .getobj()
        .setPosition(attx, atty + atti * attlineheight + 18);
      wh.attricontainer.add(this.attpg[val].getobj());
      wh.attricontainer.add(this.attpg[val].getobjfg());
      this.attpg[val].set(1750);
      atti++;
    }

    var attdb = ['maxhp', 'patk', 'matk', 'spd', 'def', 'crit', 'dodge'];
    var atty = toppos + 280;
    var atti = 0;
    var attx = leftcolx;
    var attruni = 0;
    var attlineheight = 17;
    for (let key of Object.keys(attdb)) {
      attruni++;
      let val = attdb[key];
      if (attruni == 5) {
        attx = 10;
        atti = 0;
      }
      /*atttxt=[];
      attcure=[];
      attreq=[];
      attupbtn=[];
      attupbtndis=[];*/
      this.stattxt[val] = wh.add.text(0, 0, val.toUpperCase() + '', wh.fs["attri_black_sm"]);
      this.stattxt[val]
        .setPosition(attx, atty + atti * attlineheight)
        .setOrigin(0, 0);

      wh.attricontainer.add(this.stattxt[val]);
      this.plog.log(
        'this.rcvarsservice.activechar',
        this.rcvarsservice.activechar
      );

      this.statval[val] = wh.add.text(
        0,
        0,
        //this.rcvarsservice.activechar['cbt'][val] + '',
        0,
        wh.fs["attrip_black_lmdsp"]
      );
      this.statval[val]
        .setPosition(attx + 50, atty + atti * attlineheight)
        .setOrigin(0, 0);
      wh.attricontainer.add(this.statval[val]);
      this.statval[val].updateTween = this.wh.tweens.addCounter({
        from: 0,
        to: this.rcvarsservice.activechar['cbt'][val],
        duration: 500,
        ease: 'linear',
        onUpdate: function () {
          const value = Math.round(arguments[5]);
          //console.log(this,arguments)
          this.statval[arguments[0]].setText(value);

        }.bind(this, val)
      });
      atti++;
    }


    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.attricontainer.add(this.loading);
    wh.attricontainer.bringToTop(this.loading);


    //drag
    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].attricontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('attri pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].attricontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].attricontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].attricontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].attricontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].attricontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].attricontainer.y = pointer.y;
        }
      },
      this
    );
    wh.attricontainer.add(this.shopdragbtn);
    wh.attricontainer.bringToTop(this.shopdragbtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.attricontainer.setSize(shopguibg.width, shopguibg.height);
    wh.attricontainer.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/
    var equipment = this.rcvarsservice.activechar['equip'];
    this.plog.log('equipment eq', equipment);
    this.plog.log('attricontainer', wh.attricontainer, width, height);
    wh.attricontainer.setPosition(width / 2, height / 2);
    let loader = new Phaser.Loader.LoaderPlugin(wh);
    var suppicon = wh.add.sprite(0, 0, 'stackitem_tp', 'supp');
    var supptext = wh.add.text(0, 0, '--', wh.fs["attrip_black_sm_r"]);
    this.suppicon = suppicon;
    this.supptext = supptext;
    wh.attricontainer.add(this.suppicon);
    wh.attricontainer.bringToTop(this.suppicon);
    wh.attricontainer.add(this.supptext);
    wh.attricontainer.bringToTop(this.supptext);
    this.suppicon.setOrigin(0.5, 0.5);
    this.suppicon.setPosition(136, toppos + 95);
    this.suppicon.displayWidth = 35;
    this.suppicon.displayHeight = 35;
    this.suppicon.visible = false;
    this.supptext.visible = false;
    this.supptext.setPosition(55, toppos + 80);
    //this.supptext.displayWidth = 60;

    //move
    wh.attricontainer.x = width - this.shopguibg.width / 2 + 20;

    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.show_loaded(wh, true);
      },
      this
    );
    this.wh.registry.list.gameitf.guiwindowclick(this.wh.attricontainer);

    loader.start();
  }
  async show_loaded(wh, fromshowfunc = false) {
    this.plog.log('attri show_loaded');

    var griddat = [];
    var griddatf = [];
    if (this.n_level == undefined || this.n_level.parentContainer == undefined) return; //called from charinfoupdate, but didnt loaded
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].attricontainer);
    this.firsttimerunfunc();
    var dat = this.rcvarsservice.activechar;
    var nextlvexp = this.rcvarsservice.level[dat['level']]['xp'];
    if (dat['xp'] > 10000) {
      this.n_exp.setText(
        wh.registry.list.gameitf.humannumber(dat['xp']) +
        '/' +
        //wh.registry.list.gameitf.humannumber(nextlvexp)
        nextlvexp
      );
    } else {
      this.n_exp.setText(dat['xp'] + '/' + nextlvexp);
    }
    try {
      this.n_level.setText(dat['level']);
    } catch (e) {
      console.log(e);
    }
    var nextlvsupp = Number(this.rcvarsservice.level[dat['level']]['supp']);
    var mysupp = 0;
    if (dat['stackitem'] != undefined) {
      for (const suppk in dat['stackitem']) {
        //this.plog.log("suppk",suppk,dat["stackitem"][suppk]);
        if (dat['stackitem'][suppk]['item'] == 'supp') {
          mysupp += dat['stackitem'][suppk]['amnt'];
        }
      }
    }
    this.itemdescr.setText(
      wh.trs("f:attricontainer:Item Plus Bonus===Item Plus Bonus") + ': ' +
      wh.registry.list.rcvarpass.activechar['allplus'] +
      ' (+' +
      wh.registry.list.rcvarpass.activechar['cbt']['allpluspercent'] +
      '%)'
    );
    this.plog.log('nextlvsupp', nextlvsupp, 'mysupp', mysupp);
    if (nextlvsupp > 0) {
      this.suppicon.visible = true;
      this.supptext.visible = true;
      this.supptext.setText(wh.trs("f:attricontainer:Need Cat Fur===Need Cat Fur") + '\n' + mysupp + '/' + nextlvsupp);
    } else {
      this.suppicon.visible = false;
      this.supptext.visible = false;
    }
    var xppercent = this.misc.percent(nextlvexp, dat['xp']);
    var xppercentv = this.misc.numberformat(xppercent);
    if (xppercent > 10000) {
      this.n_expp.setText(wh.registry.list.gameitf.humannumber(xppercentv) + '%');
    } else {
      this.n_expp.setText(xppercentv + '%');
    }
    this.exppg.set(dat['xp'], nextlvexp);
    if (dat['xp'] >= nextlvexp && mysupp >= nextlvsupp) {
      this.levelupbtn.visible = true;
      this.levelupbtndis.visible = false;
    } else {
      this.levelupbtn.visible = false;
      this.levelupbtndis.visible = true;
    }
    if (dat['level'] >= 450) {
      this.levelupbtn.visible = false;
      this.levelupbtndis.visible = true;
    }
    //ki
    var tmpnum = (dat['ki']).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
    this.n_ki.setText(tmpnum.replace(".0", ""));

    var attdb = ['str', 'dex', 'con', 'cha', 'int', 'wis'];

    for (let key of Object.keys(attdb)) {
      let val = attdb[key];
      var tmpnumcc = dat['s_' + val]; // 12,345.67
      if ((nextlvexpatt + "").length > 6) {
        tmpnumcc = wh.registry.list.gameitf.humannumber(tmpnumcc);
      } else {
        tmpnumcc = (Math.floor(tmpnumcc)).toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
      this.attcure[val].setText(tmpnumcc);
      var nextlvexpatt = 0;
      if (this.rcvarsservice.level[dat['s_' + val]] == undefined) { //maxlv
      } else {
        nextlvexpatt = this.rcvarsservice.level[dat['s_' + val]]['xp'];
      }

      var tmpnum2 = ""; // 12,345.67
      if ((nextlvexpatt + "").length > 5) {
        tmpnum2 = wh.registry.list.gameitf.humannumber(nextlvexpatt);
      } else {
        tmpnum2 = (Math.floor(nextlvexpatt)).toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
      tmpnum2 = tmpnum2.replace(".00", "");
      this.attreq[val].setText(tmpnum2.replace(".00", ""));

      this.attpg[val].set(dat['ki'], nextlvexpatt);

      if (dat['ki'] >= nextlvexpatt && nextlvexpatt != 0) {
        this.attupbtn[val].visible = true;
        this.attupbtndis[val].visible = false;
      } else {
        this.attupbtn[val].visible = false;
        this.attupbtndis[val].visible = true;
      }
      griddat.push(this.attupbtn[val]);
      griddatf.push(this.attupbtn[val]);

      if (dat['reborncount'] == 0) {
        //if (dat['s_' + val] >= 400) {
        if (dat['s_' + val] >= 800) {
          this.attupbtn[val].visible = false;
          this.attupbtndis[val].visible = true;
        }
      } else if (dat['reborncount'] == 1) {
        if (dat['s_' + val] >= 1000) {
          this.attupbtn[val].visible = false;
          this.attupbtndis[val].visible = true;
        }
      } else if (dat['reborncount'] > 1) {
        if (dat['s_' + val] >= 1400) {
          this.attupbtn[val].visible = false;
          this.attupbtndis[val].visible = true;
        }
      } else {
        if (dat['s_' + val] >= 1500) {
          this.attupbtn[val].visible = false;
          this.attupbtndis[val].visible = true;
        }
      }
    }
    griddat.push(this.levelupbtn);
    griddat.push(this.shopclosebtn);

    var attdb = ['maxhp', 'patk', 'matk', 'spd', 'def', 'crit', 'dodge'];

    for (let key of Object.keys(attdb)) {
      let val = attdb[key];
      var tmpnum = (dat['cbt'][val]).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
      tmpnum = Math.round(dat['cbt'][val])
      if (this.statval[val].updateTween.isPlaying()) {
        //  The tween is already running, so we'll update the end value with resetting it
        this.statval[val].updateTween.updateTo('value', Math.round(this.statval[val].text));
      }
      else {
        //  The tween has finished, so create a new one
        this.statval[val].updateTween = this.wh.tweens.addCounter({
          from: Math.round(this.statval[val].text),
          to: tmpnum,
          duration: 400,
          ease: 'linear',
          onUpdate: function () {
            const value = Math.round(arguments[5]);
            try {
              this.statval[val].setText(value);
            } catch (e) {

            }
          }.bind(this, val)
        });
      }
      this.statval[val].setText(tmpnum);
    }

    this.wh.attricontainer.visible = true;
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);

    this.loadinghide();
    if (fromshowfunc == true) {
      this.wh.registry.list.gp.guigrid(griddat, griddatf);
    }

    this.wh.registry.list.tut.show(this.wh, "rcattriloaded");
    this.wh.registry.list.tut.istutrunning = true;
  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.show_loaded(this.wh);
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
  async show_close(wh) {
    //wh.attricontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.attricontainer);
    this.firsttimerun = true;

    for (const k in this.wh.attricontainer.list) {
      try {
        await this.wh.attricontainer.remove(this.wh.attricontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! attricontainer.destroy() ", e);
      }
    }
    try {
      await this.wh.attricontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! attricontainer.destroy() ", e);
    }
    try {
      await this.wh.attricontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! attricontainer.destroy() ", e);
    }
    this.wh.attricontainer = undefined;
  }
  async levelup(wh) {
    this.loadingshow();

    //var res = await this.rchttp.levelup();
    var res = await this.rchttp.getcommon2("levelup");
    await this.rcvarsservice.setactivechar(
      res,
      this.rcvarsservice.activechar['id']
    );
    //this.plog.log("rcinventory equip", this);
    this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.rcvarsservice.gameobj.registry.list.snd.snd("atkarcane1");
    this.loadinghide();
    this.rcvarsservice.gameobj.registry.list.showreward.effwithtxt(this.rcvarsservice.gameobj.scene.scenes[0], "ixbeff317_b", "Level Up!");
    this.rcvarsservice.gameobj.registry.list.wsrc.sendeffect("levelup", "ixbeff317_b");
  }

  async attup(wh) {
    this.loadingshow();

    this.plog.log('attup', wh);
    var res = await this.rchttp.attup(wh);
    await this.rcvarsservice.setactivechar(
      res,
      this.rcvarsservice.activechar['id']
    );
    //this.plog.log("rcinventory equip", this);
    this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.rcvarsservice.gameobj.registry.list.snd.snd("atkarcane1");

    this.loadinghide();
    this.rcvarsservice.gameobj.registry.list.showreward.effwithtxt(this.rcvarsservice.gameobj.scene.scenes[0], "ixbeff317_b", "Attribute Up!")
    this.rcvarsservice.gameobj.registry.list.wsrc.sendeffect("attup", "ixbeff317_b");
  }
}
