import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RCVarsService } from './rcvars-service.service';
import { RCToastService } from './rctoast.service';
import { RCStorage } from './rcstorage.service';
import { RCLoadingService } from './rcloading.service';
import { WSRCService } from './wsrc.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCHTTP {
  constructor(
    private httpClient: HttpClient,
    private rcvars: RCVarsService,
    private rctoast: RCToastService,
    private rcstorage: RCStorage,
    private rcloading: RCLoadingService,
    public wsrc: WSRCService
  ) {
    this.plog = new plog();
  }
  plog;
  async combatwin(log, sparid, totaldmg = 0, combattoken = '', logtext = '') {
    this.plog.log('RCHTTP combatwin');
    var battlelogu = JSON.stringify(log);
    var logtextu = JSON.stringify(logtext);
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('combatwin', this);
    if (combattoken == '') this.plog.log('COMBATWIN ERROR combattoken empty');
    var mappos = '';
    if (this.rcvars.gameobj.scene.scenes[0] != undefined) {
      mappos =
        this.rcvars.gameobj.scene.scenes[0].char_main.currentx +
        '-' +
        this.rcvars.gameobj.scene.scenes[0].char_main.currenty;
    }
    url = this.rcvars.rcserver_url + '?get=combatwin';
    postdata = {
      sparid: sparid,
      totaldmg: totaldmg,
      combattoken: combattoken,
      battlelog: battlelogu,
      login: url1,
      charid: charid,
      mappos: mappos,
      sid: url2,
      logtext: logtextu,
    };
    this.plog.log('combatwin url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data); 
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Something Went Wrong\n',
                data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            if (data['voicethis'] != undefined) {
              this.plog.log("getcommon2 got voicethis", data, data['voicethis']);
              if (this.rcvars.gameobj.scene.scenes[0].char_main != undefined) {
                this.rcvars.gameobj.scene.scenes[0].registry.list.phm.voicethis(this.rcvars.gameobj.scene.scenes[0], data['voicethis']);
              }
            }

            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            this.wsrc.ping();
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async combatlose(log, sparid, totaldmg = 0, combattoken = '', logtext = '') {
    var battlelogu = JSON.stringify(log);
    var logtextu = JSON.stringify(logtext);
    this.plog.log('battlelog', battlelogu);
    this.plog.log('RCHTTP combatlose');
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    if (combattoken == '') this.plog.log('COMBATWIN ERROR combattoken empty');

    this.plog.log('combatlose', this);
    var mappos = '';
    if (this.rcvars.gameobj.scene.scenes[0] != undefined) {
      mappos =
        this.rcvars.gameobj.scene.scenes[0].char_main.currentx +
        '-' +
        this.rcvars.gameobj.scene.scenes[0].char_main.currenty;
    }
    url = this.rcvars.rcserver_url + '?get=combatlose';
    postdata = {
      sparid: sparid,
      combattoken: combattoken,
      totaldmg: totaldmg,
      battlelog: battlelogu,
      login: url1,
      charid: charid,
      mappos: mappos,
      sid: url2,
      logtext: logtextu,
    };
    this.plog.log('combatlose url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            this.wsrc.ping();
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }

            if (data['voicethis'] != undefined) {
              this.plog.log("getcommon2 got voicethis", data, data['voicethis']);
              if (this.rcvars.gameobj.scene.scenes[0].char_main != undefined) {
                this.rcvars.gameobj.scene.scenes[0].registry.list.phm.voicethis(this.rcvars.gameobj.scene.scenes[0], data['voicethis']);
              }
            }

            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async updatecharinfo() {
    this.wsrc.ping();
    var fulldata = 0;
    if (this.rcvars.activechar != undefined) {
      fulldata = Number(this.rcvars.activechar['id']);;
    }
    var res = await this.getcommon2('getcharacter', { fulldata: fulldata });
    //await this.rcvars.gameobj.registry.list.gameitf.charinfoupdated(this.rcvars.gameobj.scene.scenes[0]);
    await this.rcvars.setactivechar(res, fulldata);
    //this.plog.log("rcinventory equip", this);
    await this.rcvars.gameobj.registry.list.gameitf.charinfoupdated(this.rcvars.gameobj.scene.scenes[0]);
  }
  async getcommon2(getwhat, addvars = {}, forceurl = '') {
    //this.plog.log('RCHTTP getcommon2', getwhat);
    var postdata;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var platform = await this.rcstorage.get('remember_platform', true);
    var charid = 0;
    if (this.rcvars.activechar != undefined) {
      charid = this.rcvars.activechar.id;
    }
    var mappos = '';
    if (
      this.rcvars.gameobj != undefined &&
      this.rcvars.gameobj.scene != undefined &&
      this.rcvars.gameobj.scene.scenes[0] != undefined &&
      this.rcvars.gameobj.scene.scenes[0].char_main != undefined
    ) {
      mappos =
        this.rcvars.gameobj.scene.scenes[0].char_main.currentx +
        '-' +
        this.rcvars.gameobj.scene.scenes[0].char_main.currenty;
    }
    var url;
    if (forceurl == '') {
      if (
        this.rcvars.rcserver_url == undefined ||
        this.rcvars.rcserver_url == ''
      ) {
        this.plog.log('ERROR: (getcommon2) rcserver_url undefined');
      }
      //this.plog.log('getcommon2', this);
      url = this.rcvars.rcserver_url + '?get=' + getwhat;
    } else {
      url = forceurl + '?get=' + getwhat;
    }
    postdata = {
      lang: this.rcvars.lang,
      login: url1,
      platform: platform,
      mappos: mappos,
      charid: charid,
      sid: url2,
    };
    for (var k in addvars) {
      var v = addvars[k];
      postdata[k] = v;
    }

    //this.plog.log('getcommon2 url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          async (data: any) => {
            //this.plog.log('post done', data);
            //this.plog.log("getcommon2 log",data,data["showreward"],data["showreward_text"]);

            if (data["showreward"] != undefined && data["showreward_text"] != undefined) {
              //this.plog.log("getcommon2 found showreward", data);
              if (this.rcvars.gameobj.registry.list.showreward != undefined) {
                this.rcvars.gameobj.registry.list.showreward.show(this.rcvars.gameobj.scene.scenes[0], data["showreward"], { text: data["showreward_text"] });
              }
            }
            this.wsrc.ping();
            if (data['alert'] != '' && data['alert'] != undefined) {
              this.rctoast.rcalert(
                'Error',
                'Something Went Wrong',
                data['alert']
              );
              this.rcloading.close();
            } else if (data['alertok'] != '' && data['alertok'] != undefined) {
              this.rctoast.alert('Ok', 'Result:', data['alertok']);
              this.rcloading.close();
            } else if (data['error'] != '' && data['error'] != undefined) {
              console.log("getcommon2 error", data);
              
              if (data["error"] == "Login ID not found") {
                //await this.rcvars.gameobj.scene.scenes[0].registry.list.rcstorage.set('loggedin', 'no');
                //await this.rcvars.gameobj.scene.scenes[0].registry.list.rcstorage.set('rc_sid', '');
                await this.rctoast.rcalert(
                  this.rcvars.gameobj.scene.scenes[0],
                  'Session Error',
                  'Something Went Wrong\nYour session has expired, or you may be trying to play on multiple browsers at the same time.'
                );
                location.reload();
              } else {
                this.rctoast.rcalert(
                  this.rcvars.gameobj.scene.scenes[0],
                  'Error',
                  'Something Went Wrong\n' + data['error']
                );
              }
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              this.rcloading.close();
              //return; 
            } else if (data['rcalert'] != '' && data['rcalert'] != undefined) {
              this.rctoast.rcalert(this.rcvars.gameobj.scene.scenes[0],
                'Information',
                data['rcalert']);
              this.rcloading.close();
            } else if (data['rcalertscene1'] != '' && data['rcalertscene1'] != undefined) {
              this.rctoast.rcalert(this.rcvars.gameobj.scene.scenes[1],
                'Information',
                data['rcalertscene1']);
              this.rcloading.close();
            }
            if (data['voicethis'] != undefined) {
              //console.log("getcommon2 got voicethis", data, this.rcvars.gameobj.scene.scenes[0].char_main);
              if (this.rcvars.gameobj.scene.scenes[0].char_main != undefined) {
                this.rcvars.gameobj.scene.scenes[0].registry.list.phm.voicethis(this.rcvars.gameobj.scene.scenes[0], data['voicethis']);
              }
            }
            if (data['osdflash'] != undefined) {
              this.rcvars.gameobj.registry.list.gameitf.osdflash(this.rcvars.gameobj.scene.scenes[0], data['osdflash']);
            }
            if (data['charsay'] != undefined) {
              //if (this.rcvars)
              //console.log("getcommon2 got charsay", data, this.rcvars.gameobj.scene.scenes[0].char_main);
              if (
                this.rcvars.gameobj.scene.scenes[0].char_main != undefined
              ) {
                this.rcvars.gameobj.registry.list.phm.charsaybubble(
                  this.rcvars.gameobj.scene.scenes[0].char_main,
                  data['charsay']
                );
              } else {
                this.rctoast.rcalert(
                  this.rcvars.gameobj.scene.scenes[0],
                  'Error',
                  'Something Went Wrong\n' + data['charsay']
                );
              }
              //console.log('charsay', data, this.rcvars);
              this.rcloading.close();
            }

            if (data["lastnpcsay"] != undefined) {
              //console.log("lastnpcsay", this.rcvars.gameobj.scene.scenes[0].lastnpcuidclicked, data["lastnpcsay"]);
              var npcsprite = this.rcvars.gameobj.scene.scenes[0].registry.list.phm.findnpc(this.rcvars.gameobj.scene.scenes[0], this.rcvars.gameobj.scene.scenes[0].lastnpcuidclicked);
              if (npcsprite != undefined) {
                if (!Array.isArray(data["lastnpcsay"])) {
                  this.rcvars.gameobj.scene.scenes[0].registry.list.phm.charsaybubble(npcsprite, data["lastnpcsay"]);
                } else {
                  var timename =
                    this.rcvars.gameobj.scene.scenes[0].registry.list.timectl
                      .timename;
                  var usablemsg = [];
                  for (const mk in data["lastnpcsay"]) {
                    var mv = data["lastnpcsay"][mk];
                    mv = mv.trim();
                    var mva = mv.split(':');
                    this.plog.log("lastnpcsay array i", mva);
                    if (mva[0] == timename || mva[0] == '*') {
                      if (mva[2] != undefined && mva[2] != '') usablemsg.push(mva[2]);
                    }
                  }
                  //console.log("lastnpcsay array", timename, data["lastnpcsay"], usablemsg);

                  if (usablemsg.length != 0) {
                    var item = usablemsg[Math.floor(Math.random() * usablemsg.length)];
                    if (item != undefined && item != '') {
                      npcsprite.maptalktimeout = Number(10);
                      item = item.replace('\r', '');
                      //this.charsaybubble(v, wh.gl(item[2]));
                      this.rcvars.gameobj.scene.scenes[0].registry.list.phm.charsaybubble(npcsprite, item);

                    }
                  }
                }
              }

            }

            if (data["showeffect"] != undefined && data["showeffect_text"] != undefined) {
              //console.log("getcommon2 showefffect", data, this);
              this.rcvars.gameobj.registry.list.showreward.effwithtxt(this.rcvars.gameobj.scene.scenes[0], data["showeffect"], data["showeffect_text"]);
            }
            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error, this);
            if (
              this.rcvars != undefined &&
              this.rcvars.gameobj != undefined &&
              this.rcvars.gameobj.registry != undefined &&
              this.rcvars.gameobj.registry.list != undefined &&
              this.rcvars.gameobj.registry.list.phm != undefined
            ) {
              if (
                this.rcvars != undefined &&
                this.rcvars.gameobj != undefined &&
                this.rcvars.gameobj.scene != undefined &&
                this.rcvars.gameobj.scene.scenes != undefined &&
                this.rcvars.gameobj.scene.scenes[0] != undefined &&
                this.rcvars.gameobj.scene.scenes[0].char_main != undefined
              ) {
                this.rcvars.gameobj.registry.list.phm.charsaybubble(this.rcvars.gameobj.scene.scenes[0].char_main, "I'm offline, please check connection.");
              }
            }
            resolve(error);

          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async savespawnpos(objtype, objid, objpos) {
    this.plog.log('RCHTTP savespawnpos', objtype, objid, objpos);
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('savespawnpos', this);
    url = this.rcvars.rcserver_url + '?get=savespawnpos';
    postdata = {
      objtype: objtype,
      objid: objid,
      objpos: objpos,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('savespawnpos url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async attup(wh) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('attup', this);
    url = this.rcvars.rcserver_url + '?get=attup';
    postdata = {
      att: wh,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('attup url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async levelup() {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('levelup', this);
    url = this.rcvars.rcserver_url + '?get=levelup';
    postdata = {
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('levelup url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async unequip(itemid) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('unequip', this);
    url = this.rcvars.rcserver_url + '?get=unequip';
    postdata = {
      itemid: itemid,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('unequip url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async equip(itemid,useequipx="no") {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('equip', this);
    url = this.rcvars.rcserver_url + '?get=equip';
    postdata = {
      itemid: itemid,
      useequipx: useequipx,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('equip url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async buyitem(shopid, itemid) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('buyitem', this);
    url = this.rcvars.rcserver_url + '?get=buyitem';
    postdata = {
      lang: this.rcvars.lang,
      shopid: shopid,
      itemid: itemid,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('buyitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }

            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async buystackitem(shopid, itemid, amnt) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    this.plog.log('buystackitem', this);
    var charid = this.rcvars.activechar['id'];
    url = this.rcvars.rcserver_url + '?get=buystackitem';
    postdata = {
      lang: this.rcvars.lang,
      shopid: shopid,
      itemid: itemid,
      login: url1,
      charid: charid,
      amnt: amnt,
      sid: url2,
    };
    this.plog.log('buystackitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }

            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async dropstackitem(dropid: number, amnt: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('dropstackitem', this);
    url = this.rcvars.rcserver_url + '?get=dropstackitem';
    var mappos = '';
    if (this.rcvars.gameobj.scene.scenes[0] != undefined) {
      mappos =
        this.rcvars.gameobj.scene.scenes[0].char_main.currentx +
        '-' +
        this.rcvars.gameobj.scene.scenes[0].char_main.currenty;
    }
    postdata = {
      lang: this.rcvars.lang,
      dropid: dropid,
      login: url1,
      mappos: mappos,
      charid: charid,
      amnt: amnt,
      sid: url2,
    };
    this.plog.log('dropstackitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            this.wsrc.ping();
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            if (data['voicethis'] != undefined) {
              this.plog.log("getcommon2 got voicethis", data, this.rcvars.gameobj.scene.scenes[0].char_main);
              if (this.rcvars.gameobj.scene.scenes[0].char_main != undefined) {
                this.rcvars.gameobj.scene.scenes[0].voicethis(data['voicethis']);
              }
            }
            if (data['charsay'] != undefined) {
              if (
                this.rcvars.gameobj.scene.scenes[0].char_main != undefined
              ) {
                this.rcvars.gameobj.registry.list.phm.charsaybubble(
                  this.rcvars.gameobj.scene.scenes[0].char_main,
                  data['charsay']
                );
              }
              this.rcloading.close();
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async depositstackitem(dropid: number, amnt: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('dropstackitem', this);
    url = this.rcvars.rcserver_url + '?get=depositstackitem';
    var mappos = '';
    if (this.rcvars.gameobj.scene.scenes[0] != undefined) {
      mappos =
        this.rcvars.gameobj.scene.scenes[0].char_main.currentx +
        '-' +
        this.rcvars.gameobj.scene.scenes[0].char_main.currenty;
    }
    postdata = {
      lang: this.rcvars.lang,
      dropid: dropid,
      mappos: mappos,
      login: url1,
      charid: charid,
      amnt: amnt,
      sid: url2,
    };
    this.plog.log('dropstackitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async retrievestackitem(dropid: number, amnt: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('retrievestackitem', this);
    url = this.rcvars.rcserver_url + '?get=retrievestackitem';
    postdata = {
      lang: this.rcvars.lang,
      dropid: dropid,
      login: url1,
      charid: charid,
      amnt: amnt,
      sid: url2,
    };
    this.plog.log('retrievestackitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async sellstackitem(sellid: number, amnt: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('sellstackitem', this);
    url = this.rcvars.rcserver_url + '?get=sellstackitem';
    postdata = {
      lang: this.rcvars.lang,
      sellid: sellid,
      login: url1,
      charid: charid,
      amnt: amnt,
      sid: url2,
    };
    this.plog.log('sellstackitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              resolve(data.data);
              return;
            }

            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async donatestackitem(sellid: number, amnt: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('donatestackitem', this);
    url = this.rcvars.rcserver_url + '?get=donatestackitem';
    postdata = {
      lang: this.rcvars.lang,
      sellid: sellid,
      login: url1,
      charid: charid,
      amnt: amnt,
      sid: url2,
    };
    this.plog.log('donatestackitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              resolve(data.data);
              return;
            }

            if (data['osdflash'] != undefined) {
              this.rcvars.gameobj.registry.list.gameitf.osdflash(this.rcvars.gameobj.scene.scenes[0], data['osdflash']);
            }
            if (data["showreward"] != undefined && data["showreward_text"] != undefined) {
              //this.plog.log("getcommon2 found showreward", data);
              if (this.rcvars.gameobj.registry.list.showreward != undefined) {
                this.rcvars.gameobj.registry.list.showreward.show(this.rcvars.gameobj.scene.scenes[0], data["showreward"], { text: data["showreward_text"] });
              }
            }
            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }

            if (data["showeffect"] != undefined && data["showeffect_text"] != undefined) {
              try {
                this.plog.log("getcommon2 showefffect", data, this);
                this.rcvars.gameobj.registry.list.showreward.effwithtxt(this.rcvars.gameobj.scene.scenes[0], data["showeffect"], data["showeffect_text"]);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async sellstackitemallcate(cate) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('sellstackitem', this);
    url = this.rcvars.rcserver_url + '?get=sellstackitemallcate';
    postdata = {
      lang: this.rcvars.lang,
      cate: cate,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('sellstackitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              resolve(data.data);
              return;
            }
            if (data["showreward"] != undefined && data["showreward_text"] != undefined) {
              //this.plog.log("getcommon2 found showreward", data);
              if (this.rcvars.gameobj.registry.list.showreward != undefined) {
                this.rcvars.gameobj.registry.list.showreward.show(this.rcvars.gameobj.scene.scenes[0], data["showreward"], { text: data["showreward_text"] });
              }
            }
            if (data['osdflash'] != undefined) {
              this.rcvars.gameobj.registry.list.gameitf.osdflash(this.rcvars.gameobj.scene.scenes[0], data['osdflash']);
            }
            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async sellitem(sellid: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('sellitem', this);
    url = this.rcvars.rcserver_url + '?get=sellitem';
    postdata = {
      lang: this.rcvars.lang,
      sellid: sellid,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('sellitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }

            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async donateitem(sellid: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('donateitem', this);
    url = this.rcvars.rcserver_url + '?get=donateitem';
    postdata = {
      lang: this.rcvars.lang,
      sellid: sellid,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('donateitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }

            if (data['osdflash'] != undefined) {
              this.rcvars.gameobj.registry.list.gameitf.osdflash(this.rcvars.gameobj.scene.scenes[0], data['osdflash']);
            }
            if (data["showreward"] != undefined && data["showreward_text"] != undefined) {
              //this.plog.log("getcommon2 found showreward", data);
              if (this.rcvars.gameobj.registry.list.showreward != undefined) {
                this.rcvars.gameobj.registry.list.showreward.show(this.rcvars.gameobj.scene.scenes[0], data["showreward"], { text: data["showreward_text"] });
              }
            }
            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            if (data["showeffect"] != undefined && data["showeffect_text"] != undefined) {
              try {
                this.plog.log("getcommon2 showefffect", data, this);
                this.rcvars.gameobj.registry.list.showreward.effwithtxt(this.rcvars.gameobj.scene.scenes[0], data["showeffect"], data["showeffect_text"]);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async donateitemallcate(cate) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('donateitem', this);
    url = this.rcvars.rcserver_url + '?get=donateitemallcate';
    postdata = {
      lang: this.rcvars.lang,
      cate: cate,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('donateitemallcate url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }

            if (data['osdflash'] != undefined) {
              this.rcvars.gameobj.registry.list.gameitf.osdflash(this.rcvars.gameobj.scene.scenes[0], data['osdflash']);
            }
            if (data["showreward"] != undefined && data["showreward_text"] != undefined) {
              //this.plog.log("getcommon2 found showreward", data);
              if (this.rcvars.gameobj.registry.list.showreward != undefined) {
                this.rcvars.gameobj.registry.list.showreward.show(this.rcvars.gameobj.scene.scenes[0], data["showreward"], { text: data["showreward_text"] });
              }
            }
            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            if (data["showeffect"] != undefined && data["showeffect_text"] != undefined) {
              try {
                this.plog.log("getcommon2 showefffect", data, this);
                this.rcvars.gameobj.registry.list.showreward.effwithtxt(this.rcvars.gameobj.scene.scenes[0], data["showeffect"], data["showeffect_text"]);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async donatestackitemallcate(cate) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('donatestackitemallcate', this);
    url = this.rcvars.rcserver_url + '?get=donatestackitemallcate';
    postdata = {
      lang: this.rcvars.lang,
      cate: cate,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('donatestackitemallcate url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }

            if (data['osdflash'] != undefined) {
              this.rcvars.gameobj.registry.list.gameitf.osdflash(this.rcvars.gameobj.scene.scenes[0], data['osdflash']);
            }
            if (data["showreward"] != undefined && data["showreward_text"] != undefined) {
              //this.plog.log("getcommon2 found showreward", data);
              if (this.rcvars.gameobj.registry.list.showreward != undefined) {
                this.rcvars.gameobj.registry.list.showreward.show(this.rcvars.gameobj.scene.scenes[0], data["showreward"], { text: data["showreward_text"] });
              }
            }
            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            if (data["showeffect"] != undefined && data["showeffect_text"] != undefined) {
              try {
                this.plog.log("getcommon2 showefffect", data, this);
                this.rcvars.gameobj.registry.list.showreward.effwithtxt(this.rcvars.gameobj.scene.scenes[0], data["showeffect"], data["showeffect_text"]);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async sellitemallcate(cate) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('sellitemallcate', this);
    url = this.rcvars.rcserver_url + '?get=sellitemallcate';
    postdata = {
      lang: this.rcvars.lang,
      cate: cate,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('sellitemallcate url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }

            if (data["showreward"] != undefined && data["showreward_text"] != undefined) {
              //this.plog.log("getcommon2 found showreward", data);
              if (this.rcvars.gameobj.registry.list.showreward != undefined) {
                this.rcvars.gameobj.registry.list.showreward.show(this.rcvars.gameobj.scene.scenes[0], data["showreward"], { text: data["showreward_text"] });
              }
            }
            if (data['osdflash'] != undefined) {
              this.rcvars.gameobj.registry.list.gameitf.osdflash(this.rcvars.gameobj.scene.scenes[0], data['osdflash']);
            }
            if (data['ARCHI'] != undefined) {
              try {
                this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
              } catch (e) {
                console.log(e);
              }
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async Xconsumestackitem(useid: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('consumestackitem', this);
    url = this.rcvars.rcserver_url + '?get=consumestackitem';
    postdata = {
      useid: useid,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('consumestackitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('consumestackitem post done', data);
            if (data['error'] !== '' && data['error'] != '' && data['error'] != undefined) {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            if (data['voicethis'] != undefined) {
              this.plog.log("getcommon2 got voicethis", data, this.rcvars.gameobj.scene.scenes[0].char_main);
              if (this.rcvars.gameobj.scene.scenes[0].char_main != undefined) {
                this.rcvars.gameobj.scene.scenes[0].voicethis(data['voicethis']);
              }
            }
            if (data['charsay'] != undefined) {
              //if (this.rcvars) 
              this.plog.log("consumestackitem got charsay", data, this.rcvars.gameobj.scene.scenes[0].char_main);
              if (
                this.rcvars.gameobj.scene.scenes[0].char_main != undefined
              ) {
                this.rcvars.gameobj.registry.list.phm.charsaybubble(
                  this.rcvars.gameobj.scene.scenes[0].char_main,
                  data['charsay']
                );
              }
              this.plog.log('charsay', data, this.rcvars);
              this.rcloading.close();
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async Xusestackitem(useid: number) {//unused
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('usestackitem', this);
    url = this.rcvars.rcserver_url + '?get=usestackitem';
    postdata = {
      useid: useid,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('usestackitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            if (data['voicethis'] != undefined) {
              this.plog.log("getcommon2 got voicethis", data, this.rcvars.gameobj.scene.scenes[0].char_main);
              if (this.rcvars.gameobj.scene.scenes[0].char_main != undefined) {
                this.rcvars.gameobj.scene.scenes[0].voicethis(data['voicethis']);
              }
            }
            if (data['charsay'] != undefined) {
              //if (this.rcvars) 
              this.plog.log("usestackitem got charsay", data, this.rcvars.gameobj.scene.scenes[0].char_main);
              if (
                this.rcvars.gameobj.scene.scenes[0].char_main != undefined
              ) {
                this.rcvars.gameobj.registry.list.phm.charsaybubble(
                  this.rcvars.gameobj.scene.scenes[0].char_main,
                  data['charsay']
                );
              }
              this.plog.log('charsay', data, this.rcvars);
              this.rcloading.close();
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async dropitem(dropid: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('dropitem', this);
    url = this.rcvars.rcserver_url + '?get=dropitem';
    var mappos = '';
    if (this.rcvars.gameobj.scene.scenes[0] != undefined) {
      mappos =
        this.rcvars.gameobj.scene.scenes[0].char_main.currentx +
        '-' +
        this.rcvars.gameobj.scene.scenes[0].char_main.currenty;
    }
    postdata = {
      lang: this.rcvars.lang,
      dropid: dropid,
      mappos: mappos,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('dropitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            this.wsrc.ping();
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async deposititem(dropid: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    this.plog.log('deposititem', this);
    url = this.rcvars.rcserver_url + '?get=deposititem';
    postdata = {
      dropid: dropid,
      login: url1,
      charid: charid,
      sid: url2,
    };
    this.plog.log('deposititem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async retrieveitem(dropid: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    //this.plog.log('retrieveitem', this);
    url = this.rcvars.rcserver_url + '?get=retrieveitem';
    postdata = {
      dropid: dropid,
      login: url1,
      charid: charid,
      sid: url2,
    };
    //this.plog.log('retrieveitem url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async xxload_npcconver(wh: string, pid: number) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;
    url = this.rcvars.rcserver_url + '?get=get_npcconver';
    postdata = {
      lang: this.rcvars.lang,
      npc: wh,
      pid: pid,
      charid: charid,
      rc_login: url1,
      rc_sid: url2,
    };
    //this.plog.log('load_npcconver url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.rctoast.rcalert(
                this.rcvars.gameobj.scene.scenes[0],
                'Error',
                'Something Went Wrong\n' + data['error']
              );
              this.rcloading.close();
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async load_map(wh: string, mode = '') {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    url = this.rcvars.rcserver_url + '?get=get_load_map';
    var charid = this.rcvars.activechar.id;
    //console.log(this);
    var maprevealdata = this.rcvars.activemapreveal;
    if (
      maprevealdata != undefined &&
      Array.isArray(maprevealdata) &&
      this.rcvars != undefined &&
      this.rcvars.activemap != undefined &&
      this.rcvars.activemap["map"] != undefined &&
      this.rcvars.activemap["map"]["code"] != undefined
    ) {
      this.rcstorage.set("z_mapreveal_" + this.rcvars.activemap["map"]["code"] + "", maprevealdata);
    }
    postdata = {
      map: wh,
      charid: charid,
      rc_login: url1,
      rc_sid: url2,
    };
    if (mode == 'fromlogin') {
      postdata['fromlogin'] = 'yes';
    }
    //this.plog.log('load_map url', url);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data, this);
            if (data['error'] !== '') {
              this.rctoast.alert(
                'Error',
                'Something Went Wrong',
                data['error']
              );
              this.rcloading.close();
              resolve(data.data);
              return;
            }
            resolve(data.data);
          },
          (error) => {
            this.plog.log('posterror', error);
            resolve(error);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async getcharacters(isgetonly = false) {
    var postdata;
    var url;
    var url1 = await this.rcstorage.get('rc_login', true);
    var url2 = await this.rcstorage.get('rc_sid', true);
    url = this.rcvars.rcserver_url + '?get=getcharacter';
    if (isgetonly == false) {
      postdata = {
        login: url1,
        sid: url2,
      };
    } else {
      postdata = {
        login: url1,
        sid: url2,
        viewonly: "yes"
      };
    }

    //this.plog.log('getcharacters url', url, postdata);
    try {
      return new Promise((resolve) => {
        this.httpClient.post(url, JSON.stringify(postdata)).subscribe(
          (data: any) => {
            //this.plog.log('post done', data);
            if (data['error'] !== '') {
              this.plog.log('getcharacters err', this);
              this.plog.log('getcharacters err', arguments);
              //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
              this.rcloading.close();
              this.plog.log("getcharacters error", this);
              //this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].gifs.showrelogin(this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0]);
              //this.rcvars.rcgame.overlay_show('login');
              resolve(data);
              return 'error1';
            }
            resolve(data["data"]);
          },
          (error) => {
            this.plog.log('posterror', error);
            var tmp = { error: 'error' };
            resolve(tmp);
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async getlocal(getwhat: string) {
    //console.log('getlocal', getwhat);
    try {
      return new Promise((resolve) => {
        var ddd = this.httpClient
          .get(getwhat, { responseType: 'text' })
          .subscribe((response) => {
            //this.plog.log('getlocal', response);
            resolve(response);
          });
        this.plog.log('getlocal ddd', ddd);
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async getcommon(getwhat: string, forceurl = "") {
    //this.plog.log('getcommon', getwhat);
    var data;
    var useurl = this.rcvars.rcserver_url;
    //console.log("getcommon useurl",useurl);
    if (forceurl != "") {
      useurl = forceurl;
    }
    try {
      return new Promise((resolve) => {
        this.httpClient
          .get(useurl + '?get=' + getwhat)
          .subscribe(
            (data: any) => {
              //this.plog.log('done', data);
              this.wsrc.ping();
              if (data['error'] !== '') {
                this.rctoast.rcalert(
                  this.rcvars.gameobj.scene.scenes[0],
                  'getcommon Something Went Wrong',
                  data['error']
                );
                this.rcloading.close();
                resolve(data.data);
                return;
              }
              resolve(data);
            },
            (error) => {
              this.plog.log('getcommon error', error);
              resolve(error);
            }
          );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async geturl(useurl: string) {
    //this.plog.log('getcommon', getwhat);
    try {
      return new Promise((resolve) => {
        this.httpClient
          .get(useurl)
          .subscribe(
            (data: any) => {
              //this.plog.log('done', data);
              resolve(data);
              return;
            },
            (error) => {
              this.plog.log('geturl error', error);
              resolve(error);
            }
          );
      });
    } catch (e) {
      this.plog.log('geturl ErROR', e);
    }
  }
  async getServers() {
    var data;
    //this.plog.error("HERE",this.rcvars.rc_baseurl);
    try {
      return new Promise((resolve) => {
        //this.httpClient.get(this.rcvars.rc_baseurl + '?get=sv').subscribe(
        this.httpClient.get(this.rcvars.rc_baseurl + 'sv.txt').subscribe(
          (data: any) => {
            //console.log('done', data);
            if (data['error'] !== '') {
              this.rctoast.alert(
                'Error',
                'Something Went Wrong',
                data['error']
              );
              this.rcloading.close();
              return;
            }
            this.rcvars.rc_sv = data['data'];
            //this.plog.log('zzzz', this.rcvars.rc_sv);
            resolve(data);
          },
          (error) => {
            this.plog.error('error', error);
            resolve(error);
            this.rcloading.close();
            this.rctoast.alert(
              'Error',
              "Can't connect to server, please double check your internet connection.",
              data['error']
            );
          }
        );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async uploadavartar(avt) {
    var data;
    var llogin = await this.rcstorage.get('rc_login', true);
    var lsid = await this.rcstorage.get('rc_sid', true);
    var postdata = { rc_login: llogin, rc_sid: lsid, file: avt };
    //postdata.rc_login = llogin;
    //postdata.rc_sid = lsid;
    try {
      return new Promise((resolve) => {
        this.httpClient
          .post(
            this.rcvars.rcserver_url +
            '?get=uploadavartar',
            JSON.stringify(postdata)
          )
          .subscribe(
            (data: any) => {
              //this.plog.log('post done', data);
              if (data['error'] !== '') {
                this.rcloading.close();
                this.rctoast.alert(
                  'Error',
                  'Something Went Wrong',
                  data['error']
                );
                this.rcloading.close();
                return;
              }
              resolve(data);
            },
            (error) => {
              this.plog.log('posterror', error);
              resolve(error);
            }
          );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async mycc() {
    var data;
    var postdata: any = {};
    postdata['token'] = '57fa6a0e-f6a0-474a-a011-94c3a3c2b995';
    try {
      return new Promise((resolve) => {
        this.httpClient
          .post(this.rcvars.rc_baseurl + '?get=mycc', JSON.stringify(postdata))
          .subscribe(
            (data: any) => {
              this.plog.log('mycc done', data);
              if (data['error'] !== '') {
                this.rctoast.alert(
                  'Error',
                  'Something Went Wrong',
                  data['error']
                );
                this.rcloading.close();
                return;
              }
              resolve(data);
            },
            (error) => {
              this.plog.log('error', error);
              resolve(error);
            }
          );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async createCharacter(postdata: any) {
    var data;
    var llogin = await this.rcstorage.get('rc_login', true);
    var lsid = await this.rcstorage.get('rc_sid', true);
    postdata.rc_login = llogin;
    postdata.rc_sid = lsid;
    try {
      return new Promise((resolve) => {
        this.httpClient
          .post(
            this.rcvars.rcserver_url +
            '?get=createcharacter&rc_login=' +
            llogin +
            '&rc_sid=' +
            lsid,
            JSON.stringify(postdata)
          )
          .subscribe(
            (data: any) => {
              //this.plog.log('post done', data);
              if (data['error'] !== '') {
                this.rcloading.close();
                this.rctoast.alert(
                  'Error',
                  'Something Went Wrong',
                  data['error']
                );
                this.rcloading.close();
                return;
              }
              resolve(data);
            },
            (error) => {
              this.plog.log('posterror', error);
              resolve(error);
            }
          );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  savegame_filterObject(obj, keysToKeep) {
    const filteredObj = {};

    // Loop through the object
    for (const key in obj) {
      if (keysToKeep.includes(key)) {
        filteredObj[key] = obj[key];
      }
    }

    return filteredObj;
  }
  async savegame(postdata: any) {
    var data;
    var llogin = await this.rcstorage.get('rc_login', true);
    var lsid = await this.rcstorage.get('rc_sid', true);
    var charid = this.rcvars.activechar.id;

    const keysToKeep = ['map', 'name', 'id'];
    postdata = this.savegame_filterObject(postdata, keysToKeep);

    postdata.login = llogin;
    postdata.charid = charid;
    postdata.sid = lsid;
    try {
      return new Promise((resolve) => {
        this.httpClient
          .post(
            this.rcvars.rcserver_url +
            '?get=savegame&rc_login=' +
            llogin +
            '&rc_sid=' +
            lsid,
            JSON.stringify(postdata)
          )
          .subscribe(
            async (data: any) => {
              //this.plog.log('post done', data);
              this.wsrc.ping();
              //await this.rcvars.setactivechar(data["data"], this.rcvars.activechar["id"]);
              //this.plog.log("rcinventory equip", this);
              //await this.rcvars.gameobj.registry.list.gameitf.charinfoupdated(this.rcvars.gameobj.scene.scenes[0]);
              if (data['error'] !== '') {
                this.rcloading.close();
                this.rctoast.rcalert(
                  this.rcvars.gameobj.scene.scenes[0],
                  'Error',
                  'Something Went Wrong\n' + data['error']
                );
                this.rcloading.close();
                resolve(data.data);

                //this.rctoast.alert("Error", "Something Went Wrong", data["error"]);
                return;
              }

              if (data['ARCHI'] != undefined) {
                try {
                  this.rcvars.gameobj.scene.scenes[0].registry.list.scrw.work(this.rcvars.gameobj.scene.scenes[0], data['ARCHI']);
                } catch (e) {
                  console.log(e);
                }
              }
              resolve(data);
            },
            (error) => {
              this.plog.log('posterror', error);
              resolve(error);
            }
          );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async register(postParams: object) {
    //let postParams = {some:'some', another:'some'};
    var data;
    try {
      return new Promise((resolve) => {
        this.httpClient
          .post(
            this.rcvars.rcserver_url + '?get=register',
            JSON.stringify(postParams)
          )
          .subscribe(
            (data: any) => {
              //this.plog.log('post done', data);
              if (data['error'] !== '') {
                this.rctoast.alert(
                  'Error',
                  'Something Went Wrong',
                  data['error']
                );
                this.rcloading.close();
                return;
              }
              resolve(data);
            },
            (error) => {
              this.plog.log('posterror', error);
              resolve(error);
            }
          );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }

  async login(servercode: string, param: object) {
    //let postParams = {some:'some', another:'some'};
    //var serverurl = RCVarsService.rcserver[servercode];
    this.plog.log("login()", this);
    var serverurl = this.rcvars.rcgame.rcserverlistreg[servercode]['url'];
    this.plog.log(
      'zzz rchttp login',
      serverurl,
      '-',
      servercode,
      '-',
      this.rcvars.rc_sv
    );
    this.plog.log('login to sv ' + servercode + '-' + serverurl);
    var data;
    try {
      return new Promise((resolve) => {
        this.httpClient
          .post(serverurl + '?get=login', JSON.stringify(param))
          .subscribe(
            (data: any) => {
              //this.plog.log('login post done', data);
              if (data['error'] !== '') {
                this.rctoast.rcalert(this.rcvars.gameobj.scene.scenes[1],
                  'Information',
                  data['error']);
                this.rcloading.close();
                /*
                this.rctoast.alert(
                  'Error',
                  'Something Went Wrong',
                  data['error']
                );*/
                resolve(data.data);
                return;
              }
              resolve(data);
            },
            (error) => {
              this.plog.log('posterror', error);
              this.rctoast.alert(
                'Error',
                'Something Went Wrong',
                error['message']
              );
              resolve(error);
            }
          );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
  async reg_guest(servercode: string, param: object) {
    //let postParams = {some:'some', another:'some'};
    //var serverurl = RCVarsService.rcserver[servercode];
    //this.plog.log("reg_guest()", this);
    var serverurl = this.rcvars.rcgame.rcserverlistreg[servercode]['url'];
    this.plog.log(
      'zzz rchttp reg_guest',
      serverurl,
      '-',
      servercode,
      '-',
      this.rcvars.rc_sv
    );
    this.plog.log('login to sv ' + servercode + '-' + serverurl);
    var data;
    try {
      return new Promise((resolve) => {
        this.httpClient
          .post(serverurl + '?get=reg_guest', JSON.stringify(param))
          .subscribe(
            (data: any) => {
              //this.plog.log('login post done', data);
              if (data['error'] !== '') {
                this.rctoast.rcalert(this.rcvars.gameobj.scene.scenes[1],
                  'Information',
                  data['error']);
                this.rcloading.close();
                /*
                this.rctoast.alert(
                  'Error',
                  'Something Went Wrong',
                  data['error']
                );*/
                resolve(data.data);
                return;
              }
              resolve(data);
            },
            (error) => {
              this.plog.log('posterror', error);
              this.rctoast.alert(
                'Error',
                'Something Went Wrong',
                error['message']
              );
              resolve(error);
            }
          );
      });
    } catch (e) {
      this.plog.log('ErROR', e);
    }
  }
}
