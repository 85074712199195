import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { PGBar } from './pgbar';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class PetSkillService {
  shopdragbtn;
  skillpg;
  title;
  petid;
  empty;
  skicon = [];
  skob = [];
  sktt = [];
  skbg = [];
  currentmode;
  currentsubid;
  skdescr = [];
  filtertype = '';
  resptotalpage;
  respperpage = 6;
  currentresppage = 1;
  countraceskill;
  skillctnr_char;
  selectcircle;
  scrollareabox;
  shopclosebtn;
  itemdescr;
  eqitems = [];
  eqitemsp = [];
  itemwh = 54;
  shopguibg;
  wh;
  menubaritem = [];
  onoffbtn = [];
  shopfwdbtn;
  shopbackbtn;
  loading;
  bartext;
  countskilldsp: number;
  plog;
  isbadint = "no";

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();

  }


  loadingshow() {
    this.wh.petskillcontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }

  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      try {
        this.wh.registry.list.gameitf.guiwindowclick(this.wh.petskillcontainer);
      } catch (e) {
        this.plog.log("ERROR!", e);
      }
    }
    this.firsttimerun = false;
  }
  async show(wh, petid, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;
    this.plog.log('petskill show', wh, petid);

    this.wh = wh;

    if (this.wh.petskillcontainer != undefined) {
      await this.show_close(this.wh);
    }
    this.isbadint = await this.wh.registry.list.rcstorage.get("setting_badint", true);
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    const shopguibg = wh.add.image(0, 0, 'blankbarguibg');//skillguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    this.plog.log('skillgui var', this.rcvarsservice);

    this.skillpg = new PGBar(
      this.wh.registry.list.rcvarpass.gameobj.scene.scenes[0],
      {
        x: 0,
        y: 0,
        w: 280,
        h: 5,
        max: 100,
        me: 0,
        barcol1: 0x24ff2b,
        barcol2: 0xc45f00,
        txt: '',
      }, 'img'
    );

    this.shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    this.shopbackbtn.setOrigin(0.5, 0.5);
    this.shopbackbtn.setInteractive();
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');//wh.add.sprite(0, 0, "theb","forwardsign");//
    this.shopfwdbtn.setOrigin(0.5, 0.5);
    this.shopfwdbtn.setInteractive();
    this.shopfwdbtn.removeAllListeners();

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );

    wh.petskillcontainer = wh.add.container(300, 300);
    wh.petskillcontainer._refsrv = this;
    wh.petskillcontainer.name = "petskillcontainer";
    wh.petskillcontainer.add(this.shopclosebtn);
    wh.petskillcontainer.add(shopguibg);
    wh.petskillcontainer.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, wh.gl('Click Item to View Description'), wh.fs["winguiitemdescr"]);



    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.petskillcontainer.add(this.loading);
    wh.petskillcontainer.bringToTop(this.loading);

    if (wh.petskillcontainer_char != undefined) {
      wh.petskillcontainer_char.destroy();
    }


    wh.petskillcontainer_char = wh.add.container(-130, -100);
    wh.petskillcontainer.add(wh.petskillcontainer_char);

    wh.petskillcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 174);
    this.skillpg.bar.setPosition(-140, 172);
    this.itemdescr = itemdescr;

    wh.petskillcontainer.add(this.shopbackbtn);
    wh.petskillcontainer.add(this.shopfwdbtn);

    this.shopbackbtn.displayWidth = 100;
    this.shopfwdbtn.displayWidth = 100;
    this.shopbackbtn.displayHeight = 40;
    this.shopfwdbtn.displayHeight = 40;
    this.shopbackbtn.setPosition(-110, 220);
    this.shopfwdbtn.setPosition(110, 220);

    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );


    this.empty = wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 0);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    wh.petskillcontainer.add(this.empty);
    wh.petskillcontainer.bringToTop(this.empty);
    this.empty.visible = false;

    //drag

    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;

    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.y =
            tmpy - movedy;
          //this.maskg.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.x,this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.y)
          //this.rcvarsservice.gameobj.scene.scenes[0].petskillcontainer.y = pointer.y;
        }
      },
      this
    );

    wh.petskillcontainer.add(this.shopdragbtn);
    wh.petskillcontainer.bringToTop(this.shopdragbtn);
    this.menubaritem.push(this.shopdragbtn);
    //skilltype bar

    ///  this.scene.registry.list.rcskill.show_loaded(this.scene, "char", this.scene.registry.list.rcvarpass.activechar["race"], "normal");

    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("petskillcontainer", wh.petskillcontainer, width, height);
    wh.petskillcontainer.setPosition(width / 2, height / 2);
    let loader = new Phaser.Loader.LoaderPlugin(wh);


    //move
    wh.petskillcontainer.x = width - this.shopguibg.width / 2 + 20;

    this.bartext = wh.add.text(0, 0, 'Pet Skill: ', wh.fs["winguibardescr"]);
    wh.petskillcontainer.add(this.bartext);
    wh.petskillcontainer.bringToTop(this.bartext);
    this.bartext.setPosition(-140, -173);

    this.title = wh.add.text(0, 0, 'PET SKILLS', wh.fs["winguititle42"]);
    wh.petskillcontainer.add(this.title);
    wh.petskillcontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -226);

    wh.petskillcontainer.add(this.skillpg.bar);
    wh.petskillcontainer.bringToTop(this.skillpg.bar);
    this.skillpg.bar.visible = false;
    //this.buildskillctnr("char", this.rcvarsservice.activechar["race"]);


    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    var loadlist = ['skill_tp'];
    for (var k in loadlist) {
      var key = loadlist[k];
      //console.log(key,this.rcvarsservice.gameobj.textures.game.textures.list[key]);
      if (this.rcvarsservice.gameobj.textures.game.textures.list[key] == undefined) {
        if (this.isbadint == "yes") {
          loader.atlas(
            key + '',
            this.wh.registry.list.rcvarpass.rc_baseurl +
            '_badint/sprite/' +
            key +
            '.png' +
            svversionurlstring,
            this.wh.registry.list.rcvarpass.rc_baseurl +
            'sprite/' +
            key +
            '.json' +
            svversionurlstring
          );
        } else {
          loader.atlas(
            key + '',
            this.wh.registry.list.rcvarpass.rc_baseurl +
            'sprite/' +
            key +
            '.png' +
            svversionurlstring,
            this.wh.registry.list.rcvarpass.rc_baseurl +
            'sprite/' +
            key +
            '.json' +
            svversionurlstring
          );
        }
      }
    }
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.show_loaded(
          wh,
          petid,
          'normal'
        );
      },
      this
    );
    loader.start();
  }
  movemenubartotop() {
    for (const k in this.menubaritem) {
      var v = this.menubaritem[k];
      this.wh.petskillcontainer.bringToTop(v);
    }
  }

  async show_loaded(wh, petid, pagemode = 'normal') {
    this.plog.log('skill show_loaded', wh, petid, pagemode);

    if (this.wh.registry.list.rcvarpass.activechar == undefined ||
      this.wh.registry.list.rcvarpass.activechar["pet"] == undefined ||
      this.wh.registry.list.rcvarpass.activechar["pet"][petid] == undefined) {
      this.show_close(wh);
      return;
    }
    var maxlevel = this.wh.registry.list.rcvarpass.activechar["pet"][petid]["maxlevel"];
    this.petid = petid;
    this.currentsubid = this.wh.registry.list.rcvarpass.activechar["pet"][petid]["cbt"]["race"];
    this.bartext.setText("" + this.wh.registry.list.rcvarpass.activechar["pet"][petid]["name"] + " lv." + this.wh.registry.list.rcvarpass.activechar["pet"][petid]["level"] + " / max lv=" + this.wh.registry.list.rcvarpass.activechar["pet"][petid]['maxlevel']);
    var margintop = -90;
    var marginleft = -117;
    var i = 0;

    this.plog.log('pagemode', pagemode, this.currentresppage);
    if (pagemode == 'normal') {
      this.currentresppage = 1;
    }
    if (pagemode == 'retainpage') {
      if (this.currentresppage == 0 || this.currentresppage == undefined)
        this.currentresppage = 1;
    }

    var dat = this.rcvarsservice.skilltp;


    this.countraceskill = 0;
    this.countskilldsp = 0;
    for (const k in dat) {
      var v = dat[k];
      if (v['targetrace'] != this.currentsubid) continue;

      this.countraceskill++;
    }
    this.plog.log('skill show_loaded', wh, dat);

    var rowh = 50;
    var iconwh = 40;
    var i = 0;
    var rowi = 0;
    for (var vv in dat) {
      var vdat = dat[vv];

      //this.plog.log('skill mode char show_loaded i', i, vdat["targetrace"], subid, vdat);
      if (vdat["targetrace"] != this.currentsubid) continue;
      if (vdat["level"] > this.wh.registry.list.rcvarpass.activechar["pet"][this.petid]['maxlevel']) continue;
      //this.plog.log("xxxskdat", v);
      this.countskilldsp++;
      var codeicon = (vdat["code"] + "").replace("-", "");

      this.skicon[i] = wh.add.sprite(0, 0, "skill_tp", codeicon);
      this.skicon[i].name = "char----" + vdat["code"];

      //this.plog.log('skill show_loaded i', i, vdat);

      wh.petskillcontainer_char.add(this.skicon[i]);
      this.skicon[i].displayHeight = this.skicon[i].displayWidth = iconwh;
      this.skicon[i].setPosition((iconwh / 2) - 20, (rowi * rowh) - 5);
      this.skbg[i] = wh.add.image(0, 0, "rowbg");
      wh.petskillcontainer_char.add(this.skbg[i]);
      this.skbg[i].setOrigin(0, 0);
      this.skbg[i].setInteractive();
      this.skbg[i].removeAllListeners();
      this.skbg[i].name = this.currentsubid + "---" + vdat["code"];

      this.skbg[i].on('pointerup', function (pointer) {
        //this.plog.log("xxx,skill,", this);
        var tmpnamea = this.name.split("---");
        this.scene.registry.list.rcosd.show(wh, "skill", this.scene.registry.list.rcvarpass["skilltp"][tmpnamea[1]]);

      });

      this.skbg[i].setPosition(-20, ((rowi * rowh) - (rowh / 2)) + 5);
      this.skbg[i].displayHeight = iconwh;
      this.skbg[i].displayWidth = 300;
      wh.petskillcontainer_char.sendToBack(this.skbg[i]);

      this.sktt[i] = wh.add.text(0, 0, vdat["name"] + " lv." + vdat["level"], wh.fs["winguilist_title"]);
      wh.petskillcontainer_char.add(this.sktt[i]);
      wh.petskillcontainer_char.bringToTop(this.sktt[i]);
      this.sktt[i].setPosition(30, (rowi * rowh) - 25);
      //tmpskname.setResolution(0.25);
      //this.plog.log("zzzzzz", this.sktt[i]);
      var tmpsktype = "(Passive)";
      if (vdat["skill_type"] == "active") tmpsktype = "(Active)";
      this.skdescr[i] = wh.add.text(0, 0, tmpsktype + " " + vdat["descr"], wh.fs["winguilist_descr"]);
      wh.petskillcontainer_char.add(this.skdescr[i]);
      wh.petskillcontainer_char.bringToTop(this.skdescr[i]);
      this.plog.log("petskill", this.rcvarsservice.activechar["pet"], vdat);
      this.skdescr[i].setPosition(30, (rowi * rowh) - 5);
      if (this.rcvarsservice.activechar["pet"][this.petid]["cbt"]["skill"].includes(vdat["code"])) {
        //this.plog.log("skillobtained", vdat);
        this.skob[i] = wh.add.text(0, 0, "Obtained", wh.fs["winguilist_descr_green"]);
        wh.petskillcontainer_char.add(this.skob[i]);
        wh.petskillcontainer_char.bringToTop(this.skob[i]);
        this.skob[i].setPosition(210, (rowi * rowh) - 25);
        if (vdat["skill_type"] == "active") {
          this.onoffbtn[i] = wh.add.sprite(0, 0, "thesq", "off");
          this.onoffbtn[i].name = "char---" + vdat["code"] + "---" + this.petid;
        }
      }


      //this.onoffbtn[i].visible = false;
      //this.rcvarsservice.activechar["pet"][this.petid]["cbt"]["skill"]
      if (this.onoffbtn[i] != undefined) {
        if (this.rcvarsservice.activechar["pet"][this.petid] != undefined &&
          this.rcvarsservice.activechar["pet"][this.petid]["cbt"] != undefined &&
          this.rcvarsservice.activechar["pet"][this.petid]["cbt"]["skillconfig"] != undefined &&
          this.rcvarsservice.activechar["pet"][this.petid]["cbt"]["skillconfig"][vdat["code"]] != undefined) {
          this.onoffbtn[i].setTexture("thesq", "off");
          this.plog.log("onoffbtn exists is off", i, this.onoffbtn[i]);

        } else {
          this.onoffbtn[i].setTexture("thesq", "on");
          this.plog.log("onoffbtn exists is ON", i, this.onoffbtn[i]);
        }
        this.onoffbtn[i].removeAllListeners();
        this.onoffbtn[i].setInteractive();
        this.onoffbtn[i].on("pointerup", async function () {
          var tmpnamea = this.name.split("---");
          //this.plog.log("onoffbtn pointerup", this.name, tmpnamea, this.scene.registry.list.rcvarpass.activechar["pet"][tmpnamea[2]]);
          this.scene.plog.log("onoffbtn pointerup", this.scene);
          if (this.scene.registry.list.rcvarpass.activechar["pet"][tmpnamea[2]] == undefined) return;
          if (this.scene.registry.list.rcvarpass.activechar["pet"][tmpnamea[2]]["cbt"] == undefined) return;
          if (this.scene.registry.list.rcvarpass.activechar["pet"][tmpnamea[2]]["cbt"]["skillconfig"] == undefined) return;
          if (this.scene.registry.list.rcvarpass.activechar["pet"][tmpnamea[2]]["cbt"]["skillconfig"][tmpnamea[1]] == undefined) {
            this.scene.registry.list.rchttp.getcommon2("petskillconfig", { setoff: this.name, petid: tmpnamea[2] });
            this.scene.plog.log("onoffbtn", this.name, "currentoff", this.scene.registry.list.rcvarpass.activechar["pet"][tmpnamea[2]]["cbt"]["skillconfig"]);
          } else {
            this.scene.registry.list.rchttp.getcommon2("petskillconfig", { seton: this.name, petid: tmpnamea[2] });
            this.scene.plog.log("onoffbtn", this.name, "currentON", this.scene.registry.list.rcvarpass.activechar["pet"][tmpnamea[2]]["cbt"]["skillconfig"]);
          }
          try {
            this.scene.registry.list.gameitf.guiwindowclick(this.scene.petskillcontainer);
          } catch (e) {
            this.scene.plog.warn(e);
          }

          await this.scene.registry.list.rchttp.updatecharinfo();
          //this.plog.log("onoffbtn pointerup b4", this,this.scene);
        });

        wh.petskillcontainer_char.add(this.onoffbtn[i]);
        wh.petskillcontainer_char.bringToTop(this.onoffbtn[i]);
        this.onoffbtn[i].setPosition(this.skbg[i].x + this.skbg[i].displayWidth, (this.skbg[i].y - this.skbg[i].displayHeight / 2) + 24);
        this.plog.log("onoffbtn", this.onoffbtn[i]);
        this.onoffbtn[i].displayWidth = this.onoffbtn[i].displayHeight = 24;

      }

      i++;
      rowi++;
      if (rowi % 6 == 0) rowi = 0;
    }
    //this.plog.log('shop i count', i);
    if (i > 6) {
      //pages

      this.respperpage = 6;
      this.resptotalpage = Math.ceil(i / this.respperpage);
    }
    this.wh.registry.list.gameitf.guiwindowclick(this.wh.petskillcontainer);
    if (pagemode == "normal") {
      this.showinventory_showpage(wh, "back");
    } else {
      this.showinventory_showpage(wh, "retainpage");
    }
    this.firsttimerunfunc();

    if (this.countskilldsp == 0) {
      this.empty.visible = true;
    } else {
      this.empty.visible = false;
    }
  }
  async show_close(wh) {
    wh.petskillcontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.petskillcontainer);
    for (const k in this.wh.petskillcontainer.list) {
      try {
        await this.wh.petskillcontainer.remove(this.wh.petskillcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! petskillcontainer.destroy() ", e);
      }
    }

    for (const k in this.wh.petskillcontainer.list) {
      try {
        await this.wh.petskillcontainer.remove(this.wh.petskillcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! petskillcontainer.destroy() ", e);
      }
    }
    wh.petskillcontainer = undefined;
  }

  showinventory_showpage(gc, viewpage) {
    this.skillpg.bar.visible = false;

    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);

    if (this.currentmode == "char") {
      this.itemdescr.setText("Click skill to view description");

    }
    if (this.currentmode == "weapon") {
      this.skillpg.bar.visible = true;
      //if (this.rcvarsservice.activechar["cbt"]["allskillweaponpg"].includes(this.currentsubid)) {


    }
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = false;
      }
      if (this.sktt[i] != undefined) {
        this.sktt[i].visible = false;
      }
      if (this.skob[i] != undefined) {
        this.skob[i].visible = false;
      }
      if (this.skbg[i] != undefined) {
        this.skbg[i].visible = false;
      }
      if (this.skdescr[i] != undefined) {
        this.skdescr[i].visible = false;
      }
      if (this.onoffbtn[i] != undefined) {
        this.onoffbtn[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );
    this.loadinghide();
    for (var i = numfrom; i < numto; i++) {
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = true;
        this.skbg[i].visible = true;
        if (this.skob[i] != undefined)
          this.skob[i].visible = true;
        this.sktt[i].visible = true;
        this.skdescr[i].visible = true;
        if (this.onoffbtn[i] != undefined)
          this.onoffbtn[i].visible = true;
        var namea = this.skicon[i].name.split('-');
        if (this.onoffbtn[i] != undefined)
          griddat.push(this.onoffbtn[i]);
      }
    }
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.showinventory_showpage(null, "");
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
