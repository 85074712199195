import { Injectable } from '@angular/core';
import { plog } from './plog.service';
import { RCVarsService } from './rcvars-service.service';
import { RCHTTP } from './rchttp.service';
import { RCOSDService } from './rcosd.service';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  emptyBUYSTACKITEMcontainer_item;
  emptyVIEWMYcontainer_item;
  emptyVIEWMYSTACKcontainer_item;
  emptyBUYITEMcontainer_item;
  plog;
  VIEWMYSTACKfiltertype = '';
  VIEWMYSTACKsubmn = [];
  VIEWMYSTACKstackarr = [];
  VIEWMYSTACKselecteditemno;
  VIEWMYSTACKresptotalpage;
  VIEWMYSTACKrespperpage = 5;
  VIEWMYSTACKcurrentresppage = 1;
  VIEWMYSTACKitemdescr;
  VIEWMYSTACKinvensize;
  VIEWMYSTACKitemlist = [];
  VIEWMYSTACKitemlistp = [];
  VIEWMYSTACKitemlistpp = [];
  VIEWMYSTACKselectcircle;
  VIEWMYSTACKshopdata;
  VIEWMYSTACKguibg;
  VIEWMYSTACKbuybtn;
  VIEWMYSTACKfwdbtn;
  VIEWMYSTACKbackbtn;
  VIEWMYSTACKclosebtn;
  //
  VIEWMYfiltertype = '';
  VIEWMYsubmn = [];
  VIEWMYstackarr = [];
  VIEWMYselecteditemno;
  VIEWMYresptotalpage;
  VIEWMYrespperpage = 5;
  VIEWMYcurrentresppage = 1;
  VIEWMYitemdescr;
  VIEWMYinvensize;
  VIEWMYitemlist = [];
  VIEWMYitemlistp = [];
  VIEWMYitemlistpp = [];
  VIEWMYselectcircle;
  VIEWMYshopdata;
  VIEWMYguibg;
  VIEWMYbuybtn;
  VIEWMYfwdbtn;
  VIEWMYbackbtn;
  VIEWMYclosebtn;
  //
  BUYSTACKITEMfiltertype = '';
  BUYSTACKITEMsubmn = [];
  BUYSTACKITEMstackarr = [];
  BUYSTACKITEMselecteditemno;
  BUYSTACKITEMresptotalpage;
  BUYSTACKITEMrespperpage = 5;
  BUYSTACKITEMcurrentresppage = 1;
  BUYSTACKITEMitemdescr;
  BUYSTACKITEMinvensize;
  BUYSTACKITEMitemlist = [];
  BUYSTACKITEMitemlistp = [];
  BUYSTACKITEMitemlistpp = [];
  BUYSTACKITEMitemlistslr = [];
  BUYSTACKITEMwinguiltab;
  BUYSTACKITEMwinguiltabani;
  BUYSTACKITEMselectcircle;
  BUYSTACKITEMshopdata;
  BUYSTACKITEMguibg;
  BUYSTACKITEMbuybtn;
  BUYSTACKITEMfwdbtn;
  BUYSTACKITEMbackbtn;
  BUYSTACKITEMclosebtn;
  wh;
  BUYITEMfiltertype = '';
  BUYITEMsubmn = [];
  BUYITEMstackarr = [];
  BUYITEMselecteditemno;
  BUYITEMresptotalpage;
  BUYITEMrespperpage = 5;
  BUYITEMcurrentresppage = 1;
  BUYITEMitemdescr;
  BUYITEMinvensize;
  BUYITEMitemlist = [];
  BUYITEMitemlistr = [];
  BUYITEMitemlistp = [];
  BUYITEMitemlistpp = [];
  BUYITEMitemlistpplus = [];
  BUYITEMitemlistslr = [];
  BUYITEMselectcircle;
  BUYITEMshopdata;
  BUYITEMguibg;
  BUYITEMbuybtn;
  BUYITEMfwdbtn;
  BUYITEMbackbtn;
  BUYITEMclosebtn;
  constructor(
    public rcvarsservice: RCVarsService,
    public rchttp: RCHTTP,
    public rcosd: RCOSDService
  ) {
    this.plog = new plog();
  }

  sellitem(wh) {
    this.wh = wh;

    wh.registry.list.pickitemitem.show(
      wh,
      this.sellitem1,
      'sellitem',
      0,
      this.wh.trs("f:merchant:Select item to sell===Select item to sell")
    );
  }
  itemtosell_selecteditemcode;
  itemtosell_selecteditemno;
  itemtosell_currency
  async sellitem1(selecteditemno, selecteditemcode) {
    this.wh.registry.list.merchant.itemtosell_selecteditemcode = selecteditemcode;
    this.wh.registry.list.merchant.itemtosell_selecteditemno = selecteditemno;


    await this.wh.registry.list.rctoast.sleep(400);
    this.wh.registry.list.pickitem.show(
      this.wh,
      this.wh.registry.list.merchant.sellitem2,
      'pickcurrency',
      0,
      this.wh.trs("f:merchant:Select currency===Select currency")
    );

  }
  // async sellstackitem3(selectedcurrencycode, selectedcurrencyno) {
  //}

  async sellitem2(selectedcurrencycode, selectedcurrencyno) {
    this.plog.log("sellitem2", selectedcurrencyno, selectedcurrencycode)
    this.wh.registry.list.merchant.itemtosell_currency = selectedcurrencycode;
    var currencyname = this.wh.registry.list.rcvarpass.allstackitem[this.wh.registry.list.merchant.itemtosell_currency]['name'];
    this.plog.log("sellitem2", this.wh.registry.list.merchant.itemtosell_selecteditemcode)

    //this.plog.log('MerchantService,', selecteditemno);
    //this.plog.log('MerchantService,sellitem', selecteditemno);
    var itemname =
      this.wh.registry.list.rcvarpass.allitem[this.wh.registry.list.merchant.itemtosell_selecteditemcode]['name'];
    var price = await this.wh.registry.list.rctoast.rcenternum(
      this.wh,
      this.wh.trs("f:merchant:Enter Price===Enter Price"),
      'How much of ' + currencyname + ' do you want to sell ' + itemname + '?'
    );
    this.plog.log('MerchantService,price', price);
    var plusstr =
      '+' +
      this.wh.registry.list.rcvarpass.activechar['item'][this.wh.registry.list.merchant.itemtosell_selecteditemno]['itemplus'];
    itemname = itemname + plusstr;
    if (this.wh.registry.list.rcvarpass.activechar['item'][this.wh.registry.list.merchant.itemtosell_selecteditemno]['rarity'] != undefined && this.wh.registry.list.rcvarpass.activechar['item'][this.wh.registry.list.merchant.itemtosell_selecteditemno]['rarity'] != "") {
      if (this.wh.registry.list.rcvarpass.activechar['item'][this.wh.registry.list.merchant.itemtosell_selecteditemno]['rarity'] == "rar") {
        itemname = itemname + " [RARE ITEM]";
      }
      if (this.wh.registry.list.rcvarpass.activechar['item'][this.wh.registry.list.merchant.itemtosell_selecteditemno]['rarity'] == "leg") {
        itemname = itemname + " [LEGENDARY ITEM]";
      }
      if (this.wh.registry.list.rcvarpass.activechar['item'][this.wh.registry.list.merchant.itemtosell_selecteditemno]['rarity'] == "ult") {
        itemname = itemname + " [ULTIMATE ITEM]";
      }
      if (this.wh.registry.list.rcvarpass.activechar['item'][this.wh.registry.list.merchant.itemtosell_selecteditemno]['rarity'] == "rel") {
        itemname = itemname + " [RELIC ITEM]";
      }
    }
    if (price != 0) {
      var cfm = await this.wh.registry.list.rctoast.rcconfirm(
        this.wh,
        this.wh.trs("f:merchant:Please Confirm===Please Confirm"),
        'List \n' + itemname + ' for sell\n for ' + price + ' ' + currencyname + '? '
      );
      if (cfm == 'ok') {
        var shopdata = await this.wh.registry.list.rchttp.getcommon2(
          'merchantsellitem',
          { itemid: this.wh.registry.list.merchant.itemtosell_selecteditemno, price: price, currency: this.wh.registry.list.merchant.itemtosell_currency }
        );
        this.plog.log('MerchantService ,price', shopdata);
        if (shopdata == 'ok') {
          var cfm = await this.wh.registry.list.rctoast.rcalert(
            this.wh,
            this.wh.trs("f:merchant:Success===Success"),
            'Successfully list ' +
            itemname +
            ' for sell\n for ' +
            price +
            ' ' + currencyname + '\n ' + this.wh.trs("f:merchant:Please check back later===Please check back later")
          );
          await this.wh.registry.list.rchttp.updatecharinfo();
        }
      }
    }
  }

  async sellstackitem(wh) {
    this.wh = wh;
    this.wh.registry.list.pickitem.show(
      this.wh,
      this.sellstackitem2,
      'sellstackitem',
      0,
      this.wh.trs("f:merchant:Select StackItem to sell===Select StackItem to sell")
    );
  }
  stackitemtosell_selecteditemcode;
  stackitemtosell_selecteditemno;
  stackitemtosell_amnt;
  currency_selecteditemcode;
  currency_selecteditemno;

  /*
    async sellstackitem1(selecteditemcode, selecteditemno) {
      this.itemtosell_selecteditemcode = selecteditemcode;
      this.itemtosell_selecteditemno = selecteditemno;
      this.plog.log("sellstackitem1", this.wh);
      this.plog.log("sellstackitem1 this", this);
      await this.wh.registry.list.rctoast.sleep(400);
      this.wh.registry.list.pickitem.show(
        this.wh,
        this.wh.registry.list.merchant.sellstackitem2,
        'pickcurrency',
        0,
        'Select currency'
      );
  
    }*/
  async sellstackitem2(selecteditemcode, selecteditemno) {

    this.stackitemtosell_selecteditemcode = selecteditemcode;
    this.stackitemtosell_selecteditemno = selecteditemno;

    this.plog.log('MerchantService,', selecteditemcode, selecteditemno);
    this.plog.log('MerchantService,sellitem', selecteditemcode, selecteditemno);
    var itemname = this.wh.registry.list.rcvarpass.allstackitem[selecteditemcode]['name'];
    await this.wh.registry.list.rchttp.updatecharinfo();

    var amnt = await this.wh.registry.list.rctoast.rcenternum(
      this.wh,
      this.wh.trs("f:merchant:Enter Amount===Enter Amount"),
      'How many of ' +
      itemname +
      ' you want to sell?\n enter number from 1-' +
      this.wh.registry.list.rcvarpass.activechar['stackitem'][selecteditemno]['amnt']
    );
    if (amnt == 0) return;
    if (amnt < 1 || amnt > this.wh.registry.list.rcvarpass.activechar['stackitem'][selecteditemno]['amnt']) {
      await this.wh.registry.list.rctoast.rcalert(
        this.wh,
        this.wh.trs("f:merchant:Invalid number===Invalid number"),
        'Invalid number, please enter number 1-' +
        this.wh.registry.list.rcvarpass.activechar['stackitem'][
        selecteditemno
        ]['amnt']
      );
      return;
    }
    this.stackitemtosell_amnt = amnt;
    await this.wh.registry.list.rctoast.sleep(400);
    this.wh.registry.list.pickitem.show(
      this.wh,
      this.wh.registry.list.merchant.sellstackitem3,
      'pickcurrency',
      0,
      this.wh.trs("f:merchant:Select currency===Select currency")
    );

  }
  stackitemtosell_currency;
  async sellstackitem3(selectedcurrencycode, selectedcurrencyno) {
    this.plog.log("sellstackitem3", selectedcurrencycode, selectedcurrencyno);
    this.stackitemtosell_currency = selectedcurrencycode;
    await this.wh.registry.list.rctoast.sleep(400);
    var itemname = this.wh.registry.list.rcvarpass.allstackitem[this.stackitemtosell_selecteditemcode]['name'];
    var currencyname = this.wh.registry.list.rcvarpass.allstackitem[this.stackitemtosell_currency]['name'];
    var amnt = this.stackitemtosell_amnt;
    var price = await this.wh.registry.list.rctoast.rcenternum(
      this.wh,
      this.wh.trs("f:merchant:Enter Price===Enter Price"),
      'How much ' + currencyname + ' do you want to sell ' + itemname + ' x' + amnt + '?'
    );
    this.plog.log('MerchantService,price', price);

    itemname = itemname;
    if (price != 0) {
      var cfm = await this.wh.registry.list.rctoast.rcconfirm(
        this.wh,
        this.wh.trs("f:merchant:Please Confirm===Please Confirm"),
        'List ' + itemname + 'x' + amnt + ' for sell\n for ' + price + ' ' + currencyname + '?'
      );
      if (cfm == 'ok') {
        var shopdata = await this.wh.registry.list.rchttp.getcommon2(
          'merchantsellstackitem',
          { itemid: this.stackitemtosell_selecteditemcode, price: price, amnt: amnt, currency: this.stackitemtosell_currency }
        );
        this.BUYSTACKITEMresptotalpage = shopdata['totalpage'];
        this.plog.log('MerchantService ,price', shopdata);
        if (shopdata == 'ok') {
          var cfm = await this.wh.registry.list.rctoast.rcalert(
            this.wh,
            this.wh.trs("f:merchant:Success===Success"),
            'Successfully list ' +
            itemname +
            'x' +
            amnt +
            ' for sell\n for ' +
            price +
            ' ' + currencyname + '\n' + this.wh.trs("f:merchant:Please check back later===Please check back later")
          );
          await this.wh.registry.list.rchttp.updatecharinfo();
        }
      }
    }
  }

  BUYITEMtitle;
  BUYITEMwinguiltab;
  BUYITEMwinguiltabani;

  async BUYITEM(wh) {
    this.wh = wh;
    this.BUYITEMcurrentresppage = 1;

    this.BUYITEMshopdata = await wh.registry.list.rchttp.getcommon2(
      'merchantbuyitem',
      {}
    );
    this.plog.log('BUYITEMshopdata', wh.BUYITEMshopdata);
    if (wh.BUYITEMcontainer_item != undefined) {
      await this.BUYITEM_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('BUYITEM', wh, this.BUYITEMshopdata);

    const selectcircle = wh.add.image(0, 0, 'selectcircle');
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, 'blankrowguibg');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.BUYITEMguibg = shopguibg;
    const shopbackbtn = wh.add.sprite(0, 0, "thesb", 'backsign');
    shopbackbtn.setOrigin(0.5, 0.5);
    shopbackbtn.setInteractive();
    shopbackbtn.removeAllListeners();
    const shopfwdbtn = wh.add.sprite(0, 0, "thesb", 'forwardsign');
    shopfwdbtn.setOrigin(0.5, 0.5);
    shopfwdbtn.setInteractive();
    shopfwdbtn.removeAllListeners();
    this.plog.log('BUYITEM var', this.rcvarsservice);
    const buybtn = wh.add.sprite(0, 0, "thesb", 'buy');
    buybtn.setOrigin(0.5, 0.5);
    buybtn.setInteractive();
    buybtn.removeAllListeners();
    this.BUYITEMfwdbtn = shopfwdbtn;
    this.BUYITEMbackbtn = shopbackbtn;
    this.BUYITEMselectcircle = selectcircle;
    this.BUYITEMbuybtn = buybtn;

    var shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.BUYITEMclosebtn = shopclosebtn;
    this.BUYITEMclosebtn.setOrigin(0.5, 0.5);
    this.BUYITEMclosebtn.setPosition(165, -200);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.BUYITEMclosebtn.setInteractive();
    this.BUYITEMclosebtn.removeAllListeners();
    this.BUYITEMclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.BUYITEM_close(wh);
      },
      this
    );

    wh.BUYITEMcontainer_item = wh.add.container(300, 300);
    wh.BUYITEMcontainer_item._refsrv = this;
    wh.BUYITEMcontainer_item.name = "BUYITEMcontainer_item";
    wh.BUYITEMcontainer_item.add(this.BUYITEMclosebtn);
    wh.BUYITEMcontainer_item.add(selectcircle);
    wh.BUYITEMcontainer_item.add(shopguibg);
    wh.BUYITEMcontainer_item.add(shopbackbtn);
    wh.BUYITEMcontainer_item.add(shopfwdbtn);
    wh.BUYITEMcontainer_item.add(buybtn);

    this.BUYITEMtitle = wh.add.text(0, 0, 'ITEMS', wh.fs["winguititle42"]);
    wh.BUYITEMcontainer_item.add(this.BUYITEMtitle);
    wh.BUYITEMcontainer_item.bringToTop(this.BUYITEMtitle);
    this.BUYITEMtitle.setPosition(0 - this.BUYITEMtitle.displayWidth / 2, -225);

    this.BUYITEMwinguiltab = wh.add.image(0, 0, 'winguiltab');
    this.BUYITEMwinguiltab.setOrigin(0.5, 0.5);
    this.BUYITEMwinguiltab.displayWidth = this.BUYITEMwinguiltab.displayHeight = 52;
    this.BUYITEMwinguiltab.visible = false;
    wh.BUYITEMcontainer_item.add(this.BUYITEMwinguiltab);
    wh.BUYITEMcontainer_item.bringToTop(this.BUYITEMwinguiltab);

    this.emptyBUYITEMcontainer_item = wh.add.image(300, 300, 'empty');
    this.emptyBUYITEMcontainer_item.setOrigin(0.5, 0.5);
    this.emptyBUYITEMcontainer_item.setPosition(0, 0);
    this.emptyBUYITEMcontainer_item.displayWidth = this.emptyBUYITEMcontainer_item.displayHeight = 300;
    wh.BUYITEMcontainer_item.add(this.emptyBUYITEMcontainer_item);
    wh.BUYITEMcontainer_item.bringToTop(this.emptyBUYITEMcontainer_item);
    this.emptyBUYITEMcontainer_item.visible = false;

    shopbackbtn.displayWidth = 100;
    shopfwdbtn.displayWidth = 100;
    buybtn.displayWidth = 100;
    shopbackbtn.displayHeight = 40;
    shopfwdbtn.displayHeight = 40;
    buybtn.displayHeight = 40;
    shopbackbtn.setPosition(-100, 220);
    shopfwdbtn.setPosition(100, 220);
    buybtn.setPosition(0, 220);

    wh.BUYITEMcontainer_item.bringToTop(this.BUYITEMclosebtn);
    var itemdescr = wh.add.text(0, 0, this.wh.trs("f:merchant:Click Item to View Description===Click Item to View Description"), wh.fs['winguilist_descr']);
    wh.BUYITEMcontainer_item.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.BUYITEMitemdescr = itemdescr;
    this.BUYITEMinvensize = wh.add.text(0, 0, '0/0', {
      fontFamily: 'ffff,uuuu',
      fontSize: '12px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    wh.BUYITEMcontainer_item.add(this.BUYITEMinvensize);
    this.BUYITEMinvensize.setOrigin(1, 1);
    this.BUYITEMinvensize.setPosition(150, -147);

    //drag

    var shopdragbtn = wh.add.image(300, 300, 'spacer');
    wh.shopdragbtn = shopdragbtn;
    wh.shopdragbtn.setOrigin(0.5, 0.5);
    wh.shopdragbtn.setPosition(0, -200);
    shopdragbtn.displayWidth = 270;
    shopdragbtn.displayHeight = 60;
    wh.shopdragbtn.setInteractive();
    wh.shopdragbtn.removeAllListeners();
    wh.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item
        );

        this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.x;
        this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.y;
        this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.dragactivepy = 0;
    wh.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.BUYITEM_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item.y = pointer.y;
        }
      },
      this
    );
    wh.BUYITEMcontainer_item.add(wh.shopdragbtn);
    wh.BUYITEMcontainer_item.bringToTop(wh.shopdragbtn);

    this.plog.log(
      'BUYITEMcontainer_item x',
      wh.BUYITEMcontainer_item,
      width,
      height
    );
    wh.BUYITEMcontainer_item.setPosition(width / 2, height / 2);
    //move
    wh.BUYITEMcontainer_item.x = width - this.BUYITEMguibg.width / 2;


    for (const key in this.BUYITEMfilterlrbtn) {
      if (Object.prototype.hasOwnProperty.call(this.BUYITEMfilterlrbtn, key)) {
        try {
          this.BUYITEMfilterlrbtn[key].destroy(true);
        } catch (e) {
          console.log(e);
        }
      }
    }

    this.BUYITEMfilterlrbtn["lr1"] = this.wh.add.sprite(0, 0, "thebs", "lr1d");
    this.BUYITEMfilterlrbtn["lr51"] = this.wh.add.sprite(0, 0, "thebs", "lr51d");
    this.BUYITEMfilterlrbtn["lr101"] = this.wh.add.sprite(0, 0, "thebs", "lr101d");
    this.BUYITEMfilterlrbtn["lr151"] = this.wh.add.sprite(0, 0, "thebs", "lr151d");
    this.BUYITEMfilterlrbtn["lr201"] = this.wh.add.sprite(0, 0, "thebs", "lr201d");
    this.BUYITEMfilterlrbtn["lr251"] = this.wh.add.sprite(0, 0, "thebs", "lr251d");
    //this.BUYITEMfilterlrbtn["lr301"] = this.wh.add.sprite(0, 0, "thebs", "lr301d");
    //this.BUYITEMfilterlrbtn["lr351"] = this.wh.add.sprite(0, 0, "thebs", "lr351d");

    let loader = new Phaser.Loader.LoaderPlugin(wh);

    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    var loadlist = ['itemtypeico'];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }
    this.wh.camman(this.wh);
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.BUYITEM_showpage(wh, "1");
        this.BUYITEM_loaded(wh);
      },
      this
    );
    loader.start();
  }
  BUYITEMfilterlr = "";
  BUYITEMfilterlrbtn = [];
  async BUYITEM_loaded(wh, refocus = true) {
    this.BUYITEMbuybtn.visible = true;

    var griddat = [];
    var griddatf = [];

    if (this.BUYITEMfilterlr != "") {
      if (this.BUYITEMfilterlrbtn[this.BUYITEMfilterlr] != undefined) {
        this.BUYITEMfilterlrbtn[this.BUYITEMfilterlr].setTexture("thebs", this.BUYITEMfilterlr)
      }
    }
    var lrnamedescr = [];
    lrnamedescr["lr1"] = "Level 1-50";
    lrnamedescr["lr51"] = "Level 51-100";
    lrnamedescr["lr101"] = "Level 101-150";
    lrnamedescr["lr151"] = "Level 151-200";
    lrnamedescr["lr201"] = "Level 201-250";
    lrnamedescr["lr251"] = "Level 251-300";
    //    lrnamedescr["lr301"] = "Level 301-350";
    //lrnamedescr["lr351"] = "Level 351-400";
    for (const key in this.BUYITEMfilterlrbtn) {
      if (Object.prototype.hasOwnProperty.call(this.BUYITEMfilterlrbtn, key)) {
        this.wh.BUYITEMcontainer_item.add(this.BUYITEMfilterlrbtn[key]);
        this.wh.BUYITEMcontainer_item.bringToTop(this.BUYITEMfilterlrbtn[key]);
        this.BUYITEMfilterlrbtn[key].setInteractive();
        this.BUYITEMfilterlrbtn[key].removeAllListeners();
        this.BUYITEMfilterlrbtn[key].name = key;
        this.BUYITEMfilterlrbtn[key].descr = lrnamedescr[key];
        this.BUYITEMfilterlrbtn[key].on("pointerover", function () {
          this.scene.registry.list.gameitf.gamemenutooltip(
            this.scene,
            this,
            this.descr,
            'top'
          );
        });

        this.BUYITEMfilterlrbtn[key].on("pointerup", async function () {
          for (const key in this.scene.registry.list.merchant.BUYITEMfilterlrbtn) {
            if (Object.prototype.hasOwnProperty.call(this.scene.registry.list.merchant.BUYITEMfilterlrbtn, key)) {
              this.scene.registry.list.merchant.BUYITEMfilterlrbtn[key].setTexture("thebs", key + "d");
            }
          }
          if (this.scene.registry.list.merchant.BUYITEMfilterlr == this.name) {
            this.scene.registry.list.merchant.BUYITEMfilterlr = '';
          } else {
            this.scene.registry.list.merchant.BUYITEMfilterlr = this.name;
            this.setTexture("thebs", this.name + "");
          }

          this.scene.registry.list.merchant.BUYITEMcurrentresppage = 1;

          var shopdata = await this.scene.registry.list.rchttp.getcommon2('merchantbuyitem', {
            page: this.scene.registry.list.merchant.BUYITEMcurrentresppage,
            filtertype: this.scene.registry.list.merchant.BUYITEMfiltertype,
            filtertypelr: this.scene.registry.list.merchant.BUYITEMfilterlr,
          }
          );
          this.scene.registry.list.merchant.BUYITEMshopdata = shopdata;
          this.scene.registry.list.merchant.BUYITEMresptotalpage =
            shopdata['totalpage'];
          this.scene.registry.list.merchant.BUYITEM_loaded(
            this.scene.registry.list.merchant.wh
          );
        });
      }
    }
    this.BUYITEMfilterlrbtn["lr1"].setPosition(-152, -137);
    this.BUYITEMfilterlrbtn["lr51"].setPosition(-109, -137);
    this.BUYITEMfilterlrbtn["lr101"].setPosition(-66, -137);
    this.BUYITEMfilterlrbtn["lr151"].setPosition(-23, -137);
    this.BUYITEMfilterlrbtn["lr201"].setPosition(20, -137);
    this.BUYITEMfilterlrbtn["lr251"].setPosition(63, -137);
    //this.BUYITEMfilterlrbtn["lr301"].setPosition(106, -137);
    //this.BUYITEMfilterlrbtn["lr351"].setPosition(149, -137);
    this.plog.log('BUYITEM_loaded', wh, this.BUYITEMshopdata);
    if (refocus == true) {
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
        this.rcvarsservice.gameobj.scene.scenes[0].BUYITEMcontainer_item
      );
    }

    var paddingl = -175;
    var paddingt = -140;
    //var stackarr = [];

    this.BUYITEMstackarr['sword'] = [
      'sword',
      'longsword',
      'axe',
      'longaxe',
      'wand',
      'bow',
      'dagger',
    ];
    /*this.BUYITEMstackarr['longsword'] = ['longsword'];
    this.BUYITEMstackarr['axe'] = ['axe'];
    this.BUYITEMstackarr['longaxe'] = ['longaxe'];
    this.BUYITEMstackarr['wand'] = ['wand'];
    this.BUYITEMstackarr['bow'] = ['bow'];
    this.BUYITEMstackarr['dagger'] = ['dagger'];*/
    this.BUYITEMstackarr['shield'] = ['shield'];
    this.BUYITEMstackarr['accessories'] = [
      'ring',
      'earring',
      'necklace',
      'bracelet',
    ];
    this.BUYITEMstackarr['boots'] = [
      'strboots',
      'chaboots',
      'dexboots',
      'conboots',
    ];
    this.BUYITEMstackarr['gloves'] = [
      'strgloves',
      'dexgloves',
      'chagloves',
      'dexarmor',
    ];
    this.BUYITEMstackarr['armor'] = [
      'strarmor',
      'conarmor',
      'chaarmor',
      'conboots',
    ];
    this.BUYITEMstackarr['pant'] = [
      'strpants',
      'conpants',
      'chapants',
      'dexpants',
    ];
    this.BUYITEMstackarr['helmet'] = [
      'strhelmet',
      'conhelmet',
      'chahelmet',
      'dexhelmet',
    ];
    if (this.BUYITEMsubmn.length == 0) {
      var paddingts = paddingt + 0;
      var iconwhsub = 30;
      var rowh = 40;
      var i = 0;
      for (var k in this.BUYITEMstackarr) {
        var v = this.BUYITEMstackarr[k];
        //this.plog.log('xxxxx', this.wh, k, v, i);

        this.BUYITEMsubmn[k] = this.wh.add.sprite(
          0,
          0,
          'itemtypeico',
          k,
          iconwhsub * i + 2 + paddingts
        );
        this.wh.BUYITEMcontainer_item.add(this.BUYITEMsubmn[k]);
        this.wh.BUYITEMcontainer_item.bringToTop(this.BUYITEMsubmn[k]);
        this.BUYITEMsubmn[k].displayWidth = this.BUYITEMsubmn[k].displayHeight =
          iconwhsub;
        this.BUYITEMsubmn[k].setPosition(
          paddingl,
          paddingt + ((iconwhsub + 3) * i + 65)
        );
        paddingts = paddingts + rowh;
        this.BUYITEMsubmn[k].setInteractive();
        this.BUYITEMsubmn[k].removeAllListeners();
        this.BUYITEMsubmn[k].name = k;
        this.BUYITEMsubmn[k].on('pointerup', async function () {
          //this.plog.log('xxxxx', this);
          //this.scene.registry.list.merchant.BUYITEMwinguiltab.setPosition(this.x - 7, this.y);

          if (this.scene.registry.list.merchant.BUYITEMfiltertype == this.name) {
            this.scene.registry.list.merchant.BUYITEMfiltertype = '';
            //this.scene.registry.list.merchant.BUYITEMwinguiltab.visible = false;
          } else {
            this.scene.registry.list.merchant.BUYITEMfiltertype = this.name;
            //this.scene.registry.list.merchant.BUYITEMwinguiltab.visible = true;
            this.scene.BUYITEMcontainer_item.moveBelow(this.scene.registry.list.merchant.BUYITEMwinguiltab, this);
          }
          this.scene.registry.list.merchant.BUYITEMcurrentresppage = 1;

          var shopdata = await this.scene.registry.list.rchttp.getcommon2('merchantbuyitem', {
            page: this.scene.registry.list.merchant.BUYITEMcurrentresppage,
            filtertype: this.scene.registry.list.merchant.BUYITEMfiltertype,
            filtertypelr: this.scene.registry.list.merchant.BUYITEMfilterlr,
          });
          this.scene.registry.list.merchant.BUYITEMshopdata = shopdata;
          this.scene.registry.list.merchant.BUYITEMresptotalpage =
            shopdata['totalpage'];
          this.scene.registry.list.merchant.BUYITEM_loaded(
            this.scene.registry.list.merchant.wh
          );
        });
        this.BUYITEMsubmn[k].on('pointerover', function () {
          var str = this.name;
          if (str == 'sword') str = 'weapons';
          str = this.scene.trs("f:inventorycontainer:itemtype-" + str + "===" + str);

          this.scene.registry.list.gameitf.gamemenutooltip(
            this.scene,
            this,
            str,
            'left'
          );
        });

        griddat.push(this.BUYITEMsubmn[k]);

        if (this.BUYITEMfiltertype != "" && this.BUYITEMfiltertype == k) {
          //this.wh.registry.list.stackinven.BUYITEMwinguiltab.setPosition(this.submn[k].x - 7, this.submn[k].y);
          if (this.BUYITEMwinguiltab.visible == true) {
            try { this.BUYITEMwinguiltabani.remove(); } catch (e) { console.log(e); }
            this.BUYITEMwinguiltabani = this.wh.tweens.add(
              {
                targets: this.BUYITEMwinguiltab,
                y: this.BUYITEMsubmn[k].y,
                x: this.BUYITEMsubmn[k].x - 7,
                displayWidth: 52,
                ease: 'Power3',
                duration: 200,
                delay: 0,
                onComplete: function () {
                }
              }, this
            );
          } else {
            this.BUYITEMwinguiltab.y = this.BUYITEMsubmn[k].y;
            this.BUYITEMwinguiltab.x = this.BUYITEMsubmn[k].x - 7;
            this.BUYITEMwinguiltab.displayWidth = 1;

            try { this.BUYITEMwinguiltabani.remove(); } catch (e) { console.log(e); }
            this.BUYITEMwinguiltabani = this.wh.tweens.add(
              {
                targets: this.BUYITEMwinguiltab,
                //y: this.submn[k].y,
                displayWidth: 52,
                ease: 'Power3',
                duration: 400,
                delay: 0,
                onComplete: function () {
                }
              }, this
            );
          }
          this.BUYITEMwinguiltab.visible = true;
          this.wh.BUYITEMcontainer_item.moveBelow(this.BUYITEMwinguiltab, this.BUYITEMsubmn[k]);
        } else {

        }
        i++;
      }
    }
    if (this.BUYITEMfiltertype == "") {
      if (this.BUYITEMwinguiltab.visible == true) {
        try { this.BUYITEMwinguiltabani.remove(); } catch (e) { console.log(e); }
        this.BUYITEMwinguiltabani = this.wh.tweens.add(
          {
            targets: this.BUYITEMwinguiltab,
            displayWidth: 1,
            ease: 'Power3',
            duration: 200,
            delay: 0,
            onComplete: function () {
              //console.log("twcomplete", this);
              this.BUYITEMwinguiltab.visible = false;
              this.BUYITEMwinguiltab.displayWidth = 52;
            }.bind(this,)
          }, this
        );
      }
    }
    var margintop = -90;
    var marginleft = -117;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < this.BUYITEMrespperpage; i++) {
      if (this.BUYITEMitemlist[i] != undefined) {
        //this.plog.log(this.BUYITEMitemlist[i]);
        //this.BUYITEMitemlist[i].visible = false;
        this.BUYITEMitemlist[i].destroy();
      }
      if (this.BUYITEMitemlistp[i] != undefined) {
        this.BUYITEMitemlistp[i].destroy();
      }
      if (this.BUYITEMitemlistr[i] != undefined) {
        this.BUYITEMitemlistr[i].destroy();
      }
      if (this.BUYITEMitemlistpp[i] != undefined) {
        this.BUYITEMitemlistpp[i].destroy();
      }
      if (this.BUYITEMitemlistslr[i] != undefined) {
        this.BUYITEMitemlistslr[i].destroy();
      }
      if (this.BUYITEMitemlistpplus[i] != undefined) {
        this.BUYITEMitemlistpplus[i].destroy();
      }
    }
    this.plog.log('BUYITEMitemlist', this.BUYITEMitemlist, this.BUYITEMitemlistp);
    this.plog.log('pagemode', this.BUYITEMcurrentresppage);
    var pagemode = 'normal';
    if (pagemode == 'normal') {
      //this.BUYITEMcurrentresppage = 1;
    }
    if (pagemode == 'retainpage') {
      if (
        this.BUYITEMcurrentresppage == 0 ||
        this.BUYITEMcurrentresppage == undefined
      )
        this.BUYITEMcurrentresppage = 1;
    }
    //this.BUYITEMinvensize.setText('Viewing ' + countslot + '/' + countslot);

    this.BUYITEMinvensize.setStyle({
      fontFamily: 'ffff,uuuu',
      fontSize: '10px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    var i = 0;
    this.plog.log('loaded itemdatall ', this.BUYITEMshopdata);

    for (var vv in this.BUYITEMshopdata['item']) {
      var vdat = this.BUYITEMshopdata['item'][vv];
      var itemdat = this.rcvarsservice.allitem[vdat.item];
      this.plog.log('loaded itemdat ', vv, vdat, itemdat);
      //var tmp = wh.add.image(32, 32, itemdat["code"]);
      if (itemdat == undefined) continue;
      this.BUYITEMitemlist[i] = this.wh.add.sprite(
        32,
        32,
        'item_tp',
        itemdat['code']
      );
      this.BUYITEMitemlist[i].displayWidth = 48;
      this.BUYITEMitemlist[i].displayHeight = 48;
      this.wh.BUYITEMcontainer_item.add(this.BUYITEMitemlist[i]);
      this.BUYITEMitemlist[i].setPosition(marginleft, margintop + crow * 59);
      //this.plog.log("xxx1",tmp);
      //console.log(itemdat);
      this.BUYITEMitemlistp[i] = this.wh.add.text(
        0,
        0,
        itemdat['name'],// + '+' + vdat['itemplus'],
        this.wh.fs['winguiitemplus']
      );
      this.wh.BUYITEMcontainer_item.add(this.BUYITEMitemlistp[i]);
      this.BUYITEMitemlistp[i].setPosition(
        this.BUYITEMitemlist[i].x + 35,
        this.BUYITEMitemlist[i].y - 25
      );
      if (vdat['currency'] == "" || vdat['currency'] == undefined) {
        vdat['currency'] = "gold";
      }

      var currencyname = this.wh.registry.list.rcvarpass.allstackitem[vdat['currency']]['name'];
      if (this.BUYITEMitemlistpp[i] != undefined) {
        this.BUYITEMitemlistpp[i].destroy();
      }
      if (this.BUYITEMitemlistpplus[i] != undefined) {
        this.BUYITEMitemlistpplus[i].destroy();
      }
      if (this.BUYITEMitemlistslr[i] != undefined) {
        this.BUYITEMitemlistslr[i].destroy();
      }
      if (this.BUYITEMitemlistr[i] != undefined) {
        this.BUYITEMitemlistr[i].destroy();
      }
      this.BUYITEMitemlistr[i] = this.wh.add.sprite(16, 16, "spacer");
      if (vdat["rarity"] == "rar") {
        this.BUYITEMitemlistr[i].setTexture("effrar").play("effrar_b");
        //this.plog.warn("playing rarity");
      }
      if (vdat["rarity"] == "leg") {
        this.BUYITEMitemlistr[i].setTexture("effleg").play("effleg_b");
        //this.plog.warn("playing rarity");
      }
      if (vdat["rarity"] == "ult") {
        this.BUYITEMitemlistr[i].setTexture("effleg").play("effleg_b");
        this.BUYITEMitemlistr[i].setTint(0xff0000);
      }
      if (vdat["rarity"] == "rel") {
        this.BUYITEMitemlistr[i].setTexture("effleg").play("effleg_b");
        this.BUYITEMitemlistr[i].setTint(0x0000ff);
      }
      this.BUYITEMitemlistr[i].displayHeight = this.BUYITEMitemlistr[i].displayWidth = 32;

      this.BUYITEMitemlistslr[i] = this.wh.add.text(0, 0, vdat['tmpseller'], {
        fontFamily: 'ffff,uuuu',
        fontSize: '8px',
        fill: '#777777',
        boundsAlignH: 'center',
        boundsAlignV: 'middle',
        stroke: '#000',
        strokeThickness: 0,
      });
      currencyname = currencyname.replace("Soul Crystal", "Souls");
      this.BUYITEMitemlistpp[i] = this.wh.add.text(0, 0, this.wh.registry.list.gameitf.humannumbercomma(vdat['price'], 0) + ' ' + currencyname, {
        fontFamily: 'ffff,uuuu',
        fontSize: '12px',
        fill: '#e0ad02',
        boundsAlignH: 'center',
        boundsAlignV: 'middle',
        stroke: '#000',
        strokeThickness: 2,
      });
      this.BUYITEMitemlistpplus[i] = this.wh.add.text(0, 0, "+" + vdat['itemplus'], wh.fs["wingui_plus"]);
      this.wh.BUYITEMcontainer_item.add(this.BUYITEMitemlistslr[i]);
      this.wh.BUYITEMcontainer_item.add(this.BUYITEMitemlistpp[i]);
      this.wh.BUYITEMcontainer_item.add(this.BUYITEMitemlistpplus[i]);
      this.wh.BUYITEMcontainer_item.add(this.BUYITEMitemlistr[i]);
      this.BUYITEMitemlistr[i].setPosition(this.BUYITEMitemlist[i].x - 16, this.BUYITEMitemlist[i].y - 16);

      this.BUYITEMitemlistpp[i].setPosition(
        this.BUYITEMitemlist[i].x + 35,
        this.BUYITEMitemlist[i].y - 2
      );
      this.BUYITEMitemlistpplus[i].setPosition(
        this.BUYITEMitemlist[i].x - 11,
        this.BUYITEMitemlist[i].y + 7
      );
      this.BUYITEMitemlistslr[i].setOrigin(1, 0);
      this.BUYITEMitemlistslr[i].setPosition(
        this.BUYITEMitemlist[i].x + 260,
        this.BUYITEMitemlist[i].y + 5
      );
      this.BUYITEMitemlist[i].visible = true;
      this.BUYITEMitemlistr[i].visible = true;
      this.BUYITEMitemlistp[i].visible = true;
      this.BUYITEMitemlistpp[i].visible = true;
      this.BUYITEMitemlistpplus[i].visible = true;
      this.BUYITEMitemlistslr[i].visible = true;
      this.BUYITEMitemlist[i].setInteractive();
      this.BUYITEMitemlist[i].removeAllListeners();
      this.BUYITEMitemlist[i].name =
        '' + vdat['item'] + '-' + vdat['id'] + '-' + i;
        wh.registry.list.gameitf.gengemsdsp(wh, this.BUYITEMitemlist[i], vdat['gems'])

      this.BUYITEMitemlist[i].on(
        'pointerup',
        function (pointer, localX, localY, event) {
          this.scene.plog.log('shop itemclick this', this);
          this.scene.plog.log('shop itemclick', pointer, localX, localY, event);
          this.scene.registry.list.merchant.BUYITEM_itemclick(this.scene, this);
        }
      );
      griddat.push(this.BUYITEMitemlist[i]);
      griddatf.push(this.BUYITEMitemlist[i]);

      crow++;

      i++;
    }

    if (i == 0) {
      this.emptyBUYITEMcontainer_item.visible = true;
    } else {
      this.emptyBUYITEMcontainer_item.visible = false;
    }

    this.plog.log('shop i count', i);
    if (i > 25) {
      //pages
      this.BUYITEMresptotalpage = Math.ceil(i / this.BUYITEMrespperpage);
    }
    this.BUYITEMbackbtn.removeAllListeners();
    this.BUYITEMfwdbtn.removeAllListeners();
    this.BUYITEMbackbtn.on(
      'pointerup',
      async function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        await this.BUYITEM_showpage(wh, 'back');
        this.BUYITEM_loaded(this.wh);
      },
      this
    );
    this.BUYITEMfwdbtn.on(
      'pointerup',
      async function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        await this.BUYITEM_showpage(wh, 'next');
        this.BUYITEM_loaded(this.wh);
      },
      this
    );

    this.BUYITEMbuybtn.removeAllListeners();
    this.BUYITEMbuybtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop buybtn', this);
        this.BUYITEM_buy(this);
      },
      this
    );

    var numfrom = this.BUYITEMrespperpage * (this.BUYITEMcurrentresppage - 1);
    numfrom = numfrom + 1;
    var numto = this.BUYITEMrespperpage * this.BUYITEMcurrentresppage;
    var mineinfo = "";
    if (this.BUYITEMfiltertype != "") {
      //console.log("mineinfo", this.wh.registry.list.rcvarpass.activechar.equip);
      //console.log("BUYITEMfiltertype", this.BUYITEMfiltertype);
    }
    this.BUYITEMinvensize.setText(
      'Viewing ' +
      numfrom +
      '-' +
      numto +
      '/' +
      this.BUYITEMshopdata['allrecord']
    );
    griddat.push(this.BUYITEMbackbtn);
    griddat.push(this.BUYITEMbuybtn);
    griddat.push(this.BUYITEMfwdbtn);
    griddat.push(this.BUYITEMclosebtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }
  async BUYITEM_showpage(gc, viewpage) {
    this.plog.log('BUYITEM_showpage', gc, viewpage);
    this.BUYITEMselectcircle.visible = false;
    if (viewpage == 'back') {
      if (this.BUYITEMcurrentresppage > 1) this.BUYITEMcurrentresppage--;
    }
    if (viewpage + '' == '1') {
      this.BUYITEMcurrentresppage = 1;
    }
    if (viewpage == 'next') {
      if (this.BUYITEMcurrentresppage < this.BUYITEMresptotalpage)
        this.BUYITEMcurrentresppage++;
    }

    for (var i = 0; i <= this.BUYITEMrespperpage; i++) {
      if (this.BUYITEMitemlist[i] != undefined) {
        this.BUYITEMitemlist[i].visible = false;
        
        for (const k in this.wh.BUYITEMcontainer_item.list) {
          if (this.wh.BUYITEMcontainer_item.list[k].name == "accfor-" + this.BUYITEMitemlist[i].name) {
            this.wh.BUYITEMcontainer_item.list[k].visible = false;
          }
        }
      }
      if (this.BUYITEMitemlistp[i] != undefined) {
        this.BUYITEMitemlistp[i].visible = false;
      }
      if (this.BUYITEMitemlistr[i] != undefined) {
        this.BUYITEMitemlistr[i].visible = false;
      }
    }
    this.plog.log(
      'BUYITEM_showpage',
      viewpage,
      this.BUYITEMcurrentresppage,
      'perpage',
      this.BUYITEMrespperpage
    );

    var shopdata = await this.wh.registry.list.rchttp.getcommon2(
      'merchantbuyitem',
      { page: this.BUYITEMcurrentresppage, filtertype: this.BUYITEMfiltertype, filtertypelr: this.BUYITEMfilterlr }
    );
    this.BUYITEMshopdata = shopdata;

    this.BUYITEMresptotalpage = shopdata['totalpage'];
    //this.plog.log("xxx1", this.shopitemlist);
  }

  async BUYITEM_close(wh) {

    wh.BUYITEMcontainer_item.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.BUYITEMcontainer_item);
    for (const k in this.wh.BUYITEMcontainer_item.list) {
      try {
        await this.wh.BUYITEMcontainer_item.remove(this.wh.BUYITEMcontainer_item.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! BUYITEMcontainer_item.destroy() ", e);
      }
    }

    for (const k in this.wh.BUYITEMcontainer_item.list) {
      try {
        await this.wh.BUYITEMcontainer_item.remove(this.wh.BUYITEMcontainer_item.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! BUYITEMcontainer_item.destroy() ", e);
      }
    }
    this.wh.BUYITEMcontainer_item = undefined;

  }

  BUYITEM_itemclick(wh, item) {
    this.BUYITEMselectcircle.visible = true;
    var namea = item.name.split('-');
    wh.registry.list.merchant.BUYITEMselecteditemno = namea[1];
    this.plog.log('showinventory_itemclick', wh, item);
    this.BUYITEMselectcircle.displayWidth = 48;
    this.BUYITEMselectcircle.displayHeight = 48;
    this.BUYITEMselectcircle.setPosition(item.x, item.y);
    //wh.BUYITEMcontainer_item.sendToBack(wh.shopguibg);
    wh.BUYITEMcontainer_item.bringToTop(this.BUYITEMselectcircle);
    wh.BUYITEMcontainer_item.bringToTop(this.BUYITEMitemlist[namea[2]]);
    wh.BUYITEMcontainer_item.bringToTop(this.BUYITEMitemlistp[namea[2]]);
    wh.BUYITEMcontainer_item.bringToTop(this.BUYITEMitemlistpp[namea[2]]);
    wh.BUYITEMcontainer_item.bringToTop(this.BUYITEMitemlistpplus[namea[2]]);
    wh.BUYITEMcontainer_item.bringToTop(this.BUYITEMitemlistslr[namea[2]]);
    this.plog.log(
      'showinventory_itemclick selectcircle',
      namea,
      item,
      wh.registry.list.rcvarpass.activechar
    );
    var iteminfo = this.BUYITEMshopdata['item'][namea[1]];
    var iteminfo2 = wh.registry.list.rcvarpass.allitem[namea[0]];

    //this.selectcircle.width=
    //this.itemdescr.setText(iteminfo2["name"] + " x" + iteminfo["amnt"]);
    //this.plog.log("xxxx",this);
    /*if (wh.registry.list.rcvarpass.activechar["map"] == "home") {
      this.BUYITEMitemdescr.setText(iteminfo2["name"] + " deposit to home inventory");
    } else {
      if (this.invenmode == "sell") {
        this.itemdescr.setText(iteminfo2["name"] + " sell for " + iteminfo["sellprice"] + " gold");
      } else {
        this.itemdescr.setText(iteminfo2["name"]);
      }
    }*/
    this.rcosd.show(wh, 'itemnoeq', iteminfo2, iteminfo);
  }

  async BUYITEM_buy(wh) {
    this.plog.log('BUYITEM_buy', wh, Number(this.BUYITEMselecteditemno));
    if (this.BUYITEMselecteditemno == '') return;
    if (this.BUYITEMshopdata['item'][this.BUYITEMselecteditemno] == undefined) {
      this.plog.log(
        'BUYITEM_buy skip dropped',
        this.BUYITEMselecteditemno,
        wh.rcvarsservice.activechar['item'][this.BUYITEMselecteditemno]
      );
      return; //dropped
    }
    var tmpid = this.BUYITEMselecteditemno;
    var tmpitem = this.BUYITEMshopdata['item'][this.BUYITEMselecteditemno]['item'];
    var tmprarity = this.BUYITEMshopdata['item'][this.BUYITEMselecteditemno]['rarity'];
    var tmpitemprice = this.BUYITEMshopdata['item'][this.BUYITEMselecteditemno]['price'];
    var tmpitemplus = this.BUYITEMshopdata['item'][this.BUYITEMselecteditemno]['itemplus'];
    var iteminfo2 = wh.rcvarsservice.allitem[tmpitem];
    var tmpcurrency = this.BUYITEMshopdata['item'][this.BUYITEMselecteditemno]['currency'];

    if (tmpcurrency == "" || tmpcurrency == undefined) {
      tmpcurrency = "gold";
    }
    var currencyname = this.wh.registry.list.rcvarpass.allstackitem[tmpcurrency]['name'];
    var addname = "";
    if (tmprarity != undefined && tmprarity != "") {
      if (tmprarity == "rar") {
        addname = " [RARE ITEM]";
      }
      if (tmprarity == "leg") {
        addname = " [LEGENDARY ITEM]";
      }
      if (tmprarity == "ult") {
        addname = " [ULTIMATE ITEM]";
      }
      if (tmprarity == "rel") {
        addname = " [RELIC ITEM]";
      }
    }
    var confirm =
      await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
        this.wh.trs("f:merchant:Confirm Buying===Confirm Buying"),
        this.wh.trs("f:merchant:Buy this items?===Buy this items?") + ':\n' +
        iteminfo2['name'] + addname +
        ' lv.' +
        iteminfo2['level'] +
        ' +' +
        tmpitemplus +
        '\nWith ' +
        tmpitemprice +
        ' ' + currencyname + ''
      );
    this.plog.log('rcconfirmresult', confirm);
    if (confirm != 'ok') return;
    this.plog.log('BUYITEM_buy', wh.rcvarsservice.activechar);
    //var res = await this.rchttp.dropitem(tmpid);
    //await this.rcvarsservice.setactivechar(res, wh.rcvarsservice.activechar["id"]);
    //this.plog.log("BUYITEM_buy res", res, wh.rcvarsservice);
    //this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(this.rcvarsservice.gameobj.scene.scenes[0]);
    var shopdata = await this.wh.registry.list.rchttp.getcommon2(
      'merchantbuyitem_buy',
      { itemid: tmpid }
    );

    await this.rchttp.updatecharinfo();
    this.BUYITEMselecteditemno = '';
    await this.BUYITEM_showpage(wh, 1);
    this.BUYITEM_loaded(wh);
  }
  ///////////////////////////////////////////////////////////////////
  BUYSTACKITEMtitle;
  async BUYSTACKITEM(wh) {
    this.wh = wh;
    this.BUYSTACKITEMcurrentresppage = 1;

    this.BUYSTACKITEMshopdata = await wh.registry.list.rchttp.getcommon2(
      'merchantbuystackitem',
      {}
    );
    this.plog.log('BUYSTACKITEMshopdata', wh.BUYSTACKITEMshopdata);
    if (wh.BUYSTACKITEMcontainer_item != undefined) {
      await this.BUYSTACKITEM_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('BUYSTACKITEM', wh, this.BUYSTACKITEMshopdata);


    this.BUYSTACKITEMwinguiltab = wh.add.image(0, 0, 'winguiltab');
    this.BUYSTACKITEMwinguiltab.setOrigin(0.5, 0.5);
    this.BUYSTACKITEMwinguiltab.displayWidth = this.BUYSTACKITEMwinguiltab.displayHeight = 52;
    this.BUYSTACKITEMwinguiltab.visible = false;

    const selectcircle = wh.add.sprite(0, 0, "selectcircle").play("selected_b");
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, 'blankrowguibg');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.BUYSTACKITEMguibg = shopguibg;
    const shopbackbtn = wh.add.sprite(0, 0, "thesb", 'backsign');
    shopbackbtn.setOrigin(0.5, 0.5);
    shopbackbtn.setInteractive();
    shopbackbtn.removeAllListeners();
    const shopfwdbtn = wh.add.sprite(0, 0, "thesb", 'forwardsign');
    shopfwdbtn.setOrigin(0.5, 0.5);
    shopfwdbtn.setInteractive();
    shopfwdbtn.removeAllListeners();
    this.plog.log('BUYSTACKITEM var', this.rcvarsservice);
    const buybtn = wh.add.sprite(0, 0, "thesb", 'buy');
    buybtn.setOrigin(0.5, 0.5);
    buybtn.setInteractive();
    buybtn.removeAllListeners();
    this.BUYSTACKITEMfwdbtn = shopfwdbtn;
    this.BUYSTACKITEMbackbtn = shopbackbtn;
    this.BUYSTACKITEMselectcircle = selectcircle;
    this.BUYSTACKITEMbuybtn = buybtn;

    var shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.BUYSTACKITEMclosebtn = shopclosebtn;
    this.BUYSTACKITEMclosebtn.setOrigin(0.5, 0.5);
    this.BUYSTACKITEMclosebtn.setPosition(165, -200);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.BUYSTACKITEMclosebtn.setInteractive();
    this.BUYSTACKITEMclosebtn.removeAllListeners();
    this.BUYSTACKITEMclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.BUYSTACKITEM_close(wh);
      },
      this
    );

    wh.BUYSTACKITEMcontainer_item = wh.add.container(300, 300);
    wh.BUYSTACKITEMcontainer_item._refsrv = this;
    wh.BUYSTACKITEMcontainer_item.name = "BUYSTACKITEMcontainer_item";
    wh.BUYSTACKITEMcontainer_item.add(this.BUYSTACKITEMclosebtn);
    wh.BUYSTACKITEMcontainer_item.add(selectcircle);
    wh.BUYSTACKITEMcontainer_item.add(shopguibg);
    wh.BUYSTACKITEMcontainer_item.add(shopbackbtn);
    wh.BUYSTACKITEMcontainer_item.add(shopfwdbtn);
    wh.BUYSTACKITEMcontainer_item.add(buybtn);
    wh.BUYSTACKITEMcontainer_item.add(this.BUYSTACKITEMwinguiltab);

    this.BUYSTACKITEMtitle = wh.add.text(0, 0, 'STACKS', wh.fs["winguititle42"]);
    wh.BUYSTACKITEMcontainer_item.add(this.BUYSTACKITEMtitle);
    wh.BUYSTACKITEMcontainer_item.bringToTop(this.BUYSTACKITEMtitle);
    this.BUYSTACKITEMtitle.setPosition(0 - this.BUYSTACKITEMtitle.displayWidth / 2, -225);

    this.emptyBUYSTACKITEMcontainer_item = wh.add.image(300, 300, 'empty');
    this.emptyBUYSTACKITEMcontainer_item.setOrigin(0.5, 0.5);
    this.emptyBUYSTACKITEMcontainer_item.setPosition(0, 0);
    this.emptyBUYSTACKITEMcontainer_item.displayWidth = this.emptyBUYSTACKITEMcontainer_item.displayHeight = 300;
    wh.BUYSTACKITEMcontainer_item.add(this.emptyBUYSTACKITEMcontainer_item);
    wh.BUYSTACKITEMcontainer_item.bringToTop(this.emptyBUYSTACKITEMcontainer_item);
    this.emptyBUYSTACKITEMcontainer_item.visible = false;

    shopbackbtn.displayWidth = 100;
    shopfwdbtn.displayWidth = 100;
    buybtn.displayWidth = 100;
    shopbackbtn.displayHeight = 40;
    shopfwdbtn.displayHeight = 40;
    buybtn.displayHeight = 40;
    shopbackbtn.setPosition(-100, 220);
    shopfwdbtn.setPosition(100, 220);
    buybtn.setPosition(0, 220);

    wh.BUYSTACKITEMcontainer_item.bringToTop(this.BUYSTACKITEMclosebtn);
    var itemdescr = wh.add.text(0, 0, this.wh.trs("f:merchant:Click Item to View Description===Click Item to View Description"), wh.fs['winguilist_descr']);
    wh.BUYSTACKITEMcontainer_item.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.BUYSTACKITEMitemdescr = itemdescr;
    this.BUYSTACKITEMinvensize = wh.add.text(0, 0, '0/0', {
      fontFamily: 'ffff,uuuu',
      fontSize: '12px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    wh.BUYSTACKITEMcontainer_item.add(this.BUYSTACKITEMinvensize);
    this.BUYSTACKITEMinvensize.setOrigin(1, 1);
    this.BUYSTACKITEMinvensize.setPosition(150, -147);

    //drag

    var shopdragbtn = wh.add.image(300, 300, 'spacer');
    wh.shopdragbtn = shopdragbtn;
    wh.shopdragbtn.setOrigin(0.5, 0.5);
    wh.shopdragbtn.setPosition(0, -200);
    shopdragbtn.displayWidth = 270;
    shopdragbtn.displayHeight = 60;
    wh.shopdragbtn.setInteractive();
    wh.shopdragbtn.removeAllListeners();
    wh.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item
        );

        this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.x;
        this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.y;
        this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.dragactivepy = 0;
    wh.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.BUYSTACKITEM_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item.y = pointer.y;
        }
      },
      this
    );
    wh.BUYSTACKITEMcontainer_item.add(wh.shopdragbtn);
    wh.BUYSTACKITEMcontainer_item.bringToTop(wh.shopdragbtn);

    this.plog.log(
      'BUYSTACKITEMcontainer_item x',
      wh.BUYSTACKITEMcontainer_item,
      width,
      height
    );
    wh.BUYSTACKITEMcontainer_item.setPosition(width / 2, height / 2);
    //move
    wh.BUYSTACKITEMcontainer_item.x = width - this.BUYSTACKITEMguibg.width / 2;

    let loader = new Phaser.Loader.LoaderPlugin(wh);

    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    var loadlist = ['stackitemtypeico'];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }
    this.wh.camman(this.wh);
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);

        this.BUYSTACKITEM_showpage(wh, "1");
        this.BUYSTACKITEM_loaded(wh);
      },
      this
    );
    loader.start();
  }
  async BUYSTACKITEM_loaded(wh, refocus = true) {
    this.BUYSTACKITEMbuybtn.visible = true;

    var griddat = [];
    var griddatf = [];

    this.plog.log('BUYSTACKITEM_loaded', wh, this.BUYSTACKITEMshopdata);
    if (refocus == true) {
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
        this.rcvarsservice.gameobj.scene.scenes[0].BUYSTACKITEMcontainer_item
      );
    }

    var paddingl = -175;
    var paddingt = -140;
    //var stackarr = [];


    this.BUYSTACKITEMstackarr['util'] = ['util'];
    //this.BUYSTACKITEMstackarr['currency'] = ['currency'];
    this.BUYSTACKITEMstackarr['chest'] = ['chest'];
    this.BUYSTACKITEMstackarr['egg'] = ['egg'];
    this.BUYSTACKITEMstackarr['questitems'] = ['questitems'];

    this.BUYSTACKITEMstackarr['upgradeitem'] = ['upgradeitem'];
    this.BUYSTACKITEMstackarr['skillbook'] = ['skillbook'];
    this.BUYSTACKITEMstackarr['scrolls'] = ['scrolls'];
    this.BUYSTACKITEMstackarr['rune'] = ['rune'];
    this.BUYSTACKITEMstackarr['gems'] = ['gems'];

    if (this.BUYSTACKITEMsubmn.length == 0) {
      var paddingts = paddingt + 0;
      var iconwhsub = 30;
      var rowh = 40;
      var i = 0;
      for (var k in this.BUYSTACKITEMstackarr) {
        var v = this.BUYSTACKITEMstackarr[k];
        //this.plog.log('xxxxx', this.wh, k, v, i);

        this.BUYSTACKITEMsubmn[k] = this.wh.add.sprite(
          0,
          0,
          'stackitemtypeico',
          k,
          iconwhsub * i + 2 + paddingts
        );
        this.wh.BUYSTACKITEMcontainer_item.add(this.BUYSTACKITEMsubmn[k]);
        this.wh.BUYSTACKITEMcontainer_item.bringToTop(this.BUYSTACKITEMsubmn[k]);
        this.BUYSTACKITEMsubmn[k].displayWidth = this.BUYSTACKITEMsubmn[k].displayHeight =
          iconwhsub;
        this.BUYSTACKITEMsubmn[k].setPosition(
          paddingl,
          paddingt + ((iconwhsub + 3) * i + 30)
        );
        paddingts = paddingts + rowh;
        this.BUYSTACKITEMsubmn[k].removeAllListeners();
        this.BUYSTACKITEMsubmn[k].setInteractive();
        this.BUYSTACKITEMsubmn[k].name = k;
        this.BUYSTACKITEMsubmn[k].on('pointerup', async function () {
          //this.plog.log('xxxxx', this);
          //this.scene.registry.list.merchant.BUYSTACKITEMwinguiltab.setPosition(this.x - 7, this.y);

          if (
            this.scene.registry.list.merchant.BUYSTACKITEMfiltertype == this.name
          ) {
            this.scene.registry.list.merchant.BUYSTACKITEMfiltertype = '';
            //this.scene.registry.list.merchant.BUYSTACKITEMwinguiltab.visible = false;
          } else {
            this.scene.registry.list.merchant.BUYSTACKITEMfiltertype = this.name;
            //this.scene.registry.list.merchant.BUYSTACKITEMwinguiltab.visible = true;
            this.scene.BUYSTACKITEMcontainer_item.moveBelow(this.scene.registry.list.merchant.BUYSTACKITEMwinguiltab, this);
          }
          this.scene.registry.list.merchant.BUYSTACKITEMcurrentresppage = 1;

          var shopdata = await this.scene.registry.list.rchttp.getcommon2(
            'merchantbuystackitem',
            {
              page: this.scene.registry.list.merchant.BUYSTACKITEMcurrentresppage,
              filtertype: this.scene.registry.list.merchant.BUYSTACKITEMfiltertype,
            }
          );
          this.scene.registry.list.merchant.BUYSTACKITEMshopdata = shopdata;
          this.scene.registry.list.merchant.BUYSTACKITEMresptotalpage =
            shopdata['totalpage'];
          this.scene.registry.list.merchant.BUYSTACKITEM_loaded(
            this.scene.registry.list.merchant.wh
          );
        });
        this.BUYSTACKITEMsubmn[k].on('pointerover', function () {
          var str = this.name;
          if (str == 'sword') str = 'weapons';
          this.scene.registry.list.gameitf.gamemenutooltip(
            this.scene,
            this,
            this.scene.trs("t:stackitem_type:code:name:" + str + "===" + str),
            'left'
          );
        });
        griddat.push(this.BUYSTACKITEMsubmn[k]);
        if (this.BUYSTACKITEMfiltertype != "" && this.BUYSTACKITEMfiltertype == k) {
          //this.wh.registry.list.stackinven.BUYSTACKITEMwinguiltab.setPosition(this.submn[k].x - 7, this.submn[k].y);
          if (this.BUYSTACKITEMwinguiltab.visible == true) {
            try { this.BUYSTACKITEMwinguiltabani.remove(); } catch (e) { console.log(e); }
            this.BUYSTACKITEMwinguiltabani = this.wh.tweens.add(
              {
                targets: this.BUYSTACKITEMwinguiltab,
                y: this.BUYSTACKITEMsubmn[k].y,
                x: this.BUYSTACKITEMsubmn[k].x - 7,
                displayWidth: 52,
                ease: 'Power3',
                duration: 200,
                delay: 0,
                onComplete: function () {
                }
              }, this
            );
          } else {
            this.BUYSTACKITEMwinguiltab.y = this.BUYSTACKITEMsubmn[k].y;
            this.BUYSTACKITEMwinguiltab.x = this.BUYSTACKITEMsubmn[k].x - 7;
            this.BUYSTACKITEMwinguiltab.displayWidth = 1;

            try { this.BUYSTACKITEMwinguiltabani.remove(); } catch (e) { console.log(e); }
            this.BUYSTACKITEMwinguiltabani = this.wh.tweens.add(
              {
                targets: this.BUYSTACKITEMwinguiltab,
                //y: this.submn[k].y,
                displayWidth: 52,
                ease: 'Power3',
                duration: 400,
                delay: 0,
                onComplete: function () {
                }
              }, this
            );
          }
          this.BUYSTACKITEMwinguiltab.visible = true;
          this.wh.BUYSTACKITEMcontainer_item.moveBelow(this.BUYSTACKITEMwinguiltab, this.BUYSTACKITEMsubmn[k]);
        } else {

        }
        i++;
      }
    }
    if (this.BUYSTACKITEMfiltertype == "") {
      if (this.BUYSTACKITEMwinguiltab.visible == true) {
        try { this.BUYSTACKITEMwinguiltabani.remove(); } catch (e) { console.log(e); }
        this.BUYSTACKITEMwinguiltabani = this.wh.tweens.add(
          {
            targets: this.BUYSTACKITEMwinguiltab,
            displayWidth: 1,
            ease: 'Power3',
            duration: 200,
            delay: 0,
            onComplete: function () {
              //console.log("twcomplete", this);
              this.BUYSTACKITEMwinguiltab.visible = false;
              this.BUYSTACKITEMwinguiltab.displayWidth = 52;
            }.bind(this,)
          }, this
        );
      }
    }
    var margintop = -90;
    var marginleft = -117;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < this.BUYSTACKITEMrespperpage; i++) {
      if (this.BUYSTACKITEMitemlist[i] != undefined) {
        //this.plog.log(this.BUYSTACKITEMitemlist[i]);
        //this.BUYSTACKITEMitemlist[i].visible = false;
        this.BUYSTACKITEMitemlist[i].destroy();
      }
      if (this.BUYSTACKITEMitemlistp[i] != undefined) {
        this.BUYSTACKITEMitemlistp[i].destroy();
      }
      if (this.BUYSTACKITEMitemlistpp[i] != undefined) {
        this.BUYSTACKITEMitemlistpp[i].destroy();
      }
      if (this.BUYSTACKITEMitemlistslr[i] != undefined) {
        this.BUYSTACKITEMitemlistslr[i].destroy();
      }
    }
    this.plog.log('BUYSTACKITEMitemlist', this.BUYSTACKITEMitemlist, this.BUYSTACKITEMitemlistp);
    this.plog.log('pagemode', this.BUYSTACKITEMcurrentresppage);
    var pagemode = 'normal';
    if (pagemode == 'normal') {
      //this.BUYSTACKITEMcurrentresppage = 1;
    }
    if (pagemode == 'retainpage') {
      if (
        this.BUYSTACKITEMcurrentresppage == 0 ||
        this.BUYSTACKITEMcurrentresppage == undefined
      )
        this.BUYSTACKITEMcurrentresppage = 1;
    }
    //this.BUYSTACKITEMinvensize.setText('Viewing ' + countslot + '/' + countslot);

    this.BUYSTACKITEMinvensize.setStyle({
      fontFamily: 'ffff,uuuu',
      fontSize: '10px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    var i = 0;
    this.plog.log('loaded itemdatall ', this.BUYSTACKITEMshopdata);

    for (var vv in this.BUYSTACKITEMshopdata['item']) {
      var vdat = this.BUYSTACKITEMshopdata['item'][vv];
      var itemdat = this.rcvarsservice.allstackitem[vdat.item];
      this.plog.log('loaded stackitemdat ', vv, vdat, itemdat);
      //var tmp = wh.add.image(32, 32, itemdat["code"]);
      if (itemdat == undefined) continue;
      this.BUYSTACKITEMitemlist[i] = this.wh.add.sprite(
        32,
        32,
        'stackitem_tp',
        itemdat['code']
      );
      this.BUYSTACKITEMitemlist[i].displayWidth = 48;
      this.BUYSTACKITEMitemlist[i].displayHeight = 48;
      this.wh.BUYSTACKITEMcontainer_item.add(this.BUYSTACKITEMitemlist[i]);
      this.BUYSTACKITEMitemlist[i].setPosition(marginleft, margintop + crow * 59);
      //this.plog.log("xxx1",tmp);
      this.BUYSTACKITEMitemlistp[i] = this.wh.add.text(
        0,
        0,
        itemdat['name'] + ' x' + vdat['amnt'],
        {
          fontFamily: 'ffff,uuuu',
          fontSize: '11px',
          fill: '#000',
          boundsAlignH: 'center',
          boundsAlignV: 'middle',
          stroke: '#000',
          strokeThickness: 0,
        }
      );
      this.wh.BUYSTACKITEMcontainer_item.add(this.BUYSTACKITEMitemlistp[i]);
      this.BUYSTACKITEMitemlistp[i].setPosition(
        this.BUYSTACKITEMitemlist[i].x + 35,
        this.BUYSTACKITEMitemlist[i].y - 25
      );

      if (vdat['currency'] == "" || vdat['currency'] == undefined) {
        vdat['currency'] = "gold";
      }
      var currencyname = this.wh.registry.list.rcvarpass.allstackitem[vdat['currency']]['name'];
      this.BUYSTACKITEMitemlistslr[i] = this.wh.add.text(0, 0, vdat['tmpseller'], {
        fontFamily: 'ffff,uuuu',
        fontSize: '8px',
        fill: '#777777',
        boundsAlignH: 'center',
        boundsAlignV: 'middle',
        stroke: '#000',
        strokeThickness: 0,
      });
      this.BUYSTACKITEMitemlistslr[i].setOrigin(1, 0);
      this.wh.BUYSTACKITEMcontainer_item.add(this.BUYSTACKITEMitemlistslr[i]);
      this.BUYSTACKITEMitemlistslr[i].setPosition(
        this.BUYSTACKITEMitemlist[i].x + 260,
        this.BUYSTACKITEMitemlist[i].y + 5
      );
      currencyname = currencyname.replace("Soul Crystal", "Souls");
      this.BUYSTACKITEMitemlistpp[i] = this.wh.add.text(0, 0, this.wh.registry.list.gameitf.humannumbercomma(vdat['price'], 0) + ' ' + currencyname, {
        fontFamily: 'ffff,uuuu',
        fontSize: '12px',
        fill: '#e0ad02',
        boundsAlignH: 'center',
        boundsAlignV: 'middle',
        stroke: '#000',
        strokeThickness: 2,
      });
      this.wh.BUYSTACKITEMcontainer_item.add(this.BUYSTACKITEMitemlistpp[i]);
      this.BUYSTACKITEMitemlistpp[i].setPosition(
        this.BUYSTACKITEMitemlist[i].x + 35,
        this.BUYSTACKITEMitemlist[i].y - 2
      );
      this.BUYSTACKITEMitemlist[i].visible = true;
      this.BUYSTACKITEMitemlistp[i].visible = true;
      this.BUYSTACKITEMitemlistpp[i].visible = true;
      this.BUYSTACKITEMitemlistslr[i].visible = true;
      this.BUYSTACKITEMitemlist[i].setInteractive();
      this.BUYSTACKITEMitemlist[i].removeAllListeners();
      this.BUYSTACKITEMitemlist[i].name =
        '' + vdat['item'] + '-' + vdat['id'] + '-' + i;
      this.BUYSTACKITEMitemlist[i].on(
        'pointerup',
        function (pointer, localX, localY, event) {
          this.scene.plog.log('shop itemclick this', this);
          this.scene.plog.log('shop itemclick', pointer, localX, localY, event);
          this.scene.registry.list.merchant.BUYSTACKITEM_itemclick(this.scene, this);
        }
      );
      griddat.push(this.BUYSTACKITEMitemlist[i]);
      griddatf.push(this.BUYSTACKITEMitemlist[i]);
      crow++;

      i++;
    }
    if (i == 0) {
      this.emptyBUYSTACKITEMcontainer_item.visible = true;
    } else {
      this.emptyBUYSTACKITEMcontainer_item.visible = false;
    }
    this.plog.log('shop i count', i);
    if (i > 25) {
      //pages
      this.BUYSTACKITEMresptotalpage = Math.ceil(i / this.BUYSTACKITEMrespperpage);
    }
    this.BUYSTACKITEMbackbtn.removeAllListeners();
    this.BUYSTACKITEMfwdbtn.removeAllListeners();
    this.BUYSTACKITEMbackbtn.on(
      'pointerup',
      async function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        await this.BUYSTACKITEM_showpage(wh, 'back');
        this.BUYSTACKITEM_loaded(this.wh);
      },
      this
    );
    this.BUYSTACKITEMfwdbtn.on(
      'pointerup',
      async function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        await this.BUYSTACKITEM_showpage(wh, 'next');
        this.BUYSTACKITEM_loaded(this.wh);
      },
      this
    );
    //this.BUYSTACKITEM_showpage(wh, 'current');

    this.BUYSTACKITEMbuybtn.removeAllListeners();
    this.BUYSTACKITEMbuybtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop buybtn', this);
        this.BUYSTACKITEM_buy(this);
      },
      this
    );

    var numfrom = this.BUYSTACKITEMrespperpage * (this.BUYSTACKITEMcurrentresppage - 1);
    numfrom = numfrom + 1;
    var numto = this.BUYSTACKITEMrespperpage * this.BUYSTACKITEMcurrentresppage;

    this.BUYSTACKITEMinvensize.setText(
      'Viewing ' +
      numfrom +
      '-' +
      numto +
      '/' +
      this.BUYSTACKITEMshopdata['allrecord']
    );

    griddat.push(this.BUYSTACKITEMbackbtn);
    griddat.push(this.BUYSTACKITEMbuybtn);
    griddat.push(this.BUYSTACKITEMfwdbtn);
    griddat.push(this.BUYSTACKITEMclosebtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }
  async BUYSTACKITEM_showpage(gc, viewpage) {
    this.plog.log('BUYSTACKITEM_showpage', gc, viewpage);
    this.BUYSTACKITEMselectcircle.visible = false;
    if (viewpage == 'back') {
      if (this.BUYSTACKITEMcurrentresppage > 1) this.BUYSTACKITEMcurrentresppage--;
    }
    if (viewpage + '' == '1') {
      this.BUYSTACKITEMcurrentresppage = 1;
    }
    if (viewpage == 'next') {
      if (this.BUYSTACKITEMcurrentresppage < this.BUYSTACKITEMresptotalpage)
        this.BUYSTACKITEMcurrentresppage++;
    }
    for (var i = 0; i <= this.BUYSTACKITEMrespperpage; i++) {
      if (this.BUYSTACKITEMitemlist[i] != undefined) {
        this.BUYSTACKITEMitemlist[i].visible = false;
      }
      if (this.BUYSTACKITEMitemlistp[i] != undefined) {
        this.BUYSTACKITEMitemlistp[i].visible = false;
      }
    }
    this.plog.log(
      'BUYSTACKITEM_showpage',
      viewpage,
      this.BUYSTACKITEMcurrentresppage,
      'perpage',
      this.BUYSTACKITEMrespperpage
    );

    var shopdata = await this.wh.registry.list.rchttp.getcommon2(
      'merchantbuystackitem',
      { page: this.BUYSTACKITEMcurrentresppage, filtertype: this.BUYSTACKITEMfiltertype }
    );
    this.BUYSTACKITEMshopdata = shopdata;

    this.BUYSTACKITEMresptotalpage = shopdata['totalpage'];
    //this.BUYSTACKITEM_loaded(this.wh);
    //this.plog.log("xxx1", this.shopitemlist);
  }

  async BUYSTACKITEM_close(wh) {

    wh.BUYSTACKITEMcontainer_item.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.BUYSTACKITEMcontainer_item);
    for (const k in this.wh.BUYSTACKITEMcontainer_item.list) {
      try {
        await this.wh.BUYSTACKITEMcontainer_item.remove(this.wh.BUYSTACKITEMcontainer_item.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! BUYSTACKITEMcontainer_item.destroy() ", e);
      }
    }

    for (const k in this.wh.BUYSTACKITEMcontainer_item.list) {
      try {
        await this.wh.BUYSTACKITEMcontainer_item.remove(this.wh.BUYSTACKITEMcontainer_item.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! BUYSTACKITEMcontainer_item.destroy() ", e);
      }
    }
    this.wh.BUYSTACKITEMcontainer_item = undefined;
  }

  BUYSTACKITEM_itemclick(wh, item) {
    this.BUYSTACKITEMselectcircle.visible = true;
    var namea = item.name.split('-');
    wh.registry.list.merchant.BUYSTACKITEMselecteditemno = namea[1];
    this.plog.log('showinventory_itemclick', wh, item);
    this.BUYSTACKITEMselectcircle.displayWidth = 48;
    this.BUYSTACKITEMselectcircle.displayHeight = 48;
    this.BUYSTACKITEMselectcircle.setPosition(item.x, item.y);
    //wh.BUYSTACKITEMcontainer_item.sendToBack(wh.shopguibg);
    wh.BUYSTACKITEMcontainer_item.bringToTop(this.BUYSTACKITEMselectcircle);
    wh.BUYSTACKITEMcontainer_item.bringToTop(this.BUYSTACKITEMitemlist[namea[2]]);
    wh.BUYSTACKITEMcontainer_item.bringToTop(this.BUYSTACKITEMitemlistp[namea[2]]);
    wh.BUYSTACKITEMcontainer_item.bringToTop(this.BUYSTACKITEMitemlistpp[namea[2]]);
    wh.BUYSTACKITEMcontainer_item.bringToTop(this.BUYSTACKITEMitemlistslr[namea[2]]);
    this.plog.log(
      'showinventory_itemclick selectcircle',
      namea,
      item,
      wh.registry.list.rcvarpass.activechar
    );
    var iteminfo = this.BUYSTACKITEMshopdata['item'][namea[1]];
    var iteminfo2 = wh.registry.list.rcvarpass.allstackitem[namea[0]];

    //this.selectcircle.width=
    //this.itemdescr.setText(iteminfo2["name"] + " x" + iteminfo["amnt"]);
    //this.plog.log("xxxx",this);
    /*if (wh.registry.list.rcvarpass.activechar["map"] == "home") {
      this.BUYSTACKITEMitemdescr.setText(iteminfo2["name"] + " deposit to home inventory");
    } else {
      if (this.invenmode == "sell") {
        this.itemdescr.setText(iteminfo2["name"] + " sell for " + iteminfo["sellprice"] + " gold");
      } else {
        this.itemdescr.setText(iteminfo2["name"]);
      }
    }*/
    this.rcosd.show(wh, 'stackitem', iteminfo2, iteminfo);
  }

  async BUYSTACKITEM_buy(wh) {
    this.plog.log('BUYSTACKITEM_buy', wh, Number(this.BUYSTACKITEMselecteditemno));
    if (this.BUYSTACKITEMselecteditemno == '') return;
    if (this.BUYSTACKITEMshopdata['item'][this.BUYSTACKITEMselecteditemno] == undefined) {
      this.plog.log(
        'BUYSTACKITEM_buy skip dropped',
        this.BUYSTACKITEMselecteditemno,
        wh.rcvarsservice.activechar['item'][this.BUYSTACKITEMselecteditemno]
      );
      return; //dropped
    }
    var tmpid = this.BUYSTACKITEMselecteditemno;
    var tmpitem = this.BUYSTACKITEMshopdata['item'][this.BUYSTACKITEMselecteditemno]['item'];
    var tmpitemprice = this.BUYSTACKITEMshopdata['item'][this.BUYSTACKITEMselecteditemno]['price'];
    var tmpitemamnt = this.BUYSTACKITEMshopdata['item'][this.BUYSTACKITEMselecteditemno]['amnt'];
    var iteminfo2 = wh.rcvarsservice.allstackitem[tmpitem];
    var tmpcurrency = this.BUYSTACKITEMshopdata['item'][this.BUYSTACKITEMselecteditemno]['currency'];

    if (tmpcurrency == "" || tmpcurrency == undefined) {
      tmpcurrency = "gold";
    }
    var currencyname = this.wh.registry.list.rcvarpass.allstackitem[tmpcurrency]['name'];

    //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Drop", "<p>Remove this items?</p><p>" + iteminfo2["name"] + " lv." + iteminfo2["level"] + "</p>");
    var confirm =
      await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
        this.wh.trs("f:merchant:Confirm Buying===Confirm Buying"),
        this.wh.trs("f:merchant:Buy this items?===Buy this items?") + ':\n\n' +
        iteminfo2['name'] +
        ' x' +
        tmpitemamnt +
        '\nWith ' +
        tmpitemprice +
        ' ' + currencyname
      );
    this.plog.log('rcconfirmresult', confirm);
    if (confirm != 'ok') return;
    this.plog.log('BUYSTACKITEM_buy', wh.rcvarsservice.activechar);
    //var res = await this.rchttp.dropitem(tmpid);
    //await this.rcvarsservice.setactivechar(res, wh.rcvarsservice.activechar["id"]);
    //this.plog.log("BUYSTACKITEM_buy res", res, wh.rcvarsservice);
    //this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(this.rcvarsservice.gameobj.scene.scenes[0]);
    var shopdata = await this.wh.registry.list.rchttp.getcommon2(
      'merchantbuystackitem_buy',
      { itemid: tmpid }
    );

    await this.rchttp.updatecharinfo();
    this.BUYSTACKITEMselecteditemno = '';
    await this.BUYSTACKITEM_showpage(wh, 1);
    this.BUYSTACKITEM_loaded(wh);
  }

  ///////////////////////////////////////////////////////////////////
  VIEWMYtitle;
  VIEWMYwinguiltab
  async VIEWMY(wh) {
    this.wh = wh;
    this.VIEWMYcurrentresppage = 1;

    this.VIEWMYshopdata = await wh.registry.list.rchttp.getcommon2(
      'merchantviewmyitem',
      {}
    );
    this.plog.log('VIEWMYshopdata', wh.VIEWMYshopdata);
    if (wh.VIEWMYcontainer_item != undefined) {
      await this.VIEWMY_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('VIEWMY', wh, this.VIEWMYshopdata);

    const selectcircle = wh.add.sprite(0, 0, "selectcircle").play("selected_b");
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, 'blankrowguibg');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.VIEWMYguibg = shopguibg;
    const shopbackbtn = wh.add.sprite(0, 0, "thesb", 'backsign');
    shopbackbtn.setOrigin(0.5, 0.5);
    shopbackbtn.setInteractive();
    shopbackbtn.removeAllListeners();
    const shopfwdbtn = wh.add.sprite(0, 0, "thesb", 'forwardsign');
    shopfwdbtn.setOrigin(0.5, 0.5);
    shopfwdbtn.setInteractive();
    shopfwdbtn.removeAllListeners();
    this.plog.log('VIEWMY var', this.rcvarsservice);
    const buybtn = wh.add.sprite(0, 0, "thesb", 'cancel');
    buybtn.setOrigin(0.5, 0.5);
    buybtn.setInteractive();
    buybtn.removeAllListeners();
    this.VIEWMYfwdbtn = shopfwdbtn;
    this.VIEWMYbackbtn = shopbackbtn;
    this.VIEWMYselectcircle = selectcircle;
    this.VIEWMYbuybtn = buybtn;

    var shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.VIEWMYclosebtn = shopclosebtn;
    this.VIEWMYclosebtn.setOrigin(0.5, 0.5);
    this.VIEWMYclosebtn.setPosition(165, -200);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.VIEWMYclosebtn.setInteractive();
    this.VIEWMYclosebtn.removeAllListeners();
    this.VIEWMYclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.VIEWMY_close(wh);
      },
      this
    );

    wh.VIEWMYcontainer_item = wh.add.container(300, 300);
    wh.VIEWMYcontainer_item._refsrv = this;
    wh.VIEWMYcontainer_item.name = "VIEWMYcontainer_item";
    wh.VIEWMYcontainer_item.add(this.VIEWMYclosebtn);
    wh.VIEWMYcontainer_item.add(selectcircle);
    wh.VIEWMYcontainer_item.add(shopguibg);
    wh.VIEWMYcontainer_item.add(shopbackbtn);
    wh.VIEWMYcontainer_item.add(shopfwdbtn);
    wh.VIEWMYcontainer_item.add(buybtn);

    this.VIEWMYwinguiltab = wh.add.image(0, 0, 'winguiltab');
    this.VIEWMYwinguiltab.setOrigin(0.5, 0.5);
    this.VIEWMYwinguiltab.displayWidth = this.VIEWMYwinguiltab.displayHeight = 52;
    this.VIEWMYwinguiltab.visible = false;
    wh.VIEWMYcontainer_item.add(this.VIEWMYwinguiltab);
    wh.VIEWMYcontainer_item.bringToTop(this.VIEWMYwinguiltab);

    this.VIEWMYtitle = wh.add.text(0, 0, 'MY LISTINGS', wh.fs["winguititle42"]);
    wh.VIEWMYcontainer_item.add(this.VIEWMYtitle);
    wh.VIEWMYcontainer_item.bringToTop(this.VIEWMYtitle);
    this.VIEWMYtitle.setPosition(0 - this.VIEWMYtitle.displayWidth / 2, -225);

    this.emptyVIEWMYcontainer_item = wh.add.image(300, 300, 'empty');
    this.emptyVIEWMYcontainer_item.setOrigin(0.5, 0.5);
    this.emptyVIEWMYcontainer_item.setPosition(0, 0);
    this.emptyVIEWMYcontainer_item.displayWidth = this.emptyVIEWMYcontainer_item.displayHeight = 300;
    wh.VIEWMYcontainer_item.add(this.emptyVIEWMYcontainer_item);
    wh.VIEWMYcontainer_item.bringToTop(this.emptyVIEWMYcontainer_item);
    this.emptyVIEWMYcontainer_item.visible = false;

    shopbackbtn.displayWidth = 100;
    shopfwdbtn.displayWidth = 100;
    buybtn.displayWidth = 100;
    shopbackbtn.displayHeight = 40;
    shopfwdbtn.displayHeight = 40;
    buybtn.displayHeight = 40;
    shopbackbtn.setPosition(-100, 220);
    shopfwdbtn.setPosition(100, 220);
    buybtn.setPosition(0, 220);

    wh.VIEWMYcontainer_item.bringToTop(this.VIEWMYclosebtn);
    var itemdescr = wh.add.text(0, 0, this.wh.trs("f:merchant:Click Item to View Description===Click Item to View Description"), wh.fs['winguilist_descr']);
    wh.VIEWMYcontainer_item.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.VIEWMYitemdescr = itemdescr;
    this.VIEWMYinvensize = wh.add.text(0, 0, '0/0', {
      fontFamily: 'ffff,uuuu',
      fontSize: '12px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    wh.VIEWMYcontainer_item.add(this.VIEWMYinvensize);
    this.VIEWMYinvensize.setOrigin(1, 1);
    this.VIEWMYinvensize.setPosition(150, -147);

    //drag

    var shopdragbtn = wh.add.image(300, 300, 'spacer');
    wh.shopdragbtn = shopdragbtn;
    wh.shopdragbtn.setOrigin(0.5, 0.5);
    wh.shopdragbtn.setPosition(0, -200);
    shopdragbtn.displayWidth = 270;
    shopdragbtn.displayHeight = 60;
    wh.shopdragbtn.setInteractive();
    wh.shopdragbtn.removeAllListeners();
    wh.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item
        );

        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.x;
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.y;
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.dragactivepy = 0;
    wh.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.VIEWMY_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item.y = pointer.y;
        }
      },
      this
    );
    wh.VIEWMYcontainer_item.add(wh.shopdragbtn);
    wh.VIEWMYcontainer_item.bringToTop(wh.shopdragbtn);

    this.plog.log(
      'VIEWMYcontainer_item x',
      wh.VIEWMYcontainer_item,
      width,
      height
    );
    wh.VIEWMYcontainer_item.setPosition(width / 2, height / 2);
    //move
    wh.VIEWMYcontainer_item.x = width - this.VIEWMYguibg.width / 2;

    let loader = new Phaser.Loader.LoaderPlugin(wh);

    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    var loadlist = ['itemtypeico'];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }
    this.wh.camman(this.wh);
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.VIEWMY_showpage(wh, "1");
        this.VIEWMY_loaded(wh);
      },
      this
    );
    loader.start();
  }
  async VIEWMY_loaded(wh, refocus = true) {
    this.VIEWMYbuybtn.visible = true;
    this.plog.log('VIEWMY_loaded', wh, this.VIEWMYshopdata);

    var griddat = [];
    var griddatf = [];

    if (refocus == true) {
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYcontainer_item
      );
    }

    var paddingl = -175;
    var paddingt = -140;
    //var stackarr = [];


    this.VIEWMYstackarr['sword'] = [
      'sword',
      'longsword',
      'axe',
      'longaxe',
      'wand',
      'bow',
      'dagger',
    ];
    /*this.BUYITEMstackarr['longsword'] = ['longsword'];
    this.BUYITEMstackarr['axe'] = ['axe'];
    this.BUYITEMstackarr['longaxe'] = ['longaxe'];
    this.BUYITEMstackarr['wand'] = ['wand'];
    this.BUYITEMstackarr['bow'] = ['bow'];
    this.BUYITEMstackarr['dagger'] = ['dagger'];*/
    this.VIEWMYstackarr['shield'] = ['shield'];
    this.VIEWMYstackarr['accessories'] = [
      'ring',
      'earring',
      'necklace',
      'bracelet',
    ];
    this.VIEWMYstackarr['boots'] = [
      'strboots',
      'chaboots',
      'dexboots',
      'conboots',
    ];
    this.VIEWMYstackarr['gloves'] = [
      'strgloves',
      'dexgloves',
      'chagloves',
      'dexarmor',
    ];
    this.VIEWMYstackarr['armor'] = [
      'strarmor',
      'conarmor',
      'chaarmor',
      'conboots',
    ];
    this.VIEWMYstackarr['pant'] = [
      'strpants',
      'conpants',
      'chapants',
      'dexpants',
    ];
    this.VIEWMYstackarr['helmet'] = [
      'strhelmet',
      'conhelmet',
      'chahelmet',
      'dexhelmet',
    ];

    if (this.VIEWMYsubmn.length == 0) {
      var paddingts = paddingt + 0;
      var iconwhsub = 30;
      var rowh = 40;
      var i = 0;
      for (var k in this.VIEWMYstackarr) {
        var v = this.VIEWMYstackarr[k];
        //this.plog.log('xxxxx', this.wh, k, v, i);

        this.VIEWMYsubmn[k] = this.wh.add.sprite(
          0,
          0,
          'itemtypeico',
          k,
          iconwhsub * i + 2 + paddingts
        );
        this.wh.VIEWMYcontainer_item.add(this.VIEWMYsubmn[k]);
        this.wh.VIEWMYcontainer_item.bringToTop(this.VIEWMYsubmn[k]);
        this.VIEWMYsubmn[k].displayWidth = this.VIEWMYsubmn[k].displayHeight =
          iconwhsub;
        this.VIEWMYsubmn[k].setPosition(
          paddingl,
          paddingt + ((iconwhsub + 3) * i + 65)
        );
        paddingts = paddingts + rowh;
        this.VIEWMYsubmn[k].setInteractive();
        this.VIEWMYsubmn[k].removeAllListeners();
        this.VIEWMYsubmn[k].name = k;
        this.VIEWMYsubmn[k].on('pointerup', async function () {
          //this.plog.log('xxxxx', this);
          this.scene.registry.list.merchant.VIEWMYwinguiltab.setPosition(this.x - 7, this.y);
          if (
            this.scene.registry.list.merchant.VIEWMYfiltertype == this.name
          ) {
            this.scene.registry.list.merchant.VIEWMYfiltertype = '';
            ;
            this.scene.registry.list.merchant.VIEWMYwinguiltab.visible = false;
          } else {
            this.scene.registry.list.merchant.VIEWMYfiltertype = this.name;
            this.scene.registry.list.merchant.VIEWMYwinguiltab.visible = true;
            this.scene.VIEWMYcontainer_item.moveBelow(this.scene.registry.list.merchant.VIEWMYwinguiltab, this);
          }
          this.scene.registry.list.merchant.VIEWMYcurrentresppage = 1;

          var shopdata = await this.scene.registry.list.rchttp.getcommon2(
            'merchantviewmyitem',
            {
              page: this.scene.registry.list.merchant.VIEWMYcurrentresppage,
              filtertype: this.scene.registry.list.merchant.VIEWMYfiltertype,
            }
          );
          this.scene.registry.list.merchant.VIEWMYshopdata = shopdata;
          this.scene.registry.list.merchant.VIEWMYresptotalpage =
            shopdata['totalpage'];
          this.scene.registry.list.merchant.VIEWMY_loaded(
            this.scene.registry.list.merchant.wh
          );
        });
        this.VIEWMYsubmn[k].on('pointerover', function () {
          var str = this.name;
          if (str == 'sword') str = 'weapons';
          str = this.scene.trs("f:inventorycontainer:itemtype-" + str + "===" + str);

          this.scene.registry.list.gameitf.gamemenutooltip(
            this.scene,
            this,
            str,
            'left'
          );
        });
        griddat.push(this.VIEWMYsubmn[k]);
        i++;
      }
    }

    var margintop = -90;
    var marginleft = -117;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < this.VIEWMYrespperpage; i++) {
      if (this.VIEWMYitemlist[i] != undefined) {
        //this.plog.log(this.VIEWMYitemlist[i]);
        //this.VIEWMYitemlist[i].visible = false;
        this.VIEWMYitemlist[i].destroy();
      }
      if (this.VIEWMYitemlistp[i] != undefined) {
        this.VIEWMYitemlistp[i].destroy();
      }
      if (this.VIEWMYitemlistpp[i] != undefined) {
        this.VIEWMYitemlistpp[i].destroy();
      }
    }
    this.plog.log('VIEWMYitemlist', this.VIEWMYitemlist, this.VIEWMYitemlistp);
    this.plog.log('pagemode', this.VIEWMYcurrentresppage);
    var pagemode = 'normal';
    if (pagemode == 'normal') {
      //this.VIEWMYcurrentresppage = 1;
    }
    if (pagemode == 'retainpage') {
      if (
        this.VIEWMYcurrentresppage == 0 ||
        this.VIEWMYcurrentresppage == undefined
      )
        this.VIEWMYcurrentresppage = 1;
    }
    //this.VIEWMYinvensize.setText('Viewing ' + countslot + '/' + countslot);

    this.VIEWMYinvensize.setStyle({
      fontFamily: 'ffff,uuuu',
      fontSize: '10px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    var i = 0;
    this.plog.log('loaded itemdatall ', this.VIEWMYshopdata);

    for (var vv in this.VIEWMYshopdata['item']) {
      var vdat = this.VIEWMYshopdata['item'][vv];
      var itemdat = this.rcvarsservice.allitem[vdat.item];
      this.plog.log('loaded stackitemdat ', vv, vdat, itemdat);
      //var tmp = wh.add.image(32, 32, itemdat["code"]);
      if (itemdat == undefined) continue;
      this.VIEWMYitemlist[i] = this.wh.add.sprite(
        32,
        32,
        'item_tp',
        itemdat['code']
      );
      this.VIEWMYitemlist[i].displayWidth = 48;
      this.VIEWMYitemlist[i].displayHeight = 48;
      this.wh.VIEWMYcontainer_item.add(this.VIEWMYitemlist[i]);
      this.VIEWMYitemlist[i].setPosition(marginleft, margintop + crow * 59);
      //this.plog.log("xxx1",tmp);
      this.VIEWMYitemlistp[i] = this.wh.add.text(
        0,
        0,
        itemdat['name'] + ' +' + vdat['itemplus'],
        {
          fontFamily: 'ffff,uuuu',
          fontSize: '11px',
          fill: '#000',
          boundsAlignH: 'center',
          boundsAlignV: 'middle',
          stroke: '#000',
          strokeThickness: 0,
        }
      );
      this.wh.VIEWMYcontainer_item.add(this.VIEWMYitemlistp[i]);
      this.VIEWMYitemlistp[i].setPosition(
        this.VIEWMYitemlist[i].x + 35,
        this.VIEWMYitemlist[i].y - 25
      );

      if (vdat["currency"] == "" || vdat["currency"] == undefined) {
        vdat["currency"] = "gold";
      }
      if (vdat['currency'] == "soulcrysta") vdat['currency'] = "soulcrystal";

      var currencyname = this.wh.registry.list.rcvarpass.allstackitem[vdat["currency"]]['name'];
      currencyname = currencyname.replace("Soul Crystal", "Souls");
      this.VIEWMYitemlistpp[i] = this.wh.add.text(0, 0, this.wh.registry.list.gameitf.humannumbercomma(vdat['price'], 0) + ' ' + currencyname, {
        fontFamily: 'ffff,uuuu',
        fontSize: '12px',
        fill: '#e0ad02',
        boundsAlignH: 'center',
        boundsAlignV: 'middle',
        stroke: '#000',
        strokeThickness: 2,
      });
      this.wh.VIEWMYcontainer_item.add(this.VIEWMYitemlistpp[i]);
      this.VIEWMYitemlistpp[i].setPosition(
        this.VIEWMYitemlist[i].x + 35,
        this.VIEWMYitemlist[i].y - 2
      );
      this.VIEWMYitemlist[i].visible = true;
      this.VIEWMYitemlistp[i].visible = true;
      this.VIEWMYitemlistpp[i].visible = true;
      this.VIEWMYitemlist[i].setInteractive();
      this.VIEWMYitemlist[i].removeAllListeners();
      this.VIEWMYitemlist[i].name =
        '' + vdat['item'] + '-' + vdat['id'] + '-' + i;
      this.VIEWMYitemlist[i].on(
        'pointerup',
        function (pointer, localX, localY, event) {
          this.scene.plog.log('shop itemclick this', this);
          this.scene.plog.log('shop itemclick', pointer, localX, localY, event);
          this.scene.registry.list.merchant.VIEWMY_itemclick(this.scene, this);
        }
      );
      griddat.push(this.VIEWMYitemlist[i]);
      griddatf.push(this.VIEWMYitemlist[i]);
      crow++;

      i++;
    }
    if (i == 0) {
      this.emptyVIEWMYcontainer_item.visible = true;
    } else {
      this.emptyVIEWMYcontainer_item.visible = false;
    }
    this.plog.log('shop i count', i);
    if (i > 25) {
      //pages
      this.VIEWMYresptotalpage = Math.ceil(i / this.VIEWMYrespperpage);
    }
    this.VIEWMYbackbtn.removeAllListeners();
    this.VIEWMYfwdbtn.removeAllListeners();
    this.VIEWMYbackbtn.on(
      'pointerup',
      async function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        await this.VIEWMY_showpage(wh, 'back');
        this.VIEWMY_loaded(this.wh);
      },
      this
    );
    this.VIEWMYfwdbtn.on(
      'pointerup',
      async function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        await this.VIEWMY_showpage(wh, 'next');
        this.VIEWMY_loaded(this.wh);
      },
      this
    );
    //this.VIEWMY_showpage(wh, 'current');

    this.VIEWMYbuybtn.removeAllListeners();
    this.VIEWMYbuybtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop buybtn', arguments);
        this.VIEWMY_cancel(this);
      },
      this
    );

    var numfrom = this.VIEWMYrespperpage * (this.VIEWMYcurrentresppage - 1);
    numfrom = numfrom + 1;
    var numto = this.VIEWMYrespperpage * this.VIEWMYcurrentresppage;
    this.VIEWMYinvensize.setText(
      'Viewing ' +
      numfrom +
      '-' +
      numto +
      '/' +
      this.VIEWMYshopdata['allrecord']
    );

    griddat.push(this.VIEWMYbackbtn);
    griddat.push(this.VIEWMYbuybtn);
    griddat.push(this.VIEWMYfwdbtn);
    griddat.push(this.VIEWMYclosebtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }
  async VIEWMY_showpage(gc, viewpage) {
    this.plog.log('VIEWMY_showpage', gc, viewpage);

    this.VIEWMYselectcircle.visible = false;
    if (viewpage == 'back') {
      if (this.VIEWMYcurrentresppage > 1) this.VIEWMYcurrentresppage--;
    }
    if (viewpage + '' == '1') {
      this.VIEWMYcurrentresppage = 1;
    }
    if (viewpage == 'next') {
      if (this.VIEWMYcurrentresppage < this.VIEWMYresptotalpage)
        this.VIEWMYcurrentresppage++;
    }
    for (var i = 0; i <= this.VIEWMYrespperpage; i++) {
      if (this.VIEWMYitemlist[i] != undefined) {
        this.VIEWMYitemlist[i].visible = false;
      }
      if (this.VIEWMYitemlistp[i] != undefined) {
        this.VIEWMYitemlistp[i].visible = false;
      }
      if (this.VIEWMYitemlistpp[i] != undefined) {
        this.VIEWMYitemlistpp[i].visible = false;
      }
    }
    this.plog.log(
      'VIEWMY_showpage',
      viewpage,
      this.VIEWMYcurrentresppage,
      'perpage',
      this.VIEWMYrespperpage
    );

    var shopdata = await this.wh.registry.list.rchttp.getcommon2(
      'merchantviewmyitem',
      { page: this.VIEWMYcurrentresppage, filtertype: this.VIEWMYfiltertype }
    );
    this.VIEWMYshopdata = shopdata;

    this.VIEWMYresptotalpage = shopdata['totalpage'];
    //this.VIEWMY_loaded(this.wh);
    //this.plog.log("xxx1", this.shopitemlist);
  }

  async VIEWMY_close(wh) {
    wh.VIEWMYcontainer_item.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.VIEWMYcontainer_item);
    for (const k in this.wh.VIEWMYcontainer_item.list) {
      try {
        await this.wh.VIEWMYcontainer_item.remove(this.wh.VIEWMYcontainer_item.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! VIEWMYcontainer_item.destroy() ", e);
      }
    }

    for (const k in this.wh.VIEWMYcontainer_item.list) {
      try {
        await this.wh.VIEWMYcontainer_item.remove(this.wh.VIEWMYcontainer_item.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! VIEWMYcontainer_item.destroy() ", e);
      }
    }
    this.wh.VIEWMYcontainer_item = undefined;

  }

  VIEWMY_itemclick(wh, item) {
    this.VIEWMYselectcircle.visible = true;
    var namea = item.name.split('-');
    wh.registry.list.merchant.VIEWMYselecteditemno = namea[1];
    this.plog.log('showinventory_itemclick', wh, item);
    this.VIEWMYselectcircle.displayWidth = 48;
    this.VIEWMYselectcircle.displayHeight = 48;
    this.VIEWMYselectcircle.setPosition(item.x, item.y);
    //wh.VIEWMYcontainer_item.sendToBack(wh.shopguibg);
    wh.VIEWMYcontainer_item.bringToTop(this.VIEWMYselectcircle);
    wh.VIEWMYcontainer_item.bringToTop(this.VIEWMYitemlist[namea[2]]);
    wh.VIEWMYcontainer_item.bringToTop(this.VIEWMYitemlistp[namea[2]]);
    wh.VIEWMYcontainer_item.bringToTop(this.VIEWMYitemlistpp[namea[2]]);
    this.plog.log(
      'showinventory_itemclick selectcircle',
      namea,
      item,
      wh.registry.list.rcvarpass.activechar
    );
    var iteminfo = this.VIEWMYshopdata['item'][namea[1]];
    var iteminfo2 = wh.registry.list.rcvarpass.allitem[namea[0]];

    //this.selectcircle.width=
    //this.itemdescr.setText(iteminfo2["name"] + " x" + iteminfo["amnt"]);
    //this.plog.log("xxxx",this);
    /*if (wh.registry.list.rcvarpass.activechar["map"] == "home") {
      this.VIEWMYitemdescr.setText(iteminfo2["name"] + " deposit to home inventory");
    } else {
      if (this.invenmode == "sell") {
        this.itemdescr.setText(iteminfo2["name"] + " sell for " + iteminfo["sellprice"] + " gold");
      } else {
        this.itemdescr.setText(iteminfo2["name"]);
      }
    }*/
    this.rcosd.show(wh, 'stackitem', iteminfo2, iteminfo);
  }

  async VIEWMY_cancel(wh) {
    this.plog.log('VIEWMY_cancel', wh, Number(this.VIEWMYselecteditemno));
    if (this.VIEWMYselecteditemno == '') return;
    if (this.VIEWMYshopdata['item'][this.VIEWMYselecteditemno] == undefined) {
      this.plog.log(
        'VIEWMY_cancel skip dropped',
        this.VIEWMYselecteditemno,
        wh.rcvarsservice.activechar['item'][this.VIEWMYselecteditemno]
      );
      return; //dropped
    }
    var tmpid = this.VIEWMYselecteditemno;
    var tmpitem = this.VIEWMYshopdata['item'][this.VIEWMYselecteditemno]['item'];
    var tmpitemprice = this.VIEWMYshopdata['item'][this.VIEWMYselecteditemno]['price'];
    var tmpitemamnt = this.VIEWMYshopdata['item'][this.VIEWMYselecteditemno]['itemplus'];
    var iteminfo2 = wh.rcvarsservice.allitem[tmpitem];
    var tmpcurrency = this.VIEWMYshopdata['item'][this.VIEWMYselecteditemno]['currency'];

    //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Drop", "<p>Remove this items?</p><p>" + iteminfo2["name"] + " lv." + iteminfo2["level"] + "</p>");

    if (tmpcurrency == "" || tmpcurrency == undefined) {
      tmpcurrency = "gold";
    }
    var currencyname = this.wh.registry.list.rcvarpass.allstackitem[tmpcurrency]['name'];
    var confirm =
      await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
        'CANCEL Listing',
        this.wh.gl('Cancel selling this items?:\n') +
        iteminfo2['name'] +
        ' lv.' +
        iteminfo2['level'] +
        ' +' +
        tmpitemamnt +
        this.wh.gl('\nfor ') +
        tmpitemprice +
        ' ' + currencyname
      );
    this.plog.log('rcconfirmresult', confirm);
    if (confirm != 'ok') return;
    this.plog.log('VIEWMY_cancel', wh.rcvarsservice.activechar);
    //var res = await this.rchttp.dropitem(tmpid);
    //await this.rcvarsservice.setactivechar(res, wh.rcvarsservice.activechar["id"]);
    //this.plog.log("VIEWMY_cancel res", res, wh.rcvarsservice);
    //this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(this.rcvarsservice.gameobj.scene.scenes[0]);
    var shopdata = await this.wh.registry.list.rchttp.getcommon2(
      'merchantviewmyitem_cancel',
      { itemid: tmpid }
    );

    await this.rchttp.updatecharinfo();
    this.VIEWMYselecteditemno = '';
    await this.VIEWMY_showpage(wh, 1);
    this.VIEWMY_loaded(wh);
  }
  VIEWMYSTACKtitle;
  VIEWMYSTACKwinguiltab;

  ///////////////////////////////////////////////////////////////////
  async VIEWMYSTACK(wh) {
    this.wh = wh;
    this.VIEWMYSTACKcurrentresppage = 1;

    this.VIEWMYSTACKshopdata = await wh.registry.list.rchttp.getcommon2(
      'merchantviewmystackitem',
      {}
    );
    this.plog.log('VIEWMYSTACKshopdata', wh.VIEWMYSTACKshopdata);
    if (wh.VIEWMYSTACKcontainer_item != undefined) {
      await this.VIEWMYSTACK_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('VIEWMYSTACK', wh, this.VIEWMYSTACKshopdata);

    const selectcircle = wh.add.sprite(0, 0, "selectcircle").play("selected_b");
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, 'blankrowguibg');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.VIEWMYSTACKguibg = shopguibg;
    const shopbackbtn = wh.add.sprite(0, 0, "thesb", 'backsign');
    shopbackbtn.setOrigin(0.5, 0.5);
    shopbackbtn.setInteractive();
    shopbackbtn.removeAllListeners();
    const shopfwdbtn = wh.add.sprite(0, 0, "thesb", 'forwardsign');
    shopfwdbtn.setOrigin(0.5, 0.5);
    shopfwdbtn.setInteractive();
    shopfwdbtn.removeAllListeners();
    this.plog.log('VIEWMYSTACK var', this.rcvarsservice);
    const buybtn = wh.add.sprite(0, 0, "thesb", 'cancel');
    buybtn.setOrigin(0.5, 0.5);
    buybtn.setInteractive();
    buybtn.removeAllListeners();
    this.VIEWMYSTACKfwdbtn = shopfwdbtn;
    this.VIEWMYSTACKbackbtn = shopbackbtn;
    this.VIEWMYSTACKselectcircle = selectcircle;
    this.VIEWMYSTACKbuybtn = buybtn;

    var shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.VIEWMYSTACKclosebtn = shopclosebtn;
    this.VIEWMYSTACKclosebtn.setOrigin(0.5, 0.5);
    this.VIEWMYSTACKclosebtn.setPosition(165, -200);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.VIEWMYSTACKclosebtn.setInteractive();
    this.VIEWMYSTACKclosebtn.removeAllListeners();
    this.VIEWMYSTACKclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.VIEWMYSTACK_close(wh);
      },
      this
    );

    wh.VIEWMYSTACKcontainer_item = wh.add.container(300, 300);
    wh.VIEWMYSTACKcontainer_item._refsrv = this;
    wh.VIEWMYSTACKcontainer_item.name = "VIEWMYSTACKcontainer_item";
    wh.VIEWMYSTACKcontainer_item.add(this.VIEWMYSTACKclosebtn);
    wh.VIEWMYSTACKcontainer_item.add(selectcircle);
    wh.VIEWMYSTACKcontainer_item.add(shopguibg);
    wh.VIEWMYSTACKcontainer_item.add(shopbackbtn);
    wh.VIEWMYSTACKcontainer_item.add(shopfwdbtn);
    wh.VIEWMYSTACKcontainer_item.add(buybtn);

    this.VIEWMYSTACKtitle = wh.add.text(0, 0, 'MY LISTINGS', wh.fs["winguititle42"]);
    wh.VIEWMYSTACKcontainer_item.add(this.VIEWMYSTACKtitle);
    wh.VIEWMYSTACKcontainer_item.bringToTop(this.VIEWMYSTACKtitle);
    this.VIEWMYSTACKtitle.setPosition(0 - this.VIEWMYSTACKtitle.displayWidth / 2, -225);


    this.VIEWMYSTACKwinguiltab = wh.add.image(0, 0, 'winguiltab');
    this.VIEWMYSTACKwinguiltab.setOrigin(0.5, 0.5);
    this.VIEWMYSTACKwinguiltab.displayWidth = this.VIEWMYSTACKwinguiltab.displayHeight = 52;
    this.VIEWMYSTACKwinguiltab.visible = false;
    wh.VIEWMYSTACKcontainer_item.add(this.VIEWMYSTACKwinguiltab);
    wh.VIEWMYSTACKcontainer_item.bringToTop(this.VIEWMYSTACKwinguiltab);

    this.emptyVIEWMYSTACKcontainer_item = wh.add.image(300, 300, 'empty');
    this.emptyVIEWMYSTACKcontainer_item.setOrigin(0.5, 0.5);
    this.emptyVIEWMYSTACKcontainer_item.setPosition(0, 0);
    this.emptyVIEWMYSTACKcontainer_item.displayWidth = this.emptyVIEWMYSTACKcontainer_item.displayHeight = 300;
    wh.VIEWMYSTACKcontainer_item.add(this.emptyVIEWMYSTACKcontainer_item);
    wh.VIEWMYSTACKcontainer_item.bringToTop(this.emptyVIEWMYSTACKcontainer_item);
    this.emptyVIEWMYSTACKcontainer_item.visible = false;


    shopbackbtn.displayWidth = 100;
    shopfwdbtn.displayWidth = 100;
    buybtn.displayWidth = 100;
    shopbackbtn.displayHeight = 40;
    shopfwdbtn.displayHeight = 40;
    buybtn.displayHeight = 40;
    shopbackbtn.setPosition(-100, 220);
    shopfwdbtn.setPosition(100, 220);
    buybtn.setPosition(0, 220);

    wh.VIEWMYSTACKcontainer_item.bringToTop(this.VIEWMYSTACKclosebtn);
    var itemdescr = wh.add.text(0, 0, this.wh.trs("f:merchant:Click Item to View Description===Click Item to View Description"), wh.fs['winguilist_descr']);
    wh.VIEWMYSTACKcontainer_item.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.VIEWMYSTACKitemdescr = itemdescr;
    this.VIEWMYSTACKinvensize = wh.add.text(0, 0, '0/0', {
      fontFamily: 'ffff,uuuu',
      fontSize: '12px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    wh.VIEWMYSTACKcontainer_item.add(this.VIEWMYSTACKinvensize);
    this.VIEWMYSTACKinvensize.setOrigin(1, 1);
    this.VIEWMYSTACKinvensize.setPosition(150, -147);

    //drag

    var shopdragbtn = wh.add.image(300, 300, 'spacer');
    wh.shopdragbtn = shopdragbtn;
    wh.shopdragbtn.setOrigin(0.5, 0.5);
    wh.shopdragbtn.setPosition(0, -200);
    shopdragbtn.displayWidth = 270;
    shopdragbtn.displayHeight = 60;
    wh.shopdragbtn.setInteractive();
    wh.shopdragbtn.removeAllListeners();
    wh.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactive =
          false;
      },
      this
    );
    wh.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item
        );

        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.x;
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.y;
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.dragactivepy = 0;
    wh.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.VIEWMYSTACK_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item.y = pointer.y;
        }
      },
      this
    );
    wh.VIEWMYSTACKcontainer_item.add(wh.shopdragbtn);
    wh.VIEWMYSTACKcontainer_item.bringToTop(wh.shopdragbtn);

    this.plog.log(
      'VIEWMYSTACKcontainer_item x',
      wh.VIEWMYSTACKcontainer_item,
      width,
      height
    );
    wh.VIEWMYSTACKcontainer_item.setPosition(width / 2, height / 2);
    //move
    wh.VIEWMYSTACKcontainer_item.x = width - this.VIEWMYSTACKguibg.width / 2;

    let loader = new Phaser.Loader.LoaderPlugin(wh);

    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    var loadlist = ['stackitemtypeico'];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }
    this.wh.camman(this.wh);
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.VIEWMYSTACK_showpage(wh, "1");
        this.VIEWMYSTACK_loaded(wh);
      },
      this
    );
    loader.start();
  }
  async VIEWMYSTACK_loaded(wh, refocus = true) {
    this.VIEWMYSTACKbuybtn.visible = true;
    this.plog.log('VIEWMYSTACK_loaded', wh, this.VIEWMYSTACKshopdata);

    var griddat = [];
    var griddatf = [];

    if (refocus == true) {
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
        this.rcvarsservice.gameobj.scene.scenes[0].VIEWMYSTACKcontainer_item
      );
    }

    var paddingl = -175;
    var paddingt = -140;
    //var stackarr = [];


    this.VIEWMYSTACKstackarr["util"] = ["util"];
    //this.VIEWMYSTACKstackarr["currency"] = ["currency"];
    this.VIEWMYSTACKstackarr["upgradeitem"] = ["upgradeitem"];
    this.VIEWMYSTACKstackarr["chest"] = ["chest"];
    this.VIEWMYSTACKstackarr["egg"] = ["egg"];
    this.VIEWMYSTACKstackarr["questitems"] = ["questitems"];
    this.VIEWMYSTACKstackarr["skillbook"] = ["skillbook"];


    if (this.VIEWMYSTACKsubmn.length == 0) {
      var paddingts = paddingt + 0;
      var iconwhsub = 30;
      var rowh = 40;
      var i = 0;
      for (var k in this.VIEWMYSTACKstackarr) {
        var v = this.VIEWMYSTACKstackarr[k];
        //this.plog.log('xxxxx', this.wh, k, v, i);

        this.VIEWMYSTACKsubmn[k] = this.wh.add.sprite(
          0,
          0,
          'stackitemtypeico',
          k,
          iconwhsub * i + 2 + paddingts
        );
        this.wh.VIEWMYSTACKcontainer_item.add(this.VIEWMYSTACKsubmn[k]);
        this.wh.VIEWMYSTACKcontainer_item.bringToTop(this.VIEWMYSTACKsubmn[k]);
        this.VIEWMYSTACKsubmn[k].displayWidth = this.VIEWMYSTACKsubmn[k].displayHeight =
          iconwhsub;
        this.VIEWMYSTACKsubmn[k].setPosition(
          paddingl,
          paddingt + ((iconwhsub + 3) * i + 65)
        );
        paddingts = paddingts + rowh;
        this.VIEWMYSTACKsubmn[k].setInteractive();
        this.VIEWMYSTACKsubmn[k].removeAllListeners();
        this.VIEWMYSTACKsubmn[k].name = k;
        this.VIEWMYSTACKsubmn[k].on('pointerup', async function () {
          //this.plog.log('xxxxx', this);
          this.scene.registry.list.merchant.VIEWMYSTACKwinguiltab.setPosition(this.x - 7, this.y);
          if (
            this.scene.registry.list.merchant.VIEWMYSTACKfiltertype == this.name
          ) {
            this.scene.registry.list.merchant.VIEWMYSTACKfiltertype = '';
            this.scene.registry.list.merchant.VIEWMYSTACKwinguiltab.visible = false;
          } else {
            this.scene.registry.list.merchant.VIEWMYSTACKfiltertype = this.name;
            this.scene.registry.list.merchant.VIEWMYSTACKwinguiltab.visible = true;
            this.scene.VIEWMYSTACKcontainer_item.moveBelow(this.scene.registry.list.merchant.VIEWMYSTACKwinguiltab, this);
          }
          this.scene.registry.list.merchant.VIEWMYSTACKcurrentresppage = 1;

          var shopdata = await this.scene.registry.list.rchttp.getcommon2(
            'merchantviewmystackitem',
            {
              page: this.scene.registry.list.merchant.VIEWMYSTACKcurrentresppage,
              filtertype: this.scene.registry.list.merchant.VIEWMYSTACKfiltertype,
            }
          );
          this.scene.registry.list.merchant.VIEWMYSTACKshopdata = shopdata;
          this.scene.registry.list.merchant.VIEWMYSTACKresptotalpage =
            shopdata['totalpage'];
          this.scene.registry.list.merchant.VIEWMYSTACK_loaded(
            this.scene.registry.list.merchant.wh
          );
        });
        this.VIEWMYSTACKsubmn[k].on('pointerover', function () {
          var str = this.name;
          if (str == 'sword') str = 'weapons';
          this.scene.registry.list.gameitf.gamemenutooltip(
            this.scene,
            this,
            this.scene.trs("t:stackitem_type:code:name:" + str + "===" + str),
            'left'
          );
        });
        griddat.push(this.VIEWMYSTACKsubmn[k]);
        i++;
      }
    }

    var margintop = -90;
    var marginleft = -117;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < this.VIEWMYSTACKrespperpage; i++) {
      if (this.VIEWMYSTACKitemlist[i] != undefined) {
        //this.plog.log(this.VIEWMYSTACKitemlist[i]);
        //this.VIEWMYSTACKitemlist[i].visible = false;
        this.VIEWMYSTACKitemlist[i].destroy();
      }
      if (this.VIEWMYSTACKitemlistp[i] != undefined) {
        this.VIEWMYSTACKitemlistp[i].destroy();
      }
      if (this.VIEWMYSTACKitemlistpp[i] != undefined) {
        this.VIEWMYSTACKitemlistpp[i].destroy();
      }
    }
    this.plog.log('VIEWMYSTACKitemlist', this.VIEWMYSTACKitemlist, this.VIEWMYSTACKitemlistp);
    this.plog.log('pagemode', this.VIEWMYSTACKcurrentresppage);
    var pagemode = 'normal';
    if (pagemode == 'normal') {
      //this.VIEWMYSTACKcurrentresppage = 1;
    }
    if (pagemode == 'retainpage') {
      if (
        this.VIEWMYSTACKcurrentresppage == 0 ||
        this.VIEWMYSTACKcurrentresppage == undefined
      )
        this.VIEWMYSTACKcurrentresppage = 1;
    }
    //this.VIEWMYSTACKinvensize.setText('Viewing ' + countslot + '/' + countslot);

    this.VIEWMYSTACKinvensize.setStyle({
      fontFamily: 'ffff,uuuu',
      fontSize: '10px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
      resolution: 12,
    });
    var i = 0;
    this.plog.log('loaded itemdatall ', this.VIEWMYSTACKshopdata);

    for (var vv in this.VIEWMYSTACKshopdata['item']) {
      var vdat = this.VIEWMYSTACKshopdata['item'][vv];
      var itemdat = this.rcvarsservice.allstackitem[vdat.item];
      this.plog.log('loaded stackitemdat ', vv, vdat, itemdat);
      //var tmp = wh.add.image(32, 32, itemdat["code"]);
      if (itemdat == undefined) continue;
      this.VIEWMYSTACKitemlist[i] = this.wh.add.sprite(
        32,
        32,
        'stackitem_tp',
        itemdat['code']
      );
      this.VIEWMYSTACKitemlist[i].displayWidth = 48;
      this.VIEWMYSTACKitemlist[i].displayHeight = 48;
      this.wh.VIEWMYSTACKcontainer_item.add(this.VIEWMYSTACKitemlist[i]);
      this.VIEWMYSTACKitemlist[i].setPosition(marginleft, margintop + crow * 59);
      //this.plog.log("xxx1",tmp);
      this.VIEWMYSTACKitemlistp[i] = this.wh.add.text(
        0,
        0,
        itemdat['name'] + ' x' + vdat['amnt'],
        {
          fontFamily: 'ffff,uuuu',
          fontSize: '11px',
          fill: '#000',
          boundsAlignH: 'center',
          boundsAlignV: 'middle',
          stroke: '#000',
          strokeThickness: 0,
        }
      );
      this.wh.VIEWMYSTACKcontainer_item.add(this.VIEWMYSTACKitemlistp[i]);
      this.VIEWMYSTACKitemlistp[i].setPosition(
        this.VIEWMYSTACKitemlist[i].x + 35,
        this.VIEWMYSTACKitemlist[i].y - 25
      );
      if (vdat['currency'] == "" || vdat['currency'] == undefined) {
        vdat['currency'] = "gold";
      }
      if (vdat['currency'] == "soulcrysta") vdat['currency'] = "soulcrystal";
      var currencyname = this.wh.registry.list.rcvarpass.allstackitem[vdat['currency']]['name'];
      currencyname = currencyname.replace("Soul Crystal", "Souls");
      this.VIEWMYSTACKitemlistpp[i] = this.wh.add.text(0, 0, this.wh.registry.list.gameitf.humannumbercomma(vdat['price'], 0) + ' ' + currencyname, {
        fontFamily: 'ffff,uuuu',
        fontSize: '12px',
        fill: '#e0ad02',
        boundsAlignH: 'center',
        boundsAlignV: 'middle',
        stroke: '#000',
        strokeThickness: 2,
      });
      this.wh.VIEWMYSTACKcontainer_item.add(this.VIEWMYSTACKitemlistpp[i]);
      this.VIEWMYSTACKitemlistpp[i].setPosition(
        this.VIEWMYSTACKitemlist[i].x + 35,
        this.VIEWMYSTACKitemlist[i].y - 2
      );
      this.VIEWMYSTACKitemlist[i].visible = true;
      this.VIEWMYSTACKitemlistp[i].visible = true;
      this.VIEWMYSTACKitemlistpp[i].visible = true;
      this.VIEWMYSTACKitemlist[i].setInteractive();
      this.VIEWMYSTACKitemlist[i].removeAllListeners();
      this.VIEWMYSTACKitemlist[i].name =
        '' + vdat['item'] + '-' + vdat['id'] + '-' + i;
      this.VIEWMYSTACKitemlist[i].on(
        'pointerup',
        function (pointer, localX, localY, event) {
          this.scene.plog.log('shop itemclick this', this);
          this.scene.plog.log('shop itemclick', pointer, localX, localY, event);
          this.scene.registry.list.merchant.VIEWMYSTACK_itemclick(this.scene, this);
        }
      );
      griddat.push(this.VIEWMYSTACKitemlist[i]);
      crow++;

      i++;
    }
    if (i == 0) {
      this.emptyVIEWMYSTACKcontainer_item.visible = true;
    } else {
      this.emptyVIEWMYSTACKcontainer_item.visible = false;
    }
    this.plog.log('shop i count', i);
    if (i > 25) {
      //pages
      this.VIEWMYSTACKresptotalpage = Math.ceil(i / this.VIEWMYSTACKrespperpage);
    }
    this.VIEWMYSTACKbackbtn.removeAllListeners();
    this.VIEWMYSTACKfwdbtn.removeAllListeners();
    this.VIEWMYSTACKbackbtn.on(
      'pointerup',
      async function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        await this.VIEWMYSTACK_showpage(wh, 'back');
        this.VIEWMYSTACK_loaded(this.wh);
      },
      this
    );
    this.VIEWMYSTACKfwdbtn.on(
      'pointerup',
      async function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        await this.VIEWMYSTACK_showpage(wh, 'next');
        this.VIEWMYSTACK_loaded(this.wh);
      },
      this
    );
    //this.VIEWMYSTACK_showpage(wh, 'current');

    this.VIEWMYSTACKbuybtn.removeAllListeners();
    this.VIEWMYSTACKbuybtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop buybtn', this);
        this.VIEWMYSTACK_cancel(this);
      },
      this
    );

    var numfrom = this.VIEWMYSTACKrespperpage * (this.VIEWMYSTACKcurrentresppage - 1);
    numfrom = numfrom + 1;
    var numto = this.VIEWMYSTACKrespperpage * this.VIEWMYSTACKcurrentresppage;
    this.VIEWMYSTACKinvensize.setText(
      'Viewing ' +
      numfrom +
      '-' +
      numto +
      '/' +
      this.VIEWMYSTACKshopdata['allrecord']
    );

    griddat.push(this.VIEWMYSTACKbackbtn);
    griddat.push(this.VIEWMYSTACKbuybtn);
    griddat.push(this.VIEWMYSTACKfwdbtn);
    griddat.push(this.VIEWMYSTACKclosebtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);
  }
  async VIEWMYSTACK_showpage(gc, viewpage) {
    this.plog.log('VIEWMYSTACK_showpage', gc, viewpage);
    this.VIEWMYSTACKselectcircle.visible = false;
    if (viewpage == 'back') {
      if (this.VIEWMYSTACKcurrentresppage > 1) this.VIEWMYSTACKcurrentresppage--;
    }
    if (viewpage + '' == '1') {
      this.VIEWMYSTACKcurrentresppage = 1;
    }
    if (viewpage == 'next') {
      if (this.VIEWMYSTACKcurrentresppage < this.VIEWMYSTACKresptotalpage)
        this.VIEWMYSTACKcurrentresppage++;
    }
    for (var i = 0; i <= this.VIEWMYSTACKrespperpage; i++) {
      if (this.VIEWMYSTACKitemlist[i] != undefined) {
        this.VIEWMYSTACKitemlist[i].visible = false;
      }
      if (this.VIEWMYSTACKitemlistp[i] != undefined) {
        this.VIEWMYSTACKitemlistp[i].visible = false;
      }
      if (this.VIEWMYSTACKitemlistpp[i] != undefined) {
        this.VIEWMYSTACKitemlistpp[i].visible = false;
      }
    }
    this.plog.log(
      'VIEWMYSTACK_showpage',
      viewpage,
      this.VIEWMYSTACKcurrentresppage,
      'perpage',
      this.VIEWMYSTACKrespperpage
    );

    var shopdata = await this.wh.registry.list.rchttp.getcommon2(
      'merchantviewmystackitem',
      { page: this.VIEWMYSTACKcurrentresppage, filtertype: this.VIEWMYSTACKfiltertype }
    );
    this.VIEWMYSTACKshopdata = shopdata;

    this.VIEWMYSTACKresptotalpage = shopdata['totalpage'];

    //this.VIEWMYSTACK_loaded(this.wh);
    //this.plog.log("xxx1", this.shopitemlist);
  }

  async VIEWMYSTACK_close(wh) {


    wh.VIEWMYSTACKcontainer_item.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.VIEWMYSTACKcontainer_item);
    for (const k in this.wh.VIEWMYSTACKcontainer_item.list) {
      try {
        await this.wh.VIEWMYSTACKcontainer_item.remove(this.wh.VIEWMYSTACKcontainer_item.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! VIEWMYSTACKcontainer_item.destroy() ", e);
      }
    }

    for (const k in this.wh.VIEWMYSTACKcontainer_item.list) {
      try {
        await this.wh.VIEWMYSTACKcontainer_item.remove(this.wh.VIEWMYSTACKcontainer_item.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! VIEWMYSTACKcontainer_item.destroy() ", e);
      }
    }
    this.wh.VIEWMYSTACKcontainer_item = undefined;
  }

  VIEWMYSTACK_itemclick(wh, item) {
    this.VIEWMYSTACKselectcircle.visible = true;
    var namea = item.name.split('-');
    wh.registry.list.merchant.VIEWMYSTACKselecteditemno = namea[1];
    this.plog.log('showinventory_itemclick', wh, item);
    this.VIEWMYSTACKselectcircle.displayWidth = 48;
    this.VIEWMYSTACKselectcircle.displayHeight = 48;
    this.VIEWMYSTACKselectcircle.setPosition(item.x, item.y);
    //wh.VIEWMYSTACKcontainer_item.sendToBack(wh.shopguibg);
    wh.VIEWMYSTACKcontainer_item.bringToTop(this.VIEWMYSTACKselectcircle);
    wh.VIEWMYSTACKcontainer_item.bringToTop(this.VIEWMYSTACKitemlist[namea[2]]);
    wh.VIEWMYSTACKcontainer_item.bringToTop(this.VIEWMYSTACKitemlistp[namea[2]]);
    wh.VIEWMYSTACKcontainer_item.bringToTop(this.VIEWMYSTACKitemlistpp[namea[2]]);
    this.plog.log(
      'showinventory_itemclick selectcircle',
      namea,
      item,
      wh.registry.list.rcvarpass.activechar
    );
    var iteminfo = this.VIEWMYSTACKshopdata['item'][namea[1]];
    var iteminfo2 = wh.registry.list.rcvarpass.allstackitem[namea[0]];

    //this.selectcircle.width=
    //this.itemdescr.setText(iteminfo2["name"] + " x" + iteminfo["amnt"]);
    //this.plog.log("xxxx",this);
    /*if (wh.registry.list.rcvarpass.activechar["map"] == "home") {
      this.VIEWMYSTACKitemdescr.setText(iteminfo2["name"] + " deposit to home inventory");
    } else {
      if (this.invenmode == "sell") {
        this.itemdescr.setText(iteminfo2["name"] + " sell for " + iteminfo["sellprice"] + " gold");
      } else {
        this.itemdescr.setText(iteminfo2["name"]);
      }
    }*/
    this.rcosd.show(wh, 'stackitem', iteminfo2, iteminfo);
  }

  async VIEWMYSTACK_cancel(wh) {
    this.plog.log('VIEWMYSTACK_cancel', wh, Number(this.VIEWMYSTACKselecteditemno));
    if (this.VIEWMYSTACKselecteditemno == '') return;
    if (this.VIEWMYSTACKshopdata['item'][this.VIEWMYSTACKselecteditemno] == undefined) {
      this.plog.log(
        'VIEWMYSTACK_cancel skip dropped',
        this.VIEWMYSTACKselecteditemno,
        wh.rcvarsservice.activechar['item'][this.VIEWMYSTACKselecteditemno]
      );
      return; //dropped
    }
    var tmpid = this.VIEWMYSTACKselecteditemno;
    var tmpitem = this.VIEWMYSTACKshopdata['item'][this.VIEWMYSTACKselecteditemno]['item'];
    var tmpitemprice = this.VIEWMYSTACKshopdata['item'][this.VIEWMYSTACKselecteditemno]['price'];
    var tmpitemamnt = this.VIEWMYSTACKshopdata['item'][this.VIEWMYSTACKselecteditemno]['amnt'];
    var iteminfo2 = wh.rcvarsservice.allstackitem[tmpitem];
    var tmpcurrency = this.VIEWMYSTACKshopdata['item'][this.VIEWMYSTACKselecteditemno]['currency'];

    if (tmpcurrency == "" || tmpcurrency == undefined) {
      tmpcurrency = "gold";
    }
    var currencyname = this.wh.registry.list.rcvarpass.allstackitem[tmpcurrency]['name'];

    //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Drop", "<p>Remove this items?</p><p>" + iteminfo2["name"] + " lv." + iteminfo2["level"] + "</p>");
    var confirm =
      await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
        wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
        this.wh.trs("f:merchant:CANCEL Selling===CANCEL Selling"),
        this.wh.trs("f:merchant:Cancel listing this items?===Cancel listing this items?") + ':\n' +
        iteminfo2['name'] +
        ' x' +
        tmpitemamnt +
        this.wh.gl('\nfor ') +
        tmpitemprice +
        ' ' + currencyname
      );
    this.plog.log('rcconfirmresult', confirm);
    if (confirm != 'ok') return;
    this.plog.log('VIEWMYSTACK_cancel', wh.rcvarsservice.activechar);
    //var res = await this.rchttp.dropitem(tmpid);
    //await this.rcvarsservice.setactivechar(res, wh.rcvarsservice.activechar["id"]);
    //this.plog.log("VIEWMYSTACK_cancel res", res, wh.rcvarsservice);
    //this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(this.rcvarsservice.gameobj.scene.scenes[0]);
    var shopdata = await this.wh.registry.list.rchttp.getcommon2(
      'merchantviewmystackitem_cancel',
      { itemid: tmpid }
    );

    await this.rchttp.updatecharinfo();
    this.VIEWMYSTACKselecteditemno = '';
    await this.VIEWMYSTACK_showpage(wh, 1);
    this.VIEWMYSTACK_loaded(wh);
  }

  rebuildguigrid() {
    //BUYITEMcontainer_item
    //BUYSTACKITEMcontainer_item
    //VIEWMYcontainer_item
    //VIEWMYSTACKcontainer_item
    if (this.wh.BUYITEMcontainer_item != undefined && this.wh.BUYITEMcontainer_item.scene != undefined) {
      this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
      //this.BUYITEM_showpage(this.wh,"1");
      this.BUYITEM_loaded(this.wh, false);
      this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
    }
    if (this.wh.BUYSTACKITEMcontainer_item != undefined && this.wh.BUYSTACKITEMcontainer_item.scene != undefined) {
      this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
      //this.BUYSTACKITEM_showpage(this.wh,"1");
      this.BUYSTACKITEM_loaded(this.wh, false);
      this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
    }
    if (this.wh.VIEWMYSTACKcontainer_item != undefined && this.wh.VIEWMYSTACKcontainer_item.scene != undefined) {
      this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
      //this.VIEWMYSTACK_showpage(this.wh,"1");
      this.VIEWMYSTACK_loaded(this.wh, false);
      this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
    }
    if (this.wh.VIEWMYcontainer_item != undefined && this.wh.VIEWMYcontainer_item.scene != undefined) {
      this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
      //this.VIEWMY_showpage(this.wh,"1");
      this.VIEWMY_loaded(this.wh, false);
      this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
    }
  }
}
