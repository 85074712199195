import { plog } from "./plog.service";

export class PGBar {
    bar;
    bdradius;
    value;
    p; x; y;
    o;
    borderw;
    barcol1;
    barcol2;
    txt;
    bgcol;
    pgtype;
    barfg;
    imgcol;
    sceneobj;
    fgtween;
    hpb4tween;
    plog;
    basetext = "";
    constructor(scene, o, pgtype = "graphics") {
        this.sceneobj = scene;
        //this.plog.log("pgbar constructor", scene, o);
        //this.plog.log("pgbar constructor", this.bar);
        this.pgtype = pgtype + ""; //graphics or img
        this.plog = new plog();

        if (o.imgcol != undefined) {
            this.imgcol = o.imgcol;
        }
        if (this.imgcol == undefined || this.imgcol == "") {
            this.imgcol = "green";
        }
        if (this.pgtype == "graphics") {
            this.bar = new Phaser.GameObjects.Graphics(scene);
        }
        if (this.pgtype == "img") {
            this.bar = scene.add.sprite(300, 300, 'thecolor', "black");
            this.barfg = scene.add.sprite(300, 300, 'thecolor', "bar" + this.imgcol + "");
            this.bar.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
            this.barfg.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

        }
        if (o.txt == undefined) o.txt = "";
        this.basetext = o.txt;
        this.o = o;
        this.x = o.x;
        this.y = o.y;
        this.value = o.max;
        this.hpb4tween = o.me;

        this.p = o.me / o.max;
        //this.plog.log("zzzz",this.p,o.me,o.max);
        this.bdradius = 7;
        if (this.bdradius > o.h / 2) this.bdradius = o.h / 2;
        this.borderw = 1;
        this.barcol1 = 0xffc012
        if (o.barcol1 != undefined) {
            this.barcol1 = o.barcol1
        }
        this.barcol2 = 0xffcb3b
        this.bgcol = 0x000000
        if (o.barcol2 != undefined) {
            this.barcol2 = o.barcol2
        }
        if (o.bgcol != undefined) {
            this.bgcol = o.bgcol
        }
        this.draw();
        //scene.add.existing(this.bar);
        this.txt = scene.add.text(o.x, o.y, this.o.txt + "", { fontFamily: 'uuuu', fontSize: (o.h - 4) + 'px', stroke: '#000', strokeThickness: 2, color: '#fff' });

        return this;
    }
    setimgcol(imgcol) {
        this.imgcol = imgcol;
        if (this.barfg != undefined) {
            //this.plog.log("pgbar-setimgcol",imgcol,this.imgcol);
            //this.barfg.destroy();
            //this.barfg=undefined;
            //this.barfg=this.sceneobj.add.sprite(300, 300, 'thecolor', this.imgcol+"");
            try {
                this.barfg.setTexture('thecolor', "bar" + this.imgcol + "");
                this.draw();
            } catch (e) {
                //console.log(e);
            }
        }
    }
    destroy() {

        if (this.fgtween != undefined) {
            try {
                this.fgtween.stop();
            } catch (e) {
                this.plog.log("ERROR", e);
            }
        }
        if (this.bar != undefined) {
            try {
                this.bar.destroy();
            } catch (e) {
                this.plog.log("ERROR", e);
            }
        }
        if (this.barfg != undefined) {
            try {
                this.barfg.destroy();
            } catch (e) {
                this.plog.log("ERROR", e);
            }
        }
        if (this.txt != undefined) {
            try {
                this.txt.destroy();
            } catch (e) {
                this.plog.log("ERROR", e);
            }
        }
    }
    setcol(colobj) {
        if (colobj.barcol1 != undefined) {
            this.barcol1 = colobj.barcol1
        }
        if (colobj.barcol2 != undefined) {
            this.barcol2 = colobj.barcol2
        }
        if (colobj.bgcol != undefined) {
            this.bgcol = colobj.bgcol
        }
        this.draw();
    }
    getobj() {
        return this.bar;
    }
    getobjfg() {
        return this.barfg;
    }
    getobjtxt() {
        return this.txt;
    }
    decrease(amount) {
        this.o.me -= amount;
        if (this.o.me < 0) {
            this.o.me = 0;
        }
        this.draw();
        return (this.o.me === 0);
    }
    public set1(amount) {
        //this.plog.log("pgbar set1",amount);
        this.o.me = amount;
        this.draw();
        return (this.value === 0);
    }
    public setmax() {
        //this.plog.log("pgbar set1",amount);
        this.o.me = this.o.max;
        this.draw();
        return;
    }
    public setw(amount) {
        //this.plog.log("pgbar set1",amount);
        this.hpb4tween = amount;

        this.o.w = amount;
        this.draw();
        return (this.value === 0);
    }
    lformat(num: number): string {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + "M";
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0) + "K";
        } else {
            return num.toString();
        }
    }
    getHexColorCode(number: number, maxNumber: number): string {
        // Calculate the value between 0 and 1 based on the number and maxNumber
        const value = number / maxNumber;

        // Calculate the RGB values based on the value
        const red = Math.round(255 * (1 - value));
        const green = Math.round(255 * value);

        // Convert the RGB values to a hex color code
        const hexColorCode = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}00`;

        return hexColorCode;
    }
    drawTextPercent() {
        var percent = ((100 * this.o.me) / this.o.max);
        if (percent > 100) percent = 100;
        if (percent < 0) percent = 0;
        try {
            this.txt.setText(this.o.txt + " " + Math.floor(percent) + "% " + "/" + this.lformat(Math.floor(this.o.max)));
        } catch (e) {
            console.log("ERROR", e);
        }
    }
    drawText() {
        //console.log("drawText",this);
        try {
            this.txt.setText(this.basetext + " " + Math.floor(this.o.me) + "" + "/" + this.lformat(Math.floor(this.o.max)));
        } catch (e) {
            console.log("ERROR", e);
        }
    }
    public set(amount, max) {
        //this.plog.log("pgbar.set", amount, max);
        this.hpb4tween = this.o.me;

        this.o.me = amount;
        this.o.max = max;
        if (this.bar.visible == true) {
        } else {

            var percent = ((100 * this.o.me) / this.o.max);
            if (percent > 100) percent = 100;
            if (percent < 0) percent = 0;
            try {
                this.txt.setText(this.o.txt + " " + Math.floor(percent) + "% " + "/" + this.lformat(Math.floor(this.o.max)));
            } catch (e) {
                console.log("ERROR", e);
            }
            var tmpcol = this.getHexColorCode(this.o.me, this.o.max);
            if (percent == 0) tmpcol = "#000000";
            //this.plog.log("tmpcol",tmpcol,percent);
            try {
                this.txt.setBackgroundColor(tmpcol);
            } catch (e) {
                this.plog.log("ERROR", e);
            }
        }

        this.draw();
        return (this.value === 0);
    }

    draw() {
        if (this.pgtype == "graphics") {
            this.bar.clear();
            //  BG
            this.bar.fillRoundedRect(this.x, this.y, this.o.w, this.o.h, this.bdradius);
            //  Health
            this.bar.fillStyle(this.bgcol);
            //this.bar.fillStyle(this.barcol);
            this.bar.fillRoundedRect(this.x + this.borderw, this.y + this.borderw, this.o.w - (this.borderw * 2), this.o.h - (this.borderw * 2), this.bdradius);
            //this.plog.log("pgbar draw", this.bar, this.o, this.o.w - (this.borderw * 2));
            /* if (this.value < 30) {
                 this.bar.fillStyle(0xff0000);
             }
             else {*/
            this.bar.fillStyle(this.barcol1);
            //}
            //bar ?          me 
            //bar w         max

            //  ?           me
            //  100         max
            var percent = ((100 * this.o.me) / this.o.max);
            if (percent > 100) percent = 100;
            if (percent < 0) percent = 0;
            //this.plog.log("pgbar percent=", percent);
            var usew = (percent / 100) * this.o.w;

            //this.plog.log("pgbar usew=", usew, " from full w:" + this.o.w);

            this.bar.fillRoundedRect(this.x + this.borderw, this.y + this.borderw, usew, ((this.o.h - (this.borderw * 2)) / 2) + 1, { tl: this.bdradius, tr: this.bdradius, bl: 0, br: 0 });
            this.bar.fillStyle(this.barcol2);

            this.bar.fillRoundedRect(this.x + this.borderw, (this.y + (this.o.h / 2)), usew, (this.o.h - (this.borderw * 2)) / 2, { tl: 0, tr: 0, bl: this.bdradius, br: this.bdradius });/*
        this.bar.displayWidth=200;
        this.bar.displayHeight=200;
        this.bar.x=300; this.bar.y=200;
        this.bar.w=200; this.bar.h=200;*/
        }
        if (this.pgtype == "img") {
            this.bar.setOrigin(0, 0);
            this.barfg.setOrigin(0, 0);
            this.bar.setPosition(this.x, this.y);
            this.bar.displayWidth = this.o.w;
            this.bar.displayHeight = this.o.h;
            if (this.barfg.parentContainer != this.bar.parentContainer && this.bar.parentContainer != null && this.bar.parentContainer != undefined) {
                this.bar.parentContainer.add(this.barfg);
                //this.bar.parentContainer.bringToTop(this.barfg);
            }


            var percent = ((100 * this.o.me) / this.o.max);
            if (percent > 100) percent = 100;
            if (percent < 0) percent = 0;
            //this.plog.log("pgbar percent=", percent);
            var usew = (percent / 100) * (this.o.w - this.borderw);
            //usew = Math.floor(usew);
            this.barfg.setPosition(this.bar.x + this.borderw, this.bar.y + this.borderw);
            this.barfg.displayHeight = ((this.o.h - (this.borderw * 2)));
            //this.barfg.displayWidth = usew;
            if (this.fgtween != undefined) {
                try {
                    this.fgtween.pause();
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
                try {
                    this.fgtween.stop();
                } catch (e) {
                    this.plog.log("ERROR", e);
                }
                try {
                    this.fgtween.remove();
                } catch (e) {
                    this.plog.log("ERROR", e);
                }

                this.fgtween = undefined;
                if (this.hpb4tween != undefined) {
                    var percentb4 = ((100 * this.hpb4tween) / this.o.max);
                    if (percentb4 > 100) percentb4 = 100;
                    if (percentb4 < 0) percentb4 = 0;
                    //this.plog.log("pgbar percent=", percent);
                    var usewb4 = (percentb4 / 100) * (this.o.w - this.borderw);
                    //usewb4 = Math.floor(usewb4);
                    //if (Math.floor(this.barfg.displayWidth) != Math.floor(usewb4)) {
                    this.barfg.displayWidth = usewb4;
                    //}
                    //this.plog.log("pgbar.draw img (intween)", this.barfg.displayWidth, usew, usewb4);
                    this.hpb4tween = undefined;
                }
                //this.plog.log("pgbar.draw img (intween)", this.barfg.displayWidth, usew, this.fgtween);

            }
            /*
                */
            this.fgtween = this.sceneobj.tweens.add({
                targets: this.barfg,
                displayWidth: usew,
                ease: 'Power3',
                duration: 100, // duration of animation; higher=slower
                delay: 0, // wait 500 ms before starting
                loop: 0,
                repeat: 0,
                onComplete: function () {
                    //this.visible=false;
                    // this.plog.log("pgbartweencomplete", arguments);

                    try {
                        arguments[0].remove();
                    } catch (e) {
                        this.plog.log("ERROR", e);
                    }
                }, // set context? how?
            });
        }
    }
}