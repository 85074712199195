import { Injectable } from '@angular/core';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class PhaserMapResizeManagerService {
  plog;

  constructor() {
    this.plog = new plog();
  }

  RCMapResizeManager(
    gameSize,
    baseSize,
    displaySize,
    previousWidth,
    previousHeight
  ) {
    //,
    //this.plog.log("RESIZE - pre",this.sys.game.scale.gameSize);
    this.RCMapResizeManager_work(this);
  }

  public RCMapResizeManager_work(wh) {
    if (wh.sys == undefined) return;
    //console.error(wh);
    //cam
    //if (wh.mapcontainer != undefined)      wh.cameras.main.ignore(wh.mapcontainer);

    if (wh.gifscontainer != undefined) {
      //wh.cameras.main.ignore(wh.gifscontainer);

      //this.plog.log('wh.cameras.main.ignore', wh.cameras.main, wh.gifscontainer);
      //wh.registry.list.gameitf.wh = wh;
    }
    var width = wh.sys.game.canvas.width;
    var height = wh.sys.game.canvas.height;
    if (wh.gifcam != undefined) {
      wh.gifcam.setSize(width, height);
      /*if (wh.char_main != undefined) {
        wh.gifcam.ignore(wh.char_main);
      }
      if (wh.mapcontainer != undefined)
        wh.gifcam.ignore(wh.mapcontainer);*/
    }
    //this.plog.log('RCMapResizeManager_work', wh.gifcam, wh.mapcontainer, wh.gifscontainer);

    //this.plog.log('RCMapResizeManager_work', width, height, wh);
    //wh.scene.pause();
    //this.plog.log("RCMapResizeManager_work", width, height);
    //wh.joy_base.y = height - 150;
    //wh.joy_thumb.y = height - 150;

    if (wh.registry.list.rcvarpass.activemap != undefined &&
      wh.registry.list.rcvarpass.activemap['map']["activemap"] == "YES") {
      var height = wh.sys.game.canvas.height;
      //console.log(this);
      wh.joyStick.setPosition(150, height - 150);
    } else {
      wh.joyStick.setPosition(-1000, -1000);
    }
    /* wh.joyStick.setPosition(-1000, -1000);*/
    //map border s
    if (wh.mapbdl != undefined) {
      wh.mapbdl.setOrigin(0, 0);
      wh.mapbdl.x = 0;
      wh.mapbdl.y = 0;
      wh.mapbdl.displayWidth = width * 0.2; //20%w
      wh.mapbdl.scaleY = wh.mapbdl.scaleX;
      wh.mapbdl.alpha = 0.0;

      wh.mapbdr.setOrigin(0, 0);
      wh.mapbdr.x = width * 0.8;
      wh.mapbdr.y = 0;
      wh.mapbdr.displayWidth = width * 0.2; //20%w
      wh.mapbdr.scaleY = wh.mapbdr.scaleX;
      wh.mapbdr.alpha = 0.0;

      wh.mapbdd.setOrigin(0, 0);
      wh.mapbdd.x = 0;
      wh.mapbdd.y = height * 0.8;
      wh.mapbdd.displayHeight = height * 0.2; //20%w
      wh.mapbdd.scaleX = wh.mapbdd.scaleY;
      wh.mapbdd.alpha = 0.0;

      wh.mapbdu.setOrigin(0, 0);
      wh.mapbdu.x = 0;
      wh.mapbdu.y = 0;
      wh.mapbdu.displayHeight = height * 0.2; //20%w
      wh.mapbdu.scaleX = wh.mapbdu.scaleY;
      wh.mapbdu.alpha = 0.0;
    }
    if (wh.gamemenuloading != undefined) {
      wh.gamemenuloading.visible = false;
    }

    if (wh.minimap != undefined) {
      //this.plog.log("RCMapResizeManager_work-minimap");
    }
    if (wh.attackbtn != undefined) {
      //this.plog.log("RCMapResizeManager_work-minimap");
      wh.attackbtn.setPosition(width - 110, height - 180);
      wh.attackbtn.displayWidth = 130;
      wh.attackbtn.scaleY = wh.attackbtn.scaleX;
      wh.attackbtn.setDepth(50010);
      wh.attackbtn.visible = false;
      if (wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
        if (wh.registry.list.rcvarpass.activemap["map"]['level'] >= 10) {
          wh.attackbtn.visible = true;
        }
      } else {
        wh.attackbtn.visible = false;
      }
    }
    if (wh.jumpbtn != undefined) {
      //this.plog.log("RCMapResizeManager_work-minimap");
      wh.jumpbtn.setPosition(width - 110, height - 120);
      wh.jumpbtn.displayWidth = 130;
      wh.jumpbtn.scaleY = wh.jumpbtn.scaleX;
      wh.jumpbtn.setDepth(50010);
      wh.jumpbtn.visible = false;
      if (wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
        ///wh.jumpbtn.visible = true;
      } else {
        wh.jumpbtn.visible = false;
      }
    }
    if (wh.guardbtn != undefined) {
      //this.plog.log("RCMapResizeManager_work-minimap");
      wh.guardbtn.setPosition(width - 110, height - 240);
      wh.guardbtn.displayWidth = 130;
      wh.guardbtn.scaleY = wh.guardbtn.scaleX;
      wh.guardbtn.setDepth(50010);
      wh.guardbtn.visible = false;
      if (wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
        ///wh.guardbtn.visible = true;
      } else {
        wh.guardbtn.visible = false;
      }
    }
    if (wh.mapname != undefined) {
      wh.mapname.setOrigin(1, 0);
      wh.mapname.setPosition(width - 3, -2);
      wh.mapname.setDepth(50100);
      wh.gifscontainer.bringToTop(wh.mapname);

    }
    if (wh.minimapview != undefined) {
      wh.minimapview.setPosition(0, 0);
      wh.minimapview.displayWidth = width;
      wh.minimapview.displayHeight = height;
    }
    if (wh.minimapviewbg != undefined) {
      wh.minimapviewbg.setPosition(0, 0);
      wh.minimapviewbg.displayWidth = width;
      wh.minimapviewbg.displayHeight = height;
    }
    if (wh.minimapview != undefined) {
      let scaleX = (wh.cameras.main.width - 50) / wh.minimapview.width;
      let scaleY = (wh.cameras.main.height - 200) / wh.minimapview.height;
      let scale = Math.min(scaleX, scaleY);
      //this.plog.log("minimapview scale", scale);
      wh.minimapview.setDepth(60100);
      wh.minimapview.setPosition(width / 2, height / 2);
      wh.minimapview.setScale(scale).setScrollFactor(0);
    }


    var currtoppos = 0;
    if (
      wh.resb_gold != undefined &&
      wh.resb_diamond != undefined &&
      wh.resb_supp != undefined &&
      wh.minimapbggui != undefined
    ) {

      if (width < 470) {
        currtoppos = 62;
        if (wh.minimap.visible == false) {
          currtoppos = 82;
        }
      }
      wh.resb_gold.displayHeight = 18;
      wh.resb_gold.scaleX = wh.resb_gold.scaleY;
      wh.resb_gold.setPosition(
        width - 215,
        currtoppos + 7
      );
      wh.resb_diamond.displayHeight = 18;
      wh.resb_diamond.scaleX = wh.resb_gold.scaleY;
      wh.resb_diamond.setPosition(
        width - (215),
        wh.resb_gold.y + wh.resb_gold.displayHeight + 1
      );
      wh.resb_supp.displayHeight = 18;
      wh.resb_supp.scaleX = wh.resb_gold.scaleY;
      wh.resb_supp.setPosition(
        width - (215),
        wh.resb_diamond.y + wh.resb_diamond.displayHeight + 1
      );
      var currtextright = width - (wh.minimapbggui.displayWidth + 3);
      if (currtoppos != 0 && wh.minimap.visible == false) {
        //small scr and hide map
        currtextright = width - 6;
      }
      wh.resb_goldt.setPosition(
        currtextright,
        wh.resb_gold.y + 1
      );
      wh.resb_diamondt.setPosition(
        currtextright,
        wh.resb_diamond.y + 1
      );
      wh.resb_suppt.setPosition(
        currtextright,
        wh.resb_supp.y + 1
      );


      wh.resb_diamond.x = width - (wh.minimapbggui.displayWidth + wh.resb_diamondt.displayWidth + 28);
      wh.resb_gold.x = width - (wh.minimapbggui.displayWidth + wh.resb_goldt.displayWidth + 28);
      wh.resb_supp.x = width - (wh.minimapbggui.displayWidth + wh.resb_suppt.displayWidth + 28);
      if (currtoppos != 0 && wh.minimap.visible == false) {
        wh.resb_diamond.x = width - (wh.resb_diamondt.displayWidth + 28);
        wh.resb_gold.x = width - (wh.resb_goldt.displayWidth + 28);
        wh.resb_supp.x = width - (wh.resb_suppt.displayWidth + 28);


      }
    }

    if (
      wh.charxpbar != undefined &&
      wh.scene.scene.registry.list.rcvarpass.activechar != undefined &&
      wh.minimapbggui != undefined
    ) {

      var maxhpbarw = 350;
      var hpbarw = 0;
      if (currtoppos == 0) {
        //bigger, currency at top
        var currencywidth = Math.max(wh.resb_diamondt.displayWidth, wh.resb_suppt.displayWidth);
        hpbarw = width - wh.minimapbggui.displayWidth - 56 - currencywidth - 40;
      } else {
        //smaller, currency pushed down
        hpbarw = width - wh.minimapbggui.displayWidth - 56 - 14;
      }
      if (hpbarw > maxhpbarw) hpbarw = maxhpbarw;
      wh.xpbg.displayWidth = hpbarw;
      wh.hpbg.displayWidth = hpbarw;
      var xpneed =
        wh.registry.list.rcvarpass.level[
        wh.registry.list.rcvarpass.activechar['level']
        ]['xp'];
      var curxp = wh.registry.list.rcvarpass.activechar['xp'];

      wh.charxpbar.bar.setDepth(50000);

      wh.charxpbar.bar.x = wh.xpbg.x;
      wh.charxpbar.bar.y = wh.xpbg.y;
      wh.charxpbar.bar.displayHeight = wh.xpbg.displayHeight;

      wh.charxpbar.setw(wh.xpbg.displayWidth);
      wh.charxpbar.set(curxp, xpneed);
      if (wh.charxptxt != undefined) {
        wh.charxptxt.setOrigin(0, 0);
        wh.charxptxt.setPosition(
          wh.xpbg.x + 5,
          wh.xpbg.y - 1
        );
        var curxpdsp = wh.registry.list.gameitf.humannumber(curxp);

        //wh.charxptxt.setText('XP ' + curxpdsp + ' / ' + xpneed);
        wh.charxptxt.displayWidth = wh.charxptxt.width;
      }
      if (wh.clock_bg != undefined) {
        //this.plog.log("wh.clock_bg", wh.clock_bg);
        var clocksize = 36;
        var clockvhmargin = 40;
        wh.clock_bg.setOrigin(0.5, 0.5);
        wh.clock_bg.setPosition(width - (clocksize / 2 + 2), clockvhmargin);
        wh.clock_bg.displayWidth = wh.clock_bg.displayHeight = clocksize;
        wh.clock_bg.setDepth(60000);
        wh.clock_hh.setOrigin(0.5, 0.5);
        wh.clock_hh.setDepth(60010);
        wh.clock_hh.setPosition(wh.clock_bg.x, wh.clock_bg.y);
        wh.clock_dot.displayWidth = wh.clock_dot.displayHeight = 8;
        wh.clock_dot.setDepth(60020);
      }

      if (wh.biglightsource != undefined) {
        wh.registry.list.timectl.setlightpos();
      }


      if (wh.chatinputbox != undefined) {
        var boxw = width;
        if (boxw > 500) boxw = 500;
        var boxh = 24;
        wh.chatinputbox.resize(boxw, boxh);
        wh.chatinputbox.setPosition(width / 2, height - (boxh / 2 + 4));
      }

      if (wh.charhptxt != undefined) {

        var hpmax = wh.registry.list.rcvarpass.activechar['cbt']['maxhp'];
        var curhp = wh.registry.list.rcvarpass.activechar['cbt']['hp'];
        //wh.charhptxt.setText('HP ' + curhp + ' / ' + hpmax);
        wh.charhptxt.setOrigin(0, 0);

        wh.charhptxt.setPosition(
          wh.hpbg.x + 5,
          wh.hpbg.y - 1
        );
      }
      if (wh.charhpbar != undefined) {
        wh.charhpbar.bar.setDepth(50000);
        wh.charhpbar.bar.x = wh.hpbg.x;
        wh.charhpbar.bar.y = wh.hpbg.y;
        //wh.charhpbar.bar.displayWidth = wh.hpbg.displayWidth;
        wh.charhpbar.bar.displayHeight = wh.hpbg.displayHeight;
        wh.charhpbar.setw(wh.hpbg.displayWidth);
        wh.charhpbar.set(curhp, hpmax);

        var currencywidthhp = 0;
        if (currtoppos == 0) {
          //big
        } else {
          //small
          currencywidthhp = Math.max(wh.resb_goldt.displayWidth, wh.resb_diamondt.displayWidth, wh.resb_suppt.displayWidth) + 27;
        }
        var hpiconright = 182;
        if (wh.minimap.visible == false) {
          if (currtoppos != 0) {
            //big
            hpiconright = 56;
          } else {
            //sml
            hpiconright = 115;
          }
        } else {
          //hpiconright=hpiconright+currencywidthhp;
        }
        wh.healicon.setPosition(width - hpiconright - currencywidthhp, 88);
        wh.healicon.displayWidth = wh.healicon.displayHeight = 48;
        wh.healicondis.setPosition(width - hpiconright - currencywidthhp, 88);
        wh.healicondis.displayWidth = wh.healicondis.displayHeight = 48;
        wh.healicon.setDepth(50000);
        wh.healicondis.setDepth(50000);
        wh.healiconi.setDepth(50001);
        wh.healiconi.setPosition(width - (hpiconright + 2) - currencywidthhp, 88 + 3);
        wh.healiconi.displayWidth = wh.healiconi.displayHeight = 32;

        if (wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
          wh.healiconi.visible = false;
        } else {
          wh.healiconi.visible = true;
        }

      }

      if (wh.bc_bg != undefined) {
        wh.bc_bg.setOrigin(0, 0);
        wh.bc_bg.setPosition(0, 70);
        wh.bc_bg.setAlpha(0.7);
        wh.bc_bg.displayWidth = width;
        wh.bc_bg.displayHeight = 35;
        wh.bc_bg.setDepth(49900);
        wh.bc_text.setDepth(49901);
        wh.bc_text.setOrigin(0, 0);
        wh.bc_text.setPosition(0, 75);
      }
    }

    if (wh.hpbg != undefined && wh.xpbg != undefined) {
      wh.gifscontainer.bringToTop(wh.hpbg);
      wh.gifscontainer.bringToTop(wh.xpbg);
    }
    if (wh.charhptxt != undefined && wh.charxptxt != undefined) {
      wh.gifscontainer.bringToTop(wh.charhptxt);
      wh.gifscontainer.bringToTop(wh.charxptxt);
    }
    var mnmsize = 158;
    var mnmmargin = 0;
    var mapsmallerthanframe = 20;

    if (wh.minimap != undefined && wh.shapemnm != undefined && wh.minimapbggui != undefined && wh.minimapdot != undefined) {
      //console.error(wh);
      wh.minimap.setDepth(50002);
      wh.minimap.setOrigin(0, 0);
      wh.minimap.setPosition(
        width - (mnmsize / 2 + mnmmargin),
        mnmsize / 2 + mnmmargin
      );
      wh.shapemnm.displayWidth = wh.shapemnm.displayHeight = mnmsize - mapsmallerthanframe;
      wh.shapemnm.setPosition(
        width - mnmmargin - (mapsmallerthanframe / 2),
        (mnmsize / 2 + mnmmargin)
      );
      //this.plog.log(wh);
      wh.minimapbggui.setPosition(
        width - (mnmsize / 2 + mnmmargin),
        mnmsize / 2 + mnmmargin
      );
      wh.minimapbggui.displayWidth = mnmsize;
      wh.minimapbggui.displayHeight = mnmsize;
      wh.minimapdot.setPosition(
        width - (mnmsize / 2 + mnmmargin),
        mnmsize / 2 + mnmmargin
      );
      wh.minimapbggui_black.setPosition(wh.shapemnm.x - (wh.shapemnm.displayWidth / 2), wh.shapemnm.y);
      wh.minimapbggui_black.displayWidth = wh.shapemnm.displayWidth;
      wh.minimapbggui_black.displayHeight = wh.shapemnm.displayHeight;
      if (wh.minimap.visible == false) {
        wh.minimapbggui_black.visible = false;
      } else {
        wh.minimapbggui_black.visible = true;
      }
      wh.minimapbggui_black.setDepth(wh.minimap.depth + 1);
      try {
        //wh.gifscontainer.moveAbove(wh.minimap, wh.minimapbggui_black);;
      } catch (e) {
        //console.log(e,wh.minimap,wh.minimapbggui_black);
      }
      wh.registry.list.gameitf.updateminimap(wh);
    }

    if (wh.gamemenu_main != undefined) {
      var padding = 6;
      var iconwidth = 64;
      var iconspacing = 5;
      wh.gamemenu_main.setPosition(
        padding + iconwidth / 2,
        height - (padding + iconwidth / 2)
      );
      wh.gamemenu_main.displayHeight = iconwidth;
      wh.gamemenu_main.displayWidth = iconwidth;
      wh.gamemenu_main.setDepth(100000);
      //this.plog.log("lkjlkjlkjljjk",wh.homeinvensize);
      if (wh.gamemenu_showing == true) {
        wh.homeinvensize.visible = true;
        wh.homeinvensizestack.visible = true;
      } else {
        wh.homeinvensize.visible = false;
        wh.homeinvensizestack.visible = false;
      }
      if (wh.gamemenu_showing == false) {
        for (let key of Object.keys(wh.gamemenu)) {
          let val = wh.gamemenu[key];
          val.visible = false;
        }
        for (let key of Object.keys(wh.gamemenudis)) {
          let val = wh.gamemenudis[key];
          val.visible = false;
        }
        //return;
      }
      /////////////////////pos1
      var i = 1;
      for (let key of Object.keys(wh.gamemenu)) {
        let val = wh.gamemenu[key];
        //this.plog.log("xxx",val)
        var tmpnamea = val.name.split('-');
        if (tmpnamea[1] != 1) continue;
        //this.plog.log("gamemenu resize", wh.gamemenu, val);

        if (width > height) {
          val.setPosition(
            padding + i * iconwidth + i * iconspacing + iconwidth / 2,
            height - (padding + iconwidth / 2)
          );
        } else {
          val.setPosition(
            padding + iconwidth / 2,
            height - (padding + i * iconwidth + iconwidth / 2)
          );
        }
        //var tmpicon = wh.add.image(wh.cameras.main.width / 2, wh.cameras.main.height / 2, 'icon_'+val.code);
        //wh.gamemenu[wh.gamemenu.length]=tmpicon;
        val.displayHeight = iconwidth;
        val.displayWidth = iconwidth;
        val.setDepth(100000);
        i++;
      }
      i = 1;
      for (let key of Object.keys(wh.gamemenudis)) {
        let val = wh.gamemenudis[key];
        var tmpnamea = val.name.split('-');
        if (tmpnamea[1] != 1) continue;
        //this.plog.log("gamemenu resize", wh.gamemenudis, val);
        if (width > height) {
          val.setPosition(
            padding + i * iconwidth + i * iconspacing + iconwidth / 2,
            height - (padding + iconwidth / 2)
          );
        } else {
          val.setPosition(
            padding + iconwidth / 2,
            height - (padding + i * iconwidth + iconwidth / 2)
          );
        }
        //var tmpicon = wh.add.image(wh.cameras.main.width / 2, wh.cameras.main.height / 2, 'icon_'+val.code);
        //wh.gamemenu[wh.gamemenu.length]=tmpicon;
        val.displayHeight = iconwidth;
        val.displayWidth = iconwidth;
        val.setDepth(100000);
        i++;
      }

      /////////////////////pos2
      var i = 1;
      for (let key of Object.keys(wh.gamemenu)) {
        let val = wh.gamemenu[key];
        var tmpnamea = val.name.split('-');
        if (tmpnamea[1] != 2) continue;
        //this.plog.log("gamemenu resize", wh.gamemenu, val);

        if (width > height) {
          val.setPosition(
            padding + iconwidth / 2,
            height - (padding + i * iconwidth + iconwidth / 2)
          );
        } else {
          val.setPosition(
            padding + i * iconwidth + i * iconspacing + iconwidth / 2,
            height - (padding + iconwidth / 2)
          );
        }
        //var tmpicon = wh.add.image(wh.cameras.main.width / 2, wh.cameras.main.height / 2, 'icon_'+val.code);
        //wh.gamemenu[wh.gamemenu.length]=tmpicon;
        val.displayHeight = iconwidth;
        val.displayWidth = iconwidth;
        val.setDepth(100000);
        i++;
      }
      i = 1;
      for (let key of Object.keys(wh.gamemenudis)) {
        let val = wh.gamemenudis[key];
        var tmpnamea = val.name.split('-');

        if (tmpnamea[1] != 2) continue;
        //this.plog.log("gamemenu resize", wh.gamemenudis, val);
        if (width > height) {
          val.setPosition(
            padding + iconwidth / 2,
            height - (padding + i * iconwidth + iconwidth / 2)
          );
        } else {
          val.setPosition(
            padding + i * iconwidth + i * iconspacing + iconwidth / 2,
            height - (padding + iconwidth / 2)
          );
        }
        //this.plog.log("dispos2",tmpnamea,val);
        //var tmpicon = wh.add.image(wh.cameras.main.width / 2, wh.cameras.main.height / 2, 'icon_'+val.code);
        //wh.gamemenu[wh.gamemenu.length]=tmpicon;
        val.displayHeight = iconwidth;
        val.displayWidth = iconwidth;
        val.setDepth(100000);
        i++;
      }
      //chk req
      for (let key of Object.keys(wh.gamemenu)) {
        let val = wh.gamemenu[key];
        let val2 = wh.gamemenudis[key];
        var tmpnamea = val.name.split('-');

        var req = wh.scene.scene.registry.list.rcvarpass.gamemenu[tmpnamea[0]];
        //this.plog.log("xxxx",key,req,val,val2);
        var reschk = wh.scene.scene.registry.list.rcreq.chk(req.requirements);
        var reschktxt = wh.scene.scene.registry.list.rcreq.chk(req.requirements, "text");
        if (reschk == false) wh.scene.scene.registry.list.rcvarpass.gamemenu[tmpnamea[0]].name = reschktxt;
        if (wh.gamemenu_showing == true) {
          if (reschk == true) {
            val.visible = true;
            val2.visible = false;
          } else {
            val.visible = false;
            val2.visible = true;

          }
        } else {
          val.visible = false;
          val2.visible = false;
        }
        //this.plog.log("requirements result", reschk);
      }
    }

    this.updatehomeinvensize(wh);
    //this.plog.log("resizer",wh.registry.list.rcloading);
    /*
    if (wh.registry.list.rcloading.loading2bg != undefined) {
      if (wh.registry.list.rcloading.loading2currentpc != 100) {
        wh.registry.list.rcloading.loading2bg.x = 0;
        wh.registry.list.rcloading.loading2bg.y = 0;
        wh.registry.list.rcloading.loading2bg.displayWidth = width;
        wh.registry.list.rcloading.loading2bg.displayHeight = height;
        wh.registry.list.rcloading.loading2txt.setPosition(width / 2, (height / 2) + wh.registry.list.rcloading.loading2txtoffsetheight);
        wh.registry.list.rcloading.loading2percent(wh.registry.list.rcloading.loading2currentpc);
        wh.registry.list.rcloading.loading2text("Loading..");
      }
    }
    */
    //other module gui
    if (wh.registry.list.rctoast != undefined) {
      if (wh.registry.list.rctoast.rcaLconfirmhead != undefined) {
        wh.registry.list.rctoast.rcaLconfirmbgimg.setPosition(
          width / 2,
          height / 2
        );

        wh.registry.list.rctoast.rcaLconfirmbgimg.displayWidth = width;
        wh.registry.list.rctoast.rcaLconfirmbgimg.displayHeight = height;
        var topofdialog =
          height / 2 -
          wh.registry.list.rctoast.rcaLconfirmbgimg.displayHeight / 2; //- buttonh;
        topofdialog =
          topofdialog +
          wh.registry.list.rctoast.rcaLconfirmbgimg.displayHeight * 0.2;
        wh.registry.list.rctoast.rcaLconfirmhead.setPosition(
          width / 2 - wh.registry.list.rctoast.rcaLconfirmhead.displayWidth / 2,
          topofdialog
        );
        wh.registry.list.rctoast.rcaLconfirmtext.setPosition(
          width / 2,
          height / 2
        );
        wh.registry.list.rctoast.rcaLconfirmtext.setStyle({
          wordWrap: { width: width * 0.8, useAdvancedWrap: false },
        });
        var localwidth = Math.min(width, height);
        if (localwidth > 550) localwidth = 550;
        var buttonw = localwidth / 4;
        var buttonh = buttonw * 0.4;
        var btmofdialog =
          height / 2 +
          wh.registry.list.rctoast.rcaLconfirmbgimg.displayHeight / 2 -
          buttonh;

        var btmofdialog =
          height / 2 +
          wh.registry.list.rctoast.rcaLconfirmbgimg.displayHeight / 2 -
          buttonh;
        // ;
        btmofdialog =
          btmofdialog -
          wh.registry.list.rctoast.rcaLconfirmbtn.displayHeight * 0.1; //margin
        wh.registry.list.rctoast.rcaLconfirmbtn.setPosition(
          width / 2,
          btmofdialog
        );
      }
      if (wh.registry.list.rctoast.localmapbg != undefined) {
        wh.registry.list.rctoast.localmapbg.displayWidth = width;
        wh.registry.list.rctoast.localmapbg.displayHeight = height;

        wh.registry.list.rctoast.localmapbgimg.setPosition(
          width / 2,
          height / 2
        );

        wh.registry.list.rctoast.localmapbgimg.setPosition(
          width / 2,
          height / 2
        );
        if (width > height * 0.8) {
          wh.registry.list.rctoast.localmapbgimg.displayHeight = height;
          wh.registry.list.rctoast.localmapbgimg.scaleX =
            wh.registry.list.rctoast.localmapbgimg.scaleY;
        } else {
          wh.registry.list.rctoast.localmapbgimg.displayWidth = width;
          wh.registry.list.rctoast.localmapbgimg.scaleY =
            wh.registry.list.rctoast.localmapbgimg.scaleX;
        }
        wh.registry.list.rctoast.localmapimg.displayWidth =
          wh.registry.list.rctoast.localmapbgimg.displayWidth * 0.6;
        wh.registry.list.rctoast.localmapimg.scaleY =
          wh.registry.list.rctoast.localmapimg.scaleX;
        wh.registry.list.rctoast.localmapimg.setOrigin(0.5, 0);
        wh.registry.list.rctoast.localmapimg.setPosition(
          width / 2,
          wh.registry.list.rctoast.localmapbgimg.y -
          wh.registry.list.rctoast.localmapbgimg.displayHeight * 0.3
        );
        if (
          wh.registry.list.rctoast.localmapimg != undefined &&
          wh.registry.list.rctoast.localmaptext != undefined
        ) {
          try {
            wh.registry.list.rctoast.localmaptext.setWordWrapWidth(
              wh.registry.list.rctoast.localmapimg.displayWidth
            );
          } catch (error) {
            this.plog.log("ERROR", error);
          }

        }
        wh.registry.list.rctoast.localmaptext.setPosition(
          wh.registry.list.rctoast.localmapimg.x -
          wh.registry.list.rctoast.localmapimg.displayWidth / 2,
          wh.registry.list.rctoast.localmapimg.y +
          wh.registry.list.rctoast.localmapimg.displayHeight
        );
      }
      if (wh.registry.list.rctoast.spritedialogbg != undefined) {
        wh.registry.list.rctoast.spritedialogbg.displayWidth = width;
        wh.registry.list.rctoast.spritedialogbg.displayHeight = height;
        wh.registry.list.rctoast.spritedialogbgimg.setPosition(
          width / 2,
          height / 2
        );
        if (width > height * 0.8) {
          wh.registry.list.rctoast.spritedialogbgimg.displayHeight = height;
          wh.registry.list.rctoast.spritedialogbgimg.scaleX =
            wh.registry.list.rctoast.spritedialogbgimg.scaleY;
        } else {
          wh.registry.list.rctoast.spritedialogbgimg.displayWidth = width;
          wh.registry.list.rctoast.spritedialogbgimg.scaleY =
            wh.registry.list.rctoast.spritedialogbgimg.scaleX;
        }
        wh.registry.list.rctoast.spritedialogimg.displayWidth =
          wh.registry.list.rctoast.spritedialogbgimg.displayWidth * 0.6;
        wh.registry.list.rctoast.spritedialogimg.scaleY =
          wh.registry.list.rctoast.spritedialogimg.scaleX;
        wh.registry.list.rctoast.spritedialogimg.setPosition(
          width / 2,
          wh.registry.list.rctoast.spritedialogbgimg.y -
          wh.registry.list.rctoast.spritedialogbgimg.displayHeight * 0.25
        );
        if (wh.registry.list.rctoast.spritedialogtext != undefined &&
          wh.registry.list.rctoast.spritedialogimg != undefined &&
          wh.registry.list.rctoast.spritedialogtext.visible == true &&
          wh.registry.list.rctoast.spritedialogimg.visible == true)
          wh.registry.list.rctoast.spritedialogtext.setWordWrapWidth(
            wh.registry.list.rctoast.spritedialogimg.displayWidth
          );
        wh.registry.list.rctoast.spritedialogtext.setPosition(
          wh.registry.list.rctoast.spritedialogimg.x -
          wh.registry.list.rctoast.spritedialogimg.displayWidth / 2,
          wh.registry.list.rctoast.spritedialogimg.y +
          wh.registry.list.rctoast.spritedialogimg.displayHeight / 2
        );
      }

      if (wh.registry.list.rctoast.spritepaperdialogbg != undefined) {
        wh.registry.list.rctoast.spritepaperdialogbg.displayWidth = width;
        wh.registry.list.rctoast.spritepaperdialogbg.displayHeight = height;
        wh.registry.list.rctoast.spritepaperdialogbgimg.setPosition(
          width / 2,
          height / 2
        );
        if (width > height * 0.8) {
          wh.registry.list.rctoast.spritepaperdialogbgimg.displayHeight =
            height;
          wh.registry.list.rctoast.spritepaperdialogbgimg.scaleX =
            wh.registry.list.rctoast.spritepaperdialogbgimg.scaleY;
        } else {
          wh.registry.list.rctoast.spritepaperdialogbgimg.displayWidth = width;
          wh.registry.list.rctoast.spritepaperdialogbgimg.scaleY =
            wh.registry.list.rctoast.spritepaperdialogbgimg.scaleX;
        }

        wh.registry.list.rctoast.spritepaperdialogtext.setWordWrapWidth(
          wh.registry.list.rctoast.spritepaperdialogbgimg.displayWidth * 0.8
        );
        wh.registry.list.rctoast.spritepaperdialogtext.setPosition(
          width / 2 -
          wh.registry.list.rctoast.spritepaperdialogtext.displayWidth / 2,
          height / 2 -
          wh.registry.list.rctoast.spritepaperdialogtext.displayHeight / 2
        );
      }
    }

    var winablelist = [
      wh.msgscontainer,
      wh.VIEWMYcontainer_item,
      wh.VIEWMYSTACKcontainer_item,
      wh.BUYITEMcontainer_item,
      wh.BUYSTACKITEMcontainer_item,
      wh.petuplevelcontainer,
      wh.sparbcontainer,
      wh.inventorycontainer_stackitem,
      wh.inventorycontainer_item,
      wh.shopcontainer_stackitem,
      wh.shopcontainer_item,
      wh.equipmentcontainer,
      wh.skillcraftcontainer,
      wh.attricontainer,
      wh.skillcontainer,
      wh.questlistcontainer,
      wh.questinfocontainer,
      wh.petguicontainer,
      wh.homeinventorycontainer_stackitem,
      wh.homeinventorycontainer_item,
      wh.pettravelcontainer,
      wh.zcharinfocontainer,
      wh.iapstorecontainer,
      wh.dmcontainer,
      wh.partymapcontainer,
      wh.partymycontainer,
      wh.friendlistcontainer
    ];
    //this.plog.log('winablellist', winablelist);

    for (const k in winablelist) {
      var v = winablelist[k];
      if (v == undefined) continue;
      if (width < 400) {
        v.x = width / 2;
      } else {
        var rightpos = v.x + v.width / 2 + 20;
        if (rightpos > width) {
          v.x = width - v.width / 2 + 20;
        }
      }
      if (height < 600) {
        v.y = height / 2;
      } else {
        var btmpos = v.y + v.height / 2;
        if (btmpos > height) {
          v.y = height - v.height / 2;
        }
      }
      // this.plog.log("winablellist v",v);
    }
    if (wh.theatreicon != undefined) {
      wh.theatreicon.setDepth(50010);
      if (wh.minimap.visible == false) {
        wh.theatreicon.setPosition(width - 74, 74);
        wh.theatreicon.displayWidth = wh.theatreicon.displayHeight = 28;
      } else {
        wh.theatreicon.setPosition(width - 157, 147);
        wh.theatreicon.displayWidth = wh.theatreicon.displayHeight = 28;
      }
      wh.theatreicondis.setDepth(50010);
      if (wh.minimap.visible == false) {
        wh.theatreicondis.setPosition(width - 74, 74);
        wh.theatreicondis.displayWidth = wh.theatreicondis.displayHeight = 28;
      } else {
        wh.theatreicondis.setPosition(width - 157, 147);
        wh.theatreicondis.displayWidth = wh.theatreicondis.displayHeight = 28;
      }
      /*if (wh.registry.list.rcreq.chk({ stackitem1: "theatre", stackitem1_min: 1 })) {
        wh.theatreicon.visible = true;
        wh.theatreicondis.visible = false;
      } else {
        wh.theatreicon.visible = false;
        wh.theatreicondis.visible = true;
      }*/
    }

    if (wh.partyicon != undefined) {
      wh.partyicon.setDepth(50010);
      if (wh.minimap.visible == false) {
        wh.partyicon.setPosition(width - 132, 44);
        wh.partyicon.displayWidth = wh.partyicon.displayHeight = 36;
      } else {
        wh.partyicon.setPosition(width - 125, 156);
        wh.partyicon.displayWidth = wh.partyicon.displayHeight = 36;
      }
    }

    if (wh.msgsicon != undefined) {
      wh.msgsicon.setDepth(50010);
      wh.msgsicondis.setDepth(50010);

      if (wh.minimap.visible == false) {
        wh.msgsicon.setPosition(width - 96, 44);
        wh.msgsicon.displayWidth = wh.msgsicon.displayHeight = 36;
        wh.msgsicondis.setPosition(width - 96, 44);
        wh.msgsicondis.displayWidth = wh.msgsicondis.displayHeight = 36;
      } else {
        wh.msgsicon.setPosition(width - 89, 156);
        wh.msgsicon.displayWidth = wh.msgsicon.displayHeight = 36;
        wh.msgsicondis.setPosition(width - 89, 156);
        wh.msgsicondis.displayWidth = wh.msgsicondis.displayHeight = 36;
      }
    }
    if (wh.friendlisticon != undefined) {
      wh.friendlisticon.setDepth(50010);
      wh.friendlisticon.setDepth(50010);
      if (wh.minimap.visible == false) {
        wh.friendlisticon.setPosition(width - 60, 44);
        wh.friendlisticon.displayWidth = wh.friendlisticon.displayHeight = 36;
      } else {
        wh.friendlisticon.setPosition(width - 53, 156);
        wh.friendlisticon.displayWidth = wh.friendlisticon.displayHeight = 36;
      }
      //wh.friendlisticon.setPosition(width - 28, 200);
      //wh.friendlisticon.displayWidth = wh.friendlisticon.displayHeight = 48;
    }

    if (wh.wisdomcaticon != undefined) {
      wh.wisdomcaticon.setDepth(50010);
      wh.wisdomcaticon.setDepth(50010);

      if (wh.minimap.visible == false) {
        wh.wisdomcaticon.setPosition(width - 42, 74);
        wh.wisdomcaticon.displayWidth = wh.wisdomcaticon.displayHeight = 28;
      } else {
        wh.wisdomcaticon.setPosition(width - 18, 156);
        wh.wisdomcaticon.displayWidth = wh.wisdomcaticon.displayHeight = 36;
      }

      //wh.wisdomcaticon.setPosition(width - 28, 248);
      //wh.wisdomcaticon.displayWidth = wh.wisdomcaticon.displayHeight = 48;
    }

    if (wh.backhomeicon != undefined) {
      wh.backhomeicon.setDepth(50010);
      //wh.backhomeicon.setPosition(width - 82, 220);
      wh.backhomeicon.displayWidth = wh.backhomeicon.displayHeight = 48;

      if (wh.minimap.visible == false) {
        wh.backhomeicon.setPosition(width - 24, 118);
      } else {
        wh.backhomeicon.setPosition(width - 24, 200);
      }
    }
    if (wh.backhomeicon != undefined) {
      //this.plog.log("checking backhomeicon", wh.backhomeicon, wh.registry.list.rcreq.chk({ level_max: 10 }))
      if (wh.registry.list.rcvarpass != undefined &&
        wh.registry.list.rcvarpass.activemap != undefined &&
        wh.registry.list.rcvarpass.activemap["map"] != undefined) {
        if (wh.registry.list.rcvarpass.activemap["map"]["code"] != "map1" &&
          wh.registry.list.rcvarpass.activemap["map"]["code"] != "home" &&
          wh.registry.list.rcreq.chk({ level_max: 10 })) {
          wh.backhomeicon.visible = true;
        } else {
          wh.backhomeicon.visible = false;
        }
        if (wh.registry.list.rcvarpass.activemap["map"]["activemap"] == "YES") {
          wh.backhomeicon.visible = true;
        }
      } else {
        wh.backhomeicon.visible = false;
      }
    }

    if (wh.updateapp != undefined) {
      wh.updateapp.setDepth(50010);
      //wh.backhomeicon.setPosition(width - 82, 220);
      wh.updateapp.displayWidth = wh.updateapp.displayHeight = 48;

      if (wh.minimap.visible == false) {
        wh.updateapp.setPosition(width - 24, 118 + 50);
      } else {
        wh.updateapp.setPosition(width - 24, 200 + 50);
      }
    }
    if (wh.registry.list.rcloading.loading2txt != undefined) {
      //this.plog.log('loading2txtoffsetheight', wh.registry.list.rcloading.loading2txtoffsetheight);
      var localwidth = Math.min(width, height);
      try {
        wh.registry.list.rcloading.loading2txt.setWordWrapWidth(localwidth - 40);
        wh.registry.list.rcloading.loading2txtb.setWordWrapWidth(localwidth - 40);
        wh.registry.list.rcloading.loading2txt.setPosition(
          width / 2 - wh.registry.list.rcloading.loading2txt.displayWidth / 2,
          height / 2 - wh.registry.list.rcloading.loading2txt.displayHeight / 2
        );
        wh.registry.list.rcloading.loading2txtb.setPosition(
          width / 2 - wh.registry.list.rcloading.loading2txtb.displayWidth / 2,
          height / 2 - wh.registry.list.rcloading.loading2txtb.displayHeight / 2
        );
        wh.registry.list.rcloading.loading2bg.displayWidth = width;
        wh.registry.list.rcloading.loading2bg.displayHeight = height;
      } catch (e) {
        console.log(e);
      }
      //wh.registry.list.rcloading.loading2updatedsp();
      var boxsize = 50;
      //this.loading2box.fillRect((width / 2) - (boxsize / 2), (height / 2) - (boxsize / 2), boxsize, boxsize);
      /*wh.registry.list.rcloading.loading2box.fillRect(
        0,
        height / 2 - boxsize / 2,
        width * wh.registry.list.rcloading.loading2currentpc,
        boxsize
      );
      const mask = wh.registry.list.rcloading.loading2box.createGeometryMask();
      wh.registry.list.rcloading.loading2txtb.setMask(mask);*/
    }

    if (
      wh.registry.list.charinfo != undefined &&
      wh.registry.list.charinfo.bg != undefined
    ) {
      this.plog.log('mapresizemanager charinfo');
      wh.registry.list.charinfo.bg.setPosition(
        width / 2,
        height / 2,
        width,
        height
      );
      wh.registry.list.charinfo.bg.displayWidth = width;
      wh.registry.list.charinfo.bg.displayHeight = height;
      if (wh.registry.list.charinfo.empty != undefined) {
        wh.registry.list.charinfo.empty.displayWidth =
          wh.registry.list.charinfo.empty.displayHeight =
          Math.min(width, height) - 40;
        if (wh.registry.list.charinfo.empty.displayWidth > 450) {
          wh.registry.list.charinfo.empty.displayWidth =
            wh.registry.list.charinfo.empty.displayHeight = 450;
        }
        wh.registry.list.charinfo.empty.x = width / 2 + 32;
        wh.registry.list.charinfo.empty.y = height / 2;
        wh.registry.list.charinfo.empty.displayWidth =
          wh.registry.list.charinfo.empty.displayHeight = 250;//overwrites
      }

      var backbtnwh = 60;
      wh.registry.list.charinfo.backbtn.displayWidth =
        wh.registry.list.charinfo.backbtn.displayHeight = backbtnwh;
      wh.registry.list.charinfo.backbtn.setPosition(
        backbtnwh / 2,
        height - backbtnwh / 2
      );

      wh.registry.list.charinfo.bgmain.setPosition(0, height / 2);
      wh.registry.list.charinfo.bgmain.displayWidth = 70;
      wh.registry.list.charinfo.bgmain.displayHeight = height;
      wh.registry.list.charinfo.bgmain2.setPosition(0, height / 2);
      wh.registry.list.charinfo.bgmain2.displayWidth = 140;
      wh.registry.list.charinfo.bgmain2.displayHeight = height;
      wh.registry.list.charinfo.backbtn2.setPosition(width - 15 / 2, 15 / 2);

      //wh.registry.list.charinfo.bg.geom.setSize(W, H);
    }
    if (wh.iconmainsetting != undefined) {
      wh.iconmainsetting.displayWidth = wh.iconmainsetting.displayHeight = 32;
      wh.iconmainsetting.setPosition(width - 20, height - 20);
      wh.iconmainsetting.setDepth(100000);
    }
    if (wh.iconsettings != undefined) {
      wh.iconsettings.displayWidth = wh.iconsettings.displayHeight = 24;
      wh.iconsettings.setPosition(width + 20, height - 55);
      wh.iconsettings.setDepth(100000);
    }
    if (wh.iconchatbubble != undefined) {
      wh.iconchatbubble.displayWidth = wh.iconchatbubble.displayHeight = 24;
      wh.iconchatbubble.setPosition(width + 20, height - 85);
      wh.iconchatbubble.setDepth(100000);
    }
    if (wh.iconbugreport != undefined) {
      wh.iconbugreport.displayWidth = wh.iconbugreport.displayHeight = 24;
      wh.iconbugreport.setPosition(width + 20, height - 115);
      wh.iconbugreport.setDepth(100000);
    }
    if (wh.iconfullscreen != undefined) {
      wh.iconfullscreen.displayWidth = wh.iconfullscreen.displayHeight = 28;
      wh.iconfullscreen.setPosition(width - 10, 74);
      wh.iconfullscreen.setDepth(100000);

      if (wh.scale.isFullscreen) {
        wh.iconfullscreen.setTexture("thesq", "fullscreenexit");
      }
      else {
        wh.iconfullscreen.setTexture("thesq", "fullscreen");
      }
    }
    if (wh.iconmusic != undefined) {
      wh.iconmusic.displayWidth = wh.iconmusic.displayHeight = 24;
      wh.iconmusic.setPosition(width + 20, height - 145);
      wh.iconmusic.setDepth(100000);
      if (wh.isofftmute == true) {
        wh.iconmusic.setTexture("thesq", "mute");
      } else {
        wh.iconmusic.setTexture("thesq", "music");
      }
    }
    ///wh.scene.resume('Preloader');
    //offline
    if (wh.offline_bg != undefined) {
      wh.offline_bg.setDepth(1000000);
      wh.offline_msg.setDepth(1000010);
      wh.offline_retry.setDepth(1000010);
      wh.offline_header.setDepth(1000010);
      wh.offline_header.setPosition((width / 2) - (wh.offline_header.displayWidth / 2), 100);
      wh.offline_msg.setPosition(width / 2 - wh.offline_msg.displayWidth / 2, height / 2);
      wh.offline_retry.setPosition(width / 2, wh.offline_msg.y + (wh.offline_msg.displayHeight) + 50);
      wh.offline_bg.displayWidth = width;
      wh.offline_bg.displayHeight = height;
      wh.offline_bg.setPosition(0, 0);
      wh.offline_bg.setOrigin(0, 0);
      wh.offline_bg.setAlpha(0.8);
    }
    if (
      wh != undefined &&
      wh.registry != undefined &&
      wh.registry.list.iapstore != undefined &&
      wh.registry.list.iapstore.iapviewcontainer != undefined
    ) {
      var rtow = Math.min(800, width) / 800;//this.iapview_bgimg.displayWidth;
      var rtoh = Math.min(800, height) / 800;//this.iapview_bgimg.displayWidth;
      var rto = Math.min(rtow, rtoh)
      wh.registry.list.iapstore.iapviewcontainer.setScale(rto);
      if (wh.registry.list.iapstore.paypalbtn != undefined) {
        wh.registry.list.iapstore.paypalbtn[0].style.top = Math.floor(rto * wh.registry.list.iapstore.paypalbtn[0].origy) + "px";
        wh.registry.list.iapstore.paypalbtn[0].style.left = Math.floor(rto * wh.registry.list.iapstore.paypalbtn[0].origx) + "px";
        this.plog.log("mapresizeman-iapview rto", rto, wh.registry.list.iapstore.paypalbtn[0].origy, wh.registry.list.iapstore.paypalbtn[0].style.top);

      } else {

      }
      if (wh.registry.list.iapstore.stripebtn != undefined) {
        wh.registry.list.iapstore.stripebtn.style.top = Math.floor(rto * wh.registry.list.iapstore.stripebtn_origy) + "px";
        wh.registry.list.iapstore.stripebtn.style.left = Math.floor(rto * wh.registry.list.iapstore.stripebtn_origx) + "px";

      }
    }
    if (wh.itftr1 != undefined &&
      wh.minimapbggui != undefined
    ) {
      wh.itftr1.x = width;
      wh.itftr1.y = 0;
      wh.itftr2.displayHeight = wh.minimapbggui.displayHeight + 5;
      wh.itftr2.displayWidth = wh.minimapbggui.displayWidth + 30;
      wh.itftr2.x = width - wh.minimapbggui.displayWidth - 10;
      if (wh.minimap.visible == false) {
        //wh.itftr2.visible = false;
        wh.itftr2.displayHeight = 72;
        try {
          wh.gifscontainer.moveAbove(wh.itftr2, wh.resb_diamond);;
        } catch (e) {
          console.log(e);
        }
        try {
          wh.gifscontainer.moveAbove(wh.itftr2, wh.resb_gold);;
        } catch (e) {
          console.log(e);
        }
        try {
          wh.gifscontainer.moveAbove(wh.itftr2, wh.resb_supp);;
        } catch (e) {
          console.log(e);
        }
        wh.itftr2.x = width - (wh.minimapbggui.displayWidth - 1);
      } else {
        wh.gifscontainer.sendToBack(wh.itftr2);
        //wh.itftr2.visible = true;
      }
    }/*
    if (wh.screenbg!=undefined) {

      for (const k in wh.screenbg) {
        var v=wh.screenbg[k];
        console.log(k,v);
        v.setOrigin(0,0);
        v.x=0-width/2;
        v.y=0-height/2;
        v.displayWidth=width/wh.cameras.cameras[0].zoom;
        v.displayHeight=height/wh.cameras.cameras[0].zoom;
      }
    }*/
  }
  work(wh) {
    this.RCMapResizeManager_work(wh);
  }

  updatehomeinvensize(wh) {
    //this.plog.log('updatehomeinvensize gamemenu', wh.gamemenu, wh.gamemenu_main);
    if (wh.gamemenu != undefined) {
      for (const k in wh.gamemenu) {
        var v = wh.gamemenu[k];

        var tmpnamea = v.name.split('-');
        //this.plog.log('homeinvensize gamemenu finding', k, tmpnamea[0]);

        if (v != null && tmpnamea[0] == 'stackitem') {
          var countslot = 0;
          var countslotforslot = 0;
          for (const countslotk in wh.registry.list.rcvarpass.activechar['stackitem']) {
            var countslotv = wh.registry.list.rcvarpass.activechar['stackitem'][countslotk];
            var countslotitemdat = wh.registry.list.rcvarpass.allstackitem[countslotv["item"]];
            countslot++;
            if (countslotitemdat == undefined) {
              //this.plog.log("countslotitemdat undefined", countslotv, countslotitemdat);
              continue;
            }
            if (wh.registry.list.rcvarpass.stackitemtype[countslotitemdat["pcode"]]["iscalinven"] != "NO")
              countslotforslot++
          }
          wh.homeinvensizestack.setText(
            countslotforslot + '/' + wh.registry.list.rcvarpass.activechar['stackinvensize']
          );
          //this.plog.log('homeinvensize gamemenu found', k, v, wh.homeinvensizestack);
          wh.homeinvensizestack.x = v.x - wh.homeinvensizestack.width / 2;
          wh.homeinvensizestack.y = v.y - v.displayHeight / 2;
          wh.homeinvensizestack.setDepth(100001);
          wh.homeinvensizestack.visible = v.visible;

          /* if (
             countsloti >
             wh.registry.list.rcvarpass.activechar['stackinvensize'] * 0.9
           ) {
             wh.homeinvensizestack.setCharacterTint(0, 10, true, 0xff0000);
           } else {
             wh.homeinvensizestack.setCharacterTint(0, 10, true, 0x000000);
           }*/
        }
        if (v != null && tmpnamea[0] == 'inventory') {
          var countsloti = 0;
          for (const countslotk in wh.registry.list.rcvarpass.activechar[
            'item'
          ]) {
            countsloti++;
          }
          wh.homeinvensize.setText(
            countsloti +
            '/' +
            wh.registry.list.rcvarpass.activechar['invensize']
          );
          //this.plog.log('homeinvensize gamemenu found', k, v, wh.homeinvensizestack);
          wh.homeinvensize.x = v.x - wh.homeinvensize.width / 2;
          wh.homeinvensize.y = v.y - v.displayHeight / 2;
          wh.homeinvensize.setDepth(100001);
          wh.homeinvensize.visible = v.visible;
          /*
          if (
            countsloti >
            wh.registry.list.rcvarpass.activechar['invensize'] * 0.8
          ) {
            wh.homeinvensize.setCharacterTint(0, 10, true, 0xff0000);
          } else {
            wh.homeinvensize.setCharacterTint(0, 10, true, 0x000000);
          }*/
        }
      }
    }
  }
}
