
import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class ZCharInfoService {
  wh;
  charname;
  selectcircle;
  unequipbtn;
  shopclosebtn;
  itemdescr;
  reportbtn;
  pvpbtn;
  addcontactbtn;
  removecontactbtn;
  blockbtn;
  eqitems = [];
  eqitemsp = [];
  itemwh = 54;
  shopguibg;
  charicon;
  flag;
  data;
  elementicon;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
  }

  async show(wh, id) {
    this.plog.log("ZCharInfoService show", wh, "id=" + id);
    var tg = wh.zmapcharlist[id];
    //this.plog.log(tg);
    if (tg == undefined) {
      this.plog.log("zcharinfo, tg data not defined ", id, tg);
      // return;
    };
    //this.plog.log(tg);
    this.data = await this.rchttp.getcommon2("chargeninfo", { viewid: id });
    this.plog.log(this.data);
    this.wh = wh;
    if (this.wh.zcharinfocontainer != undefined) {
      await this.show_close(wh);
      //return;
    }

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;


    const shopguibg = wh.add.image(0, 0, "zcharinfoguibg");
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    this.shopguibg = shopguibg;
    //this.plog.log("showinventory var", this.rcvarsservice);


    var shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn = shopclosebtn;
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(80, -100);
    shopclosebtn.displayWidth = 40;
    shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.on('pointerdown', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.plog.log("gc.npcbackbtn pointerdown", pointer, localX, localY, event, this);
      this.show_close(wh);
    }, this);

    this.reportbtn = wh.add.sprite(0, 0, "thesb", "report");//wh.add.image(300, 300, 'reportbtn');

    this.reportbtn.setOrigin(0.5, 0.5);
    this.reportbtn.setPosition(60, 100);
    this.reportbtn.displayWidth = 60;

    this.reportbtn.scaleY = this.reportbtn.scaleX;
    this.reportbtn.setInteractive();
    this.reportbtn.name = id;
    
    this.reportbtn.on("pointerover", function () {
      this.scene.registry.list.gameitf.gamemenutooltip(
        this.scene,
        this,
        this.scene.trs("f:zcharinfocontainer:reportbtn===Report Player"),
        'top'
      );
    });
    this.reportbtn.on('pointerdown', async function () {
      //this.scene.registry.list["thethis"].phm.floorclick(this);

      var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh,
        this.wh.trs("f:zcharinfocontainer:Report Confirmation===Report Confirmation"),
        this.wh.trs("f:zcharinfocontainer:Are you sure to report this player?===Are you sure to report this player?"));
      if (cfm == "ok") {
        var msg = await this.wh.registry.list.rctoast.rcentLT(this.wh,
          this.wh.trs("f:zcharinfocontainer:Enter detail===Enter detail"),
          this.wh.trs("f:zcharinfocontainer:Please describe what did player do to deserve a report===Please describe what did player do to deserve a report"));
        this.plog.log("msg", msg);
        await this.wh.registry.list.rchttp.getcommon2("reportuser", { userid: this.reportbtn.name, msg: msg });
        await this.wh.registry.list.rctoast.rcalert(this.wh,
          this.wh.trs("f:zcharinfocontainer:Report Saved===Report Saved"),
          this.wh.trs("f:zcharinfocontainer:Please give some time to investigate your report===Please give some time to investigate your report"));
      }
      this.plog.log("gc.npcbackbtn pointerdown", this);

      this.show_close(wh);
    }, this);

    this.blockbtn = wh.add.sprite(0, 0, "thesb", "block");//wh.add.image(300, 300, 'blockbtn');

    this.blockbtn.setOrigin(0.5, 0.5);
    this.blockbtn.setPosition(-60, 100);
    this.blockbtn.displayWidth = 60;

    this.blockbtn.scaleY = this.blockbtn.scaleX;
    this.blockbtn.setInteractive();
    this.blockbtn.name = id;
    
    this.blockbtn.on("pointerover", function () {
      this.scene.registry.list.gameitf.gamemenutooltip(
        this.scene,
        this,
        this.scene.trs("f:zcharinfocontainer:blockbtn===Block Player"),
        'top'
      );
    });
    this.blockbtn.on('pointerdown', async function () {
      //this.scene.registry.list["thethis"].phm.floorclick(this);

      var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh,
        this.wh.trs("f:zcharinfocontainer:Blocking Confirmation===Blocking Confirmation"),
        this.wh.trs("f:zcharinfocontainer:Are you sure to block this player?===Are you sure to block this player?"));
      if (cfm == "ok") {
        await this.wh.registry.list.rchttp.getcommon2("blockuser", { userid: this.blockbtn.name });
        await this.wh.registry.list.gameitf.charinfoupdated(this.wh);
      }
      this.plog.log("gc.npcbackbtn pointerdown", this);

      this.show_close(wh);
    }, this);


    this.pvpbtn = wh.add.sprite(0, 0, "thesb", "pvp");//wh.add.image(300, 300, 'blockbtn');

    this.pvpbtn.setOrigin(0.5, 0.5);
    this.pvpbtn.setPosition(0, 100);
    this.pvpbtn.displayWidth = 60;

    this.pvpbtn.scaleY = this.pvpbtn.scaleX;
    this.pvpbtn.setInteractive();
    this.pvpbtn.removeAllListeners();
    this.pvpbtn.name = id;
    this.pvpbtn.on("pointerover", function () {
      this.scene.registry.list.gameitf.gamemenutooltip(
        this.scene,
        this,
        this.scene.trs("f:zcharinfocontainer:Just for fun, no reward, no penalty===Just for fun, no reward, no penalty"),
        'top'
      );
    });
    this.pvpbtn.on('pointerdown', async function () {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.scene.plog.log("pvpbtn down", this);
      var opodata = await this.scene.registry.list.rchttp.getcommon2('getpvpoponent',
        { opoid: this.name }
      );
      this.scene.registry.list.combat.start(wh, {
        combatmode: 'pvp',
        opoid: this.name,
        data: opodata,
      });

      this.scene.registry.list.zchari.show_close(this.scene);
      this.scene.registry.list.friendlist.show_close(this.scene);

      //this.show_close(wh);
    });

    this.addcontactbtn = wh.add.sprite(0, 0, "thesb", "contact");//wh.add.image(300, 300, 'blockbtn');

    this.addcontactbtn.setOrigin(0.5, 0.5);
    this.addcontactbtn.setPosition(0, 120);
    this.addcontactbtn.displayWidth = 60;

    this.addcontactbtn.scaleY = this.addcontactbtn.scaleX;
    this.addcontactbtn.setInteractive();
    this.addcontactbtn.removeAllListeners();
    this.addcontactbtn.name = id;
    this.addcontactbtn.on("pointerover", function () {
      this.scene.registry.list.gameitf.gamemenutooltip(
        this.scene,
        this,
        this.scene.trs("f:zcharinfocontainer:Add to contact list===Add to contact list"),
        'btm'
      );
    });
    this.addcontactbtn.on('pointerdown', async function () {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.scene.plog.log("addcontactbtn down", this);
      var opodata = await this.scene.registry.list.rchttp.getcommon2('friendlist_add',
        { opoid: this.name }
      );

      this.scene.registry.list.zchari.show_close(this.scene);
      this.scene.registry.list.friendlist.show(this.scene);
    });


    this.removecontactbtn = wh.add.sprite(0, 0, "thesb", "removecontact");//wh.add.image(300, 300, 'blockbtn');

    this.removecontactbtn.setOrigin(0.5, 0.5);
    this.removecontactbtn.setPosition(0, 120);
    this.removecontactbtn.displayWidth = 60;

    this.removecontactbtn.scaleY = this.removecontactbtn.scaleX;
    this.removecontactbtn.setInteractive();
    this.removecontactbtn.removeAllListeners();
    this.removecontactbtn.name = id;
    this.removecontactbtn.on("pointerover", function () {
      this.scene.registry.list.gameitf.gamemenutooltip(
        this.scene,
        this,
        this.scene.trs("f:zcharinfocontainer:Remove from contact list===Remove from contact list"),
        'btm'
      );
    });
    this.removecontactbtn.on('pointerdown', async function () {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      this.scene.plog.log("removecontactbtn down", this);
      var cfm = await wh.registry.list.rctoast.rcconfirm(wh,
        this.wh.trs("f:zcharinfocontainer:Remove from contact?===Remove from contact?")
      );
      if (cfm == "ok") {
        var opodata = await this.scene.registry.list.rchttp.getcommon2('friendlist_remove',
          { opoid: this.name }
        );
      }

      this.scene.registry.list.zchari.show_close(this.scene);
      this.scene.registry.list.friendlist.show(this.scene);
    });
    /*
        var tmpframe = this.rcvarsservice.gameobj.anims.generateFrameNames(
          this.data["sprite"] + "", {
          prefix: 'walk-d-',
          start: 1,
          end: 2,
          zeroPad: 0
        });
        this.rcvarsservice.gameobj.anims.remove(this.data["sprite"]+'-idle');
        var tmpx = this.rcvarsservice.gameobj.anims.create({
          key: this.data["sprite"]+'-idle',
          frames: tmpframe,
          frameRate: 1,
          repeat: -1
        });*/
    this.charicon = this.rcvarsservice.gameobj.scene.scenes[0].add.sprite(100, 100, this.data["sprite"] + "-idle").setPosition(0, 0).setScale(1.5);//.play('mainchar-idle');//.setDisplayOrigin(0.5, 0.8);
    this.plog.log("Phaser.Textures.FilterMode", Phaser.Textures.FilterMode);
    this.charicon.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
    //Phaser.Textures.FilterMode.LINEAR or Phaser.Textures.FilterMode.NEAREST
    this.elementicon = wh.add.sprite(0, 0, "spacer", "b-1");
    this.flag = wh.add.sprite(0, 0, "spacer", "b-1");

    wh.zcharinfocontainer = wh.add.container(300, 300);
    wh.zcharinfocontainer.name = "zcharinfocontainer";
    wh.zcharinfocontainer.add(this.shopclosebtn);
    wh.zcharinfocontainer.add(this.reportbtn);
    wh.zcharinfocontainer.add(this.blockbtn);
    wh.zcharinfocontainer.add(this.pvpbtn);
    wh.zcharinfocontainer.add(this.addcontactbtn);
    wh.zcharinfocontainer.add(this.removecontactbtn);
    wh.zcharinfocontainer.add(this.flag);
    wh.zcharinfocontainer.add(shopguibg);
    wh.zcharinfocontainer.add(this.elementicon);
    wh.zcharinfocontainer.add(this.charicon);
    wh.zcharinfocontainer.bringToTop(this.elementicon);

    wh.zcharinfocontainer.bringToTop(this.flag);
    this.elementicon.setPosition(48, -32);
    this.flag.setPosition(-60, -55);


    var itemdescr = wh.add.text(0, 0, "..", wh.fs['winguilist_descr']);
    wh.zcharinfocontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(0 - (itemdescr.displayWidth / 2), 20);
    this.itemdescr = itemdescr;

    var charname = wh.add.text(0, 0, "..", wh.fs['winguilist_title']);
    wh.zcharinfocontainer.add(charname);
    charname.setOrigin(0, 0);
    this.charname = charname;

    //drag

    var shopdragbtn = wh.add.image(300, 300, 'spacer');
    wh.shopdragbtn = shopdragbtn;
    wh.shopdragbtn.setOrigin(0.5, 0.5);
    wh.shopdragbtn.setPosition(0, -20);
    shopdragbtn.displayWidth = 190;
    shopdragbtn.displayHeight = 190;
    wh.shopdragbtn.setInteractive();
    wh.shopdragbtn.on('pointerout', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactive = false;
    }, this);
    wh.shopdragbtn.on('pointerup', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactive = false;
    }, this);
    wh.shopdragbtn.on('pointerdown', function (pointer, localX, localY, event) {
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer);

      this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactive = true;
      this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivex = this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivey = this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.y;
      this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivepx = pointer.x;
      this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivepy = pointer.y;
    }, this);
    this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactive = false;
    this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivepy = 0;
    wh.shopdragbtn.on('pointermove', function (pointer, localX, localY, event) {
      //this.scene.registry.list["thethis"].phm.floorclick(this);
      //this.plog.log("shop pointermove", pointer, localX, localY, event, this);
      //this.showinventory_close(wh);
      if (this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactive == true) {
        var tmpx = this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivex;
        var tmpy = this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivey;
        var tmppx = this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivepx;
        var tmppy = this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.dragactivepy;
        var movedx = tmppx - pointer.x;
        var movedy = tmppy - pointer.y;
        //this.plog.log("drag", tmppx, pointer.x, movedx);

        this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.x = tmpx - movedx;
        this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.y = tmpy - movedy;
        //this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.y = pointer.y;
      }
    }, this);
    wh.zcharinfocontainer.add(wh.shopdragbtn);
    wh.zcharinfocontainer.bringToTop(wh.shopdragbtn);
    wh.zcharinfocontainer.bringToTop(this.shopclosebtn);
    wh.zcharinfocontainer.bringToTop(this.reportbtn);
    wh.zcharinfocontainer.bringToTop(this.blockbtn);
    wh.zcharinfocontainer.bringToTop(this.pvpbtn);
    wh.zcharinfocontainer.bringToTop(this.addcontactbtn);
    wh.zcharinfocontainer.bringToTop(this.removecontactbtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.zcharinfocontainer.setSize(shopguibg.width, shopguibg.height);
    wh.zcharinfocontainer.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/
    let loader = new Phaser.Loader.LoaderPlugin(wh);


    //move
    wh.zcharinfocontainer.x = width - (this.shopguibg.width / 2);

    /*
    //move to spritepack
        for (var vv in equipment) {
          var vdat = equipment[vv];
          var itemdat = this.rcvarsservice.allitem[vdat.item];
          loader.image(vdat["item"], this.rcvarsservice.rc_baseurl + "item/" + itemdat["pcode"] + "/" + itemdat["sprite"] + ".png");
    
          this.plog.log("load shop ", vv, vdat, itemdat);
        }*/
    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log("Phaser.Loader.Events.COMPLETE");
      this.plog.log(this);
      this.show_loaded();
    }, this);
    loader.start();
  }

  show_loaded() {
    var wh = this.wh;
    this.plog.log("show_loaded", wh, this, this.data);

    if (this.data["isfriend"] == "yes") {
      this.addcontactbtn.visible = false;
      this.removecontactbtn.visible = true;
    } else {
      this.addcontactbtn.visible = true;
      this.removecontactbtn.visible = false;
    }
    this.flag.setTexture("spacer", "b-1");

    if (this.data["flag"] != undefined && this.data["flag"] != "") {
      this.flag.setTexture("flag", this.data["flag"]);
    }

    this.flag.displayHeight = 20;
    this.flag.displayWidth = 30;
    this.charname.setText(this.data["name"]);
    this.charname.setPosition(0 - (this.charname.displayWidth / 2), -105);
    this.itemdescr.setText(" ");
    if (this.data["dtl"] != undefined && this.data["dtl"] != "") {
      this.itemdescr.setText(this.rcvarsservice.allrace[this.data["race"]]["name"] + " Level " + this.data["level"] + " (" + ((this.data['gender'] + "")) + ")" + "\nActive: " + this.data["dtl"]);
    } else {
      this.itemdescr.setText(this.rcvarsservice.allrace[this.data["race"]]["name"] + " Level " + this.data["level"] + "\nBorn: " + this.data["createdt"]);
    }
    this.itemdescr.setPosition(0 - (this.itemdescr.displayWidth / 2), 50);

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.elementicon.visible = false;

    if (this.data["element"] != "") {
      var tmpelement = this.data["element"];
      if (tmpelement == "wind") tmpelement = "air";
      this.elementicon.setTexture("element", "element_" + tmpelement).play("element_" + tmpelement);
      this.elementicon.visible = true;

      this.elementicon.displayWidth = this.elementicon.displayHeight = 16;
      this.elementicon.name = this.data["element"];
      this.elementicon.removeAllListeners();
      this.elementicon.setInteractive();
      this.elementicon.on("pointerover", function () {
        this.scene.plog.log("elementicon over", this);
        this.scene.registry.list.gameitf.gamemenutooltip(
          this.scene,
          this,
          this.scene.trs("f:gifscontainer:element-" + this.name + "===element-" + this.name) + " ",
          'right'
        );
      });
      this.plog.log("elementicon ", this.elementicon);
    } else {
      this.elementicon.removeAllListeners();
    }
    this.elementicon.displayWidth = this.elementicon.displayHeight = 32;
    //this.plog.log("eqitems", this, this.eqitems);

    this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer.bringToTop(this.elementicon);

    this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].zcharinfocontainer);
    this.charicon.setTexture('charava', this.data['sprite']);
    /*if (this.wh.anims.anims.entries[this.data["sprite"] + "-idle"] == undefined) {
      this.charicon.setTexture('charava', this.data['sprite']);
      this.plog.log("anims not found", this, this.eqitems);
    } else {
      this.charicon.play(this.data["sprite"] + "-idle");
    }*/
    this.charicon.flipX = true;
    if (this.wh.anims.anims.entries[this.data["sprite"] + "-idle"] != undefined) {
      //this.charicon.setTexture(this.data["sprite"] + "-idle");
      //this.charicon.play(this.data["sprite"] + "-idle");
    }
    var equipment = this.rcvarsservice.activechar["equip"];
    this.plog.log("equipment list", equipment);
  }



  async show_close(wh) {
    wh.zcharinfocontainer.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.zcharinfocontainer);

    for (const k in this.wh.zcharinfocontainer.list) {
      try {
        await this.wh.zcharinfocontainer.remove(this.wh.zcharinfocontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! zcharinfocontainer.destroy() ", e);
      }
    }
    for (const k in this.wh.zcharinfocontainer.list) {
      try {
        await this.wh.zcharinfocontainer.remove(this.wh.zcharinfocontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! zcharinfocontainer.destroy() ", e);
      }
    }

    try {
      await this.wh.zcharinfocontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! zcharinfocontainer.destroy() ", e);
    }
    try {
      await this.wh.zcharinfocontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! zcharinfocontainer.destroy() ", e);
    }
    this.wh.zcharinfocontainer = undefined;
  }

}
