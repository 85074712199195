import { RCVarsService } from "../rcvars-service.service";
import { PhaserMapResizeManagerService } from "../phaser-map-resize-manager.service";
import { Injectable } from '@angular/core';
import VirtualJoyStick from "phaser3-rex-plugins/plugins/virtualjoystick";
import { Component } from '@angular/core';
import { PhaserMapService } from "../phaser-map.service";
import { GameInterfaceService } from "../game-interface.service";
import { Scene } from "phaser";
import { AStarFinder } from "astar-typescript";
import { plog } from "../plog.service";
import { intl } from "../intl.service";

//import { SCRW } from '../scrw.service';

@Injectable({
    providedIn: 'root'
})/*@Injectable({
    providedIn: 'root'
})*/
@Component({
    providers: [RCVarsService]
})

export class Preloader extends Phaser.Scene {
    spriteliveloaded = [];
    worldmap;
    worldmap2;
    moblocaladdbackbtn;
    worldmap_gesturetakecontrol = false;
    mobilescreensize = 450;
    settingiconactivetime = 0;
    npcfacespriteevent;
    focusguil;
    focusguir;
    flybysprite;
    charrarityeff;
    combatosdflashcount = 0;
    lastcombattoken = "";
    combateffcount = 0;
    playerName;
    walkermode = false;
    showfpsmode = false;
    //peacepos
    defaultmaplayerx = -16;
    defaultmaplayery = -16;
    activemapdead = false;
    mapclickworking = false;//so mouse hold won't intefere with click walk
    lastmousedownobj;
    jumping = false;
    guarding = false;
    charhitbullettimeout = 0;
    screenbg = [];
    activewindow;
    maincharelement;
    maincharrarity;
    kbfocus = ""; //interact with keyboard and physical joy
    kbfocuslv1 = ""; //calculate this first, for overlay guis
    kbfocusi = "";
    volume;
    extradestroylist = [];//todestroy when change map
    bgmsetting;
    public isofftmute = false;
    zmapchatwini = [];
    zmapchatwint = [];
    lastpossaysec = 100;
    charsaybubble_txt;
    gamemenuloading;
    mapsayitems = [];// to store and clearall mapsay
    birdonground = 0;
    //_load;
    fs = {
        charsaybubble: {
            fontFamily: 'ffff,uuuu',
            fontSize: '8px',
            align: 'center',
            padding: { top: 3, bottom: 3 },
            color: '#000000',
            shadow: { stroke: true, fill: true, color: '#0D0D0D', blur: 2 },
            resolution: 4
        },
        dmtext: {
            fontFamily: 'ffff,uuuu',
            fontSize: '10px',
            align: 'center',
            padding: { top: 3, bottom: 3 },
            color: '#000000',
            shadow: { stroke: true, fill: true, color: '#0D0D0D', blur: 2 },
            resolution: 4
        },
        npcconver_npc: {
            fontFamily: 'ffff,uuuu',
            fontSize: "12px",
            color: '#000000',
            align: 'left',
            padding: { top: 5, bottom: 5 },
            wordWrap: { useAdvancedWrap: true },
            boundsAlignH: 'left',
            lineSpacing: -6,
            resolution: 12
        },
        npcconver_my: {
            fontFamily: 'ffff,uuuu',
            fontSize: "12px",
            fill: '#000',
            boundsAlignH: 'center',
            boundsAlignV: 'middle',
            padding: { top: 5, bottom: 5 },
            wordWrap: { useAdvancedWrap: true },
            lineSpacing: -6,
            resolution: 12,
            maxLines: 2
        },
        npcmapname: {
            fontFamily: 'ffff,uuuu',
            fontSize: '8px',
            color: '#fff',
            stroke: '#02A8DF',
            strokeThickness: 2,
            shadow: { color: '#070707', fill: true, blur: 16, stroke: true },
            resolution: 4
        },
        onscreenchat: {
            fontFamily: 'ffff,uuuu',
            fontSize: '9px',
            color: '#fff',
            stroke: '#02A8DF',
            strokeThickness: 2,
            shadow: { color: '#070707', fill: true, blur: 16, stroke: true },
            resolution: 4
        },
        pageheader: {
            fontFamily: 'ffff,uuuu',
            fontSize: '30px',
            color: '#F64900',
            stroke: '#FFFFFF',
            strokeThickness: 4,
            resolution: 4
        },
        pageheader2: {
            fontFamily: 'ffff,uuuu',
            fontSize: '20px',
            color: '#F64900',
            stroke: '#FFFFFF',
            strokeThickness: 4,
            resolution: 4
        },
        iap_limit: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '10px',
            color: '#0552AD',
            stroke: '#7ECDBF',
            strokeThickness: 2,
            shadow: { color: '#FFFFFF', fill: true, blur: 11, stroke: true },
            resolution: 4
        },
        iap_itemtitle: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '14px',
            color: '#0552AD',
            fontStyle: 'normal',
            stroke: '#CAFFF5',
            strokeThickness: 4,
            shadow: { color: '#FFFFFF', fill: true, blur: 4, stroke: true },
            resolution: 2,

            lineSpacing: -6,
        },
        iap_itemtitledis: {
            fontFamily: 'uuuu',
            fontSize: '14px',
            color: '#000000',
            fontStyle: 'normal',
            stroke: '#777777',
            strokeThickness: 4,
            shadow: { color: '#FFFFFF', fill: true, blur: 4, stroke: true },
            resolution: 2
        },
        iap_itemprice: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '14px',
            color: '#0552AD',
            stroke: '#7ECDBF',
            strokeThickness: 2,
            shadow: { color: '#FFFFFF', fill: true, blur: 11, stroke: true },
            resolution: 4,
        },
        iap_itempricedis: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '16px',
            color: '#000000',
            stroke: '#777777',
            strokeThickness: 2,
            shadow: { color: '#FFFFFF', fill: true, blur: 11, stroke: true },
            resolution: 4
        },
        iapviewhead: {
            fontFamily: 'ffff,uuuu',
            fontSize: '30px',
            color: '#FFE69F',
            //backgroundColor: '#FFFFFF',
            fontStyle: 'normal',
            stroke: '#682B00',
            strokeThickness: 4,
            shadow: { color: '#000000', fill: true, blur: 4, stroke: true },
            resolution: 2
        },
        head1: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '22px',
            color: '#FFE69F',
            stroke: '#262626',
            strokeThickness: 2,
            shadow: { color: '#000000', fill: true, blur: 8, offsetX: 0, offsetY: 0, stroke: true },
            resolution: 4
        },
        iapviewdescr: {
            fontFamily: 'ffff,uuuu',
            fontSize: '22px',
            color: '#000000',
            //backgroundColor: '#FFFFFF',
            fontStyle: 'normal',
            stroke: '#FFFFFF',
            strokeThickness: 6,
            shadow: { color: '#000000', fill: true, blur: 6, stroke: true },
            resolution: 8,
            lineSpacing: -6,
        },
        descr1: {
            fontFamily: 'ffff,uuuu',
            fontSize: '11px',
            color: '#000000',
            fontStyle: 'normal',
            stroke: '#FFFFFF',
            strokeThickness: 1,
            shadow: { color: '#000000', fill: true, blur: 4, offsetX: 2, offsetY: 2, stroke: true },
            resolution: 4
        },
        iapviewprice: {
            fontFamily: 'ffff,uuuu',
            fontSize: '38px',
            color: '#FFEDD8',
            //backgroundColor: '#FFFFFF',
            fontStyle: 'normal',
            stroke: '#C84D07',
            strokeThickness: 3,
            shadow: { color: '#000000', fill: true, blur: 4, stroke: true },
            resolution: 2
        },
        price1: {
            fontFamily: 'ffff,uuuu',
            fontSize: '22px',
            color: '#FFEDD8',
            fontStyle: 'bold',
            stroke: '#F5A623',
            strokeThickness: 3,
            shadow: { color: '#000000', fill: true, blur: 7, offsetX: 2, offsetY: 2, stroke: true },
            resolution: 4
        },

        questinfo_title: {
            fontFamily: 'aaaa',
            fontStyle: 'normal',
            fontSize: '28px',
            color: '#ffffff',
            stroke: '#CE7A00',
            strokeThickness: 3,
            shadow: { color: '#F5A623', fill: true, blur: 20, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        questinfo_obj: {
            fontFamily: 'ffff,uuuu',
            fontSize: '10px',
            color: '#670000',
            shadow: { color: '#000000', fill: true, blur: 3 },
            resolution: 4
        },
        questinfo_txt: {
            fontFamily: 'ffff,uuuu',
            fontSize: '8px',
            color: '#000000',
            shadow: { color: '#000000', fill: true, blur: 3 },
            resolution: 4
        },
        questinfo_hint: {
            fontFamily: 'ffff,uuuu',
            fontSize: '8px',
            color: '#000000',
            shadow: { color: '#000000', fill: true, blur: 3 },
            resolution: 4,
            padding: { top: 3, bottom: 3 },
            backgroundColor: '#FFFFFF'
        },
        winguibardescr: {
            fontFamily: 'ffff,uuuu',
            padding: { top: 5, bottom: 5 },
            //fontStyle: 'bold',
            fontSize: '10px',
            color: '#fff',
            stroke: '#CE7A00',
            strokeThickness: 3,
            shadow: { color: '#F5A623', fill: true, blur: 20, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        winguibardescrred: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '12px',
            color: '#D0021B',
            stroke: '#FFFFFF',
            strokeThickness: 3,
            shadow: { color: '#FFFFFF', fill: true, blur: 20, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },/*
        winguititle: {
            fontFamily: 'aaaa',
            fontStyle: 'normal',
            fontSize: '36px',
            color: '#ffffff',
            stroke: '#CE7A00',
            strokeThickness: 3,
            shadow: { color: '#F5A623', fill: true, blur: 20, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },*/
        winguititle: {
            fontFamily: 'aaaa',
            fontStyle: 'normal',
            fontSize: '36px',
            color: '#ffffff',
            stroke: '#CE7A00',
            strokeThickness: 3,
            shadow: { color: '#F5A623', fill: true, blur: 20, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },

        winguititle42: {
            fontFamily: 'aaaa',
            fontStyle: 'normal',
            fontSize: '42px',
            color: '#ffffff',
            stroke: '#CE7A00',
            strokeThickness: 3,
            shadow: { color: '#F5A623', fill: true, blur: 20, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        winguiitemname: {
            fontFamily: 'ffff,uuuu',
            fontSize: '12px',
            color: '#000000',
            fontStyle: 'normal',
            stroke: '#FFFFFF',
            strokeThickness: 1,
            shadow: { color: '#000000', fill: true, blur: 4, offsetX: 2, offsetY: 2, stroke: true },
            resolution: 4
        },
        winguiitemplus: {
            fontFamily: 'ffff,uuuu',
            fontSize: '10px',
            color: '#000000',
            fontStyle: 'normal',
            stroke: '#FFFFFF',
            strokeThickness: 1,
            shadow: { color: '#000000', fill: true, blur: 4, offsetX: 2, offsetY: 2, stroke: true },
            resolution: 4
        },
        winguiitemdescr: {
            fontFamily: 'uuuu',
            fontSize: '10px',
            color: '#422D00',
            padding: { top: 5, bottom: 5 },
            shadow: { color: '#E89903', fill: true, blur: 20, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 8
        },
        scrw_badgehead: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '8px',
            color: '#FFFFFF',
            stroke: '#E9BC81',
            strokeThickness: 2,
            padding: { top: 5, bottom: 5 },
            shadow: { color: '#583500', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 12
        },
        scrw_badgedescr: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '8px',
            color: '#444444',
            stroke: '#E9BC81',
            strokeThickness: 0,
            padding: { top: 5, bottom: 5 },
            shadow: { color: '#835F00', fill: true, blur: 0, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 12
        },
        winguilist_title: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '12px',
            color: '#000000',
            stroke: '#E9BC81',
            strokeThickness: 2,
            padding: { top: 5, bottom: 5 },
            shadow: { color: '#583500', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 12
        },
        winguilist_descr: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '8px',
            color: '#444444',
            stroke: '#E9BC81',
            strokeThickness: 0,
            padding: { top: 5, bottom: 5 },
            shadow: { color: '#835F00', fill: true, blur: 0, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 12
        },
        winguilist_descr_black: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '8px',
            color: '#000000',
            stroke: '#E9BC81',
            strokeThickness: 0,
            padding: { top: 5, bottom: 5 },
            shadow: { color: '#835F00', fill: true, blur: 0, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 12
        },
        winguilist_descr_green: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '8px',
            color: '#3D7200',
            stroke: '#E9BC81',
            padding: { top: 5, bottom: 5 },
            shadow: { color: '#835F00', fill: true, blur: 0, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 12
        },
        wingui_plus: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '8px',
            color: '#FFFFFF',
            stroke: '#001CFF',
            strokeThickness: 2,
            shadow: { color: '#007D9C', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        iapviewwingui_amnt: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '26px',
            color: '#FFFFFF',
            stroke: '#000000',
            strokeThickness: 2,
            shadow: { color: '#0009BA', fill: true, blur: 7, stroke: true, offsetX: 0, offsetY: 0 },
            resolution: 5
        },
        petuplevel_diamond: {
            fontFamily: 'ffff,uuuu',
            fontSize: '10px',
            color: '#4A90E2',
            stroke: '#080070',
            strokeThickness: 2,
            shadow: { color: '#000000', fill: true, blur: 18 },
            resolution: 4
        },
        wingui_amnt: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '8px',
            color: '#FFFFFF',
            stroke: '#000000',
            strokeThickness: 2,
            shadow: { color: '#0009BA', fill: true, blur: 7, stroke: true, offsetX: 0, offsetY: 0 },
            resolution: 5
        },
        playername: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '12px',
            color: '#FFFFFF',
            stroke: '#825100',
            //stroke: '#F5A623',
            strokeThickness: 2,
            shadow: { color: '#000000', fill: true, blur: 20, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 5
        },
        attri_blue_lm: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '10px',
            boundsAlignH: 'left',
            boundsAlignV: 'middle',
            color: '#FFFFFF',
            stroke: '#0077FF',
            strokeThickness: 2,
            shadow: { color: '#000000', fill: true, blur: 18, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 5
        },
        attrip_blue_lm: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '12px',
            boundsAlignH: 'left',
            boundsAlignV: 'middle',
            color: '#0900FF',
            stroke: '#000000',
            shadow: { color: '#0656D3', fill: true, blur: 8, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 5
        },
        attrip_black_lm: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '10px',
            boundsAlignH: 'left',
            boundsAlignV: 'middle',
            color: '#000000',
            stroke: '#000000',
            shadow: { color: '#8D8D8D', fill: true, blur: 8, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 5
        },
        attrip_black_lmdsp: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '10px',
            boundsAlignH: 'left',
            boundsAlignV: 'middle',
            color: '#000000',
            stroke: '#000000',
            shadow: { color: '#8D8D8D', fill: true, blur: 8, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 5
        },
        attrip_black_sm: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '10px',
            boundsAlignH: 'left',
            boundsAlignV: 'middle',
            color: '#000000',
            stroke: '#000000',
            shadow: { color: '#8D8D8D', fill: true, blur: 8, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 5
        },
        attrip_black_sm_r: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '10px',
            boundsAlignH: 'right',
            boundsAlignV: 'right',
            align: 'right',
            color: '#000000',
            stroke: '#000000',
            shadow: { color: '#8D8D8D', fill: true, blur: 8, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 5
        },
        attri_black_lm: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '10px',
            boundsAlignH: 'left',
            boundsAlignV: 'middle',
            color: '#FFFFFF',
            stroke: '#000000',
            strokeThickness: 2,
            shadow: { color: '#000000', fill: true, blur: 18, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 5
        },
        attri_black_sm: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '8px',
            boundsAlignH: 'left',
            boundsAlignV: 'middle',
            color: '#FFFFFF',
            stroke: '#000000',
            strokeThickness: 2,
            shadow: { color: '#000000', fill: true, blur: 18, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 5
        },
        list_title: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '12px',
            color: '#FFFFFF',
            stroke: '#E9BC81',
            strokeThickness: 2,
            shadow: { color: '#583500', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 12
        },
        list_descr: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '8px',
            color: '#666666',
            stroke: '#E9BC81',
            shadow: { color: '#835F00', fill: true, blur: 0, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 12
        },
        list_dmg: {
            fontFamily: 'ffff,uuuu',
            fontSize: '16px',
            color: '#FF7272',
            stroke: '#5D0000',
            strokeThickness: 1,
            shadow: { stroke: true, fill: true, color: '#000000', blur: 7 },
            resolution: 3
        },

        spinner_txt: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '20px',
            color: '#0F4DAA',
            stroke: '#E2E2FF',
            strokeThickness: 4,
            padding: { top: 8, bottom: 8 },
            shadow: { stroke: true, fill: true, blur: 15, color: '#A4A4A4' },
            resolution: 4
        },

        showreward_plus: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '10px',
            color: '#FFFFFF',
            stroke: '#001CFF',
            strokeThickness: 2,
            shadow: { color: '#007D9C', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        showreward_amnt: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '12px',
            color: '#FFFFFF',
            stroke: '#000000',
            strokeThickness: 2,
            shadow: { color: '#0009BA', fill: true, blur: 7, stroke: true, offsetX: 0, offsetY: 0 },
            resolution: 5
        },
        showreward_amnt_s: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '8px',
            color: '#FFFFFF',
            stroke: '#000000',
            strokeThickness: 2,
            shadow: { color: '#0009BA', fill: true, blur: 7, stroke: true, offsetX: 0, offsetY: 0 },
            resolution: 8,
            lineSpacing: -6,
        },
        showreward_bigtext: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '15px',
            color: '#FFFFFF',
            align: 'center',
            stroke: '#000000',
            strokeThickness: 2,
            shadow: { color: '#0009BA', fill: true, blur: 7, stroke: true, offsetX: 0, offsetY: 0 },
            resolution: 5
        },
        showreward_itemtext: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '10px',
            color: '#FFFFFF',
            stroke: '#D3D1D1',
            strokeThickness: 2,
            shadow: { stroke: true, fill: true, blur: 15 },
            resolution: 4
        },
        showreward_itemtext_s: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '8px',
            color: '#FFFFFF',
            stroke: '#D3D1D1',
            strokeThickness: 2,
            shadow: { stroke: true, fill: true, blur: 15 },
            resolution: 4,
            lineSpacing: -6
        },
        showreward_itemtextred: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '10px',
            color: '#FF0000',
            stroke: '#FFFFFF',
            strokeThickness: 4,
            shadow: { stroke: true, fill: true, blur: 15 },
            resolution: 4
        },
        showreward_victory: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '31px',
            color: '#FFFFFF',
            stroke: '#FFF33F',
            strokeThickness: 4,
            shadow: { color: '#C77200', fill: true, blur: 22, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        showreward_clicktoclose: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '16px',
            boundsAlignH: 'center',
            align: 'center',
            padding: { top: 5, bottom: 5 },
            color: '#FFFFFF',
            stroke: '#FFF33F',
            strokeThickness: 2,
            shadow: { color: '#C77200', fill: true, blur: 22, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        rcded_text: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '16px',
            boundsAlignH: 'center',
            align: 'center',
            padding: { top: 5, bottom: 5 },
            color: '#FFFFFF',
            stroke: '#FF0000',
            strokeThickness: 2,
            shadow: { color: '#C770000', fill: true, blur: 22, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },

        dramafilltxt: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '16px',
            boundsAlignH: 'center',
            align: 'center',
            padding: { top: 5, bottom: 5 },
            color: '#FFFFFF',
            stroke: '#000000',
            strokeThickness: 2,
            shadow: { color: '#C770000', fill: true, blur: 22, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        dramastttxt: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '16px',
            boundsAlignH: 'center',
            align: 'center',
            padding: { top: 5, bottom: 5 },
            color: '#FFFFFF',
            stroke: '#000000',
            strokeThickness: 3,
            shadow: { color: '#C770000', fill: true, blur: 22, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        dramastttxt_sm: {
            fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '12px',
            boundsAlignH: 'center',
            align: 'center',
            padding: { top: 5, bottom: 5 },
            color: '#FFFFFF',
            stroke: '#000000',
            strokeThickness: 3,
            shadow: { color: '#C770000', fill: true, blur: 22, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },

        dramacredittxt: {
            fontFamily: 'ffff,uuuu',
            fontSize: '8px',
            boundsAlignH: 'left',
            align: 'left',
            padding: { top: 5, bottom: 5 },
            color: '#FFFFFF',
            stroke: '#000000',
            strokeThickness: 2,
            shadow: { color: '#C770000', fill: true, blur: 22, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        rcalert_text: {
            fontFamily: 'ffff,uuuu',
            fontSize: '10px',
            color: '#000000',
            padding: { top: 5, bottom: 5 },
            strokeThickness: 0,
            stroke: '#000000',
            shadow: { color: '#C77200', fill: true, stroke: true, blur: 1 },
            resolution: 12
        },
        wingui_text: {
            fontFamily: 'ffff,uuuu',
            fontSize: '10px',
            color: '#000000',
            padding: { top: 5, bottom: 5 },
            strokeThickness: 1,
            shadow: { color: '#000000', fill: true, stroke: true, blur: 0 },
            resolution: 4
        },
        wingui_text_s: {
            fontFamily: 'ffff,uuuu',
            fontSize: '8px',
            color: '#000000',
            padding: { top: 5, bottom: 5 },
            strokeThickness: 1,
            shadow: { color: '#000000', fill: true, stroke: true, blur: 0 },
            resolution: 4
        },
        rcalert_head: {
            fontFamily: 'ffff,uuuu',
            backgroundColor: '#F5A623',
            align: 'center',
            fontSize: '13px',
            color: '#000000',
            padding: { top: 5, bottom: 5 },
            stroke: '#E9BC81',
            strokeThickness: 2,
            shadow: { color: '#583500', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 12
            /*fontFamily: 'ffff,uuuu',
            //fontStyle: 'bold',
            fontSize: '12px',
            color: '#FFFFFF',
            stroke: '#E9BC81',
            strokeThickness: 2,
            padding: { top: 5, bottom: 5 },
            shadow: { color: '#583500', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 12*/
        },
        osdflash_red: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '20px',
            color: '#FF0000',
            stroke: '#FFFFFF',
            strokeThickness: 2,
            shadow: { color: '#2F2F2F', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        osdflash_green: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '20px',
            color: '#72D500',
            stroke: '#FFFFFF',
            strokeThickness: 2,
            shadow: { color: '#2F2F2F', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        osdflash_blue: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '20px',
            color: '#4A90E2',
            stroke: '#FFFFFF',
            strokeThickness: 2,
            shadow: { color: '#2F2F2F', fill: true, blur: 17, stroke: true, offsetX: 2, offsetY: 2 },
            resolution: 4
        },
        logtext_rnormal: {
            fontFamily: 'ffff,uuuu',
            fontSize: '8px',
            color: '#fff',
            backgroundColor: '#0000003D',
            align: 'left',
            stroke: '#FFF',
            strokeThickness: 1,
            shadow: { color: '#FF0000', fill: true, blur: 4, stroke: true },
            maxLines: 2,
            resolution: 4,
            wordWrap: { width: 300 },
            fixedWidth: 300,
            fixedHeight: 34
        },
        logtext_gnormal: {
            fontFamily: 'ffff,uuuu',
            fontSize: '8px',
            color: '#fff',
            backgroundColor: '#0000003D',
            align: 'left',
            stroke: '#FFF',
            strokeThickness: 1,
            shadow: { color: '#006600', fill: true, blur: 4, stroke: true },
            maxLines: 2,
            resolution: 4,
            wordWrap: { width: 300 },
            fixedWidth: 300,
            fixedHeight: 34
        },
        logtext_bnormal: {
            fontFamily: 'ffff,uuuu',
            fontSize: '8px',
            color: '#fff',
            backgroundColor: '#0000003D',
            align: 'left',
            stroke: '#FFF',
            strokeThickness: 1,
            shadow: { color: '#0000FF', fill: true, blur: 4, stroke: true },
            maxLines: 2,
            resolution: 4,
            wordWrap: { width: 300 },
            fixedWidth: 300,
            fixedHeight: 34
        },
        logtext_0: {
            fontFamily: 'ffff,uuuu',
            fontSize: '8px',
            color: '#fff',
            backgroundColor: '#0000003D',
            align: 'left',
            stroke: '#FFF',
            strokeThickness: 1,
            shadow: { color: '#000000', fill: true, blur: 4, stroke: true },
            maxLines: 2,
            resolution: 4,
            wordWrap: { width: 300 },
            fixedWidth: 300,
            fixedHeight: 34
        },

        logtext_rbold: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '8px',
            color: '#fff',
            backgroundColor: '#0000003D',
            align: 'left',
            stroke: '#FFF',
            strokeThickness: 1,
            shadow: { color: '#FF0000', fill: true, blur: 4, stroke: true },
            maxLines: 2,
            resolution: 4,
            wordWrap: { width: 300 },
            fixedWidth: 300,
            fixedHeight: 34
        },
        logtext_gbold: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '8px',
            color: '#fff',
            backgroundColor: '#0000003D',
            align: 'left',
            stroke: '#FFF',
            strokeThickness: 1,
            shadow: { color: '#006600', fill: true, blur: 4, stroke: true },
            maxLines: 2,
            resolution: 4,
            wordWrap: { width: 300 },
            fixedWidth: 300,
            fixedHeight: 34
        },
        logtext_bbold: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '8px',
            color: '#fff',
            backgroundColor: '#0000003D',
            align: 'left',
            stroke: '#FFF',
            strokeThickness: 1,
            shadow: { color: '#0000FF', fill: true, blur: 4, stroke: true },
            maxLines: 2,
            resolution: 4,
            wordWrap: { width: 300 },
            fixedWidth: 300,
            fixedHeight: 34
        },
        logtext_0bold: {
            fontFamily: 'ffff,uuuu',
            fontStyle: 'bold',
            fontSize: '8x',
            color: '#fff',
            backgroundColor: '#0000003D',
            align: 'left',
            stroke: '#FFF',
            strokeThickness: 1,
            shadow: { color: '#000000', fill: true, blur: 4, stroke: true },
            maxLines: 2,
            resolution: 4,
            wordWrap: { width: 300 },
            fixedWidth: 300,
            fixedHeight: 34
        },
        event_text: {
            fontFamily: 'aaaa',
            fontSize: '40px',
            color: '#fff',
            stroke: '#F5A623',
            strokeThickness: 3,
            shadow: { stroke: true, fill: true, color: '#0D0D0D', blur: 5 },
            maxLines: 1,
            resolution: 4
        },
        event_descr: {
            fontFamily: 'ffff,uuuu',
            fontSize: '12px',
            color: '#000000',
            shadow: { stroke: true, fill: true, color: '#0D0D0D', blur: 4 },
            maxLines: 1,
            resolution: 4
        }
    }
    inputitemdb = [];
    pnnpcitfdb = [];
    homeinvensize;
    fishingcontainer;
    homeinvensizestack;
    healicon;
    healicondis;
    npclist = [];
    showrewardcontainer;
    showrewardcontainer_sub;
    upgradeitemcontainer;
    upgraderarcontainer;
    zcharinfocontainer;
    biglightsource;
    //biglightsourceadd1;
    pickitemcontainer;
    pickitemitemcontainer;
    petguicontainer;
    petguicontainer_char;
    pettravelcontainer;
    pettravelcontainer_char;
    biglightsource2;
    clock_bg;
    maincharwalktween;
    clock_hh;
    clock_dot;
    chatinputbox;
    questlistcontainer_char;
    questlistcontainer_daily;
    questlistcontainer_weekly;
    charinfocontainersub;
    charinfocontainer;
    combatcontainer;
    combatcontainer_reward;
    questlistcontainer;
    questinfocontainer;
    mapbdl;
    mapbdu;
    mapbdr;
    mapbdd;
    maptweeningchkcount = 0;
    joyStick;
    joyStickactive = false;
    joycursor;
    iswarping = false;
    maptweening = false;
    pauseload = false;
    char_main;
    joy_base;
    joy_thumb;
    mapresizeobj;
    phasermapservice;
    char_main_lastspriteface;
    zmapi = [];
    mapeffects = [];
    walkpathcmd = [];
    walkdoneaction = "";
    zsortlist = [];
    zsortlistcombat = [];
    maplayers = [];
    fow;//fog of war
    foww;//fow w since create
    fowh; //fow h since create
    fowsize = 210; // fow w/2, to align middle
    mobs = [];
    lightlist = [];
    gamemenu = [];
    gamemenudis = [];
    gamemenu_showing = true;
    mapcontainer;
    tilecontainer;
    npccontainer;
    skillcontainer;
    skillcontainer_char;
    shopcontainer_stackitem;
    shopcontainer_item;
    inventorycontainer_stackitem;
    inventorycontainer_item;
    homeinventorycontainer_stackitem;
    homeinventorycontainer_item;
    equipmentcontainer;
    skillcraftcontainer;
    attricontainer;
    walkingtonpc = "";
    osdcontainer;
    gifcam;
    mnmcam;
    shopitemlist = [];
    shopitemlistp = [];
    mapbgimg;
    updatetrigger10 = 0;
    updatetrigger100 = 0;
    public timectltimer;
    public timectltimer1sec;
    timerseccount = 0;
    gametimesec = 0;
    zaninightlist = [];
    zmapcharlist = [];
    zmapcharfollower = [];
    zmapchatballoonlist = [];
    timefadecam;
    maplayerfader;
    lastnpcuidclicked = "";
    webfont;
    npcbribestatus = [];
    cheststatus = [];
    bushrewardstatus = [];
    cursorKeys;
    mapmob = [];
    mapwalker = [];
    hpbg;
    xpbg;
    plog;
    rexGestures: any;
    swipeInput;
    pinch;

    constructor(
        public rcvarsservice: RCVarsService,
        public phasermapresizemanager: PhaserMapResizeManagerService,
        public gifs: GameInterfaceService,
        public intl: intl,
    ) {
        super('Preloader');
        //super({});
        //this.plog.log("Preloader constructor", this);
        this.plog = new plog();
        //this.plog.setLevel(1);
        //this.input.keyboard.enabled = false;

    }
    preload() {
        //this.plog.log("Preloader preload");
        //this.plog.log("this preload", this);
        //RCVarsService.scene_preload = this;
        //this.plog.log("Preloader create scene_preload1", RCVarsService.scene_preload);
        //this.load.plugin('dragonBones', '../dragonBones/phaser/plugin/DragonBonesPlugin.ts', true);
        //this.load.scenePlugin('dragonBones', '/assets/dragonBones/plugin/DragonBonesPlugin.ts');
        ///this.load.bitmapFont('fffw', 'assets/font/fffw.png', 'assets/font/fffw.fnt');
        ///this.load.bitmapFont('fffb', 'assets/font/fffb.png', 'assets/font/fffb.fnt');
        ///this.load.bitmapFont('fpw', 'assets/font/fpw.png', 'assets/font/fpw.fnt');
        //// this.load.bitmapFont('fffw', 'assets/font/fffw.png', 'assets/font/fffw.fnt');
        //this.load.bitmapFont('fffb', 'assets/font/fffb.png', 'assets/font/fffb.fnt');
        // this.load.bitmapFont('fpw', 'assets/font/fpw.png', 'assets/font/fpw.fnt');

        /*
        var WebFontConfig = {
            custom: {
                families: ['aaaa','ffff,uuuu'],
                urls: ['assets/font/AgreloycAlmond.css']
            }
        };
        var tmp;
        tmp = this.plugins.get('rexWebFontLoader');//.addToScene(this);
        //this.plugins.Mana.addToScene(tmp);
        this.plog.log("rex", tmp);
        //this.plugins.
        //tmp.pluginManager.addToScene(this,tmp);
        var ll;
        ll = this.load;
        ll.rexWebFont(WebFontConfig);*/

    }
    timefunc() {
        //this.plog.log("Preloader timectl",this);
        this.registry.list.timectl.timefunc(this);
        //this.timectl.timefunc(this);
    }
    timefunc1sec() {
        //this.plog.log("Preloader timectl",this);
        this.registry.list.phm.timefunc1sec(this);
        this.registry.list.timectl.timefunc1sec(this);
    }

    async create() {
        ///this.sound.pauseOnBlur = false


        //console.log(this);
        this.pinch = this.rexGestures.add.pinch(this.game, {
            // enable: true,
            // bounds: undefined,

            // threshold: 0,
        })
            .on('drag1', function (pinch) {
                //console.log("drag1", pinch.drag1Vector);
                //console.log("drag1", pinch,pinch.distanceBetween,pinch.drag1Vector);
                //console.log(arguments);

                var donesomething = false;
                if (this.registry.list.drama.isdramaplaying == true) return;
                var drag1Vector = pinch.drag1Vector;
                if (this.worldmap != undefined && this.worldmap.visible == true) {
                    donesomething = true;
                    this.worldmap_gesturetakecontrol = true;
                    this.worldmap.x += drag1Vector.x * this.worldmap.scale;
                    this.worldmap.y += drag1Vector.y * this.worldmap.scale;
                    //console.log("drag1worldmap", this.worldmap, this.worldmap.scale);
                }
                if (this.worldmap2 != undefined && this.worldmap2.visible == true) {
                    donesomething = true;
                    this.worldmap_gesturetakecontrol = true;
                    this.worldmap2.x += drag1Vector.x * this.worldmap2.scale;
                    this.worldmap2.y += drag1Vector.y * this.worldmap2.scale;
                    //console.log("drag1worldmap", this.worldmap2, this.worldmap2.scale);
                }
                if (donesomething == false) {
                    this.worldmap_gesturetakecontrol = true; //skip for now, messing with worldmap
                }
                /*var camera=this.cameras.cameras[0] 
                camera.scrollX -= drag1Vector.x / camera.zoom;
                 camera.scrollY -= drag1Vector.y / camera.zoom;
                 */
            }, this)
            .on('pinch', function (pinch) {

                this.worldmap_gesturetakecontrol = true;
                if (this.registry.list.drama.isdramaplaying == true) return;
                //console.log("pinch", pinch)
                //console.log(arguments);
                //pinch.event.preventDefault();
                var donesomething = false;
                if (this.worldmap != undefined && this.worldmap.visible == true) {
                    this.worldmap_gesturetakecontrol = true;
                    var scaleFactor = pinch.scaleFactor;
                    this.worldmap.scale *= scaleFactor;
                    donesomething = true;
                }
                if (this.worldmap2 != undefined && this.worldmap2.visible == true) {
                    this.worldmap_gesturetakecontrol = true;
                    var scaleFactor = pinch.scaleFactor;
                    this.worldmap2.scale *= scaleFactor;
                    donesomething = true;
                }
                if (donesomething == false) {
                    var scaleFactor = pinch.scaleFactor;
                    var camera = this.cameras.cameras[0];
                    var tgzoom = camera.zoom *= scaleFactor;
                    if (tgzoom >= 1.5) tgzoom = 1.5;
                    if (tgzoom <= 0.4) tgzoom = 0.4;
                    //console.log(tgzoom);
                    camera.zoom = tgzoom;
                    //temp1.screenbg["bg1"].scale=1/temp1.cameras.cameras[0].zoom

                    if (this.screenbg != undefined) {

                        for (const k in this.screenbg) {
                            var v = this.screenbg[k];
                            //console.log(k, v);
                            if (v != undefined) {
                                var scrbv = this.rcvarsservice.activemap['map']['screenbg'][k];
                                var scaleh = height / this.rcvarsservice.gameobj.scene.scenes[0].textures.list[scrbv["file"]].source[0].height;
                                this.rcvarsservice.gameobj.scene.scenes[0].screenbg[k].setTileScale(scaleh, scaleh);
                                //this.screenbg[k].scale = 1 / this.cameras.cameras[0].zoom;
                            }
                        }
                    }

                }
                //camera.zoom *= scaleFactor;
            }, this);
        //console.log(this);
        /*this.input.on('wheel', (pointer) => {
            console.log(pointer );
            //console.log(arguments);
            if (this.worldmap != undefined && this.worldmap.visible == true) {
                var newscale = this.worldmap.scale += pointer.deltaY * 0.001;
                console.log(newscale);
                if (newscale > 0.65) newscale = 0.65;
                if (newscale < 0.1) newscale = 0.1;
                this.worldmap.scale = newscale;
            }
            if (this.worldmap2 != undefined && this.worldmap2.visible == true) {
                var newscale = this.worldmap2.scale += pointer.deltaY * 0.001;
                console.log(newscale);
                if (newscale > 0.65) newscale = 0.65;
                if (newscale < 0.1) newscale = 0.1;
                this.worldmap2.scale = newscale;
            }

        });*/
        /*                this.swipeInput = this.rexGestures.add.swipe({
                    // dir: '4dir',
                    velocityThreshold: 1000
                })
                    .on('swipe', function (swipe) {
                        //print.text += `swipe, v = ${swipe.dragVelocity}\n`;
                        console.log(swipe);
                    }, this);
        */
        //this.plog.log("Preloader create", this, this.registry.list.phaserGame.scene.scenes[1].isloaded);

        //this.plog.log("this create", this);
        //let plugin = this.plugins.get('RandomNamePlugin');
        /*

                this.input.on('gameobjectdown', function(pointer, gameObject, event){ 
                    var downobj=pointer.downElement.tagName;
                    this.plog.log("zxcgameobjectdown downobj",downobj);
                    this.plog.log("zxcgameobjectdown",arguments);
                    if (downobj!="CANVAS") {
                         this.plog.log("zxcgameobjectdown preventing",downobj);
                        event.stopPropagation();
                        event.stopPropagation();
                        //event.preventDefault(); 
                    }
                 });
                 */

        //this.plog.log("Preloader create scene_preload2", RCVarsService.scene_preload);
        this.timectltimer = this.time.addEvent({
            delay: 3000,                // ms
            callback: this.timefunc,
            //args: [],
            callbackScope: this,
            loop: true
        });
        this.timectltimer1sec = this.time.addEvent({
            delay: 1000,                // ms
            callback: this.timefunc1sec,
            //args: [],
            callbackScope: this,
            loop: true
        });

        /*
        var swipe = this.rexGestures.add.swipe({
            // enable: true,
            // bounds: undefined,
        
            // threshold: 10,
            // velocityThreshold: 1000,
            // dir: '8dir',
        });*/

        // this.scene.start('MainMenu');
        // this.scene.start('PlayerDetails');
        // this.scene.start('GameStats');
        // this.scene.start('GameData');
        //this.scene.start('MainGame');

        //this.scene.start('RCGame');
        //rc_footer();
        //var joyStick = this.plugins.get('rexVirtualJoystick').addPlayer(scene, config);
        //this.joystickPlugin = this.game.plugins.add(Phaser.VirtualJoystick as any);
        //var joyStick=this.plugins.plugins[0].addPlayer(scene, config);
        //let { width, height } = this.sys.game.canvas;
        var width = this.sys.game.canvas.width;
        var height = this.sys.game.canvas.height;
        var joybase = this.add.image(300, 300, "joybase");

        //this.plog.log("this.sys.game.canvas",this.sys.game.canvas,width, height);
        var joyconfig = {
            //y: height - 150,
            // x: 0,
            y: - 1000,
            x: - 1000,
            radius: 60,
            //base: baseGameObject,
            //thumb: thumbGameObject,
            //dir: '8dir',
            forceMin: 16,
            fixed: true,
            //enable: true,
            base: joybase,//this.add.circle(0, 0, 60, 0x888888).setDepth(998).setAlpha(0.5),
            thumb: this.add.circle(0, 0, 20, 0xcccccc).setDepth(999).setAlpha(0.8)
        };
        this.joyStick = new VirtualJoyStick(this, joyconfig);
        //this.joyStick.showOnTouch = true;
        this.joy_base = this.joyStick.base;
        this.joy_thumb = this.joyStick.thumb;
        this.joyStick.visible = false;
        /*this.tweens.add({
            targets: this.joyStick,
            //angle: -20, // '+=100'
            x: 150,
            ease: "Linear", // 'Cubic', 'Elastic', 'Bounce', 'Back'
            duration: 800,
            repeat: 0,
            yoyo: false
        });*/

        //this.plog.log("Joy set");
        /*this.joyStick.on('update', function(){
            this.plog.log("joyupdate",this);
        });*/
        this.joy_base.on('pointerdown', function (event) {
            //this.plog.log("zxc joy_base pointerdown",event,arguments);
            if (arguments[0].downElement.tagName != "CANVAS") {
                this.plog.log("zxc ,joy_base,prevent event", arguments);
                arguments[3].stopPropagation();
                return;
            }
        });
        this.mapcami(this, this.joy_base);
        this.mapcami(this, this.joy_thumb);
        this.joy_thumb.on('pointerdown', function (event) {
            //console.log("zxc joy_thumb pointerdown", event, arguments);
            if (arguments[0].downElement.tagName != "CANVAS") {
                //this.plog.log("zxc ,joy_thumb,prevent event", arguments);
                arguments[3].stopPropagation();
                return;
            }
            this.joyStickactive = true;
        });
        this.joyStick.on('pointerup', function () {
            this.plog.log("zxc joyStick pointerup", arguments);
        }, this);
        this.joyStick.on('pointerdown', function () {
            this.joyStickactive = true;
        }, this);
        this.joy_thumb.setInteractive();
        /*
         this.joy_thumb.on('pointerup', function () {
            this.plog.log("zxc joy_thumb pointerup", arguments);
            this.joyStick.setPosition(-1000,-1000);
        }, this);*/
        //joyStick..set.setsetDepth();
        //joyStick..on('update', function(){});
        //this.plog.log("zxc plugins", this.plugins.plugins);
        this.joycursor = this.joyStick.createCursorKeys();
        /*
        this.joyStick.on('update', function (event) {
            this.plog.log("zxc joyStick update",event,arguments);
        });*/
        this.input.on('pointerup', () => {
            if (this.registry.list.rcvarpass.activemap['map']["activemap"] == "YES") {
                var height = this.sys.game.canvas.height;
                //console.log(this);
                this.joyStick.setPosition(150, height - 150);
                this.joyStickactive = false;
            } else {
                this.joyStick.setPosition(-1000, -1000);
                this.joyStickactive = false;
            }/*
            this.joyStick.setPosition(-1000, -1000);
            this.joyStickactive = false;*/
            //console.log(this.game.input.activePointer);
        }, this);

        this.input.on('pointermove', () => {
            if (this.joyStickactive == true) {
                var dist = this.calculateDistance(this.joyStick.x, this.joyStick.y, this.game.input.activePointer.x, this.game.input.activePointer.y);
                //console.log(dist);
                //console.log(this.game.input.activePointer);
                var linkdist = 100;//max distance bettween pointer and joystick
                //console.log(this.joyStick, dist);
                if (dist > linkdist) {
                    var moveto = this.positionBeforeDestination(this.joyStick.x, this.joyStick.y, this.game.input.activePointer.x, this.game.input.activePointer.y, linkdist);
                    this.joyStick.setPosition(moveto.x, moveto.y);
                }
            }
        }, this);

        this.input.on('pointerdown', function () {
            //this.plog.log("hmc click arg", arguments);
            if (this.registry.list.drama.isdramaplaying == true) return;
            this.worldmap_gesturetakecontrol = false;
            if (this.preloaderjoychkinterval != undefined) clearInterval(this.preloaderjoychkinterval);
            this.preloaderjoychkinterval = setTimeout(() => {
                if (this.game.input.activePointer.isDown == true) {
                    //still down
                    if (this.worldmap_gesturetakecontrol == true) return;
                    this.joyStick.setPosition(this.game.input.activePointer.x, this.game.input.activePointer.y);
                    this.joyStickactive = true;
                    //console.log(this.game.input.activePointer);
                }
            }, 300);
        }, this);
        //this.plog.log("joycursor", this.joycursor, this.joyStick);
        //this.plog.log("this.phasermapresizemanager", this.phasermapresizemanager);
        this.mapresizeobj = new PhaserMapResizeManagerService();
        this.scale.on('resize', function () {
            //this.plog.log("resize", this);
            //var tmp = new PhaserMapResizeManagerService();
            this.mapresizeobj.RCMapResizeManager_work(this);
            if (this.registry.list.phaserGame.scene.scenes[1].isloaded == "yes") {
                this.registry.list.phaserGame.scene.scenes[1].resized();
            }

        }, this);
        this.phasermapservice = new PhaserMapService(this.rcvarsservice);
        //this.gifs = new GameInterfaceService();
        //this.gifs.registryclone=this;
        if (this.registry.list.phaserGame.scene.scenes[1] == undefined || this.registry.list.phaserGame.scene.scenes[1].isloaded == undefined)
            this.scene.start('Welcome');

        //audio
        /*this.startbgm();
        if (this.volume==undefined||this.volume=="") {
            this.volume = await this.registry.list.rcstorage.get('setting_volume');
        }
        var xx = this.sound.add("bgm");
        xx.play();*/
        if (this.volume == undefined || this.volume == "") {
            this.volume = await this.registry.list.rcstorage.get('setting_volume');
        }
        if (this.bgmsetting == undefined || this.bgmsetting == "") {
            this.bgmsetting = await this.registry.list.rcstorage.get('setting_bgm');
        }
        this.sound.volume = (this.volume / 10);

        //this.registry.list.snd.prepare();
        this.registry.list.snd.bgm("bgm");



    }
    calculateDistance(x, y, x2, y2) {
        return Math.sqrt(Math.pow(x2 - x, 2) + Math.pow(y2 - y, 2));
    }

    positionBeforeDestination(x, y, x2, y2, distanceBefore) {
        const distance = this.calculateDistance(x, y, x2, y2);

        if (distance <= distanceBefore) {
            return { x: x, y: y }; // If the distance is less than or equal to the distance before, return the current position
        }

        const ratio = (distance - distanceBefore) / distance;
        const newX = x + (x2 - x) * ratio;
        const newY = y + (y2 - y) * ratio;

        return { x: newX, y: newY };
    }
    resizemanager() {
        //this.plog.log("resizemanager local");
    }
    chkanims_stepon() {
        if (this.iswarping == true) return;
        if (this.char_main != undefined && this.registry.list.rcvarpass.activemap["zwarp"] != undefined) {

            var tmpcharpos = this.char_main.currentx + "-" + this.char_main.currenty;
            if (this.registry.list.rcvarpass.activemap["zani"] != undefined) {
                var anim = this.registry.list.rcvarpass.activemap["zani"][tmpcharpos];
                if (anim != undefined) {
                    this.plog.log("chkanims_stepon zani ", anim);
                    //this.plog.log("chkanims_stepon zani ", this.zsortlist);
                    //chk for playon 

                    if (this.registry.list.rcvarpass.allanims[anim] != undefined) {
                        if (this.registry.list.rcvarpass.allanims[anim]["playon"] == "stepon") {
                            this.plog.log("chkanims_stepon STEPON ", this.registry.list.rcvarpass.allanims[anim]);
                            //find the sprite and play
                            for (const k in this.zsortlist) {
                                var v = this.zsortlist[k];
                                var tmpnamea = v.name.split("--");
                                if (tmpnamea[1] == tmpcharpos) {
                                    this.plog.log("chkanims_stepon STEPON-> found n play", this.char_main);
                                    v.setOrigin(0.5, 0.5);
                                    v.x = this.char_main.x;//- (this.char_main.displayOriginX / 2);
                                    v.y = this.char_main.y + (this.char_main.displayOriginY / 2);

                                    v.play({
                                        key: tmpnamea[2], repeat: 0, callback: function () {
                                            this.plog.log("stepon complete", this);
                                        }
                                    });
                                    v.removeAllListeners();
                                    v.once('animationcomplete', function () {
                                        //this.plog.log('animationcomplete', arguments, this);
                                        arguments[2].visible = false;
                                    }, v);
                                    v.visible = true;
                                }
                            }
                        }
                    }
                }
            }
            if (this.registry.list.rcvarpass.activemap["zaninight"] != undefined) {
                var anim = this.registry.list.rcvarpass.activemap["zaninight"][tmpcharpos];
                if (anim != undefined) {
                    this.plog.log("chkanims_stepon zaninight ", anim);
                    if (this.registry.list.rcvarpass.allanims[anim] != undefined) {
                        if (this.registry.list.rcvarpass.allanims[anim]["playon"] == "stepon") {
                            this.plog.log("chkanims_stepon STEPON ", this.registry.list.rcvarpass.allanims[anim]);
                            //find the sprite and play
                            for (const k in this.zsortlist) {
                                var v = this.zsortlist[k];
                                var tmpnamea = v.name.split("--");
                                if (tmpnamea[1] == tmpcharpos) {
                                    this.plog.log("chkanims_stepon STEPON-> found n play", this.char_main);
                                    v.setOrigin(0.5, 0.5);
                                    v.x = this.char_main.x;//- (this.char_main.displayOriginX / 2);
                                    v.y = this.char_main.y + (this.char_main.displayOriginY / 2);

                                    v.play({
                                        key: tmpnamea[2], repeat: 0, callback: function () {
                                            this.plog.log("stepon complete", this);
                                        }
                                    });
                                    v.removeAllListeners();
                                    v.once('animationcomplete', function () {
                                        this.plog.log('animationcomplete', arguments, this);
                                        arguments[2].visible = false;
                                    }, v);
                                    v.visible = true;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    async chkpossay() {
        // this.plog.log("chkpossay()", this.lastpossaysec, this.iswarping);
        if (this.iswarping == true) return;
        if (this.lastpossaysec < 15) return;
        if (this.char_main != undefined && this.registry.list.rcvarpass.activemap["possay"] != undefined) {

            var tmpcharpos = this.char_main.currentx + "-" + this.char_main.currenty;
            if (this.registry.list.rcvarpass.activemap["possay"] != undefined && this.registry.list.rcvarpass.activemap["possay"][tmpcharpos] != undefined) {
                //this.plog.log("chkpossay()", this.lastpossaysec, this.iswarping);
                var chkdrama = ("" + this.registry.list.rcvarpass.activemap["possay"][tmpcharpos]).split(":");
                this.lastpossaysec = 0;
                if (chkdrama[0] == "drama") {
                    this.plog.log("chkpossay() founddrama", this.registry.list.rcvarpass.activemap["possay"][tmpcharpos]);
                    await this.playdrama(this.registry.list.rcvarpass.activemap["possay"][tmpcharpos]);
                } else {
                    this.registry.list.phm.charsaybubble(this.char_main, this.gl(this.registry.list.rcvarpass.activemap["possay"][tmpcharpos]));
                }
            }
        }
    }
    async chkwarppos() {
        if (this.iswarping == true) return;
        //this.plog.log("chkwarppos", this.registry.list.rcvarpass);
        if (this.char_main != undefined && this.registry.list.rcvarpass.activemap["zwarp"] != undefined) {
            var tmpcharpos = this.char_main.currentx + "-" + this.char_main.currenty;
            var tmpaction = this.registry.list.rcvarpass.activemap["zwarp"][tmpcharpos];
            //this.plog.log("chkwarppos", tmpcharpos, tmpaction);

            if (tmpaction != undefined) {
                var tmpactiona = tmpaction.split("-");
                if (tmpactiona[0] == "tout") return;//ignore inmap teleport out mark
                this.iswarping = true;
                if (tmpaction == "teleporter1") {
                    this.registry.list.phm.mapitemeffect(this.char_main, "farteleport_b");
                } else {
                    this.registry.list.phm.mapitemeffect(this.char_main, "warpgate_b");
                }

                this.registry.list.snd.snd("warp");

                var tweenchar = this.tweens.add({
                    targets: this.char_main,
                    alpha: 0,
                    ease: 'Linear',
                    duration: 200,
                    repeat: 0,
                    onStart: function () {
                    },
                    onComplete: function (wh) {
                        //this.plog.log("oncomplete",wh);
                        this.parent.scene.chkwarppos_warp(this.parent.scene, tmpaction);
                    },
                    onYoyo: function () {
                    },
                    onRepeat: function () {
                    }
                });

            }
        }
    }
    async chkwarppos_warp(wh, tmpaction) {
        this.plog.log("chkwarppos_warp", tmpaction);
        //this.scene.restart(); return;///
        //if (this.iswarping==true) return;
        var zwnameclickeda = tmpaction.split("-");
        if (zwnameclickeda[0] == "redir") {
            //find target
            tmpaction = zwnameclickeda[1];
            /*
            for (const zwk in wh.registry.list.rcvarpass.activemap["zwarp"]) {
                var zwv = wh.registry.list.rcvarpass.activemap["zwarp"][zwk];
                if (zwv == zwnameclickeda[1]) {
                    var zwka = zwk.split("-");
                    this.plog.log("redir redirecting to ", zwnameclickeda[1], zwka);
                    tmpaction = zwnameclickeda[1];
                    //tmpa[1]=zwka[0];
                    //tmpa[2]=zwka[1];
                    //var tmploc = { name: "redir-" + zwk }
                    //this.plog.log(tmploc, this, this.name);
                    //wh.registry.list.phm.floorclick(tmploc, arguments);
                    //return;
                }
            }*/
        }
        if (zwnameclickeda[0] == "tin") {
            //teleport inmap
            for (const zwk in wh.registry.list.rcvarpass.activemap["zwarp"]) {
                var zwv = wh.registry.list.rcvarpass.activemap["zwarp"][zwk];
                if (zwv == "tout-" + zwnameclickeda[1]) {
                    var zwka = zwk.split("-");
                    this.plog.log("teleport inmap to tout-", zwnameclickeda[1], zwka);
                    //tmpaction = zwnameclickeda[1];

                    wh.registry.list.phm.movemaincharto(Number(zwka[1]) - 0, Number(zwka[0]) - 0);
                    wh.registry.list.phm.movemaincharto(Number(zwka[1]) - 0, Number(zwka[0]) - 0);
                    wh.char_main.setAlpha(1);
                    wh.iswarping = false;
                }
            }
            return;
        }

        if (zwnameclickeda[0] == "redirjump") {
            //redirjump inmap
            this.plog.log("redirjump", zwnameclickeda);
            for (const zwk in wh.registry.list.rcvarpass.activemap["zwarp"]) {
                var zwv = wh.registry.list.rcvarpass.activemap["zwarp"][zwk];
                if (zwv == "" + zwnameclickeda[1]) {
                    var zwka = zwk.split("-");
                    this.plog.log("redirjump to :", zwnameclickeda[1], zwka);
                    //tmpaction = zwnameclickeda[1];

                    //wh.registry.list.phm.movemaincharto(zwka[1], zwka[0]);
                    //wh.registry.list.phm.movemaincharto(zwka[1], zwka[0]);
                    var targetx = Number(zwka[0]) * 32;
                    var targety = Number(zwka[1]) * 32;
                    this.plog.log("redirjump from xy:", wh.char_main.x, wh.char_main.y);
                    this.plog.log("redirjump to xy:", targetx, targety);
                    wh.char_main.setAlpha(1);
                    var jumpup1 = Math.max(wh.char_main.x, targetx) - Math.min(wh.char_main.x, targetx);
                    var jumpup2 = Math.max(wh.char_main.y, targety) - Math.min(wh.char_main.y, targety);
                    var jumpup = Math.max(jumpup1, jumpup2) - Math.min(jumpup1, jumpup2);
                    jumpup = jumpup * 2;
                    this.plog.log("redirjump length use=", jumpup);
                    wh.char_main.setAlpha(1);
                    wh.iswarping = true;

                    var timeline = wh.add.timeline([
                        {
                            at: 0,
                            tween: {
                                targets: wh.char_main,
                                x: { from: wh.char_main.x, to: targetx },
                                ease: 'Sine.easeInOut',
                                duration: 400,
                            }
                        },
                        {
                            at: 0,
                            tween: {
                                targets: wh.char_main,
                                y: { from: wh.char_main.y, to: Math.min(targety, wh.char_main.y) - jumpup },
                                ease: 'Sine.easeOut',
                                duration: 200,
                            }
                        },
                        {
                            at: 200,
                            tween: {
                                targets: wh.char_main,
                                y: { from: Math.min(targety, wh.char_main.y) - jumpup, to: targety },
                                ease: 'Sine.easeIn',
                                duration: 200,
                            }
                        }
                    ]
                    );

                    timeline.on('complete', function () {
                        this.plog.log("redirjump timelinecomplete ", wh, zwnameclickeda);
                        this.chkwarppos_warp(wh, zwnameclickeda[1]);
                    }.bind(this));
                    timeline.play();
                    this.plog.log(timeline);
                }
            }
            return;
        }

        var nextmapinfo = wh.registry.list.rcvarpass.allmap[tmpaction];
        var chkreq = false;
        chkreq = wh.registry.list.rcreq.chk(nextmapinfo["requirements"]);
        if (chkreq == false) {
            var chkreqtext = wh.registry.list.rcreq.chk(nextmapinfo["requirements"], "text");
            await wh.registry.list.rctoast.rcalert(wh, "Map Requirement not met", "To enter " + nextmapinfo["name"] + " you need\n\n" + chkreqtext);
            wh.char_main.setAlpha(1);
            wh.iswarping = false;
            //this.plog.log("chkwarppos_warp chkreq", chkreq, chkreqtext);
            return;
        }
        wh.registry.list.rcloading.loading2(wh);
        wh.walkpathcmd = [];
        wh.pauseload = true;
        /*if (wh.char_main != undefined) {
            this.plog.error("Preloader chkwarppos_warp found char_main???", wh.char_main);
            //return;
            wh.char_main.destroy();
            wh.char_main = undefined;
            return;
        }*/
        var tmpwarpfrommap = wh.registry.list.rcvarpass.activechar["map"];
        wh.registry.list.rcvarpass.activechar["map"] = tmpaction;
        this.plog.log("activemapb", wh.registry.list.rcvarpass.activemap);
        var tmpmap = await wh.registry.list.rchttp.load_map(tmpaction);
        wh.registry.list.rcvarpass.activemap = tmpmap;
        await this.registry.list.rctoast.sleep(50);
        wh.registry.list.rcvarpass.activemap = tmpmap;
        //this.plog.log("activemapba", wh.registry.list.rcvarpass.activemap);

        wh.registry.list.phm.astar = undefined;
        //this.textures.remove('minimap');
        this.iswarping = true;
        //this.plog.log("this.iswarping", this.iswarping)
        //wh.registry.list.rcvarpass.activechar["map"]=tmpaction;// pre set variable to make WSRC send to server in time
        await wh.registry.list.rchttp.savegame(wh.registry.list.rcvarpass.activechar);
        //await wh.registry.list.rchttp.updatecharinfo(); //update in initmap
        wh.registry.list.rcvarpass.activechar["warpfrommap"] = tmpwarpfrommap;
        wh.registry.list.rcvarpass.activechar["walkfrommap"] = tmpwarpfrommap;//to force joystick walk later
        await wh.registry.list.phm.initmap();
        //wh.registry.list.rcloading.close();
        this.iswarping = false;
        this.plog.log("this.iswarping", this.iswarping)
    }
    addinterface() {
        //this.plog.log("addinterface in preloader.ts")
        this.registry.list.gameitf.addinterface(this);
        this.registry.list.npcconver.initthis(this);
    }
    update() {
        //return;///
        //this.plog.log("preloader update() game input",this.game.input.pointers[0].downTime);
        //this.plog.log("preloader update()",this);
        //this.plog.log("preloader update() game input",this.game.input.activePointer.downTime);
        //this.plog.log("preloader update() game input",this.game.input);
        //return;
        //this.plog.log("preloader chkinput update", this.game.input.activePointer.isDown);

        if (this.charatk_timeout > 0) this.charatk_timeout--;
        this.updatetrigger10++;
        this.updatetrigger100++;
        if (this.updatetrigger10 == 10) this.updatetrigger10 = 0;
        if (this.updatetrigger100 == 100) this.updatetrigger100 = 0;
        if (this.updatetrigger10 == 0 && this.showfpsmode == true) {
            if (this.playerName != undefined) {
                this.playerName.setText(this.game.loop.actualFps);
                this.playerName.setDepth(9999999999999);
            }
            return;
        }
        if (this.updatetrigger100 == 0) {
            //update idle/sit for char_main+zmapchar
            if (this.char_main != undefined) {
                if (this.registry.list.drama.isdramaplaying == true) return;
                if (this.char_main.idlecount == undefined)
                    this.char_main.idlecount = 0;
                this.char_main.idlecount++;
                //console.log("charmainidlecount", this.char_main.idlecount);
                for (const k in this.zmapcharlist) {
                    //this.plog.log("xxx found left char chk", k, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]["stillexists"],this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]);
                    if (this.zmapcharlist[k] == undefined) {
                        continue;
                    }
                    if (this.zmapcharlist[k].idlecount == undefined)
                        this.zmapcharlist[k].idlecount = 0;
                    this.zmapcharlist[k].idlecount++;

                    //console.log("zmapcharlist", k, this.zmapcharlist[k].idlecount);
                }

                //set sprite
                if (this.char_main.idlecount == 20) {
                    var charspritename = this.registry.list.rcvarpass.activechar["sprite"];
                    /*if ( this.anims.exists(charspritename + "-idle")) {
                        this.registry.list.phm.loadcharsprite(charspritename);
                   }*/
                    //console.log(charspritename, this.char_main.anims.currentAnim);

                    if (this.char_main_lastspriteface == "up") {
                        if (this.char_main.anims != undefined && this.char_main.anims.currentAnim != undefined)
                            this.char_main.play({ key: charspritename + "-sit-u", repeatDelay: 2000 }, true);
                    }
                    if (this.char_main_lastspriteface == "down") {
                        if (this.char_main.anims != undefined && this.char_main.anims.currentAnim != undefined)
                            this.char_main.play({ key: charspritename + "-sit-d", repeatDelay: 2000 }, true);
                    }
                    if (this.char_main_lastspriteface == "left") {
                        if (this.char_main.anims != undefined && this.char_main.anims.currentAnim != undefined)
                            this.char_main.play({ key: charspritename + "-sit-l", repeatDelay: 2000 }, true);
                    }
                    if (this.char_main_lastspriteface == "right") {
                        if (this.char_main.anims != undefined && this.char_main.anims.currentAnim != undefined)
                            this.char_main.play({ key: charspritename + "-sit-r", repeatDelay: 2000 }, true);
                    }
                }
                //
                for (const k in this.zmapcharlist) {
                    //this.plog.log("xxx found left char chk", k, this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]["stillexists"],this.rcvarsservice.gameobj.scene.scenes[0].zmapcharlist[k]);
                    if (this.zmapcharlist[k] == undefined) {
                        continue;
                    }
                    if (this.zmapcharlist[k].idlecount > 20) {
                        var zcharspritename = this.zmapcharlist[k].spritex;
                        //console.log("zmapcharlist", k, this.zmapcharlist[k], this.zmapcharlist[k].idlecount);
                        if (this.zmapcharlist[k].lastspriteface == undefined) this.zmapcharlist[k].lastspriteface = "down";
                        if (this.zmapcharlist[k].lastspriteface == "up") {
                            if (this.zmapcharlist[k].anims != undefined && this.zmapcharlist[k].anims.currentAnim != undefined)
                                this.zmapcharlist[k].play({ key: zcharspritename + "-sit-u", repeatDelay: 2000 }, true);
                        }
                        if (this.zmapcharlist[k].lastspriteface == "down") {
                            if (this.zmapcharlist[k].anims != undefined && this.zmapcharlist[k].anims.currentAnim != undefined)
                                this.zmapcharlist[k].play({ key: zcharspritename + "-sit-d", repeatDelay: 2000 }, true);
                        }
                        if (this.zmapcharlist[k].lastspriteface == "left") {
                            if (this.zmapcharlist[k].anims != undefined && this.zmapcharlist[k].anims.currentAnim != undefined)
                                this.zmapcharlist[k].play({ key: zcharspritename + "-sit-l", repeatDelay: 2000 }, true);
                        }
                        if (this.zmapcharlist[k].lastspriteface == "right") {
                            if (this.zmapcharlist[k].anims != undefined && this.zmapcharlist[k].anims.currentAnim != undefined)
                                this.zmapcharlist[k].play({ key: zcharspritename + "-sit-r", repeatDelay: 2000 }, true);
                        }
                    }
                }
            }

        }

        if (this.updatetrigger100 == 0 && this.walkermode == true) {
            if (this.walkpathcmd.length == 0) {
                //find target
                /*var spawnpos; // = this.rcvarsservice.activemap["spawn"];
                for (var key in this.registry.list.rcvarpass.activemap['spawn']) {
                    if (this.registry.list.rcvarpass.activemap['spawn'][key] == 'yn_y') {
                        spawnpos = key;
                    }
                    //this.rcvarsservice.activemap["spawn"][key];
                }
                var spawnposa = spawnpos.split('-');
                this.registry.list.phm.movemaincharto(Number(spawnposa[0]), Number(spawnposa[1]))
               */

                var tmpfoundtg = false;
                var tmpchoices = [];
                for (var key in this.registry.list.rcvarpass.activemap['zwarp']) {

                    var tmpwarplist = this.registry.list.rcvarpass.activemap['zwarp'][key] + "";
                    if (tmpwarplist.indexOf("redir-") != -1) continue;
                    if (tmpwarplist == "EVENT") continue;
                    if (tmpwarplist == "home") continue;
                    if (tmpwarplist == "backyard") continue;
                    if (tmpwarplist == "backyard2") continue;
                    if (this.registry.list.rcvarpass.activechar["walkfrommap"] != undefined && tmpwarplist == this.registry.list.rcvarpass.activechar["walkfrommap"]) continue;
                    this.plog.log("walkerchk", tmpwarplist);

                    var nextmapinfo = this.registry.list.rcvarpass.allmap[tmpwarplist];
                    if (nextmapinfo == undefined) continue;
                    var chkreq = this.registry.list.rcreq.chk(nextmapinfo["requirements"]);
                    if (chkreq == false) continue;

                    tmpchoices.push(key)

                    tmpfoundtg = true;
                    //this.rcvarsservice.activemap["spawn"][key];
                }

                if (this.registry.list.rcvarpass.activemapmetrix == undefined || this.registry.list.rcvarpass.activemapmetrix.length <= 1) return;
                if (tmpchoices.length > 0) {
                    this.plog.log("walker choices", tmpchoices);

                    const randomElement = tmpchoices[Math.floor(Math.random() * tmpchoices.length)];
                    var keya = randomElement.split("-");
                    this.registry.list.phm.walkto(Number(keya[0]), Number(keya[1]));
                } else {

                    var spawnpos; // = this.rcvarsservice.activemap["spawn"];
                    for (var key in this.registry.list.rcvarpass.activemap['spawn']) {
                        if (this.registry.list.rcvarpass.activemap['spawn'][key] == 'yn_y') {
                            spawnpos = key;
                        }
                        //this.rcvarsservice.activemap["spawn"][key];
                    }
                    var spawnposa = spawnpos.split('-');
                    this.registry.list.phm.movemaincharto(Number(spawnposa[1]), Number(spawnposa[0]))
                    // return;
                    var tmpchoices = [];
                    this.plog.log("walker cant find choices, recal without walkfrommap");
                    for (var key in this.registry.list.rcvarpass.activemap['zwarp']) {
                        var tmpwarplist = this.registry.list.rcvarpass.activemap['zwarp'][key] + "";
                        if (tmpwarplist.indexOf("redir-") != -1) continue;
                        if (tmpwarplist == "EVENT") continue;
                        tmpchoices.push(key)
                    }
                    const randomElement = tmpchoices[Math.floor(Math.random() * tmpchoices.length)];
                    var keya = randomElement.split("-");
                    this.registry.list.phm.walkto(Number(keya[0]), Number(keya[1]));
                }

            }
        }

        /*if (this.updatetrigger100 % 3 == 0) {
            if (
                this.biglightsource != undefined &&
                this.char_main != undefined
            ) {
                //this.plog.log("preloader biglightsource ",this.biglightsource);
                //var blcpos = this.getRelativePositionToCanvas(this.mapcontainer, this.cameras.cameras[0]);
                //this.plog.log("updatenightlightposition()2",blcpos);
                var width = this.scene.systems.canvas.width;
                var height = this.scene.systems.canvas.height;
                / *
                this.biglightsource.setPosition(
                    Math.abs(blcpos.x) + Math.floor(width / 2),
                    Math.abs(blcpos.y) + Math.floor(height / 2)
                );* /
                this.biglightsource.setPosition(
                    this.char_main.x,
                    this.char_main.y
                );

            } else {

                if (
                    this.biglightsource == undefined
                ) {
                    var width = this.scene.systems.canvas.width;
                    var height = this.scene.systems.canvas.height;

                    this.biglightsource =
                        this.lights.addLight(
                            width / 2,
                            height / 2,
                            Math.min(width, height)
                        );
                }

            }
        }*/
        /*
                if (
                    this.updatetrigger10 == 0 &&
                    this.biglightsource != undefined
                ) {
                    var blcpos = this.getRelativePositionToCanvas(this.mapcontainer, this.cameras.cameras[0]);
                    //this.plog.log("updatenightlightposition()2",blcpos);
                    var width = this.scene.systems.canvas.width;
                    var height = this.scene.systems.canvas.height;
                    
                    this.biglightsource.setPosition(
                        Math.abs(blcpos.x) + Math.floor(width / 2),
                        Math.abs(blcpos.y) + Math.floor(height / 2)
                    );
                    
                }*/
        if (this.charhitbullettimeout > 0)
            this.charhitbullettimeout--;
        if (this.pauseload == true) {
            //this.plog.log("preloader update pauseload",this.pauseload);
            return;
        }
        if (this.cursorKeys == undefined)
            this.cursorKeys = this.input.keyboard.createCursorKeys();


        //this.plog.log("walkpathcmd",this.walkpathcmd.length,this.walkpathcmd);
        if (this.updatetrigger10 == 0) {
            this.updatezsort(this);
        }
        if (this.updatetrigger10 % 3 == 0) {
            //if (this.updatetrigger10 == 0) {
            this.updatezmapcharfollower(this);
            //}
            if (this.maincharelement != undefined && this.char_main != undefined) {
                this.maincharelement.x = this.char_main.x + 24;
                this.maincharelement.y = this.char_main.y - 32;
            }

            //screenbg
            if (
                this.registry.list.rcvarpass != undefined &&
                this.registry.list.rcvarpass.activemap != undefined &&
                this.registry.list.rcvarpass.activemap['map'] != undefined &&
                this.registry.list.rcvarpass.activemap['map']['screenbg'] != undefined
            ) {
                //this.plog.log("screenbg preloader update",this.screenbg);
                for (const screenbgk in this.registry.list.rcvarpass.activemap['map']['screenbg']) {
                    if (this.registry.list.rcvarpass.activemap['map']['screenbg'][screenbgk] != undefined) {
                        var scrbv = this.registry.list.rcvarpass.activemap['map']['screenbg'][screenbgk];
                        if (this.screenbg[scrbv["key"]] != undefined) {
                            this.screenbg[scrbv["key"]].tilePositionX += (scrbv["spd"] / 10);
                        }
                    }
                }
            }
        }
        /*if (this.maincharrarity != undefined && this.char_main != undefined) {
            this.maincharrarity.x = this.char_main.x + 0;
            this.maincharrarity.y = this.char_main.y +16;
        }*/
        var walkcmdbyinputhold = "";
        /*
        if (this.registry.list.gp != undefined && this.updatetrigger10 % 3 == 0) {
            this.plog.log("GPS ", this.registry.list.gp);
        }        */

        const pad = this.input.gamepad.getPad(0);
        var gpup = false; var gpdown = false; var gpleft = false; var gpright = false;
        if (pad != undefined && pad.axes != undefined && pad.axes.length) {
            const axisH = pad.axes[0].getValue();
            const axisV = pad.axes[1].getValue();
            if (axisH == -1) gpleft = true;
            if (axisH == 1) gpright = true;
            if (axisV == -1) gpup = true;
            if (axisV == 1) gpdown = true;
        }
        if (this.registry.list.gp != undefined && this.kbfocus == "" && walkcmdbyinputhold == "") {
            if (this.cursorKeys.up.isDown == true || this.cursorKeys.down.isDown == true || this.cursorKeys.left.isDown == true || this.cursorKeys.right.isDown == true || this.registry.list.gp.arrowDown == true || gpup || gpdown || gpleft || gpright) {
                this.walkpathcmd = [];
                var dir;


                //this.plog.log(pad, gpup, gpdown, gpleft, gpright);

                if (this.cursorKeys.up.isDown || this.registry.list.gp.isUp || gpup) {
                    dir = "up";
                    this.char_main_lastspriteface = "up";
                }
                if (this.cursorKeys.down.isDown || this.registry.list.gp.isDown || gpdown) {
                    dir = "down";
                    this.char_main_lastspriteface = "down";
                }
                if (this.cursorKeys.left.isDown || this.registry.list.gp.isLeft || gpleft) {
                    dir = "left";
                    this.char_main_lastspriteface = "left";
                }
                if (this.cursorKeys.right.isDown || this.registry.list.gp.isRight || gpright) {
                    dir = "right";
                    this.char_main_lastspriteface = "right";
                }
                if ((this.cursorKeys.up.isDown && this.cursorKeys.left.isDown) || (this.registry.list.gp.isUp && this.registry.list.gp.isLeft) || (gpup == true && gpleft == true)) {
                    dir = "up left";
                    this.char_main_lastspriteface = "left";
                }
                if ((this.cursorKeys.up.isDown && this.cursorKeys.right.isDown) || (this.registry.list.gp.isUp && this.registry.list.gp.isRight) || (gpup == true && gpright == true)) {
                    dir = "up right";
                    this.char_main_lastspriteface = "right";
                }
                if ((this.cursorKeys.down.isDown && this.cursorKeys.right.isDown) || (this.registry.list.gp.isDown && this.registry.list.gp.isRight) || (gpdown == true && gpright == true)) {
                    dir = "down right";
                    this.char_main_lastspriteface = "right";
                }
                if ((this.cursorKeys.down.isDown && this.cursorKeys.left.isDown) || (this.registry.list.gp.isDown && this.registry.list.gp.isLeft) || (gpdown == true && gpleft == true)) {
                    dir = "down left";
                    this.char_main_lastspriteface = "left";
                }

                this.maincharwalk(dir, this);
            }
        }

        if (this.walkpathcmd.length > 0) {
            //this.plog.log("preloader walkpathcmd will walkthen",this.walkpathcmd.length);
            if (this.maptweening != undefined && this.maptweening != true && this.iswarping == false) {
                this.maptweeningchkcount = 0;
                var nextstop = this.walkpathcmd.shift();;
                //this.plog.log("I'm at", this.char_main.currentx, this.char_main.currenty);
                //this.plog.log("nextstop", nextstop);
                //find direction 
                var dir;
                if (nextstop[0] > this.char_main.currentx && nextstop[1] > this.char_main.currenty) {
                    dir = "down right";
                    this.char_main_lastspriteface = "right";
                }
                if (nextstop[0] > this.char_main.currentx && nextstop[1] < this.char_main.currenty) {
                    dir = "up right";
                    this.char_main_lastspriteface = "right";
                }
                if (nextstop[0] < this.char_main.currentx && nextstop[1] < this.char_main.currenty) {
                    dir = "up left";
                    this.char_main_lastspriteface = "left";
                }
                if (nextstop[0] < this.char_main.currentx && nextstop[1] > this.char_main.currenty) {
                    dir = "down left";
                    this.char_main_lastspriteface = "left";
                }
                if (nextstop[0] == this.char_main.currentx && nextstop[1] > this.char_main.currenty) {
                    dir = "down";
                    this.char_main_lastspriteface = "down";
                }
                if (nextstop[0] == this.char_main.currentx && nextstop[1] < this.char_main.currenty) {
                    dir = "up";
                    this.char_main_lastspriteface = "up";
                }
                if (nextstop[0] > this.char_main.currentx && nextstop[1] == this.char_main.currenty) {
                    dir = "right";
                    this.char_main_lastspriteface = "right";
                }
                if (nextstop[0] < this.char_main.currentx && nextstop[1] == this.char_main.currenty) {
                    dir = "left";
                    this.char_main_lastspriteface = "left";
                }
                this.maincharwalk(dir, this);
            } else {
                this.maptweeningchkcount++;
                //this.plog.log("preloader update but maptweening",this.maptweening,this.maptweeningchkcount);
                if (this.maptweeningchkcount > 100) {
                    this.maptweening = false;
                    this.maptweeningchkcount = 0;
                }
            }
        } else {
            //this.plog.log("preloader update maptweening",this.maptweening);
            if (this.maptweening != true) {
                //this.plog.log("map not tweening");
                var s = '';

                if (this.joycursor["up"].isDown || this.joycursor["down"].isDown || this.joycursor["left"].isDown || this.joycursor["right"].isDown) {
                    //this is for joy
                    //this.plog.log(this.joycursor);
                    this.walkingtonpc = "";
                    for (var name in this.joycursor) {
                        if (this.joycursor[name].isDown) {
                            s += name + ' ';
                        }
                    }
                    s = s.trim();
                    //this.plog.log("update joy", "[" + s + "]");

                    this.char_main_lastspriteface = s;
                    this.maincharwalk(s, this);
                    //this.phasermapservice.maincharwalk(s,this);
                }
                //this.walkdoneaction=="" && 
                if (this.char_main != undefined && s == "") {
                    //this.plog.log("finding idlepos",this);
                    var charspritename = this.registry.list.rcvarpass.activechar["sprite"];
                    //this.loadcharsprite(this.rcvarsservice.activechar["sprite"]);
                    if (!this.anims.exists(charspritename + "-idle")) {
                        //this.plog.log("xxxanims not exists", charspritename);
                        this.registry.list.phm.loadcharsprite(charspritename);
                    }
                    //this.plog.log("char_main_lastspriteface",this.char_main_lastspriteface)
                    //this.plog.log("char_main_lastspriteface play idle sprite", this.char_main)
                    //this.plog.log("this.char_main.anims.currentAnim.key",this.char_main.anims.currentAnim.key);
                    if (this.char_main.anims == undefined) {
                        this.plog.log("xxxxxxxxxx", this.char_main);
                    }
                    if (this.char_main_lastspriteface == "up") {
                        if (this.char_main.anims != undefined && this.char_main.anims.currentAnim != undefined)
                            if (this.char_main.anims.currentAnim.key == charspritename + "-walk-u")
                                this.char_main.play({ key: charspritename + "-walk-u-idle", repeatDelay: 2000 }, true);
                    }
                    if (this.char_main_lastspriteface == "left" || this.char_main_lastspriteface == "up left" || this.char_main_lastspriteface == "down left") {
                        if (this.char_main.anims != undefined && this.char_main.anims.currentAnim != undefined)
                            if (this.char_main.anims.currentAnim.key == charspritename + "-walk-l")
                                this.char_main.play({ key: charspritename + "-walk-l-idle", repeatDelay: 2000 }, true);
                    }
                    if (this.char_main_lastspriteface == "right" || this.char_main_lastspriteface == "up right" || this.char_main_lastspriteface == "down right") {
                        if (this.char_main.anims != undefined && this.char_main.anims.currentAnim != undefined)
                            if (this.char_main.anims.currentAnim.key == charspritename + "-walk-r")
                                this.char_main.play({ key: charspritename + "-walk-r-idle", repeatDelay: 2000 }, true);
                    }
                    if (this.char_main_lastspriteface == "down") {
                        if (this.char_main.anims != undefined && this.char_main.anims.currentAnim != undefined)
                            if (this.char_main.anims.currentAnim.key == charspritename + "-walk-d") {
                                try {
                                    this.char_main.play({ key: charspritename + "-walk-d-idle", repeatDelay: 2000 }, true);
                                } catch (err) {
                                    this.plog.log("ERR", err);
                                }
                            }
                    }
                    //this.plog.log("this.char_main_lastspriteface",this.char_main_lastspriteface);
                }
                if (this.walkdoneaction != "" && this.walkdoneaction != undefined) {
                    this.plog.log("walkdoneaction", charspritename, this.walkdoneaction, this.char_main);
                    if (this.char_main_lastspriteface == "up") {
                        this.char_main.play({ key: charspritename + "-" + this.walkdoneaction + "-u", frameRate: 16, repeat: 1 }, false).chain({ key: charspritename + "-walk-u-idle", repeatDelay: 2000 }, true);
                        this.plog.log("walkdoneaction d", this.char_main_lastspriteface, "u", { key: charspritename + "-" + this.walkdoneaction + "-u", frameRate: 3 });
                    }
                    if (this.char_main_lastspriteface == "left" || this.char_main_lastspriteface == "up left" || this.char_main_lastspriteface == "down left") {
                        this.char_main.play({ key: charspritename + "-" + this.walkdoneaction + "-l", frameRate: 16, repeat: 1 }, false).chain({ key: charspritename + "-walk-l-idle", repeatDelay: 2000 }, true);
                        this.plog.log("walkdoneaction d", this.char_main_lastspriteface, "l", { key: charspritename + "-" + this.walkdoneaction + "-l", frameRate: 3 });
                    }
                    if (this.char_main_lastspriteface == "right" || this.char_main_lastspriteface == "up right" || this.char_main_lastspriteface == "down right") {
                        this.char_main.play({ key: charspritename + "-" + this.walkdoneaction + "-r", frameRate: 16, repeat: 1 }, false).chain({ key: charspritename + "-walk-r-idle", repeatDelay: 2000 }, true);
                        this.plog.log("walkdoneaction d", this.char_main_lastspriteface, "r", { key: charspritename + "-" + this.walkdoneaction + "-r", frameRate: 3 });
                    }
                    if (this.char_main_lastspriteface == "down") {
                        this.char_main.play({ key: charspritename + "-" + this.walkdoneaction + "-d", frameRate: 16, repeat: 1 }, false).chain({ key: charspritename + "-walk-d-idle", repeatDelay: 2000 }, true);
                        this.plog.log("walkdoneaction d", this.char_main_lastspriteface, "d", { key: charspritename + "-" + this.walkdoneaction + "-d", frameRate: 3 });
                    }
                    this.walkdoneaction = "";
                }
                //s += '\n';
                //s += ('Force: ' + Math.floor(this.joyStick.force * 100) / 100 + '\n');
                //s += ('Angle: ' + Math.floor(this.joyStick.angle * 100) / 100 + '\n');
            } else {
                //this.plog.log("preloader update maptweening",this.maptweening);

            }
        }
    }
    tweenspritealpha(wh, sprite, alphax) {
        //mainly use with map border
        //var tweenbd = 
        wh.tweens.add({
            targets: sprite,
            alpha: alphax,
            ease: 'Power1',
            duration: 500,
            repeat: 0
        });
    }
    isladder(wh, x, y) {
        //console.log("isladder", x, y);
        if (wh.registry.list.rcvarpass.activemap == undefined) return false;
        if (wh.registry.list.rcvarpass.activemap['ladder'] == undefined) return false;
        if (wh.registry.list.rcvarpass.activemap["ladder"][x + "-" + y] == "yn_y") {
            //console.log("LADDER");
            return true;
        }
        return false;
    }
    async maincharwalk(dir, wh) {
        this.plog.log("maincharwalk", dir);
        //this.plog.log("maincharwalk this", this);

        var width = this.sys.game.canvas.width;
        var height = this.sys.game.canvas.height;

        var spritename = this.registry.list.rcvarpass.activechar["sprite"];
        if (this.char_main != undefined) {
            this.registry.list.timectl.setlightpos();
        }
        if (wh.activemapdead == true) return;
        if (wh.iswarping == true) return;
        if (wh.guarding == true) {

            if (dir.indexOf("up") != -1) {
                wh.char_main.play({ key: spritename + "-walk-u", repeat: 0, frameRate: 1 }, true); return;
            }
            if (dir.indexOf("down") != -1) {
                wh.char_main.play({ key: spritename + "-walk-d", repeat: 0, frameRate: 1 }, true); return;
            }
            if (dir.indexOf("left") != -1) {
                wh.char_main.play({ key: spritename + "-walk-l", repeat: 0, frameRate: 1 }, true); return;
            }
            if (dir.indexOf("right") != -1) {
                wh.char_main.play({ key: spritename + "-walk-r", repeat: 0, frameRate: 1 }, true); return;
            }
            return;
        }
        if (wh.maptweening == true) return;
        if (wh.mapbgimg == undefined) return;
        //this.plog.log("mapbound start", wh.mapbgimg, width, height);
        if (wh.mapbgimg == undefined) this.plog.log("ERROR map bgimg not exists");
        var mapminx = 0 - ((wh.mapbgimg.displayWidth) - (width));
        var mapmaxx = 0;
        var mapminy = 0 - ((wh.mapbgimg.displayHeight) - (height));
        var mapmaxy = 0;
        var newcx = 0;
        var newcy = 0;
        var dirx = 0;
        var diry = 0;

        //wh.char_main.x = 100;        wh.char_main.y = 100;
        newcx = wh.char_main.currentx;
        newcy = wh.char_main.currenty;
        var walkspeednormal = 140;
        var walkspeedslow = 170;
        walkspeednormal = 200;
        walkspeedslow = 240;
        if (wh.registry.list.rcvarpass.activechar['walkspeedboost'] == undefined)
            wh.registry.list.rcvarpass.activechar['walkspeedboost'] = 0;
        var incwalkspd = wh.registry.list.rcvarpass.activechar['walkspeedboost'] * 5;
        if (incwalkspd > Math.min(walkspeednormal, walkspeedslow))
            incwalkspd = Math.min(walkspeednormal, walkspeedslow);
        walkspeednormal = walkspeednormal - incwalkspd;
        walkspeedslow = walkspeedslow - incwalkspd;

        var walkspeed = walkspeednormal;
        var tmpposchk;
        var tmpposchka1;
        var tmpposchka2;
        if (dir == "up") {
            newcy -= 1;
            diry = 32;
            if (this.isladder(wh, wh.char_main.currentx, wh.char_main.currenty)) {
                wh.char_main.play({
                    key: spritename + "-climb", repeat: 0,
                    frameRate: 12
                }, true);
            } else {
                wh.char_main.play({
                    key: spritename + "-walk-u", repeat: -1,
                    frameRate: 12
                }, true);
            }
        }
        if (dir == "up right") {
            newcy -= 1;
            newcx += 1;
            diry = 32;
            dirx = -32;
            if (this.isladder(wh, wh.char_main.currentx, wh.char_main.currenty)) {
                wh.char_main.play({
                    key: spritename + "-climb", repeat: 0,
                    frameRate: 12
                }, true);
            } else {
                wh.char_main.play({
                    key: spritename + "-walk-r", repeat: -1,
                    frameRate: 12
                }, true);
            }
            walkspeed = walkspeedslow;
            tmpposchk = this.registry.list.rcvarpass.activemap["walkable"][newcx + "-" + newcy];
            //this.plog.log("tmpposchk",tmpposchk);
            if (tmpposchk != "yn_y") {
                tmpposchka1 = this.registry.list.rcvarpass.activemap["walkable"][newcx + "-" + (newcy + 1)];
                tmpposchka2 = this.registry.list.rcvarpass.activemap["walkable"][(newcx - 1) + "-" + newcy];
                if (tmpposchka1 == "yn_y") {
                    newcy += 1;
                    diry = 0;
                }
                if (tmpposchka2 == "yn_y") {
                    newcx -= 1;
                    dirx = 0;
                }
            }
        }
        if (dir == "right") {
            newcx += 1;
            dirx = -32;
            if (this.isladder(wh, wh.char_main.currentx, wh.char_main.currenty)) {
                wh.char_main.play({
                    key: spritename + "-climb", repeat: 0,
                    frameRate: 12
                }, true);
            } else {
                wh.char_main.play({
                    key: spritename + "-walk-r", repeat: -1,
                    frameRate: 12
                }, true);
            }
        }
        if (dir == "down right") {
            newcy += 1;
            newcx += 1;
            diry = -32;
            dirx = -32;
            if (this.isladder(wh, wh.char_main.currentx, wh.char_main.currenty)) {
                wh.char_main.play({
                    key: spritename + "-climb", repeat: 0,
                    frameRate: 12
                }, true);
            } else {
                wh.char_main.play({
                    key: spritename + "-walk-r", repeat: -1,
                    frameRate: 12
                }, true);
            }
            walkspeed = walkspeedslow;
            tmpposchk = this.registry.list.rcvarpass.activemap["walkable"][newcx + "-" + newcy];
            //this.plog.log("tmpposchk",tmpposchk);
            if (tmpposchk != "yn_y") {
                tmpposchka1 = this.registry.list.rcvarpass.activemap["walkable"][newcx + "-" + (newcy - 1)];
                tmpposchka2 = this.registry.list.rcvarpass.activemap["walkable"][(newcx - 1) + "-" + newcy];
                if (tmpposchka1 == "yn_y") {
                    newcy -= 1;
                    diry = 0;
                }
                if (tmpposchka2 == "yn_y") {
                    newcx -= 1;
                    dirx = 0;
                }
            }
        }
        if (dir == "down") {
            newcy += 1;
            diry = -32;
            if (this.isladder(wh, wh.char_main.currentx, wh.char_main.currenty)) {
                wh.char_main.play({
                    key: spritename + "-climb", repeat: 0,
                    frameRate: 12
                }, true);
            } else {
                wh.char_main.play({
                    key: spritename + "-walk-d", repeat: -1,
                    frameRate: 12
                }, true);
            }
        }
        if (dir == "down left") {
            newcy += 1;
            newcx -= 1;
            diry = -32;
            dirx = 32;
            if (this.isladder(wh, wh.char_main.currentx, wh.char_main.currenty)) {
                wh.char_main.play({
                    key: spritename + "-climb", repeat: 0,
                    frameRate: 12
                }, true);
            } else {
                wh.char_main.play({
                    key: spritename + "-walk-l", repeat: -1,
                    frameRate: 12
                }, true);
            }
            walkspeed = walkspeedslow;
            tmpposchk = this.registry.list.rcvarpass.activemap["walkable"][newcx + "-" + newcy];
            //this.plog.log("tmpposchk",tmpposchk);
            if (tmpposchk != "yn_y") {
                tmpposchka1 = this.registry.list.rcvarpass.activemap["walkable"][newcx + "-" + (newcy - 1)];
                tmpposchka2 = this.registry.list.rcvarpass.activemap["walkable"][(newcx + 1) + "-" + newcy];
                if (tmpposchka1 == "yn_y") {
                    newcy -= 1;
                    diry = 0;
                }
                if (tmpposchka2 == "yn_y") {
                    newcx += 1;
                    dirx = 0;
                }
            }
        }
        if (dir == "left") {
            newcx -= 1;
            dirx = 32;
            if (this.isladder(wh, wh.char_main.currentx, wh.char_main.currenty)) {
                wh.char_main.play({
                    key: spritename + "-climb", repeat: 0,
                    frameRate: 12
                }, true);
            } else {
                wh.char_main.play({
                    key: spritename + "-walk-l", repeat: -1,
                    frameRate: 12
                }, true);
            }
        }
        if (dir == "up left") {
            newcy -= 1;
            newcx -= 1;
            diry = 32;
            dirx = 32;
            if (this.isladder(wh, wh.char_main.currentx, wh.char_main.currenty)) {
                wh.char_main.play({
                    key: spritename + "-climb", repeat: 0,
                    frameRate: 12
                }, true);
            } else {
                wh.char_main.play({
                    key: spritename + "-walk-l", repeat: -1,
                    frameRate: 12
                }, true);
            }
            walkspeed = walkspeedslow;
            tmpposchk = this.registry.list.rcvarpass.activemap["walkable"][newcx + "-" + newcy];
            //this.plog.log("tmpposchk",tmpposchk);
            if (tmpposchk != "yn_y") {
                tmpposchka1 = this.registry.list.rcvarpass.activemap["walkable"][newcx + "-" + (newcy + 1)];
                tmpposchka2 = this.registry.list.rcvarpass.activemap["walkable"][(newcx + 1) + "-" + newcy];
                if (tmpposchka1 == "yn_y") {
                    newcy += 1;
                    diry = 0;
                }
                if (tmpposchka2 == "yn_y") {
                    newcx += 1;
                    dirx = 0;
                }
            }
        }

        //fow draw
        try {
            //console.log(wh);
            wh.registry.list.rcvarpass.activemapreveal[newcx + "-" + newcy] = 1;
        } catch (e) {
            console.log(e);
        }

        wh.registry.list.phm.fow_clear(newcx, newcy, 'revealbrush');
        //wh.fow.erase('revealbrush', newcx * 32 - wh.fowsize + 16, newcy * 32 - wh.fowsize + 16);


        //this.plog.log("walkspeed",walkspeed);
        //check if prev map (when use joystick to walk)
        //registry.list.rcvarpass.activechar["walkfrommap"]
        if (wh.registry.list.rcvarpass.activechar["walkfrommap"] != undefined && this.registry.list.rcvarpass.activemap["zwarp"] != undefined) {
            var chkmapzwarp = this.registry.list.rcvarpass.activemap["zwarp"][newcx + "-" + newcy];
            //this.plog.log("chkmapzwarp", newcx + "-" + newcy, chkmapzwarp, wh.registry.list.rcvarpass.activechar["walkfrommap"], this.registry.list.rcvarpass.activemap["zwarp"]);
            if (chkmapzwarp == wh.registry.list.rcvarpass.activechar["walkfrommap"] ||
                chkmapzwarp == "redir-" + wh.registry.list.rcvarpass.activechar["walkfrommap"]) {
                //walk back to redir area of just left map
                return;
            } else {
                wh.registry.list.rcvarpass.activechar["walkfrommap"] = undefined;
            }
        }

        wh.registry.list.snd.snd("footstep");

        var newposwalkable = this.registry.list.rcvarpass.activemap["walkable"][newcx + "-" + newcy];
        //this.plog.log("xxx newposwalkable", newposwalkable);
        if (newposwalkable == "yn_y") {
            //this.plog.log("ok walkable");
            //this.plog.log("xxx", newcx, newcy, dirx, diry);
            //var tmpmap = new RCVarsService(wh);
            //this.plog.log("map", tmpmap);
            wh.maptweening = true;
            wh.char_main.currentx = newcx;
            wh.char_main.currenty = newcy;

            //wh.char_main.play('mainchar-walk-d');
            //wh.char_main.x = (newcx * 32);
            //wh.char_main.y = (newcy * 32);
            //this.plog.log("mapbound", (wh.mapcontainer.x + dirx), mapminx);
            //this.plog.log("mapbound", "mapminx", mapminx, (wh.mapcontainer.x + dirx));
            //this.plog.log("mapbound", "mapmaxx", mapmaxx, (wh.mapcontainer.x + dirx));
            //this.plog.log("mapbound", "2x", wh.mapcontainer.x,wh.char_main.x);

            var mapboundmarginw = width * 0.2;
            var mapboundmarginwm = width * 0.5;
            var mapboundmarginwe = width * 0.8;
            var mapboundmarginh = height * 0.2;
            var mapboundmarginhm = height * 0.5;
            var mapboundmarginhe = height * 0.8;

            var charactualx = wh.mapcontainer.x + wh.char_main.x;
            var charactualy = wh.mapcontainer.y + wh.char_main.y;
            //this.plog.log("mapbound", "charactualx", charactualx);
            //this.plog.log("mapbound margin ", mapboundmarginw);

            if (dirx > 0) {
                //going left
                if ((wh.mapcontainer.x + dirx) > mapmaxx) {
                    //not move map if close to screen edge
                    dirx = 0;
                    if (charactualx < mapboundmarginw) {
                        //but not if closer than 20%
                        dirx = 32;
                        this.tweenspritealpha(wh, wh.mapbdl, 1);
                    }
                }
                if (charactualx > mapboundmarginwm) {
                    dirx = 0;
                }
            }
            if ((wh.mapcontainer.x + dirx) < mapmaxx) {
                this.tweenspritealpha(wh, wh.mapbdl, 0.0);
            }

            if (dirx < 0) {
                //going right
                if ((wh.mapcontainer.x + dirx) < mapminx) {
                    //not move map if close to screen edge
                    dirx = 0;
                    if (charactualx > mapboundmarginwe) {
                        dirx = -32;
                        this.tweenspritealpha(wh, wh.mapbdr, 1);
                    }
                }
                if (charactualx < mapboundmarginwm) {
                    dirx = 0;
                }
            }
            if ((wh.mapcontainer.x + dirx) > mapminx) {
                this.tweenspritealpha(wh, wh.mapbdr, 0.0);
            }

            if (diry > 0) {
                //going up
                if ((wh.mapcontainer.y + diry) > mapmaxy) {
                    //not move map if close to screen edge
                    diry = 0;
                    if (charactualy < mapboundmarginh) {
                        //but not if closer than 20%
                        diry = 32;
                        this.tweenspritealpha(wh, wh.mapbdu, 1);
                    }
                }
                if (charactualy > mapboundmarginhm) {
                    diry = 0;
                }
            }
            if ((wh.mapcontainer.y + diry) < mapmaxy) {
                this.tweenspritealpha(wh, wh.mapbdu, 0.0);
            }

            if (diry < 0) {
                //going down
                if ((wh.mapcontainer.y + diry) < mapminy) {
                    //not move map if close to screen edge
                    diry = 0;
                    if (charactualy > mapboundmarginhe) {
                        diry = -32;
                        this.tweenspritealpha(wh, wh.mapbdd, 1);
                    }
                }
                if (charactualy < mapboundmarginhm) {
                    diry = 0;
                }
            }
            if ((wh.mapcontainer.y + diry) > mapminy) {
                this.tweenspritealpha(wh, wh.mapbdd, 0.0);
            }

            /*wh.maincharwalktween = wh.tweens.add({
                targets: wh.mapcontainer,
                x: wh.mapcontainer.x + dirx,
                y: wh.mapcontainer.y + diry,
                ease: 'Power1',
                duration: walkspeed,
                repeat: 0,
                onUpdate: function () {
                    //this.plog.log("mapcontainer light update",arguments);
                    arguments[0].parent.scene.updatenightlightposition();
                },
                onStart: function () {
                    //this.plog.log('onStart'); this.plog.log(arguments); 
                },
                onComplete: function (wh) {
                    //this.plog.log('onComplete', this);
                    //this.plog.log('onComplete', arguments);
                    if (arguments[1][0] != undefined && arguments[1][0].scene != undefined && arguments[1][0].scene.char_main != undefined) {
                        arguments[1][0].scene.maptweening = false;
                        arguments[1][0].scene.registry.list.rcvarpass.activechar["mappos"] = arguments[1][0].scene.char_main.currentx + "-" + arguments[1][0].scene.char_main.currenty;
                        arguments[1][0].scene.chkwarppos();
                        arguments[1][0].scene.chkpossay();
                        arguments[1][0].scene.chkanims_stepon();

                        if (arguments[1][0].scene.walkpathcmd.length == 0) {
                            arguments[1][0].scene.chkzmapitem();
                        }
                        / ****if (arguments[1][0].scene.registry.list.rcvarpass.activechar['cbt'] != undefined && arguments[1][0].scene.registry.list.rcvarpass.activechar['cbt']["raritycount"] != undefined && arguments[1][0].scene.registry.list.rcvarpass.activechar['cbt']["raritycount"] != "" && arguments[1][0].scene.registry.list.rcvarpass.activechar['cbt']["raritycount"] + "" != "0") {
                            arguments[1][0].scene.maincharrarity = arguments[1][0].scene.add
                                .sprite(100, 100, "effleg", "effleg_b");
                            arguments[1][0].scene.maincharrarity.play("effleg_b");
                            arguments[1][0].scene.maincharrarity.setAlpha(arguments[1][0].scene.registry.list.rcvarpass.activechar['cbt']["raritycount"] / 10);
                            arguments[1][0].scene.maincharrarity.displayWidth = arguments[1][0].scene.maincharrarity.displayHeight = 72;
                            arguments[1][0].scene.mapcontainer.add(arguments[1][0].scene.maincharrarity);
                            arguments[1][0].scene.mapcontainer.bringToTop(arguments[1][0].scene.maincharrarity);
                            arguments[1][0].scene.mapcontainer.bringToTop(arguments[1][0].scene.char_main);
                            this.plog.log("rarity tweencomplete", arguments[1][0].scene.char_main);

                            arguments[1][0].scene.maincharrarity.setPosition(arguments[1][0].scene.char_main.x, arguments[1][0].scene.char_main.y-24);
                            var tweenchar = arguments[1][0].scene.tweens.add({
                                targets: arguments[1][0].scene.maincharrarity,
                                alpha: 0,
                                ease: 'Power1',
                                duration: 300,
                                delay: 100,
                                onComplete: function () {
                                    //this.plog.log("rarity tweencomplete",this,arguments);
                                    if (arguments[1][0]!=undefined) arguments[1][0].destroy();
                                }
                            });
                        }
                        **** /
                    }
                    //this.plog.log("preloader talkingtonpc?", arguments,arguments[1][0].scene,arguments,arguments[1][0].scene.walkpathcmd);

                    if (arguments[1][0] != undefined && arguments[1][0].scene != undefined && arguments[1][0].scene.walkingtonpc != "" && arguments[1][0].scene.registry != undefined && (arguments[1][0].scene.walkpathcmd != undefined && arguments[1][0].scene.walkpathcmd.length == 0)) {
                        //this.plog.log("preloader talkingtonpc", arguments[1][0].scene.walkingtonpc);
                        //arguments[1][0].scene.walkingtonpc="";
                        arguments[1][0].scene.registry.list.npcconver.starttalk(arguments[1][0].scene.walkingtonpc, arguments[1][0].scene);
                    }
                    //this.rcvarsservice.gameobj.scene.scenes[0].registry.list["resizeman"].work(this.rcvarsservice.gameobj.scene.scenes[0]);
                    if (arguments[1][0] != undefined && arguments[1][0].scene != undefined) {
                        arguments[1][0].scene.registry.list["gameitf"].updateminimap(arguments[1][0].scene);
                    }
                    if (arguments[1][0].scene != undefined) {
                        arguments[1][0].scene.update();
                    }
                },
            });*/
            //if (wh.gifscontainer != undefined)                wh.cameras.main.ignore(wh.gifscontainer);

            //wh.mapbgimg.setPosition(wh.defaultmaplayerx+32, wh.defaultmaplayery+32);
            wh.mapcontainer.setPosition(0, 0);
            //peacepos
            // this.plog.log("wh.mapbgimg", wh.mapbgimg, newcx, newcy);
            var tweenchar = wh.tweens.add({
                targets: wh.char_main,
                x: wh.mapbgimg.x + (newcx * 32) + wh.defaultmaplayerx + 0,
                y: wh.mapbgimg.y + (newcy * 32) + wh.defaultmaplayery + 0,
                ease: 'Linear',//Power1
                duration: walkspeed,
                repeat: 0,
                onComplete: function (wh) {
                    //this.plog.log('onComplete', this);
                    //this.plog.log('onComplete', arguments);
                    if (arguments[1][0] != undefined && arguments[1][0].scene != undefined && arguments[1][0].scene.char_main != undefined) {
                        arguments[1][0].scene.maptweening = false;
                        arguments[1][0].scene.registry.list.rcvarpass.activechar["mappos"] = arguments[1][0].scene.char_main.currentx + "-" + arguments[1][0].scene.char_main.currenty;
                        arguments[1][0].scene.chkwarppos();
                        arguments[1][0].scene.chkpossay();
                        arguments[1][0].scene.chkanims_stepon();

                        if (arguments[1][0].scene.walkpathcmd.length == 0) {
                            arguments[1][0].scene.chkzmapitem();
                        }

                    }
                    //this.plog.log("preloader talkingtonpc?", arguments,arguments[1][0].scene,arguments,arguments[1][0].scene.walkpathcmd);

                    if (arguments[1][0] != undefined && arguments[1][0].scene != undefined && arguments[1][0].scene.walkingtonpc != "" && arguments[1][0].scene.registry != undefined && (arguments[1][0].scene.walkpathcmd != undefined && arguments[1][0].scene.walkpathcmd.length == 0)) {
                        //this.plog.log("preloader talkingtonpc", arguments[1][0].scene.walkingtonpc);
                        //arguments[1][0].scene.walkingtonpc="";
                        arguments[1][0].scene.registry.list.npcconver.starttalk(arguments[1][0].scene.walkingtonpc, arguments[1][0].scene);
                    }
                    //this.rcvarsservice.gameobj.scene.scenes[0].registry.list["resizeman"].work(this.rcvarsservice.gameobj.scene.scenes[0]);
                    if (arguments[1][0] != undefined && arguments[1][0].scene != undefined) {
                        arguments[1][0].scene.registry.list["gameitf"].updateminimap(arguments[1][0].scene);
                    }
                    if (arguments[1][0].scene != undefined) {
                        arguments[1][0].scene.update();
                    }
                },
                onUpdate: function () {
                    this.registry.list.timectl.setlightpos();
                }.bind(this)
            });

        } else {
            //this.plog.log("nuh uh, un-walkable");
        }
    }
    updatezsort(wh) {
        //this.plog.log("updatezsort");
        for (const key1 in wh.zsortlist) {
            var callerobj = wh.zsortlist[key1];
            if (callerobj == undefined) continue;
            var tmpchary = callerobj.y + 0;//wh.char_main.y + 0;
            //this.plog.log("zsortfloorlevel ",callerobj.zsortfloorlevel);
            //this.plog.log("zsortdub ", callerobj);
            var findida = (callerobj.name + "").split("--");
            if (wh.registry.list.rcvarpass.allanims[findida[2]] != undefined && wh.registry.list.rcvarpass.allanims[findida[2]]["playon"] == "topmost") {
                //this.plog.log("findida bringToTop", callerobj); 
                wh.mapcontainer.bringToTop(callerobj);
                continue;
            }
            if (wh.registry.list.rcvarpass.allanims[findida[2]] != undefined && wh.registry.list.rcvarpass.allanims[findida[2]]["playon"] == "bottom") {
                //this.plog.log("findida sendToBack", callerobj);
                wh.mapcontainer.sendToBack(callerobj);
                continue;
            }
            if (callerobj.zsortfloorlevel == true) {
                for (const key in wh.zsortlist) {
                    if (Object.prototype.hasOwnProperty.call(wh.zsortlist, key)) {
                        if (wh.zsortlist[key] != undefined) {
                            const element = wh.zsortlist[key];
                            if (element == undefined) continue;
                            if (element.zsortfloorlevel == true) continue;
                            if (wh.mapcontainer.list.includes(element) && wh.mapcontainer.list.includes(callerobj))
                                wh.mapcontainer.moveAbove(element, callerobj);
                            //continue;
                            // this.plog.log("updatezsort",callerobj,element);
                        }
                    }
                }
            } else {
                for (const key in wh.zsortlist) {
                    if (Object.prototype.hasOwnProperty.call(wh.zsortlist, key)) {
                        if (wh.zsortlist[key] != undefined) {
                            const element = wh.zsortlist[key];
                            var findida = (element.name + "").split("--");
                            if (wh.registry.list.rcvarpass.allanims[findida[2]] != undefined && (wh.registry.list.rcvarpass.allanims[findida[2]]["playon"] == "topmost" || wh.registry.list.rcvarpass.allanims[findida[2]]["playon"] == "bottom")) {
                                //this.plog.log("findida2 skipping");
                                continue;
                            }
                            if (element == undefined) continue;
                            if (element.zsortfloorlevel != true) {
                                // this.plog.log("zi",element);
                                if (element.y < tmpchary) {
                                    //this.plog.log("movebelow b",wh.mapcontainer,element, callerobj);
                                    if (wh.mapcontainer.list.includes(element) && wh.mapcontainer.list.includes(callerobj))
                                        wh.mapcontainer.moveBelow(element, callerobj);
                                    //this.plog.log("movebelow b",element.y, this.char_main.y);
                                    //this.plog.log("zi LOW",tmpchary,element.y);
                                }
                                if (element.y > tmpchary) {
                                    if (wh.mapcontainer.list.includes(element) && wh.mapcontainer.list.includes(callerobj))
                                        wh.mapcontainer.moveBelow(callerobj, element);
                                    //this.plog.log("movebelow u",element.y, this.char_main.y);
                                    //this.plog.log("zi HIG",tmpchary,element.y);
                                }
                            }
                        }
                    }
                }
            }
        }
        if (wh.maplayers != undefined && wh.maplayers["bg"] != undefined) {
            for (const key in wh.zsortlist) {
                if (Object.prototype.hasOwnProperty.call(wh.zsortlist, key)) {
                    if (wh.zsortlist[key] != undefined) {
                        const element = wh.zsortlist[key];
                        if (element == undefined) continue;
                        if (element.zsortfloorlevel == true) {

                            if (wh.mapcontainer.list.includes(element)) {
                                try {
                                    wh.mapcontainer.moveBelow(element, wh.maplayers["bg"]);
                                    wh.mapcontainer.moveAbove(element, wh.maplayers["bg"]);
                                } catch (e) {
                                    this.plog.log("ERROR", e);
                                    //remove to prevent further error
                                    wh.zsortlist[key] = undefined;
                                }
                            }
                        }
                    }
                }
            }
        }

        if (wh.maplayers != undefined && wh.maplayers["fg"] != undefined) {
            for (const key in wh.zsortlist) {
                if (Object.prototype.hasOwnProperty.call(wh.zsortlist, key)) {
                    if (wh.zsortlist[key] != undefined) {
                        const element = wh.zsortlist[key];
                        if (element == undefined) continue;
                        if (element.zsorttoplevel == true) {
                            if (wh.mapcontainer.list.includes(element)) {
                                this.plog.error("moveAbove", element, wh.maplayers["fg"]);
                                try {
                                    wh.mapcontainer.moveAbove(element, wh.maplayers["fg"]);
                                } catch (e) {
                                    this.plog.log("ERROR", e);
                                    //remove to prevent further error
                                    wh.zsortlist[key] = undefined;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    pausemapmob() {
        for (var key in this.mobs) {
            var mapmobi = this.mobs[key];
            //this.plog.log("xxx", mapmobi);
            if (mapmobi == undefined) continue;
            if (mapmobi.sprite == undefined) continue;
            if (mapmobi.sprite.anims == undefined) continue;
            mapmobi.sprite.anims.pause(mapmobi.sprite.anims.currentAnim.frames[0]);
        }
    }
    resumemapmob() {
        for (var key in this.mobs) {
            var mapmobi = this.mobs[key];
            //this.plog.log("xxx",mapmobi);
            mapmobi.sprite.anims.resume();
        }
    }
    getRelativePositionToCanvas(gameObject, camera) {
        if (gameObject == undefined) {
            return {
                x: 0,
                y: 0
            }
        }
        return {
            x: (gameObject.x - camera.worldView.x) * camera.zoom,
            y: (gameObject.y - camera.worldView.y) * camera.zoom
        }
    }
    updatenightlightposition() {
        //this.plog.log("updatenightlightposition()",this.lightlist);
        return;
        if (this.registry.list.thethis != undefined && this.registry.list.thethis.chkconfiglighteffects != undefined && this.registry.list.thethis.chkconfiglighteffects == "yes") {
            for (const k in this.lightlist) {
                var v = this.lightlist[k];
                var tmpnamea = v.name.split("-");
                v.setPosition(this.mapcontainer.x + (tmpnamea[1] * 32), this.mapcontainer.y + (tmpnamea[2] * 32));
            }
        }
        //this.plog.log("updatenightlightposition()1",this);
    }
    async chkzmapitem() {
        //this.plog.log("chkzmapitem",this.zmapi);
        if (this.joycursor["up"].isDown || this.joycursor["down"].isDown || this.joycursor["left"].isDown || this.joycursor["right"].isDown) {
            return;
        }
        if (this.iswarping == true) return;
        //this.plog.log("chkzmapitem h", this.char_main,this.zmapi,this.zmapi.length);
        if (this.char_main != undefined && this.zmapi != undefined) {
            //var tmpcharpos = this.char_main.currentx + "-" + this.char_main.currenty;
            for (const k in this.zmapi) {
                var v = this.zmapi[k];
                if (v == undefined) continue;
                var vnamea = v.name.split("-");
                //this.plog.log("chkzmapitem",vnamea);
                if (vnamea[3] == this.char_main.currentx && vnamea[4] == this.char_main.currenty) {
                    //this.plog.log("zhkzmapitem stepped on", vnamea);
                    this.plog.log("pickupzmapitem", this.registry.list.rcvarpass.activechar.level, this.registry.list.rcvarpass);
                    if (this.registry.list.rcvarpass.activemap.map.level > this.registry.list.rcvarpass.activechar.level && this.registry.list.rcvarpass.activemap.map.ispublic == 'YES') {
                        if (v.texture.key != "mapgrow") {
                            if (v.texture.key == "mapflower") {
                                this.plog.log("FLOWER!!!", tmpchkzmapi);
                                //console.log(v);
                                v.setTexture("mapgrow", v.origmapgrowcode);
                                var res = await this.registry.list.rchttp.getcommon2("pickupnectar", { zmapid: vnamea[2] });
                                if (res == "ok") {
                                    this.registry.list.rchttp.updatecharinfo();
                                }
                                break;
                            }
                            this.registry.list.phm.charsaybubble(this.char_main, this.gl("I have to be level " + this.registry.list.rcvarpass.activemap.map.level + " to pick up item from this map"));
                            this.registry.list.phm.voicethis(this, "cantpickup-level");
                        }
                    } else {
                        this.registry.list.snd.snd("pickup");
                        // console.log("pickup", vnamea, vnamea[2]);
                        var tmpchkzmapi = this.zmapi["i" + vnamea[2]];
                        if (tmpchkzmapi != undefined) {
                            if (tmpchkzmapi.name != undefined && tmpchkzmapi.name.indexOf("zmap-mapgrow-") != -1 && tmpchkzmapi.alpha == 1) {
                                if (v.texture.key == "mapgrow") {
                                    this.plog.log("step on mapgrow", tmpchkzmapi);
                                    continue;
                                }
                                if (v.texture.key == "mapflower") {
                                    this.plog.log("FLOWER!!!", tmpchkzmapi);
                                    //console.log(v);
                                    v.setTexture("mapgrow", v.origmapgrowcode);
                                    var res = await this.registry.list.rchttp.getcommon2("pickupnectar", { zmapid: vnamea[2] });
                                    if (res == "ok") {
                                        this.registry.list.rchttp.updatecharinfo();

                                        /*this.tweens.add({
                                            targets: tmpchkzmapi,
                                            alpha: { from: 1, to: 0 },
                                            ease: "Linear",
                                            duration: 500,
                                            repeat: 0,
                                            yoyo: false,
                                        });*/
                                    }
                                    break;
                                }

                            }
                        }
                        var res = await this.registry.list.rchttp.getcommon2("pickupzmapitem", { zmapid: vnamea[2] });
                        //this.plog.log("chkzmapitem pickup res", res);
                        if (res == "ok") {
                            //this.registry.list.gameitf.osdflash(this, [
                            //  { txt: "Picked up item", col: "green" }
                            //]);
                            this.registry.list.rchttp.updatecharinfo();
                        }
                    }
                }
                //this.plog.log("chkzmapitem", v);
            }
            //this.plog.log("chkwarppos", tmpcharpos, tmpaction);
        }
    }
    trs(str) { //translate/get string using intl
        return this.registry.list.intl.ff(str);
    }
    gl(str) {
        //this.plog.log("gl(),", str, this.registry.list.rcvarpass.lang);
        if (str == undefined) return "";
        str = str.replace("::l::", "___l___")
        var stra = str.split("___l___");

        if (this.registry.list.rcvarpass.lang == undefined || this.registry.list.rcvarpass.lang == "") {
            return stra[0];
        }
        switch (this.registry.list.rcvarpass.lang) {
            case "EN":
                return stra[0];
                break;
            case "TH":
                if ((stra[1] == "" || stra[1] == undefined) && stra[0] != "") {
                    return stra[0];
                }
                return stra[1];
                break;

            default:
                return stra[0];
                break;
        }
        return str;
    }
    updatezmapcharfollower(wh) {
        for (const k in this.zmapcharfollower) {
            var v = this.zmapcharfollower[k];
            //this.plog.log("updatezmapcharfollower", k, v);
            //this.plog.log("updatezmapcharfollower zmapcharlist", this.zmapcharlist);
            if (v == undefined) continue;
            if (v == v["following"]) continue;
            if (v["following"] == undefined || v["followingx"] == undefined || v["followingy"] == undefined) continue;
            if (v["following"] == "char_main") {
                if (this.char_main != undefined) {
                    if (this.char_main.idlecount == undefined)
                        this.char_main.idlecount = 0;

                    v.setPosition(this.char_main.x + v["followingx"], this.char_main.y + v["followingy"]);

                    if (this.mapcontainer.list.includes(this.char_main) && this.mapcontainer.list.includes(v)) {
                        wh.mapcontainer.moveBelow(v, this.char_main);
                    }
                }
            } else {
                if (this.zmapcharlist[v["following"]] == undefined) {
                    try {
                        delete this.zmapcharfollower[k];
                    } catch (e) {
                        console.log(e);
                    }
                    try {
                        v.destroy();
                    } catch (e) {
                        console.log(e);
                    }
                    continue;
                }
                v.setPosition(this.zmapcharlist[v["following"]].x + v["followingx"], this.zmapcharlist[v["following"]].y + v["followingy"]);

                if (this.mapcontainer.list.includes(this.zmapcharlist[v["following"]]) && this.mapcontainer.list.includes(v)) {
                    wh.mapcontainer.moveBelow(v, this.zmapcharlist[v["following"]]);
                }
            }

        }
    }
    async playdrama(dramascenes) {
        for (var i = 0; i <= 20; i++) {
            dramascenes = dramascenes.replace("\r\n", "\n");
        }
        var width = this.sys.game.canvas.width;
        var height = this.sys.game.canvas.height;
        var x = this.char_main.currentx;
        var y = this.char_main.currenty;

        const cam = this.cameras.cameras[1];//this.cameras.main;
        this.plog.log("playdrama", this.cameras);
        this.plog.log("playdrama xy", x, y);
        this.plog.log("playdrama wh", width, height);

        var dmsca = dramascenes.split("\n");
        var scenetime = 3500;
        for (const k in dmsca) {
            var v = dmsca[k];
            var va = v.split(":");
            var usescenetime = scenetime;
            if (va[4] == "long") usescenetime = usescenetime * 2;
            if (va[4] == "short") usescenetime = usescenetime / 2;
            this.registry.list.gameitf.mapfadeout(this, Math.floor(usescenetime * 0.3));
            this.plog.log("playdrama() play", va);
            //focus char_main first
            ///this.mapcontainer.x = 0 - x * 32 + width / 2;
            //this.mapcontainer.y = 0 - y * 32 + height / 2;
            if (va[1] == "say") {
                this.plog.log("playdrama() play! say", va);
                var targettwx = 0;
                var targettwy = 0;
                if (va[2] == "char_main") { //focus on char_main
                    this.registry.list.phm.charsaybubble(this.char_main, va[3]);
                    this.camman_refollow(this);
                } else { //focus on an npc
                    this.plog.log("npclist", this.npclist);
                    var npcsprite = this.registry.list.phm.findnpccode(this, va[2]);
                    if (npcsprite != undefined) {
                        this.camman_follow(this, npcsprite);
                        this.registry.list.phm.charsaybubble(npcsprite, va[3]);
                    }
                }
                //continue;
            }
            if (va[1] == "pan") {
                this.plog.log("playdrama() play! pan", va);
                var targettwx = 0;
                var targettwy = 0;
                if (va[2] == "char_main") { //focus on char_main
                    var targettwx = 0 - x * 32 + width / 2;
                    var targettwy = 0 - y * 32 + height / 2;
                } else { //focus on an npc
                    this.plog.log("npclist", this.npclist);
                    var npcsprite = this.registry.list.phm.findnpccode(this, va[2]);
                    if (npcsprite != undefined) {
                        var targettwx = 0 - npcsprite.x + width / 2;
                        var targettwy = 0 - npcsprite.y + height / 2;
                    }
                }
                var twstartposx = targettwx;
                var twstartposy = targettwy;
                var twendposx = targettwx;
                var twendposy = targettwy;

                if (va[3] == "left") {
                    twstartposx = twstartposx + (width * 0.5)
                }
                if (va[3] == "right") {
                    twstartposx = twstartposx - (width * 0.5)
                }
                if (va[3] == "up") {
                    twstartposy = twstartposy - (width * 0.5)
                }
                if (va[3] == "down") {
                    twstartposy = twstartposy + (width * 0.5)
                }


                this.tweens.add({
                    targets: this.mapcontainer,
                    //angle: -20, // '+=100'
                    x: { from: twstartposx, to: twendposx },
                    y: { from: twstartposy, to: twendposy },
                    ease: "Linear", // 'Cubic', 'Elastic', 'Bounce', 'Back'
                    duration: usescenetime * 0.6,
                    repeat: 0,
                    yoyo: false,
                    onUpdate: function () {
                        this.updatenightlightposition();

                    }.bind(this)
                });
            }

            if (va[1] == "zoom") {
                this.plog.log("playdrama() play! zoom", va);
                var targettwx = 0;
                var targettwy = 0;
                if (va[2] == "char_main") { //focus on char_main
                    var targettwx = 0 - x * 32 + width / 2;
                    var targettwy = 0 - y * 32 + height / 2;
                } else { //focus on an npc
                    this.plog.log("npclist", this.npclist);
                    var npcsprite = this.registry.list.phm.findnpccode(this, va[2]);
                    if (npcsprite != undefined) {
                        var targettwx = 0 - npcsprite.x + width / 2;
                        var targettwy = 0 - npcsprite.y + height / 2;;
                    }
                }
                this.plog.log("playdrama txy", targettwx, targettwy);

                this.mapcontainer.x = targettwx;
                this.mapcontainer.y = targettwy;
                this.plog.log("playdrama() play! zoommapcontainer", this.mapcontainer.x, this.mapcontainer.y);

                //                    scaleX: { from: 1, to: 2.5 },
                //scaleY: { from: 1, to: 2.5 },
                cam.zoomTo(3, usescenetime * 0.6);
                //cam.pan(width / 2, height / 4, 2000, 'Power2');


            }

            //this.registry.list.gameitf.mapfadeout(this, usescenetime * 0.3);
            await this.registry.list.rctoast.sleep(usescenetime);

            //reset between scenes
            //cam.zoomTo(1, 0);

            //this.mapcontainer.x = 0 - x * 32 + width / 2;
            //this.mapcontainer.y = 0 - y * 32 + height / 2;
            // this.mapcontainer.setScale(1);
            // this.mapcontainer.scaleX = 1;
            // this.mapcontainer.scaleY = 1;

        }
        //reset position

        this.registry.list.gameitf.mapfadeout(this, scenetime * 0.3);
        await this.registry.list.rctoast.sleep(50);
        this.camman_refollow(this);
        //reset between scenes
        //cam.zoomTo(1, 0);

        /* this.mapcontainer.x = 0 - x * 32 + width / 2;
         this.mapcontainer.y = 0 - y * 32 + height / 2;
         this.mapcontainer.setScale(1);
         this.mapcontainer.scaleX = 1;
         this.mapcontainer.scaleY = 1;*/

        //this.plog.log("playdrama e", dmsca);
    }
    osdflash(wh, sprite, txt, col, extrapos = "") {
        var dsparr = [];
        var val = txt;
        var dsparrl = dsparr.length;
        var flashtime = 2000;
        var addx = 0;
        if (extrapos == "left") addx = -sprite.displayWidth * 2;
        if (col == "red") {
            dsparr[dsparrl] = wh.add.text(0, 0, txt, { fontFamily: 'ffff,uuuu', fontSize: '16px', stroke: '#fff', strokeThickness: 3, color: '#f00', fontStyle: 'bold' });
            dsparr[dsparrl].setPosition(sprite.x + addx, sprite.y);
            dsparr[dsparrl].setDepth(150000);
        }
        if (col == "black") {
            dsparr[dsparrl] = wh.add.text(0, 0, txt, { fontFamily: 'ffff,uuuu', fontSize: '18px', stroke: '#fff', strokeThickness: 3, color: '#000', fontStyle: 'bold' });
            dsparr[dsparrl].setPosition(sprite.x + addx, sprite.y);
            dsparr[dsparrl].setDepth(150000);
            flashtime = 5000;
        }
        if (val["col"] == "green") {
            dsparr[dsparrl] = wh.add.text(0, 0, val["txt"], { fontFamily: 'ffff,uuuu', fontSize: '16px', stroke: '#fff', strokeThickness: 3, color: '#0f0', fontStyle: 'bold' });
            dsparr[dsparrl].setPosition(sprite.x + addx, sprite.y);
            dsparr[dsparrl].setDepth(150000);
        }
        if (dsparr[dsparrl] != undefined) {
            wh.mapcami(wh, dsparr[dsparrl]);
            var tw = wh.tweens.add(
                {
                    targets: dsparr[dsparrl],
                    alpha: 0,
                    y: dsparr[dsparrl].y - 100,
                    ease: 'Power3',
                    duration: 2000, // duration of animation; higher=slower
                    delay: 0,      // wait 500 ms before starting
                    onComplete: function () {
                        //this.plog.log("osdflash tweencomplete", this);
                        if (this.target != undefined && this.target[0] != undefined) {
                            this.target[0].destroy();
                        }
                    }  // set context? how?
                }
            );
        }


    }
    async charhitbullet(wh, bull) {
        if (wh.charhitbullettimeout > 0) return;
        if (wh.activemapdead == true) return;
        if (wh.jumping == true) return;
        if (bull.visible == false) return;//dead mobx
        wh.charhitbullettimeout = 32;
        //console.log("charhitbullet",bull);
        this.plog.warn("charhitbullet", wh, bull, bull.atkpow);
        //console.log("charhitbullet", wh, bull, bull.atkpow);
        wh.iswarping = true;
        if (wh.char_main.guardicon != undefined) {
            wh.char_main.guardicon.x = wh.char_main.x;
            wh.char_main.guardicon.y = wh.char_main.y;
        }
        var spawnpos = wh.registry.list.phm.getspawnpos();
        var spawnposa = spawnpos.split('-');
        wh.walkpathcmd = [];
        //wh.registry.list.phm.movemaincharto(spawnposa[1], spawnposa[0]);
        wh.char_main.setAlpha(1);
        wh.registry.list.snd.snd("atkhit");
        var zcharastar = new AStarFinder({
            grid: {
                matrix: wh.registry.list.rcvarpass.activemapmetrix,
            },
            includeStartNode: false,
            includeEndNode: true,
            diagonalAllowed: false,
        });

        let startPos = {
            x: wh.char_main.currentx,
            y: wh.char_main.currenty,
        };
        let goalPos = { x: spawnposa[0], y: spawnposa[1] };

        if (bull != undefined && bull.bulletdirection != undefined) {
            if (bull.bulletdirection == "u") {
                goalPos = { x: wh.char_main.currentx, y: wh.char_main.currenty + 1 };
            }
            if (bull.bulletdirection == "d") {
                goalPos = { x: wh.char_main.currentx, y: wh.char_main.currenty - 1 };
            }
            if (bull.bulletdirection == "l") {
                goalPos = { x: wh.char_main.currentx - 1, y: wh.char_main.currenty };
            }
            if (bull.bulletdirection == "r") {
                goalPos = { x: wh.char_main.currentx + 1, y: wh.char_main.currenty };
            }
        }
        //console.log(bull,bull.bulletdirection,startPos, goalPos);

        //this.plog.log("floorastar1", this.floorastar);
        //this.plog.log('zmapchar move 1', startPos, goalPos);
        //this.plog.log('charhitbullet move', startPos, goalPos, zcharastar);
        let myPathway = zcharastar.findPath(startPos, goalPos);
        //console.log("charhitbullet move myPathway", myPathway);
        if (bull.atkpow != undefined) {
            //if (bull.cbt==undefined) bull.cbt=bull.o.data.cbt;// mob contact
            if (bull.cbt == undefined) {
                bull.cbt = {
                    crit: Number(bull.atkpow),
                    element: "",
                    acc: Number(bull.atkpow)
                }
            }
            var useatkpow = Number(bull.atkpow) + 0;
            var atkdescr = "";
            var atkpowcrit = Number(bull.cbt["crit"]) + 0;
            if (bull.cbt["element"] != undefined) {
                if (bull.cbt["element"] == "fire" && (wh.registry.list.rcvarpass.activechar.cbt["element"] == "earth" || wh.registry.list.rcvarpass.activechar.cbt["element"] == "")) {
                    useatkpow = useatkpow * 1.15;
                    atkpowcrit = atkpowcrit * 1.15;
                    atkdescr = "fire";
                }
                if (bull.cbt["element"] == "earth" && (wh.registry.list.rcvarpass.activechar.cbt["element"] == "water" || wh.registry.list.rcvarpass.activechar.cbt["element"] == "")) {
                    useatkpow = useatkpow * 1.15;
                    atkpowcrit = atkpowcrit * 1.15;
                    atkdescr = "earth";
                }
                if (bull.cbt["element"] == "water" && (wh.registry.list.rcvarpass.activechar.cbt["element"] == "wind" || wh.registry.list.rcvarpass.activechar.cbt["element"] == "")) {
                    useatkpow = useatkpow * 1.15;
                    atkpowcrit = atkpowcrit * 1.15;
                    atkdescr = "water";
                }
                if (bull.cbt["element"] == "wind" && (wh.registry.list.rcvarpass.activechar.cbt["element"] == "fire" || wh.registry.list.rcvarpass.activechar.cbt["element"] == "")) {
                    useatkpow = useatkpow * 1.15;
                    atkpowcrit = atkpowcrit * 1.15;
                    atkdescr = "wind";
                }
                if (bull.cbt["element"] == "electric" && (wh.registry.list.rcvarpass.activechar.cbt["element"] != "electric" || wh.registry.list.rcvarpass.activechar.cbt["element"] == "")) {
                    useatkpow = useatkpow * 1.2;
                    atkdescr = "electric";
                }
                if (bull.cbt["element"] == "soul" && wh.registry.list.rcvarpass.activechar.cbt["element"] != "soul") {
                    useatkpow = useatkpow * 1000;
                    atkdescr = "soul";
                }
            } else {
                if (wh.registry.list.rcvarpass.activechar.cbt["element"] != "") {
                    useatkpow = useatkpow * 0.9;
                }
            }
            useatkpow = Math.floor(useatkpow);
            var dodgechance = wh.registry.list.rcvarpass.activechar.cbt["dodge"];
            var dodged = this.localrandp([
                { k: "atk", n: Number(bull.cbt["acc"]) },
                { k: "dodge", n: Math.floor(wh.registry.list.rcvarpass.activechar.cbt["dodge"] / 50) }
            ]);
            if (dodged == "dodge") {
                this.plog.log("charhitbullet dodge", dodged, [
                    { k: "atk", n: Number(bull.cbt["acc"]) },
                    { k: "dodge", n: wh.registry.list.rcvarpass.activechar.cbt["dodge"] }
                ]);
                wh.registry.list.gameitf.osdflash(
                    wh, [{ txt: "Dodge!", col: "green", }]
                );
                wh.iswarping = false;
                return;
            }

            var critchance = this.localrandp([
                { k: "crit", n: bull.cbt["acc"] },
                { k: "normal", n: wh.registry.list.rcvarpass.activechar.cbt["dodge"] }
            ]);
            if (critchance == "crit") {
                atkpowcrit = atkpowcrit - wh.registry.list.rcvarpass.activechar.cbt["def"];

                if (atkpowcrit < 0) {
                    atkpowcrit = Math.floor(Math.random() * (3 - 1)) + 1;;//min1-3
                }
                atkpowcrit = Math.floor(atkpowcrit);
                useatkpow = atkpowcrit;
                useatkpow = Math.floor(useatkpow);
                wh.registry.list.gameitf.osdflash(
                    wh, [{ txt: "Crit! -" + Math.floor(Number(atkpowcrit)) + atkdescr, col: "red", }]
                );
            } else {
                if (wh.guarding == true) {
                    //damage no push
                    myPathway = [];
                }
                //console.log("useatkpow",useatkpow,wh.registry.list.rcvarpass.activechar.cbt["def"],wh.registry.list.rcvarpass.activechar.cbt);
                useatkpow = useatkpow - Number(wh.registry.list.rcvarpass.activechar.cbt["def"]);
                //console.log(useatkpow);
                useatkpow = Math.floor(useatkpow);
                //console.log(useatkpow);
                if (useatkpow < 0) {
                    useatkpow = Math.floor(Math.random() * (3 - 1)) + 1;;//min1-3
                }

                wh.registry.list.gameitf.osdflash(
                    wh, [{ txt: "-" + Math.floor(Number(useatkpow)) + atkdescr, col: "red", }]
                );

            }
            if (useatkpow < 0) {
                useatkpow = Math.floor(Math.random() * (3 - 1)) + 1;;//min1-3
            }



            var hpmax = wh.registry.list.rcvarpass.activechar['cbt']['maxhp'];
            var curhp = wh.registry.list.rcvarpass.activechar['cbt']['hp'];
            var newhp = Number(curhp - useatkpow);
            if (newhp < 0) {
                newhp = 0;
            }
            wh.registry.list.rcvarpass.activechar['cbt']['hp'] = newhp;
            wh.charhpbar.set1(newhp);
            var isded = false;//wh.charhpbar.decrease(useatkpow);
            if (newhp == 0) isded = true;
            //wh.charhpbar.drawText();
            if (wh.charhptxt != undefined) {
                //if (wh.registry.list.rcvarpass.activemap['map']["activemap"] != "YES") {
                wh.charhptxt.setText('HP ' + newhp + ' / ' + hpmax);
                //}
                wh.charhpbar.draw();
            }

            if (isded == true) {
                this.plog.log("dead. by atkpow ", useatkpow, wh.charhpbar);
                wh.char_main.play({ key: wh.registry.list.rcvarpass.activechar.sprite + '-dead', repeat: 0 });
                wh.activemapdead = true;
                wh.guarding = false;
                await this.registry.list.rctoast.sleep(1500);
                await wh.registry.list.rctoast.rcded(wh, " U ded");
                var spawnpos = wh.registry.list.phm.getspawnpos();
                var spawnposa = spawnpos.split('-');
                wh.registry.list.phm.movemaincharto(spawnposa[1], spawnposa[0]);
                wh.registry.list.phm.movemaincharto(spawnposa[1], spawnposa[0]);
                wh.char_main.play({ key: wh.registry.list.rcvarpass.activechar.sprite + '-idle', repeat: 0, frameRate: 16 });

                wh.jumping = false;
                wh.activemapdead = false;
                wh.iswarping = false;
                wh.charhpbar.setmax();
                return;
            }

        }
        if (myPathway.length > 0) {
            var mypathuse = myPathway[0];
            wh.char_main.currentx = mypathuse[0];
            wh.char_main.currenty = mypathuse[1];

            var targetx = ((mypathuse[0]) - 1) * 32;
            var targety = ((mypathuse[1]) - 1) * 32;
            this.plog.warn("charhitbullet move mypathuse", mypathuse, wh.char_main.x, wh.char_main.y, targetx, targety);
            var timeline = wh.add.timeline([
                {
                    at: 0,
                    loop: 0,
                    tween:
                    {
                        targets: wh.char_main,
                        x: { from: wh.char_main.x, to: targetx },
                        y: { from: wh.char_main.y, to: targety },
                        ease: 'Sine.easeInOut',
                        duration: 300,
                        yoyo: false,
                        onComplete: function () {
                            if (arguments[1][0].scene.char_main.guardicon != undefined) {
                                arguments[1][0].scene.char_main.guardicon.x = arguments[1][0].scene.char_main.x;
                                arguments[1][0].scene.char_main.guardicon.y = arguments[1][0].scene.char_main.y;
                            }
                        }.bind(this)
                    }
                }, {
                    at: 301,
                    loop: 0,
                    tween:
                    {
                        targets: wh.char_main,
                        alpha: { from: 0, to: 1 },
                        ease: 'Bounce',
                        duration: 100,
                        offset: 0,
                        loop: 3,
                        onComplete: function () {
                            this.plog.log("charhitbullet timelinecomplete ", arguments);
                            //arguments[0].data[0].targets[0].scene.iswarping = false;
                            arguments[1][0].scene.iswarping = false;
                            if (arguments[1][0].scene.char_main.guardicon != undefined) {
                                arguments[1][0].scene.char_main.guardicon.x = arguments[1][0].scene.char_main.x;
                                arguments[1][0].scene.char_main.guardicon.y = arguments[1][0].scene.char_main.y;
                            }
                        }.bind(this)
                    }
                }
            ]
            );
            /*
                timeline.on('complete', function () {
                    this.plog.log("charhitbullet timelinecomplete ", arguments);
                    //arguments[0].data[0].targets[0].scene.iswarping = false;
                    arguments[1][0].scene.iswarping = false;
                }.bind(this));*/
            timeline.play();
            //wh.iswarping = false;

        } else {
            //cant go where bullet push

            var timeline = wh.add.timeline([
                {
                    at: 0,
                    loop: 0,
                    tween:
                    {
                        targets: wh.char_main,
                        alpha: { from: 0, to: 1 },
                        ease: 'Bounce',
                        duration: 100,
                        offset: 0,
                        loop: 3,
                        onComplete: function () {
                            this.plog.log("charhitbullet timelinecomplete ", arguments);
                            //arguments[0].data[0].targets[0].scene.iswarping = false;
                            arguments[1][0].scene.iswarping = false;
                        }.bind(this)
                    }
                }
            ]
            );
            timeline.play();
        }
    }
    charatk_genbullet(wh, bullanim, size, atkarea) {

        //this.plog.log("genbullet_sprite", this.o.data.cbt);
        //var tmpbullet = this.wh.add.sprite(100, 100, bullanim["sprite"]);
        var tmpbullet = wh.physics.add.sprite(100, 100, bullanim["sprite"]);
        tmpbullet.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        //tmpbullet.play(bullanim["code"]);
        tmpbullet.play({
            key: bullanim["code"],
            frameRate: 32,
        });

        tmpbullet.setOrigin(0.5, 0.5);
        wh.mapcontainer.add(tmpbullet);
        wh.mapcontainer.bringToTop(tmpbullet);
        tmpbullet.x = wh.char_main.x;//- (this.sprite.displayWidth / 2);
        tmpbullet.y = wh.char_main.y;//- (this.sprite.displayHeight / 2);
        if (size == undefined) size = 1;
        tmpbullet.displayWidth = size * 32;
        tmpbullet.scaleY = tmpbullet.scaleX;
        //tmpbullet.body.setSize(size * 32, size * 32);

        //tmpbullet.body.setOffset(16, 16);
        //tmpbullet.body.scaleX = tmpbullet.scaleX
        //tmpbullet.body.scaleY = tmpbullet.scaleY
        tmpbullet.atkpow = Number(wh.registry.list.rcvarpass.activechar.cbt.patk) + 1;//
        //this.wh.mapcontainer.sendBelow
        wh.mapcontainer.moveBelow(tmpbullet, wh.char_main);
        //this.plog.log("genbullet_sprite end", tmpbullet);
        return tmpbullet;
    }

    localrandp(arr) {
        var decisarr = {};
        var maxposs = 0;

        for (var k in arr) {
            var v = arr[k];
            //this.plog.log("randp", k, v);
            decisarr[v.k] = [];
            decisarr[v.k]['min'] = maxposs;
            maxposs = maxposs + Number(v.n);
            decisarr[v.k]['max'] = maxposs;
        }
        var decis = Number(Math.random() * (maxposs - 0) + 0);
        //this.plog.log("randp maxposs", maxposs);
        //this.plog.log("randp decis", decis);
        for (var k in decisarr) {
            var v = decisarr[k];
            //this.plog.log("randp scanning", k, v,"with",decis);
            if (decis >= v['min'] && decis <= v['max']) {
                //this.plog.log("yyyy randp found this",  k,v,decis);
                return k;
            }
        }
    }
    async mapmobxhitbullet(wh, a1, a2) {
        //console.log("mapmobxhitbullet()", a1, a2);
        this.plog.log("mapmobxhitbullet() get", a2.mapmonobj);
        if (a2.visible == false) return; //deadmapmob
        if (a2.mapmonobj.underattack == true) return;
        a2.mapmonobj.underattack = true;
        var useatkpow = a1.atkpow + 0;
        var atkdescr = "";
        var atkpowcrit = wh.registry.list.rcvarpass.activechar.cbt["crit"] + 0;
        if (wh.registry.list.rcvarpass.activechar.cbt["element"] != undefined) {
            if (wh.registry.list.rcvarpass.activechar.cbt["element"] == "fire" && (a2.mapmonobj.o.data.cbt["element"] == "earth" || a2.mapmonobj.o.data.cbt["element"] == "")) {
                useatkpow = useatkpow * 1.15;
                atkpowcrit = atkpowcrit * 1.15;
                atkdescr = "fire";
            }
            if (wh.registry.list.rcvarpass.activechar.cbt["element"] == "earth" && (a2.mapmonobj.o.data.cbt["element"] == "water" || a2.mapmonobj.o.data.cbt["element"] == "")) {
                useatkpow = useatkpow * 1.15;
                atkpowcrit = atkpowcrit * 1.15;
                atkdescr = "earth";
            }
            if (wh.registry.list.rcvarpass.activechar.cbt["element"] == "water" && (a2.mapmonobj.o.data.cbt["element"] == "wind" || a2.mapmonobj.o.data.cbt["element"] == "")) {
                useatkpow = useatkpow * 1.15;
                atkpowcrit = atkpowcrit * 1.15;
                atkdescr = "water";
            }
            if (wh.registry.list.rcvarpass.activechar.cbt["element"] == "wind" && (a2.mapmonobj.o.data.cbt["element"] == "fire" || a2.mapmonobj.o.data.cbt["element"] == "")) {
                useatkpow = useatkpow * 1.15;
                atkpowcrit = atkpowcrit * 1.15;
                atkdescr = "wind";
            }
            if (wh.registry.list.rcvarpass.activechar.cbt["element"] == "electric" && (a2.mapmonobj.o.data.cbt["element"] != "electric" || a2.mapmonobj.o.data.cbt["element"] == "")) {
                useatkpow = useatkpow * 1.2;
                atkdescr = "electric";
            }
            if (wh.registry.list.rcvarpass.activechar.cbt["element"] == "soul" && a2.mapmonobj.o.data.cbt["element"] != "soul") {
                useatkpow = useatkpow * 1000;
                atkdescr = "soul";
            }
        }
        useatkpow = Math.floor(useatkpow);
        var dodgechance = Number(a2.mapmonobj.o.data.cbt["dodge"]);
        var critchance = this.localrandp([
            { k: "crit", n: wh.registry.list.rcvarpass.activechar.cbt["acc"] },
            { k: "normal", n: dodgechance }
        ]);
        this.plog.log("mapmobxhit crit", critchance, [
            { k: "crit", n: wh.registry.list.rcvarpass.activechar.cbt["acc"] },
            { k: "normal", n: dodgechance }
        ]);
        if (critchance == "crit") {
            atkpowcrit = atkpowcrit - a2.mapmonobj.o.data.cbt["def"];

            if (atkpowcrit < 0) {
                atkpowcrit = Math.floor(Math.random() * (3 - 1)) + 1;;//min1-3
            }
            atkpowcrit = Math.floor(atkpowcrit);
            wh.registry.list.gameitf.osdflash(
                wh,
                [{
                    txt: "Crit! " + Math.floor(Number(atkpowcrit)) + atkdescr,
                    col: 'red',
                }]
            );
        } else {
            useatkpow = useatkpow - a2.mapmonobj.o.data.cbt["def"];
            if (atkpowcrit < 0) {
                atkpowcrit = Math.floor(Math.random() * (3 - 1)) + 1;;//min1-3
            }
            wh.registry.list.gameitf.osdflash(
                wh,
                [{
                    txt: Math.floor(Number(useatkpow)) + atkdescr,
                    col: 'red',
                }]
            );
        }
        if (useatkpow < 0) {
            //console.log("atkpow under0", useatkpow);
            useatkpow = Math.floor(Math.random() * (3 - 1)) + 1;
        }
        var hpleft = a2.mapmonobj.o.data.cbt.hp - useatkpow;

        wh.registry.list.snd.snd("atkhit");

        if (hpleft < 0) hpleft = 0;
        a2.mapmonobj.o.data.cbt.hp = hpleft;
        a2.mapmonobj.pgbar_health.set1(hpleft);
        a2.mapmonobj.pgbar_health.draw();
        a2.mapmonobj.pgbar_health.drawText();
        //console.log(a2.mapmonobj);
        //console.log(a2.mapmonobj.pgbar_health.o);
        if (hpleft == 0) {
            //console.log(a1, a2);
            var mobcodeslain = "";
            if (a2.mapmonobj != undefined &&
                a2.mapmonobj.mobdata != undefined &&
                a2.mapmonobj.mobdata.code != undefined &&
                a2.mapmonobj.onhide == 'no'
            ) {
                a2.mapmonobj.onhide = "yes";
                mobcodeslain = a2.mapmonobj.mobdata.code
                //var mobxdeadres = await wh.registry.list.rchttp.getcommon2("activemapkill", { mobid: mobcodeslain });
                var mobxdeadres = wh.registry.list.rchttp.getcommon2("activemapkill", { mobid: mobcodeslain });
                //this.registry.list.rchttp.updatecharinfo();
            }
            a2.setTintFill(0xffffff);

            var timeline = wh.add.timeline([{
                at: 0,
                tween:
                {
                    targets: a2,
                    alpha: { from: 0, to: 1 },
                    ease: 'Bounce',
                    duration: 70,
                    offset: 0,
                    loop: 3,
                    onComplete: function () {
                        //console.log("mapmobxhitbullet timelinecomplete ", arguments);
                        a2.mapmonobj.hide(180);
                        a2.mapmonobj.sprite.clearTint();
                        a2.mapmonobj.underattack = false;
                    }.bind(this)
                }

            }]);
            //timeline.on('complete',);
            timeline.play();
        } else {
            var timeline = wh.add.timeline([{
                loop: 1,
                tween:
                {
                    targets: a2,
                    alpha: { from: 0, to: 1 },
                    ease: 'Bounce',
                    duration: 70,
                    offset: 0,
                    loop: 2,
                    onComplete: function () {
                        a2.mapmonobj.underattack = false;
                    }.bind(this)
                }

            }]);
            timeline.on('complete', function () {
                this.plog.log("mapmobxhitbullet timelinecomplete ", arguments);
                a2.mapmonobj.sprite.clearTint();
            }.bind(this));
            timeline.play();
        }
        if (hpleft == 0) {
            //a2.mapmonobj.hide(120);
        }
        if (a1.keepbullet != true)
            a1.destroy();
    }
    async spawnbomb(binf, spawner) {
        this.plog.log("spawnbomb", binf);
        var bullanim = this.registry.list.rcvarpass.allanims[binf['ani']];
        var tmpbullet = this.physics.add.sprite(100, 100, bullanim["sprite"]);
        //tmpbullet.atkpow = Number(binf['atkpow'])+0;//

        this.physics.add.collider(tmpbullet, this.char_main, function (a1, a2) {
            // this.plog.log("collide",binf);
            a1.atkpow = binf['atkpow'];
            a1.scene.charhitbullet(a1.scene, a1);
        }.bind(this));
        tmpbullet.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        tmpbullet.play({ key: bullanim["code"], repeat: 0, frameRate: 16 });
        tmpbullet.on('animationcomplete', function () {
            //this.plog.log("atkanicomplete",arguments);
            if (arguments[2] == undefined) return;
            arguments[2].destroy();
        });
        tmpbullet.setOrigin(0.5, 0.5);
        this.mapcontainer.add(tmpbullet);
        this.mapcontainer.bringToTop(tmpbullet);
        tmpbullet.x = spawner.x;//- (this.sprite.displayWidth / 2);
        tmpbullet.y = spawner.y;//- (this.sprite.displayHeight / 2);
        var size = Number(binf["range"]);
        if (size == undefined) size = 1;
        tmpbullet.displayWidth = size * 32;
        tmpbullet.scaleY = tmpbullet.scaleX;
        tmpbullet.body.setSize(size * 32, size * 32);
        this.mapcontainer.bringToTop(tmpbullet);
    }
    charatk_timeout = 0;
    charatk(wh) {
        this.plog.log("charatk", wh);
        if (this.charatk_timeout > 0) return;
        this.charatk_timeout = 20;

        this.plog.log("charatk activechar", wh.registry.list.rcvarpass.activechar);
        this.plog.log("charatk play", wh.registry.list.rcvarpass.activechar.sprite + '-' + wh.registry.list.rcvarpass.activechar.cbt.attackani + '-' + Array.from(wh.char_main_lastspriteface)[0]);

        if (wh.activemapdead == true) return;
        wh.char_main.play({ key: wh.registry.list.rcvarpass.activechar.sprite + '-' + wh.registry.list.rcvarpass.activechar.cbt.attackani + '-' + Array.from(wh.char_main_lastspriteface)[0], repeat: 0, frameRate: 16 });
        wh.char_main.on('animationcomplete', function () {
            //this.plog.log("atkanicomplete",arguments);
            if (arguments[2].scene.activemapdead == true) return;
            arguments[2].play(arguments[1].textureKey + '-idle-' + Array.from(arguments[2].scene.char_main_lastspriteface)[0])
        });

        var bullanimk = "eldenring_b"; //  slashcircle1_b
        var bullanim = wh.registry.list.rcvarpass.allanims[bullanimk];

        var findeqtype1 = "";
        for (const findeqi in wh.registry.list.rcvarpass.activechar.equip) {
            if (wh.registry.list.rcvarpass.activechar.equip[findeqi].equippos == "weapon1") {
                findeqtype1 = wh.registry.list.rcvarpass.activechar.equip[findeqi].item;
            }
        }
        var weapontype = ""
        if (findeqtype1 != "") {
            weapontype = wh.registry.list.rcvarpass.allitem[findeqtype1].typecode
        }

        var size = 0.9;
        var atkarea = "normal";
        if (weapontype == "longsword" || weapontype == "longaxe") {
            size = 2.8;
            atkarea = "long";
        }
        var range = wh.registry.list.rcvarpass.activechar.cbt.combatrange + 0;
        var spd = 100 * range;
        var tmpbullet = this.charatk_genbullet(wh, bullanim, size, atkarea);
        //tmpbullet.setBodySize(size*32,size*32);
        tmpbullet.body.setSize(size * 32, size * 32);
        tmpbullet.body.setSize(bullanim.displayWidth, bullanim.displayHeight);
        ;
        //tmpbullet.body.width=tmpbullet.displayWidth;
        //tmpbullet.body.height=tmpbullet.displayHeight;
        this.plog.log("debugbullet", tmpbullet, size, tmpbullet.body);
        tmpbullet.atkpow = wh.registry.list.rcvarpass.activechar.cbt.patk + 1;
        for (const partyk in wh.mapmob) {
            //this.plog.log("charatk collider",wh.mapmob[partyk],tmpbullet);
            if (wh.mapmob[partyk] != undefined && wh.mapmob[partyk].sprite != undefined) {
                //wh.mapmob[partyk].mapmobk=partyk;
                wh.physics.add.collider(tmpbullet, wh.mapmob[partyk].sprite, function (a1, a2) {
                    a1.scene.mapmobxhitbullet(a1.scene, a1, a2);
                }.bind(this));
            }
        }
        switch (wh.char_main_lastspriteface) {

            case "left":
                var bulltgx = tmpbullet.x; var bulltgy = tmpbullet.y;
                bulltgx = bulltgx - (32 * range);
                var tw = wh.tweens.add({
                    targets: tmpbullet,
                    x: bulltgx, y: bulltgy,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        if (this.targets[0] != undefined)
                            this.targets[0].destroy();
                    }
                }, this);
                break;
            case "right":
                var bulltgx = tmpbullet.x; var bulltgy = tmpbullet.y;
                bulltgx = bulltgx + (32 * range);
                var tw = wh.tweens.add({
                    targets: tmpbullet,
                    x: bulltgx, y: bulltgy,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        if (this.targets[0] != undefined)
                            this.targets[0].destroy();
                    }
                }, this);
                break;
            case "up":
                var bulltgx = tmpbullet.x; var bulltgy = tmpbullet.y;
                bulltgy = bulltgy - (32 * range);
                var tw = wh.tweens.add({
                    targets: tmpbullet,
                    x: bulltgx, y: bulltgy,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        if (this.targets[0] != undefined)
                            this.targets[0].destroy();
                    }
                }, this);
                break;
            case "down":
                var bulltgx = tmpbullet.x; var bulltgy = tmpbullet.y;
                bulltgy = bulltgy + (32 * range);
                var tw = wh.tweens.add({
                    targets: tmpbullet,
                    x: bulltgx, y: bulltgy,
                    ease: 'Linear', duration: spd,
                    onComplete: function () {
                        if (this.targets[0] != undefined)
                            this.targets[0].destroy();
                    }
                }, this);
                break;

            default:
                this.plog.log("charatk, error lastspriteface ", wh.char_main_lastspriteface)
                break;
        }
    }

    charguard(wh) {
        if (wh.jumping == true) return;
        if (wh.activemapdead == true) return;
        wh.guarding = true;
        wh.char_main.scaleY = wh.char_main.scaleY * 0.9;
        if (wh.char_main.guardicon == undefined) {
            wh.char_main.guardicon = wh.registry.list.rcvarpass.gameobj.scene.scenes[0].add.sprite(
                100,
                100,
                'charguard'
            );

            wh.mapcontainer.add(wh.char_main.guardicon);
            wh.char_main.guardicon.play({ key: "charguard_b", repeat: -1 });
            wh.char_main.guardicon.displayWidth = 74;
            wh.char_main.guardicon.scaleY = wh.char_main.guardicon.scaleX;
        }
        wh.char_main.guardicon.x = wh.char_main.x;
        wh.char_main.guardicon.y = wh.char_main.y;
        wh.char_main.guardicon.visible = true;
        //console.log('createpanel icon', icon);
        this.plog.log("charguard", wh);
    }
    charguard_e(wh) {
        this.plog.log("charguard_e", wh);
        wh.char_main.scaleY = wh.char_main.scaleX;
        wh.guarding = false;
        if (wh.char_main.guardicon != undefined) {
            wh.char_main.guardicon.visible = false;
        }
    }
    charjump(wh) {
        this.plog.log("charjump", wh);
        if (wh.jumping == true) return;
        if (wh.guarding == true) return;
        if (wh.activemapdead == true) return;

        var topy = wh.char_main.y - (32 * 2.5);
        wh.iswarping = true;
        wh.jumping = true;
        var curos = (wh.char_main.currentx) + "-" + (wh.char_main.currenty);
        var chkpos = "";
        var chkposb = "";
        switch (wh.char_main_lastspriteface) {
            case "left":
                chkpos = (wh.char_main.currentx - 2) + "-" + (wh.char_main.currenty);
                chkposb = (wh.char_main.currentx - 1) + "-" + (wh.char_main.currenty);
                break;
            case "right":
                chkpos = (wh.char_main.currentx + 2) + "-" + (wh.char_main.currenty);
                chkposb = (wh.char_main.currentx + 1) + "-" + (wh.char_main.currenty);
                break;
            case "up":
                chkpos = (wh.char_main.currentx) + "-" + (wh.char_main.currenty - 2);
                chkposb = (wh.char_main.currentx) + "-" + (wh.char_main.currenty - 1);
                break;
            case "down":
                chkpos = (wh.char_main.currentx) + "-" + (wh.char_main.currenty + 2);
                chkposb = (wh.char_main.currentx) + "-" + (wh.char_main.currenty + 1);
                break;
            default:
                this.plog.log("charjump no direction");
                break;
        }
        var tmpposchk = "";
        var tmpposchkb = "";
        tmpposchk = wh.registry.list.rcvarpass.activemap["walkable"][chkpos];
        tmpposchkb = wh.registry.list.rcvarpass.activemap["walkable"][chkposb];
        var newx = wh.char_main.x;
        var newy = wh.char_main.y;
        //this.plog.log("tmpposchk",tmpposchk);
        if (tmpposchk == "yn_y") {
            var chkposarr = chkpos.split("-");
            newx = Number(parseInt(chkposarr[0]) - 1) * 32;
            newy = Number(parseInt(chkposarr[1])) * 32;
            wh.char_main.currentx = Number(chkposarr[0]);
            wh.char_main.currenty = Number(chkposarr[1]);
        }
        var midx = wh.char_main.x;
        var midy = wh.char_main.y;
        if (wh.char_main.x > newx) {
            midx = Number(wh.char_main.x) - (Number(Math.max(Number(wh.char_main.x), newx) - Math.min(Number(wh.char_main.x), newx)) / 2);//* 32;
        } else {
            midx = Number(wh.char_main.x) + (Number(Math.max(Number(wh.char_main.x), newx) - Math.min(Number(wh.char_main.x), newx)) / 2);//* 32;
        }
        midy = Number(wh.char_main.y) - (Number(Math.max(Number(wh.char_main.y), newy) - Math.min(Number(wh.char_main.y), newy)) / 2) - (32 * 2.5);//xxx
        this.plog.log("charjump chk x", wh.char_main.x, newx);
        this.plog.log("charjump timeline pos", midx, midy, newx, newy);
        var timeline = wh.add.timeline([{
            at: 0,
            loop: 0,
            tween:
            {
                targets: wh.char_main,
                x: { from: wh.char_main.x, to: midx },
                y: { from: wh.char_main.y, to: midy },
                ease: 'Linear',//Sine.easeOut
                duration: 150,
                yoyo: false
            }
        }, {
            at: 151,
            loop: 0,
            tween:
            {
                targets: wh.char_main,
                x: { from: midx, to: newx },
                y: { from: midy, to: newy },
                ease: 'Linear',
                duration: 100,
                delay: 0,
                loop: 0,
                onComplete: function () {
                    this.plog.warn("charjump timelinecomplete ", arguments);
                    //arguments[0].data[0].targets[0].scene.iswarping = false;
                    //arguments[0].data[0].targets[0].scene.jumping = false;
                    arguments[1][0].scene.iswarping = false;
                    arguments[1][0].scene.jumping = false;
                }.bind(this)
            }
        }]);
        timeline.play();
    }
    chardash_getDirection(coord1, coord2) {
        const x1 = coord1.x;
        const y1 = coord1.y;
        const x2 = coord2.x;
        const y2 = coord2.y;

        if (x2 > x1 && Math.abs(x2 - x1) > Math.abs(y2 - y1)) {
            return "right";
        } else if (x2 < x1 && Math.abs(x2 - x1) > Math.abs(y2 - y1)) {
            return "left";
        } else if (y2 < y1) {
            return "up";
        } else if (y2 > y1) {
            return "down";
        } else {
            return "same position";
        }
    }
    chardasheff;
    chardashbuffer = {};//keep 1 next dash queue
    atmpower_1 = 0;
    atmpower_1_sprite;
    atmpower_1_path;
    atmpower_1_tween;
    atmpower_1_angle = 0.2;
    atmpower_1_timer;
    atmpower_1_dist = 70;
    atmpower_2 = 0;
    atmpower_2_sprite;
    atmpower_1_timerfunc() {
        if (this.atmpower_1_sprite != undefined && this.char_main != undefined) {
            Phaser.Math.RotateAroundDistance(this.atmpower_1_sprite, this.char_main.x, this.char_main.y, this.atmpower_1_angle, this.atmpower_1_dist);
        }

        //this.atmpower_1_angle = Phaser.Math.Angle.Wrap(this.atmpower_1_angle + 0.02);
        //console.log(this.atmpower_1_angle);
    }
    atm_atmpower_reset() {
        this.atmpower_1 = 0;
        this.atmpower_2 = 0;
        this.atm_atmpower();

    }
    atm_atmpower() {
        if (this.atmpower_1_sprite != undefined) {
            try {
                this.atmpower_1_sprite.destroy();
                this.atmpower_1_sprite = undefined;
            } catch (e) {
                console.log(e);
            }
        }
        if (this.atmpower_2_sprite != undefined) {
            try {
                this.atmpower_2_sprite.destroy();
                this.atmpower_2_sprite = undefined;
            } catch (e) {
                console.log(e);
            }
        }
        if (this.atmpower_1 == 1) {
            console.log("atmpower_1 active");
            try {
                this.atmpower_1_timer.remove(false);
                this.atmpower_1_timer = undefined;
            } catch (e) {
                console.log(e);
            }

            if (this.atmpower_1_sprite == undefined) {

                this.atmpower_1_sprite = this.physics.add.sprite(0, 0, "dasheff2").play({ key: "dasheff2_b", repeatDelay: 0, frameRate: 24, repeat: -1 }, false);
                this.atmpower_1_sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
                this.atmpower_1_sprite.displayWidth = this.atmpower_1_sprite.displayHeight = 80;
                this.atmpower_1_sprite.setBodySize(20, 20, true);

                this.mapcontainer.add(this.atmpower_1_sprite);
                this.mapcontainer.bringToTop(this.atmpower_1_sprite);


                this.atmpower_1_angle = Phaser.Math.Angle.Wrap(this.atmpower_1_angle + 0.02);

                this.atmpower_1_timer = this.time.addEvent(
                    {
                        delay: 30,// * this.timespeed,
                        callback: this.atmpower_1_timerfunc,
                        args: [],
                        callbackScope: this,
                        loop: true,
                        repeat: 0,
                        startAt: 0,
                        timeScale: 1,
                        paused: false,
                    }
                );


                for (const partyk in this.mapmob) {
                    //console.log("charatk collider",wh.mapmob[partyk],this.chardasheff);
                    if (this.mapmob[partyk] != undefined && this.mapmob[partyk].sprite != undefined) {
                        //console.log("charatk collider",wh.mapmob[partyk].sprite,this.chardasheff);
                        //wh.mapmob[partyk].mapmobk=partyk;
                        this.atmpower_1_sprite.atkpow = Math.floor(this.registry.list.rcvarpass.activechar.cbt.patk * 0.1) + 1;
                        this.atmpower_1_sprite.keepbullet = true;//don't disappear
                        this.physics.add.collider(this.atmpower_1_sprite, this.mapmob[partyk].sprite, function (a1, a2) {
                            //console.error("charatk collider", a1, a2);
                            a1.scene.mapmobxhitbullet(a1.scene, a1, a2);
                        }.bind(this));
                    }
                }
            }
        }
        if (this.atmpower_2 == 1) {
            console.log("atmpower_2 active");

            if (this.atmpower_2_sprite == undefined) {

                this.atmpower_2_sprite = this.physics.add.sprite(0, 0, "effect95").play({ key: "effect95_b", repeatDelay: 0, frameRate: 24, repeat: -1 }, false);
                this.atmpower_2_sprite.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
                this.atmpower_2_sprite.displayWidth = this.atmpower_2_sprite.displayHeight = 100;
                this.atmpower_2_sprite.setBodySize(100, 100, true);

                this.mapcontainer.add(this.atmpower_2_sprite);
                this.mapcontainer.bringToTop(this.atmpower_2_sprite);

                this.atmpower_2_sprite.setPosition(this.char_main.x, this.char_main.y - 16);

            }
        }
    }
    chardash(wh, pos) {
        //console.log("chardash", pos, wh.char_main.currentx, wh.char_main.currenty);
        //console.log("chardash", wh);

        var chkpos = "";
        var chkposb = "";
        var chkposb2 = "";
        if (wh.jumping == true) {
            this.chardashbuffer = pos;
            return;
        }
        if (wh.guarding == true) return;
        if (wh.activemapdead == true) return;

        wh.iswarping = true;
        wh.jumping = true;
        let charpos = { x: Number(wh.char_main.currentx), y: Number(wh.char_main.currenty) };
        wh.registry.list.snd.snd("dash");
        var direction = this.chardash_getDirection(charpos, pos);
        //console.log("direction", direction);
        wh.char_main_lastspriteface = direction;
        var charspritename = wh.registry.list.rcvarpass.activechar["sprite"];
        var direction1 = "d";
        this.chardasheff = wh.physics.add.sprite(0, 0, "dasheff2").play({ key: "dasheff2_b", repeatDelay: 0, frameRate: 24, repeat: -1 }, false);
        this.chardasheff.texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
        this.chardasheff.keepbullet = true;
        this.chardasheff.displayWidth = this.chardasheff.displayHeight = 120;
        //this.chardasheff.setSize(40, 40);
        //this.chardasheff.body.setSize(90, 90);
        //this.chardasheff.body.setSize(this.chardasheff.displayWidth, this.chardasheff.displayHeight);
        this.chardasheff.setBodySize(30, 30, true)
        //this.chardasheff.body.scaleX = this.chardasheff.scaleX
        //this.chardasheff.body.scaleY = this.chardasheff.scaleY
        for (const partyk in wh.mapmob) {
            //console.log("charatk collider",wh.mapmob[partyk],this.chardasheff);
            if (wh.mapmob[partyk] != undefined && wh.mapmob[partyk].sprite != undefined) {
                //console.log("charatk collider",wh.mapmob[partyk].sprite,this.chardasheff);
                //wh.mapmob[partyk].mapmobk=partyk;
                this.chardasheff.atkpow = wh.registry.list.rcvarpass.activechar.cbt.patk + 1;
                wh.physics.add.collider(this.chardasheff, wh.mapmob[partyk].sprite, function (a1, a2) {
                    //console.error("charatk collider", a1, a2);
                    a1.scene.mapmobxhitbullet(a1.scene, a1, a2);
                }.bind(this));
            }
        }
        this.mapcontainer.add(this.chardasheff);
        this.mapcontainer.bringToTop(this.chardasheff);
        this.mapcontainer.moveBelow(this.chardasheff, wh.char_main);
        //this.chardasheff.setAngle(0);

        switch (direction) {
            case "left":
                chkpos = (wh.char_main.currentx - 3) + "-" + (wh.char_main.currenty);
                chkposb = (wh.char_main.currentx - 2) + "-" + (wh.char_main.currenty);
                chkposb2 = (wh.char_main.currentx - 1) + "-" + (wh.char_main.currenty);
                wh.char_main.play({ key: charspritename + "-thrust-l", repeatDelay: 2000 }, true); direction1 = "l";

                break;
            case "right":
                chkpos = (wh.char_main.currentx + 3) + "-" + (wh.char_main.currenty);
                chkposb = (wh.char_main.currentx + 2) + "-" + (wh.char_main.currenty);
                chkposb2 = (wh.char_main.currentx + 1) + "-" + (wh.char_main.currenty);
                wh.char_main.play({ key: charspritename + "-thrust-r", repeatDelay: 2000 }, true);
                direction1 = "r";
                break;
            case "up":
                chkpos = (wh.char_main.currentx) + "-" + (wh.char_main.currenty - 3);
                chkposb = (wh.char_main.currentx) + "-" + (wh.char_main.currenty - 2);
                chkposb2 = (wh.char_main.currentx) + "-" + (wh.char_main.currenty - 1);
                wh.char_main.play({ key: charspritename + "-thrust-u", repeatDelay: 2000 }, true);
                direction1 = "u";
                //this.chardasheff.setAngle(90);
                break;
            case "down":
                chkpos = (wh.char_main.currentx) + "-" + (wh.char_main.currenty + 3);
                chkposb = (wh.char_main.currentx) + "-" + (wh.char_main.currenty + 2);
                chkposb2 = (wh.char_main.currentx) + "-" + (wh.char_main.currenty + 1);
                wh.char_main.play({ key: charspritename + "-thrust-d", repeatDelay: 2000 }, true);
                direction1 = "d";
                //this.chardasheff.setAngle(270);
                break;
            default:
                this.plog.log("charjump no direction");
                break;
        }
        var tmpposchk = "";
        var tmpposchkb = "";
        var tmpposchkb2 = "";
        tmpposchk = wh.registry.list.rcvarpass.activemap["walkable"][chkpos];
        tmpposchkb = wh.registry.list.rcvarpass.activemap["walkable"][chkposb];
        tmpposchkb2 = wh.registry.list.rcvarpass.activemap["walkable"][chkposb2];
        var newx = wh.char_main.currentx * 32;//wh.char_main.x;
        var newy = wh.char_main.currenty * 32;////wh.char_main.y;
        //console.log("chardash", chkpos, chkposb, chkposb2);
        //console.log("chardash", tmpposchk, tmpposchkb, tmpposchkb2);

        //this.plog.log("tmpposchk",tmpposchk);
        var tweentime = 300;
        if (tmpposchk == "yn_y" && tmpposchkb == "yn_y" && tmpposchkb2 == "yn_y") {
            var chkposarr = chkpos.split("-");
            newx = Number(parseInt(chkposarr[0])) * 32;
            newy = Number(parseInt(chkposarr[1])) * 32;
        } else if (tmpposchkb == "yn_y" && tmpposchkb2 == "yn_y") {
            var chkposarr = chkposb.split("-");
            newx = Number(parseInt(chkposarr[0])) * 32;
            newy = Number(parseInt(chkposarr[1])) * 32;
            tweentime = 200;
        } else if (tmpposchkb2 == "yn_y") {
            var chkposarr = chkposb2.split("-");
            newx = Number(parseInt(chkposarr[0])) * 32;
            newy = Number(parseInt(chkposarr[1])) * 32;
            tweentime = 100;
        }
        wh.registry.list.phm.fow_clear(wh.char_main.currentx, wh.char_main.currenty, 'revealbrush');
        //newy=newy-32;
        //console.log("charjump chk x", wh.char_main.currentx, wh.char_main.x, newx);
        //console.log("charjump chk y", wh.char_main.currenty, wh.char_main.y, newy);
        //console.log("charjump timeline pos", newx, newy);
        this.chardasheff.setPosition(wh.char_main.x, wh.char_main.y - 16);
        if (this.atmpower_2_sprite != undefined)
            this.atmpower_2_sprite.setPosition(wh.char_main.x, wh.char_main.y - 16);

        var timeline = wh.add.timeline([{
            at: 170,
            loop: 0,
            tween:
            {
                targets: wh.char_main,
                x: { from: wh.char_main.currentx * 32 - 32, to: newx - 32 },
                y: { from: wh.char_main.currenty * 32 - 32, to: newy - 32 },
                ease: 'Power3',
                duration: tweentime,
                loop: 0,
                onComplete: function () {
                    this.plog.warn("charjump timelinecomplete ", arguments);
                    //arguments[0].data[0].targets[0].scene.iswarping = false;
                    //arguments[0].data[0].targets[0].scene.jumping = false;
                    try {
                        arguments[0].iswarping = false;
                        arguments[0].jumping = false;
                        arguments[0].char_main.play({ key: arguments[2] + "-idle-" + arguments[1], repeatDelay: 2000 }, true);
                    } catch (e) {
                        console.log(e);
                    }
                    //console.log(this.chardasheff);
                    try {
                        this.chardasheff.destroy();
                        this.chardasheff = undefined;
                    } catch (e) {
                        console.log(e);
                    }
                    try {
                        arguments[0].chkzmapitem();
                        arguments[0].chkwarppos();
                        arguments[0].registry.list.phm.fow_clear(arguments[0].char_main.currentx, arguments[0].char_main.currenty, 'revealbrush');
                        arguments[0].registry.list.timectl.setlightpos();

                    } catch (e) {
                        console.log(e);
                    }
                    try {
                        if (Object.keys(this.chardashbuffer).length === 0 && this.chardashbuffer.constructor === Object) {
                            //console.log("The object is empty");
                        } else {
                            //console.log("The object is not empty");
                            this.chardash(arguments[0], this.chardashbuffer);
                            this.chardashbuffer = {};
                        }
                    } catch (e) {
                        console.log(e);
                    }

                }.bind(this, wh, direction1, charspritename),
                onUpdate: function () {
                    try {
                        this.chardasheff.setPosition(arguments[0].char_main.x, arguments[0].char_main.y - 16);
                        this.registry.list.timectl.setlightpos();
                        if (this.atmpower_2_sprite != undefined)
                            this.atmpower_2_sprite.setPosition(arguments[0].char_main.x, arguments[0].char_main.y - 16);
                    } catch (e) {
                        console.log(e);
                    }
                }.bind(this, wh)
            }
        }]);

        timeline.play();
        if (chkposarr != undefined) {
            wh.char_main.currentx = Number(chkposarr[0]);
            wh.char_main.currenty = Number(chkposarr[1]);
        }
    }
    camman(wh) {
        if (typeof wh.camman === "function") {
            // ref by Preloader scene
            this.plog.log("camman();", wh);
            wh.gifcami(wh, wh.mapcontainer);
            wh.mapcami(wh, wh.playerName);
            ///wh.mapcami(wh, wh.avartarcircle);
            wh.mapcami(wh, wh.playerAvatar);
            wh.mapcami(wh, wh.clock_hh);
            wh.mapcami(wh, wh.clock_dot);
            wh.mapcami(wh, wh.mnmclickarea);

            if (wh.registry.list.drama.pgbar != undefined) {
                wh.mapcami(wh, wh.registry.list.drama.pgbar);
                wh.mapcami(wh, wh.registry.list.drama.pgbar.barfg);
                wh.mapcami(wh, wh.registry.list.drama.pgbar.bar);
                wh.mapcami(wh, wh.registry.list.drama.pgbar.txt);
                wh.mapcami(wh, wh.registry.list.drama.stttxt);
                //console.log(wh.registry.list.drama.pgbar);
            }
            if (wh.registry.list.tut != undefined) {
                wh.mapcami(wh, wh.registry.list.tut.notutbtn);
                wh.mapcami(wh, wh.registry.list.tut.closebtn);

                if (wh.registry.list.tut.objlist != undefined) {
                    wh.mapcami(wh, wh.registry.list.tut.objlist["text"]);
                    //wh.mapcami(wh, wh.registry.list.tut.objlist["fow"]);
                    //wh.mapcami(wh, wh.registry.list.tut.objlist["autofow"]);
                    //wh.mapcami(wh, wh.registry.list.tut.objlist["hand"]);
                    //wh.mapcami(wh, wh.registry.list.tut.objlist["circlemap"]);
                }
            }
            wh.mapcami(wh, wh.registry.list.drama.fillbg);
            wh.mapcami(wh, wh.registry.list.drama.filltxt);

            if (wh.registry.list.gp.slfocusl != undefined &&
                wh.registry.list.gp.slfocusl.parentContainer != undefined &&
                wh.registry.list.gp.slfocusl.parentContainer.name != "mapcontainer") {
                wh.mapcami(wh, wh.registry.list.gp.slfocusl);
                wh.mapcami(wh, wh.registry.list.gp.slfocusr);
            } else if (wh.registry.list.gp.slfocusl != undefined &&
                wh.registry.list.gp.slfocusl.parentContainer == undefined) {
                wh.mapcami(wh, wh.registry.list.gp.slfocusl);
                wh.mapcami(wh, wh.registry.list.gp.slfocusr);
            }

            wh.mapcami(wh, wh.partymapcontainer);
            wh.mapcami(wh, wh.partymycontainer);

            wh.mapcami(wh, wh.upgradeitemcontainer);
            wh.mapcami(wh, wh.upgraderarcontainer);

            if (wh.mapeffects != undefined)
                wh.mapcami(wh, wh.mapeffects["fogalpha"]);


            wh.mapcami(wh, wh.avartarcircle);

            wh.mapcami(wh, wh.registry.list.drama.skipbtn);

            wh.mapcami(wh, wh.registry.list.rctoast.rcaconfirmbgimg);
            wh.mapcami(wh, wh.registry.list.rctoast.rcaconfirmbg);
            wh.mapcami(wh, wh.registry.list.rctoast.rcaconfirmbtn);
            wh.mapcami(wh, wh.registry.list.rctoast.rcaconfirmhead);
            wh.mapcami(wh, wh.registry.list.rctoast.rcaconfirmtext);

            wh.mapcami(wh, wh.clock_hh);
            wh.mapcami(wh, wh.registry.list.rctoast.npcvideobg);
            wh.mapcami(wh, wh.registry.list.rctoast.npcvideobgimg);
            wh.mapcami(wh, wh.registry.list.rctoast.npcvideobgtext);
            wh.mapcami(wh, wh.registry.list.rctoast.npcvideoimg);
            wh.mapcami(wh, wh.registry.list.rctoast.npcvideotext);

            wh.mapcami(wh, wh.registry.list.rctoast.rcaLconfirmbg);
            wh.mapcami(wh, wh.registry.list.rctoast.rcaLconfirmbtn);
            wh.mapcami(wh, wh.registry.list.rctoast.rcaLconfirmhead);
            wh.mapcami(wh, wh.registry.list.rctoast.rcaLconfirmtext);
            wh.mapcami(wh, wh.registry.list.rctoast.rcaLconfirmbgimg);

            wh.mapcami(wh, wh.registry.list.rctoast.rcconfirmbtn);
            wh.mapcami(wh, wh.registry.list.rctoast.rccancelbtn);
            wh.mapcami(wh, wh.registry.list.rctoast.rcconfirmhead);
            wh.mapcami(wh, wh.registry.list.rctoast.rcconfirmtext);
            wh.mapcami(wh, wh.registry.list.rctoast.rcconfirmbgimg);
            wh.mapcami(wh, wh.registry.list.rctoast.rcconfirmbg);


            wh.mapcami(wh, wh.registry.list.rctoast.rcenternumbg);
            wh.mapcami(wh, wh.registry.list.rctoast.rcenternumbtn);
            wh.mapcami(wh, wh.registry.list.rctoast.rcenternumcancelbtn);
            wh.mapcami(wh, wh.registry.list.rctoast.rcenternumhead);
            wh.mapcami(wh, wh.registry.list.rctoast.rcenternumtext);
            wh.mapcami(wh, wh.registry.list.rctoast.rcenternumbgimg);
            wh.mapcami(wh, wh.registry.list.rctoast.rcenternumbg);

            wh.mapcami(wh, wh.registry.list.rctoast.rcentertextbg);
            wh.mapcami(wh, wh.registry.list.rctoast.rcentertextbtn);
            wh.mapcami(wh, wh.registry.list.rctoast.rcentertextcancelbtn);
            wh.mapcami(wh, wh.registry.list.rctoast.rcentertexthead);
            wh.mapcami(wh, wh.registry.list.rctoast.rcentertexttext);
            wh.mapcami(wh, wh.registry.list.rctoast.rcentertextbgimg);
            wh.mapcami(wh, wh.registry.list.rctoast.rcentertextbg);

            wh.mapcami(wh, wh.mapeffects);
            wh.mapcami(wh, wh.mapeffects['ray1']);
            wh.mapcami(wh, wh.mapeffects['fogalpha']);

            wh.mapcami(wh, wh.npccontainer);
            wh.mapcami(wh, wh.petskillcontainer);
            wh.mapcami(wh, wh.questinfocontainer);
            wh.mapcami(wh, wh.BUYITEMcontainer_item);
            wh.mapcami(wh, wh.BUYSTACKITEMcontainer_item);
            wh.mapcami(wh, wh.VIEWMYcontainer_item);
            wh.mapcami(wh, wh.VIEWMYSTACKcontainer_item);
            wh.mapcami(wh, wh.pickitemitemcontainer);
            wh.mapcami(wh, wh.pickitemcontainer);
            wh.mapcami(wh, wh.farmordercontainer);

            wh.mapcami(wh, wh.registry.list.rctoast.homeinvensiibtn);
            wh.mapcami(wh, wh.registry.list.rctoast.homeinvensihead);
            wh.mapcami(wh, wh.registry.list.rctoast.homeinvensitext);
            wh.mapcami(wh, wh.registry.list.rctoast.homeinvensibgimg);
            wh.mapcami(wh, wh.registry.list.rctoast.homeinvenclosebtn);
            wh.mapcami(wh, wh.registry.list.rctoast.homeinvensiibtn);
            wh.mapcami(wh, wh.registry.list.rctoast.homeinvensisbtn);
            wh.mapcami(wh, wh.registry.list.rctoast.homeinvensibg);

            wh.mapcami(wh, wh.partymycontainer);
            wh.mapcami(wh, wh.partymapcontainer);

            wh.mapcami(wh, wh.registry.list.rctoast.rcdedbg);
            wh.mapcami(wh, wh.registry.list.rctoast.rcdedbgimg);
            wh.mapcami(wh, wh.registry.list.rctoast.rcdedbtn);
            wh.mapcami(wh, wh.registry.list.rctoast.rcdedtext);

            wh.mapcami(wh, wh.registry.list.rcloading.loading2box);
            wh.mapcami(wh, wh.registry.list.rcloading.loading2txt);
            wh.mapcami(wh, wh.registry.list.rcloading.loading2txtb);
            wh.mapcami(wh, wh.registry.list.rcloading.loading2bg);
            wh.mapcami(wh, wh.registry.list.rcloading.loadingtip);
            wh.mapcami(wh, wh.registry.list.rcloading.loadingcat);
            wh.mapcami(wh, wh.registry.list.rcloading.loading2imgbg);

            wh.mapcami(wh, wh.maplayerfader);

            wh.mapcami(wh, wh.mapbdl);
            wh.mapcami(wh, wh.mapbdr);
            wh.mapcami(wh, wh.mapbdd);
            wh.mapcami(wh, wh.mapbdu);
            wh.mapcami(wh, wh.gifscontainer);

            wh.mapcami(wh.loading2box);
            wh.mapcami(wh.loading2txt);
            wh.mapcami(wh.loading2txtb);
            wh.mapcami(wh.loading2bg);
            wh.mapcami(wh.loadingtip);
            //this.gifcam.name = "gifcam";

            wh.mapcami(wh, wh.VIEWMYcontainer_item);
            wh.mapcami(wh, wh.VIEWMYSTACKcontainer_item);
            wh.mapcami(wh, wh.BUYITEMcontainer_item);
            wh.mapcami(wh, wh.petuplevelcontainer);
            wh.mapcami(wh, wh.msgscontainer);
            wh.mapcami(wh, wh.BUYSTACKITEMcontainer_item);
            wh.mapcami(wh, wh.sparbcontainer);
            wh.mapcami(wh, wh.inventorycontainer_stackitem);
            wh.mapcami(wh, wh.inventorycontainer_item);
            wh.mapcami(wh, wh.shopcontainer_stackitem);
            wh.mapcami(wh, wh.shopcontainer_item);
            wh.mapcami(wh, wh.equipmentcontainer);
            wh.mapcami(wh, wh.skillcraftcontainer);
            wh.mapcami(wh, wh.attricontainer);
            wh.mapcami(wh, wh.skillcontainer);
            wh.mapcami(wh, wh.questlistcontainer);
            wh.mapcami(wh, wh.petguicontainer);
            wh.mapcami(wh, wh.homeinventorycontainer_stackitem);
            wh.mapcami(wh, wh.homeinventorycontainer_item);
            wh.mapcami(wh, wh.pettravelcontainer);
            wh.mapcami(wh, wh.iapstorecontainer);
            wh.mapcami(wh, wh.zcharinfocontainer);
            wh.mapcami(wh, wh.dmcontainer);
            wh.mapcami(wh, wh.partymapcontainer);
            wh.mapcami(wh, wh.partymycontainer);
            wh.mapcami(wh, wh.friendlistcontainer);

            wh.gifcami(wh, wh.char_main);

            wh.mapcami(wh, [
                wh.registry.list.rctoast.localmapbg,
                wh.registry.list.rctoast.localmapbgimg,
                wh.registry.list.rctoast.localmaptext,
                wh.registry.list.rctoast.localmapimg,
            ]);

            wh.minimapview
            wh.mapcami(wh, [
                wh.minimapview,
                wh.minimapviewbg
            ]);

            wh.mapcami(wh, [
                wh.registry.list.rctoast.localmapbg,
                wh.registry.list.rctoast.localmapbgimg,
                wh.registry.list.rctoast.localmaptext,
                wh.registry.list.rctoast.localmapimg,
            ]);
            wh.mapcami(wh, [
                wh.registry.list.rctoast.selectgemslotbg, 
                wh.registry.list.rctoast.selectgemslotbgimg, 
                wh.registry.list.rctoast.selectgemslotbtn, 
                wh.registry.list.rctoast.selectgemslotcancelbtn, 
                wh.registry.list.rctoast.selectgemslotgemicon, 
                wh.registry.list.rctoast.selectgemslothead, 
                wh.registry.list.rctoast.selectgemslotitemicon, 
                wh.registry.list.rctoast.selectgemslot_gem1, 
                wh.registry.list.rctoast.selectgemslot_text1, 
                wh.registry.list.rctoast.selectgemslot_gem2, 
                wh.registry.list.rctoast.selectgemslot_text2, 
                wh.registry.list.rctoast.selectgemslot_gem3, 
                wh.registry.list.rctoast.selectgemslot_text3, 
                wh.registry.list.rctoast.selectgemslot_gem4, 
                wh.registry.list.rctoast.selectgemslot_text4, 
            ]);  
        }
    }
    camman_refollow(wh) {
        wh.cameras.main.stopFollow();;
        wh.cameras.main.startFollow(wh.char_main, true, 0.05, 0.05);
    }

    camman_follow(wh, obj) {
        wh.cameras.main.stopFollow();;
        wh.cameras.main.startFollow(obj, true, 0.05, 0.05);
    }
    gifcami(wh, hidewhat) {
        if (wh.gifcam != undefined) {
            if (hidewhat != undefined) {
                if (Array.isArray(hidewhat)) {
                    for (const k in hidewhat) {
                        if (hidewhat[k] != undefined)
                            wh.gifcam.ignore(hidewhat[k]);
                    }
                } else {
                    wh.gifcam.ignore(hidewhat);
                }
            }
        }
    }
    mapcami(wh, hidewhat) {
        //this.plog.log("mapcami", wh, hidewhat);
        if (hidewhat != undefined) {
            if (Array.isArray(hidewhat)) {
                for (const k in hidewhat) {
                    if (hidewhat[k] != undefined)
                        wh.cameras.main.ignore(hidewhat[k]);
                }
            } else {
                try {
                    wh.cameras.main.ignore(hidewhat);
                } catch (e) {
                    //console.log(e);
                }
            }
            // wh.cameras.main.ignore(hidewhat);
        }
    }

    mapcontainer_hide() {
        this.plog.log("mapcontainer_hide", this);
        this.mapcontainer.visible = false;
    }
    mapcontainer_show() {
        this.plog.log("mapcontainer_show", this);
        this.mapcontainer.visible = true;
    }
    ismobileguimode() {

        var width = this.sys.game.canvas.width;
        var height = this.sys.game.canvas.height;
        if (width < this.mobilescreensize || height < this.mobilescreensize) {
            return true;
        } else {
            return false;
        }
    }
}