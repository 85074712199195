import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCHTTP } from './rchttp.service';
import { RCOSDService } from './rcosd.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class NPCSHOPService {
  loading;
  winguiltab;
  plog;
  title;
  shopdragbtn;
  selecteditemno = '';
  currentresppage = 1;
  respperpage = 25;
  resptotalpage;
  selectcircle;
  itemdescr;
  shopitemlist = [];
  shopitemlistp = [];
  shopfwdbtn;
  shopbackbtn;
  shopdata;
  buymultbtn;
  buy10btn;
  shopinfo;
  shopguibg;
  currencyicon;
  shopclosebtn;
  shopbuybtn;
  wh;

  constructor(
    public rcvarsservice: RCVarsService,
    public rchttp: RCHTTP,
    public rcosd: RCOSDService
  ) { 
    this.plog=new plog();
  }


  loadingshow() {
    this.wh.shopcontainer_stackitem.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  async shop_stackitem(wh, shopdata) {
    this.wh = wh;
    
    if (this.wh.shopcontainer_stackitem != undefined) {
      await this.shop_stackitem_close(this.wh);
    }
    this.plog.log('shop_stackitem', wh, shopdata);
    this.shopdata = shopdata;/*
    if (wh.shopcontainer_stackitem != undefined) {
      wh.shopcontainer_stackitem.visible = true;
      this.shop_stackitem_loaded(wh, shopdata);
      return;
    }*/

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('shop_stackitem', wh, shopdata);

    this.selectcircle = wh.add.sprite(0, 0, 'selectcircle').play('selected_b');
    this.selectcircle.setOrigin(0.5, 0.5);
    this.selectcircle.visible = false;
    this.selectcircle.setAlpha(0.7);
    this.shopguibg = wh.add.image(0, 0, 'shopguibg5x5');
    this.shopguibg.setOrigin(0.5, 0.5);
    this.shopguibg.setInteractive({ pixelPerfect: true });
    this.shopguibg.removeAllListeners();
    this.shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    this.shopbackbtn.setOrigin(0.5, 0.5);
    this.shopbackbtn.setInteractive();
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');
    this.shopfwdbtn.setOrigin(0.5, 0.5);
    this.shopfwdbtn.setInteractive();
    this.shopfwdbtn.removeAllListeners();
    this.shopbuybtn = wh.add.sprite(0, 0, "thesb", "buy");//wh.add.image(0, 0, 'buybtn');
    this.shopbuybtn.setOrigin(0.5, 0.5);
    this.shopbuybtn.setInteractive();
    this.shopbuybtn.removeAllListeners();
    this.buymultbtn = wh.add.sprite(0, 0, "thesq", "num");//wh.add.image(0, 0, 'buybtn');
    this.buymultbtn.setOrigin(0.5, 0.5);
    this.buymultbtn.setInteractive();
    this.buymultbtn.removeAllListeners();
    this.buy10btn = wh.add.sprite(0, 0, "thesq", "n10");//wh.add.image(0, 0, 'buybtn');
    this.buy10btn.setOrigin(0.5, 0.5);
    this.buy10btn.setInteractive();
    this.buy10btn.removeAllListeners();
    this.plog.log('shop_stackitem var', this.rcvarsservice);

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name='skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(165, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.shop_stackitem_close(wh);
      },
      this
    );

    this.shopbackbtn.displayWidth = 100;
    this.shopfwdbtn.displayWidth = 100;
    this.shopbuybtn.displayWidth = 100;
    this.shopbackbtn.displayHeight = 40;
    this.shopfwdbtn.displayHeight = 40;
    this.shopbuybtn.displayHeight = 40;
    this.shopbackbtn.setPosition(-100, 220);
    this.shopfwdbtn.setPosition(120, 220);
    this.buymultbtn.setPosition(60, 210);
    this.buy10btn.setPosition(60, 232);
    this.shopbuybtn.setPosition(0, 220);
    this.buy10btn.displayWidth = this.buy10btn.displayHeight = 18;
    this.buymultbtn.displayWidth = this.buymultbtn.displayHeight = 18;
    wh.shopcontainer_stackitem = wh.add.container(300, 300);
    wh.shopcontainer_stackitem.name = "shopcontainer_stackitem";
    wh.shopcontainer_stackitem.add(this.shopclosebtn);
    wh.shopcontainer_stackitem.add(this.selectcircle);
    wh.shopcontainer_stackitem.add(this.shopguibg);
    wh.shopcontainer_stackitem.add(this.shopbackbtn);
    wh.shopcontainer_stackitem.add(this.shopfwdbtn);
    wh.shopcontainer_stackitem.add(this.shopbuybtn);
    wh.shopcontainer_stackitem.add(this.buy10btn);
    wh.shopcontainer_stackitem.add(this.buymultbtn);
    wh.shopcontainer_stackitem.bringToTop(this.shopclosebtn);
    wh.shopcontainer_stackitem.bringToTop(this.buy10btn);
    wh.shopcontainer_stackitem.bringToTop(this.buymultbtn);
    var itemdescr = wh.add.text(0, 0,this.wh.trs("f:shopcontainer:Click Item to View Description===Click Item to View Description"), wh.fs["winguiitemdescr"]);
    wh.shopcontainer_stackitem.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 176);
    wh.itemdescr = itemdescr;
    //this.plog.log("shop_stackitem shopinfo",this.shopdata);
    var shopname = this.shopdata['name'];
    if (this.shopdata['currency'] != 'gold') {
      shopname = shopname + ', ' + this.shopdata['currency'] + ' shop';
    }
    this.shopinfo = wh.add.text(0, 0, '' + shopname, wh.fs["winguibardescr"]);
    wh.shopcontainer_stackitem.add(this.shopinfo);

    this.shopinfo.setOrigin(0, 0);
    this.shopinfo.setPosition(-125, -173);
    this.currencyicon = wh.add.sprite(
      0,
      0,
      'stackitem_tpt',
      this.shopdata['currency']
    );
    wh.shopcontainer_stackitem.add(this.currencyicon);
    this.currencyicon.setOrigin(0.5, 0.5);
    this.currencyicon.displayWidth = this.currencyicon.displayHeight = 32;
    this.currencyicon.setPosition(-142, -155);


    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.shopcontainer_stackitem.add(this.loading);
    wh.shopcontainer_stackitem.bringToTop(this.loading);

    //drag

    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem
        );

        this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.x;
        this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.y;
        this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.shop_stackitem_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem.y = pointer.y;
        }
      },
      this
    );
    wh.shopcontainer_stackitem.add(this.shopdragbtn);
    wh.shopcontainer_stackitem.bringToTop(this.shopdragbtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.shopcontainer_stackitem.setSize(shopguibg.width, shopguibg.height);
    wh.shopcontainer_stackitem.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/


    this.title = wh.add.text(0, 0, 'SHOP', wh.fs["winguititle"]);
    this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.shopcontainer_stackitem.add(this.title);
    wh.shopcontainer_stackitem.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -223);


    this.plog.log(
      'shopcontainer_stackitem',
      wh.shopcontainer_stackitem,
      width,
      height
    );
    wh.shopcontainer_stackitem.setPosition(width / 2, height / 2);
    let loader = new Phaser.Loader.LoaderPlugin(wh);
    for (var vv in shopdata['items']) {
      var vdat = shopdata['items'][vv];
      if (vdat.type1 == 'stackitem') {
        var itemdat = this.rcvarsservice.allstackitem[vdat.item];
        if (itemdat == undefined) continue;
        var tmpurl = this.rcvarsservice.gameobj.registry.list.rcvarpass.getlocal(
           'stackitem/' +
          itemdat['sprite'] +
          '.png'
        );
        loader.image(
          vdat['item'],
          tmpurl
        );
      }
      if (vdat.type1 == 'item') {
        //this.plog.log("load shop item", this.rcvarsservice.allitem);
        var itemdat = this.rcvarsservice.allitem[vdat.item];
        //this.plog.log("npcshopitem", itemdat);
        if (itemdat == undefined) {
          this.plog.warn("ITEM not found", vdat);
          continue;
        }
        var tmpurl = this.rcvarsservice.gameobj.registry.list.rcvarpass.getlocal(
          'item/' +
          itemdat['pcode'] +
          '/' +
          itemdat['sprite'] +
          '.png'
        );
        loader.image(
          vdat['item'],
          tmpurl
        );
      }
      this.plog.log('load shop ', vv, vdat, itemdat);
    }
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.shop_stackitem_loaded(wh, shopdata);
      },
      this
    );
    loader.start();
  }
  shop_stackitem_loaded(wh, shopdata) {
    this.plog.log('shop_stackitem_loaded', wh, shopdata);
    this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
      this.rcvarsservice.gameobj.scene.scenes[0].shopcontainer_stackitem
    );
    var shopname = this.shopdata['name'];
    if (this.shopdata['currency'] != 'gold') {
      shopname = shopname + ', ' + this.shopdata['currency'] + ' shop';
    }
    this.shopinfo.setText(shopname);
    this.currencyicon.setTexture('stackitem_tpt', this.shopdata['currency']);



    this.buymultbtn.removeAllListeners();
    this.buymultbtn.setInteractive();
    this.buymultbtn.on('pointerup', async function () {
      //this.showinventory_sell(this, 5);
      //this.plog.log(this);
      if (this.selecteditemno == '' || this.selecteditemno == undefined)
        return;
      var tmpid = this.shopdata['items'][this.selecteditemno]['item'];
      var tmpprice = this.shopdata['items'][this.selecteditemno]['price'];

      var iteminfo2 = this.rcvarsservice.allstackitem[tmpid];

      var amnt = await this.rcvarsservice.gameobj.registry.list.rctoast.rcenternum(
        this.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
        this.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].trs("f:shopcontainer:Confirm Buying===Confirm Buying"),
        '' + iteminfo2['name'] + ' = ' + tmpprice + ' ' + this.shopdata['currency'] + ' each  \n'+this.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].trs("f:shopcontainer:How many to buy?===How many to buy?")
      );
      amnt = Number(amnt);
      if (amnt < 1) return;
      this.shop_stackitem_buy(this, amnt);
    }, this);


    this.buy10btn.removeAllListeners();
    this.buy10btn.setInteractive();
    this.buy10btn.on('pointerup', async function () {
      //this.showinventory_sell(this, 5);
      this.plog.log(this);
      if (this.selecteditemno == '' || this.selecteditemno == undefined)
        return;

      this.shop_stackitem_buy(this, 10);
    }, this);

    var margintop = -90;
    var marginleft = -117;
    var cxpos = 0;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
        // this.shopitemlistp[i].setText("");
        this.shopitemlistp[i].visible = false;
      }
    }
    for (var i = 0; i < 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].destroy(); // = undefined;
        this.shopitemlist[i] = undefined;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].destroy(); // = undefined;
        this.shopitemlistp[i] = undefined;
      }
    }
    this.currentresppage = 1;
    var i = 0;
    for (var vv in shopdata['items']) {
      var vdat = shopdata['items'][vv];
      var tmp;
      if (vdat.type1 == 'stackitem') {
        var itemdat = this.rcvarsservice.allstackitem[vdat.item];
        if (itemdat == undefined) continue;
        tmp = wh.add.sprite(32, 32, 'stackitem_tp', itemdat['code']);
      }
      if (vdat.type1 == 'item') {
        var itemdat = this.rcvarsservice.allitem[vdat.item];
        if (itemdat == undefined) {
          this.plog.warn("ITEM not found", vdat);
          continue;
        }
        tmp = wh.add.sprite(32, 32, 'item_tp', itemdat['code']);
      }
      this.plog.log('loaded shop ', vv, vdat, itemdat);
      tmp.displayWidth = 48;
      tmp.displayHeight = 48;
      wh.shopcontainer_stackitem.add(tmp);
      tmp.setPosition(marginleft + cxpos * 59, margintop + crow * 59);
      var pricetext = vdat['price'];
      if (vdat['price'] > 99999) {
        pricetext = wh.registry.list.gameitf.humannumber(pricetext);
      }
      var tmpp = wh.add.text(0, 0, '' + pricetext, {
        fontFamily: 'ffff',
        fontSize: '10px',
        fill: '#fff',
        boundsAlignH: 'center',
        boundsAlignV: 'middle',
        stroke: '#000',
        strokeThickness: 3,
        resolution: 12
      });
      wh.shopcontainer_stackitem.add(tmpp);
      tmpp.setPosition(tmp.x - 27, tmp.y + 8);
      this.shopitemlist[i] = tmp;
      this.shopitemlistp[i] = tmpp;
      this.shopitemlist[i].visible = true;
      this.shopitemlistp[i].visible = true;
      this.shopitemlist[i].setInteractive();
      this.shopitemlist[i].removeAllListeners();
      this.shopitemlist[i].name = '' + shopdata['code'] + '-' + vv;
      this.shopitemlist[i].on(
        'pointerup',
        function (pointer, localX, localY, event) {
          this.scene.plog.log('shop itemclick this', this);
          this.scene.plog.log('shop itemclick', pointer, localX, localY, event);
          this.scene.registry.list.npcshop.shop_stackitem_itemclick(
            this.scene,
            this
          );
        }
      );
      cxpos++;
      if (cxpos > 4) {
        cxpos = 0;
        crow++;
      }
      i++;
      if (i % 25 == 0) {
        cxpos = 0;
        crow = 0;
      }
    }
    this.plog.log('shop i count', i);
    if (i > 25) {
      //pages

      this.respperpage = 25;
      this.resptotalpage = Math.ceil(i / 25);
    }
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.shop_stackitem_showpage(wh, 'back');
      },
      this
    );
    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.shop_stackitem_showpage(wh, 'next');
      },
      this
    );
    this.shop_stackitem_showpage(wh, 'back');
    this.shopbuybtn.removeAllListeners();
    this.shopbuybtn.on('pointerup', function (pointer, localX, localY, event) {
      this.plog.log('shop shopbuybtn', this);
      this.shop_stackitem_buy(wh, 1);
    }, this);
    this.wh.registry.list.tut.show(this.wh, "npcshop_stack_loaded");

  }
  async shop_stackitem_buy(wh, amnt) {
    this.plog.log('shop_stackitem_buy', wh, '[' + this.selecteditemno + ']');
    if (this.selecteditemno == '') return;
    this.plog.log('shop_stackitem_buy', this);
    if (this.shopdata['items'][this.selecteditemno] == undefined) {
      this.plog.log(
        'shop_stackitem_buy skip undefined',
        this.shopdata['items'][this.selecteditemno]
      );
      return; //dropped
    }
    this.loadingshow();
    var tmpid = this.shopdata['items'][this.selecteditemno]['id'];
    this.plog.log('lllll', this.shopdata['items'][this.selecteditemno]);
    this.rcvarsservice.gameobj.registry.list.gameitf.osdflash(
      this.rcvarsservice.gameobj.scene.scenes[0],
      [{
        txt: this.shopdata['currency'] + ' x' + amnt + ' - ' + (this.shopdata['items'][this.selecteditemno]['price'] * amnt),
        col: 'red',
      }]
    );
    if (this.shopdata['items'][this.selecteditemno]['type1'] == 'stackitem') {
      var res = await this.rchttp.buystackitem(this.shopdata['code'], tmpid, amnt);
    }
    if (this.shopdata['items'][this.selecteditemno]['type1'] == 'item') {
      var res = await this.rchttp.buyitem(this.shopdata['code'], tmpid);
    }
    this.rcvarsservice.gameobj.registry.list.snd.snd("buy");

    //return
    //await this.rcvarsservice.setactivechar(res, this.rcvarsservice.activechar["id"]);
    //this.plog.log("shop_stackitem_buy res", res, this.rcvarsservice);
    //await this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(this.rcvarsservice.gameobj.scene.scenes[0]);
    await this.rchttp.updatecharinfo();
    this.loadinghide();
    //this.selecteditemno="";
  }
  shop_stackitem_itemclick(wh, item) {
    this.selectcircle.visible = true;
    var namea = item.name.split('-');
    wh.registry.list.npcshop.selecteditemno = namea[1];
    this.plog.log('shop_stackitem_itemclick', wh, item);
    this.selectcircle.displayWidth = 50;
    this.selectcircle.displayHeight = 50;
    this.selectcircle.setPosition(item.x, item.y);
    //wh.shopcontainer_stackitem.sendToBack(wh.shopguibg);
    wh.shopcontainer_stackitem.bringToTop(this.selectcircle);
    wh.shopcontainer_stackitem.bringToTop(this.shopitemlist[namea[1]]);
    wh.shopcontainer_stackitem.bringToTop(this.shopitemlistp[namea[1]]);
    var iteminfo =
      wh.registry.list.rcvarpass.allnpcshop[namea[0]]['items'][namea[1]];

    if (iteminfo['type1'] == 'stackitem') {
      var iteminfo2 = wh.registry.list.rcvarpass.allstackitem[iteminfo['item']];
      this.buy10btn.visible = true;
      this.buymultbtn.visible = true;
    } else {
      var iteminfo2 = wh.registry.list.rcvarpass.allitem[iteminfo['item']];
      this.buy10btn.visible = false;
      this.buymultbtn.visible = false;
    }
    this.plog.log(
      'shop_stackitem_itemclick selectcircle',
      iteminfo,
      iteminfo2,
      this.selectcircle
    );
    //this.selectcircle.width=

    wh.itemdescr.setText(
      iteminfo2['name'] +
      ' ' +
       wh.registry.list.gameitf.humannumbercomma(iteminfo['price'])
       +
      ' ' +
      this.shopdata['currency']
    );
    this.rcosd.show(wh, iteminfo['type1'], iteminfo2, iteminfo);
  }
  async shop_stackitem_close(wh) {
    await wh.registry.list.gameitf.closegui(wh, wh.shopcontainer_stackitem);
    wh.shopcontainer_stackitem.visible = false;
    
    for (const k in this.wh.shopcontainer_stackitem.list) {
      try {
        await this.wh.shopcontainer_stackitem.remove(this.wh.shopcontainer_stackitem.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! shopcontainer_stackitem.destroy() ", e);
      }
    }
    try {
      await this.wh.shopcontainer_stackitem.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! shopcontainer_stackitem.destroy() ", e);
    }
    wh.shopcontainer_stackitem = undefined;
  }
  shop_stackitem_showpage(gc, viewpage) {
    this.selectcircle.visible = false;
    this.loadinghide();
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
        //this.shopitemlistp[i].setText("");
        this.shopitemlistp[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'shop_stackitem_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );

    
    var griddat = [];
    var griddatf = [];
    griddat.push(this.shopclosebtn);


    for (var i = numfrom; i < numto; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = true;
        this.shopitemlistp[i].visible = true;
        griddat.push(this.shopitemlist[i]);
        griddatf.push(this.shopitemlist[i]);
      }
    }
    griddat.push(this.shopbuybtn);

    
    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }
}
