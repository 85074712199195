import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { GameInterfaceService } from './game-interface.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class RCStackInventoryService {
  shopdragbtn;
  sellallbtn;
  title;
  stackarr = [];
  winguiltab;
  winguiltabani;
  empty;
  invensize;
  submn = [];
  incubatebtndis;
  selecteditemno = '';
  currentresppage = 1;
  respperpage = 25;
  resptotalpage;
  selectcircle;
  itemdescr;
  useitembtndis;
  useitembtn;
  useallbtn;
  shopitemlist = [];
  shopitemlistp = [];
  shopfwdbtn;
  shopbackbtn;
  invendropbtn;
  depositbtn;
  invenmode = '';
  sellbtn;
  donatebtn;
  donateallbtn;
  shopclosebtn;
  no5;
  no5r;
  no5b;
  no10;
  no10r;
  no10b;
  shopguibg;
  wh;
  incubatebtn;
  shopdata;
  filtertype = '';
  loading;
  sellanywhere: any;
  shine1;
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rchttp: RCHTTP,
    public gameitf: GameInterfaceService
  ) {
    this.plog = new plog();
  }

  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true && this.wh.inventorycontainer_stackitem != undefined) {
      this.wh.registry.list.gameitf.guiwindowclick(
        this.wh.inventorycontainer_stackitem
      );
    }
    this.firsttimerun = false;
  }

  loadingshow() {
    try {
      this.wh.inventorycontainer_stackitem.bringToTop(this.loading);
    } catch (e) {
      this.plog.log(e);
    }
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  sortdata(obj) {
    // Transform the object into an array of key/value pairs
    let entries = Object.entries(obj);

    entries.sort((a, b) => {
      if (a[1]["item"] < b[1]["item"]) {
        return -1;
      } else if (a[1]["item"] > b[1]["item"]) {
        return 1;
      } else {
        return 0;
      }
    });
    // Sort the array based on whether the 'item' value contains 'xxa'
    entries.sort((a, b) => {
      const aContains = a[1]['item'].includes('theatre');
      const bContains = b[1]['item'].includes('theatre');

      if (aContains && !bContains) {
        return -1;
      } else if (!aContains && bContains) {
        return 1;
      } else {
        return 0;
      }
    });
    entries.sort((a, b) => {
      const aContains = a[1]['item'].includes('chest');
      const bContains = b[1]['item'].includes('chest');

      if (aContains && !bContains) {
        return -1;
      } else if (!aContains && bContains) {
        return 1;
      } else {
        return 0;
      }
    });
    entries.sort((a, b) => {
      const aContains = a[1]['item'].includes('xpscroll');
      const bContains = b[1]['item'].includes('xpscroll');

      if (aContains && !bContains) {
        return -1;
      } else if (!aContains && bContains) {
        return 1;
      } else {
        return 0;
      }
    });
    entries.sort((a, b) => {
      const aContains = a[1]['item'].includes('kiscroll');
      const bContains = b[1]['item'].includes('kiscroll');

      if (aContains && !bContains) {
        return -1;
      } else if (!aContains && bContains) {
        return 1;
      } else {
        return 0;
      }
    });

    entries.sort((a, b) => {
      const aContains = a[1]['item'].includes('teleportkey');
      const bContains = b[1]['item'].includes('teleportkey');

      if (aContains && !bContains) {
        return -1;
      } else if (!aContains && bContains) {
        return 1;
      } else {
        return 0;
      }
    });


    return entries;
    // Manually construct a new object from the sorted array
    let sortedObj = {};
    for (let [key, value] of entries) {
      sortedObj[key] = value;
    }

    return sortedObj;
  }
  async showinventory(wh, shopdata, invenmode, forcefocus = false) {
    //console.error("showinven");
    if (forcefocus == true) this.firsttimerun = true;

    this.invenmode = invenmode;
    this.shopdata = shopdata;
    this.wh = wh;

    if (this.wh.inventorycontainer_stackitem != undefined) {
      await this.showinventory_close(this.wh);
    }
    /*
    for (var i = 0; i < 200; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].destroy(); //true = undefined;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].destroy(); // = undefined;
        //this.shopitemlistp[i] = undefined;
      }
      if (this.shopitemlist[i] != undefined) this.shopitemlist[i].destroy();
      if (this.shopitemlist[i] != undefined) this.shopitemlist[i] = undefined;
      if (this.shopitemlistp[i] != undefined) this.shopitemlistp[i].destroy();
      if (this.shopitemlistp[i] != undefined) this.shopitemlistp[i] = undefined;

    }*/
    /*
    if (wh.inventorycontainer_stackitem != undefined) {
      this.plog.log(
        'inventorycontainer_stackitem',
        wh.inventorycontainer_stackitem
      );
      wh.inventorycontainer_stackitem.visible = true;
      this.showinventory_loaded(wh, shopdata);

      return;
    }*/
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('showinventory', wh, shopdata);

    this.winguiltab = wh.add.image(0, 0, 'winguiltab');
    this.winguiltab.setOrigin(0.5, 0.5);
    this.winguiltab.displayWidth = this.winguiltab.displayHeight = 52;
    this.winguiltab.visible = false;

    const selectcircle = wh.add.sprite(0, 0, 'selectcircle').play('selected_b');
    //const selectcircle = wh.add.image(0, 0, "selectcircle");
    selectcircle.setOrigin(0.5, 0.5);
    selectcircle.visible = false;
    selectcircle.setAlpha(0.7);
    const shopguibg = wh.add.image(0, 0, 'blankbarboxguibg');//stackitemguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    const shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    shopbackbtn.setOrigin(0.5, 0.5);
    shopbackbtn.setInteractive();
    shopbackbtn.removeAllListeners();
    const shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');
    shopfwdbtn.setOrigin(0.5, 0.5);
    shopfwdbtn.setInteractive();
    shopfwdbtn.removeAllListeners();
    const invendropbtn = wh.add.sprite(0, 0, "thesb", "drop");//wh.add.image(0, 0, 'dropbtn');
    invendropbtn.setOrigin(0.5, 0.5);
    invendropbtn.setInteractive();
    invendropbtn.removeAllListeners();
    const depositbtn = wh.add.sprite(0, 0, "thesb", "deposit");//wh.add.image(0, 0, 'homeinvendep');
    depositbtn.setOrigin(0.5, 0.5);
    depositbtn.setInteractive();
    depositbtn.removeAllListeners();

    const sellbtn = wh.add.sprite(0, 0, "thesb", "sell");//wh.add.image(0, 0, 'sellbtn');
    sellbtn.setOrigin(0.5, 0.5);
    sellbtn.removeAllListeners();
    sellbtn.setInteractive();
    const donatebtn = wh.add.sprite(0, 0, "thesb", "donate");//wh.add.image(0, 0, 'sellbtn');
    donatebtn.setOrigin(0.5, 0.5);
    donatebtn.removeAllListeners();
    donatebtn.setInteractive();
    const donateallbtn = wh.add.sprite(0, 0, "thesb", "donateall");//wh.add.image(0, 0, 'sellbtn');
    donateallbtn.setOrigin(0.5, 0.5);
    donateallbtn.removeAllListeners();
    donateallbtn.setInteractive();


    depositbtn.setOrigin(0.5, 0.5);

    const sellanywhere = wh.add.sprite(0, 0, "stackitem_tp", "sellstackitem");//wh.add.image(0, 0, 'sellbtn');
    sellanywhere.setOrigin(0.5, 0.5);
    sellanywhere.setInteractive();
    sellanywhere.removeAllListeners();
    sellanywhere.on(
      'pointerup',
      async function () {
        var res = await this.rchttp.getcommon2("consumestackitem", { usecode: 'sellstackitem' })
        if (res == 'ok') {
          this.wh.registry.list.execmod.exec(
            'npc_sellstackitem::',
            this.wh
          );
        }

        await this.wh.registry.list.rchttp.updatecharinfo();

        this.wh.registry.list.gameitf.guiwindowclick(
          this.wh.inventorycontainer_stackitem
        );
      }, this);
    this.sellanywhere = sellanywhere;
    var shine1 = wh.add.sprite(100, 100, "shinebox", "shinebox_b");
    shine1.play("shinebox_b");
    shine1.setOrigin(0.5, 0.5);
    this.shine1 = shine1;
    this.sellanywhere.name = 'skipcntnrsizecal';
    this.shine1.name = 'skipcntnrsizecal';
    //this.plog.log('showinventory var', this.rcvarsservice);

    this.shopfwdbtn = shopfwdbtn;
    this.shopbackbtn = shopbackbtn;
    this.selectcircle = selectcircle;
    this.invendropbtn = invendropbtn;
    this.sellbtn = sellbtn;
    this.donatebtn = donatebtn;
    this.donateallbtn = donateallbtn;
    this.depositbtn = depositbtn;

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.showinventory_close(wh);
      },
      this
    );

    wh.inventorycontainer_stackitem = wh.add.container(300, 300);
    wh.inventorycontainer_stackitem._refsrv = this;

    wh.inventorycontainer_stackitem.visible = false;
    wh.inventorycontainer_stackitem.name = "inventorycontainer_stackitem";
    wh.inventorycontainer_stackitem.add(this.shopclosebtn);
    wh.inventorycontainer_stackitem.add(selectcircle);
    wh.inventorycontainer_stackitem.add(shopguibg);
    wh.inventorycontainer_stackitem.add(shopbackbtn);
    wh.inventorycontainer_stackitem.add(shopfwdbtn);
    wh.inventorycontainer_stackitem.add(invendropbtn);
    wh.inventorycontainer_stackitem.add(depositbtn);
    wh.inventorycontainer_stackitem.add(sellbtn);
    wh.inventorycontainer_stackitem.add(donatebtn);
    wh.inventorycontainer_stackitem.add(donateallbtn);
    wh.inventorycontainer_stackitem.add(this.winguiltab);
    wh.inventorycontainer_stackitem.add(this.sellanywhere);
    wh.inventorycontainer_stackitem.add(this.shine1);
    wh.inventorycontainer_stackitem.bringToTop(this.shine1);


    this.sellallbtn = wh.add.sprite(0, 0, "thesb", "sellall");//wh.add.image(0, 0, 'incubate');
    this.sellallbtn.setOrigin(0.5, 0.5);
    this.sellallbtn.setInteractive();
    this.sellallbtn.removeAllListeners();
    this.incubatebtn = wh.add.sprite(0, 0, "thesb", "incubate");//wh.add.image(0, 0, 'incubate');
    this.incubatebtn.setOrigin(0.5, 0.5);
    this.incubatebtn.setInteractive();
    this.incubatebtn.removeAllListeners();
    this.incubatebtndis = wh.add.sprite(0, 0, "thesb", "incubatedis");//wh.add.image(0, 0, 'incubatedis');
    this.incubatebtndis.setOrigin(0.5, 0.5);
    this.incubatebtndis.setInteractive();
    this.incubatebtndis.removeAllListeners();
    this.useitembtn = wh.add.sprite(0, 0, "thesb", "useitem");//wh.add.image(0, 0, 'useitembtn');
    this.useitembtn.setOrigin(0.5, 0.5);
    this.useitembtn.setInteractive();
    this.useitembtn.removeAllListeners();
    this.useallbtn = wh.add.sprite(0, 0, "thesb", "useall");//wh.add.image(0, 0, 'useitembtn');
    this.useallbtn.setOrigin(0.5, 0.5);
    this.useallbtn.setInteractive();
    this.useallbtn.removeAllListeners();
    this.useitembtndis = wh.add.sprite(0, 0, "thesb", "useitemdis");//wh.add.image(0, 0, 'useitembtndis');
    this.useitembtndis.setOrigin(0.5, 0.5);
    this.useitembtndis.setInteractive();
    this.useitembtndis.removeAllListeners();
    wh.inventorycontainer_stackitem.add(this.useitembtn);
    wh.inventorycontainer_stackitem.add(this.useallbtn);
    wh.inventorycontainer_stackitem.add(this.useitembtndis);
    wh.inventorycontainer_stackitem.bringToTop(this.useitembtn);
    wh.inventorycontainer_stackitem.bringToTop(this.useallbtn);
    wh.inventorycontainer_stackitem.bringToTop(this.useitembtndis);
    this.useitembtn.setPosition(-100, -155);
    this.useallbtn.setPosition(-100, -155);
    this.useitembtndis.setPosition(-100, -155);
    this.useitembtn.displayWidth = 100;
    this.useitembtn.displayHeight = 35;
    this.useallbtn.displayWidth = 100;
    this.useallbtn.displayHeight = 35;
    this.useitembtndis.displayWidth = 100;
    this.useitembtndis.displayHeight = 35;
    this.useitembtn.visible = false;
    this.useallbtn.visible = false;
    this.useitembtn.on(
      'pointerup',
      function () {
        this.useselecteditem();
      },
      this
    );
    this.useallbtn.on(
      'pointerup',
      function () {
        this.useallselecteditem();
      },
      this
    );
    this.sellallbtn.setPosition(0, -155);
    this.sellallbtn.displayWidth = 100;
    this.sellallbtn.displayHeight = 35;
    this.sellallbtn.visible = false;
    this.incubatebtn.setPosition(0, -155);
    this.incubatebtn.displayWidth = 100;
    this.incubatebtn.displayHeight = 35;
    this.incubatebtn.visible = false;
    this.incubatebtndis.setPosition(0, -155);
    this.incubatebtndis.displayWidth = 100;
    this.incubatebtndis.displayHeight = 35;
    this.incubatebtndis.visible = false;
    wh.inventorycontainer_stackitem.add(this.sellallbtn);
    wh.inventorycontainer_stackitem.add(this.incubatebtn);
    wh.inventorycontainer_stackitem.add(this.incubatebtndis);
    wh.inventorycontainer_stackitem.bringToTop(this.sellallbtn);
    wh.inventorycontainer_stackitem.bringToTop(this.incubatebtn);
    wh.inventorycontainer_stackitem.bringToTop(this.incubatebtndis);

    this.sellallbtn.on(
      'pointerup',
      function () {
        this.showinventory_sellall();
        // this.plog.log('incubatebtndis', this);
      },
      this
    ); this.sellallbtn.on(
      'pointerover',
      function () {
        this.gameitf.gamemenutooltip(
          wh,
          this.sellallbtn,
          'Sell all StackItems in this category',
          'top'
        );
        // this.plog.log('incubatebtndis', this);
      },
      this
    );
    this.incubatebtndis.on(
      'pointerover',
      function () {
        if (this.rcvarsservice.activechar['map'] != 'home') {
          this.gameitf.gamemenutooltip(
            wh,
            this.incubatebtndis,
            'Use Egg at home',
            'top'
          );
        }
        // this.plog.log('incubatebtndis', this);
      },
      this
    );
    this.incubatebtn.on(
      'pointerup',
      function () {
        //this.plog.log('incubatebtn', this);
        if (this.rcvarsservice.activechar['map'] == 'home') {
          var tmpid =
            this.rcvarsservice.activechar['stackitem'][this.selecteditemno][
            'id'
            ];
          this.wh.registry.list.execmod.exec('openeggpass:' + tmpid, wh);
        }
      },
      this
    );

    shopbackbtn.displayWidth = 100;
    shopfwdbtn.displayWidth = 100;
    invendropbtn.displayWidth = 100;
    sellbtn.displayWidth = 100;
    donatebtn.displayWidth = 100;
    donateallbtn.displayWidth = 100;
    depositbtn.displayWidth = 100;
    shopbackbtn.displayHeight = 40;
    shopfwdbtn.displayHeight = 40;
    invendropbtn.displayHeight = 40;
    sellbtn.displayHeight = 40;
    donatebtn.displayHeight = 40;
    donateallbtn.displayHeight = 40;
    depositbtn.displayHeight = 40;
    sellanywhere.displayWidth = sellanywhere.displayHeight = 64;
    shine1.displayWidth = shine1.displayHeight = 64;
    shopbackbtn.setPosition(-110, 220);
    shopfwdbtn.setPosition(110, 220);
    invendropbtn.setPosition(-10, 220);
    sellbtn.setPosition(-10, 220);
    donatebtn.setPosition(-10, 220);
    donateallbtn.setPosition(0, -155);
    depositbtn.setPosition(-10, 220);
    this.sellanywhere.setPosition(-150, -200);
    this.sellanywhere.visible = false;
    this.shine1.setPosition(-150, -200);
    this.shine1.visible = false;

    wh.inventorycontainer_stackitem.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, wh.trs("f:attricontainer:Click Item to View Description===Click Item to View Description"), wh.fs["winguiitemdescr"]);
    wh.inventorycontainer_stackitem.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;
    this.invensize = wh.add.text(0, 0, '0/0', wh.fs["winguibardescr"]);
    wh.inventorycontainer_stackitem.add(this.invensize);
    this.invensize.setOrigin(1, 1);
    this.invensize.setPosition(150, -138);

    var no5 = wh.add.sprite(0, 0, "thesq", 'num');
    this.no5 = no5;
    no5.displayWidth = 18;
    no5.displayHeight = 18;
    no5.setPosition(52, 210);
    var no10 = wh.add.sprite(0, 0, "thesq", 'n10');
    this.no10 = no10;
    no10.displayWidth = 18;
    no10.displayHeight = 18;
    no10.setPosition(52, 230);

    var no5b = wh.add.sprite(0, 0, "thesq", 'numb');
    this.no5b = no5b;
    no5b.displayWidth = 18;
    no5b.displayHeight = 18;
    no5b.setPosition(52, 210);
    var no10b = wh.add.sprite(0, 0, "thesq", 'n10b');
    this.no10b = no10b;
    no10b.displayWidth = 18;
    no10b.displayHeight = 18;
    no10b.setPosition(52, 230);

    no5.setInteractive();
    no10.setInteractive();
    no5.removeAllListeners();
    no10.removeAllListeners();
    no5b.setInteractive();
    no10b.setInteractive();
    no5b.removeAllListeners();
    no10b.removeAllListeners();
    no5b.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        this,
        wh.trs("f:attricontainer:Deposit Items===Deposit Items"),
        'right'
      );
    });
    no10b.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        this,
        wh.trs("f:attricontainer:Deposit 10 of selected item===Deposit 10 of selected item"),
        'right'
      );
    });

    no5.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(wh, this, wh.trs("f:attricontainer:Drop items===Drop items"), 'right');
    });
    no10.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        this,
        wh.trs("f:attricontainer:Drop 10 of selected item===Drop 10 of selected item"),
        'right'
      );
    });
    wh.inventorycontainer_stackitem.add(no5);
    wh.inventorycontainer_stackitem.add(no10);
    wh.inventorycontainer_stackitem.add(no5b);
    wh.inventorycontainer_stackitem.add(no10b);

    var no5r = wh.add.sprite(0, 0, "thesq", 'numr');
    this.no5r = no5r;
    no5r.displayWidth = 18;
    no5r.displayHeight = 18;
    no5r.setPosition(52, 210);
    var no10r = wh.add.sprite(0, 0, "thesq", 'n10r');
    this.no10r = no10r;
    no10r.displayWidth = 18;
    no10r.displayHeight = 18;
    no10r.setPosition(52, 230);
    wh.inventorycontainer_stackitem.add(no5r);
    wh.inventorycontainer_stackitem.add(no10r);


    this.empty = wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 0);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    wh.inventorycontainer_stackitem.add(this.empty);
    wh.inventorycontainer_stackitem.bringToTop(this.empty);
    this.empty.visible = false;

    //drag


    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0]
            .inventorycontainer_stackitem
        );

        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.x;
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.y;
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0]
            .inventorycontainer_stackitem.dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .inventorycontainer_stackitem.dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .inventorycontainer_stackitem.dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .inventorycontainer_stackitem.dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0]
              .inventorycontainer_stackitem.dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.y = pointer.y;
        }
      },
      this
    );
    wh.inventorycontainer_stackitem.add(this.shopdragbtn);
    wh.inventorycontainer_stackitem.bringToTop(this.shopdragbtn);

    /*
    this.plog.log("dragsize",shopguibg.width, shopguibg.height);
    wh.inventorycontainer_stackitem.setSize(shopguibg.width, shopguibg.height);
    wh.inventorycontainer_stackitem.setInteractive();
    wh.input.on('mousemove', function(pointer) {
      //check here is a scene has a selected gameObject
      //if(this.selected) { //to be able to detect if user really select something.
          wh.setPosition(pointer.x, pointer.y);
      //}
    }, this);*/

    this.plog.log(
      'inventorycontainer_stackitem',
      wh.inventorycontainer_stackitem,
      width,
      height
    );
    wh.inventorycontainer_stackitem.setPosition(width / 2, height / 2);
    //move
    wh.inventorycontainer_stackitem.x = width - this.shopguibg.width / 2 + 30;

    this.title = wh.add.text(0, 0, 'STACK INVEN', wh.fs["winguititle"]);
    this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.inventorycontainer_stackitem.add(this.title);
    wh.inventorycontainer_stackitem.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -225);


    let loader = new Phaser.Loader.LoaderPlugin(wh);
    /*
    move to spritestack
    for (var vv in shopdata["stackitem"]) {
      var vdat = shopdata["stackitem"][vv];
      var itemdat = this.rcvarsservice.allstackitem[vdat.item];
      //this.plog.log("xxxx",itemdat,vdat.item,this.rcvarsservice);
      if (itemdat == undefined) continue;
      loader.image(vdat["item"], this.rcvarsservice.rc_baseurl + "stackitem/" + itemdat["sprite"] + ".png");

      this.plog.log("load shop ", vv, vdat, itemdat);
    }
    */

    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.inventorycontainer_stackitem.add(this.loading);
    wh.inventorycontainer_stackitem.bringToTop(this.loading);
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    var loadlist = ['stackitemtypeico'];
    for (var k in loadlist) {
      var key = loadlist[k];
      if (this.rcvarsservice.gameobj.textures.game.textures.list[key] == undefined) {

        loader.atlas(
          key + '',
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.png' +
          svversionurlstring,
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.json' +
          svversionurlstring
        );
      }
    }
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        this.showinventory_loaded(wh, shopdata);
      },
      this
    );
    loader.start();
  }

  showinventory_loaded(wh, shopdata, pagemode = 'normal') {
    this.firsttimerunfunc();
    this.wh.registry.list.gameitf.guiwindowclick(this.wh.inventorycontainer_stackitem);
    this.shopdata = shopdata;
    if (wh.registry.list.rcvarpass.activechar['map'] == 'home' || wh.registry.list.rcvarpass.activechar['map'] == 'ship1') {
      this.sellbtn.visible = false;
      this.donateallbtn.visible = false;
      this.donatebtn.visible = false;
      this.depositbtn.visible = true;
      this.invendropbtn.visible = false;
      this.no5r.visible = false;
      this.no10r.visible = false;
      this.no5.visible = false;
      this.no10.visible = false;
      this.no5b.visible = true;
      this.no10b.visible = true;
    } else {
      if (this.invenmode == 'sell') {
        this.title.setText("Sell Stacks");

        this.donateallbtn.visible = false;
        this.donatebtn.visible = false;
        this.sellbtn.visible = true;
        this.depositbtn.visible = false;
        this.invendropbtn.visible = false;
        this.no5r.visible = true;
        this.no10r.visible = true;
        this.no5.visible = false;
        this.no10.visible = false;
        this.no5b.visible = false;
        this.no10b.visible = false;
      } else if (this.invenmode == 'donate') {
        this.title.setText("Donate Stacks");

        this.donateallbtn.visible = true;
        this.donatebtn.visible = true;
        this.sellbtn.visible = false;
        this.depositbtn.visible = false;
        this.invendropbtn.visible = false;
        this.no5r.visible = false;
        this.no10r.visible = false;
        this.no5.visible = false;
        this.no10.visible = false;
        this.no5b.visible = false;
        this.no10b.visible = false;
      } else {
        this.donateallbtn.visible = false;
        this.donatebtn.visible = false;
        this.sellbtn.visible = false;
        this.depositbtn.visible = false;
        this.invendropbtn.visible = true;
        this.no5r.visible = false;
        this.no10r.visible = false;
        this.no5.visible = true;
        this.no10.visible = true;
        this.no5b.visible = false;
        this.no10b.visible = false;
      }
    }
    this.loadinghide();
    //this.plog.log('showinventory_loaded', wh, shopdata);
    //this.plog.log('showinventory_loaded filtertype', this.filtertype);
    //this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem);shine1
    this.sellanywhere.visible = false;
    this.sellallbtn.visible = false;
    this.donateallbtn.visible = false;

    this.incubatebtn.visible = false;
    this.incubatebtndis.visible = false;
    this.shine1.visible = false;
    if (wh.registry.list.rcreq.chk({ stackitem1: "sellstackitem", stackitem1_min: 1 })) {
      this.sellanywhere.visible = true;
      this.shine1.visible = true;
    }
    if (this.invenmode == 'sell') {
      this.sellanywhere.visible = false;
      this.shine1.visible = false;
    }
    var paddingl = -175;
    var paddingt = -140;
    this.stackarr['util'] = ['util', 'buildable'];
    this.stackarr['currency'] = ['currency'];
    this.stackarr['chest'] = ['chest', 'choicechest'];
    this.stackarr['egg'] = ['egg'];
    this.stackarr['questitems'] = ['questitems'];

    this.stackarr['upgradeitem'] = ['upgradeitem'];
    this.stackarr['skillbook'] = ['skillbook'];
    this.stackarr['scrolls'] = ['scrolls'];
    this.stackarr['rune'] = ['rune'];
    this.stackarr['gems'] = ['gems'];

    var sellallok = [];
    sellallok['scrolls'] = 1;
    sellallok['skillbook'] = 1;
    sellallok['egg'] = 1;
    sellallok['questitems'] = 1;
    sellallok['rune'] = 1;
    var paddingts = paddingt + 0;
    var iconwhsub = 30;
    var rowh = 40;
    var i = 0;
    for (var k in this.stackarr) {
      var v = this.stackarr[k];
      //this.plog.log('xxxxmergerex', k, v, i);

      this.submn[k] = this.wh.add.sprite(0, 0, 'stackitemtypeico', k, iconwhsub * i + 2 + paddingts);
      this.wh.inventorycontainer_stackitem.add(this.submn[k]);
      this.wh.inventorycontainer_stackitem.bringToTop(this.submn[k]);
      this.submn[k].displayWidth = this.submn[k].displayHeight = iconwhsub;
      this.submn[k].setPosition(
        paddingl,
        paddingt + ((iconwhsub + 3) * i + 35)
      );
      paddingts = paddingts + rowh;
      this.submn[k].setInteractive();
      this.submn[k].removeAllListeners();
      this.submn[k].name = k;
      this.submn[k].on('pointerup', function () {
        //this.plog.log('xxxxx', this);
        //this.scene.registry.list.stackinven.winguiltab.setPosition(this.x - 7, this.y);


        if (this.scene.registry.list.stackinven.filtertype == this.name) {
          this.scene.registry.list.stackinven.filtertype = '';
          //this.scene.registry.list.stackinven.winguiltab.visible = false;
        } else {
          this.scene.registry.list.stackinven.filtertype = this.name;
          // this.scene.registry.list.stackinven.winguiltab.visible = true;
          this.scene.inventorycontainer_stackitem.moveBelow(this.scene.registry.list.stackinven.winguiltab, this);
        }
        this.scene.registry.list.stackinven.showinventory_loaded(
          this.scene.registry.list.stackinven.wh,
          this.scene.registry.list.stackinven.shopdata
        );
      });
      this.submn[k].on('pointerover', function () {
        this.scene.registry.list.gameitf.gamemenutooltip(
          this.scene,
          this,
          this.scene.trs("t:stackitem_type:code:name:" + this.name + "===" + this.name),
          'left'
        );
      });
      if (this.filtertype != "" && this.filtertype == k) {
        //this.wh.registry.list.stackinven.winguiltab.setPosition(this.submn[k].x - 7, this.submn[k].y);
        if (this.wh.registry.list.stackinven.winguiltab.visible == true) {
          try { this.winguiltabani.remove(); } catch (e) { console.log(e); }
          this.winguiltabani = this.wh.tweens.add(
            {
              targets: this.wh.registry.list.stackinven.winguiltab,
              y: this.submn[k].y,
              x: this.submn[k].x - 7,
              displayWidth: 52,
              ease: 'Power3',
              duration: 200,
              delay: 0,
              onComplete: function () {
              }
            }, this
          );
        } else {
          this.wh.registry.list.stackinven.winguiltab.y = this.submn[k].y;
          this.wh.registry.list.stackinven.winguiltab.x = this.submn[k].x - 7;
          this.wh.registry.list.stackinven.winguiltab.displayWidth = 1;

          try { this.winguiltabani.remove(); } catch (e) { console.log(e); }
          this.winguiltabani = this.wh.tweens.add(
            {
              targets: this.wh.registry.list.stackinven.winguiltab,
              //y: this.submn[k].y,
              displayWidth: 52,
              ease: 'Power3',
              duration: 400,
              delay: 0,
              onComplete: function () {
              }
            }, this
          );
        }
        this.wh.registry.list.stackinven.winguiltab.visible = true;
        this.wh.inventorycontainer_stackitem.moveBelow(this.wh.registry.list.stackinven.winguiltab, this.submn[k]);
      } else {

      }
      i++;
    }
    if (this.filtertype == "") {
      if (this.wh.registry.list.stackinven.winguiltab.visible == true) {
        try { this.winguiltabani.remove(); } catch (e) { console.log(e); }
        this.winguiltabani = this.wh.tweens.add(
          {
            targets: this.wh.registry.list.stackinven.winguiltab,
            displayWidth: 1,
            ease: 'Power3',
            duration: 200,
            delay: 0,
            onComplete: function () {
              //console.log("twcomplete", this);
              this.winguiltab.visible = false;
              this.winguiltab.displayWidth = 52;
            }.bind(this,)
          }, this
        );
      }
    }
    var margintop = -90;
    var marginleft = -117;
    var cxpos = 0;
    var crow = 0;
    var i = 0;
    for (var i = 0; i < 200; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
      }
    }
    for (var i = 0; i < 200; i++) {
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = false;
      }
    }

    for (var i = 0; i < 200; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].destroy(); // = undefined;
        this.shopitemlist[i] = undefined;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].destroy(); // = undefined;
        this.shopitemlistp[i] = undefined;
      }
    }
    //this.plog.log('pagemode', pagemode, this.currentresppage);
    if (pagemode == 'normal') {
      this.currentresppage = 1;
    }
    if (pagemode == 'retainpage') {
      if (this.currentresppage == 0 || this.currentresppage == undefined)
        this.currentresppage = 1;
    }
    var countslot = 0;
    var countslotforslot = 0;
    for (const countslotk in this.shopdata['stackitem']) {
      var countslotitemdat = this.rcvarsservice.allstackitem[this.shopdata['stackitem'][countslotk]["item"]];
      countslot++;
      if (countslotitemdat == undefined) {
        //this.plog.log("countslotitemdat undefined", this.shopdata['stackitem'][countslotk]["item"], countslotitemdat);
        continue;
      }
      if (this.rcvarsservice.stackitemtype[countslotitemdat["pcode"]]["iscalinven"] != "NO")
        countslotforslot++
    }
    var i = 0;
    //this.plog.log("showinven-updateslotdsp",this.shopdata["stackitem"],this.shopdata["stackitem"].length);
    this.invensize.setText(
      'Slot ' +
      countslotforslot +
      '/' +
      this.rcvarsservice.activechar['stackinvensize']
    );
    if (
      countslot >=
      this.rcvarsservice.activechar['stackinvensize']
    ) {
      try {
        this.invensize.setStyle(wh.fs["winguibardescrred"]);
      } catch (e) {
        this.plog.warn(e);
      }
    } else {
      try {
        this.invensize.setStyle(wh.fs["winguibardescr"]);

      } catch (e) {
        this.plog.warn(e);
      }
    }
    if (this.filtertype != undefined && this.filtertype != "" && countslotforslot != 0) {
      if (sellallok[this.filtertype] == 1 && this.invenmode == 'sell') {
        this.sellallbtn.visible = true;
      }
      if (sellallok[this.filtertype] == 1 && this.invenmode == 'donate') {
        this.donateallbtn.visible = true;
      }
    }
    var sorted = this.sortdata(shopdata['stackitem']);
    //console.log("sorted",sorted);
    for (var vv in sorted) {
      //
      var sortedi = sorted[vv];
      //console.log("sortedi",sortedi);
      var vdat = shopdata['stackitem'][sortedi[0]];//sorted[vv[0]];// shopdata['stackitem'][vv];
      //console.log(vv,vdat);
      var itemdat = this.rcvarsservice.allstackitem[vdat.item];
      //stackarr

      //this.plog.log("loaded shop ", vv, vdat, itemdat);
      if (itemdat == undefined) continue;
      if (this.filtertype != '' && !this.stackarr[this.filtertype].includes(itemdat['pcode']))
        continue;
      var tmp = wh.add.sprite(32, 32, 'stackitem_tp', itemdat['code']);
      //var tmp = wh.add.image(32, 32, itemdat["code"]);
      tmp.displayWidth = 48;
      tmp.displayHeight = 48;
      wh.inventorycontainer_stackitem.add(tmp);

      tmp.setPosition(marginleft + cxpos * 59, margintop + crow * 59);
      this.shopitemlist[i] = tmp;
      if (this.shopitemlistp[i] == undefined) {
        this.shopitemlistp[i] = wh.add.text(
          0, 0,
          '' + this.gameitf.humannumber(vdat['amnt'], 0),
          wh.fs["wingui_amnt"]
        );
        wh.inventorycontainer_stackitem.add(this.shopitemlistp[i]);
        this.shopitemlistp[i].setPosition(tmp.x - 27, tmp.y + 11);
      }
      this.shopitemlist[i].visible = true;
      this.shopitemlistp[i].visible = true;
      this.shopitemlist[i].setInteractive();
      this.shopitemlist[i].removeAllListeners();
      this.shopitemlist[i].name =
        '' + vdat['item'] + '-' + i + '-' + vdat['id'];
      this.shopitemlist[i].on(
        'pointerup',
        function (pointer, localX, localY, event) {
          this.scene.plog.log('shop itemclick this', this);
          this.scene.plog.log('shop itemclick', pointer, localX, localY, event);
          this.scene.registry.list.stackinven.showinventory_itemclick(
            this.scene,
            this
          );
        }
      );
      cxpos++;
      if (cxpos > 4) {
        cxpos = 0;
        crow++;
      }
      i++;
      if (i % 25 == 0) {
        cxpos = 0;
        crow = 0;
      }
    }
    //this.plog.log('shop i count', i);
    if (i > 25) {
      //pages

      this.respperpage = 25;
      this.resptotalpage = Math.ceil(i / 25);
    }

    if (i == 0) {
      this.empty.visible = true;
    } else {
      this.empty.visible = false;
    }

    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );

    this.showinventory_showpage(wh, 'current');
    this.depositbtn.removeAllListeners();
    this.depositbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop invendropbtn', this);
        this.showinventory_deposit(this, 1);
      },
      this
    );
    this.invendropbtn.removeAllListeners();
    this.invendropbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop invendropbtn', this);
        this.showinventory_drop(this.wh, 1);
      },
      this
    );
    this.no5r.removeAllListeners();
    this.no10r.removeAllListeners();
    this.no5r.setInteractive();
    this.no10r.setInteractive();
    this.no5r.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(wh, this, wh.trs("f:attricontainer:Sell items===Sell items"), 'right');
    });
    this.no10r.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        this,
        wh.trs("f:attricontainer:Sell 10 of selected item===Sell 10 of selected item"),
        'right'
      );
    });
    this.no5r.on(
      'pointerup',
      async function () {
        //this.showinventory_sell(this, 5);

        //this.plog.log(this);
        if (this.selecteditemno == '' || this.selecteditemno == undefined)
          return;
        var tmpid =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno]['id'];
        var tmpamnt =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno][
          'amnt'
          ];
        var tmpitem =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno][
          'item'
          ];
        var tmpsellprice =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno][
          'sellprice'
          ];
        var iteminfo2 = this.rcvarsservice.allstackitem[tmpitem];

        var amnt =
          await this.rcvarsservice.gameobj.registry.list.rctoast.rcenternum(
            this.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
            wh.trs("f:attricontainer:Confirm Sellling===Confirm Sellling"),
            wh.trs("f:attricontainer:Selling===Selling") + ' ' + iteminfo2['name'] + '\n' + wh.trs("f:attricontainer:How many to sell?===How many to sell?"),
            tmpamnt
          );
        amnt = Number(amnt);
        if (amnt < 1) return;
        this.showinventory_sell(this, amnt);
      },
      this
    );
    this.no10r.on(
      'pointerup',
      function () {
        this.showinventory_sell(this, 10);
      },
      this
    );

    this.no5.setInteractive();
    this.no10.setInteractive();
    this.no5b.setInteractive();
    this.no10b.setInteractive();
    this.no5.removeAllListeners();
    this.no10.removeAllListeners();
    this.no5b.removeAllListeners();
    this.no10b.removeAllListeners();
    this.no5.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        this,
        wh.trs("f:attricontainer:Drop some of selected item===Drop some of selected item"),
        'right'
      );
    });
    this.no10.on('pointerover', function () {
      wh.registry.list.gameitf.gamemenutooltip(
        wh,
        this,
        wh.trs("f:attricontainer:Drop 10 of selected item===Drop 10 of selected item"),
        'right'
      );
    });
    this.no5.on(
      'pointerup',
      async function () {
        //this.showinventory_drop(this, 5);
        //this.plog.log(this);
        if (this.selecteditemno == '' || this.selecteditemno == undefined)
          return;
        var tmpid =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno]['id'];
        var tmpamnt =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno][
          'amnt'
          ];
        var tmpitem =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno][
          'item'
          ];
        var tmpsellprice =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno][
          'sellprice'
          ];
        var iteminfo2 = this.rcvarsservice.allstackitem[tmpitem];

        var amnt =
          await this.rcvarsservice.gameobj.registry.list.rctoast.rcenternum(
            this.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
            wh.trs("f:attricontainer:Confirm Dropping===Confirm Dropping"),
            wh.trs("f:attricontainer:Dropping===Dropping") + ' ' + iteminfo2['name'] + '\n' + wh.trs("f:attricontainer:How many to drop?===How many to drop?")
          );
        amnt = Number(amnt);
        if (amnt < 1) return;
        this.showinventory_drop(this.wh, amnt);
        // var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],"Confirm Sell", "Sell " + amnt + " of:\n" + iteminfo2["name"] + " for " + (tmpsellprice * amnt) + " gold");
      },
      this
    );
    this.no10.on(
      'pointerup',
      function () {
        this.showinventory_drop(this.wh, 10);
      },
      this
    );

    this.no5b.on(
      'pointerup',
      async function () {
        //this.showinventory_drop(this, 5);
        //this.plog.log(this);
        if (this.selecteditemno == '' || this.selecteditemno == undefined)
          return;
        var tmpid =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno]['id'];
        var tmpamnt =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno][
          'amnt'
          ];
        var tmpitem =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno][
          'item'
          ];
        var tmpsellprice =
          this.rcvarsservice.activechar['stackitem'][this.selecteditemno][
          'sellprice'
          ];
        var iteminfo2 = this.rcvarsservice.allstackitem[tmpitem];

        var amnt =
          await this.rcvarsservice.gameobj.registry.list.rctoast.rcenternum(
            this.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
            wh.trs("f:attricontainer:Confirm Deposit===Confirm Deposit"),
            wh.trs("f:attricontainer:Depositing===Depositing") + ' ' + iteminfo2['name'] + '\n' + wh.trs("f:attricontainer:How many to deposit?===How many to deposit?"),
            tmpamnt
          );
        amnt = Number(amnt);
        if (amnt < 1) return;
        this.showinventory_deposit(this, amnt);
        // var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],"Confirm Sell", "Sell " + amnt + " of:\n" + iteminfo2["name"] + " for " + (tmpsellprice * amnt) + " gold");
      },
      this
    );
    this.no10b.on(
      'pointerup',
      function () {
        this.showinventory_deposit(this, 10);
      },
      this
    );

    this.sellbtn.removeAllListeners();
    this.sellbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop sellbtn', this);
        this.showinventory_sell(this, 1);
      },
      this
    );

    this.donatebtn.removeAllListeners();
    this.donatebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop donate', this);
        this.showinventory_donate(this, 1);
      },
      this
    );
    this.donateallbtn.removeAllListeners();
    this.donateallbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop donateall', this);
        this.showinventory_donateall(this.wh, 1);
      },
      this
    );
    this.wh.registry.list.gameitf.guiwindowclick(
      this.wh.inventorycontainer_stackitem
    );
    this.wh.inventorycontainer_stackitem.visible = true;
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);
    if (this.sellanywhere.visible == true) {
      this.wh.registry.list.tut.show(this.wh, "stackinven_sellanywhere");
    }
  }

  async showinventory_sellall() {
    this.plog.log('showinventory_sellall');
    if (this.filtertype == undefined || this.filtertype == "") return;
    var cateinfo = this.wh.trs("t:stackitem_type:code:name:" + this.filtertype + "===" + this.filtertype);

    //console.log(cateinfo);
    var confirm =
      await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
        this.rcvarsservice.gameobj.scene.scenes[0],
        this.rcvarsservice.gameobj.scene.scenes[0].trs("f:attricontainer:Confirm Selling===Confirm Selling"),
        "Sell all items in category: " + cateinfo + "?"
      );
    //this.plog.log('rcconfirmresult', confirm);
    //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Sell", "Sell " + amnt + " of:\n" + iteminfo2["name"] + " for " + (tmpsellprice * amnt) + " gold");
    if (confirm != 'ok') {
      this.loadinghide();
      return;
    }

    this.rcvarsservice.gameobj.registry.list.snd.snd("coins");

    var res = await this.rchttp.sellstackitemallcate(this.filtertype);
    await this.wh.registry.list.rchttp.updatecharinfo();
  }
  async showinventory_sell(wh, amnt) {
    this.plog.log('showinventory_sell', wh, Number(this.selecteditemno));
    if (this.selecteditemno == '') return;
    if (
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno] == undefined
    ) {
      this.plog.log(
        'showinventory_sell skip dropped',
        this.selecteditemno,
        wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]
      );
      return; //dropped
    }
    this.loadingshow();
    var tmpid =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['id'];
    var tmpamnt =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['amnt'];
    var tmpitem =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['item'];
    var tmpsellprice =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno][
      'sellprice'
      ];
    var iteminfo2 = this.rcvarsservice.allstackitem[tmpitem];
    if (amnt != 1) {
      var confirm =
        await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
          this.rcvarsservice.gameobj.scene.scenes[0],
          this.rcvarsservice.gameobj.scene.scenes[0].trs("f:attricontainer:Confirm Selling===Confirm Selling"),
          this.rcvarsservice.gameobj.scene.scenes[0].trs("f:attricontainer:Selling===Selling") + ' ' + amnt + ' of:\n' + iteminfo2['name'] + ' for ' + tmpsellprice * amnt + ' gold'
        );
      //this.plog.log('rcconfirmresult', confirm);
      //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Sell", "Sell " + amnt + " of:\n" + iteminfo2["name"] + " for " + (tmpsellprice * amnt) + " gold");
      if (confirm != 'ok') {
        this.loadinghide();
        return;
      }
    }
    this.rcvarsservice.gameobj.registry.list.gameitf.osdflash(
      this.rcvarsservice.gameobj.scene.scenes[0],
      [{ txt: 'Gold +' + tmpsellprice * amnt, col: 'green' }]
    );
    this.plog.log('showinventory_sell', wh.rcvarsservice.activechar);
    this.rcvarsservice.gameobj.registry.list.snd.snd("coins");

    var tmpid =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['id'];
    var tmpamnt =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['amnt'];
    var res = await this.rchttp.sellstackitem(tmpid, amnt);
    await this.wh.registry.list.rchttp.updatecharinfo();

    this.loadinghide();
    if (tmpamnt <= 1) {
      this.selecteditemno = '';
    }
    this.loadinghide();
  }

  async showinventory_donate(wh, amnt) {
    this.plog.log('showinventory_donate', wh, Number(this.selecteditemno));
    if (this.selecteditemno == '') return;
    if (
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno] == undefined
    ) {
      this.plog.log(
        'showinventory_donate skip dropped',
        this.selecteditemno,
        wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]
      );
      return; //dropped
    }
    this.loadingshow();
    var tmpid =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['id'];
    var tmpamnt =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['amnt'];
    var tmpitem =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['item'];
    var tmpsellprice =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno][
      'sellprice'
      ];
    var iteminfo2 = this.rcvarsservice.allstackitem[tmpitem];
    if (amnt != 1) {
      var confirm =
        await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
          this.rcvarsservice.gameobj.scene.scenes[0],
          this.rcvarsservice.gameobj.scene.scenes[0].trs("f:attricontainer:Confirm Donate===Confirm Donate"),
          this.rcvarsservice.gameobj.scene.scenes[0].trs("f:attricontainer:Donate===Donate") + ' ' + amnt + ' of:\n' + iteminfo2['name'] + ' for ' + tmpsellprice * amnt + ' credits'
        );
      //this.plog.log('rcconfirmresult', confirm);
      //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Sell", "Sell " + amnt + " of:\n" + iteminfo2["name"] + " for " + (tmpsellprice * amnt) + " gold");
      if (confirm != 'ok') {
        this.loadinghide();
        return;
      }
    }
    /*this.rcvarsservice.gameobj.registry.list.gameitf.osdflash(
      this.rcvarsservice.gameobj.scene.scenes[0],
      [{ txt: 'Gold +' + tmpsellprice * amnt, col: 'green' }]
    );*/
    this.plog.log('showinventory_donate', wh.rcvarsservice.activechar);
    this.rcvarsservice.gameobj.registry.list.snd.snd("coins");

    var tmpid =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['id'];
    var tmpamnt =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['amnt'];
    var res = await this.rchttp.donatestackitem(tmpid, amnt);
    await this.wh.registry.list.rchttp.updatecharinfo();
    this.wh.registry.list.wsrc.sendeffect("donate", "ixbeff137_b");

    this.loadinghide();
    if (tmpamnt <= 1) {
      this.selecteditemno = '';
    }
    this.loadinghide();
  }

  async showinventory_donateall(wh) {
    //console.log('showinventory_donateall', wh, Number(this.selecteditemno));
    if (this.filtertype == undefined || this.filtertype == "") return;
    var cateinfo = this.wh.trs("t:stackitem_type:code:name:" + this.filtertype + "===" + this.filtertype);



    //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Sell", "<p>Sell this items?</p><p>" + iteminfo2["name"] + " lv." + iteminfo2["level"] + "</p><p>For " + tmpsellprice + " gold");
    var confirm =
      await wh.registry.list.rctoast.rcconfirm(
        wh.registry.list.phaserGame.scene.scenes[0],
        wh.registry.list.phaserGame.scene.scenes[0].trs("f:inventorycontainer:Confirm Donate===Confirm Donate"),
        'Donate ALL StackItems in category? \n' +
        cateinfo
      );

    if (confirm != 'ok') return;
    /*this.rcvarsservice.gameobj.registry.list.gameitf.osdflash(
      this.rcvarsservice.gameobj.scene.scenes[0],
      [{ txt: 'Gold +' + tmpsellprice, col: 'green' }]
    );*/
    wh.registry.list.snd.snd("coins");

    this.plog.log('showinventory_donateall', wh.registry.list.rcvarpass.activechar);
    this.loadingshow();
    var res = await this.rchttp.donatestackitemallcate(this.filtertype);
    await wh.registry.list.rcvarpass.setactivechar(
      res,
      wh.registry.list.rcvarpass.activechar['id']
    );
    this.plog.log('showinventory_donateall res', res, wh);
    wh.registry.list.gameitf.charinfoupdated(
      wh
    );
    wh.registry.list.wsrc.sendeffect("donate", "ixbeff137_b");

    this.loadinghide();
    //if (tmpamnt<=1) {
    //}
  }
  async useallselecteditem() {
    this.loadingshow();
    this.plog.log('useallselecteditem', this.wh);
    if (this.selecteditemno == '') return;
    if (
      this.wh.registry.list.rcvarpass.activechar['stackitem'][
      this.selecteditemno
      ] == undefined
    ) {
      this.plog.log('useallselecteditem skip not exists', this.selecteditemno);
      this.loadinghide();
      return; //dropped
    }
    this.plog.log('useallselecteditem', this.selecteditemno);

    var tmpid = this.wh.registry.list.rcvarpass.activechar['stackitem'][this.selecteditemno]['id'];
    var tmpitem = this.wh.registry.list.rcvarpass.activechar['stackitem'][this.selecteditemno]['item'];
    var iteminfo2 = this.wh.registry.list.rcvarpass.allstackitem[tmpitem];
    var module = iteminfo2['module'];
    //this.plog.log("module",module); return;
    var modulea = module.split(':');
    //this.plog.log("ddd"); return;
    var res = await this.rchttp.getcommon2("useallstackitem", { useid: tmpid })
    await this.wh.registry.list.rchttp.updatecharinfo();
    this.loadinghide();
  }
  async useselecteditem() {
    this.loadingshow();
    this.plog.log('useselecteditem');//, this.wh);
    if (this.selecteditemno == '') return;
    if (
      this.wh.registry.list.rcvarpass.activechar['stackitem'][
      this.selecteditemno
      ] == undefined
    ) {
      this.plog.log('showinventory_drop skip not exists', this.selecteditemno);
      this.loadinghide();
      return; //dropped
    }
    this.plog.log('useselecteditem', this.selecteditemno);

    var tmpid =
      this.wh.registry.list.rcvarpass.activechar['stackitem'][
      this.selecteditemno
      ]['id'];
    var tmpitem =
      this.wh.registry.list.rcvarpass.activechar['stackitem'][
      this.selecteditemno
      ]['item'];
    var iteminfo2 = this.wh.registry.list.rcvarpass.allstackitem[tmpitem];
    var module = iteminfo2['module'];
    //this.plog.log("module",module); return;
    var modulea = module.split(':');
    if (modulea[0] == 'cli') {
      this.plog.log('climodule', modulea, this.wh);
      //var res = await this.rchttp.consumestackitem(tmpid);
      if (iteminfo2["code"] != "teleportkey2" && modulea[1] != "element") {
        var res = await this.rchttp.getcommon2("consumestackitem", { useid: tmpid })

        if (res == 'ok') {
          this.wh.registry.list.execmod.exec(
            modulea[1] + ':' + modulea[2] + ':' + modulea[3],
            this.wh
          );
        }
        await this.wh.registry.list.rchttp.updatecharinfo();
      } else {
        this.wh.registry.list.execmod.exec(
          modulea[1] + ':' + modulea[2] + ':' + modulea[3],
          this.wh
        );
      }
    } else if (modulea[0] == "cli2step") {
      //don't "consume" before executed, execmod will consume the item
      this.wh.registry.list.execmod.exec(
        modulea[1] + ':' + modulea[2] + ':' + modulea[3],
        this.wh
      );

    } else {
      //this.plog.log("ddd"); return;
      //var res = await this.rchttp.usestackitem(tmpid);
      var res = await this.rchttp.getcommon2("usestackitem", { useid: tmpid })
      await this.wh.registry.list.rchttp.updatecharinfo();
      /*
            await this.rcvarsservice.setactivechar(
              res,
              this.wh.registry.list.rcvarpass.activechar['id']
            );
            //this.plog.log("showinventory_drop res", res, wh.rcvarsservice);
            //re-select item if still exists
            await this.wh.registry.list.gameitf.charinfoupdated(this.wh);
            this.wh.registry.list.gameitf.guiwindowclick(
              this.wh.inventorycontainer_stackitem
            );*/
    }
    this.loadinghide();
  }
  async showinventory_drop(wh, amnt) {
    this.plog.log('showinventory_drop', this, wh, amnt);
    if (this.selecteditemno == '') return;
    if (
      this.rcvarsservice.activechar['stackitem'][this.selecteditemno] == undefined
    ) {
      this.plog.log(
        'showinventory_drop skip dropped',
        this.selecteditemno,
        this.rcvarsservice.activechar['stackitem'][this.selecteditemno]
      );
      return; //dropped
    }
    this.loadingshow();
    var tmpid =
      this.rcvarsservice.activechar['stackitem'][this.selecteditemno]['id'];
    var tmpamnt =
      this.rcvarsservice.activechar['stackitem'][this.selecteditemno]['amnt'];
    var tmpitem =
      this.rcvarsservice.activechar['stackitem'][this.selecteditemno]['item'];
    if (tmpitem == 'supp') {
      this.rcvarsservice.gameobj.registry.list.rctoast.rcalert(
        wh,
        wh.trs("f:attricontainer:Can't drop===Can't drop"),
        wh.trs("f:attricontainer:Cat Fur can't be dropped===Cat Fur can't be dropped")
      );
      this.loadinghide();
      return;
    }
    var tmpitemchkchest = (tmpitem + "").indexOf("chest");
    //this.plog.log("tmpitemchkchest",tmpitemchkchest);
    if (tmpitemchkchest != -1) {
      this.rcvarsservice.gameobj.registry.list.rctoast.rcalert(
        wh,
        wh.trs("f:attricontainer:Can't drop===Can't drop"),
        wh.trs("f:attricontainer:Chests can't be dropped===Chests can't be dropped")
      );
      this.loadinghide();
      return;
    }
    var iteminfo2 = this.rcvarsservice.allstackitem[tmpitem];
    if (amnt != 1) {
      //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Drop", "<p>Remove " + amnt + " of:</p><p>" + iteminfo2["name"] + "</p>");
      //if (!confirm) return;
      var confirm =
        await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
          wh,
          wh.trs("f:attricontainer:Confirm Dropping===Confirm Dropping"),
          'Remove ' + amnt + ' of:\n' + iteminfo2['name'] + ''
        );
      if (confirm != 'ok') {
        this.loadinghide();
        return;
      }
    }
    this.plog.log('showinventory_drop', this.rcvarsservice.activechar);
    this.loadingshow();
    var res = await this.rchttp.dropstackitem(tmpid, amnt);
    await this.rcvarsservice.setactivechar(
      res,
      this.rcvarsservice.activechar['id']
    );
    if (iteminfo2["code"] == "mobdetect") {
      //this.plog.error("drop mobdetect");
      var bossloc = await wh.registry.list.rchttp.getcommon2('shareboss_info', {});
      await this.rcvarsservice.gameobj.registry.list.rctoast.rcalert(
        wh,
        "Mob Sniffer said..",
        bossloc + ""
      );
    }
    //this.plog.log("showinventory_drop res", res, wh.rcvarsservice);
    //re-select item if still exists
    await this.rcvarsservice.gameobj.registry.list.gameitf.charinfoupdated(
      this.rcvarsservice.gameobj.scene.scenes[0]
    );
    this.rcvarsservice.gameobj.registry.list.snd.snd("drop");
    this.loadinghide();
    if (
      this.rcvarsservice.activechar['stackitem'][this.selecteditemno] != undefined
    ) {
      this.plog.log('select same item since it still exists after drop');
      this.selecteditemno = this.selecteditemno;
      this.selectcircle.visible = true;
    } else {
      this.selecteditemno = '';
      this.selectcircle.visible = false;
    }

    /*if (tmpamnt == 1) {
      this.selecteditemno = "";
    }*/
  }

  async showinventory_deposit(wh, amnt) {
    this.plog.log('showinventory_deposit', wh, amnt);
    if (this.selecteditemno == '') return;
    if (
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno] == undefined
    ) {
      this.plog.log(
        'showinventory_deposit skip dropped',
        this.selecteditemno,
        wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]
      );
      return; //dropped
    }
    var tmpid =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['id'];
    var tmpamnt =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['amnt'];
    var tmpitem =
      wh.rcvarsservice.activechar['stackitem'][this.selecteditemno]['item'];
    var iteminfo2 = this.rcvarsservice.allstackitem[tmpitem];
    if (amnt != 1) {
      //var confirm = await this.rcvarsservice.gameobj.registry.list.rctoast.confirm("Confirm Drop", "<p>Remove " + amnt + " of:</p><p>" + iteminfo2["name"] + "</p>");
      //if (!confirm) return;
      var confirm =
        await this.rcvarsservice.gameobj.registry.list.rctoast.rcconfirm(
          wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0],
          wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].trs("f:attricontainer:Confirm Deposit===Confirm Deposit"),
          wh.rcvarsservice.gameobj.registry.list.phaserGame.scene.scenes[0].trs("f:attricontainer:Confirm Deposit===Confirm Deposit") + ' ' + amnt + ' of:\n' + iteminfo2['name'] + ''
        );
      if (confirm != 'ok') return;
    }
    this.plog.log('showinventory_deposit', wh.rcvarsservice.activechar);
    this.loadingshow();
    var res = await this.rchttp.depositstackitem(tmpid, amnt);
    await this.wh.registry.list.rchttp.updatecharinfo();

    this.loadinghide();
    if (tmpamnt == 1) {
      this.selecteditemno = '';
    }
  }
  showinventory_itemclick(wh, item) {
    this.plog.log('showinventory_itemclick', wh, item);
    this.plog.log('showinventory_itemclick item.name', item.name);
    //this.plog.log("showinventory_itemclick shopitemlist", namea, this.shopitemlist);
    this.selectcircle.visible = true;
    var namea = item.name.split('-');
    wh.registry.list.stackinven.selecteditemno = namea[2];
    this.selectcircle.displayWidth = 52;
    this.selectcircle.displayHeight = 52;
    this.selectcircle.setPosition(item.x, item.y);
    //wh.inventorycontainer_stackitem.sendToBack(wh.shopguibg);
    wh.inventorycontainer_stackitem.bringToTop(this.selectcircle);
    wh.inventorycontainer_stackitem.bringToTop(this.shopitemlist[namea[1]]);
    wh.inventorycontainer_stackitem.bringToTop(this.shopitemlistp[namea[1]]);
    this.plog.log(
      'showinventory_itemclick selectcircle',
      namea,
      item,
      wh.registry.list.rcvarpass.activechar
    );
    var iteminfo = wh.registry.list.rcvarpass.activechar['stackitem'][namea[2]];
    var iteminfo2 = wh.registry.list.rcvarpass.allstackitem[namea[0]];
    this.useallbtn.visible = false;

    if (iteminfo == undefined) {
      this.plog.log("ERROR: showinventory_itemclick, iteminfo undefined");
      return;
    }
    if (iteminfo2 == undefined) {
      this.plog.log("ERROR: showinventory_itemclick, iteminfo2 undefined");
      return;
    }
    if (iteminfo2['module'] != undefined && iteminfo2['module'] != '') {
      this.plog.log("showinventory_itemclick chk", iteminfo2, iteminfo)
      if (iteminfo2["pcode"] == "chest") {
        this.useallbtn.visible = true;
        this.useitembtn.visible = false;
        this.useitembtndis.visible = false;
      } else {
        this.useallbtn.visible = false;
        this.useitembtn.visible = true;
        this.useitembtndis.visible = false;
      }
    } else {
      this.useitembtn.visible = false;
      this.useitembtndis.visible = true;
    }
    this.wh.registry.list.rcosd.show(wh, 'stackitem', iteminfo2, iteminfo);

    if (iteminfo2['pcode'] == 'egg') {
      if (this.rcvarsservice.activechar['map'] != 'home') {
        this.incubatebtn.visible = false;
        this.incubatebtndis.visible = true;
      } else {
        this.incubatebtn.visible = true;
        this.incubatebtndis.visible = false;
      }
    } else {
      this.incubatebtn.visible = false;
      this.incubatebtndis.visible = true;
    }

    //this.selectcircle.width=
    //this.plog.log("xxxx", iteminfo2);

    if (this.invenmode == 'sell') {
      this.itemdescr.setText(
        wh.gl(iteminfo2['name'] +
          ' x' +
          iteminfo['amnt'] +
          ' sell 1 for ' +
          iteminfo['sellprice'] +
          ' gold')
      );
    } else {
      this.itemdescr.setText(
        iteminfo2['name'] + ' x' + iteminfo['amnt'] + " " + wh.trs("f:attricontainer:click Drop to DROP 1===click Drop to DROP 1")
      );
    }
    wh.registry.list.rchttp.getcommon2('removeisnew_stackitem', {
      itemid: iteminfo['id'],
    });
    wh.registry.list.rcvarpass.activechar['stackitem'][namea[2]]['isnew'] = 0;
  }
  async showinventory_close(wh) {
    if (wh.inventorycontainer_stackitem != undefined)
      wh.inventorycontainer_stackitem.visible = false;
    await wh.registry.list.gameitf.closegui(wh, wh.inventorycontainer_stackitem);
    if (wh.inventorycontainer_stackitem == undefined) {
      this.plog.warn("showinventory_close wh.inventorycontainer_stackitem undefined;");
      return;
    }

    for (const k in this.wh.inventorycontainer_stackitem.list) {
      try {
        await this.wh.inventorycontainer_stackitem.remove(this.wh.inventorycontainer_stackitem.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! inventorycontainer_stackitem.destroy() ", e);
      }
    }
    for (const k in this.wh.inventorycontainer_stackitem.list) {
      try {
        await this.rcvarsservice.gameobj.scene.scenes[0].inventorycontainer_stackitem.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! inventorycontainer_stackitem.destroy() ", e);
      }
    }
    try {
      await this.wh.inventorycontainer_stackitem.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! inventorycontainer_stackitem.destroy() ", e);
    }
    try {
      this.wh.inventorycontainer_stackitem.visible = false;
    } catch (e) {
      this.plog.log("ERROR! inventorycontainer_stackitem.visible=false;", e);
    }
    wh.inventorycontainer_stackitem = undefined;
  }
  showinventory_showpage(gc, viewpage) {
    this.selectcircle.visible = false;
    this.useitembtn.visible = false;
    this.useitembtndis.visible = true;
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 200; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = false;
      }
      if (this.shopitemlistp[i] != undefined) {
        this.shopitemlistp[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );
    var griddat = [];
    var griddatf = [];
    for (var i = numfrom; i < numto; i++) {
      if (this.shopitemlist[i] != undefined) {
        this.shopitemlist[i].visible = true;
        griddat.push(this.shopitemlist[i]);
        griddatf.push(this.shopitemlist[i]);

        this.shopitemlistp[i].visible = true;
        var namea = this.shopitemlist[i].name.split('-');
        var isnewdata =
          this.wh.registry.list.rcvarpass.activechar['stackitem'][namea[1]];
        //this.plog.log("xxx1", isnewdata);
        if (isnewdata == undefined) continue;
        if (isnewdata['isnew'] == 1) {
          this.wh.registry.list.notif.show(this.wh, this.shopitemlist[i]);
        }
      }
    }

    for (var k in this.stackarr) {
      var v = this.stackarr[k];
      griddat.push(this.submn[k]);
    }
    griddat.push(this.sellanywhere);
    griddat.push(this.useitembtn);
    griddat.push(this.useallbtn);
    griddat.push(this.sellallbtn);
    griddat.push(this.incubatebtn);
    griddat.push(this.invendropbtn);
    griddat.push(this.sellbtn);
    griddat.push(this.donatebtn);
    griddat.push(this.donateallbtn);
    griddat.push(this.depositbtn);

    griddat.push(this.shopclosebtn);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);
    this.wh.registry.list.gp.guigrid(griddat, griddatf);
  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.showinventory_showpage(null, "");
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
