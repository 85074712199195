import { Injectable } from '@angular/core';
//import { Observable, Subject, EMPTY, of, interval } from 'rxjs';
/*import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { map, catchError, distinctUntilChanged, pairwise, tap, delay, first, takeLast, distinct, switchMap } from 'rxjs/operators';*/
//import { Observable, Subject, throwError, timer } from 'rxjs';
//import { Observable, Subject } from 'rxjs';
//import { mergeMap, finalize } from 'rxjs/operators';
//import { webSocket } from "rxjs/webSocket"; // for RxJS 6, for v5 use Observable.webSocket
//import { Observable } from 'rxjs';
//import { catchError, retryWhen } from 'rxjs/operators';
//import { AppService } from './app.service';
///import { interval, of } from 'rxjs';
//import { mergeMap, retry } from 'rxjs/operators';
//import { _throw } from 'rxjs/observable/throw';
//import { Sleeping } from 'matter';
import { HttpClient } from '@angular/common/http';
import { RCVarsService } from './rcvars-service.service';
import { RCToastService } from './rctoast.service';
import { RCStorage } from './rcstorage.service';
import { RCLoadingService } from './rcloading.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})

export class WSRCService {
  private url: string = '';
  static readonly ID = 'mytestwebsocket';
  readonly reload_time = 3000;
  obs;
  subj;
  lastmappos = "";
  //public messages: Observable<any>;
  private ws;
  public timer;
  //public onclose = new Subject();
  plog;

  constructor(
    private httpClient: HttpClient,
    private rcvars: RCVarsService,
    private rctoast: RCToastService,
    private rcstorage: RCStorage,
    private rcloading: RCLoadingService
  ) {
    this.plog = new plog();
  }

  public setUrl(id: string) {
    id = "connect";
    //this.plog.log('wsrc setUrl() ', id, this.url);
    this.url = this.rcvars.rcserver_wsurl + id;
  }

  //public connect(): Observable<any> {
  public connect() {
    //this.plog.log('wsrc connect() ', this);

    /*this.ws = Observable.webSocket({
    url: this.url,
    closeObserver: this.onclose
  });*/
    this.ws = new WebSocket(this.url);
    //this.messages = this.ws.asObservable();
    /*this.ws.subscribe(
      msg => {
        this.plog.log(msg)
       // this.bitVavoWssData = msg;
      }
      , // Called whenever there is a message from the server.
      err => this.plog.log(err), // Called if at any point WebSocket API signals some kind of error.
      () => this.plog.log('complete') // Called when connection is closed (for whatever reason).
    );*/
    //this.subj = new Subject<string>();
    /*
        this.obs = new Observable(subscriber => {
          subscriber.next(1);
          subscriber.next(2);
          subscriber.next(3);
          setTimeout(() => {
            subscriber.next(4);
            subscriber.complete();
          }, 1000);
        });*/
    //this.obs = observable;

    /*
        this.plog.log('wsrc just before subscribe');
        this.obs.subscribe({
          next(x) { this.plog.log('wsrc--got value ' + x); },
          error(err) { this.plog.error('wsrc--something wrong occurred: ' + err); },
          complete() { this.plog.log('wsrc--done'); }
        }); 
        this.plog.log('wsrc just after subscribe');
    */
    this.ws.onmessage = function (evt) {
      //this.plog.log('wsrc message received', evt, arguments);
      //this.plog.log('wsrc message received DATA', evt["data"]);
      //response to server here
      if (evt["data"] == "iden") {
        this.get("iden");
        return;
      }
      var data = JSON.parse(evt["data"]);
      //var ispublic =this.rcvars.activemap["map"]["ispublic"];
      //this.plog.log("wsrc ispublic ",ispublic,this.rcvars);
      if (data["mode"] == "zmap") {
        //this.plog.log("wsrc zmap",this);
        this.rcvars.rcgame.phm.updatezmap(data);
      }
      if (data["mode"] == "bc") {
        //this.plog.log("wsrc zmap",this);
        this.rcvars.rcgame.phm.broadcast(data);
        //this.rcvars.rcgame.phm.voicethis(this.wh, "svannouce");

      }
      if (data["mode"] == "ping") {
        //this.plog.log("wsrc zmap",this);
        this.ping();
      }
      if (data["mode"] == "dm") {
        //this.plog.log("wsrc dm",this);
        this.rcvars.rcgame.phaserGame.registry.list.dm.updatedm(data);
        //this.ping();
      }
      if (data["mode"] == "pingchar") {
        //this.plog.log("wsrc zmap",this);
        this.pingchar();
      }
      if (data["mode"] == "zmapchar") {
        //this.plog.log("wsrc zmapchar", data);
        this.rcvars.rcgame.phm.updatezmapchar(data);
      }
      if (data["mode"] == "zmapchat") {
        //this.plog.log("wsrc zmapchat", data);
        this.rcvars.rcgame.phm.updatezmapchat(data);
      }
      if (data["mode"] == "effect") {
        //this.plog.log("wsrc zmapchat", data);
        this.rcvars.rcgame.phm.zmapchareffect(data);
      }

    }.bind(this);
    this.ws.onopen = function (evt) {
      //this.plog.log('wsrc onopen .', evt, arguments);
      //this.send("aaaaaaaaaaaaaaaa");
      this.get("iden");
      this.ping();
      this.pingchar();
      //console.log("ws onopen");

      var welcommsg = [
        {
          "id": -1,
          "charid": 1,
          "charname": "@",
          "mappos": "26-27",
          "msg": "Visit our Discord or use @gm to contact GM",
          "dt": 10
        },
        {
          "id": -2,
          "charid": 1,
          "charname": "@",
          "mappos": "1-1",
          "msg": "Press [ENTER] or click chat icon in setting to open chat",
          "dt": 20
        },
        {
          "id": -3,
          "charid": 1,
          "charname": "@",
          "mappos": "1-1",
          "msg": "Welcome to Peacely Online",
          "dt": 30
        }
      ]
      //temp1.registry.list.phm.zmapchatwin(welcommsg);
      this.rcvars.rcgame.phm.zmapchatwin(welcommsg);
    }.bind(this);
    this.ws.onerror = function (evt) {
      //this.plog.log('wsrc onerror', evt, arguments);
    };
    this.ws.onclose = function () {
      /// try to reconnect websocket in 5 seconds
      //this.plog.log("wsrc onclose", this);
      setTimeout(function () {
        this.plog.log("wsrc onclose - try connect", this);
        this.connect();
        //start(websocketServerLocation)
      }.bind(this), 1000);
    }.bind(this);
    //this.plog.log('wsrc connect() end', this);
    //return this.messages = this.ws.retryWhen(errors => errors.delay(this.reload_time)).map(msg => msg).share();
    clearInterval(this.timer);
    this.timer = setInterval(function (this) {
      //this.plog.log("wsrc timer ",this,arguments)
      //this.plog.log("wsrc timer ",this.ws.readyState);
      //this.get("ping");
      this.sendmappos();
    }.bind(this), 1000);
  }
  ping() {
    if (!this.status()) return;
    this.get("ping", {});
  }
  pingchar() {
    if (!this.status()) return;
    this.get("pingchar", {});
  }
  sendmappos() {
    if (!this.status()) return;
    if (this.rcvars.gameobj.scene.scenes[0].char_main == undefined) return;
    var curpos = this.rcvars.gameobj.scene.scenes[0].char_main.currentx + "-" + this.rcvars.gameobj.scene.scenes[0].char_main.currenty;
    if (curpos != this.lastmappos) {
      this.lastmappos = curpos;
      //this.plog.log("sendmappos", this);
      this.get("mappos", { mappos: curpos });
    }
  }
  sendeffect(text, effect) {
    //console.log("sendeffect", text, effect);
    if (!this.status()) return;
    if (this.rcvars.gameobj.scene.scenes[0].char_main == undefined) return;
    var curpos = this.rcvars.gameobj.scene.scenes[0].char_main.currentx + "-" + this.rcvars.gameobj.scene.scenes[0].char_main.currenty;
    if (curpos != this.lastmappos) {
      this.lastmappos = curpos;
      //this.plog.log("sendmappos", this);
    }
    this.get("effect", { text: text, effect: effect });
  }
  sendchat(txt) {
    if (!this.status()) return;
    if (txt == undefined || txt == "") return;
    //this.plog.log("sendchat", this.rcvars.activechar, this.rcvars.gameobj.scene.scenes[0].registry.list.timectl)
    if (this.rcvars.activechar["banchatuntil"] > this.rcvars.gameobj.scene.scenes[0].registry.list.timectl.humantime) {
      var banuntil = this.rcvars.gameobj.scene.scenes[0].registry.list.gameitf.formattimestamp(this.rcvars.activechar["banchatuntil"]);
      var nowdt = this.rcvars.gameobj.scene.scenes[0].registry.list.gameitf.formattimestamp(this.rcvars.gameobj.scene.scenes[0].registry.list.timectl.humantime);
      //this.plog.log("Ban chat until", banuntil,"now",this.rcvars.gameobj.scene.scenes[0].registry.list.timectl.humantime);
      this.rcvars.gameobj.scene.scenes[0].registry.list.rctoast.rcalert(this.rcvars.gameobj.scene.scenes[0], "Chat banned", "you can't send chat until " + banuntil + "\n now " + nowdt);
      return;
    }
    this.get("sendchat", { msg: txt });

  }
  send(msg: any) {
    //return;
    //this.plog.log('wsrc send() ', msg, this.subj, this.obs, this.ws);
    this.ws.send(JSON.stringify(msg));
    //this.subj.next(JSON.stringify(msg));
  }
  status() {
    //this.plog.log("wsrc status",this.ws);
    if (this.ws == undefined) return false;
    return (this.ws.readyState == 1);
  }
  initmap(mapcode) {
    //this.plog.log("wsrc initmap", mapcode);
    this.setUrl(mapcode);
    //if (this.status() == true) {
    //  this.disconnect();
    //}
    if (!this.status())
      this.connect();
    this.get("iden", {});
    this.get("ping", {});
    this.get("pingchar", {});
  }

  disconnect() {
    this.ws.close();
  }
  async get(mode, addvars) {
    var url1 = await this.rcstorage.get("rc_login");
    var url2 = await this.rcstorage.get("rc_sid");
    var mapid = "";
    var charid = this.rcvars.activechar.id;
    var charname = this.rcvars.activechar.name+"("+this.rcvars.activechar.level+")";
    var mysprite = "";
    var flag = "";
    if (this.rcvars.activechar != undefined) {
      mysprite = this.rcvars.activechar["sprite"];
      mapid = this.rcvars.activechar["map"];
      flag = this.rcvars.activechar["flag"];
    }
    var curpos = "";
    var element = "";
    if (this.rcvars.gameobj.scene.scenes[0].char_main != undefined) {
      curpos = this.rcvars.gameobj.scene.scenes[0].char_main.currentx + "-" + this.rcvars.gameobj.scene.scenes[0].char_main.currenty;
    }
    if (this.rcvars.activechar != undefined) {
      element = this.rcvars.activechar["element"];
    }
    var postdata = {
      mode: mode,
      sprite: mysprite,
      element: element,
      mapid: mapid,
      mappos: curpos,
      login: url1,
      charname: charname,
      charid: charid,
      flag: flag,
      sid: url2
    }
    //this.plog.log("wsrc get mapid", mapid, this.rcvars.activechar);
    for (var k in addvars) {
      var v = addvars[k]
      //this.plog.log("wsrc get addvars", k,v);
      postdata[k] = v;
    }
    //this.plog.log("wsrc get data", postdata);
    if (!this.status()) return;
    //this.subj.next(JSON.stringify(postdata));
    this.send(postdata);
  }
  public close() {
    //this.plog.log('on closing WS');
    this.ws.complete();
  }
  getData(status: number) {
    //this.plog.log("wsrc getData",status);
    ///return _throw({ status });
  }
  senddm(charid, msg) {
    this.plog.log("WSRC senddm", charid, msg);
    this.get("senddm", { dmid: charid, msg: msg });
  }
}
