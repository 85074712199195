import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class SNDService {
  bgmobj;
  playingbgm = "";
  loader;
  sounds = [];
  loadingbgm = "";
  plog;

  constructor(
    public rcvars: RCVarsService
  ) {
    //this.plog.log("SND constructor", this);
    this.plog = new plog();
  }/*
  prepare() {
    if (this.rcvars.gameobj == undefined) {
      this.plog.log("SND prepare, skip, gameobj not defined");
      return false;
    }
    return true;
  }
  loadbgm(id) {
    this.plog.log("loadbgm", id, this.loader, this.rcvars.gameobj);
    this.loader = new Phaser.Loader.LoaderPlugin(
      this.rcvars.gameobj.scene.scenes[0]
    );
    this.loader.load.audio(id, ["assets/audio/" + id]);
    this.loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.loadbgm_loaddone();
    });
    this.loader.start();
  }*/
  prepare() {
    var slist = ["intro", "intro2", "fruitcollect", "btn", "btn2", "btn3", "drop", "coins", "buy", "pickup", "combatwin", "combatlose", "meow1", "heal", "balloonpop", "balloonwoosh", "fruitcollect", "atkhit1", "atkhit2", "atkhit3", "atkbuff1", "atkcurse1", "atkcurse2", "atkarrow1", "atkarrow2", "atkexplode1", "atkexplode2", "atkwhip1", "atkwhip2", "atkwink1", "atkwink2", "atkelectric1", "atkarcane1", "atkarcane2", "heavy-rain", "light-rain", "thunder", "dash", "footstep", "warp1", "warp2"];
    for (const k in slist) {
      var v = slist[k];
      if (this.sounds[v] == undefined) {
        //console.log(this);
        if (this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].cache.audio.entries.entries[v] != undefined) {
          this.sounds[v] = this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].sound.add(v);
        } else {
          //console.log("audio not exists yet", v);
        }
      }
    }

  }
  async snd(id, isloop = false) {
    //this.plog.error("snd snd", id, this.sounds);
    if (id == "atkhit") {
      var slist = ["atkhit1", "atkhit2", "atkhit3"];
      var item = slist[Math.floor(Math.random() * slist.length)];
      //this.plog.error("snd snd", id, item,this.sounds[item]);
      if (this.sounds[item] != undefined) {
        this.sounds[item].play();
        return;
      }
    }
    if (id == "warp") {
      var slist = ["warp1", "warp2"];
      var item = slist[Math.floor(Math.random() * slist.length)];
      //this.plog.error("snd snd", id, item,this.sounds[item]);
      if (this.sounds[item] != undefined) {
        var usevol = this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].volume = await this.rcvars.gameobj.registry.list.rcstorage.get('setting_volume', true);
        //usevol = usevol / 2;
       // this.sounds[item].setVolume(usevol / 10);
        this.sounds[item].play();
        return;
      }
    }
    if (id == "footstep") {
      if (this.sounds[id] != undefined) {

        var usevol = this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].volume = await this.rcvars.gameobj.registry.list.rcstorage.get('setting_volume', true);
        usevol = usevol / 3;
        this.sounds[id].setVolume(usevol / 10);
        this.sounds[id].play();
        return;
      }
    }
    if (id == "explode") {
      var slist = ["atkexplode1", "atkexplode2"];
      var item = slist[Math.floor(Math.random() * slist.length)];
      if (this.sounds[item] != undefined) {
        this.sounds[item].play();
        return;
      }
    }
    if (id == "arcane") {
      var slist = ["atkarcane1", "atkarcane2"];
      var item = slist[Math.floor(Math.random() * slist.length)];
      if (this.sounds[item] != undefined) {
        this.sounds[item].play();
        return;
      }
    }
    if (id == "buff") {
      var slist = ["atkbuff1"];
      var item = slist[Math.floor(Math.random() * slist.length)];
      if (this.sounds[item] != undefined) {
        this.sounds[item].play();
        return;
      }
    }
    if (id == "curse") {
      var slist = ["atkcurse1", "atkcurse2"];
      var item = slist[Math.floor(Math.random() * slist.length)];
      if (this.sounds[item] != undefined) {
        this.sounds[item].play();
        return;
      }
    }
    if (id == "wink") {
      var slist = ["atkwink1", "atkwink2"];
      var item = slist[Math.floor(Math.random() * slist.length)];
      if (this.sounds[item] != undefined) {
        this.sounds[item].play();
        return;
      }
    }
    if (id == "electric") {
      var slist = ["atkelectric1"];
      var item = slist[Math.floor(Math.random() * slist.length)];
      if (this.sounds[item] != undefined) {
        this.sounds[item].play();
        return;
      }
    }
    if (id == "atkarrow") {
      var slist = ["atkarrow1", "atkarrow2"];
      var item = slist[Math.floor(Math.random() * slist.length)];
      if (this.sounds[item] != undefined) {
        this.sounds[item].play();
        return;
      }
    }
    if (this.sounds[id] != undefined) {
      if (isloop == true)
        this.sounds[id].loop = true;

      this.sounds[id].play();

      //this.plog.log("snd snd", id, "playing");
    }
  }
  stop(id) {
    if (this.sounds[id] != undefined) {
      try {
        this.sounds[id].stop();
      } catch (e) {
        console.log(e);
      }
    }
  }
  async bgm(id, forceplay = false) {
    this.playingbgm = id;

    if (this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].volume == undefined || this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].volume == "") {
      this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].volume = await this.rcvars.gameobj.registry.list.rcstorage.get('setting_volume');
    }

    // this.plog.log("bgm", id, this, this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0]);
    //this.plog.log("bgm this.bgmobj", this.bgmobj, this);
    if (this.bgmobj == undefined) {
      //this.plog.log("bgm()",id,"halted, bgmobj not defined")
      //return;
    }
    //this.plog.log("bgmsetting", this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].bgmsetting);
    if (this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].bgmsetting == "no" && id != "bgm" && id != "combat") {
      this.rcvars.gameobj.registry.list.rctoast.rctoast(
        this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0],
        "Background music playing, but BGM setting is off"
      );
    }
    if (this.bgmobj != undefined) {
      /*this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].tweens.add({
        targets: this.bgmobj,
        volume: 0.5,
        duration: 500,
        onComplete: function () {
          this.plog.log("bgm tween complete", this, arguments);
          arguments[0].stop();

        }.bind(this),
        onUpdate: function () {
          this.plog.log("bgm tween update", this, arguments);

        }.bind(this),
      });*/

      this.bgmobj.stop();
      this.bgmobj.destroy();
      this.bgmobj = undefined;//
      this.bgm(id);
    } else {

      if (forceplay == false) {
        if (this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].bgmsetting != "yes") return;
      }
      try {
        this.bgmobj = this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].sound.add(id);
        this.bgmobj.loop = true; // This is what you are looking for
        this.bgmobj.play();
        var usevol = this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].volume = await this.rcvars.gameobj.registry.list.rcstorage.get('setting_volume', true);
        usevol = usevol - 3;
        if (usevol < 1) usevol = 1;
        usevol = usevol / 2;
        this.bgmobj.setVolume(usevol / 10);
        this.plog.log("bgmvol", usevol);
        this.plog.log("bgm setting_volume", this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].volume = await this.rcvars.gameobj.registry.list.rcstorage.get('setting_volume'));
      } catch (e) {
        this.plog.log("ERROR", e);
      }
    }
    //this.bgmobj.destroy();
    //this.bgmobj = this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].sound.add(id);
    //this.bgmobj.setVolume(this.rcvars.gameobj.registry.list.phaserGame.scene.scenes[0].volume / 10);
  }
  loadbgm_loaddone() {
    this.bgmobj = this.rcvars.gameobj.add.audio(this.loadingbgm);
  }
}
