import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { PGBar } from './pgbar';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class PetTravelService {
  shopdragbtn;
  caticon = [];
  healallpets: any;
  shine1;
  loading;
  shopbackbtn;
  shopfwdbtn;
  empty;
  currentresppage = 1;
  resptotalpage = 0;
  respperpage = 6;
  title;
  dwidescr_weekly;
  wwdata;
  dwichest_weekly;
  petskill = [];
  pethp = [];
  petxp = [];
  petbg = [];
  petname = [];
  peticon = [];
  petdescr = [];

  lastdat;
  dwidescr_daily;
  dwichest_daily;
  countpets;
  pettravelcontainer_char;
  selectcircle;
  scrollareabox;
  shopclosebtn;
  itemdescr;
  eqitems = [];
  eqitemsp = [];
  itemwh = 54;
  shopguibg;
  wh;
  menubaritem = [];
  btnstory;
  btndaily;
  btnweekly;
  countpet;
  healbtndis = [];
  healbtn = [];
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
  }

  loadingshow() {
    this.wh.pettravelcontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }

  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      //this.wh.registry.list.gameitf.guiwindowclick(this.wh.pettravelcontainer);
    }
    this.firsttimerun = false;
  }

  async show(wh, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;
    this.wh = wh;
    /*
    if (wh.pettravelcontainer != undefined) {
      wh.pettravelcontainer.visible = true;

      this.buildpetctnr();
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
        this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer
      );
      return;
    }*/
    if (wh.pettravelcontainer != undefined) {
      await this.show_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('petgui show', wh, this);


    const shopguibg = wh.add.image(0, 0, 'blankbarguibg');//petsguibg
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    //this.plog.log("questgui var", this.rcvarsservice);

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );

    wh.pettravelcontainer = wh.add.container(300, 300);
    wh.pettravelcontainer._refsrv = this;
    wh.pettravelcontainer.visible = false;
    wh.pettravelcontainer.name = "pettravelcontainer";
    wh.pettravelcontainer.add(this.shopclosebtn);
    wh.pettravelcontainer.add(shopguibg);
    wh.pettravelcontainer.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, '', {
      fontFamily: 'ffff',
      fontSize: '8px',
      fill: '#000',
      boundsAlignH: 'center',
      boundsAlignV: 'middle',
    });
    wh.pettravelcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;


    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.pettravelcontainer.add(this.loading);
    wh.pettravelcontainer.bringToTop(this.loading);


    this.empty = wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 0);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    wh.pettravelcontainer.add(this.empty);
    wh.pettravelcontainer.bringToTop(this.empty);
    this.empty.visible = false;

    this.shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    this.shopbackbtn.setOrigin(0.5, 0.5);
    this.shopbackbtn.setInteractive();
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');//wh.add.sprite(0, 0, "theb","forwardsign");//
    this.shopfwdbtn.setOrigin(0.5, 0.5);
    this.shopfwdbtn.setInteractive();
    this.shopfwdbtn.removeAllListeners();


    this.shopbackbtn.displayWidth = 100;
    this.shopfwdbtn.displayWidth = 100;
    this.shopbackbtn.displayHeight = 40;
    this.shopfwdbtn.displayHeight = 40;
    this.shopbackbtn.setPosition(-110, 220);
    this.shopfwdbtn.setPosition(110, 220);

    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );


    wh.pettravelcontainer.add(this.shopfwdbtn);
    wh.pettravelcontainer.bringToTop(this.shopfwdbtn);

    wh.pettravelcontainer.add(this.shopbackbtn);
    wh.pettravelcontainer.bringToTop(this.shopbackbtn);

    //drag
    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.y =
            tmpy - movedy;
          //this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer.y = pointer.y;
        }
      },
      this
    );
    wh.pettravelcontainer.add(this.shopdragbtn);
    wh.pettravelcontainer.bringToTop(this.shopdragbtn);
    this.menubaritem.push(this.shopdragbtn);


    this.title = wh.add.text(0, 0, 'PETS', wh.fs["winguititle42"]);
    this.plog.log("winguititle fs", wh.fs["winguititle"])
    wh.pettravelcontainer.add(this.title);
    wh.pettravelcontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -228);


    //skilltype bar
    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("pettravelcontainer", wh.pettravelcontainer, width, height);
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    wh.pettravelcontainer.setPosition(width / 2, height / 2);
    let loader = new Phaser.Loader.LoaderPlugin(wh);
    var loadlist = ['mob'];
    for (var k in loadlist) {
      var key = loadlist[k];
      if (this.rcvarsservice.gameobj.textures.game.textures.list[key] == undefined) {

        loader.atlas(
          key + '',
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.png' +
          svversionurlstring,
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.json' +
          svversionurlstring
        );
      }
    }
    //move
    wh.pettravelcontainer.x = width - this.shopguibg.width / 2 + 20;



    //wh.pettravelcontainer_char.on('pointermove', function (pointer) {
    //this.plog.log("xxxcontainer", this);
    //});
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        //this.plog.log('Phaser.Loader.Events.COMPLETE');
        //this.plog.log(this);
        //this.show_loaded();

        this.buildpetctnr();
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].pettravelcontainer
        );
      },
      this
    );
    loader.start();
  }

  async buildpetctnr(gopagemode = "1st") {
    this.firsttimerunfunc();
    this.plog.log('buildpetctnr');
    var wh = this.wh;


    if (wh.pettravelcontainer_char != undefined) {
      for (const k in this.wh.pettravelcontainer_char.list) {
        try {
          await this.wh.pettravelcontainer_char.remove(this.wh.pettravelcontainer_char.list[k], true);
          //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
        } catch (e) {
          this.plog.log("ERROR! pettravelcontainer_char.destroy() ", e);
        }
      }
    }

    if (wh.pettravelcontainer_char != undefined) {
      wh.pettravelcontainer_char.destroy();
    }

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    this.loadingshow();



    wh.pettravelcontainer_char = wh.add.container(0, 0);

    this.healallpets = wh.add.sprite(0, 0, "stackitem_tp", "healallpets");//wh.add.image(0, 0, 'sellbtn');
    this.healallpets.setOrigin(0.5, 0.5);
    this.healallpets.setInteractive();
    this.healallpets.removeAllListeners();
    this.healallpets.on(
      'pointerup',
      async function () {
        var res = await this.rchttp.getcommon2("consumestackitem", { usecode: 'healallpets' })
        if (res == 'ok') {
          this.wh.registry.list.execmod.exec(
            'healallpets',
            this.wh
          );
        }
        await this.wh.registry.list.rchttp.updatecharinfo();
      }, this);
    this.healallpets.name = 'skipcntnrsizecal';

    this.shine1 = wh.add.sprite(100, 100, "shinebox", "shinebox_b");
    this.shine1.name = 'skipcntnrsizecal';
    this.shine1.play("shinebox_b");
    this.shine1.setOrigin(0.5, 0.5);
    this.shine1.visuble = false;
    this.healallpets.visible = false;
    wh.pettravelcontainer.add(this.healallpets);
    wh.pettravelcontainer.bringToTop(this.healallpets);
    wh.pettravelcontainer.add(this.shine1);
    wh.pettravelcontainer.bringToTop(this.shine1);

    this.healallpets.displayWidth = this.healallpets.displayHeight = 64;
    this.shine1.displayWidth = this.shine1.displayHeight = 64;

    this.healallpets.setPosition(-150, -200);
    this.healallpets.visible = false;
    this.shine1.setPosition(-150, -200);
    this.shine1.visible = false;

    this.healallpets.visible = false;
    this.shine1.visible = false;


    wh.pettravelcontainer.add(wh.pettravelcontainer_char);
    var dat;
    dat = await this.rchttp.getcommon2('allpets', {});
    this.lastdat = dat;
    this.countpet = 0;
    for (const k in dat) {
      var v = dat[k];
      if (v['take'] != 'YES') continue;
      this.countpet++;
    }

    if (this.countpet == 0) {
      this.empty.visible = true;
    } else {
      this.empty.visible = false;
    }

    //CATROW
    var i = 0;
    var catbasex = 230;
    var catbasey = -295;
    var iconwh = 96;

    for (const k in dat) {
      var v = dat[k];
      //console.log("petinfo 1", v);
      if (v['take'] != 'YES') continue;

      var catchk = v['mob'].substring(0, 3);
      if (catchk != "cat") continue;
      this.caticon[i] = wh.add.sprite(0, 0, 'mob', v['mob']).play({ key: v['sprite'] + "-sit" });
      this.caticon[i].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

      wh.pettravelcontainer_char.add(this.caticon[i]);

      this.caticon[i].postFX.addShine(0.5, 0.2, 5, false);
      this.caticon[i].displayHeight = this.caticon[i].displayWidth = iconwh;
      this.caticon[i].setOrigin(0.5, 0.5);
      this.caticon[i].setPosition(catbasex - (i * 48), catbasey);
      this.caticon[i].flipX = true;
      this.caticon[i].removeAllListeners();
      this.caticon[i].setInteractive({ pixelPerfect: true });
      this.caticon[i].name = v['name'] + " (" + v['racename'] + ")";
      this.caticon[i].on("pointerover", async function () {
        var addstr = "";
        if (arguments[0]["mob"] == "cat01") addstr = " Stunner";
        if (arguments[0]["mob"] == "cat02") addstr = " Combat Timer";
        if (arguments[0]["mob"] == "cat03") addstr = " Kicker";
        if (arguments[0]["mob"] == "cat04") addstr = " Attribuite Doubler";
        if (arguments[0]["mob"] == "cat05") addstr = " Skill Cooldown";
        if ((arguments[0]["mob"] == "cat03" ||
          arguments[0]["mob"] == "cat04" ||
          arguments[0]["mob"] == "cat05")
          && arguments[0]["following"] != "") {
          addstr += " \n supporting ";
          if (arguments[0]["following"] == "char") {
            addstr += " player ";
          } else {
            //console.log(arguments[1]);
            addstr += " pet: " + arguments[1][arguments[0]["following"]]["name"] + " lv." + arguments[1][arguments[0]["following"]]["level"];
          }
        }
        this.scene.registry.list.gameitf.gamemenutooltip(
          this.scene,
          this,
          this.name + "\n" + addstr,
          'bottom'
        );
        //console.log(arguments);
      }.bind(this.caticon[i], v, this.lastdat))
      this.caticon[i].on("pointerup", async function () {
        //console.log(arguments);
        if (arguments[1]["mob"] == "cat01") {
          this.wh.registry.list.rctoast.rcalert(this.wh, "Set Following", arguments[1]["racename"] + " no need to set following setting");
          return;
        }
        if (arguments[1]["mob"] == "cat02") {
          this.wh.registry.list.rctoast.rcalert(this.wh, "Set Following", arguments[1]["racename"] + " no need to set following setting");
          return;
        }
        if (arguments[1]["mob"] == "cat03") {
          this.wh.registry.list.rctoast.rcalert(this.wh, "Set Following", arguments[1]["racename"] + " no need to set following setting");
          return;
        }
        //this.wh.registry.list.exec.exec("pickpet");
        this.wh.registry.list.execmod.exec('pickpetcatfollow:' + arguments[0], this.wh);
      }.bind(this, v["id"], v))
      wh.pettravelcontainer_char.bringToTop(this.caticon[i]);
      i++;
    }

    this.plog.log('xxxcountpet', this.countpet);
    this.pettravelcontainer_char = wh.pettravelcontainer_char;
    wh.pettravelcontainer_char.setPosition(-120, -120);
    var i = 0;
    var ii = 0;
    var rowh = 50;
    var iconwh = 40;
    var basey = -250;
    var basex = -10;
    var needhealing = false;
    for (const k in dat) {
      var v = dat[k];
      //this.plog.log("petinfo 1", v);
      if (v['take'] != 'YES') continue;

      var catchk = v['mob'].substring(0, 3);
      if (catchk == "cat") continue;
      //var petsprite = wh.registry.list.rcvarpass.allmob[v['code']];
      //this.plog.log("xxxskdat", v);
      this.peticon[i] = wh.add.sprite(0, 0, 'mob', v['mob']);
      this.peticon[i].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);
      var reqxp = wh.registry.list.rcvarpass.level[v['level']]['xp'];

      wh.pettravelcontainer_char.add(this.peticon[i]);
      this.peticon[i].displayHeight = this.peticon[i].displayWidth = iconwh;
      this.peticon[i].setPosition(basex + (iconwh / 2 - 20), basey + (ii * rowh - 5));
      this.peticon[i].removeAllListeners();
      this.peticon[i].setInteractive();
      this.peticon[i].name = v['xp'] + " / " + reqxp + " EXP";
      this.peticon[i].on("pointerover", async function () {
        this.scene.registry.list.gameitf.gamemenutooltip(
          this.scene,
          this,
          this.name,
          'right'
        );
      })
      this.petbg[i] = wh.add.image(0, 0, 'rowbg');
      wh.pettravelcontainer_char.add(this.petbg[i]);
      this.petbg[i].setOrigin(0, 0);
      this.petbg[i].setInteractive();
      this.petbg[i].removeAllListeners();
      this.petbg[i].name = v['id'];

      this.petbg[i].on('pointerup', async function (pointer) {
        this.scene.plog.log('xxx,skill,', this);
        //var tmpnamea = this.name.split("---");
        var data = await this.scene.registry.list.rchttp.getcommon2('allpets');
        this.scene.registry.list.rcosd.show(wh, 'pet', data[this.name]);
      });

      this.petbg[i].setPosition(basex + (-20), basey + (ii * rowh - rowh / 2 + 5));
      this.petbg[i].displayHeight = iconwh;
      this.petbg[i].displayWidth = 300;
      wh.pettravelcontainer_char.sendToBack(this.petbg[i]);

      var namedsp = v['name'] + '';
      if (namedsp == '') namedsp = 'Unnamed Pet';
      namedsp = namedsp + ' lv.' + v['level'];
      this.petname[i] = wh.add.text(0, 0, namedsp, wh.fs["winguilist_title"]);
      wh.pettravelcontainer_char.add(this.petname[i]);
      wh.pettravelcontainer_char.bringToTop(this.petname[i]);
      this.petname[i].setPosition(basex + (30), basey + (ii * rowh - 35));
      //tmpskname.setResolution(0.25);
      this.plog.log("petinfo", v);
      this.petdescr[i] = wh.add.text(0, 0, ' ' + v['racename'] + ' ' + v['descr'], {
        fontFamily: 'ffff',
        fontSize: '8px',
        fill: '#333',
        boundsAlignH: 'center',
        boundsAlignV: 'middle',
      });
      wh.pettravelcontainer_char.add(this.petdescr[i]);
      wh.pettravelcontainer_char.bringToTop(this.petdescr[i]);
      this.petdescr[i].setPosition(basex + (30), basey + (ii * rowh - 7));

      var hpbaroption = {
        x: basex + (30),
        y: basey + (ii * rowh + 5 + 3),
        w: 150,
        h: 5,
        max: v['cbt']['maxhp'],
        me: v['cbt']['hp'],
        txt: 'HP',
        barcol1: 0x24ff2b,
        barcol2: 0x00d407,
        bgcol: 0xffffff,
        borderw: 0,
        imgcol: 'green',
      };
      this.pethp[i] = new PGBar(wh, hpbaroption, 'img');
      wh.pettravelcontainer_char.add(this.pethp[i].getobj());
      wh.pettravelcontainer_char.add(this.pethp[i].getobjfg());
      wh.pettravelcontainer_char.bringToTop(this.pethp[i].getobj());
      wh.pettravelcontainer_char.bringToTop(this.pethp[i].getobjfg());
      //this.pethp[i].getobj().setPosition(basex + (30), basey + (ii * rowh + 5 + 3));

      //this.plog.log("reqxp",reqxp);
      var xpbaroption = {
        x: basex + (30),
        y: basey + (ii * rowh + 10 + 3),
        w: 150,
        h: 5,
        max: reqxp,
        me: v['xp'],
        txt: 'XP',
        barcol1: 0xffc70f,
        barcol2: 0xffc70f,
        bgcol: 0xffffff,
        borderw: 0,
        imgcol: 'yellow'
      };
      this.petxp[i] = new PGBar(wh, xpbaroption, "img");
      wh.pettravelcontainer_char.add(this.petxp[i].getobj());
      wh.pettravelcontainer_char.bringToTop(this.petxp[i].getobj());
      wh.pettravelcontainer_char.add(this.petxp[i].getobjfg());
      wh.pettravelcontainer_char.bringToTop(this.petxp[i].getobjfg());
      //this.petxp[i].getobj().setPosition(basex + (30), basey + (ii * rowh + 10 + 3));



      this.petskill[i] = wh.add.sprite(300, 300, "thesq", 'skill');
      wh.pettravelcontainer_char.add(this.petskill[i]);
      wh.pettravelcontainer_char.bringToTop(this.petskill[i]);
      this.petskill[i].displayWidth = 35;
      this.petskill[i].scaleY = this.petskill[i].scaleX;
      this.petskill[i].setPosition(basex + (240 + -35), basey + (ii * rowh - 5));
      this.petskill[i].setInteractive();
      this.petskill[i].removeAllListeners();
      this.petskill[i].name = k;
      this.petskill[i].on('pointerover', async function () {
        this.scene.registry.list.gameitf.gamemenutooltip(
          this.scene,
          this,
          this.scene.trs("f:pettravelcontainer:Manage Skill===Manage Skill"),
          'left'
        );
      });
      this.petskill[i].on('pointerup', async function () {
        this.scene.plog.log('skillbtn', this.name, this);
        if (this.scene.registry.list.gameitf.clicktoosoon()) return; this.scene.registry.list.gameitf.markclick();

        this.scene.registry.list.petskill.show(this.scene, this.name, true);
      });


      this.healbtndis[i] = wh.add.sprite(300, 300, "thesq", 'healdis');
      wh.pettravelcontainer_char.add(this.healbtndis[i]);
      wh.pettravelcontainer_char.bringToTop(this.healbtndis[i]);
      this.healbtndis[i].displayWidth = 50;
      this.healbtndis[i].scaleY = this.healbtndis[i].scaleX;
      this.healbtndis[i].setPosition(basex + (240 + 10), basey + (ii * rowh - 5));
      this.healbtndis[i].setInteractive();
      this.healbtndis[i].removeAllListeners();
      this.healbtndis[i].name = k;



      this.healbtn[i] = wh.add.sprite(300, 300, "thesq", 'heal');
      wh.pettravelcontainer_char.add(this.healbtn[i]);
      wh.pettravelcontainer_char.bringToTop(this.healbtn[i]);
      this.healbtn[i].displayWidth = 50;
      this.healbtn[i].scaleY = this.healbtn[i].scaleX;
      this.healbtn[i].setPosition(basex + (240 + 10), basey + (ii * rowh - 5));
      this.healbtn[i].setInteractive();
      this.healbtn[i].removeAllListeners();
      this.healbtn[i].name = k;
      this.healbtn[i].on('pointerover', async function () {
        this.scene.registry.list.gameitf.gamemenutooltip(
          this.scene,
          this,
          "Heal",
          'left'
        );
      });
      this.healbtn[i].on('pointerup', async function () {
        //this.scene.plog.log('healbtn', this.name, this);
        this.scene.registry.list.pettravel.loadingshow();
        var res = await this.scene.registry.list.rchttp.getcommon2('petheal', {
          petid: this.name,
        });
        // this.scene.plog.log(res, this, this.name, this.scene.registry.list.pettravel);
        if (res == 'ok') {
          //this.scene.registry.list.pettravel.buildpetctnr();
          //this.scene.registry.list.pettravel.healbtn[this.name].visible = false;
          this.scene.registry.list.rchttp.updatecharinfo();
        }
        this.scene.registry.list.pettravel.loadinghide();
      });
      if (v['cbt']['hp'] >= v['cbt']['maxhp']) {
        this.healbtndis[i].visible = true;
        this.healbtn[i].visible = false;
      } else {
        this.healbtndis[i].visible = false;
        this.healbtn[i].visible = true;
        needhealing = true;
      }

      i++;
      ii++;
      if (ii == this.respperpage) ii = 0;
    }
    if (needhealing == true) {

      if (wh.registry.list.rcreq.chk({ stackitem1: "healallpets", stackitem1_min: 1 })) {
        this.healallpets.visible = true;
        this.shine1.visible = true;
        this.wh.registry.list.tut.show(this.wh, "pet_travel_needhealinghealallpets");
      } else {
        this.wh.registry.list.tut.show(this.wh, "pet_travel_needhealing");
      }

    }
    //if (i > 6) {
    //pages
    this.resptotalpage = Math.ceil(i / this.respperpage);
    this.plog.log("petpage", this.resptotalpage, this.respperpage, i);
    //}
    this.pettravelcontainer_char.y = 150;
    if (gopagemode == "1st") {
      this.showinventory_showpage(this.wh, "1st");
    } else {
      this.showinventory_showpage(this.wh, gopagemode);
    }
    this.loadinghide();

    this.wh.pettravelcontainer.visible = true;
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);



  }

  async show_close(wh) {
    this.plog.log(this);

    this.wh.registry.list.gameitf.closegui(wh, wh.pettravelcontainer);

    for (const k in this.wh.pettravelcontainer.list) {
      try {
        await this.wh.pettravelcontainer.remove(this.wh.pettravelcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! pettravelcontainer.destroy() ", e);
      }
    }
    for (const k in this.wh.pettravelcontainer.list) {
      try {
        await this.wh.pettravelcontainer.remove(this.wh.pettravelcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! pettravelcontainer.destroy() ", e);
      }
    }

    try {
      await this.wh.pettravelcontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! pettravelcontainer.destroy() ", e);
    }
    try {
      await this.wh.pettravelcontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! pettravelcontainer.destroy() ", e);
    }
    this.wh.pettravelcontainer = undefined;
    // wh.pettravelcontainer.visible = false;
  }
  showinventory_showpage(gc, viewpage) {
    if (viewpage == '1st') {
      this.currentresppage = 1;
    }
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.healbtndis[i] != undefined) {
        this.healbtndis[i].visible = false;
      }
      if (this.healbtn[i] != undefined) {
        this.healbtn[i].visible = false;
      }
      if (this.peticon[i] != undefined) {
        this.peticon[i].visible = false;
      }
      if (this.petname[i] != undefined) {
        this.petname[i].visible = false;
      }
      if (this.petdescr[i] != undefined) {
        this.petdescr[i].visible = false;
      }
      if (this.petxp[i] != undefined) {
        this.petxp[i].visible = false;
        this.petxp[i].bar.visible = false;
      }
      if (this.pethp[i] != undefined) {
        this.pethp[i].bar.visible = false;
      }
      if (this.petbg[i] != undefined) {
        this.petbg[i].visible = false;
      }
      if (this.petskill[i] != undefined) {
        this.petskill[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );
    this.loadinghide();

    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);
    griddat.push(this.healallpets);

    for (var i = numfrom; i < numto; i++) {

      if (this.healbtndis[i] != undefined && this.healbtn[i] != undefined) {
        //this.healbtn[i].visible = true;
        if (this.lastdat != undefined && this.lastdat[this.healbtn[i].name] != undefined)
          if (this.lastdat[this.healbtn[i].name]['cbt']['hp'] >= this.lastdat[this.healbtn[i].name]['cbt']['maxhp']) {
            this.healbtndis[i].visible = true;
            this.healbtn[i].visible = false;

          } else {
            this.healbtndis[i].visible = false;
            this.healbtn[i].visible = true;
            griddat.push(this.healbtn[i]);
            griddatf.push(this.healbtn[i]);
          }

      } else {
        if (this.healbtndis[i] != undefined)
          this.healbtndis[i].visible = false;
        if (this.healbtn[i] != undefined) {
          this.healbtn[i].visible = false;
        }
      }
      if (this.peticon[i] != undefined) {
        this.peticon[i].visible = true;
      }
      if (this.petname[i] != undefined) {
        this.petname[i].visible = true;
      }
      if (this.petdescr[i] != undefined) {
        this.petdescr[i].visible = true;
      }
      if (this.petxp[i] != undefined) {
        this.petxp[i].bar.visible = true;
        this.petxp[i].barfg.visible = true;
      }
      if (this.pethp[i] != undefined) {
        this.pethp[i].bar.visible = true;
        this.pethp[i].barfg.visible = true;
      }
      if (this.petskill[i] != undefined) {
        this.petskill[i].visible = true;
        griddat.push(this.petskill[i]);
      }
      if (this.petbg[i] != undefined) {
        this.petbg[i].visible = true;
      }

      if (this.healbtn[i] != undefined) {
        var catchk = this.lastdat[this.healbtn[i].name]['mob'].substring(0, 3);
        if (catchk == "cat") {

          this.petskill[i].visible = false;
          this.healbtndis[i].visible = false;
          this.healbtn[i].visible = false;
          this.petxp[i].bar.visible = false;
          this.petxp[i].barfg.visible = false;
          this.petdescr[i].visible = false;
          this.pethp[i].bar.visible = false;
          this.pethp[i].barfg.visible = false;
        }
      }
    }
    if (this.wh.activewindow == this.wh.pettravelcontainer)
      this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.showinventory_showpage(null, "");
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
