import { Capacitor } from '@capacitor/core';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { Component, HostListener, OnInit } from '@angular/core';
import Phaser from 'phaser';
import { Platform } from '@ionic/angular';
import { RCVarsService } from './rcvars-service.service';
import { RCStorage } from './rcstorage.service';
import { RCHTTP } from './rchttp.service';
import { ChangeDetectorRef } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AuthenticationService } from './firebaseauth';
import { SparBoardService } from './spar-board.service';
import { GAService } from './ga.service';
import { TutService } from './tut.service';
import { PetSkillService } from './petskill.service'
import { IAPStoreService } from './iapstore.service';
import { SpinnerService } from './spinner.service';
import { ShipNavigationService } from './shipnavigation.service';
//import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SNDService } from './snd.service';
import { PetUpLevelService } from './pet-uplevel';
import { DMServices } from './dm.services';
import { RCSkillCraftService } from './rcskillcraft.service';
import { PartyMapService } from './partymap.service';
import { PartyMyService } from './partymy.service';
import { Purchases, LOG_LEVEL } from '@revenuecat/purchases-capacitor';
//import { PayPal, PayPalPayment, PayPalConfiguration } from '@awesome-cordova-plugins/paypal/ngx';
//import firebase from 'firebase/compat';
//import { StatusBar } from '@capacitor/status-bar';
//import { GooglePlayGamesServices } from '@ionic-native/google-play-games-services';
import { SplashScreen } from '@capacitor/splash-screen';
import { NgZone } from '@angular/core';
import { enableProdMode } from '@angular/core';
import { DramaService } from './drama-service';
import { SCRW } from './scrw.service';
//import { Plugins } from '@capacitor/core'
//const { SplashScreen } = Plugins;
//enableProdMode();

import {
  FormGroup,
  Validators,
} from '@angular/forms';
import { RCToastService } from './rctoast.service';
import { RCLoadingService } from './rcloading.service';
import { RCSVVARSService } from './rcsvvars.service';
import { RCUpdateITFService } from './rcupdate-itf.service';
import { PhaserMapService } from './phaser-map.service';
import { Preloader } from './Scenes/preloader';
import { Welcome } from './Scenes/welcome';
import WebFontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin'
import VirtualJoystickPlugin from 'phaser3-rex-plugins/plugins/virtualjoystick-plugin';
import InputTextPlugin from 'phaser3-rex-plugins/plugins/inputtext-plugin';
//import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin';
import PixelationPipelinePlugin from 'phaser3-rex-plugins/plugins/pixelationpipeline-plugin.js';
import ToonifyPipelinePlugin from 'phaser3-rex-plugins/plugins/toonifypipeline-plugin.js';
//import rexGrayScalePipeline from 'phaser3-rex-plugins/plugins/grayscalepipeline.js'
import GesturesPlugin from 'phaser3-rex-plugins/plugins/gestures-plugin.js';
import HSLAdjustPipelinePlugin from 'phaser3-rex-plugins/plugins/hsladjustpipeline-plugin.js';
import { GameInterfaceService } from './game-interface.service';
import { NPCConverService } from './npcconver.service';
import { ExecModuleService } from './exec-module.service';
import { PhaserMapResizeManagerService } from './phaser-map-resize-manager.service';
import { RCRequirementService } from './rcrequirement.service';
import { NPCSHOPService } from './npcshop.service';
import { RCStackInventoryService } from './rcstack-inventory.service';
import { RCInventoryService } from './rcinventory.service';
import { RCEquipmentService } from './rcequipment.service';
import { RCOSDService } from './rcosd.service';
import { RCAttriService } from './rcattri.service';
import { RCSkillService } from './rcskill.service';
import { PickRewardService } from './pick-reward.service';
import { AnyJs } from './anyjs';
//import RCGame from './Scenes/rcgame';
//import { IonicStorageModule } from '@ionic/storage-angular';
import { RCHomeInvenStackItemsService } from './rchome-inven-stack-items.service';
import { RCHomeInvenItemsService } from './rchome-inven-items.service';
import { ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { RCCharInfoService } from './rcchar-info.service';
import { QuestInfoService } from './quest-info.service';
import { QuestListService } from './quest-list.service';
import { MsgsService } from './msgs.service';
import { ShowRewardService } from './show-reward.service';
import { NotifService } from './notif.service';
import { DiamondOfferService } from './diamond-offer.service';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { FriendListService } from './friendlist.service';
import { TimeCtlService } from './time-ctl.service';
import { EggNestService } from './egg-nest.service';
import { WSRCService } from './wsrc.service';
import { PetGuiService } from './pet-gui.service';
import { PetTravelService } from './pet-travel.service';
import { ZCharInfoService } from './zchar-info.service';
import { UpgradeItemService } from './upgrade-item.service';
import { UpgradeRarItemService } from './upgraderar-item.service';
import { PickItemService } from './pick-item.service';
import { PickPetService } from './pick-pet.service';
import { FarmOrderService } from './farmorder.service';
import { PickItemItemService } from './pick-item-item.service';
import { COMBAT } from './combat';
import { MerchantService } from './merchant.service';
import { FarmPlotService } from './farm-plot.service';
import { WeatherCtlService } from './weather-ctl.service';
import { OverlayDSPService } from './overlay-dsp.service';
import { FishingService } from './fishing.service';
import { GPService } from './gp.service';
import { intl } from './intl.service';
//import { MapWalker } from './mapwalker';
//import { DragonBones } from './dragonBones/phaser/plugin/DragonBonesPlugin';

//import { catchError, retryWhen } from 'rxjs/operators';
//import { of } from 'rxjs/observable/of';
//import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
//import { GooglePlayGamesServices } from '@awesome-cordova-plugins/google-play-games-services/ngx';

//import { InAppPurchase } from '@ionic-native/in-app-purchase-2/ngx';
//import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2/index';
//import { IAPProduct } from '@ionic-native/in-app-purchase-2';
/*
import {
  InAppPurchase,
  IAPProduct,
} from '@ionic-native/in-app-purchase-2/ngx';
*/
//import dragonBones from './dragonBones/plugin/DragonBonesPlugin';
//import *  as TransformMatrix from './dragonBones/util/TransformMatrix'

declare var paypal: any;
//declare var cordova: any;


interface serversS {
  id: number;
  code: string;
  suggested: string;
  name: string;
}
interface raceS {
  id: number;
  name: string;
  descr: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //console.log('on resize fired');
    /*
     */
  }
  //selectcharsslideops: any;
  profile_avartar = 'https://assets.peacely.online/provider/defavatar.png';
  previewcreatechar = '../assets/interface/loadspin.gif';
  selectcharimg = [];
  static slidercolnum;
  slidercolnum = 4;
  gender;
  showchatfabbtn = false;
  itf_selectchar = false;
  avartarImageFile;
  //loginForm;
  initializeApp_done = false;
  phaserGame: Phaser.Game;
  config: Phaser.Types.Core.GameConfig;
  rcserverlist: serversS[] = [];
  racelist: raceS[] = [];
  rcserverlistreg: serversS[] = [];
  rcserverlistforgotpassword: serversS[] = [];
  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public forgotpassword3Form: FormGroup;
  public forgotpassword2Form: FormGroup;
  public forgotpasswordForm: FormGroup;
  resetpassword_gamerid = 0;
  login_default_email: string;
  login_default_pwd: string;
  login_default_server: string;
  register_default_server: string;
  selectcharsslideops;
  loggedin;
  createchar_race;
  createchar_gender;
  createchar_name;
  settinglang = 'en';
  settingantialias = '';
  settinglighteffects = '';
  settingweathereffects = '';
  /**/
  settinglangcb = "en";
  task_checkfirebaseauth = false;
  taskinterval;
  testermode = false;
  is_gamerunning = false;
  is_loggedin = false;
  is_showingselectchar = false;

  payPalConfig: any;
  price;
  priceToPay = 10;
  PAYPAL_CLIENT_ID_TEST = 'AcJ-DisR_zPIUMe5dTTcXR4L7-ApCtvZk6xYX_w3fBKbYXPNhula4zuugDilhW0j0zXzNBBcr2jcD6Ku';
  PAYPAL_CLIENT_ID_LIVE = 'AXCbDWzRRcV4jbZd46jDMsdFGNAWN-Cas64zETxQYfbgNpKPLMUQPBsodC-wdBOgtVi_dgUDzmvBWPDh';
  PAYPAL_CLIENT_ID = this.PAYPAL_CLIENT_ID_TEST;

  productIds = ['online.peacely.diamondpack01', 'online.peacely.diamondpack02']; // <- Add your product Ids here
  products: any;

  getCurrentAppVersion;
  getAvailableAppVersion;
  openAppStore;
  performImmediateUpdate;
  startFlexibleUpdate;
  completeFlexibleUpdate;
 
  constructor(
    //private splashScreen: SplashScreen,
    //private iab: InAppBrowser,
    public iapstore: IAPStoreService,
    public ga: GAService,
    //public gpgs: GooglePlayGamesServices,
    //public splashscreen: SplashScreen,
    public spinner: SpinnerService,
    public shipnavigation: ShipNavigationService,
    public friendlist: FriendListService,
    public petuplevel: PetUpLevelService,
    public AlertController: AlertController,
    private platform: Platform,
    public partymap: PartyMapService,
    public partymy: PartyMyService,
    public rcstorage: RCStorage,
    public rchttp: RCHTTP,
    public intl: intl,
    public anyjs: AnyJs,
    public dm: DMServices,
    public merchant: MerchantService,
    public cdr: ChangeDetectorRef,
    //public formBuilder: FormBuilder,
    private rctoast: RCToastService,
    public rcvarservice: RCVarsService,
    public rcloading: RCLoadingService,
    public rcsvvars: RCSVVARSService,
    public rcupdateitf: RCUpdateITFService,
    public phm: PhaserMapService,
    public preloader: Preloader,
    public welcome: Welcome,
    public gameitf: GameInterfaceService,
    public npcconver: NPCConverService,
    public scrw: SCRW,
    public execmod: ExecModuleService,
    public resizeman: PhaserMapResizeManagerService,
    public rcreq: RCRequirementService,
    public npcshop: NPCSHOPService,
    public stackinven: RCStackInventoryService,
    public inven: RCInventoryService,
    public equipment: RCEquipmentService,
    public rcosd: RCOSDService,
    public snd: SNDService,
    public attri: RCAttriService,
    public rcskill: RCSkillService,
    public homeinvens: RCHomeInvenStackItemsService,
    public homeinveni: RCHomeInvenItemsService,
    public charinfo: RCCharInfoService,
    public questinfo: QuestInfoService,
    public questlist: QuestListService,
    public showreward: ShowRewardService,
    public notif: NotifService,
    public diamondoffer: DiamondOfferService,
    public timectl: TimeCtlService,
    public tut: TutService,
    public eggnest: EggNestService,
    public wsrc: WSRCService,
    public petgui: PetGuiService,
    public pettravel: PetTravelService,
    public zchari: ZCharInfoService,
    //private iap: InAppPurchase2,
    public upgradeitem: UpgradeItemService,
    public upgraderar: UpgradeRarItemService,
    public pickitem: PickItemService,
    public pickpet: PickPetService,
    public farmorder: FarmOrderService,
    public pickitemitem: PickItemItemService,
    public skillcraft: RCSkillCraftService,
    public pickreward: PickRewardService,
    public combat: COMBAT,
    public farmplot: FarmPlotService,
    public weather: WeatherCtlService,
    public odsp: OverlayDSPService,
    public fishing: FishingService,
    public sparb: SparBoardService,
    public msgs: MsgsService,
    //public scro: ScreenOrientation,
    public petskill: PetSkillService,
    public authserv: AuthenticationService, //public astar: AStarFinder
    private ngzone: NgZone,
    public drama: DramaService,
    public gp: GPService,
    //public mapwalker: MapWalker
  ) {
    SplashScreen.show();
    /*
        if (this.rcvarservice.iwidth < 992) {
          this.slidercolnum = 4;
        }
        if (this.rcvarservice.iwidth < 768) {
          this.slidercolnum = 3;
        }
        if (this.rcvarservice.iwidth < 576) {
          this.slidercolnum = 2;
        }
        this.selectcharsslideops = {
          slidesPerView: 3,
          freeMode: false,
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }
        }*/
    //this.cdr.detectChanges();
    /*
    this.load.scripts('dragonBones', [
      '../dragonBones/plugin/DragonBonesFile.ts',
      '../dragonBones/plugin/DragonBonesPlugin.ts'
    ]);*/

    //firebase.initializeApp(this);
    //return;
    this.getCurrentAppVersion = async () => {
      const result = await AppUpdate.getAppUpdateInfo();
      if (Capacitor.getPlatform() === 'android') {
        return result.currentVersionCode;
      } else {
        return result.currentVersionName;
      }
    };

    this.getAvailableAppVersion = async () => {
      const result = await AppUpdate.getAppUpdateInfo();
      if (Capacitor.getPlatform() === 'android') {
        return result.availableVersionCode;
      } else {
        return result.availableVersionName;
      }
    };

    this.openAppStore = async () => {
      await AppUpdate.openAppStore();
    };

    this.performImmediateUpdate = async () => {
      const result = await AppUpdate.getAppUpdateInfo();
      if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
        return;
      }
      if (result.immediateUpdateAllowed) {
        await AppUpdate.performImmediateUpdate();
      }
    };

    this.startFlexibleUpdate = async () => {
      const result = await AppUpdate.getAppUpdateInfo();
      if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
        return;
      }
      if (result.flexibleUpdateAllowed) {
        await AppUpdate.startFlexibleUpdate();
      }
    };

    this.completeFlexibleUpdate = async () => {
      await AppUpdate.completeFlexibleUpdate();
    };
 
    this.ngzone.runOutsideAngular(async function () {
      window.onmessage = async function (evt) {
        //console.log("window.onmessage", evt)
        //console.log("window.onmessage this",this)
        var receivedMsg = evt.data;
        if (receivedMsg.cmd != undefined && receivedMsg.piid != undefined && receivedMsg.cmd == "stripecheck") {
          console.log("this onmessage", this, receivedMsg);
          await this.rcvarservice.gameobj.registry.list.iapstore.iapview_close(this.rcvarservice.gameobj.scene.scenes[0]);
          await this.rcvarservice.gameobj.registry.list.rchttp.getcommon2("stripe-chkorder", {
            piid: receivedMsg.piid
          });
          await this.rcvarservice.gameobj.registry.list.rchttp.updatecharinfo();
        }

        if (receivedMsg.cmd != undefined && receivedMsg.piid != undefined && receivedMsg.cmd == "amazondelivery") {
          console.log("this onmessage", this, receivedMsg);
          await this.rcvarservice.gameobj.registry.list.iapstore.iapview_close(this.rcvarservice.gameobj.scene.scenes[0]);
          await this.rcvarservice.gameobj.registry.list.rchttp.getcommon2("amazondelivery", {
            rec: receivedMsg.rec, sku: receivedMsg.sku
          });
          await this.rcvarservice.gameobj.registry.list.rchttp.updatecharinfo();
        }
        if (receivedMsg.cmd != undefined && receivedMsg.cmd == "globalshared") {
          console.log("app.component onmessage", receivedMsg, this);
          var saveev = await this.rcvarservice.gameobj.registry.list.rchttp.getcommon2('savesharedevent', { sharemode: "global" });
          await this.rcvarservice.gameobj.registry.list.rchttp.updatecharinfo();

        }
        if (receivedMsg.cmd != undefined && receivedMsg.cmd == "sharecombatforreward_onshare") {
          console.log("app.component onmessage", receivedMsg, this);
          var saveev = await this.rcvarservice.gameobj.registry.list.execmod.exec('sharereward');
          await this.rcvarservice.gameobj.registry.list.rchttp.updatecharinfo();

        }


      }.bind(this);
    }.bind(this));
    //console.log("Capacitor.getPlatform", Capacitor);
    //if (Capacitor.getPlatform() != "web") {
    //  StatusBar.hide();
    //}


    /*this.platform.backButton.subscribeWithPriority(10, async () => {
      console.log('Handler was called!');
      var choice = await this.rctoast.confirm('Exit?', 'Click OK to exit');
      console.log('choice', choice);
      if (choice == true) {
        navigator['app'].exitApp();
      }
    });*/
    this.login_default_server = '';
    this.login_default_email = '';
    this.login_default_pwd = '';

    /*this.loginForm = new FormGroup({
      server: new FormControl('server'),
      email: new FormControl('email'),
      pwd: new FormControl('pwd'),
    });*/
    /*
    this.loginForm = this.formBuilder.group({
      server: ['', Validators.compose([Validators.required])],
      email: [
        '',
        Validators.compose([
          Validators.minLength(5),
          Validators.maxLength(30),
          Validators.pattern(
            '^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'
          ),
          Validators.required,
        ]),
      ],
      pwd: [
        '',
        Validators.compose([Validators.minLength(3), Validators.required]),
      ],
    });
    this.registerForm = this.formBuilder.group({
      reg_server: ['', Validators.compose([Validators.required])],
      reg_email: [
        '',
        Validators.compose([
          Validators.minLength(5),
          Validators.maxLength(40),
          Validators.pattern(
            '^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'
          ),
          Validators.required,
        ]),
      ],
      reg_pwd: [
        '',
        Validators.compose([Validators.minLength(3), Validators.required]),
      ],
    });
    this.forgotpasswordForm = this.formBuilder.group({
      forgotpassword_server: ['', Validators.compose([Validators.required])],
      forgotpassword_email: Validators.compose([
        Validators.minLength(5),
        Validators.maxLength(40),
        Validators.pattern(
          '^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'
        ),
        Validators.required,
      ]),
    });
    this.forgotpassword2Form = this.formBuilder.group({
      forgotpassword_code: Validators.compose([
        Validators.minLength(5),
        Validators.maxLength(40),
      ]),
    });
    this.forgotpassword3Form = this.formBuilder.group({
      forgotpassword_newpwd: Validators.compose([
        Validators.minLength(5),
        Validators.maxLength(40),
      ]),
    });
    */
    this.rcvarservice.rcgame = this;
    //console.error("HERE");
    this.platform.ready().then(async () => {
      /*
      let enviroment = '';
      if (this.PAYPAL_CLIENT_ID == this.PAYPAL_CLIENT_ID_TEST) {
        enviroment = 'sandbox';
      } else {
        enviroment = 'live';
      }*/
      // platform.ready().then(async () => {

      // OR: if building for Amazon, be sure to follow the installation instructions then:
      //await Purchases.configure({ apiKey: <public_amazon_api_key>, useAmazon: true });
      //});
      //console.error("HERE2");
      this.initializeSession();
    });
  }
  async ngOnInit() {
  }
  testclick() {

  }
  async initializeSession() {
    //console.log('loginstage a01 initializeSession');
    //console.error("HERE");
    await this.initializeApp();
    //console.log('loginstage a02 initializeSession');

    //this.overlay_hide();//

    this.ngzone.runOutsideAngular(async function () {
      this.taskinterval = setInterval(
        async function () {
          //console.log('loginstage aT taskinterval init');

          //console.log('taskinterval', this);
          if (this.task_checkfirebaseauth == true) {
            if (
              this.authserv.userData != undefined &&
              this.authserv.userData != null
            ) {
              this.rcloading.loading();
              this.task_checkfirebaseauth = false;
              //console.log('taskinterval task_checkfirebaseauth', this);
              //var remember_server = await this.rcstorage.get('remember_server');
              var remember_server = await this.rcstorage.get('remember_server');
              //console.log(              'taskinterval remember_server',              remember_server,              this.rcvarservice.rc_sv[remember_server]            );
              var tmpservername =
                this.rcvarservice.rc_sv[remember_server]['name'];
              var clonedata = this.authserv.userData;
              this.authserv.signoutUser();

              //this.rcloading.loading();
              var forceurl = this.rcvarservice.rc_sv[remember_server]['url'];
              var connected = await this.rchttp.getcommon2(
                'loginwithsocialfirebase',
                { user: clonedata },
                forceurl
              );
              //console.log(              'loginwithsocialfirebase not loggedin',              clonedata,              connected            );
              this.rcloading.close();
              if (connected == 'notlinked') {
                var choice = await this.rctoast.confirm(
                  'Account not linked',
                  'Social media not linked to any account<BR><BR>Would you like to create an account with this social media now?<BR><BR>(Server: ' +
                  tmpservername +
                  ')'
                );
                //console.log('choice', choice);
                if (choice == true) {
                  var res = await this.rchttp.getcommon2(
                    'createandloginwithsocialfirebase',
                    { user: clonedata, server: remember_server },
                    forceurl
                  );
                  if (res == 'linked') {
                    await this.rctoast.alert(
                      'Error',
                      'This social media already linked to another account'
                    );
                  }
                  if (res == 'invalidserver') {
                    await this.rctoast.alert(
                      'Error',
                      'Invalid server selected, please try again'
                    );
                  }
                  if (res == 'emailtaken') {
                    await this.rctoast.alert(
                      'Error',
                      'This social media email already use to register to this server<BR>Please try login with the email'
                    );
                  }
                  if (res['msg'] == 'ok') {
                    await this.rcstorage.set('rc_usesv', remember_server);

                    //set default sv and url
                    this.rcvarservice.rcserver_url = this.rcvarservice.rc_sv[remember_server]['url'];
                    this.rcvarservice.rcserver_wsurl = this.rcvarservice.rc_sv[remember_server]['wsurl'];
                    this.rcvarservice.svversionstring = this.rcvarservice.rc_sv[remember_server]['versionstring'];

                    await this.rcsvvars.initallvars(this);

                    await this.rcstorage.set('rc_login', res['rclogin']);
                    await this.rcstorage.set('rc_sid', res['rcsid']);
                    await this.rcstorage.set('loggedin', 'yes');
                    this.is_loggedin = true;
                    RCVarsService.rc_sid = res['rcsid'];

                    var gamer = await this.rchttp.getcommon2('gamer');
                    this.rcvarservice.gamer = gamer;

                    var cdata = await this.rchttp.getcharacters();
                    //console.log('zzz2 cdata', cdata);
                    this.rcvarservice.allchars = cdata;
                    //console.log("zzz on login ",RCVarsService.rcserver_url);
                    //console.log("int vars:", RCVarsService.rc_sid, RCVarsService.rcserverurl);
                    //this.initializeSession();
                    //console.log('loginstage aT createandloginwithsocialfirebase');

                    this.authserv.signoutUser();
                    location.reload();///
                    //console.log("authserve", this);
                    this.phaserGame.scene.scenes[1].selectChar_build();
                    this.phaserGame.scene.scenes[1].showpage('selectchar');

                  }
                }
              }
              if (connected == 'banned') {
                this.authserv.signoutUser();
                await this.rctoast.alert(
                  'Error',
                  'Your account is currently banned, please contact support'
                );
              }
              if (connected['msg'] == 'ok') {
                await this.rcstorage.set('rc_usesv', remember_server);

                //set default sv and url
                this.rcvarservice.rcserver_url =
                  this.rcvarservice.rc_sv[remember_server]['url'];
                this.rcvarservice.rcserver_wsurl =
                  this.rcvarservice.rc_sv[remember_server]['wsurl'];
                this.rcvarservice.svversionstring =
                  this.rcvarservice.rc_sv[remember_server]['versionstring'];

                await this.rcsvvars.initallvars(this);

                await this.rcstorage.set('rc_login', connected['rclogin']);
                await this.rcstorage.set('rc_sid', connected['rcsid']);
                await this.rcstorage.set('loggedin', 'yes');
                this.is_loggedin = true;
                RCVarsService.rc_sid = connected['rcsid'];

                var gamer = await this.rchttp.getcommon2('gamer');
                this.rcvarservice.gamer = gamer;

                var cdata = await this.rchttp.getcharacters();
                //console.log('zzz2 cdata', cdata);
                this.rcvarservice.allchars = cdata;
                //console.log("zzz on login ",RCVarsService.rcserver_url);
                //console.log("int vars:", RCVarsService.rc_sid, RCVarsService.rcserverurl);
                ///this.initializeSession();
                this.authserv.signoutUser();
                //console.log("authserve", this);
                this.phaserGame.scene.scenes[1].selectChar_build();
                this.phaserGame.scene.scenes[1].showpage('selectchar');
              }
              this.rcloading.close();
            }
          }
        }.bind(this),
        500
      );
    }.bind(this));
    return;///


  }
  async reloadandplay(charid) {
    //console.log('loginstage  reloadandplay', charid);
    this.rcloading.loading();

    //await this.rcstorage.set('reloadandplay_id', charid);
    //await this.rcstorage.set('reloadandplay_id', charid);
    while (true) {
      //console.log('loginstage  reloadandplay loop check', charid);
      await this.rcstorage.set('reloadandplay_id', charid);
      var savedrap = await this.rcstorage.get('reloadandplay_id');
      if (savedrap == charid) break;
    }
    this.rcstorage.listall();
    location.reload();
  }




  compare_server(o1, o2) {
    //console.log("compare_server 1",o1);
    //console.log("compare_server 2",o2);
    //console.log("compareserver this",this);
    if (o1.suggested == 'YES') {
      return true;
    } else {
      return false;
    }
    return o1 === o2;
  }
  async initializeApp() {
    // console.error("HERE");
    await this.rcsvvars.initserversdata(this);
    //console.error("HERE2");
    if (this.initializeApp_done == true) {
      console.log('initializeApp try to execute again');
      return;
    }
    this.initializeApp_done = true;
    this.platform.ready().then(async () => {
      SplashScreen.hide();
    });
    var configantialias = false;
    var configpixelart = false;
    //scene: [Preloader, RCGame]
    this.ngzone.runOutsideAngular(async function () {
      //loggedin = RCStorage;

      //console.log('initializeApp', this.phaserGame, this);
      /*if (this.phaserGame != undefined) {
        //this.phaserGame.scene.pause('Preloader');
        //this.phaserGame= undefined;
        //var tmppldr=this.phaserGame.scene.getScene('Preloader');
        //this.phaserGame.scene.scenes[0]["timectltimer"].remove();
        //this.phaserGame.scene.scenes[0]["timectltimer1sec"].remove();
        // this.phaserGame.scene.scenes[0].registry.destroy(); // destroy registry
        //this.phaserGame.scene.scenes[0].off(); // disable all active events
        //this.phaserGame.scene.scenes[0].scene.launch('Preloader'); //.restart(); // restart current scene
      }*/
      //var chkconfigantialias = await this.rcstorage.get('setting_antialias');
      var chkconfigantialias = "no"; //antialias removed from setting page
      var chkconfigweathereffects = await this.rcstorage.get('setting_weathereffects');
      this.chkconfigweathereffects = chkconfigweathereffects + "";
      var chkconfiglighteffects = await this.rcstorage.get('setting_lighteffects');
      this.chkconfiglighteffects = chkconfiglighteffects + "";
      //var chkconfigweathereffects = await this.rcstorage.get('setting_weathereffects');
      //var chkconfiglighteffects = await this.rcstorage.get('setting_lighteffects');
      if (chkconfigantialias == 'yes') {
        configantialias = true;
        configpixelart = false;
      } else {
        configantialias = false;
        configpixelart = true;
      }

      //console.log('configantialias', configantialias);
      //console.log('configpixelart', configpixelart);
      //console.log('window wh', window.innerWidth, window.innerHeight);
      var usephasercanvastype = Phaser.AUTO;
      if (chkconfigweathereffects == "no") {
        //usephasercanvastype = Phaser.CANVAS; //lower CPU -- but it reduce fps to 10-ish, finding other way
      }
      //usephasercanvastype = Phaser.WEBGL;
      this.config = {
        powerPreference: "high-performance",
        type: usephasercanvastype,
        //type: Phaser.CANVAS,
        //width: 800,
        //height: 600,
        scale: {
          //mode: Phaser.Scale.FIT,
          mode: Phaser.Scale.RESIZE,
          //zoom: Phaser.Scale.Zoom.ZOOM_4X ,
          autoCenter: Phaser.Scale.CENTER_BOTH,
          parent: 'thecanvas',
          //mode: Phaser.Scale.NONE,
          width: window.innerWidth, // * window.devicePixelRatio,
          height: window.innerHeight, //* window.devicePixelRatio
        },
        physics: {
          default: "arcade",
          arcade: {
            debug: false
          }
        },
        input: {
          gamepad: true,
        },
        /*
        fps: {
          target: 24,
          forceSetTimeOut: true
        },*/
        pixelArt: configpixelart,
        antialias: configantialias,
        roundPixels: false,
        backgroundColor: '#000000',
        disableContextMenu: false,
        scene: [
          Preloader,
          Welcome,
        ],
        dom: {
          createContainer: true,
        },
        plugins: {
          global: [
            {
              key: 'rexVirtualJoystick',
              plugin: VirtualJoystickPlugin,
              start: true,
            },
            {
              key: 'ToonifyPipelinePlugin',
              plugin: ToonifyPipelinePlugin,
              start: true,
            },
            {
              key: 'HSLAdjustPipelinePlugin',
              plugin: HSLAdjustPipelinePlugin,
              start: true,
            },
            /*wont start if enable
            {
              key: 'rexGrayScalePipeline',
              plugin: rexGrayScalePipeline,
              start: true,
            },*/
            {
              key: 'PixelationPipelinePlugin',
              plugin: PixelationPipelinePlugin,
              start: true,
            },
            {
              key: 'rexInputTextPlugin',
              plugin: InputTextPlugin,
              start: true,
            }, {
              key: 'rexWebFontLoader',
              plugin: WebFontLoaderPlugin,
              start: true
            }/*, {
              key: 'rexUI',
              plugin: UIPlugin,
              start: true
              //mapping: 'rexUI'
            }*/
            /*, {
          key: "DragonBonesPlugin",
          plugin: dragonBones.phaser.plugin.DragonBonesPlugin,
          start: true
        }*/,
          ],
          scene: [
            {
              key: 'rexGestures',
              plugin: GesturesPlugin,
              //start: true,
              mapping: 'rexGestures'
            }
          ]/*
        scene: [
          { key: "DragonBones", plugin: dragonBones.phaser.plugin.DragonBonesScenePlugin, mapping: "dragonbone" }    // setup DB scene plugin
        ]  */, // setup DB scene plugin
        },
      };
      this.phaserGame = new Phaser.Game(this.config);

      //this.phaserGame.registry.set('scro', this.scro);
      this.phaserGame.registry.set('petuplevel', this.petuplevel);
      this.phaserGame.registry.set('rcvarpass', this.rcvarservice);
      this.phaserGame.registry.set('dm', this.dm);
      this.phaserGame.registry.set('intl', this.intl);
      this.phaserGame.registry.set('drama', this.drama);
      //this.phaserGame.registry.set('mapwalker', this.mapwalker);
      this.phaserGame.registry.set('partymap', this.partymap);
      this.phaserGame.registry.set('partymy', this.partymy);
      this.phaserGame.registry.set('snd', this.snd);
      this.phaserGame.registry.set('thethis', this);
      this.phaserGame.registry.set('merchant', this.merchant);
      this.phaserGame.registry.set('combat', this.combat);
      this.phaserGame.registry.set('msgs', this.msgs);
      this.phaserGame.registry.set('petskill', this.petskill);
      this.phaserGame.registry.set('friendlist', this.friendlist);
      //this.phaserGame.registry.set('iab', this.iab);
      this.phaserGame.registry.set('phm', this.phm);
      this.phaserGame.registry.set('pickreward', this.pickreward);
      this.phaserGame.registry.set('skillcraft', this.skillcraft);
      this.phaserGame.registry.set('rchttp', this.rchttp);
      this.phaserGame.registry.set('rcloading', this.rcloading);
      this.phaserGame.registry.set('spinner', this.spinner);
      this.phaserGame.registry.set('shipnavigation', this.shipnavigation);

      this.phaserGame.registry.set('rctoast', this.rctoast);
      this.phaserGame.registry.set('gameitf', this.gameitf);
      this.phaserGame.registry.set('npcconver', this.npcconver);
      this.phaserGame.registry.set('scrw', this.scrw);
      this.npcconver.rcvarpass = this.rcvarservice;
      this.phaserGame.registry.set('anyjs', this.anyjs);
      this.phaserGame.registry.set('execmod', this.execmod);
      this.phaserGame.registry.set('diamondoffer', this.diamondoffer);
      this.phaserGame.registry.set('rcskill', this.rcskill);
      this.phaserGame.registry.set('rcreq', this.rcreq);
      this.phaserGame.registry.set('resizeman', this.resizeman);
      this.phaserGame.registry.set('stackinven', this.stackinven);
      this.phaserGame.registry.set('inven', this.inven);
      this.phaserGame.registry.set('npcshop', this.npcshop);
      this.phaserGame.registry.set('equipment', this.equipment);
      this.phaserGame.registry.set('rcosd', this.rcosd);
      this.phaserGame.registry.set('attri', this.attri);
      this.phaserGame.registry.set('questinfo', this.questinfo);
      this.phaserGame.registry.set('questlist', this.questlist);
      this.phaserGame.registry.set('phaserGame', this.phaserGame);
      this.phaserGame.registry.set('homeinvens', this.homeinvens);
      this.phaserGame.registry.set('homeinveni', this.homeinveni);
      this.phaserGame.registry.set('charinfo', this.charinfo);
      this.phaserGame.registry.set('petgui', this.petgui);
      this.phaserGame.registry.set('farmplot', this.farmplot);
      this.phaserGame.registry.set('pettravel', this.pettravel);
      this.phaserGame.registry.set('showreward', this.showreward);
      this.phaserGame.registry.set('notif', this.notif);
      this.phaserGame.registry.set('timectl', this.timectl);
      this.phaserGame.registry.set('tut', this.tut);
      this.phaserGame.registry.set('eggnest', this.eggnest);
      this.phaserGame.registry.set('wsrc', this.wsrc);
      this.phaserGame.registry.set('iap', this.iap);
      this.phaserGame.registry.set('zchari', this.zchari);
      this.phaserGame.registry.set('upgradeitem', this.upgradeitem);
      this.phaserGame.registry.set('upgraderar', this.upgraderar);
      this.phaserGame.registry.set('pickitem', this.pickitem);
      this.phaserGame.registry.set('pickpet', this.pickpet);
      this.phaserGame.registry.set('pickitemitem', this.pickitemitem);
      this.phaserGame.registry.set('farmorder', this.farmorder);
      this.phaserGame.registry.set('rcstorage', this.rcstorage);

      this.phaserGame.registry.set('rcsvvars', this.rcsvvars);
      this.phaserGame.registry.set('weather', this.weather);
      this.phaserGame.registry.set('odsp', this.odsp);
      this.phaserGame.registry.set('fishing', this.fishing);
      this.phaserGame.registry.set('sparb', this.sparb);
      this.phaserGame.registry.set('ga', this.ga);
      this.phaserGame.registry.set('gp', this.gp);
      this.phaserGame.registry.set('iapstore', this.iapstore);

      //this.phaserGame.registry.set("astar",this.astar);
      //this.phaserGame.domContainer.style.pointerEvents = 'none';
      //this.phaserGame.config.svvarobj=this.rcsvvars;
      //console.log("initmap",this);


      this.rcvarservice.gameobj = this.phaserGame;
    }.bind(this));
    /*this.phaserGame.renderer.on("render" , function (srcCanvas, dstTexture, flipY)
    {
        if (flipY === undefined) { flipY = false; }

        var gl = this.gl;

        var width = srcCanvas.width;
        var height = srcCanvas.height;

        if (width > 0 && height > 0)
        {
            this.setTexture2D(dstTexture, 0);
            
            gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, flipY);
            gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);

            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, srcCanvas);
            console.log("Hi");
            dstTexture.width = width;
            dstTexture.height = height;
    
            this.setTexture2D(null, 0);
        }

        return dstTexture;
    })*/
    //console.log("gamereg", this.phaserGame.registry);

    //this.phaserGame.registry.set('gpgs', this.gpgs);
    //loggedin=this.storage.getItem("loggedin");
    //console.log("loggedin",loggedin);
  }


  /*
    async rclogin_btn() {
      var login_c_server;
      login_c_server = document.getElementById('form_login_server');
      login_c_server = login_c_server.value;
      //console.log('login_c_server 1 - ', login_c_server);
  
      this.rcloading.loading();
  
      if (login_c_server == undefined || login_c_server == '') {
        //console.log('no sv');
        this.rctoast.show('Please select server');
        return;
      }
      //login_c_server=login_c_server.code;
      //console.log("login_c_server code", login_c_server);
      var login_c_login;
      login_c_login = document.getElementById('form_login_login');
      login_c_login = login_c_login.value;
      //console.log('login_c_login', login_c_login);
      if (login_c_login == undefined || login_c_login == '') {
        this.rctoast.show('Please enter valid email');
        this.rcloading.close();
        return;
      }
      var login_c_pwd;
      login_c_pwd = document.getElementById('form_login_pwd');
      login_c_pwd = login_c_pwd.value;
      if (login_c_pwd == undefined || login_c_pwd == '') {
        this.rctoast.show('Please enter password');
        this.rcloading.close();
        return;
      }
      var login_c_remember;
      login_c_remember = document.getElementById('login_remember');
      //console.log('login_c_remember', login_c_remember);
      var rememberme = login_c_remember.checked;
      if (rememberme == true) {
        await this.rcstorage.set('remember_server', login_c_server);
        await this.rcstorage.set('remember_email', login_c_login);
        //this.rcstorage.set("remember_pwd", login_c_pwd);
        await this.rcstorage.listall();
      }
      var sv;
      var postdat;
      postdat = {
        server: login_c_server,
        email: login_c_login,
        pwd: login_c_pwd,
      };
  
      sv = await this.rchttp.login(login_c_server, postdat);
      //console.log("login result:",sv);
      if (sv['msg'] == 'ok') {
        //console.log('login success', sv);
        await this.rcstorage.set('rc_usesv', login_c_server);
        this.is_loggedin = true;
  
        //set default sv and url
        this.rcvarservice.rcserver_url =
          this.rcvarservice.rc_sv[login_c_server]['url'];
        this.rcvarservice.rcserver_wsurl =
          this.rcvarservice.rc_sv[login_c_server]['wsurl'];
        this.rcvarservice.svversionstring =
          this.rcvarservice.rc_sv[login_c_server]['versionstring'];
  
        await this.rcsvvars.initallvars(this);
  
        await this.rcstorage.set('rc_login', login_c_login);
        await this.rcstorage.set('rc_sid', sv['rcsid']);
        await this.rcstorage.set('loggedin', 'yes');
        this.is_loggedin = true;
        RCVarsService.rc_sid = sv['rcsid'];
  
        var gamer = await this.rchttp.getcommon2('gamer');
        this.rcvarservice.gamer = gamer;
  
        var cdata = await this.rchttp.getcharacters();
        console.log('zzz2 cdata', cdata);
        this.rcvarservice.allchars = cdata;
        //console.log("zzz on login ",RCVarsService.rcserver_url);
        //console.log("int vars:", RCVarsService.rc_sid, RCVarsService.rcserverurl);
        this.initGame();
        this.rcloading.close();
      }
      this.rcloading.close();
      //or
      //console.log("login_c_server", login_c_server);
    }*/
  async initGame(hideoverlay = true) {
    console.log('initGame');
    if (hideoverlay == true) this.overlay_hide();
    //this.overlay_show('selectcharacter', false);
    this.rc_selectcharacter(false);
    this.cdr.detectChanges();
  }
  async rc_selectcharacter(backbtn: boolean) {
    //this.rcstorage.listall();
    //var cdata = await this.rchttp.getcharacters();
    var tmp = await this.rcupdateitf.recreate_selectcharacter(this);
    console.log('rc_selectcharacter', tmp);
    if (this.is_gamerunning == false) backbtn = false;
    this.overlay_show('selectcharacter', backbtn);
  }
  overlay_page_hide() {
    var allpages = [
    ];
    var el;
    for (let index = 0; index < allpages.length; index++) {
      const element = allpages[index];
      console.log("element-", element);
      el = document.getElementById('overlaypage_' + element);
      el.style.visibility = 'hidden';
      //el.style.visible = false;
      el.style.display = 'none';

      //console.log("this.overlay_page_hide();",el);
    }
    this.content.scrollToTop(100);
  }
  overlay_page_show(page: string) {
    this.overlay_page_hide();

    var el;
    el = document.getElementById('overlaypage_' + page);
    //console.log("xxxx",this);
    this.content.scrollToTop(100);
    if (el == null) return;
    //console.log("elelel", el);
    el.style.visibility = 'visible';
    el.style.display = 'block';
  }
  overlay_show(page: string, showclose?: boolean) {
    console.log('overlay_show', page, showclose);
    this.overlay_page_hide();
    return;
    var el;
    this.ga.se(page);
    var elgamemenu = document.getElementById('gamemenu');
    elgamemenu.style.visibility = 'hidden';
    el = document.getElementById('overlayclose');
    el.style.visibility = 'hidden';

    el = document.getElementById('overlay');
    el.style.height = 'auto';
    el.style.visibility = 'visible';
    el = document.getElementById('overlaybg');
    el.style.visibility = 'visible';
    if (page == 'selectcharacter') {
      this.rcupdateitf.show_ifbtns(this);
      this.is_showingselectchar = true;
      if (this.is_loggedin == false) showclose = false;
    } else {
      this.is_showingselectchar = false;
    }
    if (showclose == true) {
      el = document.getElementById('overlayclose');
      el.style.visibility = 'visible';
    }
    this.overlay_page_show(page);
    window.scrollTo(0, 0);

    elgamemenu.style.visibility = 'visible';
    this.showchatfabbtn = false;
  }
  odsp_hide() {
    var el;
    el = document.getElementById('odspdiv');
    el.style.visibility = 'hidden';
    el = document.getElementById('odspdivclose');
    el.style.visibility = 'hidden';
  }
  overlay_hide() {
    /*
    if (!this.is_showingselectchar) {
      this.rcupdateitf.hide_ifbtns(this);
    }*/
    this.is_showingselectchar = false;
    this.overlay_page_hide();
    var el;
    el = document.getElementById('overlay');
    el.style.visibility = 'hidden';
    el = document.getElementById('overlayclose');
    el.style.visibility = 'hidden';
    el = document.getElementById('overlaybg');
    el.style.visibility = 'hidden';
    if (
      this.rcvarservice.rcserver_url == undefined ||
      this.rcvarservice.rcserver_url == '' ||
      this.is_gamerunning == false
    ) {
      if (this.is_loggedin == true) {
        this.rc_selectcharacter(false);
        //this.overlay_show('selectcharacter');
        this.is_gamerunning = true;
      } else {
        this.overlay_show('login');
      }
    }
    window.scrollTo(0, 0);
  }






  async initializePayment() {
    //console.log('initializePayment', this);
    var charid = 0;
    if (this.rcvarservice.activechar != undefined) {
      charid = this.rcvarservice.activechar.id;
    }
    if (charid == 0) {
      //console.log('initializePayment skip, charid=0');
      return;
    }
    var proddata;
    proddata = await this.rchttp.getcommon2('iapstore');
    this.rcvarservice.iapstoredata = proddata;
    /*console.log(
      'reloadandplay_play() proddata',
      proddata,
      this.rcvarservice.iapstoredata
    );*/

    var modes;
    this.rcvarservice.paymentoptions = [];
    //console.log("this.platform", this.platform);
    //console.log("this.platforms()", this.platform.platforms());
    //console.log("Capacitor.getPlatform()", Capacitor.getPlatform());
    //alert("1");
    if (Capacitor.getPlatform() === 'android') {
      //if (this.platform.is('android')) { 
      //if (!(cordova === null) && cordova != undefined && cordova.platformId == "android") {
      modes = await this.rchttp.getcommon2('paymentmethods', {
        mycc: this.rcvarservice.mycc,
        platform: 'android',
      });
      if (Array.isArray(modes)) {
        this.rcvarservice.paymentoptions = modes;
        //this.rchttp.getcommon2("log",{msg: "paymentoptions,"+this.rcvarservice.mycc+",android"});
      } else {
        this.rcvarservice.paymentoptions = [];
        this.rchttp.getcommon2('log', {
          msg: 'no paymentoptions found,' + this.rcvarservice.mycc + ',android',
        });
      }
      console.log(
        'initializePayment android',
        this.rcvarservice.paymentoptions
      );
    }
    if (Capacitor.getPlatform() == "web") {
      modes = await this.rchttp.getcommon2('paymentmethods', {
        mycc: this.rcvarservice.mycc,
        platform: 'web',
      });
      if (Array.isArray(modes)) {
        this.rcvarservice.paymentoptions = modes;
        //this.rchttp.getcommon2("log",{msg: "paymentoptions,"+this.rcvarservice.mycc+",android"});
      } else {
        this.rcvarservice.paymentoptions = [];
        this.rchttp.getcommon2('log', {
          msg: 'no paymentoptions found,' + this.rcvarservice.mycc + ',desktop',
        });
      }
      //console.log(        'initializePayment desktop',        this.rcvarservice.paymentoptions      );
    }
    /////////////////
    if (this.rcvarservice.paymentoptions.includes('iap2')) {
      this.initializePayment_iap2();
    }
    if (this.rcvarservice.paymentoptions.includes('paypal')) {
      this.initializePayment_paypal();
    }
    if (this.rcvarservice.paymentoptions.includes('rc')) {
      this.initializePayment_rc();
    }
    //console.log('initializePayment() iapstoredata', this.rcvarservice.iapstoredata);

    for (var k in this.rcvarservice.iapstoredata) {
      var v: string[] = [];
      v = this.rcvarservice.iapstoredata[k];
      if (this.rcvarservice.iapstoredata[k]["price"] == undefined) {//not googleplay
        this.rcvarservice.iapstoredata[k]["price"] = this.rcvarservice.iapstoredata[k]["price_usd"];
        v["currency"] = "USD";
      }
      //console.log("iapstoredata", this.rcvarservice.iapstoredata[k]);
    }
  }
  async initializePayment_paypal() {
    this.phaserGame.registry.set('paypal', paypal);
    console.log('paypal', paypal);
  }
  async initializePayment_rc() {
    //this.phaserGame.registry.set('paypal', paypal);
    await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs
    var tmpcurrentlogin = await this.rcstorage.get('rc_login');
    var tmplastplaycharid = 0;
    if (this.rcvarservice.activechar != undefined) {
      tmplastplaycharid = this.rcvarservice.activechar.id;
    }
    //alert(tmpcurrentlogin);
    if (tmplastplaycharid == 0) {
      console.warn("initializePayment_rc() skip, charid=0");
      return;
    }
    if (Capacitor.getPlatform() === 'ios') {
      //await Purchases.configure({ apiKey: "strp_jlLGmLovXxYRXudijHsAUAVhGgV" });
    } else if (Capacitor.getPlatform() === 'android') {
      await Purchases.configure({
        apiKey: "goog_JvdVxSHufJqawPhuOOKQBgsTFCy",
        appUserID: tmpcurrentlogin + "#" + tmplastplaycharid
      });
    }

    console.log('initializePayment_rc',);
  }
  initializePayment_iap2_ran = false;
  async initializePayment_iap2() {
    console.log('initializePayment_iap2() iapstoredata', this.rcvarservice.iapstoredata);
    if (this.initializePayment_iap2_ran == true) {
      console.log("skip, initializePayment_iap2_ran=true;");
      return;
    }
    this.initializePayment_iap2_ran = true;

    try {
      //this.iap.validator = "https://billing.fovea.cc/";
      /*
            this.iap.verbosity = this.iap.DEBUG;
      
            for (const k in this.rcvarservice.iapstoredata) {
              var v = this.rcvarservice.iapstoredata[k];
              //console.log('initializePayment_iap2() proddata i', k, v);
              var productchk = await this.iap.get(v["code"]);
              if (productchk == undefined) {
                console.log('initializePayment_iap2() REGGING', k, v, this.iap);
              }
              console.log('initializePayment_iap2() proddata i', k, v, this.iap);
              //console.log('initializePayment_iap2() proddata type', this.iap);
      
              if (v['type1'] == 'CONSUMABLE') {
                this.iap.register({
                  id: v['code'],
                  type: this.iap.CONSUMABLE,
                  // alias: v['code'],
                });
              }
              if (v['type1'] == 'NON_CONSUMABLE') {
                this.iap.register({
                  id: v['code'],
                  type: this.iap.NON_CONSUMABLE,
                  //alias: v['code'],
                });
              }
              if (v['type1'] == 'PAID_SUBSCRIPTION') {
                this.iap.register({
                  id: v['code'],
                  type: this.iap.PAID_SUBSCRIPTION,
                  //alias: v['code'],
                });
              }
              //
              // Register event handlers for the specific product
              /*this.iap.when(v['code']).registered(async (product: IAPProduct) => {
                console.log('proddataRegistered: ', product);//+ JSON.stringify(product));
      
                //amend iapstoredata, to make sure data match by terms and agreement
                //this.rcvarservice.iapstoredata[k]["name"] =
                //  this.rcvarservice.iapstoredata[k]["descr"] =
                //  this.rcvarservice.iapstoredata[k]["price"] =
              });*/
      /*
      this.iap.when(v['code']).approved(async (product: IAPProduct) => {
        // Purchase was approved
        console.log('proddataApproved', product, arguments);//+ JSON.stringify(product));
        var chkorder = await this.rchttp.getcommon2("iapa-chkorder", {
          productData: product,
        });
        await this.phaserGame.scene.scenes[0].registry.list.rchttp.updatecharinfo();
        product.finish();
        //this.subscribe(); 
      });
      // Updated
      this.iap.when(v['code']).updated(async (product: IAPProduct) => {
        console.log('proddataUpdated', product, arguments, this);//+ JSON.stringify(product));
        /* var chkorder = await this.rchttp.getcommon2("log", {
          cate: "proddataUpdated",
          productData: product,
          ag: arguments
        });* /
        if (product["transaction"] != null && product["transaction"] != undefined) {
          console.log('proddataUpdated:with transaction', product, arguments);//+ JSON.stringify(product));
          var chkorder = await this.rchttp.getcommon2("iapa-chkorder", {
            productData: product,
            fromupdate: "yes"
          });
          //this.phaserGame.scene.scenes[0].registry.list.rctoast.rcalert(this.phaserGame.scene.scenes[0], "Order Received", "We got your order, but seems the payment is pending, when it approved we will send item(s) to your inbox");
        }
        this.phaserGame.scene.scenes[0].registry.list.iapstore.iapview_close(this.phaserGame.scene.scenes[0]);
      });
      */
      /*
       // User closed the native purchase dialog
       this.iap.when(v['code']).cancelled(async (product) => {
         / *var chkorder = await this.rchttp.getcommon2("log", {
           cate: "purchasecancelled",
           product: product,
           ag: arguments
         });* /
         //this.ga.se2("purchasecancelled",v["code"])
         console.warn('proddataPurchase was Cancelled', product, arguments);
       });
       */
      /*
       this.iap.when(v['code']).error(async (error) => {
         console.error('proddataPurchase has error', error, this);
         this.ga.se2("purchaseerror", v["code"])
         var chkorder = await this.rchttp.getcommon2("log", {
           cate: "purchaseerror",
           err: error,
           ag: arguments
         });
       });
       * /
       //continue;
       //break;//
     }
*/
      // Run some code only when the store is ready to be used
      /*this.iap.ready(() => {
        console.log('proddataStore is ready');
        console.log('proddataProducts: ', this.iap.products);
        //console.log("proddata",JSON.stringify(this.iap.get("my_product_id")));
      });*/

      // Track all store errors
      /*this.iap.error((err) => {
        console.error('proddataStore Error ', JSON.stringify(err));
        console.error('proddataStore Error ', err);
      });*/

      // Refresh the status of in-app products
      //this.iap.refresh();

    } catch (err) {
      console.log('Error On Store Issues', err);
    }
  }



  stopevent(event) {
    console.log('stopevent', event);
    event.preventDefault();
    event.stopPropagation();
    if (event.srcEvent != undefined) event.srcEvent.stopPropagation();
  }
  tmplog(event, myParam: any) {
    console.log('tmplog', event, myParam, this);
    console.log('tmplog event.path[1].tagName', event.path[1].tagName);

    if (event.path[1].tagName == 'A') {
      console.log('tmplog event allow');
    } else {
      event.preventDefault();
      event.stopPropagation();
      if (event.srcEvent != undefined) event.srcEvent.preventDefault();
      if (event.srcEvent != undefined) event.srcEvent.stopPropagation();
    }
  }
  openWebpage(url: string) {
    /*const options: InAppBrowserOptions = {
      zoom: 'no'
    }
    // Opening a URL and returning an InAppBrowserObject
    const browser = this.iab.create(url, '_self', options);
   // Inject scripts, css and more with browser.X
   */
    //const browser = this.iab.create(url, '_self');
    window.open(url, '_blank');
  }



  avartarImageFilePath_change(event) {
    console.log('avartarImageFilePath_change', event);
    console.log('avartarImageFilePath_change .target', event.target);
    this.avartarImageFile = event.target.files[0];
    console.log('avartarImageFilePath_change', this.avartarImageFile);

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      //data = data.replace(/['"]+/g, '');
      //$('#imageupload').attr('src', e.target.result);
      this.rcloading.loading();
      reader.onload = async function (e) {
        //console.log("avartarImageFilePath_change loaded",e.target.result);
        await this.rchttp.uploadavartar(e.target.result);

        var gamer = await this.rchttp.getcommon2('gamer');
        this.rcvarservice.gamer = gamer;
        this.profile_avartar = gamer['avatar'];
        //console.log(this.rcvarservice);
        this.rcvarservice.gameobj.registry.list.gameitf.updateava(
          this.rcvarservice.gameobj.scene.scenes[0]
        );
        this.rcloading.close();
        //console.log(e)
      }.bind(this);
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  openAvartarDialog() {
    document.getElementById('uploadavartar').click();
  }
  fabchatbtnclick(ev) {
    console.log('fabchatbtnclick', this, ev);
    this.gameitf.showchatinput(this.phaserGame.scene.scenes[0]);



  }
}
