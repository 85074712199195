import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class FriendListService {
  shopdragbtn;
  title;
  empty;
  loading;

  skicon = [];
  skob = [];
  sktt = [];
  skbg = [];
  skdescr = [];
  skdescr2 = [];
  msgicon = [];
  fulldat;
  resptotalpage;
  respperpage = 6;
  currentresppage = 1;

  shopbackbtn;
  searchbyname;
  shopfwdbtn;

  dwidescr_weekly;
  wwdata;
  dwichest_weekly;
  wwtitle = [];
  wwdescr = [];
  wwicon = [];
  wwbtn = [];

  lastdat;
  ddtitle = [];
  dddescr = [];
  ddicon = [];
  ddbtn = [];
  dwidescr_daily;
  dwichest_daily;
  countpets;
  friendlistcontainer_char;
  //maskg;
  //maskm;
  selectcircle;
  scrollareabox;
  shopclosebtn;
  helpicon;
  itemdescr;
  eqitems = [];
  eqitemsp = [];
  itemwh = 54;
  shopguibg;
  wh;
  menubaritem = [];
  btnstory;
  btndaily;
  btnweekly;
  countpet;
  mode = '';
  plog;

  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
  }

  loadingshow() {
    this.wh.friendlistcontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(this.wh.friendlistcontainer);
    }
    this.firsttimerun = false;
  }

  async show(wh, mode, forcefocus = false) {
    this.mode = mode;
    if (forcefocus == true) this.firsttimerun = true;

    this.wh = wh;
    if (wh.friendlistcontainer != undefined) {
      await this.show_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('sparboard show', wh, this);

    this.shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");
    this.shopbackbtn.setOrigin(0.5, 0.5);
    this.shopbackbtn.setInteractive();
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");
    this.shopfwdbtn.setOrigin(0.5, 0.5);
    this.shopfwdbtn.setInteractive();
    this.shopfwdbtn.removeAllListeners();

    this.searchbyname = wh.add.sprite(0, 0, "thesb", "searchbyname");
    this.searchbyname.setOrigin(0.5, 0.5);
    this.searchbyname.setInteractive();
    this.searchbyname.removeAllListeners();

    const shopguibg = wh.add.image(0, 0, 'paperwithheadguibg');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    //this.plog.log("questgui var", this.rcvarsservice);

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );


    this.helpicon = wh.add.sprite(300, 300, "thesq", 'help');
    this.helpicon.setOrigin(0.5, 0.5);
    this.helpicon.setPosition(165, -160);
    this.helpicon.displayWidth = 40;
    this.helpicon.displayHeight = 40;
    this.helpicon.setInteractive();
    this.helpicon.removeAllListeners();
    this.helpicon.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.wh.registry.list.rctoast.rclongalert(this.wh, "Contact List", this.wh.gl("Click on player on map to add contact"));
        //this.show_close(wh);
      },
      this
    );

    wh.friendlistcontainer = wh.add.container(300, 300);
    wh.friendlistcontainer._refsrv = this;
    wh.friendlistcontainer.name = "friendlistcontainer";
    wh.friendlistcontainer.add(this.shopclosebtn);
    wh.friendlistcontainer.add(this.helpicon);
    wh.friendlistcontainer.add(shopguibg);
    wh.friendlistcontainer.bringToTop(this.shopclosebtn);
    wh.friendlistcontainer.bringToTop(this.helpicon);
    var itemdescr = wh.add.text(0, 0, '', wh.fs["winguibardescr"]);
    wh.friendlistcontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-70, -164);
    this.itemdescr = itemdescr;

    this.shopbackbtn.displayWidth = 100;
    this.shopfwdbtn.displayWidth = 100;
    this.shopbackbtn.displayHeight = 40;
    this.shopfwdbtn.displayHeight = 40;
    this.shopbackbtn.setPosition(-110, 220);
    this.shopfwdbtn.setPosition(110, 220);

    this.searchbyname.displayWidth = 100;
    this.searchbyname.displayHeight = 40;
    this.searchbyname.setPosition(0, 220);
    
    wh.friendlistcontainer.add(this.shopfwdbtn);
    wh.friendlistcontainer.add(this.shopbackbtn);
    wh.friendlistcontainer.add(this.searchbyname);
    wh.friendlistcontainer.bringToTop(this.shopfwdbtn);
    wh.friendlistcontainer.bringToTop(this.shopbackbtn);
    wh.friendlistcontainer.bringToTop(this.searchbyname);


    this.title = wh.add.text(0, 0, 'CONTACTS', wh.fs["winguititle42"]);
    wh.friendlistcontainer.add(this.title);
    wh.friendlistcontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -226);

    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );

    this.searchbyname.removeAllListeners();
    this.searchbyname.on(
      'pointerup',
      async function (pointer, localX, localY, event) {
        this.plog.log('shop searchbyname', this);
        
        var code =
          await this.rcvarsservice.gameobj.registry.list.rctoast.rcentertext(
            this.wh,
            this.wh.trs("f:exec:entercharnametitle===Search Player"),
            this.wh.trs("f:exec:entercharnamedescr===Enter Player's name")
          );
        this.plog.log('searchbyname=', code);
        if (code == 'cancel') return;
        var redeem = await wh.registry.list.rchttp.getcommon2('searchbyname', {
          search: code,
        });
        redeem=Number(redeem);
        if (redeem!="" && Math.floor(redeem)!=0) {
          wh.registry.list.zchari.show(wh, redeem);

        }

        this.plog.log('searchbyname res=', redeem);
      },
      this
    );

    this.empty = wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 0);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    wh.friendlistcontainer.add(this.empty);
    wh.friendlistcontainer.bringToTop(this.empty);
    this.empty.visible = false;


    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.friendlistcontainer.add(this.loading);
    wh.friendlistcontainer.bringToTop(this.loading);

    //drag
    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactive =
          false;
        //this.rebuildguigrid();
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          ////this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.y =
            tmpy - movedy;
          //this.maskg.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.x,this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.y)
          //this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.y = pointer.y;
          //this.setmaskposition();
        }
      },
      this
    );
    wh.friendlistcontainer.add(this.shopdragbtn);
    wh.friendlistcontainer.bringToTop(this.shopdragbtn);
    this.menubaritem.push(this.shopdragbtn);
    //skilltype bar
    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("friendlistcontainer", wh.friendlistcontainer, width, height);
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    wh.friendlistcontainer.setPosition(width / 2, height / 2);
    let loader = new Phaser.Loader.LoaderPlugin(wh);
    var loadlist = ['mob'];
    for (var k in loadlist) {
      var key = loadlist[k];
      if (this.rcvarsservice.gameobj.textures.game.textures.list[key] == undefined) {

        loader.atlas(
          key + '',
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.png' +
          svversionurlstring,
          this.wh.registry.list.rcvarpass.rc_baseurl +
          'sprite/' +
          key +
          '.json' +
          svversionurlstring
        );
      }
    }
    //move
    wh.friendlistcontainer.x = width - this.shopguibg.width / 2;
    /*
    var graphics = wh.make.g raphics();
    graphics.fillStyle(0xff0000);
    graphics.fillRect(-200, 0, 300, 300);
    //wh.friendlistcontainer.add(graphics);
    //graphics.setOrigin(0,0);
    graphics.setPosition(0, 0);
    graphics.setDepth(10000000);

    //graphics.displayHeight = graphics.displayWidth = 400;
    //this.maskg = graphics;
    this.plog.log("xxxgraphics", graphics);

    //var mask = new Phaser.Display.Masks.GeometryMask(wh.friendlistcontainer_char, graphics);
    const mask = graphics.createGeometryMask();
    this.maskm = mask;
*/

    //wh.friendlistcontainer_char.on('pointermove', function (pointer) {
    //this.plog.log("xxxcontainer", this);
    //});
    //this.setmaskposition();
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        this.plog.log('Phaser.Loader.Events.COMPLETE');
        this.plog.log(this);
        //this.show_loaded();
        //this.setmaskposition();

        this.buildpetctnr();
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer
        );
      },
      this
    );
    loader.start();
  }
  /*
  setmaskposition() {
    this.maskm.geometryMask.setPosition(
      this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.x + 50,
      this.rcvarsservice.gameobj.scene.scenes[0].friendlistcontainer.y - 130
    );
    //this.maskm.geometryMask.displayWidth=500;
  }*/
  async buildpetctnr() {
    this.plog.log('buildpetctnr');
    this.firsttimerunfunc();
    var wh = this.wh;
    if (wh.friendlistcontainer_char != undefined) {
      wh.friendlistcontainer_char.destroy();
    }

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    wh.friendlistcontainer_char = wh.add.container(0, 0);
    wh.friendlistcontainer.add(wh.friendlistcontainer_char);
    var dat;
    this.loadingshow();

    this.fulldat = await this.rchttp.getcommon2('friendlist', {});
    dat = this.fulldat['friendlist'];
    if (dat == null) {
      this.itemdescr.setText("Nothing Saved");
      this.empty.visible = true;
    } else {
      this.itemdescr.setText("Saved Contact: " + dat.length);
      this.empty.visible = false;
    }

    this.lastdat = dat;
    this.countpet = 0;
    for (const k in dat) {
      var v = dat[k];
      this.countpet++;
    }
    if (this.countpet > 6) {
      //pages

      this.respperpage = 6;
      this.resptotalpage = Math.ceil(this.countpet / this.respperpage);
    }
    this.plog.log('xxxcountpet', this.countpet);
    this.friendlistcontainer_char = wh.friendlistcontainer_char;
    wh.friendlistcontainer_char.setPosition(-120, -320);
    var i = -5;
    var rowh = 50;
    var iconwh = 40;
    var rowi = 0;
    var ii = 0;
    var rowhadjust = -260;
    for (const k in dat) {
      var v = dat[k];
      this.plog.log('xxxskdat', ii, rowi, v);
      this.msgicon[ii] = wh.add.sprite(0, 0, 'thesq', "messagedis");
      this.skicon[ii] = wh.add.sprite(0, 0, 'charava', v['sprite']);

      wh.friendlistcontainer_char.add(this.skicon[ii]);
      wh.friendlistcontainer_char.add(this.msgicon[ii]);
      this.skicon[ii].displayHeight = this.skicon[ii].displayWidth = iconwh;
      this.msgicon[ii].displayHeight = this.msgicon[ii].displayWidth = iconwh;
      this.skicon[ii].setPosition(
        iconwh / 2 - 20,
        rowi * rowh - 5 + rowhadjust
      );
      this.msgicon[ii].setPosition(
        iconwh / 2 - 55,
        rowi * rowh - 5 + rowhadjust
      );
      //this.msgicon[ii].visible=false; 
      if (v["isnew"] == 1) this.msgicon[ii].setTexture('thesq', "message");
      this.msgicon[ii].visible = false;
      //if (v['isfriend'] == 'YES') this.msgicon[ii].visible = true;
      this.msgicon[ii].visible = true; // allow msg even on-side add
      this.skbg[ii] = wh.add.image(0, 0, 'rowbg');
      wh.friendlistcontainer_char.add(this.skbg[ii]);
      this.skbg[ii].setOrigin(0.5, 0.5);
      this.skbg[ii].setInteractive();
      this.skbg[ii].removeAllListeners();
      this.skbg[ii].name = v['id'];
      this.skbg[ii].on("pointerup", function () {
        this.scene.plog.log('friendlist i click', this.name, this);
        this.scene.registry.list.zchari.show(
          this.scene,
          this.name
        );
      });

      this.msgicon[ii].setInteractive();
      this.msgicon[ii].removeAllListeners();
      this.msgicon[ii].name = v['id'];
      this.msgicon[ii].on("pointerup", function () {
        this.scene.plog.log('friendlist msg i click', this.name, this);
        this.scene.registry.list.dm.show(
          this.scene,
          this.name
        );
        this.scene.registry.list.friendlist.show_close(this.scene);
      });

      /*this.skbg[ii].on('pointermove', function (pointer) {
        //this.plog.log(this);
        //var pos = this.parentContainer.y + this.y;
        //this.plog.log("pos", pos);
        //if (pos > -150) {
        if (pointer.isDown) {
          this.scene.registry.list.petgui.friendlistcontainer_char.y += (pointer.velocity.y / 3);
          this.scene.registry.list.petgui.friendlistcontainer_char.y = Phaser.Math.Clamp(this.scene.registry.list.petgui.friendlistcontainer_char.y, 430 - (this.scene.registry.list.petgui.countpet * 50), 150);
          //this.scene.registry.list.petgui.movemenubartotop();
          //this.plog.log("xxxskill in container", 0-(this.countpet*50),this.friendlistcontainer_char.y);
        }
        //}
      });

      this.skbg[ii].on('pointerup', async function (pointer) {
        this.plog.log("xxx,skill,", this);
        //var tmpnamea = this.name.split("---");
        var data = await this.scene.registry.list.rchttp.getcommon2("allpets");

        this.scene.registry.list.rcosd.show(wh, "pet", data[this.name]);
      });*/

      this.skbg[ii].displayHeight = iconwh + 10;
      this.skbg[ii].displayWidth = 300;
      this.skbg[ii].setPosition(-20 + (this.skbg[ii].displayWidth / 2), rowi * rowh - rowh / 2 - 5 + rowhadjust + (this.skbg[ii].displayHeight / 2));
      wh.friendlistcontainer_char.sendToBack(this.skbg[ii]);

      var namedsp = v['name'] + '';
      if (namedsp == '') namedsp = '??';
      //namedsp = namedsp + ' lv.' + v["char"]['level'];
      this.sktt[ii] = wh.add.text(0, 0, namedsp, wh.fs["list_title"]);
      wh.friendlistcontainer_char.add(this.sktt[ii]);
      wh.friendlistcontainer_char.bringToTop(this.sktt[ii]);
      this.sktt[ii].setPosition(30, rowi * rowh - 25 + rowhadjust);
      //tmpskname.setResolution(0.25);
      this.plog.log('zzzzzz', this.sktt[ii]);
      var racetext = "";
      var maptext = "";
      if (wh.registry.list.rcvarpass.allrace[v["race"]] != undefined) {
        racetext = wh.registry.list.rcvarpass.allrace[v["race"]]["name"];
      }
      if (wh.registry.list.rcvarpass.allmap[v["map"]] != undefined) {
        maptext = wh.registry.list.rcvarpass.allmap[v["map"]]["name"];
      }
      this.skdescr[ii] = wh.add.text(0, 0, racetext + ' lv.' + v['level'] + ' ' + v['lastonlinestr'], wh.fs["list_descr"]);
      this.skdescr2[ii] = wh.add.text(0, 0, maptext + '', wh.fs["list_descr"]);
      wh.friendlistcontainer_char.add(this.skdescr[ii]);
      wh.friendlistcontainer_char.bringToTop(this.skdescr[ii]);
      this.skdescr[ii].setPosition(30, rowi * rowh - 2 + rowhadjust);
      wh.friendlistcontainer_char.add(this.skdescr2[ii]);
      wh.friendlistcontainer_char.bringToTop(this.skdescr2[ii]);
      this.skdescr2[ii].setPosition(280 - this.skdescr2[ii].displayWidth, rowi * rowh - 20 + rowhadjust);



      i++;
      ii++;
      rowi++;
      if (rowi == 6) rowi = 0;
    }
    this.friendlistcontainer_char.y = 150;
    //wh.friendlistcontainer_char.setMask(this.maskm);
    this.showinventory_showpage(wh, 'back');

    //this.plog.log("xxxmask", this.maskm);
    this.wh.registry.list.rcloading.close();
    this.loadinghide();

  }

  showinventory_showpage(gc, viewpage) {
    if (gc == undefined || gc == null) gc = this.wh;
    var griddat = [];
    var griddatf = [];
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 200; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = false;
      }
      if (this.sktt[i] != undefined) {
        this.sktt[i].visible = false;
      }
      if (this.skob[i] != undefined) {
        this.skob[i].visible = false;
      }
      if (this.skbg[i] != undefined) {
        this.skbg[i].visible = false;
      }
      if (this.skdescr[i] != undefined) {
        this.skdescr[i].visible = false;
      }
      if (this.skdescr2[i] != undefined) {
        this.skdescr2[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );



    griddat.push(this.shopclosebtn);
    griddat.push(this.helpicon);
    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);

    for (var i = numfrom; i < numto; i++) {
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = true;
        this.skbg[i].visible = true;
        if (this.skob[i] != undefined) this.skob[i].visible = true;
        this.sktt[i].visible = true;
        this.skdescr[i].visible = true;
        this.skdescr2[i].visible = true;
        griddat.push(this.skbg[i]);
        griddatf.push(this.skbg[i]);
        //griddat.push(this.msgicon[i]);

      }
    }
    this.wh.registry.list.gp.guigrid(griddat, griddatf);
  }

  async show_close(wh) {
    if (wh.friendlistcontainer != undefined)
      wh.friendlistcontainer.visible = false;
    this.wh.registry.list.gameitf.closegui(wh, wh.friendlistcontainer);

    for (const k in this.wh.friendlistcontainer.list) {
      try {
        await this.wh.friendlistcontainer.remove(this.wh.friendlistcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! friendlistcontainer.destroy() ", e);
      }
    }
    for (const k in this.wh.friendlistcontainer.list) {
      try {
        await this.wh.friendlistcontainer.remove(this.wh.friendlistcontainer.list[k], true);
        //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
      } catch (e) {
        this.plog.log("ERROR! friendlistcontainer.destroy() ", e);
      }
    }

    try {
      await this.wh.friendlistcontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! friendlistcontainer.destroy() ", e);
    }
    try {
      await this.wh.friendlistcontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! friendlistcontainer.destroy() ", e);
    }
    this.wh.friendlistcontainer = undefined;
  }
  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.showinventory_showpage(null, "");
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
