import { Injectable } from '@angular/core';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root'
})
export class EggNestService {
  wh;
  plog;

  constructor() {
    this.plog = new plog();
  }

  async show(wh) {
    this.wh = wh;


    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != "") {
      svversionurlstring = "?" + svversionurlstring;
    }
    let loader = new Phaser.Loader.LoaderPlugin(this.wh);
    var loadlist = ["mob"];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(key + "", this.wh.registry.list.rcvarpass.rc_baseurl + "sprite/" + key + ".png" + svversionurlstring, this.wh.registry.list.rcvarpass.rc_baseurl + "sprite/" + key + ".json" + svversionurlstring);
    }
    loader.once(Phaser.Loader.Events.COMPLETE, () => {
      this.plog.log("Phaser.Loader.Events.COMPLETE");
      this.plog.log(this);
      this.show_loaded();
    });
    loader.start();
  }
  async show_loaded() {
    var eggres = await this.wh.registry.list.rchttp.getcommon2("eggneststatus");
    this.plog.log("eggnest eggres", eggres);
    if (eggres["status"] == "waiting") {
      //await this.wh.registry.list.rctoast.rcalert(this.wh, "Nest status", eggres["msg"]);
      //this.wh.registry.list.phm.charsaybubble(this.wh.char_main,eggres["msg"]);

      var cfm = await this.wh.registry.list.rctoast.rcconfirm(this.wh, "Incubating", eggres["msg"]);
      if (cfm == "ok") {
        //await this.wh.registry.list.rchttp.getcommon2("wishingwell", { wellid: moda[1], mode: "risk" });
        var eggffres = await this.wh.registry.list.rchttp.getcommon2("eggneststatus", { ff: "yes" });
        this.plog.log("eggnest eggffres", eggffres);
        if (eggffres=="ok") {
          this.show(this.wh);
        }
      }
    }
    if (eggres["status"] == "empty") {
      //await this.wh.registry.list.rctoast.rcalert(this.wh, "Nest status", eggres["msg"]);
      this.wh.registry.list.phm.charsaybubble(this.wh.char_main, eggres["msg"]);
    }
    if (eggres["status"] == "hatched") {
      this.plog.log("eggres hatched", this.wh.registry);
      await this.wh.registry.list.showreward.show(this.wh, ["mob:" + eggres["obtain"]]);
    }
    await this.wh.registry.list.rchttp.updatecharinfo();
    this.plog.log("EggNestService show", this.wh);
    this.wh.registry.list.eggnest.chkmapdsp(this.wh);
  }
  async incubate(wh, id) {
    this.wh = wh;
    var res = await wh.registry.list.rchttp.getcommon2("incubate", { stackitemid: id });
    this.plog.log("egg incubate res ", res);
    if (res == "ok") {
      this.wh.registry.list.eggnest.show(wh);
    }
  }
  chkmapdsp(wh) {
    this.wh = wh;
    //alter npc sprite if incubating
    //this.plog.log("EggNest chkmapdsp", wh);

    if (this.wh.registry.list.rcvarpass.activechar["map"] == "home" && this.wh.registry.list.rcvarpass.activechar["eggnest"]["dte"] != undefined) {
      for (const k in this.wh.npclist) {
        var v = this.wh.npclist[k];
        //this.plog.log("EggNest chkmapdsp", v.name);
        var tmpnamea = v.name.split("-");
        if (tmpnamea[1] == "eggincubator") {
          v.anims.stop();
          v.setTexture('nestwithegg');
        }
      };
    } else {
      for (const k in this.wh.npclist) {
        var v = this.wh.npclist[k];
        //this.plog.log("EggNest chkmapdsp", v.name);
        var tmpnamea = v.name.split("-");
        if (tmpnamea[1] == "eggincubator") {
          v.anims.stop();
          v.setTexture('nest');
        }
      };
    }
  }
}
