import { Injectable } from '@angular/core';
import { RCVarsService } from './rcvars-service.service';
import { RCOSDService } from './rcosd.service';
import { RCHTTP } from './rchttp.service';
import { plog } from './plog.service';

@Injectable({
  providedIn: 'root',
})
export class PetGuiService {
  shopdragbtn;
  empty;
  title;
  plog;
  removebtn = [];
  renamebtn = [];
  skicon = [];
  skob = [];
  sktt = [];
  skbg = [];
  skdescr = [];
  loading;
  resptotalpage;
  respperpage = 6;
  currentresppage = 1;

  shopbackbtn;
  shopfwdbtn;

  maxpetinfo = [];
  dwidescr_weekly;
  wwdata;
  dwichest_weekly;
  wwtitle = [];
  wwdescr = [];
  wwicon = [];
  wwbtn = [];

  lastdat;
  ddtitle = [];
  dddescr = [];
  ddicon = [];
  ddbtn = [];
  dwidescr_daily;
  dwichest_daily;
  countpets;
  petguicontainer_char;
  //maskg;
  //maskm;
  selectcircle;
  scrollareabox;
  shopclosebtn;
  itemdescr;
  eqitems = [];
  eqitemsp = [];
  itemwh = 54;
  shopguibg;
  wh;
  menubaritem = [];
  btnstory;
  btndaily;
  btnweekly;
  countpet;
  takebtn = [];
  homebtn = [];
  constructor(
    public rcvarsservice: RCVarsService,
    public rcosd: RCOSDService,
    public rchttp: RCHTTP
  ) {
    this.plog = new plog();
  }

  firsttimerun = true;
  firsttimerunfunc() {
    if (this.firsttimerun == true) {
      this.wh.registry.list.gameitf.guiwindowclick(this.wh.petguicontainer);
    }
    this.firsttimerun = false;
  }

  loadingshow() {
    this.wh.petguicontainer.bringToTop(this.loading);
    this.loading.visible = true;
  }
  loadinghide() {
    this.loading.visible = false;
  }
  async show(wh, forcefocus = false) {
    if (forcefocus == true) this.firsttimerun = true;

    this.wh = wh;
    /*
    if (wh.petguicontainer != undefined) {
      wh.petguicontainer.visible = true;
      //this.setmaskposition();

      this.buildpetctnr();
      this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
        this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer
      );
      return;
    }*/

    if (this.wh.petguicontainer != undefined) {
      await this.show_close(this.wh);
    }
    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;
    this.plog.log('petgui show', wh, this);
    this.maxpetinfo = await this.wh.registry.list.rchttp.getcommon2(
      'pet_getmaxpetinfo',
      {}
    );
    this.shopbackbtn = wh.add.sprite(0, 0, "thesb", "backsign");//wh.add.image(0, 0, 'listbackbtn');
    this.shopbackbtn.setOrigin(0.5, 0.5);
    this.shopbackbtn.setInteractive();
    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn = wh.add.sprite(0, 0, "thesb", "forwardsign");//wh.add.image(0, 0, 'listfwdbtn');
    this.shopfwdbtn.setOrigin(0.5, 0.5);
    this.shopfwdbtn.setInteractive();
    this.shopfwdbtn.removeAllListeners();

    const shopguibg = wh.add.image(0, 0, 'blankbarguibg');
    shopguibg.setOrigin(0.5, 0.5);
    shopguibg.setInteractive({ pixelPerfect: true });
    shopguibg.removeAllListeners();
    this.shopguibg = shopguibg;
    //this.plog.log("questgui var", this.rcvarsservice);

    this.shopclosebtn = wh.add.sprite(300, 300, "thesq", 'close');
    this.shopclosebtn.name = 'skipcntnrsizecal';
    this.shopclosebtn.setOrigin(0.5, 0.5);
    this.shopclosebtn.setPosition(155, -200);
    this.shopclosebtn.displayWidth = 40;
    this.shopclosebtn.displayHeight = 40;
    this.shopclosebtn.setInteractive();
    this.shopclosebtn.removeAllListeners();
    this.shopclosebtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        this.plog.log(
          'gc.npcbackbtn pointerup',
          pointer,
          localX,
          localY,
          event,
          this
        );
        this.show_close(wh);
      },
      this
    );

    wh.petguicontainer = wh.add.container(300, 300);
    wh.petguicontainer._refsrv = this;
    wh.petguicontainer.visible = false;
    wh.petguicontainer.name = "petguicontainer";
    wh.petguicontainer.add(this.shopclosebtn);
    wh.petguicontainer.add(shopguibg);
    wh.petguicontainer.bringToTop(this.shopclosebtn);
    var itemdescr = wh.add.text(0, 0, '', wh.fs['winguiitemdescr']);
    wh.petguicontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-140, 179);
    this.itemdescr = itemdescr;


    //console.log(this.maxpetinfo, this.rcvarsservice.activechar);
    var maxpetstr = "";
    for (const k in this.maxpetinfo) {
      if (this.rcvarsservice.activechar['level'] >= this.maxpetinfo[k]["level"]) {
        maxpetstr = "Max pet you can take with you: " + this.maxpetinfo[k]["num"];
      }
    }
    var itemdescr = this.wh.add.text(0, 0, maxpetstr, wh.fs["winguibardescr"]);
    this.wh.petguicontainer.add(itemdescr);
    itemdescr.setOrigin(0, 0);
    itemdescr.setPosition(-145, -173);


    this.title = wh.add.text(0, 0, 'PETS', wh.fs["winguititle42"]);
    wh.petguicontainer.add(this.title);
    wh.petguicontainer.bringToTop(this.title);
    this.title.setPosition(0 - this.title.displayWidth / 2, -228);

    this.shopbackbtn.displayWidth = 100;
    this.shopfwdbtn.displayWidth = 100;
    this.shopbackbtn.displayHeight = 40;
    this.shopfwdbtn.displayHeight = 40;
    this.shopbackbtn.setPosition(-110, 220);
    this.shopfwdbtn.setPosition(110, 220);
    wh.petguicontainer.add(this.shopfwdbtn);
    wh.petguicontainer.add(this.shopbackbtn);
    wh.petguicontainer.bringToTop(this.shopfwdbtn);
    wh.petguicontainer.bringToTop(this.shopbackbtn);

    this.shopbackbtn.removeAllListeners();
    this.shopfwdbtn.removeAllListeners();
    this.shopbackbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopbackbtn', this);
        this.showinventory_showpage(wh, 'back');
      },
      this
    );

    this.shopfwdbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.plog.log('shop shopfwdbtn', this);
        this.showinventory_showpage(wh, 'next');
      },
      this
    );


    this.loading = wh.add.sprite(300, 300, 'pixelloading').play('pixelloading_b');
    this.loading.setOrigin(0.5, 0.5);
    this.loading.setPosition(0, 0);
    this.loading.displayWidth = this.loading.displayHeight = 60;
    wh.petguicontainer.add(this.loading);
    wh.petguicontainer.bringToTop(this.loading);


    this.empty = wh.add.image(300, 300, 'empty');
    this.empty.setOrigin(0.5, 0.5);
    this.empty.setPosition(0, 0);
    this.empty.displayWidth = this.empty.displayHeight = 300;
    wh.petguicontainer.add(this.empty);
    wh.petguicontainer.bringToTop(this.empty);
    this.empty.visible = false;
    //drag
    this.shopdragbtn = wh.add.image(300, 300, 'spacer');
    this.shopdragbtn.setOrigin(0.5, 0.5);
    this.shopdragbtn.setPosition(0, -200);
    this.shopdragbtn.displayWidth = 270;
    this.shopdragbtn.displayHeight = 60;
    this.shopdragbtn.setInteractive();
    this.shopdragbtn.removeAllListeners();
    this.shopdragbtn.on(
      'pointerout',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerup',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactive =
          false;
      },
      this
    );
    this.shopdragbtn.on(
      'pointerdown',
      function (pointer, localX, localY, event) {
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer
        );

        this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactive =
          true;
        this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactivex =
          this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactivey =
          this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.y;
        this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactivepx =
          pointer.x;
        this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactivepy =
          pointer.y;
      },
      this
    );
    this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactive =
      false;
    this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactivex = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactivey = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactivepx = 0;
    this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.dragactivepy = 0;
    this.shopdragbtn.on(
      'pointermove',
      function (pointer, localX, localY, event) {
        //this.scene.registry.list["thethis"].phm.floorclick(this);
        //this.plog.log('shop pointermove', pointer, localX, localY, event, this);
        //this.showinventory_close(wh);
        if (
          this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer
            .dragactive == true
        ) {
          var tmpx =
            this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer
              .dragactivex;
          var tmpy =
            this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer
              .dragactivey;
          var tmppx =
            this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer
              .dragactivepx;
          var tmppy =
            this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer
              .dragactivepy;
          var movedx = tmppx - pointer.x;
          var movedy = tmppy - pointer.y;
          //this.plog.log('drag', tmppx, pointer.x, movedx);

          this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.x =
            tmpx - movedx;
          this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.y =
            tmpy - movedy;
          //this.maskg.setPosition(this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.x,this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.y)
          //this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.y = pointer.y;
          //this.setmaskposition();
        }
      },
      this
    );
    wh.petguicontainer.add(this.shopdragbtn);
    wh.petguicontainer.bringToTop(this.shopdragbtn);
    this.menubaritem.push(this.shopdragbtn);
    //skilltype bar
    //var equipment = this.rcvarsservice.activechar["equip"];
    //this.plog.log("equipment eq", equipment);
    //this.plog.log("petguicontainer", wh.petguicontainer, width, height);
    var svversionurlstring = this.wh.registry.list.rcvarpass.svversionstring;
    if (svversionurlstring != '') {
      svversionurlstring = '?' + svversionurlstring;
    }
    wh.petguicontainer.setPosition(width / 2, height / 2);
    let loader = new Phaser.Loader.LoaderPlugin(wh);
    var loadlist = ['mob'];
    for (var k in loadlist) {
      var key = loadlist[k];
      loader.atlas(
        key + '',
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.png' +
        svversionurlstring,
        this.wh.registry.list.rcvarpass.rc_baseurl +
        'sprite/' +
        key +
        '.json' +
        svversionurlstring
      );
    }
    //move
    wh.petguicontainer.x = width - this.shopguibg.width / 2 + 20;
    /*
    var graphics = wh.make.graphics();
    graphics.fillStyle(0xff0000);
    graphics.fillRect(-200, 0, 300, 300);
    //wh.petguicontainer.add(graphics);
    //graphics.setOrigin(0,0);
    graphics.setPosition(0, 0);
    graphics.setDepth(10000000);

    //graphics.displayHeight = graphics.displayWidth = 400;
    //this.maskg = graphics;
    this.plog.log("xxxgraphics", graphics);

    //var mask = new Phaser.Display.Masks.GeometryMask(wh.petguicontainer_char, graphics);
    const mask = graphics.createGeometryMask();
    this.maskm = mask;
*/

    //wh.petguicontainer_char.on('pointermove', function (pointer) {
    //this.plog.log("xxxcontainer", this);
    //});
    //this.setmaskposition();
    loader.once(
      Phaser.Loader.Events.COMPLETE,
      () => {
        //this.plog.log('Phaser.Loader.Events.COMPLETE');
        //this.plog.log(this);
        //this.show_loaded();
        //this.setmaskposition();

        this.buildpetctnr();
        this.rcvarsservice.gameobj.registry.list.gameitf.guiwindowclick(
          this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer
        );
      },
      this
    );
    loader.start();
  }
  /*
  setmaskposition() {
    this.maskm.geometryMask.setPosition(
      this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.x + 50,
      this.rcvarsservice.gameobj.scene.scenes[0].petguicontainer.y - 130
    );
    //this.maskm.geometryMask.displayWidth=500;
  }*/
  async buildpetctnr() {
    this.plog.log('buildpetctnr');
    this.firsttimerunfunc();
    var wh = this.wh;
    if (wh.petguicontainer_char != undefined) {
      wh.petguicontainer_char.destroy();
    }

    var width = wh.game.canvas.width;
    var height = wh.game.canvas.height;

    wh.petguicontainer_char = wh.add.container(0, 0);
    wh.petguicontainer.add(wh.petguicontainer_char);
    var dat;
    dat = await this.rchttp.getcommon2('allpets', {});
    this.lastdat = dat;
    this.countpet = 0;
    for (const k in dat) {
      var v = dat[k];
      this.countpet++;
    }

    if (this.countpet == 0) {
      this.empty.visible = true;
    } else {
      this.empty.visible = false;
    }

    if (this.countpet > 6) {
      //pages

      this.respperpage = 6;
      this.resptotalpage = Math.ceil(this.countpet / this.respperpage);
    }
    this.plog.log('xxxcountpet', this.countpet);
    this.petguicontainer_char = wh.petguicontainer_char;
    wh.petguicontainer_char.setPosition(-120, -320);
    var i = -5;
    var rowh = 50;
    var iconwh = 40;
    var rowi = 0;
    var ii = 0;
    var rowhadjust = -250;
    for (const k in dat) {
      var v = dat[k];
      this.plog.log('xxxskdat', ii, rowi, v);
      this.skicon[ii] = wh.add.sprite(0, 0, 'mob', v['mob']);
      this.skicon[ii].texture.setFilter(Phaser.Textures.FilterMode.NEAREST);

      wh.petguicontainer_char.add(this.skicon[ii]);
      this.skicon[ii].displayHeight = this.skicon[ii].displayWidth = iconwh;
      this.skicon[ii].setPosition(
        iconwh / 2 - 20,
        rowi * rowh - 5 + rowhadjust
      );
      this.skbg[ii] = wh.add.image(0, 0, 'rowbg');
      wh.petguicontainer_char.add(this.skbg[ii]);
      this.skbg[ii].setOrigin(0, 0);
      this.skbg[ii].setInteractive();
      this.skbg[ii].removeAllListeners();
      this.skbg[ii].name = v['code'];

      /*this.skbg[ii].on('pointermove', function (pointer) {
        //this.plog.log(this);
        //var pos = this.parentContainer.y + this.y;
        //this.plog.log("pos", pos);
        //if (pos > -150) {
        if (pointer.isDown) {
          this.scene.registry.list.petgui.petguicontainer_char.y += (pointer.velocity.y / 3);
          this.scene.registry.list.petgui.petguicontainer_char.y = Phaser.Math.Clamp(this.scene.registry.list.petgui.petguicontainer_char.y, 430 - (this.scene.registry.list.petgui.countpet * 50), 150);
          //this.scene.registry.list.petgui.movemenubartotop();
          //this.plog.log("xxxskill in container", 0-(this.countpet*50),this.petguicontainer_char.y);
        }
        //}
      });

      this.skbg[ii].on('pointerup', async function (pointer) {
        this.plog.log("xxx,skill,", this);
        //var tmpnamea = this.name.split("---");
        var data = await this.scene.registry.list.rchttp.getcommon2("allpets");

        this.scene.registry.list.rcosd.show(wh, "pet", data[this.name]);
      });*/

      this.skbg[ii].setPosition(-20, rowi * rowh - rowh / 2 + 5 + rowhadjust);
      this.skbg[ii].displayHeight = iconwh;
      this.skbg[ii].displayWidth = 300;
      wh.petguicontainer_char.sendToBack(this.skbg[ii]);

      var namedsp = v['name'] + '';
      if (namedsp == '') namedsp = this.wh.trs("f:petguicontainer:Unnamed Pet===Unnamed Pet");
      namedsp = namedsp + ' lv.' + v['level'];
      this.sktt[ii] = wh.add.text(0, 0, namedsp, wh.fs['winguiitemname']);
      wh.petguicontainer_char.add(this.sktt[ii]);
      wh.petguicontainer_char.bringToTop(this.sktt[ii]);
      this.sktt[ii].setPosition(30, rowi * rowh - 25 + rowhadjust);
      //tmpskname.setResolution(0.25);
      this.plog.log('zzzzzz', this.sktt[ii]);
      this.skdescr[ii] = wh.add.text(0, 0, ' ' + v['racename'] + ' ' + v['descr'], wh.fs["winguilist_descr"]);
      //this.skdescr[ii].setFontSize("8px");
      wh.petguicontainer_char.add(this.skdescr[ii]);
      wh.petguicontainer_char.bringToTop(this.skdescr[ii]);
      this.skdescr[ii].setPosition(30, rowi * rowh - 5 + rowhadjust);
      /*    if (mode == "char" && this.rcvarsservice.activechar["cbt"]["skill"].includes(v["code"])) {
            this.plog.log("skillobtained", v);
            var tmpskobtained = wh.add.text(0, 0, "Obtained", { fontFamily: 'ffff', fontSize: '12px', fill: '#090', boundsAlignH: "center", boundsAlignV: "middle" });
            wh.petguicontainer_char.add(tmpskobtained);
            wh.petguicontainer_char.bringToTop(tmpskobtained);
            tmpskobtained.setPosition(210, (i * rowh) - 25);
          }
      */
      this.takebtn[ii] = wh.add.sprite(300, 300, "thesq", 'take');
      wh.petguicontainer_char.add(this.takebtn[ii]);
      wh.petguicontainer_char.bringToTop(this.takebtn[ii]);
      this.takebtn[ii].displayWidth = 50;
      this.takebtn[ii].scaleY = this.takebtn[ii].scaleX;
      this.takebtn[ii].setPosition(240, rowi * rowh - 5 + rowhadjust);
      this.takebtn[ii].setInteractive();
      this.takebtn[ii].removeAllListeners();
      this.takebtn[ii].name = k + "-" + ii;
      this.takebtn[ii].on('pointerup', async function () {
        var tmpnamea = this.name.split("-");
        this.scene.plog.log('tmptakbtn', this.name, this);
        this.scene.registry.list.petgui.loadingshow();
        var res = await this.scene.registry.list.rchttp.getcommon2(
          'pettoggletakehome',
          { petid: tmpnamea[0], set: 'leave' }
        );
        this.scene.plog.log(res);
        if (res == 'ok') {
          //this.scene.registry.list.petgui.takebtn[this.name].visible = false;
          //this.scene.registry.list.petgui.homebtn[this.name].visible = true;
          this.scene.registry.list.petgui.takebtn[tmpnamea[1]].visible = false;
          this.scene.registry.list.petgui.homebtn[tmpnamea[1]].visible = true;
          this.scene.registry.list.petgui.lastdat[tmpnamea[0]]["take"] = "NO";

        }
        this.scene.registry.list.petgui.loadinghide();

      });

      this.homebtn[ii] = wh.add.sprite(300, 300, "thesq", 'home');
      wh.petguicontainer_char.add(this.homebtn[ii]);
      wh.petguicontainer_char.bringToTop(this.homebtn[ii]);
      this.homebtn[ii].displayWidth = 50;
      this.homebtn[ii].scaleY = this.homebtn[ii].scaleX;
      this.homebtn[ii].setPosition(240, rowi * rowh - 5 + rowhadjust);
      this.homebtn[ii].setInteractive();
      this.homebtn[ii].removeAllListeners();
      this.homebtn[ii].name = k + "-" + ii;
      this.homebtn[ii].on('pointerup', async function () {
        this.scene.plog.log('homebtn', this.name, this);
        this.scene.registry.list.petgui.loadingshow();

        var tmpnamea = this.name.split("-");
        var res = await this.scene.registry.list.rchttp.getcommon2(
          'pettoggletakehome',
          { petid: tmpnamea[0], set: 'take' }
        );
        this.scene.plog.log(res, this.name, this.scene.registry.list.petgui);
        if (res == 'ok') {
          this.scene.registry.list.petgui.takebtn[tmpnamea[1]].visible = true;
          this.scene.registry.list.petgui.homebtn[tmpnamea[1]].visible = false;
          this.scene.registry.list.petgui.lastdat[tmpnamea[0]]["take"] = "YES";
        }
        this.scene.registry.list.petgui.loadinghide();

      });
      if (v['take'] == 'YES') {
        this.takebtn[ii].visible = true;
        this.homebtn[ii].visible = false;
      } else {
        this.takebtn[ii].visible = false;
        this.homebtn[ii].visible = true;
      }

      this.renamebtn[ii] = wh.add.sprite(300, 300, "thesq", 'edit');
      wh.petguicontainer_char.add(this.renamebtn[ii]);
      wh.petguicontainer_char.bringToTop(this.renamebtn[ii]);
      this.renamebtn[ii].displayWidth = 22;
      this.renamebtn[ii].scaleY = this.renamebtn[ii].scaleX;
      this.renamebtn[ii].setPosition(200, rowi * rowh - 15 + rowhadjust);
      this.renamebtn[ii].setInteractive();
      this.renamebtn[ii].removeAllListeners();
      this.renamebtn[ii].name = k;
      this.renamebtn[ii].on('pointerup', async function () {
        var newname;
        newname = await this.scene.registry.list.rctoast.rcentertext(
          this.scene,
          this.scene.trs("f:petguicontainer:Enter new name for your pet===Enter new name for your pet"),
          this.scene.trs("f:petguicontainer:Current name===Current name") + ' [' +
          this.scene.registry.list.petgui.lastdat[this.name]['name'] +
          ']'
        );
        this.scene.plog.log('newname', newname);
        if (newname != false && newname != '') {
          this.scene.registry.list.petgui.loadingshow();

          await this.scene.registry.list.rchttp.getcommon2('petrename', {
            petid: this.name,
            newname: newname,
          });
          try {
            this.scene.registry.list.petgui.loadinghide();
            this.scene.registry.list.petgui.buildpetctnr();
          } catch (e) {
            console.log(e);
          }
        }
      });

      this.removebtn[ii] = wh.add.sprite(300, 300, "thesq", 'del');
      wh.petguicontainer_char.add(this.removebtn[ii]);
      wh.petguicontainer_char.bringToTop(this.removebtn[ii]);
      this.removebtn[ii].displayWidth = 22;
      this.removebtn[ii].scaleY = this.removebtn[ii].scaleX;
      this.removebtn[ii].setPosition(200, rowi * rowh + 5 + rowhadjust);
      this.removebtn[ii].setInteractive();
      this.removebtn[ii].removeAllListeners();
      this.removebtn[ii].name = k;
      this.removebtn[ii].on('pointerup', async function () {
        var cfm;
        cfm = await this.scene.registry.list.rctoast.rcconfirm(
          this.scene,
          ' ' + this.scene.trs("f:petguicontainer:Do you want to abandon===Do you want to abandon this pet?") + ' ',
          this.scene.trs("f:petguicontainer:This action can't be undone===This action can't be undone")
        );
        this.scene.plog.log('cfm', cfm);
        if (cfm == 'ok') {

          await this.scene.registry.list.rctoast.sleep(500);

          var cfm2 = await this.scene.registry.list.rctoast.rcconfirm(
            this.scene,
            this.scene.trs("f:petguicontainer:Do you want to abandon2===Do you want to abandon this pet?"),
            this.scene.trs("f:petguicontainer:Confirm againabandon===Confirm again, just to be sure, this pet will miss you.")
          );
          this.scene.plog.log('cfm2', cfm2);
          if (cfm2 == 'ok') {
            this.scene.registry.list.petgui.loadingshow();

            await this.scene.registry.list.rchttp.getcommon2('petremove', {
              petid: this.name,
            });
            this.scene.registry.list.petgui.loadinghide();
            this.scene.registry.list.petgui.buildpetctnr();
          }

        }
      });

      i++;
      ii++;
      rowi++;
      if (rowi == 6) rowi = 0;
    }
    this.petguicontainer_char.y = 150;
    //wh.petguicontainer_char.setMask(this.maskm);
    this.showinventory_showpage(wh, 'back');

    this.wh.petguicontainer.visible = true;
    this.wh.registry.list.resizeman.RCMapResizeManager_work(this.wh);

    if (ii > 0) {
      this.wh.registry.list.tut.show(this.wh, "pet_home_loaded_gotpet");
    }


    //this.plog.log("xxxmask", this.maskm);
  }

  showinventory_showpage(gc, viewpage) {
    this.loadinghide();
    if (viewpage == 'back') {
      if (this.currentresppage > 1) this.currentresppage--;
    }
    if (viewpage == 'next') {
      if (this.currentresppage < this.resptotalpage) this.currentresppage++;
    }
    for (var i = 0; i <= 100; i++) {
      //this.plog.log(this.shopitemlist[i]);
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = false;
      }
      if (this.sktt[i] != undefined) {
        this.sktt[i].visible = false;
      }
      if (this.skob[i] != undefined) {
        this.skob[i].visible = false;
      }
      if (this.skbg[i] != undefined) {
        this.skbg[i].visible = false;
      }
      if (this.skdescr[i] != undefined) {
        this.skdescr[i].visible = false;
      }
      if (this.removebtn[i] != undefined) {
        this.removebtn[i].visible = false;
      }
      if (this.renamebtn[i] != undefined) {
        this.renamebtn[i].visible = false;
      }
      if (this.takebtn[i] != undefined) {
        this.takebtn[i].visible = false;
      }
      if (this.homebtn[i] != undefined) {
        this.homebtn[i].visible = false;
      }
    }
    var numfrom = this.respperpage * (this.currentresppage - 1);
    var numto = this.respperpage * this.currentresppage;
    this.plog.log(
      'showinventory_showpage',
      viewpage,
      this.currentresppage,
      'fromto',
      numfrom,
      numto,
      'perpage',
      this.respperpage
    );

    var griddat = [];
    var griddatf = [];

    griddat.push(this.shopclosebtn);

    griddat.push(this.shopfwdbtn);
    griddat.push(this.shopbackbtn);
    for (var i = numfrom; i < numto; i++) {
      if (this.skicon[i] != undefined) {
        this.skicon[i].visible = true;
        this.skbg[i].visible = true;
        if (this.skob[i] != undefined) this.skob[i].visible = true;
        this.sktt[i].visible = true;
        this.skdescr[i].visible = true;
        this.homebtn[i].visible = true;
        this.takebtn[i].visible = true;
        this.removebtn[i].visible = true;
        this.renamebtn[i].visible = true;
        if (this.takebtn[i] != undefined && this.takebtn[i].name != undefined) {
          var namea = this.takebtn[i].name.split('-');
          //this.plog.log("decisbv",i,namea,this.lastdat);
          if (this.lastdat[namea[0]]["take"] == 'YES') {
            this.takebtn[i].visible = true;
            this.homebtn[i].visible = false;
          } else {
            this.takebtn[i].visible = false;
            this.homebtn[i].visible = true;
          }
        }
        griddat.push(this.homebtn[i]);
        griddat.push(this.takebtn[i]);
        griddat.push(this.removebtn[i]);
        griddat.push(this.renamebtn[i]);
      }
    }

    this.wh.registry.list.gp.guigrid(griddat, griddatf);

  }

  async show_close(wh) {
    wh.petguicontainer.visible = false;
    this.wh.registry.list.gameitf.closegui(wh, wh.petguicontainer);
    for (let desi = 0; desi < 20; desi++) {

      for (const k in this.wh.petguicontainer.list) {
        try {
          await this.wh.petguicontainer.remove(this.wh.petguicontainer.list[k], true);
          //this.rcvarsservice.gameobj.scene.scenes[0].mapcontainer.list[k].destroy();
        } catch (e) {
          this.plog.log("ERROR! petguicontainer.destroy() ", e);
        }
      }
    }
    try {
      await this.wh.petguicontainer.destroy(true);
    } catch (e) {
      this.plog.log("ERROR! petguicontainer.destroy() ", e);
    }
    wh.petguicontainer = undefined;
  }

  rebuildguigrid() {
    this.plog.log("rebuildguigrid();b", this.wh.kbfocus);
    this.showinventory_showpage(null, "");
    this.plog.log("rebuildguigrid();a", this.wh.kbfocus);
  }
}
